import React, { useState } from "react";
import {Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import YCC1 from '../../Assests/Images/YCC1.png';
import YCC2 from '../../Assests/Images/YCC2.png';
import YCC3 from '../../Assests/Images/YCC3.png';
import YCC4 from '../../Assests/Images/YCC4.png';
import YCC5 from '../../Assests/Images/YCC5.png';
import YCC6 from '../../Assests/Images/YCC6.png';
import YCC7 from '../../Assests/Images/YCC7.png';
import YCC8 from '../../Assests/Images/YCC8.png';
import YCC9 from '../../Assests/Images/YCC9.png';
import Modal from './Modal';

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

function SwiperCoverflow() {
    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setactiveIndex] = useState(0)
    const [OpenVideoPlayer, setOpenVideoPlayer] = useState(false)
    const [count ,setcount] = useState(-1)

    const OpenPlayer = (id) => {
        setOpenVideoPlayer(true)
        setcount(id)
    }

    const ClosePlayer = () => {
        setOpenVideoPlayer(false)
        setcount(-1)
    }

    return (
        <div className="swiperconatoner">
            {(window.matchMedia("(max-width: 991px)").matches) ?
                <Swiper
                    className="padding-top-slider"
                    navigation
                    pagination={{
                        type: "progressbar",
                    }}
                    breakpoints={{
                        568: {
                            slidesPerView: 2,
                            // spaceBetweenSlides: 30
                        }
                        // 999: {
                        //     slidesPerView: 2,
                        //     spaceBetweenSlides: 40
                        // }

                    }}
                    // navigation = {true}
                    modules={[Pagination, Navigation]}
                    spaceBetween={(window.matchMedia("(max-width: 568px)").matches) ? 20 : (window.matchMedia("(max-width: 991px)").matches) ? 10 : -32}
                    effect="coverflow"
                    coverflowEffect={{
                        // rotate: 45,
                        // stretch: 0,
                        // depth: 100,
                        // modifier: 1,
                        // slideShadows: true
                        rotate: -20,
                        stretch: 0,
                        depth: (window.matchMedia("(max-width: 568px)").matches) ? 100 : 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    slidesPerView={1}
                    centeredSlides
                    style={{
                        height: (window.matchMedia("(max-width: 568px)").matches) ? window.innerHeight * 0.30 :
                            (window.matchMedia("(max-width: 991px)").matches) ? window.innerHeight * 0.45 : window.innerHeight * 0.62
                        , width: window.innerWidth * 0.90
                    }}
                    loop={true}
                    speed={800}
                    grabCursor={false}
                    parallax={true}
                    dots={false}
                    nextButton=".swiper-button-next"
                    prevButton=".swiper-button-prev"
                    onSlideChange={(swiperCore) => {
                        const {
                            activeIndex,
                            snapIndex,
                            previousIndex,
                            realIndex,
                        } = swiperCore;
                        setactiveIndex(realIndex)
                        // var frames = document.getElementsByTagName('iframe'); var t = 1;
                        // for (var i in frames) {
                        //     if(i  === previousIndex){
                        //     window.$(frames[i]).attr('src', window.$(frames[i]).attr('src'));
                        //     }
                        // }

                    }}

                >
                    <SwiperSlide
                        style={{ backgroundColor: '#1C1C1D ' }}
                    >
                        {/* <div>Slide 1</div> */}
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 2 </div>
                            <div className="secdata">PLENARY SESSION 4</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                        <img src={YCC1} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons" onClick={()=>{OpenPlayer(0)}}  />
                    </SwiperSlide>
                    <SwiperSlide
                        style={{ backgroundColor: '#1C1C1D ' }}
                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 4</div>
                            <div className="secdata">AUDIO VISUAL ENGINEERING</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC2} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} onClick={()=>{OpenPlayer(1)}} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons" />
                    </SwiperSlide>
                    <SwiperSlide
                        style={{ backgroundColor: '#1C1C1D ' }}
                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 4 </div>
                            <div className="secdata">ART & DESIGN</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC3} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons" onClick={()=>{OpenPlayer(2)}}  />
                    </SwiperSlide>
                    <SwiperSlide
                        style={{ backgroundColor: '#1C1C1D ' }}
                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 5 </div>
                            <div className="secdata">ENTERTAINMENT INDUSTRY</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                        <img src={YCC4} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons" onClick={()=>{OpenPlayer(3)}} />
                    </SwiperSlide>
                    <SwiperSlide

                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 5 </div>
                            <div className="secdata">KEYNOTE SPEAKER OF THE DAY</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC5} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"}/>
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons"  onClick={()=>{OpenPlayer(4)}}  />
                    </SwiperSlide>
                    <SwiperSlide

                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 12</div>
                            <div className="secdata">SPECIAL SESSION # 1 CONVERGING INDUSTRIES</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC6} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"}/>
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons"  onClick={()=>{OpenPlayer(5)}}  />
                    </SwiperSlide>
                    <SwiperSlide

                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 </div>
                            <div className="secdata">Industry Awareness Quiz | Mega Final</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC9} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons"  onClick={()=>{OpenPlayer(6)}} />
                    </SwiperSlide>
                </Swiper>
                :
                <Swiper
                    className="padding-top-slider"
                    navigation
                    pagination={{
                        type: "progressbar",
                    }}
                    breakpoints={{
                        568: {
                            slidesPerView: 2,
                            // spaceBetweenSlides: 30
                        }
                        // 999: {
                        //     slidesPerView: 2,
                        //     spaceBetweenSlides: 40
                        // }

                    }}
                    // navigation = {true}
                    modules={[Pagination, Navigation]}
                    spaceBetween={(window.matchMedia("(max-width: 568px)").matches) ? 20 : (window.matchMedia("(max-width: 991px)").matches) ? 10 : -32}
                    effect="coverflow"
                    coverflowEffect={{
                        // rotate: 45,
                        // stretch: 0,
                        // depth: 100,
                        // modifier: 1,
                        // slideShadows: true
                        rotate: -20,
                        stretch: 0,
                        depth: (window.matchMedia("(max-width: 568px)").matches) ? 100 : 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    slidesPerView={2}
                    centeredSlides
                    style={{ height: (window.matchMedia("(max-width: 568px)").matches) ? window.innerHeight * 0.25 : (window.matchMedia("(max-width: 991px)").matches) ? window.innerHeight * 0.45 : window.innerHeight * 0.62 }}
                    loop={true}
                    speed={800}
                    grabCursor={false}
                    parallax={true}
                    dots={false}
                    nextButton=".swiper-button-next"
                    prevButton=".swiper-button-prev"
                    onSlideChange={(swiperCore) => {
                        const {
                            activeIndex,
                            snapIndex,
                            previousIndex,
                            realIndex,
                        } = swiperCore;
                        setactiveIndex(realIndex)
                       

                    }}

                >
                    <SwiperSlide
                        style={{ backgroundColor: '#1C1C1D ' }}
                    >
                        {/* <div>Slide 1</div> */}
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 2 </div>
                            <div className="secdata">PLENARY SESSION 4</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                        <img src={YCC1} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons" onClick={()=>{OpenPlayer(0)}}  />
                    </SwiperSlide>
                    <SwiperSlide
                        style={{ backgroundColor: '#1C1C1D ' }}
                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 4</div>
                            <div className="secdata">AUDIO VISUAL ENGINEERING</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC2} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"}/>
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons"  onClick={()=>{OpenPlayer(1)}}  />
                    </SwiperSlide>
                    <SwiperSlide
                        style={{ backgroundColor: '#1C1C1D ' }}
                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 4 </div>
                            <div className="secdata">ART & DESIGN</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC3} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons" onClick={()=>{OpenPlayer(2)}}  />
                    </SwiperSlide>
                    <SwiperSlide
                        style={{ backgroundColor: '#1C1C1D ' }}
                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 5 </div>
                            <div className="secdata">ENTERTAINMENT INDUSTRY</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                        <img src={YCC4} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons" onClick={()=>{OpenPlayer(3)}} />
                    </SwiperSlide>
                    <SwiperSlide

                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 5 </div>
                            <div className="secdata">KEYNOTE SPEAKER OF THE DAY</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC5} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"}/>
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons"  onClick={()=>{OpenPlayer(4)}}  />
                    </SwiperSlide>
                    <SwiperSlide

                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 DAY 12</div>
                            <div className="secdata">SPECIAL SESSION # 1 CONVERGING INDUSTRIES</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC6} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"}/>
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons"  onClick={()=>{OpenPlayer(5)}}  />
                    </SwiperSlide>
                    <SwiperSlide

                    >
                        <div className="dataCoantaineer">
                            <div className="fstdata">YCC 2020 </div>
                            <div className="secdata">Industry Awareness Quiz | Mega Final</div>
                        </div>
                        {/* <iframe width="100%" height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} src="https://www.youtube.com/embed/AD56kXDh7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <img src={YCC9} height={(window.matchMedia("(max-width: 568px)").matches) ? "80%" : "85%"} />
                        <FontAwesomeIcon icon={faPlay} className="svg-play-icons"  onClick={()=>{OpenPlayer(6)}} />
                    </SwiperSlide>
                </Swiper>
            }
            <div className="view-all-slider-data"><a href="https://www.youtube.com/playlist?list=PLtpsAZJjrAoRGErHcZKH3YbL7qtHM4pQO" target={'_blank'}> view all</a></div>
            {OpenVideoPlayer &&
                <Modal
                    isFrame={true}
                    Url={count}
                    close={ClosePlayer} 
                    isIntern = {false}/>
            }
        </div>
    );
}

export default React.memo(SwiperCoverflow)
