import React from "react";
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import { IndustryURL,IndusURL,Instituteurl } from "../../Assests/js/config";

import 'react-toastify/dist/ReactToastify.css';
import visualprofile_light from '../../Assests/Images/visualprofile-light.png'
import visualprofile_dark1 from '../../Assests/Images/visualprofile-dark1.png'
import profile360_light from '../../Assests/Images/profile360-light.png'
import profile360_dark from '../../Assests/Images/profile360-dark.png'
import jobconnect_dark from '../../Assests/Images/AACTe dark.png'
import jobconnect_light from '../../Assests/Images/AACTe.png'
import interview_dark from '../../Assests/Images/interview-dark.png'
import interview_light from '../../Assests/Images/interview-light.png'
import {IndividualUrllogin} from '../../Assests/js/config';
import Instdashboard from '../../Assests/Images/Instdashboard.png'
import Lmscourse from '../../Assests/Images/Lmscourse.png'
import InstSvp from '../../Assests/Images/InstSvp.png'
import Phonixpng from '../../Assests/Images/Phonixpng.png'
import upskillinggs from '../../Assests/Images/upskillinggs.png';
import onlineinterview from '../../Assests/Images/onlineinterview.png';
import careerCoun from '../../Assests/Images/careerCoun.png';
import c360light from '../../Assests/Images/connect360-light.png'
import c360dark from '../../Assests/Images/c360dark.png';
import c360lightv1 from '../../Assests/Images/c360lightv1.png';
import history from '../../../src/histoty';
import base64 from 'base-64'
import indulite from '../../Assests/Images/indulite.svg';
import indudark from '../../Assests/Images/indudark.svg';
import Instdashdark from '../../Assests/Images/Instdashdark.svg';
import Instdashlite from '../../Assests/Images/Instdashlite.svg';
import Instsvpdark from '../../Assests/Images/Instsvpdark.svg';
import Instsvplite from '../../Assests/Images/Instsvplite.svg';
import Instlmsdark from '../../Assests/Images/Instlmsdark.svg';
import instlmslite from '../../Assests/Images/instlmslite.svg';
import appleimg from '../../Assests/Images/AACTelarge.png'
import Requestdomologo from "../../Assests/Images/mobiledemologo.svg"
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import OnlineDemo from '../Skl_aspire_component/skl-aspire-onlinedemo'
import Processflowarrow from '../../Assests/Images/RightandLeftarrowinProcessFlow.svg'

import inquibyccseriesimgLight from '../../Assests/Images/inquibyccseriesimgLight.png'
import inquibyccseriesimgDark from '../../Assests/Images/inquibyccseriesimgDark.png'
import inquibSectorimglight from '../../Assests/Images/inquibSectorimglight.png'
import inquibSectorimgDark from '../../Assests/Images/inquibSectorimgDark.png'

import inquibOfferCoursesimaglight from '../../Assests/Images/inquibOfferCoursesimaglight.png'
import inquibOfferCoursesimagDark from '../../Assests/Images/inquibOfferCoursesimagDark.png'

import inquibAssementimaglight from '../../Assests/Images/inquibAssementimaglight.png'
import inquibAssementDark from '../../Assests/Images/inquibAssementDark.png'

import inquibEngageLight from '../../Assests/Images/inquibEngageLight.png'
import inquibEngageDark from '../../Assests/Images/inquibEngageDark.png'

import Sklaspirelogo from '../../Assests/Images/Sklaspirelogo.png'
import InquibimagProcess from '../../Assests/Images/InquibimagProcess.png'

import inquibsectorimags from '../../Assests/Images/inquibsectorimags.svg'
import quibsectomobiletabimag from '../../Assests/Images/quib-secto-mobile-tabimag.svg'


import EnquirenowQuib from '../../Assests/Images/EnquirenowQuib.svg'
import PartnernowQuib from '../../Assests/Images/PartnernowQuib.svg'
import freeimgicone from '../../Assests/Images/freeimgicone.svg';

import Dummycicleaspire from '../../Assests/Images/Dummycicleaspire.png';
import {RoutingUrl} from '../../Assests/js/config'

import orange from '../../Assests/Images/orangesklaspire.png';
import green from '../../Assests/Images/greensklaspire.png';
import violet from '../../Assests/Images/violetaspire.png';

import FeaturesHighlightDesk from '../../Assests/Images/FeaturesHighlightDesk.png';
import FeaturesHighlightMob from '../../Assests/Images/FeaturesHighlightMob.png';

import sklaspassemntLight from '../../Assests/Images/sklaspassemntLight.png';
import sklaspassemntDark from '../../Assests/Images/sklaspassemntDark.png';
import solutionsklaspLight from '../../Assests/Images/solutionsklaspLight.png';
import solutionsklaspDark from '../../Assests/Images/solutionsklaspDark.png';

import INTEGRATIONLight from '../../Assests/Images/INTEGRATIONLight.png';
import INTEGRATIONDark from '../../Assests/Images/INTEGRATIONDark.png';
import FEATURESLIGHT from '../../Assests/Images/FEATURESLIGHT.png';
import FEATURESDark from '../../Assests/Images/FEATURESDark.png';


import Sklaspstage1 from '../../Assests/Images/Sklaspstage1.png';
import Sklasptage2 from '../../Assests/Images/Sklasptage2.png';
import Sklaspstage3 from '../../Assests/Images/Sklaspstage3.png';


import Newsletter1 from '../../Assests/Images/Newsletter1.png';
import newslettersectmob from '../../Assests/Images/newslettersectmob.png';
import newslettersectdesk from '../../Assests/Images/newslettersectdesk.png';
import newsletterbookviewwhite from '../../Assests/Images/newsletterbookviewwhite.png';
import technewsletterDark from '../../Assests/Images/technewsletterDark.png';
import technewsletterlight from '../../Assests/Images/technewsletterlight.png';
import keyfeatureDark from '../../Assests/Images/keyfeatureDark.png';
import keyfeaturelight from '../../Assests/Images/keyfeaturelight.png';
import newsletteractiveDark from '../../Assests/Images/newsletteractiveDark.png';
import newsletteractivelight from '../../Assests/Images/newsletteractivelight.png';
import valueprepositionDarknews from '../../Assests/Images/valueprepositionDarknews.png';
import valueprepositionlightnews from '../../Assests/Images/valueprepositionlightnews.png';
import forinstitute from '../../Assests/Images/forinstitute.png';
import forstudents from '../../Assests/Images/forstudents.png';
import Select from "react-select";
import {GetNLAQQuizData} from '../General/general-methods';
import {GetAllSectorList} from '../Skl_aspire_component/skl-aspir-method';
import RefreshsectorIcon from '../../Assests/Images/RefreshsectorIcon.svg' 
let scroll
let QuizUrl="http://localhost:3000/skillablers-aspire"
export const Quizselectknowledgehub = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        background: '#e3e5f6',
        color: '#000',
        padding: 10,
        fontSize: (window.matchMedia("(min-width :2000px)").matches) ?"20px" :"12px",
        textTransform:'capitalize',
        '&:hover': {
            background: '#CCE7F3',
            color: '#000',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
        fontSize: "12px",
    }),
    dropdownIndicator: () => ({
        color: "rgb(32,168,216)",
        padding: (window.matchMedia("(max-width :  568px)").matches) ? '0px' : '0px',
        //margin: '1px 0px',
        borderRadius: "15px"

    }),

    control: () => ({
        color: '#ffffff',
        background:'var(--svpNewBg)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "5px" : "5px",
        border: '1px solid rgb(32,168,216)',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '6px  20px' : '2.1px 5px 1.1px 5px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4vh' : "30px"
    }),
    
    input: () => ({
        color: 'var(--inputTextClr)',
        padding: '5px 0px',
        cursor: 'pointer !important',
        fontSize: "13px",
        textTransform: "capitalize",
        whiteSpace: 'nowrap'
    }),
    
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: '#000',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '0vh' : '0px',
        whiteSpace: 'nowrap'
    }),
    placeholder: () => ({
        color: '#000',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}

class Newsletter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeData:1,
            skills: [],
            jobs :[],
            clientdetail: [],
            categ : [],
            catcomplogo:[],
            // searchSkills : '',
            searchSkills : [],
            textactive:1,
            Tab:1,
            PFCfiltredselected:'',
            CategHalf :[],
            selectedpack:'',
            checkedtrue:false,
            isReqstdemo:false,
            type:"",
            monthlist:[ {value:1 , label:'January'},
                        {value:2 , label:'February'},
                        {value:3 , label:'March'},
                        {value:4 , label:'April'},
                        {value:5 , label:'May'},
                        {value:6 , label:'June'},
                        {value:7 , label:'July'},
                        {value:8 , label:'Auguest'},
                        {value:9 , label:'September'},
                        {value:10 , label:'October'},
                        {value:11, label:'November'},
                        {value:12, label:'December'}],
            yearlist:[{value:2026, label:'2026'}
                    ,{value:2025, label:'2025'}
                    ,{value:2024, label:'2024'}
                    ,{value:2023, label:'2023'}
                    ,{value:2022, label:'2022'}
                    ,{value:2021, label:'2021'}],
            optionsector:[],
            candList:[],
            selectedsector:{value:0, label:''},
            selectedYear:{value:0, label:''},
            selectedMonth:{value:0, label:''},
            show:-1
        }
    }

    componentDidMount(){
        //mobile view tabs process flow function
        // hide all contents accept from the first div
        window.$('html,body').animate({
            scrollTop: 0
        });
        $('.tabContent div:not(:first)').toggle();
        // hide the previous button
        $('.PreviousprocessFlow').hide();
        $('.tabs1 li').click(function () {
            if ($(this).is(':last-child')) {
                $('.nextprocessFlow').hide();
            }else {
                $('.nextprocessFlow').show();
            }
            if ($(this).is(':first-child')) {
                $('.PreviousprocessFlow').hide();
            }else {
                $('.PreviousprocessFlow').show();
            }
            var position = $(this).position();
            var corresponding = $(this).data("id");
            // scroll to clicked tab with a little gap left to show previous tabs
            scroll = $('.tabs1').scrollLeft();
            $('.tabs1').animate({
                'scrollLeft': scroll + position.left
            }, 200);
            // show content of corresponding tab
            $('div.' + corresponding).toggle();
            // remove active class from currently not active tabs
            $('.tabs1 li').removeClass('active');
            // add active class to clicked tab
            $(this).addClass('active');
        });

        $('.nextprocessFlow').click(function(e){
            e.preventDefault();
            $('li.active').next('li').trigger('click');
            if(scroll== 185|| scroll== 184||scroll== 159 || scroll == 160){
                $('.nextprocessFlow').hide();
            }
        });

        $('.PreviousprocessFlow').click(function(e){
            e.preventDefault();
            $('li.active').prev('li').trigger('click'); 
        });
    
        //mobile view tabs process flow function

        this.Getcanddetails()
    }

    Getcanddetails=async()=>{
        await GetNLAQQuizData(0,0,0).then(res=>{
            this.setState({
                candList:res.recordsets[0].length>0?res.recordsets[0]:[]
            })
        })

        await GetAllSectorList(0).then(rest=>{
            this.setState({
                optionsector:rest.recordsets[0].length>0?rest.recordsets[0]:[]
            })
        })
    }

    visuaprofiling = (id) => {
        this.setState({
            activeData: id,
            textactive: 0
        }, () => {
            setTimeout(() => {
                this.setState(
                    {
                        textactive: id
                    }
                )
            }, 900)
        })
        window.$('html,body').animate({
            scrollTop: 0
        });
    }
    
    openinsti=()=>{
       window.open(Instituteurl+'/ins-login','_blank')
    }

    // popup for Request for demo

    OpenRequestDemo = (type) => {
        this.setState({
            isReqstdemo : true,
            type:type
        })
    }

    closeRequestDemo = () => {
        this.setState({
            isReqstdemo : false,
            type:""
        })
    }

    toastMessage=()=>{
        toast.success('Thank you for your request. Our team will contact you soon.', {    
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4000,
            // autoClose: 5555000,
            theme: "colored"
        })
    }

    setlasttabactive=(id)=>{
        this.setState({
            activeData:id
        })
        window.$('html,body').animate({
            scrollTop: 0
        });
    }

    redirectinstituteReg=()=>{
        window.open(Instituteurl+'/ins-registration', '_blank')
    }
    redirectindustryReg=()=>{
        window.open(IndusURL+'/indu-registration', '_blank')
    }
    redirectGuestLogin=()=>{
        window.open(IndividualUrllogin+'/indi-guest-login', '_blank')
    }
    aspireenquiryform=()=>{
        window.open('#' +Routing.sklenquiry, '_self')
    }  
    //quiz assement
    redirecttoQuiz = (QuizId,IsRetake)=>{
        let canid='CAN19A00267'
        // let canid='CAN23A19065'
        let obj = {'Quiz_Id':QuizId,"RedirectURL":window.location.href,"CANUID":canid,"Is_Retake":IsRetake}
        obj = base64.encode(JSON.stringify(obj))
        let URL =  QuizUrl +'?'+ obj;
        let a = document.createElement('a');
        a.target = '_self';
        a.href = URL;
        a.click();
    }
    viewdetails=(data)=>{
        data = (data === this.state.show? -1:data)
        this.setState({
            show:data
        })
    }
    SelectSector=async(selection)=>{
        await GetNLAQQuizData(selection.value,this.state.selectedYear.value,this.state.selectedMonth.value).then(res=>{
            this.setState({
                candList:res.recordsets[0].length>0?res.recordsets[0]:[],
                selectedsector:selection
            })
        })
    }
    SelectYear=async(selection)=>{
        await GetNLAQQuizData(this.state.selectedsector.value,selection.value,this.state.selectedMonth.value).then(res=>{
            this.setState({
                candList:res.recordsets[0].length>0?res.recordsets[0]:[],
                selectedYear:selection
            })
        })
    }

    SelectMonth=async(selection)=>{
        await GetNLAQQuizData(this.state.selectedsector.value,this.state.selectedYear.value,selection.value).then(res=>{
            this.setState({
                candList:res.recordsets[0].length>0?res.recordsets[0]:[],
                selectedMonth:selection
            })
        })
    }
    Discard=async()=>{
        await this.Getcanddetails()
        this.setState({
            selectedsector:{value:0, label:''},
            selectedYear:{value:0, label:''},
            selectedMonth:{value:0, label:''},
            show:-1
        })
    }
    render(){
        return(   
            <div>
                <div className="indi-conatiner bg-roadmap d-flex mb-3" >
                    <div className="indihomeland-subwrap" id="popupdetail"></div>
                </div>
                <div className="mb-style-changewrap paddtopdeskNewflows Desktopnewdesign" id="Processflow">
                    <div className="col-lg-12 col-md-12 col-3 px-0 row mx-0 d-flex justify-content-center  directmbcloum ">
                        <div className="  col-md-2 col-lg-2 px-0  pubjd">
                            <a target='_blank'>
                                <div className={this.state.activeData === 1 ? "skl-productss active  Brradius-indu" : "skl-productss   Brradius-indu"}
                                    onClick={()=>this.visuaprofiling(1)}
                                >
                                    <div className="indu-image-blocks">
                                        <img src= {this.state.activeData === 1 ? technewsletterDark : technewsletterlight} className="Brradius-indu"/>
                                    </div>
                                    <div>
                                        <div className="skl-prod-name bold-efeect" id='VProfile'>Tech Newsletter</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="   col-md-2 col-lg-2   px-0">
                            <a target='_blank'>
                                <div 
                                    className={this.state.activeData === 2 ? "skl-productss active  Brradius-indu" : "skl-productss Brradius-indu"}
                                    onClick={()=>this.visuaprofiling(2)} 
                                >
                                    <div className="indu-image-blocks">
                                        <img src= {this.state.activeData === 2 ? keyfeatureDark : keyfeaturelight} className="Brradius-indu"/>
                                    </div>
                                    <div>
                                        <div className="skl-prod-name bold-efeect">Key Features Highlight</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2  px-0">
                            <div 
                                className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                onClick={()=>this.visuaprofiling(3)}
                            >
                                <div className="indu-image-blocks">
                                    <img src={this.state.activeData === 3 ? FEATURESDark  : FEATURESLIGHT} className="Brradius-indu" />
                                </div>
                                <div>
                                    <div className="skl-prod-name bold-efeect">Industry sectors</div>
                                </div>
                            </div>
                        </div>
                        <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
                            <a target='_blank'>
                                <div className={this.state.activeData === 4 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                    onClick={()=>this.visuaprofiling(4)}
                                >
                                    <div className="indu-image-blocks">
                                        <img src={this.state.activeData === 4 ? newsletteractiveDark : newsletteractivelight} className="Brradius-indu"/>
                                    </div>
                                    <div>
                                        <div className="skl-prod-name bold-efeect">Newsletter Activities</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                            <a target='_blank'>
                                <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                    onClick={()=>this.visuaprofiling(5)}
                                >
                                    <div className="indu-image-blocks">
                                        <img src={this.state.activeData === 5 ? valueprepositionDarknews  : valueprepositionlightnews} className="Brradius-indu"/>
                                    </div>
                                    <div>
                                        <div className="skl-prod-name bold-efeect"><span className="txt-trans">VALUE PROPOSITION</span></div>      
                                    </div>
                                </div>
                            </a>
                        </div>    
                    </div> 
                    <div className={this.state.activeData === 1 ? "col-sm-12 col-9 px-0 indu-data-containerss active fst-border" :
                                   this.state.activeData === 5 ? "col-sm-12 col-9  px-0 indu-data-containerss active lst-border" : "col-sm-12 col-9  px-0 indu-data-containerss active all-border "}
                    >
                        <div className="col-12 px-0 row mx-0   tabsec-center  mi-heigh-overall">            
                            {this.state.activeData === 1 ?
                                <div>
                                    <div className="row alignaccordinginquib">
                                        <div className="col-lg-6">
                                            <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">TECH NEWSLETTER</span></div> 
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">
                                                Stay ahead of the curve with our "Tech Trends for Students" newsletter, designed to keep you informed about the latest developments in technology and how they impact your education and future career. Whether you're a student exploring potential career paths or an institute looking to provide the best resources for your students, our newsletter is your go-to source for staying updated with the dynamic world of technology.
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={Newsletter1} className="inquibprocess sklaspnewwidthimg"></img></div>
                                        </div>
                                    </div>
                                </div>
                                :this.state.activeData === 2 ?
                                <div>
                                    <div className="Inquib-corporateText mb-1">KEY FEATURES HIGHTLIGHT</div>
                                    <div>
                                        <div className="">
                                            <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Expertly Curated Content</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">We source our information from reliable and reputable sources to ensure you receive accurate and insightful updates.</div>
                                        </div>
                                        <div>
                                            <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Sector Specific</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Customization options to receive sector-specific newsletters, ensuring relevance to your career goals and academic pursuits.</div>
                                        </div>
                                        <div>
                                            <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Career Guidance</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Our newsletter includes articles that offer practical advice on career paths, job market trends, and essential skills needed in the tech industry.</div>
                                        </div>
                                        <div>
                                            <div  className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Convenient Access</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Receive the newsletter directly via email or access it through our user-friendly online platform, ensuring you can stay informed anytime, anywhere.</div>
                                        </div>
                                    </div>
                                    {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                        <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                    </div> */}
                                </div>
                                :this.state.activeData === 3 ?
                                <div>
                                    <div className="Inquib-corporateText mb-1">INDUSTRY SECTORS</div>
                                    <div className="row mx-0">
                                        <div className="">
                                            <img src={newslettersectdesk} className="widsector-tab-imag-quib"></img>
                                        </div>
                                    </div> 
                                    {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                        <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                    </div> */}
                                </div>
                                :this.state.activeData === 4 ?
                                <div>
                                    <div className="Inquib-corporateText mb-1">NEWSLETTER ACTIVITIES</div>
                                    <div>
                                        <div className="">
                                            <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Interactive Classroom Discussions</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Foster in-depth discussions on the latest tech trends covered in the newsletter, encouraging critical thinking and idea exchange.</div>
                                        </div>
                                        <div>
                                            <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Knowledge Testing</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Engage students with Monthly quizzes that test their understanding of the topics covered in the latest newsletter.</div>
                                        </div>
                                        <div>
                                            <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Rewards and Recognition</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Motivate participation through rewards for top performers, enhancing learning through healthy competition.</div>
                                        </div>
                                        <div>
                                            <div  className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Webinars and Seminars</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Organize talks with professionals from various tech industries to discuss current trends, career advice, and industry insights.</div>
                                        </div>
                                        <div>
                                            <div  className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Networking Opportunities</span></div>
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext mb-2">Facilitate connections between students and industry professionals, providing valuable networking opportunities.</div>
                                        </div>
                                    </div>
                                    {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                <button className="createprofile meet-our-team texnonetransfor"  onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                    </div> */}
                                </div>
                                :this.state.activeData === 5 ?
                                <div>
                                    <div className="Inquib-corporateText mb-1">VALUE PROPOSITION</div>
                                    <div>
                                        <div className="row alignaccordinginquibnlaq">
                                            <div className="col-lg-6">
                                                <div>
                                                    <div className="row mx-0">
                                                        <div className="flextextandimgnewsletter"><div><img src={forstudents}/></div><div className="subheadaspiresecondtab ml-2"><span className="bldtex-list-quib sklasptexthead">For Students</span></div> </div>
                                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead"> Stay Informed and Ahead of the Curve</span></div> 
                                                    </div>
                                                    <div>
                                                        <ul className="listparent-quib">
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Premium Content :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Provide your students with access to expertly curated content from reliable sources, enriching their learning experience.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Career Development Support :</span> <span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Equip students with essential career guidance, helping them to successfully transition from education to employment in the tech industry.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Customizable Resources :</span> <span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Offering tailored newsletters branchwise that align with the specific interests and career goals of your student body, ensuring relevance and engagement.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Boost Institutional Value :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Strengthen your institute's reputation as a forward-thinking, tech-savvy educational provider committed to student success and continuous learning.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <div className="row mx-0">
                                                        <div className="flextextandimgnewsletter"><div><img src={forinstitute}/></div><div className="subheadaspiresecondtab ml-2"><span className="bldtex-list-quib sklasptexthead">For Institute</span></div> </div>
                                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead"> Enhance Educational Offerings and Support Student Success</span></div>
                                                    </div>
                                                    <div>
                                                        <ul className="listparent-quib">
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Cutting-Edge Knowledge :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Provide your students with access to expertly curated content from reliable sources, enriching their learning experience.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Tailored Career Guidance :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Equip students with essential career guidance, helping them to successfully transition from education to employment in the tech industry.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Personalized Learning :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Offering tailored newsletters branchwise that align with the specific interests and career goals of your student body, ensuring relevance and engagement.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Convenient Access :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Strengthen your institute's reputation as a forward-thinking, tech-savvy educational provider committed to student success and continuous learning.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                            <button className="createprofile meet-our-team texnonetransfor"  onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                        </div> */}    
                                    </div>
                                </div>
                                :this.state.activeData === 6 ?
                                <>
                                    <div>
                                        <div className="row  mx-0">
                                            <div className="col-lg-12">
                                                <div className="disflexiinquibmob mb-1"><img src={Sklaspirelogo} className="inquibyccfreeimag"></img></div>
                                            </div>
                                        </div>
                                        <div className="row mx=0">
                                            <div className=" Inquib-corporateText aspirewidtab6 textcolrtab6cuttingedge">
                                                Cutting-edge technology solution to seamlessly manage 
                                                institution admission assessment test process – instantly
                                            </div>
                                            <div className="col-12 Inquib-corporateText martop-aspire-sch-eng Robototextligth">Launch a Live Demo<button className="createprofile meet-our-team texnonetransfor margskladspbut" onClick={() => { this.OpenRequestDemo("SKLASPIREPolicy") }}>Start Demo</button></div>
                                            <div className="col-12 Inquib-corporateText Robototextligth">Engage Ahead<button className="createprofile meet-our-team texnonetransfor margskladspbut" onClick={this.aspireenquiryform}>Partner Now</button></div>
                                            {/* <button className="createprofile meet-our-team texnonetransfor margskladspbut" onClick={()=>this.redirecttoQuiz(173,1)}>sklasp</button> */}
                                        </div>
                                    </div>
                                </>
                                :<></>
                            }
                        </div>
                    </div>
                </div>
                <div className="MobileviewnewDesign">
                    <div className="indiproceswrap">
                        <div class="contentWrapperProcessflow">
                            <div class="tabsWrapperProcessflow">
                                <ul class="tabs1">
                                    <li data-id="contentOne" class="active"><span className={this.state.activeData=== 1?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(1)}>Tech Newsletter</span><div className={this.state.activeData=== 1?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentTwo"><span className={this.state.activeData=== 2?"pfscoreclr fntwettext ":"pfscoreclr"} onClick={()=>this.visuaprofiling(2)}>Key Features Highlight </span><div className={this.state.activeData=== 2?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentThree"><span className={this.state.activeData=== 3? "pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(3)}>Industry sectors</span><div className={this.state.activeData=== 3?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentFour"><span className={this.state.activeData=== 4?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(4)}>Newsletter Activities</span><div className={this.state.activeData=== 4?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentSix"><span className={this.state.activeData=== 5?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(5)}>Value Proposition</span><div className={this.state.activeData=== 5?"bdbottomclrnew ":""}></div></li>
                                </ul>
                            </div> 
                            <span class="nextprocessFlow"><img src={Processflowarrow}></img></span>
                            <span class="PreviousprocessFlow"><img src={Processflowarrow}></img></span>
                        </div>
                    </div>
                    {this.state.activeData === 1 ?
                        <div>
                            <div className="row alignaccordinginquib mobiletextnewsletterblock">
                                <div className="col-lg-6">
                                    <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">TECH NEWSLETTER</span></div> 
                                    <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">
                                        Stay ahead of the curve with our "Tech Trends for Students" newsletter, designed to keep you informed about the latest developments in technology and how they impact your education and future career. Whether you're a student exploring potential career paths or an institute looking to provide the best resources for your students, our newsletter is your go-to source for staying updated with the dynamic world of technology.
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="makecenterfsttabimag-quib aspcirclecent"><img src={newsletterbookviewwhite} className="inquibprocess sklaspnewwidthimg"></img></div>
                                </div>
                            </div>
                            {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                <button className="createprofile meet-our-team texnonetransfor"  onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                            </div> */}       
                        </div> 
                        :this.state.activeData === 2 ?
                            <div className="mobiletextnewsletterActivitiesblock">
                                <div className="Inquib-corporateText mb-1">KEY FEATURES HIGHTLIGHT</div>
                                <div>
                                    <div className="">
                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Expertly Curated Content</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">We source our information from reliable and reputable sources to ensure you receive accurate and insightful updates.</div>
                                    </div>
                                    <div>
                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Sector Specific</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Customization options to receive sector-specific newsletters, ensuring relevance to your career goals and academic pursuits.</div>
                                    </div>
                                    <div>
                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Career Guidance</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Our newsletter includes articles that offer practical advice on career paths, job market trends, and essential skills needed in the tech industry.</div>
                                    </div>
                                    <div>
                                        <div  className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Convenient Access</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Receive the newsletter directly via email or access it through our user-friendly online platform, ensuring you can stay informed anytime, anywhere.</div>
                                    </div>
                                </div>
                                {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                             <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                </div> */}
                            </div>           
                        :this.state.activeData === 3 ?
                            <div className="mobiletextnewsletterblock">
                                <div className="Inquib-corporateText mb-1">INDUSTRY SECTORS</div>
                                <div className="row mx-0">
                                    <div className="">
                                        <img src={newslettersectmob} className="widsector-tab-imag-quib"></img>
                                    </div>
                                </div> 
                                {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                    <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                </div> */}
                            </div>
                        :this.state.activeData === 4 ?
                            <div className="mobiletextnewsletterActivitiesblock">               
                                <div className="Inquib-corporateText mb-1">NEWSLETTER ACTIVITIES</div>
                                <div>
                                    <div className="">
                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Interactive Classroom Discussions</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Foster in-depth discussions on the latest tech trends covered in the newsletter, encouraging critical thinking and idea exchange.</div>
                                    </div>
                                    <div>
                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Knowledge Testing</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Engage students with Monthly quizzes that test their understanding of the topics covered in the latest newsletter.</div>
                                    </div>
                                    <div>
                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Rewards and Recognition</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Motivate participation through rewards for top performers, enhancing learning through healthy competition.</div>
                                    </div>
                                    <div>
                                        <div  className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Webinars and Seminars</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Organize talks with professionals from various tech industries to discuss current trends, career advice, and industry insights.</div>
                                    </div>
                                    <div>
                                        <div  className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Networking Opportunities</span></div>
                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext  mb-2">Facilitate connections between students and industry professionals, providing valuable networking opportunities.</div>
                                    </div>
                                </div>
                                {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                        <button className="createprofile meet-our-team texnonetransfor"  onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                </div> */}
                            </div>
                        :this.state.activeData === 5 ?
                            <div className="mobiletextnewsletterblock">
                                <div className="Inquib-corporateText mb-1">VALUE PROPOSITION</div>
                            <div>
                                <div className="row alignaccordinginquib">
                                    <div className="col-lg-6">
                                                <div>
                                                    <div className="row mx-0">
                                                        <div className="flextextandimgnewsletter"><div><img src={forstudents}/></div><div className="subheadaspiresecondtab ml-2"><span className="bldtex-list-quib sklasptexthead">For Students</span></div> </div>
                                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead"> Stay Informed and Ahead of the Curve</span></div> 
                                                    </div>
                                                    <div>
                                                        <ul className="listparent-quib">
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Premium Content :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Provide your students with access to expertly curated content from reliable sources, enriching their learning experience.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Career Development Support :</span> <span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Equip students with essential career guidance, helping them to successfully transition from education to employment in the tech industry.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Customizable Resources :</span> <span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Offering tailored newsletters branchwise that align with the specific interests and career goals of your student body, ensuring relevance and engagement.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Boost Institutional Value :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Strengthen your institute's reputation as a forward-thinking, tech-savvy educational provider committed to student success and continuous learning.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <div className="row mx-0">
                                                        <div className="flextextandimgnewsletter"><div><img src={forinstitute}/></div><div className="subheadaspiresecondtab ml-2"><span className="bldtex-list-quib sklasptexthead">For Institute</span></div> </div>
                                                        <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead"> Enhance Educational Offerings and Support Student Success</span></div>
                                                    </div>
                                                    <div>
                                                        <ul className="listparent-quib">
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Cutting-Edge Knowledge :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Provide your students with access to expertly curated content from reliable sources, enriching their learning experience.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Tailored Career Guidance :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Equip students with essential career guidance, helping them to successfully transition from education to employment in the tech industry.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Personalized Learning :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Offering tailored newsletters branchwise that align with the specific interests and career goals of your student body, ensuring relevance and engagement.</span></li>
                                                            <li className="size-quib-pointlist"><span className="bldtex-list-quib rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 fntoboldnesletter">Convenient Access :</span><span className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Strengthen your institute's reputation as a forward-thinking, tech-savvy educational provider committed to student success and continuous learning.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                </div>
                                {/* <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                        <button className="createprofile meet-our-team texnonetransfor"  onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                </div> */}          
                            </div>        
                        </div>
                        :this.state.activeData === 6 ?
                            <>
                                <div className="wid90mobTab2">
                                    <div className="row  mx-0">
                                        <div className="col-lg-12">
                                            <div className="disflexiinquibmob mb-1"><img src={Sklaspirelogo} className="inquibyccfreeimag"></img></div>
                                        </div>
                                    </div>
                                    <div className="row mx=0">
                                        <div className=" Inquib-corporateText aspirewidtab6 textcolrtab6cuttingedge">
                                            Cutting-edge technology solution to seamlessly manage 
                                            institution admission assessment test process – instantly
                                        </div>
                                        <div className="col-12 Inquib-corporateText martop-aspire-sch-eng Robototextligth">Launch a Live Demo</div>
                                        <div className="col-12 Inquib-corporateText"><button className="createprofile meet-our-team texnonetransfor" onClick={() => { this.OpenRequestDemo("SKLASPIREPolicy") }}>Start Demo</button></div>
                                        <div className="col-12 Inquib-corporateText Robototextligth">Engage Ahead</div>
                                        <div className="col-12 Inquib-corporateText"><button className="createprofile meet-our-team texnonetransfor " onClick={this.aspireenquiryform}>Partner Now</button></div>
                                    </div>
                                </div>
                            </>
                        :<></>
                    }
                </div>
                <div>
                    {/* commented for work in progress  */}
                    {/* <div className="bgclrindu-main centerblocknews" id="scrol-price" >
                        <div className="">
                            <div class="newsletterhead">NEWSLETTER RELATED AWARENESS QUIZZES (NLAQ) LEADERBOARD</div>
                        </div>
                        <div className='disflexnewsletter mb-3'>
                            <div className='row mx-0'>
                                <div className='col-lg-3  col-12 selectmobcenter'>
                                    <div className=''>
                                        <Select
                                            className="basic-single widthfilterselect"
                                            placeholder="Sector"
                                            isDisabled={false}
                                            isLoading={false}
                                            styles={Quizselectknowledgehub}
                                            value={this.state.selectedsector.value === 0?[]:this.state.selectedsector}
                                            isSearchable={false}
                                            options={this.state.optionsector}
                                            onChange={this.SelectSector}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-3  col-12 selectmobcenter'>
                                    <div className=''>
                                        <Select
                                            className="basic-single widthfilterselect"
                                            placeholder="Year"
                                            isDisabled={false}
                                            isLoading={false}
                                            styles={Quizselectknowledgehub}
                                            value={this.state.selectedYear.value === 0?[]:this.state.selectedYear}
                                            isSearchable={false}
                                            options={this.state.yearlist}
                                            onChange={this.SelectYear}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-3 col-12 selectmobcenter'>
                                    <div className=''>
                                        <Select
                                            className="basic-single widthfilterselect"
                                            placeholder="Month"
                                            isDisabled={false}
                                            isLoading={false}
                                            styles={Quizselectknowledgehub}
                                            value={this.state.selectedMonth.value === 0?[]:this.state.selectedMonth}
                                            isSearchable={false}
                                            options={this.state.monthlist}
                                            onChange={this.SelectMonth}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-3  col-12 refreshmobalign'>
                                    <div className='Congrats-leaderbordredirect-iaq'>
                                        <div className='pd-left-iaq-coin arrowiconpointIAQ cursorpoint'>
                                            <span tooltip="Clear Filter" flow="down" >
                                                <img src={RefreshsectorIcon} onClick={this.Discard}/>    
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ovrflowscrll">
                            <div className="row rowcolornews">
                                <div className="col-lg-1 col-1 brdr-main padinheritmob jsftycntrnews">
                                    <div className="news-daysclr plan-daysclr signfee-pr-compare"><span id="sigfont">Rank</span></div>
                                </div>
                                <div className="col-lg-3 col-5 brdr-main padinheritmob">
                                    <div className="news-daysclr plan-daysclr "><span id="sigfont">Name</span></div>
                                </div>
                                {window.matchMedia("(max-width: 650px)").matches ?
                                    <div className="col-lg-3 col-6 brdr-main padinheritmob">
                                        <div className="news-daysclr plan-daysclr"><span id="sigfont">Details</span></div>
                                    </div>
                                :
                                    <>
                                        <div className="col-lg-3 col-3 brdr-main ">
                                            <div className="news-daysclr plan-daysclr"><span id="sigfont">Institution Name</span></div>
                                        </div>
                                        <div className="col-lg-5 col-5 brdr-main ">
                                            <div className="news-daysclr plan-daysclr "><span id="sigfont">Sector</span></div>
                                            
                                        </div>
                                    </>
                                }
                            </div>
                        
                            {this.state.candList.length>0 &&
                                this.state.candList.map((x,i)=>
                                    <div className="row border-board">
                                        <div className="col-lg-1 col-1  brdr-main px-0">
                                            <div className="industext-pricingtable bgnone jsftycntrnews">{x.rank}</div>
                                        </div>
                                        <div className="col-lg-3 col-5  brdr-main px-0">
                                            <div className="content-text-pricing bgnone">{x.Name}</div>
                                        </div>
                                        {window.matchMedia("(max-width: 650px)").matches ?
                                            <>
                                                <div className="col-lg-3  col-6  brdr-main px-0 " onClick={()=>{this.viewdetails(i)}}>
                                                    <div className="content-text-pricing bgnone clr-blu" >{this.state.show == i?'Hide':'View'}</div>
                                                    
                                                </div>
                                                {this.state.show == i && 
                                                    <>
                                                        <div className="  brdr-main px-0">
                                                            <div className="content-text-pricing bgnone">{x.InstituteName}</div>
                                                        </div>
                                                        <div className=" brdr-main px-0">
                                                            <div className="content-text-pricing bgnone">{x.SectorName}</div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                                <div className="col-lg-3  col-3  brdr-main px-0">
                                                    <div className="content-text-pricing bgnone">{x.InstituteName}</div>
                                                </div>
                                                <div className="col-lg-5 col-5 brdr-main px-0">
                                                    <div className="content-text-pricing bgnone">{x.SectorName}</div>
                                                </div>
                                            </> 
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div> */}
                {/* commented for work in progress  */}
            </div>
            <ToastContainer className="toastsubmit"/>
            {this.state.isReqstdemo &&
                <OnlineDemo
                    isFrame={false}
                    Url={""}
                    close={this.closeRequestDemo}
                    Actiontype={this.state.type}
                    isReqstdemo={true}
                    toastMsg={this.toastMessage}
                />
            }
        </div>

    )
}}
export default Newsletter