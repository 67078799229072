import React from "react";
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import { IndustryURL,IndusURL } from "../../Assests/js/config";

import 'react-toastify/dist/ReactToastify.css';
import appleimg from '../../Assests/Images/Appleimg.png'
import Iplanet from '../../Assests/Images/Iplanet.png'
import Requestdomologo from "../../Assests/Images/Enquirymobilelogo.svg"
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import RequestDemo from '../Industry_component/requestdemo-popup'


class Aacte extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReqstdemo:false
        }
    }
    componentDidMount(){
        $('html,body').animate({
            scrollTop: 0
        });
    }

    // popup for Request for demo

OpenRequestDemo = () => {

    this.setState({

        isReqstdemo : true,

     

    })

}



 closeRequestDemo = () => {

   // setisIntern(false)

   this.setState({

    isReqstdemo : false,

    // pop : 0

})

}

 toastMessage=()=>{

    toast.success('Thank you for your request. Our team will contact you soon.', {    

        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        // autoClose: 5555000,
        theme: "colored"

    })

 }

    render(){
        return(
            <div className="Main_container">
                <img src={appleimg} className="appimg"/>
                <div className="row apple-main">
                    <div className="col-lg-6 col-sm-12 col-md-6 apple-flex">
                        <div>
                        {/* <div className="apple-background">
                            <img src={Iplanet} className="iplanetimg"/>
                        </div> */}
                        {/* <div className="store-bold mblviewapple txtalign"><span className="applespan">Apple</span> Authorized</div>
                        <div className="store-bold txtalign">Training Center for Education (AATCe)</div> */}
                        </div>
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="Apple-content">
                    <p className="apple-para"><span className="font-apple-weight">The Apple Authorized Training Center for Education program</span> provides unique benefits to institutions that incorporate Apple’s</p>
                    <p className="apple-para">Develop in Swift offering into their curriculum. The program includes instructor training as well as optional App Development</p>
                    <p className="apple-para_btm">with Swift certification exams for students who demonstrate their ability to build amazing apps.</p>
                    <p className="apple-para">Apple provides everything students need to start designing and coding. With <span className="font-apple-weight">Mac—plus Swift, Xcode, and free developer tools</span></p>
                    <p className="apple-para">from Apple—students have all they need to build incredible apps.</p>
                </div>
                <div className="Apple-content">
                    <p className="apple_heading">Student Certification</p>
                    <p className="apple-para">Help students earn recognition for their knowledge of<span className="font-apple-weight"> Swift and Xcode.</span> App Development with <span className="font-apple-weight">Swift certifications</span> are based</p>
                    <p className="apple-para">on Develop in Swift Explorations and Develop in Swift Fundamentals courses to help students stand out for in-demand jobs in</p>
                    <p className="apple-para">iOS app development.</p>
                    
                </div>
                <div className="rqst-demp-main">
<button className="btn rqst-demo  mobilenone" onClick={()=>{this.OpenRequestDemo()}} >
Enquire Now
</button>
</div>
<div className="mobiledemologo  rqst-demologo "> 
<img src={Requestdomologo} className="" onClick={()=>{this.OpenRequestDemo()}} />
</div>
           
<ToastContainer className="toastsubmit"/>
 {this.state.isReqstdemo &&
 <RequestDemo
 isFrame={false}
 Url={""}
close={this.closeRequestDemo}
Actiontype={"AACTEPolicy"}
 isReqstdemo={true}
 toastMsg={this.toastMessage}
// Industry = {this.state.Industry}
 />}         
           
           
            </div>
        )
    }
    
}
export default Aacte