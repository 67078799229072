import React from "react";
import $ from 'jquery';
class Cookies extends React.Component
{
    componentDidMount(){
        window.$('html,body').animate({
            scrollTop: 0
        }); 
    }
    render(){
        return(
            <div className="section section-common">
            <div className="section-wrapper">
                <div className="regular-pages">
                    <h1 className="h1-regular-format">Cookies Policy</h1>
                    <p className="p-regular-format"><a href="https://www.skillablers.com " target="_blank"
                        style={{color: 'red',textDecoration: 'none'}}>www.skillablers.com </a>("the Website") is owned and operated by EDC Creative Technology Solutions Private Limited. This Cookies Policy, together with our Terms and Conditions, explain
                        how Skillablers and/or EDC Creative Technology Solutions Private Limited may use information we collect about you, as well as your rights over any personal information we hold about you. Please read this policy and our Terms and Conditions
                        carefully; by accessing the Website you confirm to have understood and agreed to them.
                    </p>
                    <p className="p-regular-format">We collect information about you when you:</p>
                    <ul className="ul-regular-format">
                        <li>Visit the Website, and other sites accessible from the Website.</li>
                        <li>Register with and buy products or services on the Website. </li>
                        <li>Take part in promotions, competitions, customer surveys and questionnaires.</li>
                        <li>Contact us eg in writing, call customer services.</li>
                    </ul>
                    <p className="p-regular-format">We may supplement the information we collect about you with information we receive from other sources. This allows us to assess the accuracy of the information we hold about you in order to send you relevant offers and information.</p>
    
                    <h3>Cookies</h3>
                    <p className="p-regular-format">In order to comply with new rules, we use a system of classNameifying the different types of cookies which we use on the Website, or which may be dropped by third parties through our websites. The classNameification was developed by International
                        Bodies and explains more about which cookies we use, why we use them, and the functionality you will lose if you decide you don't want to have them on your device. Please be reassured that we are working on other privacy and cookie-related
                        improvements to the Website.</p>
    
                    <h3>What is a cookies?</h3>
                    <p className="p-regular-format">Cookies are text files containing small amounts of information which are downloaded to your personal computer, mobile or other devices when you visit a website. Cookies are then sent back to the originating website on each subsequent visit,
                        or to another website that recognises that cookie. Cookies are useful because they allow a website to recognise a user's device.</p>
                    <p className="p-regular-format"><span>Persistent Cookies</span> - these cookies remain on a user's device for the period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.</p>
    
                    <p className="p-regular-format"><span>Session Cookies </span>- these cookies allow website operators to link the actions of a user during a browser session. A browser session starts when a user opens the browser window and finishes when they close the browser window.
                        Session cookies are created temporarily. Once you close the browser, all session cookies are deleted.</p>
                    <p className="p-regular-format">Cookies do lots of different jobs, like letting you navigate between pages efficiently, remembering your preferences, and generally improve the user experience. They can also help to ensure that adverts you see online are more relevant
                        to you and your interests.</p>
                    <p className="p-regular-format">You can find more information about cookies at <a href="https://www.allaboutcookies.org" target="_blank" style={{color: 'red',textDecoration: 'none'}}>www.allaboutcookies.org</a>. </p>
                    <h3>Cookies used on the Website</h3>
                    <p className="p-regular-format">A list of all the cookies used on the Website by category is set out below.</p>
                    <ul className="ul-regular-format">
                        <li>
                            <p className="p-regular-format"><span>Strictly necessary Cookies</span></p>
                            <p className="p-regular-format"> These cookies enable services you have specifically asked for. For those types of cookies that are strictly necessary, no consent is required.
                            </p>
                            <p className="p-regular-format">
                                These cookies are essential in order to enable you to move around the Website and use its features, such as accessing secure areas of the Website. Without these cookies services you have asked for, like shopping baskets or e-billing, cannot be provided.
                            </p>
                        </li>
    
                        <li>
                            <p className="p-regular-format"><span>Performance Cookies </span></p>
                            <p className="p-regular-format">These cookies collect anonymous information on the pages visited. By using the Website, you agree that we can place these types of cookies on your device.
                            </p>
                            <p className="p-regular-format">
                                These cookies collect information about how visitors use the Website, for instance which pages visitors go to most often, and if they get error messages from web pages. These cookies don't collect information that identifies a visitor. All information
                                these cookies collect is aggregated and therefore anonymous. It is only used to improve how the Website works.
                            </p>
                        </li>
    
                        <li>
                            <p className="p-regular-format">
                                <span>Functionality Cookies </span></p>
                            <p className="p-regular-format">
                                These cookies remember choices you make to improve your experience. By using the Website, you agree that we can place these types of cookies on your device.
                            </p>
                            <p className="p-regular-format">
                                These cookies allow the Website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personal features. These cookies can also be used to remember changes you have made to text size, fonts
                                and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymised and
                                they cannot track your browsing activity on other websites.
                            </p>
                        </li>
    
                        <li>
                            <p className="p-regular-format"><span> Targeting or advertising Cookies</span></p>
                            <p className="p-regular-format">
                                These cookies collect information about your browsing habits in order to make advertising relevant to you and your interests.
                            </p>
                            <p className="p-regular-format">
                                These cookies are used to deliver adverts more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They are usually placed
                                by advertising networks with our permission. They remember that you have visited a website and this information is shared with other organisations such as advertisers. Quite often targeting or advertising cookies will be linked
                                to site functionality provided by the other organisation.
                            </p>
                        </li>
                    </ul>
                    <h3>Using your information</h3>
                    <p className="p-regular-format">Collecting your personal information helps Skillablers and/or EDC Creative Technology Solutions Private Limited to better understand what you need from us. </p>
    
                    <p className="p-regular-format"> We use your information to:</p>
    
                    <ul className="ul-regular-format">
                        <li>manage and improve the Website.</li>
                        <li>personalise our services to you.</li>
                        <li>administer and operate your account.</li>
                        <li>process your orders.</li>
                        <li>tell you about important changes to the Website and our services.</li>
                        <li>understand your shopping behavior to develop and improve our products and services.</li>
                        <li>manage promotions, competitions, customer surveys and questionnaires.</li>
                        <li>Check and verify your identity, and prevent or detect crime. In performing checks your information may be disclosed to credit reference agencies, who may keep a record of that information. This is not a credit check and your credit
                            rating will be unaffected.</li>
                    </ul>
    
                    <p className="p-regular-format">We may share your personal information across Skillablers and/or EDC Creative Technology Solutions Private Limited so they can provide you with relevant products and services.</p>
                    <p className="p-regular-format">Your personal information is safe with us and will never be released to companies outside Skillablers and/or EDC Creative Technology Solutions Private Limited for their marketing purposes.</p>
                    <p className="p-regular-format">We may use and share anonymised information outside Skillablers and/or EDC Creative Technology Solutions Private Limited. However, we would like to reassure you that this never includes your personal information.</p>
                    <h3>Marketing and research</h3>
                    <p className="p-regular-format">We may contact you:</p>
                    <ul className="ul-regular-format">
                        <li>With offers and information about Skillablers products or services</li>
                        <li>With offers and information about partners' products or services</li>
                        <li>For customer research eg to help improve our services</li>
                    </ul>
                    <p className="p-regular-format">When you register online you give Skillablers and/or EDC Creative Technology Solutions Private Limited access that allows us to tailor our commercial communications to you.</p>
                    <p className="p-regular-format">If you do not want to receive commercial communications from us, please write to us on <a href="mailto:connect@skillablers.com" style={{color: 'red',textDecoration: 'none'}}>connect@skillablers.com.</a></p>
                    <p className="p-regular-format">We like to hear your views to help us improve our service. From time to time, we may contact you to ask your opinions. Again, if you do not want to be contacted for this purpose, please write to us on <a href="mailto:connect@skillablers.com" style={{color: 'red',textDecoration: 'none'}}>connect@skillablers.com.</a></p>
                    <h3>Behavioural advertising and online privacy</h3>
                    <p className="p-regular-format">A guide to behavioural advertising and online privacy has been produced by the internet advertising industry which can be found at <a href="https://www.youronlinechoices.eu/" target="_blank" style={{color: 'red',textDecoration: 'none'}}>www.youronlinechoices.eu.</a></p>
                    <p className="p-regular-format">The guide contains an explanation of the IAB's self-regulatory scheme to allow you greater control of the advertising you see.</p>
    
                    <h3>Using browser settings to manage Cookies</h3>
                    <p className="p-regular-format">The Help menu on the menu bar of most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies altogether. You can also disable
                        or delete similar data used by browser add-ons, such as Flash cookies, by changing the add-on's settings or visiting the website of its manufacturer.</p>
                    <p className="p-regular-format">However, because cookies allow you to take advantage of some of the Website's essential features, we recommend you leave them turned on. For example, if you block or otherwise reject cookies you will not be able to add items to your Shopping
                        Basket, proceed to Checkout, or use any of our products and services that require you to Sign in. If you leave cookies turned on, remember to sign off when you finish using a shared computer.
                    </p>
    
                    <h3>Disclosing your information</h3>
                    <p className="p-regular-format">We will never disclose your information to anyone outside the Skillablers and/or EDC Creative Technology Solutions Private Limited except where we have your consent; where we are required or permitted to do so by law; to other companies
                        who provide a service to us; or any successors in title to our business.</p>
                    <p className="p-regular-format">Whenever we transfer personal information to countries outside India UK and Qatar/Middle East, we will ensure that appropriate security measures are taken.
                    </p>
                    <h3>Other Websites</h3>
                    <p className="p-regular-format">The Website may contain links to other sites which are outside our control and not covered by this policy. The operators of these sites may collect information from you that will be used by them in accordance with their policy, which may
                        differ from ours.</p>
                    <h3>Changes to our policy</h3>
                    <p className="p-regular-format">This policy replaces all previous versions and is correct as of October 2014. We reserve the right to change the policy at any time.</p>
                    <h3>Contacting us</h3>
                    <p className="p-regular-format">If you have any queries, please contact us at <a href="mailto:connect@skillablers.com" style={{color: 'red',textDecoration: 'none'}}>connect@skillablers.com.</a></p>
                </div>
            </div>
        </div>
        )
    }
}
export default Cookies
 