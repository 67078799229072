import React from "react";
import $ from 'jquery';
 
const linkStyle={color: 'red',textDecoration: 'none'}
class Confirmationofpayment extends React.Component
{
    componentDidMount(){
        window.$('html,body').animate({
            scrollTop: 0
        }); 
    }
    render(){
        return(
            <div className="section section-common">
                <div className="section-wrapper">
                    <div className="regular-pages">
                        <h1 className="h1-regular-format">Confirmation of Payment Terms<span className="confr-paymnt"> [As per RBI guidelines]</span></h1>
                        <h3>Online Payments </h3>
                        <p className="p-regular-format">This online payment system is provided by Skillablers Technology Pvt. Ltd. (hereinafter referred to as "Skillablers"). Skillablers may update these terms from time to time and any changes will be effective immediately on being set out here. Please ensure you are aware of the current terms. The country of domicile for Skillablers is India.</p>
                        <p className="p-regular-format">Please read these terms carefully before using the online payment facility.</p>
                        <ul className="ul-regular-format">
                            <li> Using the online payment facility on <a href="https://www.skillablers.com" style={linkStyle}>https://www.skillablers.com</a> (hereinafter referred to as "this website" or "website" or "site") indicates that you accept these <b>Confirmation of Payment Terms [As per RBI guidelines]</b> (hereinafter referred to as "Terms" or "terms"). If you do not accept these terms do not use this facility.</li>
                            <li>Payment(s) through this Service may only be made with a Credit Card, Debit card, UPI or Net Banking.</li>
                            <li>The credit card/ Debit Card information supplied at the time of using the service is processed by the payment gateway of the service provider and is not directly related to Skillablers. Information related to payments using debit or credit card is not accessed or stored by Skillablers.</li>
                            <li>We cannot accept liability for, if payment is refused or declined by the credit/debit card supplier for any reason.</li>
                            <li>It is the sole responsibility of the User of the service to ensure that the information entered in the relevant fields is correct. It is recommended that you take and retain a copy of the transaction for record keeping purposes, which might assist you later in resolution of any disputes that may arise out of usage of the service.</li>
                            <li>The Applicant agrees, understands and confirms that his/ her personal data, including without limitation, details relating to debit card/ credit card/net banking transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that Skillablers or the Payment Service Provider(s) have no control over such matters.</li>
                            <li>If payment is made by means of a card that you do not personally own, the permission of the card owner must always be obtained to make payments using the card. By paying Skillablers, you confirm that you have such permission and Skillablers is in no way bound by your mode of transaction.</li>
                        </ul>
                        <h3>Limitation of Liability</h3>
                        <ul className="ul-regular-format">
                            <li>In no event, Skillablers will be liable for any damage – direct or indirect losses or expenses arising in connection with site.</li>
                            <li>Skillablers may also make additions/deletions/alteration to the services offered, at its sole discretion. Skillablers reserve the right to withdraw/change the service or the payment gateway providers at any time and/or at its own discretion.</li>
                            <li>All Fees quoted in are in Indian Rupees. Skillablers also retain all the rights to change the terms and conditions for Online Payments.</li>					
                        </ul>
                        
                        <h3>Refund Policy</h3>                
                        <ul className="ul-regular-format">
                            <li>If the Customer leaves the Skillablers before they complete their Program, there shall be no entitlement to a refund of Fees.</li>
                            <li>Refunds, if applicable, at the discretion of the Management, will only be made to the debit/credit card used for the original transaction. For the avoidance of doubt, Nothing in this policy shall require Skillablers to refund the Fees (or part thereof) unless such Fees (or part thereof) have previously been paid.</li>                    
                        </ul>
        
                        <h3>Security</h3>                
                        <ul className="ul-regular-format">
                            <li>All payment details which are entered through this payment gateway are encrypted when the Customer, or third party making payment, enters them. Communications to and from the Service Provider's site are encrypted. </li>
                            <li>Skillablers shall not be liable for any failure by the Customer or third party making payment of Fees to properly protect data from being seen on their screen by other persons or otherwise obtained by such persons, during the online payment process or in respect of any omission to provide accurate information in the course of the online payment process</li>                    
                        </ul>
                        
                        <h3>Changes to our Privacy Policy</h3>                
                        <ul className="ul-regular-format">
                            <li>Skillablers reserves the entire right to modify/amend/remove this privacy statement anytime and without any reason. Nothing contained herein creates or is intended to create a contract/agreement between Skillablers and any user visiting the Skillablers website or providing identifying information of any kind.</li>
                        </ul>
                        <p className="p-regular-format3">If you have any questions about this Privacy Policy, please feel free to contact us through our website or write to us at <a href="mailto:connect@skillablers.com" style={linkStyle}>connect@skillablers.com</a></p>
                    </div>
                </div>
        
            </div>
        )
    }
}
export default Confirmationofpayment