const customStyles = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        // color: 'var(--asycSelectDropDownText)',
        // color:"#fff",
        // background: 'var(--asycSelectDropDownBg)',
        background:"#fff",
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: 'var(--whiteClr)',
        background: '#FFF',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "39px" : "39px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '10px 45px 10px 10px' : '10px 45px 10px 10px',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? 'calc(3vh + 20px)' : "39px",
        // width: (window.matchMedia("(min-width: 1824px)").matches) ? '0px' : "89px"
        width:"100%",
        //width: (window.matchMedia("(min-width: 0px)and(max-width: 1824px)").matches) ? '100%': '115%',
        position:"relative",
        left:'0px'
    }),
    input: () => ({
        // position:'relative',
        // left:'1%',
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        //padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '10px 0px 10px 45px',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '10px' : "0px",
        textTransform: "uppercase",
        textAlign:'center',
        width:"100%"
        // marginRight:"50px"
    }),
    loadingMessage: () => ({
        color: '#000',
        background: '#FFF',
        textAlign: "center",
    }),
    menu: () => ({
        color: '#000',
        background: '#fff',
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'relative',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "uppercase"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "2.2vh",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '0px 0px 30px 43px !important',
        textAlign:"center",
       width:"100%",
        textTransform: "uppercase",
        fontWeight:'100'
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
}
export const customStylesWelcome = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: 'var(--whiteClr)',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '2.1px 18px 1.1px 18px',
        border: '1px solid var(--inputborder)',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        //position: 'absolute', //commented by jithin
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: '#000',
        background: '#fff',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "20px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)"
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        color:'#fff',
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
}
export const customStyleHobbiesMulti = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: 'var(--whiteClr)',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '2.7vh 18px' : '7px 0px 1.1px 18px',
        border: '1px solid var(--inputborder)',
        height: 'auto',
        minHeight: (window.matchMedia("(min-width: 1824px)").matches) ? '10vh' : "110px",
        zIndex: 2
    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        // position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '0px' : "13px",
        zIndex: "3"
    }),
}

export const customStylesMultiVerifi = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: 'var(--whiteClr)',
        background: '#fff',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '2.1px 18px 1.1px 18px',
        border: '1px solid var(--inputborder)',
        height: 'auto',
        minHeight: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        //position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        // padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '2.1px 18px 1.1px 18px',
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3",
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '0px' : "13px",
    }),
}

export const customStylesMultiVerification = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: 'var(--whiteClr)',
        background: '#fff',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '2.1px 18px 1.1px 18px',
        border: '1px solid var(--inputborder)',
        height: 'auto',
        minHeight: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: '#000',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        //position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3",
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '0px' : "13px",
    }),
}


export const customStylesMulti = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: 'var(--whiteClr)',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '2.1px 18px 1.1px 18px',
        border: '1px solid var(--inputborder)',
        height: 'auto',
        minHeight: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        //position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3",
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '0px' : "13px",
    }),
}

export const registrationAsyc = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        background: '#1b313e',
        color: '#ffffff',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: '#ffffff',
            color: '#000000',
        }
    }),
    noOptionsMessage: () => ({
        color: '#ffffff',
        background: '#155e77',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: '#ffffff',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        // color: '#ffffff',
        // background: 'transparent',
        // display: 'flex',
        // borderRadius: "15px",
        // border: '1px solid #18799b',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '2.1px 18px 1.1px 18px',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
        // padding:'2.1px 0px 1.1px 15px',
    }),
    input: () => ({
        color: '#ffffff',
        padding: "6px 5px",
        fontSize: "13px",
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: '#ffffff',
        background: '#155e77',
        textAlign: "center",
    }),
    menu: () => ({
        color: '#ffffff',
        background: '#155e77',
        //marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: '#ffffff',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        padding: "6px 0px 6px 5px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
        //fontWeight:"bold"
    }),
    placeholder: () => ({
        color: 'grey',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "14px",
        padding: "6px 5px",
        fontWeight: "bold"
    }),
    control: () => ({
        background: '#1b313e',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: "#1b313e",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px 22px' : '2.1px 18px 1.1px 18px',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        // padding:'2.1px 0px 1.1px 15px',
        display: 'flex',
        zIndex: 2
    }),
    container: () => ({
        marginBottom: "10px",
    }),
    valueContainer: () => ({
        padding: "6px 20px",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        height: "34.5px",
        zIndex: "3"
    })
}

export const isNotSearchable = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: (window.matchMedia("(max-width :  568px)").matches) ? '0px' : '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    // indicatorsContainer: () => ({
    //     display: 'none',
    // }),
    control: () => ({
        color: '#ffffff',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--inputborder)',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '6px  20px' : '2.1px 18px 1.1px 18px',
        // padding:'6.1px 0px 6.1px 15px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px"
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        padding: '5px 0px',
        cursor: 'pointer !important',
        fontSize: "13px",
        textTransform: "capitalize",
        whiteSpace: 'nowrap'
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        whiteSpace: 'nowrap'
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}

export const isNotSearchablewelcome = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        zIndex: 3,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: (window.matchMedia("(max-width :  568px)").matches) ? '0px' : '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    // indicatorsContainer: () => ({
    //     display: 'none',
    // }),
    control: () => ({
        color: '#ffffff',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--inputborder)',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '6px  20px' : '2.1px 18px 1.1px 18px',
        // padding:'6.1px 0px 6.1px 15px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px"
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        padding: '5px 0px',
        cursor: 'pointer !important',
        fontSize: "13px",
        textTransform: "capitalize",
        whiteSpace: 'nowrap'
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "20px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        whiteSpace: 'nowrap'
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}


export const isNotSearchableCTC = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: (window.matchMedia("(max-width :  568px)").matches) ? '0px' : '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    control: () => ({
        color: '#ffffff',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--inputborder)',
        // padding:'5.1px 15px 5.1px 15px',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '13px 20px' : '7.1px 18px 5.1px 18px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        cursor: 'pointer !important',
        fontSize: "13px",
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}
export const cRStype = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: (window.matchMedia("(max-width :  568px)").matches) ?'0px 8px' : '0px 8px 0px 8px',
        //margin: '1px 0px',
        borderRadius: "15px",
        position: "relative",
        top: (window.matchMedia("(min-width: 1824px)").matches) ?"0px": "-3px"
    }),
  
    control: () => ({
        color: 'var(--whiteClr)',
        background: 'var(--textboxbackground)',
        display: 'flex',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--inputborder)',
        // padding:'5.1px 0px 5.1px 15px',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '13px 20px' : '7.1px 0px 4px 18px',
        cursor: 'pointer !important',
        minHeight: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "38px",
        height: 'auto',
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        cursor: 'pointer !important',
        fontSize: "13px",
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.1vh' : '8px',
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
       (window.matchMedia("(max-width: 568px)").matches) ? "4px": "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
       (window.matchMedia("(max-width: 568px)").matches) ? "4px": "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
        (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" :  "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    multiValue: () => ({
        //background: '#20a8d8',
        background:"var(--async-multivalue-bg)",
        display: 'flex',
        marginRight: '2px',
        borderRadius: '5px',
        padding: '2px 7px',
        marginTop: '12px' ,
        marginBottom : '1px'
      }),
      multiValueLabel: () => ({
        color: '#ffffff',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.55vh' : "12px",
        display: 'inline-flex',
        marginRight: '10px',
      }),
      multiValueRemove: () => ({
        color: '#ffffff',
        display: 'inline-flex',
        marginTop: '2px',
        cursor:'pointer'
      })
  }

export const registrationAsycCountry = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        background: '#1b313e',
        color: '#ffffff',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: '#ffffff',
            color: '#000000',
        }
    }),
    noOptionsMessage: () => ({
        color: '#ffffff',
        background: '#155e77',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: '#ffffff',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    // indicatorsContainer: () => ({
    //     display: 'none',
    // }),
    control: () => ({
        // color: '#ffffff',
        // background: 'transparent',
        // display: 'flex',
        // borderRadius: "15px",
        // border: '1px solid #18799b'
        // padding:'2.1px 0px 1.1px 15px',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px 22px' : '2.1px 18px 1.1px 18px',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '49' : "39px",
        zIndex: 2
    }),
    input: () => ({
        color: '#ffffff',
        padding: "6px 5px",
        fontSize: "13px",
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: '#ffffff',
        background: '#155e77',
        textAlign: "center",
    }),
    menu: () => ({
        color: '#ffffff',
        background: '#155e77',
        //marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: '#ffffff',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        padding: "6px 0px 6px 5px",
        fontWeight: "bold",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'grey',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "14px",
        padding: "6px 5px",
        fontWeight: "bold"
    }),
    control: () => ({
        background: '#1b313e',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: "#1b313e",
        // padding:'2.1px 0px 1.1px 15px',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px 22px' : '2.1px 18px 1.1px 18px',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    container: () => ({
        marginBottom: "10px",
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    })
}

export const AdvanceSearchStyle = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    control: () => ({
        color: '#ffffff',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--inputborder)',
        //  padding:'5.1px 15px 5.1px 15px',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px 22px' : '5.1px 15px 5.1px 15px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        cursor: 'pointer !important',
        fontSize: "13px",
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}

export const AdvanceSearchStyle1 = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "11px",
        textTransform: 'capitalize',
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    control: (styles, { isDisabled }) => ({
        color: '#ffffff',
        background: 'var(--sortbydropDown-BgClr)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid rgba(0, 0, 0, 0.2)',
        //  padding:'5px 0px 5px 11px',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px 22px' : '5.1px 15px 5.1px 15px',
        cursor: 'pointer !important',
        // boxShadow: 'var(--filterAccHeadShadow)',
        textTransform: 'capitalize',
        fontSize: "12px",
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "auto",
        opacity: isDisabled ? '0.5' : '1'
    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // cursor: 'pointer !important',
        // fontSize: "13px !important",
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        position: 'absolute',
        textTransform: "capitalize"
        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        position: 'absolute',
        textTransform: "capitalize"
        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}

export const GetnoticeCustomstyle = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: 'var(--whiteClr)',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '2.1px 18px 1.1px 18px',
        border: '1px solid var(--inputborder)',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        padding: '5px 0px',
        fontSize: "13px",
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
}

export const CustomStyleForJobSearch = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: '#ffffff',
        background: 'var(--sortbydropDown-BgClr)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px 7px' : '2.1px 0px 1.1px 7px',
        border: 'none',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        // paddingRight: (window.matchMedia("(max-width: 568px)").matches) ? "35px" : "7px",
        paddingRight: "35px",
        zIndex: 2
    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        textTransform: "capitalize",
        // position: 'absolute',
        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        textTransform: "capitalize",
        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "Capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
}


export const customStyles_Select = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        // padding: '0px 6px 0px 6px',
        // padding: '0px',   
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '0px' : (window.matchMedia("(min-width: 1320px)").matches) ? '0px 8px 10px 8px' : '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),

    // indicatorsContainer: () => ({
    //     paddingBottom: '10px'
    // }),

    control: () => ({
        color: 'var(--whiteClr)',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--inputborder)',
        // padding: (window.matchMedia("(min-width: 1824px)").matches) ? '5px 18px' : (window.matchMedia("(min-height: 1350px)").matches) ? '5px 0px 1.1px 18px' : (window.matchMedia("(max-width: 410px)").matches) ? '6px' : '6px 6px 6px 12px',
        // padding: (window.matchMedia("(max-width: 410px)").matches) ? '6px' : (window.matchMedia("(min-width: 1320px)").matches) ? '10px 0px 1.1px 18px' : (window.matchMedia("(min-height: 1350px)").matches) ? "11px 18px"  : (window.matchMedia("(min-width: 1824px)").matches) ? '5px 18px' : '6px 6px 6px 12px',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '5px 18px' : (window.matchMedia("(min-width: 1320px)").matches) ? '10px 0px 1.1px 18px' : (window.matchMedia("(min-height: 1350px)").matches) ? "11px 18px" : (window.matchMedia("(max-width: 410px)").matches) ? '6px' : '8px 6px 6px 18px',

        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        cursor: 'pointer !important',
        fontSize: "13px",
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize",
    }),

    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),

    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.4vh' : '10px',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.4vh' : '4px',
        paddingLeft: (window.matchMedia("(max-width: 640px)").matches) ? '2px' : 'auto',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: (window.matchMedia("(max-width: 640px)").matches) ? '2px' : '6px',
        textTransform: "capitalize"
    }),

    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        display: "inline-block",
        overflowY: "scroll",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}
export const ProoritySelected = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    // indicatorsContainer: () => ({
    //     display: 'none',
    // }),
    control: () => ({
        color: '#ffffff',
        background: 'var(--InterestCountryBG)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--inputborder)',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '6px  20px' : '2.1px 18px 1.1px 18px',
        // padding:'6.1px 0px 6.1px 15px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        padding: '5px 0px',
        cursor: 'pointer !important',
        fontSize: "13px",
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}

export const customStylesWhiteBg = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        // color: 'var(--asycDropDownIndicator)',
        color: 'var(--textboxbackground)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),

    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: 'var(--whiteClr)',
        background: 'var(--InterestCountryBG)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px  20px' : '2.1px 18px 1.1px 18px',
        border: '1px solid var(--inputborder)',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        //position: 'absolute', //commented by jithin
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px',
        textTransform: "capitalize"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
}
export const isNotSearchableRegisteration = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: '#000',
        padding: (window.matchMedia("(max-width :  568px)").matches) ? '0px' : '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    // indicatorsContainer: () => ({
    //     display: 'none',
    // }),
    control: () => ({
        color: '#000',
        background: '#ffffff',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: 'none',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '6px  20px' : '2.1px 18px 1.1px 18px',
        // padding:'6.1px 0px 6.1px 15px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.7vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        padding: '5px 0px',
        cursor: 'pointer !important',
        fontSize: "13px"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: '#000',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}
export const isNotSearchableCareerTest = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: (window.matchMedia("(max-width :  568px)").matches) ? '0px' : '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    // indicatorsContainer: () => ({
    //     display: 'none',
    // }),
    control: () => ({
        color: '#ffffff',
        background: 'var(--competeecncyBgColr)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--compenectncyBoderClr)',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '6px  20px' : '2.1px 18px 1.1px 18px',
        // padding:'6.1px 0px 6.1px 15px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        zIndex: 2
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        padding: '5px 0px',
        cursor: 'pointer !important',
        fontSize: "13px"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}
export const VideoPopupSTyle = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        //background: 'var(--asycSelectDropDownBg)',
        textAlign: "left",
        color: 'var(--asycSelectDropDownText)',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: 'var(--asycSelectDropDownItemHoverBg)',
            color: 'var(--asycSelectDropDownItemHoverText)',
        }
    }),
    noOptionsMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        //margin: '1px 0px',
        borderRadius: "15px"
    }),

    control: () => ({
        color: '#ffffff',
        background: 'var(--textboxbackground)',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "10px" : "10px",
        border: '1px solid var(--inputborder)',
        // padding:'5.1px 15px 5.1px 15px',
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '13px 20px' : '7.1px 0px 5.1px 15px',
        cursor: 'pointer !important',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '4.9vh' : "39px",
        // boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)"
        zIndex: 2
    }),
    input: () => ({
        color: 'var(--inputTextClr)',
        cursor: 'pointer !important',
        fontSize: "13px"
    }),
    loadingMessage: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        textAlign: "center",
    }),
    menu: () => ({
        color: 'var(--asycSelectDropDownText)',
        background: 'var(--asycSelectDropDownBg)',
        // marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
    }),
    placeholder: () => ({
        color: 'var(--inputHeading)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.48vh' : "12px",
        paddingLeft: '6px',
        textTransform: "capitalize"
    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        position: "relative",
        overflow: "hidden",
        zIndex: "3"
    }),
    indicatorSeparator: () => ({
        display: 'none',
    })
}

export default customStyles;