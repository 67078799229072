const validateEmail = function (req, param, callback) {
    let errors = {};
    let formIsValid = true;

    if (!req) {
        formIsValid = false;
        errors["EmailId"] = "*Please enter your " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (typeof req !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(req)) {
            formIsValid = false;
            errors["EmailId"] = "*Please enter valid " + param + ".";
            errors["formIsValid"] = formIsValid;
        }
        else {
            formIsValid = true;
            errors["formIsValid"] = formIsValid;
        }
    }
    else if (typeof req == "undefined") {
        formIsValid = false;
        errors["formIsValid"] = formIsValid;
    }
    callback(errors);
}

const validateMobile = function (req, param, callback) {
    let errors = {};
    let formIsValid = true;

    if (!req) {
        formIsValid = false;
        errors["MobileNumber"] = "*Please enter your " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (typeof req !== "undefined") {
        if (!req.match(/^([6789]{1})([0-9]{9})$/)) {
            formIsValid = false;
            errors["MobileNumber"] = "*Please enter valid " + param + ".";
            errors["formIsValid"] = formIsValid;
        }
        else {
            formIsValid = true;
            errors["formIsValid"] = formIsValid; 
        }
    }
    else if (typeof req == "undefined") {
        formIsValid = false;
        errors["formIsValid"] = formIsValid;
    }
    callback(errors);
}

const ValidateRequired = function (req, param, callback) {
    let errors = {};
    let formIsValid = true;
    
    if (!req) {
        formIsValid = false;
        errors["RequiredField"] = "*Please enter " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (!req.toString().replace(/\s/g, '').length) {
        formIsValid = false;
        errors["RequiredField"] = "*Please enter " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (typeof req == "undefined") {
        formIsValid = false;
        errors["RequiredField"] = "*Please enter " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else {
        formIsValid = true;
        errors["formIsValid"] = formIsValid;
    }
    callback(errors);
}

const ValidateRequiredradio = function (req, param, callback) {
    let errors = {};
    let formIsValid = true;
    
    if (!req) {
        formIsValid = false;
        errors["RequiredField"] = "*Please select " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (!req.toString().replace(/\s/g, '').length) {
        formIsValid = false;
        errors["RequiredField"] = "*Please select " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (typeof req == "undefined") {
        formIsValid = false;
        errors["RequiredField"] = "*Please select " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else {
        formIsValid = true;
        errors["formIsValid"] = formIsValid;
    }
    callback(errors);
}

const ValidateRequiredLength = function (req, param,param_minlength,param_maxlength, callback) {
    let errors = {};
    let formIsValid = true;

    if (req.length !== param_maxlength) {
        formIsValid = false;
        if (param_minlength === 0) {
            errors["RequiredField"] = "* " + param + " Length Should Be " + param_maxlength + ".";
        }
        else {
            errors["RequiredField"] = "* " + param + " Length Should Be between " + param_minlength + " and " + param_maxlength + ".";
        }
        errors["formIsValid"] = formIsValid;
    }
    else {
        formIsValid = true;
        errors["formIsValid"] = formIsValid;
    }
    if(formIsValid){
        if (param === "Driving License") {
            var pattern = new RegExp(/^[A-Z]{2}[0-9]{6,13}|[0-9]{8,15}/i);
            if (!pattern.test(req)) {
                formIsValid = false;
                errors["RequiredField"] = "*Please enter valid " + param + ".";
                errors["formIsValid"] = formIsValid;
            }
            else {
                formIsValid = true;
                errors["formIsValid"] = formIsValid;
            }
        }
        if (param === "Passport Number") {
            var pattern = new RegExp(/^[A-Z]{1}[0-9]{7}/i);
            if (!pattern.test(req)) {
                formIsValid = false;
                errors["RequiredField"] = "*Please enter valid " + param + ".";
                errors["formIsValid"] = formIsValid;
            }
            else {
                formIsValid = true;
                errors["formIsValid"] = formIsValid;
            }
        }
        if (param === "PAN Card Number") {
            var pattern = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/i);
            if (!pattern.test(req)) {
                formIsValid = false;
                errors["RequiredField"] = "*Please enter valid " + param + ".";
                errors["formIsValid"] = formIsValid;
            }
            else {
                formIsValid = true;
                errors["formIsValid"] = formIsValid;
            }
        }
        if (param === "Voter ID") {
            var pattern = new RegExp(/^[A-Z0-9]{10}/i);
            if (!pattern.test(req)) {
                formIsValid = false;
                errors["RequiredField"] = "*Please Enter Valid " + param + ".";
                errors["formIsValid"] = formIsValid;
            }
            else {
                formIsValid = true;
                errors["formIsValid"] = formIsValid;
            }
        }
    }
    callback(errors);
}

const ValidateDDLRequired = function (req, param, callback) {

    let errors = {};
    let formIsValid = true;
    if (req.length === 0) {
        formIsValid = false;
        errors["DDLRequiredField"] = "*Please select " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (req === 0) {
        formIsValid = false;
        errors["DDLRequiredField"] = "*Please select " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (typeof req === "undefined") {
        formIsValid = false;
        errors["DDLRequiredField"] = "*Please Select " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else {
        formIsValid = true;
        errors["formIsValid"] = formIsValid;
    }
    callback(errors);
}

const OnlyNumbers = (e) => {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
    }
}
const OnlyAlphabets = (e) => {
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 33 && charCode <= 45) || (charCode >= 47 && charCode <= 64) || (charCode >= 91 && charCode <= 96) || (charCode >= 123 && charCode <= 126)) {
        e.preventDefault();
    }
}
const OnlyAlphaNumeric = (e) => {
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 33 && charCode <= 35) || (charCode >= 36 && charCode <= 44) || (charCode >= 45 && charCode <= 47) || charCode == 187 || (charCode >= 58 && charCode <= 64) || (charCode >= 91 && charCode <= 96) || (charCode >= 123 && charCode <= 126)) {
        e.preventDefault();
    }
}
const OnlyAlphaNumericHyphenUnderscore = (e) => {
    const charCode = (e.which) ? e.which : e.keyCode;
    if((charCode !== 8) && (charCode !== 32) && (charCode !== 45) && !(charCode >= 48 && charCode <= 57) && !(charCode >= 65 && charCode <= 90) && (charCode !== 95) && !(charCode >= 97 && charCode <= 122) && (charCode !== 127))
    {
        e.preventDefault();
    }
}

const ValidateNewPassword = function (req, callback) {
    console.log(req);
    let errors = {};
    let formIsValid = true;
    const passwordvalid = /(?=.{8,20})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=]).*/;
    const specials = /[\*\|\"\:\.\!\,\<\>\[\]\{\} \`\\\(\)\'\;\/]/;
    if (!req) {
        formIsValid = false;
        errors["PasswordField"] = "*Password should contain at least 1 Uppercase, 1 lowercase,any 1 special character from @#$%^&+= and at least 1 digit.";
        errors["formIsValid"] = formIsValid;
    }
    else if (!req.toString().replace(/\s/g, '').length) {
        formIsValid = false;
        errors["PasswordField"] = "*Password should contain at least 1 Uppercase, 1 lowercase,any 1 special character from @#$%^&+= and at least 1 digit.";
        errors["formIsValid"] = formIsValid;
    }
    else if (req.toString().replace(/\s/g, '').length < 8 || req.toString().replace(/\s/g, '').length > 20) {
        formIsValid = false;
        errors["PasswordField"] = "*Password length should be minimum 8 and maximum 20.";
        errors["formIsValid"] = formIsValid;
    }
    else if(specials.test(req.toString())){
        formIsValid = false;
        errors["PasswordField"] = "*Please remove special character other than @#$%^&+= from the given password.";
        errors["formIsValid"] = formIsValid;
    }
    else if (req.match(passwordvalid)) {        
        formIsValid = true;
        errors["PasswordField"] = "";
        errors["formIsValid"] = formIsValid;
    }
    else {
        formIsValid = false;
        errors["PasswordField"] = "*Password should contain at least 1 Uppercase, 1 lowercase,any 1 special character from @#$%^&+= and at least 1 digit.";
        errors["formIsValid"] = formIsValid;
    }
    console.log(errors);
    callback(errors);
}

const ValidateUrl= function (req,param,callback){
    let errors = {};
    let formIsValid = true;

    if (!req) {
        formIsValid = false;
        errors["Url"] = "*Please enter your " + param + ".";
        errors["formIsValid"] = formIsValid;
    }
    else if (typeof req !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);

        if (!pattern.test(req)) {
            formIsValid = false;
            errors["Url"] = "*Please enter valid " + param + ".";
            errors["formIsValid"] = formIsValid;
        }
        else {
            formIsValid = true;
            errors["formIsValid"] = formIsValid;
        }
    }
    else if (typeof req == "undefined") {
        formIsValid = false;
        errors["formIsValid"] = formIsValid;
    }
    callback(errors);
}

export {
    validateEmail,
    validateMobile,
    ValidateRequired,
    ValidateRequiredradio,
    ValidateDDLRequired,
    ValidateRequiredLength,
    OnlyNumbers,
    OnlyAlphabets,
    OnlyAlphaNumeric,
    OnlyAlphaNumericHyphenUnderscore,
    ValidateNewPassword,
    ValidateUrl
};