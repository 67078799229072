import React from 'react';
import { NavLink , BrowserRouter } from "react-router-dom";
import { Routing } from "../../Assests/js/routing";
import history from "../../histoty";
import EDClogofullcurved02C from '../../Assests/Images/EDC_logo_full_curved-02C.png';
import $ from "jquery"
import { HashRouter , Link } from 'react-router-dom';
const NOOP =()=>{}
function Menu({toggle = NOOP}){
    const RoutingTo = (location) => {
        window.open(window.location.origin + location, '_self');
    }
    const  AutoScroll = ()=>{
        // $('html,body').animate({
        //     scrollTop: 0
        // });
        let scrolling = $("#dfooter").offset().top
        $('html,body').animate({
            scrollTop: scrolling
        },
            'slow');
            toggle(1)
            window.footer.relo();
    }
    const AutoClose=()=>{
        document.getElementById("header").classList.remove('transparent-backgror')
        toggle(1)
    }
    const OpenYouTube=()=>{
        window.open("https://www.youtube.com/channel/UCZx7OXHcq8YOl_-95SFZAaA",'_blank')
    }
    const Openfacebook =()=>{
        window.open("https://www.facebook.com/skillablers/",'_blank')
    }
    const Opentwitter=()=>{
        window.open("https://twitter.com/SkillablersTech",'_blank')
    }
    const OpenLinkedIN=()=>{
        window.open("https://www.linkedin.com/company/skillablers",'_blank')
    }
    const OpenInstagram=()=>{
        window.open("https://www.instagram.com/skillablers/",'_blank')
        if (document.getElementById("cont") !== undefined) {
            document.getElementById("cont").classList.add("mhide");
        }
    }
    return(
        <HashRouter>
            <ul className="nav__menu-lists nav__menu--1-lists navarea-dropdown menu-frombanner">
                <li className="menu-dts-data" >
                    <i className="fa fa-window-close" aria-hidden="true"></i> 
                    <NavLink onClick={AutoClose} to={Routing.Home}  activeClassName="active" className={"menuanchor"}>Home</NavLink>
                    {/* <a className={(window.location.pathname === Routing.Home  || window.location.pathname === '/')? "menuanchor active" : "menuanchor"} onClick={() => { RoutingTo(Routing.Home) }}  >Home</a> */}
                </li>
                <li className="menu-dts-data" >
                    <NavLink onClick={AutoClose}  to={Routing.threeIconnect} NavLinkactiveClassName="active" className={"menuanchor"}>3-I Connect</NavLink>
                    {/* <a NavLinkactiveClassName="active" className={"menuanchor"}  onClick={() => { RoutingTo(Routing.Individual) }} >Individuals</a> */}
                </li>
                {/* <li className="menu-dts-data" >
                <NavLink onClick={AutoClose}  to={Routing.Industry} NavLinkactiveClassName="active" className={"menuanchor"}  >Industries</NavLink>
                    {/* <a NavLinkactiveClassName="active" className={"menuanchor"}  onClick={() => { RoutingTo(Routing.Industry) }}>Industries</a> */}
                {/* </li>
                <li className="menu-dts-data" >
                <NavLink  onClick={AutoClose}  to={Routing.Institute} NavLinkactiveClassName="active" className={"menuanchor"}  >Institutions</NavLink>
                </li>  */}
                <li className="menu-dts-data" >
                    <NavLink onClick={AutoClose}  to={Routing.rediness}  NavLinkactiveClassName="active" className={"menuanchor"}   >Industry Readiness Primer</NavLink>
                    {/* <a NavLinkactiveClassName="active" className={"menuanchor"}  onClick={() => { RoutingTo(Routing.rediness) }}>Industry Readiness</a> */}
                </li>
                <li className="menu-dts-data" >
                    <NavLink  onClick={AutoClose}  to={Routing.ycc}  NavLinkactiveClassName="active" className={"menuanchor"}  >SKL YCC 2022</NavLink>
                    {/* <a NavLinkactiveClassName="active" className={"menuanchor"}  onClick={() => { RoutingTo(Routing.ycc) }} >YCC</a> */}
                </li>
                <li className="menu-dts-data" >
                    <NavLink  onClick={AutoClose}  to={Routing.OurTeam}NavLinkactiveClassName="active" className={"menuanchor"}  >Meet our Team</NavLink>
                    {/* <a NavLinkactiveClassName="active" className={"menuanchor"}  onClick={() => { RoutingTo(Routing.OurTeam) }}>Our Team</a> */}
                </li>
                <li className="menu-dts-data Connectlink" onClick={AutoScroll}>
                    <a className="menuanchor5">Connect</a>
                </li>
                <div className="divfooterrol menu-section-undeline">
                    <div className="d-flex-col menu-footerr-conatiner">
                        <ul className="menu-socila-media ">
                            <li onClick={Openfacebook} className="social-btns no-margin-left"> <i className="fa fa-facebook-f"></i></li>
                            <li onClick={Opentwitter} className="social-btns"><i className="fa fa-twitter"></i></li>
                            <li onClick={OpenLinkedIN} className="social-btns"><i className="fa fa-linkedin"></i></li>
                            <li onClick={OpenInstagram} className="social-btns"> <i className="fa fa-instagram"></i></li>
                            <li onClick={OpenYouTube} className="social-btns"><i className="fa fa-youtube"></i></li>
                        </ul>
                        {/* <div className="edclos" onclick="hide()">
                            <img src={EDClogofullcurved02C} alt="" style={{ width: '80px' }} onclick="hide()" />
                            <span id="gp">Group</span>
                            </div> */}
                    </div>
                </div>
        </ul>
        </HashRouter>
    )
}

export default Menu
