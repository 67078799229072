import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";



export default function ChaloExam(){
    const [showLoader ,setShowLoader] = useState(true)
    // useEffect(()=>{
    //     customElements.define('x-frame-bypass', class extends HTMLIFrameElement {
    //         static get observedAttributes() {
    //             return ['src']
    //         }
    //         constructor() {
    //             super()
    //         }
    //         attributeChangedCallback() {
    //             this.load(this.src)
    //         }
    //         connectedCallback() {
    //             this.sandbox = '' + this.sandbox || 'allow-forms allow-modals allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation' // all except allow-top-navigation
    //         }
    //         load(url, options) {
    //             if (!url || !url.startsWith('http'))
    //                 throw new Error(`X-Frame-Bypass src ${url} does not start with http(s)://`)
    //             console.log('X-Frame-Bypass loading:', url)
    //             this.srcdoc = `<html>
    //     <head>
    //         <style>
    //             iframe{
    //                 display: block;
    //         width: calc(100% - 0px);
    //         height: calc(100% - 0px);
    //         margin: 0px;
    //         border: 0px;
    //             }
    //         .loader {
    //             position: absolute;
    //             top: calc(50% - 25px);
    //             left: calc(50% - 25px);
    //             width: 50px;
    //             height: 50px;
    //             background-color: #333;
    //             border-radius: 50%;  
    //             animation: loader 1s infinite ease-in-out;
    //         }
    //         @keyframes loader {
    //             0% {
    //             transform: scale(0);
    //             }
    //             100% {
    //             transform: scale(1);
    //             opacity: 0;
    //             }
    //         }
    //         </style>
    //     </head>
    //     <body>
    //         <div class="loader"></div>
    //     </body>
    //     </html>`
    //             this.fetchProxy(url, options, 0).then(res => res.text()).then(data => {
    //                 console.log('data', data)
    //                 if (data)
    //                     this.srcdoc = data.replace(/<head([^>]*)>/i, `<head$1>
    //         <base href="${url}">`)
        
    //                 console.log('this.srcdoc', this.srcdoc)
    //             }).catch(e => console.error('Cannot load X-Frame-Bypass:', e))
    //         }
    //         fetchProxy(url, options, i) {
    //             console.log('url, options, i', url, options, i)
    //             const proxies = (options || {}).proxies || [
    //                 'https://cors-anywhere.herokuapp.com/',
    //                 'https://yacdn.org/proxy/',
    //                 'https://api.codetabs.com/v1/proxy/?quest='
    //             ]
    //             return fetch(proxies[i] + url, options).then(res => {
    //                 if (!res.ok)
    //                     throw new Error(`${res.status} ${res.statusText}`);
    //                 return res
    //             }).catch(error => {
    //                 if (i === proxies.length - 1)
    //                     throw error
    //                 return this.fetchProxy(url, options, i + 1)
    //             })
    //         }
    //     }, { extends: 'iframe' })
    // })
    useEffect(()=>
    {
        setTimeout(() => {

            setShowLoader(false)

        }, 5000);

    })
    return(
        <div className="if-chalo-wt-ht ">
        <div className="po-rel-chal0">
        {showLoader?
        <div className="load-alignment chalo-loader">
        <PuffLoader  color="#0572aa" />                                                    
        </div>
        :<></>
        }
        </div>
        
       	<iframe 
        // is="x-frame-bypass"   
        src="https://chaloexam.com/"></iframe>
        </div>
    )
}