
import { faThermometerThreeQuarters, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {OnlyNumbers, OnlyAlphaNumeric, validateEmail, validateMobile, ValidateRequired,ValidateDDLRequired} from '../General/validation';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import Customstyles from '../General/custom-style'
import {GetAllPincode, GetAllCollege, SaveInstituteEnquiry, CheckUserMob, CheckUsermail} from './Institute-methods'
import {AutoGenerateCode, ToSendOTP, ToVerifyOTP, sendMail} from '../General/general-methods'
import { ToastContainer, toast } from 'react-toastify';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';
import base64 from 'base-64'

const Input = (props) => <components.Input {...props} isHidden={false} />;

const NOOP = () => { }

function Modal({ isFrame, Url, close = NOOP, isIntern, Industry}) {
    
    const [url , seturl] = useState([
        'https://www.youtube.com/embed/vg3y_UOKo7M',
        'https://www.youtube.com/embed/LiyDiRnPS84',
        'https://www.youtube.com/embed/m85MeVY50m8',
        'https://www.youtube.com/embed/yywIJC_NL7Q',
        'https://www.youtube.com/embed/ePIHSBkoRcg',
        'https://www.youtube.com/embed/7BXzQhSs1OY',
        'https://www.youtube.com/embed/az9GHhyf6eA'
    ])

    const [OpenDelay, setOpenDelay] = useState(false)

    const [Intern , setIntern] = useState({
        College : [],
        InsType : '',
        street : '',
        pincode : '',
        District : '',
        State : '',
        Name : '',
        email: '',
        mobile : '',
        MobileReferenceCode: '',
        IsEmailVerified : false,
        IsMobileVerified: false,
        SentToApproval:false,
        showOtp:false,
        onceOtpVerfied:'',
        isMobileNumberExists: 0,
        errors: [],
        CollegeInput : ''
    })

    useEffect(() => {
        setTimeout(() => {
            setOpenDelay(true)
        }, 200);
       window. $('.modal-overlay').click(function(e) {
            if (!window.$(e.target).closest('.modal-box').length ||  !window.$(e.target).closest('.svg-play-icons').length){
                closPopup()
            }
        });
    },[])

    const getCollegeOptions = (input) => {       
        if (input.length > 1) {            
            return GetAllCollege(input, "")                
            .then((res) => {                   
                let result = res;                    
                return result;                
            })                
            .catch(function (error) {});        
        }   
    }

    const getPinOptions = (input) => {  
        if (input.length > 1) {             
            return GetAllPincode(input)                
            .then((res) => {                   
                let result = res;                    
                return result;               
            })                
            .catch(function (error) {}); 
        }         
    }

    const ShowInsTypeContents=(selectedcontent)=>{
        setIntern({
            ...Intern,
            InsType : selectedcontent
        })
    }

    const closPopup = () => {
        setOpenDelay(false)
        setIntern({
            College : [],
            InsType : '',
            street : '',
            pincode : '',
            District : '',
            State : '',
            Name : '',
            email: '',
            mobile : '',
            MobileReferenceCode: '',
            IsEmailVerified : false,
            IsMobileVerified: false,
            SentToApproval:false,
            showOtp:false,
            onceOtpVerfied:'',
            isMobileNumberExists: 0,
            errors: [],
            CollegeInput : ''
        })
        setTimeout(() => {
            close(true)
        }, 200);
    }

    const handleChange = e =>{
        setIntern({
            ...Intern,
            [e.target.name]:e.target.value 
        })
    }

    const ValidateIsEmpty = (name, label) => {
        let flag = true;
        let errors = Intern.errors
        ValidateRequired(Intern[name], label, function (err) {
            errors[name] = err.RequiredField;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const validatEmail = async(label) =>{
        let flag = true;
        let errors = Intern.errors
        validateEmail(Intern["email"], label, function (err) {
            errors["email"] = err.EmailId;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const ValEmailIsExist = async () => {
        let errors = Intern.errors;
        let flag = false;
        let res = await validatEmail('Email Id');
        var res1 = await ValidateCollege('College');
        let Isexists = 0; 
        let isEmailIdExists = 0;
        if(res1 === true){
            if (res === true) {
                CheckUsermail(Intern.email,Intern.College.label).then(result => {
                    Isexists = result.recordsets[0][0].OUTPUT;
                    if (Isexists === "1") {
                        flag = false;
                        errors["email"] = '*Institute Contact Details Exists.';
                        isEmailIdExists = 1
                    } else {
                        flag = true;
                        errors["email"] = ''
                        isEmailIdExists = 0
                    }
                })
                setIntern({
                    ...Intern,
                    isEmailIdExists:isEmailIdExists,
                    errors: errors
                });
            }
        }else{
            flag = false;
            errors["email"] = '*Please enter the College';
            setIntern({
                ...Intern,
                errors: errors,
                isEmailIdExists:isEmailIdExists
            });
        }
        return flag
    }

    const Validatephone = async(label) =>{
        let flag = true;
        let errors = Intern.errors
        validateMobile(Intern["mobile"], label, function(err){
            errors["mobile"] = err.MobileNumber;
            flag = err.formIsValid;
        })
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const ValMobileNoIsExist = async() => {
        let errors = Intern.errors;
        let IsMobileVerified = Intern.IsMobileVerified;
        let isMobileNumberExists = 0
        let flag = false;
        let showOtp = false;
        var res = await Validatephone('mobile');
        var res1 = await ValidateCollege('College');
        if(res1 === true){
            if (res === true) {
                CheckUserMob(Intern.mobile, Intern.College.label).then(result => {
                    let Isexists = result.recordsets[0][0].OUTPUT;
                    if (Isexists === "1") {
                        flag = false;
                        errors["mobile"] = '*Institute Contact Details Exists.';
                        IsMobileVerified = true;
                        isMobileNumberExists = 1;
                    }else{
                        flag = true;
                        errors["mobile"] = '';
                        errors["OTP"] = '';
                        IsMobileVerified = Intern.onceOtpVerfied === Intern.mobile ? true : false ;
                        showOtp = true;
                    }
                    setIntern({
                        ...Intern,
                        errors: errors,
                        isMobileNumberExists: isMobileNumberExists,
                        IsMobileVerified: IsMobileVerified,
                        showOtp:showOtp
                    })
                })
            }else {
                setIntern({
                    ...Intern,
                    isMobileNumberExists: isMobileNumberExists,
                    IsMobileVerified: false
                });
            }
        }else{
            flag = false;
            errors["mobile"] = '*Please enter the College';
            setIntern({
                ...Intern,
                errors: errors,
                isMobileNumberExists: isMobileNumberExists,
                IsMobileVerified: false
            });
        }
        return flag
    }

    const ValidateCollege = async(label) => {
        let flag = true;
        let errors = Intern.errors
        if (Intern.College !== null) {
            ValidateDDLRequired(Intern[label], 'College', function (err) {
                errors["College"] = err.DDLRequiredField;
                flag = err.formIsValid;
            });
        }
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const Validatepincode = (label) => {
        let flag = true;
        let errors = Intern.errors
        if (Intern.pincode !== null) {
            ValidateDDLRequired(Intern[label], 'Pincode', function (err) {
                errors["pincode"] = err.DDLRequiredField;
                flag = err.formIsValid;
            });
        }
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const ValidateInsType = () =>{
        let flag = true;
        let errors = Intern.errors
        if(Intern.InsType === ""){
            errors["InsType"] = "*Please Select Institute Type"
            flag = false;
        }else{
            errors["InsType"] = ""
            flag = true;
        }
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const SendOTP = () => {
        if (Intern.isMobileNumberExists === 0) {
            let MobileReferenceCode = AutoGenerateCode(Intern.MobileReferenceCode);
            if (MobileReferenceCode !== "") {
                let MobNo = Intern.mobile;
                ToSendOTP(MobNo, MobileReferenceCode)
                .then(result => {
                    if (result.Status === "Success") {
                        toast.success("Sent OTP Successfully.", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setIntern({
                            ...Intern,
                            MobileReferenceCode: MobileReferenceCode
                        })
                    } else {
                        toast.error("Sending OTP Failed.", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
            }
        }
    }

    const Verify = () => {
        let flag = true;
        let IsMobileVerified = false;
        ToVerifyOTP(Intern.OTP)
            .then(result => {
                toast.dismiss();
                if(result.Status !== undefined && result.Status === "Success") {
                    toast.success("Mobile number verification completed successfully.", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    IsMobileVerified = true
                    setIntern({
                        ...Intern,
                        IsMobileVerified: IsMobileVerified,
                        onceOtpVerfied: Intern.mobile
                    });
                } 
                else {
                    toast.error("Incorrect OTP.", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    IsMobileVerified = false
                    setIntern({
                        ...Intern,
                        IsMobileVerified: IsMobileVerified
                    });
                }
            })
        return flag;
    }

    const ValidateIntern=async()=>{
        let a = await ValidateCollege('College')
        let b = await ValidateInsType()
        let c = await ValidateIsEmpty('street', 'street')
        let d = await Validatepincode('pincode')
        let e = await ValidateIsEmpty('District', 'District')
        let f = await ValidateIsEmpty('State', 'State')
        let g = await ValidateIsEmpty('Name', 'Name')
        let h = await validatEmail('Email Id')
        let i = await Validatephone('mobile')
        let j = await ValidateIsEmpty('OTP', 'OTP')
        if( a=== true && b === true && c === true && d === true && e===true && f===true && g===true && h===true && i===true && j===true && Intern.IsMobileVerified){
            return true;
        }
        else{
            return false
        }
    }

    const SaveIntern = async()=>{
        let val = await ValidateIntern()
        if(val){
            let EntityTypeId = 3;
            let EntityTypeDescriptionID = 5;
            let InstitutionType = (Intern.InsType === 'grou')? 2 : 1;
            let MSTCollegeId = Intern.College.value;
            let InstituteName = Intern.College.label;
            let StausOfPartners = '';
            let SeniorExecutive = Intern.Name;
            let ExecutiveDesignation = '';
            let EmailId = base64.encode(Intern.email);
            let MobileNumber = base64.encode(Intern.mobile);
            let WhatsAppNumber = '';
            let AlternateEmailId = '';
            let MobileReferenceCode = Intern.MobileReferenceCode;
            let Password = '';
            let Website = '';
            let ContactPersonName = '';
            let ContactPersonEmailId = '';
            let ContactPersonMobileNumber = '';
            let ContactPersonWhatsappNumber = '';
            let IsEmailVerified = Intern.IsEmailVerified;
            let IsMobileVerified = Intern.IsMobileVerified;
            let PincodeId = Intern.pincode.value;
            let CityId = Intern.pincode.CityId
            let DistrictId = Intern.pincode.DistrictId;
            let StateId = Intern.pincode.StateId;
            let CountryId = Intern.pincode.CountryId;
            let Pincode = Intern.pincode.Pincode;
            let CityName = Intern.pincode.CityName;
            let DistrictName = Intern.pincode.DistrictName;
            let StateName = Intern.pincode.StateName;
            let CountryName = Intern.pincode.CountryName;
            let BuildingName = '';
            let Street = Intern.street;
            let LandMark = '';
            let CommunicationPincodeId = 0;
            let CommunicationCityId = 0;
            let CommunicationDistrictId = 0;
            let CommunicationStateId = 0;
            let CommunicationCountryId = 0;
            let CommunicationPincode = 0;
            let CommunicationCityName = '';
            let CommunicationDistrictName = '';
            let CommunicationStateName = '';
            let CommunicationCountryName = '';
            let CommunicationBuildingName = '';
            let CommunicationStreet = '';
            let CommunicationLandMark = '';
            let IsReviewedAgreement = '';
            let GSTCode = '';
            let ReviewValidityStarts = '';
            let DesignatedPartnerName = '';
            let DesignatedPartnerUID = '';
            let DesignatedDistrictId = '';
            let DesignatedStateId = '';
            let SentToApproval = Intern.SentToApproval;
            SaveInstituteEnquiry(
                EntityTypeId, EntityTypeDescriptionID, InstitutionType, MSTCollegeId, InstituteName, StausOfPartners, 
                SeniorExecutive, ExecutiveDesignation, EmailId, MobileNumber, WhatsAppNumber, AlternateEmailId, 
                MobileReferenceCode, Password, Website, ContactPersonName, ContactPersonEmailId, ContactPersonMobileNumber, 
                ContactPersonWhatsappNumber, IsEmailVerified, IsMobileVerified, PincodeId, CityId, DistrictId, StateId, 
                CountryId, Pincode, CityName,DistrictName, StateName, CountryName, BuildingName, Street, LandMark, 
                CommunicationPincodeId, CommunicationCityId, CommunicationDistrictId, CommunicationStateId,CommunicationCountryId,
                CommunicationPincode, CommunicationCityName, CommunicationDistrictName, CommunicationStateName, CommunicationCountryName, 
                CommunicationBuildingName, CommunicationStreet, CommunicationLandMark, IsReviewedAgreement, GSTCode, ReviewValidityStarts,
                DesignatedPartnerName, DesignatedPartnerUID, DesignatedDistrictId, DesignatedStateId, SentToApproval
            ).then(result=>{
                toast.success("Thanks For Showing intrest, we will get back to you shorly.", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                closeAfterDelay()
            })
        }
        const closeAfterDelay=async()=>{
            setTimeout(() =>{closPopup()},5000)
        }
    }
    
    const OnChangeSelect = selectedOptions =>{
        setIntern({
            ...Intern,
            College : selectedOptions
        })
    }

    const OnChangeSelectPin = selectedOptions =>{
        setIntern({
            ...Intern,
            pincode : selectedOptions,
            District : selectedOptions.DistrictName,
            State : selectedOptions.StateName
        })
    }

    const CollegeInputChange = (inputValue, { action }) => {
        if (action === "input-change") {
            if (inputValue.trim() !== "") {
                setIntern({
                    ...Intern,
                    College :  { value: 0, label: inputValue }
                })
                return inputValue
            }
            else {
                setIntern({
                    ...Intern,
                    College :  []
                })
                return ''
            }
        }
    }

    const PinCodeInputChange = (inputValue, { action }) => {
        if (action === "input-change") {
            if (inputValue.trim() !== "") {
                setIntern({
                    ...Intern,
                    pincode :  { value: 0, label: inputValue }
                })
                return inputValue
            }
            else {
                setIntern({
                    ...Intern,
                    pincode :  []
                })
                return ''
            }
        }
    }
    return (
        <>
            <div className='modal-overlay'></div>
            <div className={OpenDelay ? isFrame === false? 'modal-box active mi-wdth' : 'modal-box active trans-background' :
                isFrame === false?  'modal-box mi-wdth': 'modal-box trans-background'}>
                <div className='modal-close' onClick={closPopup}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                {isFrame ?
                    <iframe className='modal-iframe' src={url[Url]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    :
                    <>
                        <div className='intern-modal'>CONTACT US</div>
                        <div className='iner-datasection'>Provide your details and we will connect with you shortly.</div>
                        <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                            <AsyncSelect
                                cacheOptions                               
                                defaultOptions                                
                                loadOptions={getCollegeOptions}                                
                                isClearable={true}
                                name={'College'}
                                placeholder={'College'}
                                value={Intern.College}
                                inputValue = {(Intern.College === null || Intern.College.label === undefined) ? '' :Intern.College.label }
                                styles={Customstyles}
                                controlShouldRenderValue={false}                               
                                components={{                                    
                                    Input                               
                                }}
                                onChange={OnChangeSelect}
                                onInputChange ={CollegeInputChange}
                                onBlur={()=>{ValidateCollege('College')}}
                            />
                            <span className='error'>{Intern.errors["College"]}</span>
                        </div>
                        <div className='col-lg-12 col-md-12 col-12 row padding-bottom-one-vh padd-left'>
                            <div className="col-lg-6 col-md-5">
                                <input className="mr-2" type="radio" id={"sing"} onClick={(e) => ShowInsTypeContents("sing")} name="radio-group-InsType" />
                                <label className="Custom-radio label-font" htmlFor={"sing"}>Single Institution</label>
                            </div>
                            <div className="col-lg-6 col-md-5">
                                <input className="label-dis" type="radio" id={"grou"} onClick={(e) => ShowInsTypeContents("grou")} name="radio-group-InsType" />
                                <label className="Custom-radio label-font" htmlFor={"grou"}>Group of Institutions</label>
                            </div>
                            <span className='error'>{Intern.errors["InsType"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <input 
                                type="text" 
                                className="input-reg" 
                                placeholder="Street" 
                                value={Intern.street} 
                                name='street' 
                                onChange={handleChange}
                                onBlur={()=>{ValidateIsEmpty('street', 'Street')}} 
                            />
                            <span className='error'>{Intern.errors["street"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                            <AsyncSelect
                                cacheOptions                               
                                defaultOptions                                
                                loadOptions={getPinOptions}                                
                                isClearable={true}
                                name={'pincode'}
                                placeholder={'Pincode'}
                                value={Intern.pincode}
                                inputValue = {(Intern.pincode === null || Intern.pincode.label === undefined) ? '' :Intern.pincode.label }
                                styles={Customstyles}
                                controlShouldRenderValue={false}                               
                                components={{                                    
                                    Input                               
                                }}
                                onChange={OnChangeSelectPin}
                                onInputChange ={PinCodeInputChange}
                                onBlur={()=>{Validatepincode('pincode')}}
                            />
                                <span className='error'>{Intern.errors["pincode"]}</span>
                            </div>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    placeholder="District" 
                                    value={Intern.District} 
                                    name='District' 
                                    onChange={handleChange}
                                    onBlur={()=>{ValidateIsEmpty('District', 'District')}}  
                                /> 
                                <span className='error'>{Intern.errors["District"]}</span>
                            </div>
                        </div>
                        <div className='col-12 px-0 padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    placeholder="State" 
                                    value={Intern.State} 
                                    name='State' 
                                    onChange={handleChange}
                                    onBlur={()=>{ValidateIsEmpty('State', 'State')}}  
                                /> 
                                <span className='error'>{Intern.errors["State"]}</span>
                            </div>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    placeholder="Name"
                                    value={Intern.Name} 
                                    name='Name' 
                                    onChange={handleChange}
                                    onBlur={()=>{ValidateIsEmpty('Name', 'Name')}} 
                                />
                                <span className='error'>{Intern.errors["Name"]}</span>
                            </div>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    placeholder="email"
                                    value={Intern.email} 
                                    name='email' 
                                    onChange={handleChange}
                                    onBlur={ValEmailIsExist}
                                />
                                <span className='error'>{Intern.errors["email"]}</span>
                            </div>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    placeholder="mobile"
                                    value={Intern.mobile} 
                                    name='mobile' 
                                    onChange={handleChange}
                                    onBlur={ValMobileNoIsExist}
                                />
                                <span className='error'>{Intern.errors["mobile"]}</span>
                            </div>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            {(Intern.IsMobileVerified === false && Intern.mobile.length >= 10 && Intern.showOtp === true && Intern.isMobileNumberExists === 0 && (Intern.errors["mobile"] === '' || Intern.errors["mobile"] === undefined))&&
                                <div className="row mx-0 col-12 d-flex-fully-cent">
                                    <div className="col-4 country-code-padding">
                                        <button className="reg-button" onClick={SendOTP}> 
                                            {Intern.MobileReferenceCode === "" ? "Send OTP" : "Re-Send OTP"}
                                        </button>
                                    </div>
                                    <div className="col-4 px-0">
                                        <input 
                                            type="text" 
                                            className="input-reg" 
                                            placeholder="Enter OTP" 
                                            name='OTP'
                                            onChange={handleChange}
                                            onKeyPress={OnlyAlphaNumeric}
                                            onBlur={()=>{ValidateIsEmpty('OTP', 'OTP')}}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-4  mobile-padding">
                                        <button className="reg-button" onClick={Verify}>Verify</button>
                                    </div>
                                </div>
                            }
                            <span className="error">{Intern.errors.OTP}</span>
                        </div>
                        <div className='text-aligen-center d-fully-cent '>
                            <button className='ycc-home-button z-ined-2postition-realtive login-button-padding' onClick={SaveIntern}>Submit</button>
                        </div>
                    </> 
                }
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
        </>
    )
}
export default React.memo(Modal); 