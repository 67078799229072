import React from "react";
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import { IndustryURL,IndusURL } from "../../Assests/js/config";

import 'react-toastify/dist/ReactToastify.css';
import  visualprofile_dark1 from '../../Assests/Images/visualprofile-light.png'
import  visualprofile_light from '../../Assests/Images/visualprofile-dark1.png'
import profile360_light from '../../Assests/Images/profile360-light.png'
import profile360_dark from '../../Assests/Images/profile360-dark.png'
import jobconnect_dark from '../../Assests/Images/jobconnect-dark.png'
import jobconnect_light from '../../Assests/Images/jobconnect-light.png'
import interview_dark from '../../Assests/Images/interview-dark.png'
import interview_light from '../../Assests/Images/interview-light.png'
import {IndividualUrllogin} from '../../Assests/js/config';
import onlineinterview_white from '../../Assests/Images/onlineinterview-white.png'
import profile360score_white from '../../Assests/Images/profile360score-white.png'
import visualprofiling_white from '../../Assests/Images/visualprofiling-white.png'
import jobconnect_white from '../../Assests/Images/job-connect-white.png'
import upskillinggs from '../../Assests/Images/upskillinggs.png';
import onlineinterview from '../../Assests/Images/onlineinterview.png';
import careerCoun from '../../Assests/Images/careerCoun.png';
import c360light from '../../Assests/Images/connect360-light.png'
import c360dark from '../../Assests/Images/c360dark.png';
import c360lightv1 from '../../Assests/Images/c360lightv1.png';
import history from '../../../src/histoty';
import Instlmsdark from '../../Assests/Images/Instlmsdark.svg';
import instlmslite from '../../Assests/Images/instlmslite.svg';
import Lmscourse from '../../Assests/Images/Lmscourse.png'
import C360Mobile_PF from '../../Assests/Images/Connect 360ScoreMobile-PF.png'
import P360Mobile_PF from '../../Assests/Images/Profile360ScoreMobile-PF.png'
import Slider from "react-slick";

import Processflowarrow from '../../Assests/Images/RightandLeftarrowinProcessFlow.svg'


import base64 from 'base-64'

const Mobilevewslide = {
    dots: false,
    // dots:  window.matchMedia("(min-width: 649px)").matches ? false : true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay:false ,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    adaptiveHeight: true,
    arrows:true,
    cssEase: 'ease-out',
    speed:1000
}
let scroll 
class processFlow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeData:1,
            skills: [],
            jobs :[],
            clientdetail: [],
            categ : [],
            catcomplogo:[],
            // searchSkills : '',
            searchSkills : [],
            textactive:1,
            Tab:1,
            PFCfiltredselected:'',
            CategHalf :[],
            selectedpack:'',
            checkedtrue:false,
            topbottomtransition:0
        }
    }
    componentDidMount(){
        let resp = ~~parseInt(history.location.hash.substring(history.location.hash.length-1, history.location.hash.length));
        let con = parseInt(resp === 0? 1:resp);
        this.visuaprofiling(con)
     
//mobile view tabs process flow function
    // hide all contents accept from the first div
    $('.tabContent div:not(:first)').toggle();
    // hide the previous button
    $('.PreviousprocessFlow').hide();
    $('.tabs1 li').click(function () {
    if ($(this).is(':last-child')) {
            $('.nextprocessFlow').hide();
    } 
    else {
            $('.nextprocessFlow').show();
    }
    if ($(this).is(':first-child')) {
            $('.PreviousprocessFlow').hide();
    } 
    else {
            $('.PreviousprocessFlow').show();
    }
        var position = $(this).position();
            // console.log("position",position)
        var corresponding = $(this).data("id");
    // scroll to clicked tab with a little gap left to show previous tabs
        scroll = $('.tabs1').scrollLeft();
        // console.log("scroll",scroll)
        $('.tabs1').animate({
            'scrollLeft': scroll + position.left
           
        }, 200);
    // show content of corresponding tab
        $('div.' + corresponding).toggle();
    // remove active class from currently not active tabs
        $('.tabs1 li').removeClass('active');
    // add active class to clicked tab
        $(this).addClass('active');
    });

    $('.nextprocessFlow').click(function(e){
    e.preventDefault();
    $('li.active').next('li').trigger('click');
    if(scroll==155||scroll==156)
        {
          
            $('.nextprocessFlow').hide();
        }
    });

    $('.PreviousprocessFlow').click(function(e){
    e.preventDefault();
    $('li.active').prev('li').trigger('click'); 
    });
//mobile view tabs process flow function

}
    
visuaprofiling=(id)=>
    {

        this.setState({
            activeData:id,
            textactive:0
        } ,()=> {
            setTimeout(()=>{
                this.setState(
                    {
                        textactive: id
                    }
                )
            },900)
            

        })

        

        

        
    }
    opencompany=()=>
 {
    window.open(IndusURL+'/indu-login','_blank')
 }
 openindi=()=>
    {
        // window.open(IndividualUrllogin+'/indi-login' ,'_blank')
        window.open('#'+Routing.Job ,'_self')
    }

    transitionfunction=(id)=>
    {
        if(id===1)
        {
            this.setState({
                topbottomtransition:1
            })
        }
        else
        {
            this.setState({
                topbottomtransition:0
            })
        }
       
    }
    

    render(){
        return(
            <div>
                    <div className="mb-style-changewrap  paddtopdeskNewflows   Desktopnewdesign" id="Processflow">
                        <div className="col-lg-12 col-md-12 col-3 px-0 row mx-0 d-flex justify-content-center  directmbcloum ">
                            <div className="  col-md-2 col-lg-2 px-0  pubjd">
                                <a target='_blank'>
                                    <div className={this.state.activeData === 1 ? "skl-productss active  Brradius-indu" : "skl-productss   Brradius-indu"}
                                        onClick={()=>this.visuaprofiling(1)}
                                    >
                                        <div className="indu-image-blocks">
                                            <img src= {this.state.activeData === 1 ?visualprofile_dark1:visualprofile_light} className="Brradius-indu"/>
                                        </div>
                                        <div>
                                            <div className="skl-prod-name bold-efeect" id='VProfile'>Visual Profiling</div>
                                        </div>
                                </div>
                                </a>
                            </div>
                            <div className="   col-md-2 col-lg-2   px-0">
                                <a target='_blank'>
                                    <div className={this.state.activeData === 2 ? "skl-productss active  Brradius-indu" : "skl-productss Brradius-indu"}
                                        onClick={()=>this.visuaprofiling(2)} >
                                            <div className="indu-image-blocks">
                                                <img src= {this.state.activeData === 2 ? profile360_dark : profile360_light} className="Brradius-indu"
                                                    // onMouseOver={e => e.currentTarget.src = P360_1}
                                                    // onMouseOut={e => e.currentTarget.src = P360}
                                            />
                                            </div>
                                            <div>
                                                <div className="skl-prod-name bold-efeect"><span className="pfscoreclr">Profile<span className="score360"> 360</span> Score</span> <span className="pfscoreclr">(P<span className="score360">360</span>S)</span> </div>
                                                        
            
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        {/* <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
            
                                            <div className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(3)}>
                                                <div className="indu-image-blocks">
                                                    <img src={this.state.activeData === 3 ? jobconnect_dark  : jobconnect_light} className="Brradius-indu"
                                                    />
                                                </div>
                                                <div>
                                                    <div className="skl-prod-name bold-efeect">Carrier connect</div>
            
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
            
                                            <div className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(3)}>
                                                <div className="indu-image-blocks">
                                                    <img src={this.state.activeData === 3 ? Instlmsdark  : instlmslite} className="Brradius-indu"
                                                    />
                                                </div>
                                                <div>
                                                    <div className="skl-prod-name bold-efeect">Upgrade Skill</div>
            
                                                </div>
                                            </div>
                                        </div>
                                        <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
                                            <a target='_blank'>
                                                <div className={this.state.activeData === 4 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                        onClick={()=>this.visuaprofiling(4)}>
                                                    <div className="indu-image-blocks">
                                                        <img src={this.state.activeData === 4 ? c360dark : c360lightv1} className="Brradius-indu"
                                                        // onMouseOver={e => e.currentTarget.src = online1}
                                                        // onMouseOut={e => e.currentTarget.src = online}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="skl-prod-name bold-efeect"><span className="pfscoreclr">Connect<span className="score360"> 360</span> Score</span> <span className="pfscoreclr">(C<span className="score360">360</span>S)</span> </div>
            
                                                    </div>
                                                </div>
                                            </a></div>
        
                                            <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                                            <a target='_blank'>
                                                <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                        onClick={()=>this.visuaprofiling(5)}>
                                                    <div className="indu-image-blocks">
                                                        <img src={this.state.activeData === 5 ? jobconnect_dark  : jobconnect_light} className="Brradius-indu"
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="skl-prod-name bold-efeect">Career connect</div>
            
                                                    </div>
                                                </div>
                                            </a></div>
                                            {/* <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                                            <a target='_blank'>
                                                <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                        onClick={()=>this.visuaprofiling(5)}>
                                                    <div className="indu-image-blocks">
                                                        <img src={this.state.activeData === 5 ? interview_dark : interview_light} className="Brradius-indu"
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="skl-prod-name bold-efeect">online interviews</div>
            
                                                    </div>
                                                </div>
                                            </a></div> */}
                                        
                                    </div>
        
                                    <div className={this.state.activeData === 1 ? "col-sm-12 col-9 px-0 indu-data-containerss active fst-border individualprocessflowheigtmobile" :
                                        this.state.activeData === 5 ? "col-sm-12 col-9  px-0 indu-data-containerss active lst-border individualprocessflowheigtmobile" : "col-sm-12 col-9  px-0 indu-data-containerss active all-border individualprocessflowheigtmobile"}>
                                        <div className="col-12 px-0 row mx-0   tabsec-center  mi-heigh-overall">
                                            <div className={ this.state.textactive==0?"col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0  align-paddd-induss py-3":"col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0 align-paddd-induss   py-3" }   >
                                                <div className="visul-porfile-head  flow-align">
                                                    {this.state.activeData === 1 ? 
                                                    <div className="">
                                                        {/* <div className="visual-profile-head1 ">PUBLISH</div>
                                                        <div className="visual-haed-two ">JOB DESCRIPTION</div> */}
                                                        <div className="visual-profile-head1">Create your </div>
                                                            <div className="visual-haed-two ">Visual profile</div>
                                                    </div> :
                                                        this.state.activeData === 2 ?
                                                            <div className="">
                                                                <div className="visual-profile-head1">Check out your</div>
                                                                <div className="visual-haed-two "><span className="pfscoreclr">Profile<span className="score360"> 360</span> Score</span> <span className="pfscoreclr"> (P<span className="score360">360</span>S)</span></div>
                                                            </div>
                                                            : this.state.activeData === 3 ?
                                                                <div className="">
                                                                    <div className="visual-profile-head1 ">Importance of</div>
                                                                    <div className="visual-haed-two  ">UPGRADING YOUR SKILL</div>
                                                                </div>
                                                                //    <div className="">
                                                                //         <div className="visual-profile-head1 ">FIND YOUR</div>
                                                                //         <div className="visual-haed-two  ">perfect job</div>
                                                                //     </div>
                                                                
                                                                :
                                                                this.state.activeData === 4 ?
                                                                    <>
                                                                        {/* <div className="visual-profile-head1 animat ">CANDIDATE</div> */}
                                                                        <div className="visual-haed-two "><span className="pfscoreclr">Connect<span className="score360"> 360</span> Score</span><span className="pfscoreclr"> (C<span className="score360">360</span>S)</span></div>
                                                                    </> 
                                                                    //   :<></>
                                                                    :
                                                                    this.state.activeData === 5 ?
                                                                        <>
                                                                            <div className="visual-profile-head1">FIND YOUR</div>
                                                                            <div className="visual-haed-two">perfect job</div>
                                                                        </>
                                                                        //    <>
                                                                        //    <div className="visual-profile-head1">Attend your</div>
                                                                        //    <div className="visual-haed-two">online interviews</div>
                                                                        //     </>
                                                                    
                                                                        :  <>
                                                                            {/* <div className="visual-profile-head1">upgrade your
                                                                            </div>
                                                                            <div className="visual-haed-two">skills</div> */}
                                                                            {/* <div className="visual-profile-head1">Get your</div>
                                                                            <div className="visual-haed-two">Career counselling</div> */}
                                                                        </>
                                                                        
                                                                        // <></>
        
                                                    }
                                                </div>
                                                <div className="visual-data-contentss ">
                                                    {this.state.activeData === 1 ?
                                                        <div className="">
                                                            {/* Want to stand out from the crowd? Visual profiling gives you the power to express yourself. In this digital age, video profiles are undoubtedly the future. */}
                                                            {/* Visual Profiling is a revolutionary digital-n-dynamic technology tool for positioning, interpreting, profiling and managing Individual profiles across various professions, qualifications and industries. */}
                                                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <p className="mb-3">For one's resume to really shine, we need to think carefully about how it's designed as well as what's written. Visual Profiling tool helps you do it.</p>
                                <p className="mb-3">Visual Profiling is a revolutionary digital-n-dynamic technology tool for positioning, interpreting, profiling and managing Individual profiles across various professions, qualifications and industries.</p>
                                <p className="mb-3">With the best use of UX-n-UI, one can express oneself effectively-n-efficiently. This is the best way to get noticed and with least clutter reach out to the world with one's own credibility, capabilities, skills and aspirations.</p>
                                <p className="mb-3">The powerful interpretation of one's own profile, breaks the monotony of the written word and instead communicates visually to express better, clearly and with specificity with our evolving thoughts.</p>
                            </div>
                                                        </div>
                                                        :
                                                        this.state.activeData === 2 ?
                                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext ">
                                                                {/* <span className="pfscoreclr">P<span className="score360">360</span>S</span> is a powerful rating mechanism that takes into account various attributes of your Skills, Experience and Accomplishments across various categories. On a scale of <span className="score360">360</span>, your score is a weightage calculated based on your Academics, Skills, Experience and other Accomplishments.  */}
                                                                {/* A Profile 360 Score (P360S) */}
                                                                <span className="pfscoreclr">P<span className="score360">360</span>S</span> is a powerful rating mechanism that is assigned to an individual's profile based on certain criteria. It is designed to provide a quick summary of the person's qualifications, skills, experience, or suitability for a job application.
                                                                </div>
                                                            :
                                                            this.state.activeData === 3 ?
                                                                <div className=" ">
                                                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                                            <ul className="ul-skillupgrading">
                                                                                <li className="mb-3"><span className="font-weight-bold">Increased job opportunities:</span> In today's competitive job market, having the latest skills and knowledge is essential for getting a good job. By upgrading your skills, you can make yourself more marketable to employers and increase your chances of getting hired.</li>
                                                                                <li className="mb-3"><span className="font-weight-bold">Higher salaries:</span> Employees with in-demand skills are often paid more than those with outdated skills. By upgrading your skills, you can increase your earning potential.</li>
                                                                                <li className="mb-3"><span className="font-weight-bold">More job satisfaction:</span> When you have the skills and knowledge to do your job well, you're more likely to be satisfied with your work. This can lead to increased productivity and a better work-life balance.</li>
                                                                                <li className="mb-3"><span className="font-weight-bold">Improved career prospects:</span> By upgrading your skills, you can open up new career opportunities for yourself. You may be able to move into a new role, start your own business, or take on more responsibility in your current role.</li> 
                                                                                <li className="mb-0"><span className="font-weight-bold">Personal development:</span> Learning new skills can help you to grow as a person. You'll gain new knowledge, develop new skills, and meet new people. This can lead to a more fulfilling and rewarding life.</li>
                                                                            </ul>
                                                                        </div>
                                                                </div> 
                                                                //    <div className=" ">
                                                                //         Career Connect aims to align individuals' skills and interests with suitable career paths, enabling them to make informed decisions and achieve their professional goals. It recognizes the importance of continuous learning, adaptability, and staying current with industry trends to thrive in a rapidly changing job market. Ultimately, Career Connect seeks to empower individuals to build meaningful and fulfilling careers by providing them with the tools, knowledge, and connections necessary to navigate their professional journeys successfully.  
                                                                //     </div> 
                                                                :
                                                                this.state.activeData === 4 ?
                                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext ">
                                                                            <span className="pfscoreclr">C<span className="score360">360</span>S</span> is a measure of your skills and abilities that is used to match you with jobs. It is calculated based on your education, work experience, and skills assessments. The higher your score, the more likely you are to be matched with a job that is a good fit for you.
                                                                        <div className="mt-3">The benefits of having a high  <span className="pfscoreclr">C<span className="score360">360</span>S</span>:
                                                                        <li className="C360list my-3">You are more likely to be matched with a job that is a good fit for your skills and interests.</li>
                                                                        <li className="C360list mb-3">You are more likely to be considered for a job interview.</li>
                                                                        <li className="C360list mb-0">You are more likely to be offered a job.</li>   </div>   
                                                                    </div> :
                                                                    this.state.activeData === 5 ?
                                                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                                            Career Connect aims to align individuals' skills and interests with suitable career paths, enabling them to make informed decisions and achieve their professional goals. It recognizes the importance of continuous learning, adaptability, and staying current with industry trends to thrive in a rapidly changing job market. Ultimately, Career Connect seeks to empower individuals to build meaningful and fulfilling careers by providing them with the tools, knowledge, and connections necessary to navigate their professional journeys successfully.
                                                                            {/* Connect with the candidates with a one click scheduling and interviewing platform. Avoid multiple email / phone follow-ups with candidates. With real-time video feedback capture, this truly makes your overall recruitment process Comprehensive, Complete and Efficient. */}
                                                                            </div>
                                                                        :
                                                                        <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                                            Connect with your shortlisted candidates virtually 
                                                                            and capture real time feedback. 
                                                                            You can choose to connect 
                                                                            with candidate 1 on 1 or as a panel</div>
            
                                                    }
                                                </div>
        
                                                
                                                    <div className="processflowAccessbt">
                                                {/* {this.state.activeData === 3 &&
                                                    <div  className="visual-view-more-btn-btnnns">
                                                
                                                    <button className="createprofile meet-our-team  fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}}  >Login</button>
                                                    
                                                    </div>
                                                } */}
                                                {this.state.activeData === 3 &&
                                                    <div className="visual-view-more-btn-btnnns">
                                                        {/* <HashRouter><Link to={Routing.Job} > */}
                                                            {/* <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button> */}
                                                            {/* </Link></HashRouter> */}
                                                            <div className="pop-imagcent">
                                                            <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href="https://mots.skillablers.com/#" target="_blank">View Course</a></button>
                                                            <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={IndividualUrllogin+'/indi-login'} target="_blank">Upgrade</a></button>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.activeData === 5 &&
                                                    <div className="visual-view-more-btn-btnnns">
                                                        <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
                                                    </div>
                                                }
                                                {this.state.activeData === 4 &&
                                                    <div className="visual-view-more-btn-btnnns">
                                                        <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
                                                    </div>
                                                }
                                                {/* {this.state.activeData === 5 &&
                                                    <div  className="visual-view-more-btn-btnnns ">
                                                    <button className="createprofile meet-our-team fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}} >Login</button>
                                                    </div>
                                                } */}
                                                    {/* {this.state.activeData === 4 &&
                                                    <div  className="visual-view-more-btn-btnnns ">
                                                    <button className="createprofile meet-our-team fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}} >Login</button>
                                                    </div>
                                                } */}
                                                {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
                                                    <div className="visual-view-more-btn-btnnns">

                                                        <button className="createprofile meet-our-team texnonetransfor py-2"
                                                            onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                            <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? IndividualUrllogin+'/indi-login'  :
                                                                this.state.activeData === 2 ? IndividualUrllogin+'/indi-login' :
                                                                    this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                                {this.state.activeData === 1 ? "Create Profile" : this.state.activeData === 2 ? " Create Profile " :
                                                                    this.state.activeData === 3 ? "View Jobs" : this.state.activeData === 5 ? "Contact Us" :
                                                                        "View Courses"}</a></button>
                                                        {/* {this.state.activeData === 2 &&
                                                            <div className="create-your-profie px-0">by creating your profile</div>} */}
                                                    </div>
                                                }
                                                </div>
                                                
                                            </div>

                                            <div className={this.state.textactive == 0 ? "col-sm-6 col-12 dflex-cent padding-zer imgalignment  " : "col-sm-6 col-12 dflex-cent padding-zer imgalignment   "}>
                                                <img src={this.state.activeData === 1 ? visualprofiling_white: this.state.activeData === 2 ?
                                                profile360score_white : this.state.activeData === 3 ?Lmscourse  :
                                                        this.state.activeData === 5 ?jobconnect_white
                                                        //  onlineinterview_white 
                                                        :
                                                            this.state.activeData === 4 ? c360light : upskillinggs} className="width-100per-image" />
                                            </div>
                                            <div className="processflowAccessbtMobile">
                                                {/* {this.state.activeData === 3 &&
                                                    <div  className="visual-view-more-btn-btnnns">
                                                
                                                    <button className="createprofile meet-our-team  fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}}  >Login</button>
                                                    
                                                    </div>
                                                } */}
                                                {this.state.activeData === 3 &&
                                                    // <div className="visual-view-more-btn-btnnns">
                                                    //     {/* <HashRouter><Link to={Routing.Job} > */}
                                                    //         <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
                                                    //         {/* </Link></HashRouter> */}
                                                    // </div>
                                                    <div className="visual-view-more-btn-btnnns">
                                                        {/* <HashRouter><Link to={Routing.Job} > */}
                                                        <div className="pop-imagcent">
                                                            <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href="https://mots.skillablers.com/#">View Course</a></button>
                                                            <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={IndividualUrllogin+'/indi-login'}>Upgrade</a></button>
                                                        </div>
                                                            {/* </Link></HashRouter> */}
                                                    </div>
                                                    // <div className="visual-view-more-btn-btnnns">
                                                    //     <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
                                                    // </div>
                                                }
                                                {this.state.activeData === 4 &&
                                                    <div className="visual-view-more-btn-btnnns">
                                                        {/* <HashRouter><Link to={Routing.Job} > */}
                                                            <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
                                                            {/* </Link></HashRouter> */}
                                                    </div>
                                                }
                                                {this.state.activeData === 5 &&
                                                    <div className="visual-view-more-btn-btnnns">
                                                        {/* <HashRouter><Link to={Routing.Job} > */}
                                                            <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
                                                            {/* </Link></HashRouter> */}
                                                    </div>
                                                }

                                                {/* {this.state.activeData === 5 &&
                                                    // <div  className="visual-view-more-btn-btnnns ">
                                                    // <button className="createprofile meet-our-team fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}} >Login</button>
                                                    //  </div>
                                                } */}
                                                    {/* {this.state.activeData === 4 &&
                                                    <div  className="visual-view-more-btn-btnnns ">
                                                    <button className="createprofile meet-our-team fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}} >Login</button>
                                                    </div>
                                                } */}
                                                {(this.state.activeData !== 4 && this.state.activeData !== 3  && this.state.activeData !== 5) &&
                                                    <div className="visual-view-more-btn-btnnns">

                                                    <button className="createprofile meet-our-team texnonetransfor py-2"
                                                        onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                        <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? IndividualUrllogin+'/indi-login'  :
                                                            this.state.activeData === 2 ? IndividualUrllogin+'/indi-login' :
                                                                this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                            {this.state.activeData === 1 ? "Create Profile" : this.state.activeData === 2 ? "Create Profile" :
                                                                this.state.activeData === 3 ? "View Jobs" : this.state.activeData === 5 ? "Contact Us" :
                                                                    "View Courses"}</a></button>
                                                    {this.state.activeData === 2 &&
                                                        <div className="create-your-profie">by creating your profile</div>}
                                                </div>
                                                }
                                                </div>
                                        </div>
                                    </div>
        
                    </div>
                    {/* //mobile view process flow  */}
                    <div className="MobileviewnewDesign">
                           <div className="indiproceswrap">
                                {/* <div className={this.state.activeData=== 1 || this.state.activeData=== 2 || this.state.activeData=== 3 ? "row procesfledirecMobsecond" :" row procesfledirecMob"}>
                                    <div className="col-12 disflemobprocess">
                                            <div className={this.state.activeData=== 4?"col-4 MobboxlineclassProcess backactivehighlight":"col-4 MobboxlineclassProcess"}  onClick={()=>this.visuaprofiling(4)}>
                                                <span className={this.state.activeData=== 4?"pfscoreclr textcolractivewhite":"pfscoreclr"}>C<span className="score360">360</span>S</span>
                                            </div>
                                            <div className= {this.state.activeData=== 5?"col-8 MobboxlineclassProcess backactivehighlight":"col-8 MobboxlineclassProcess"} onClick={()=>this.visuaprofiling(5)}>
                                                <span className={this.state.activeData=== 5?"pfscoreclr textcolractivewhite":"pfscoreclr"}>CAREER CONNECT</span>
                                            </div>
                                    </div>
                                

                                    <div className="col-12 disflemobprocess">
                                            <div className={this.state.activeData=== 1?"col-3 MobboxlineclassProcess backactivehighlight":"col-3 MobboxlineclassProcess"} onClick={()=>this.visuaprofiling(1)}>
                                                <span className={this.state.activeData=== 1?"pfscoreclr textcolractivewhite":"pfscoreclr"}>SVP</span>
                                            </div>
                                            <div className= {this.state.activeData=== 2?"col-3 MobboxlineclassProcess backactivehighlight":"col-3 MobboxlineclassProcess"} onClick={()=>this.visuaprofiling(2)}>
                                                <span className={this.state.activeData=== 2?"pfscoreclr textcolractivewhite":"pfscoreclr"}>P<span className="score360">360</span>S</span>
                                            </div>
                                            <div className={this.state.activeData=== 3?"col-6 MobboxlineclassProcess backactivehighlight":"col-6 MobboxlineclassProcess" } onClick={()=>this.visuaprofiling(3)}>
                                                <span className={this.state.activeData=== 3? "pfscoreclr textcolractivewhite":"pfscoreclr"}>UPGRADE SKILL</span>
                                            </div>
                                    </div>
                                </div> */}

                    <div class="contentWrapperProcessflow">
                        <div class="tabsWrapperProcessflow">
                            <ul class="tabs1">
                                <li data-id="contentOne" class="active"><span className={this.state.activeData=== 1?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(1)}>SVP</span><div className={this.state.activeData=== 1?"bdbottomclrnew ":""}></div></li>
                                <li data-id="contentTwo"><span className={this.state.activeData=== 2?"pfscoreclr  fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(2)}>P<span className="score360">360</span>S</span><div className={this.state.activeData=== 2?"bdbottomclrnew ":""}></div></li>
                                <li data-id="contentThree"><span className={this.state.activeData=== 3? "pfscoreclr fntwettext ":"pfscoreclr"} onClick={()=>this.visuaprofiling(3)}>UPGRADE SKILL</span><div className={this.state.activeData=== 3?"bdbottomclrnew ":""}></div></li>
                                <li data-id="contentFour"><span className={this.state.activeData=== 4?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(4)}>C<span className="score360">360</span>S</span><div className={this.state.activeData=== 4?"bdbottomclrnew ":""}></div></li>
                                <li data-id="contentSix"><span className={this.state.activeData=== 5?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(5)}>CAREER CONNECT</span><div className={this.state.activeData=== 5?"bdbottomclrnew ":""}></div></li>
                            </ul>
                        </div> 
                    <span class="nextprocessFlow">
                        <img src={Processflowarrow}></img>
                    </span>
                    <span class="PreviousprocessFlow">
                     <img src={Processflowarrow}></img>
                    </span>

                    </div>
                                                

                            </div>

                            <div className="ProcessflowdescriptionWrap-Indi px-2">
                            <div className="visul-porfile-head  flow-align">
                                                    {this.state.activeData === 1 ? 
                                                    <div className="">
                                                            <div className="visual-profile-head1">Create your </div>
                                                            <div className="visual-haed-two ">Visual profile</div>
                                                    </div> :
                                                    this.state.activeData === 2 ?
                                                    <div className="">
                                                            <div className="visual-profile-head1">Check out your</div>
                                                            <div className="visual-haed-two "><span className="pfscoreclr">Profile<span className="score360"> 360</span> Score</span> <span className="pfscoreclr"> (P<span className="score360">360</span>S)</span></div>
                                                    </div> : 
                                                    this.state.activeData === 3 ?
                                                    <div className="">
                                                            <div className="visual-profile-head1 ">Importance of</div>
                                                            <div className="visual-haed-two  ">UPGRADING YOUR SKILL</div>
                                                    </div>:
                                                    this.state.activeData === 4 ?
                                                    <>
                                                            <div className="visual-haed-two "><span className="pfscoreclr">Connect<span className="score360"> 360</span> Score</span><span className="pfscoreclr"> (C<span className="score360">360</span>S)</span></div>
                                                    </> :
                                                    this.state.activeData === 5 ?
                                                    <>
                                                            <div className="visual-profile-head1">FIND YOUR</div>
                                                            <div className="visual-haed-two">perfect job</div>
                                                    </> :  
                                                    <>

                                                    </>
                                                    }
                                                </div>
                            </div>
                            <div className="px-2">
                                <div className={this.state.textactive == 0 ? "col-sm-6 col-12 dflex-cent padding-zer imgalignment  " : "col-sm-6 col-12 dflex-cent padding-zer imgalignment   "}>
                                        <img src={this.state.activeData === 1 ? visualprofiling_white: this.state.activeData === 2 ?
                                            P360Mobile_PF : this.state.activeData === 3 ?Lmscourse  :
                                            this.state.activeData === 5 ?jobconnect_white:
                                            this.state.activeData === 4 ? C360Mobile_PF : upskillinggs} className="width-100per-image imagshaw-pf" 
                                        />
                                </div>
                            </div>
                            <div className="textprocessflowwrap-indi px-2">
                                    <div className="visual-data-contentss ">
                                                    {this.state.activeData === 1 ?
                                                    <div className="">
                                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                        <p className="mb-3">For one's resume to really shine, we need to think carefully about how it's designed as well as what's written. Visual Profiling tool helps you do it.</p>
                                                        <p className="mb-3">Visual Profiling is a revolutionary digital-n-dynamic technology tool for positioning, interpreting, profiling and managing Individual profiles across various professions, qualifications and industries.</p>
                                                        <p className="mb-3">With the best use of UX-n-UI, one can express oneself effectively-n-efficiently. This is the best way to get noticed and with least clutter reach out to the world with one's own credibility, capabilities, skills and aspirations.</p>
                                                        <p className="mb-3">The powerful interpretation of one's own profile, breaks the monotony of the written word and instead communicates visually to express better, clearly and with specificity with our evolving thoughts.</p>
                                                        </div>
                                                    </div> :
                                                    this.state.activeData === 2 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext ">
                                                        <span className="pfscoreclr">P<span className="score360">360</span>S</span> is a powerful rating mechanism that is assigned to an individual's profile based on certain criteria. It is designed to provide a quick summary of the person's qualifications, skills, experience, or suitability for a job application.
                                                    </div>:
                                                    this.state.activeData === 3 ?
                                                    <div className=" ">
                                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                        <ul className="ul-skillupgrading">
                                                            <li className="mb-3"><span className="font-weight-bold">Increased job opportunities:</span> In today's competitive job market, having the latest skills and knowledge is essential for getting a good job. By upgrading your skills, you can make yourself more marketable to employers and increase your chances of getting hired.</li>
                                                            <li className="mb-3"><span className="font-weight-bold">Higher salaries:</span> Employees with in-demand skills are often paid more than those with outdated skills. By upgrading your skills, you can increase your earning potential.</li>
                                                            <li className="mb-3"><span className="font-weight-bold">More job satisfaction:</span> When you have the skills and knowledge to do your job well, you're more likely to be satisfied with your work. This can lead to increased productivity and a better work-life balance.</li>
                                                            <li className="mb-3"><span className="font-weight-bold">Improved career prospects:</span> By upgrading your skills, you can open up new career opportunities for yourself. You may be able to move into a new role, start your own business, or take on more responsibility in your current role.</li> 
                                                            <li className="mb-0"><span className="font-weight-bold">Personal development:</span> Learning new skills can help you to grow as a person. You'll gain new knowledge, develop new skills, and meet new people. This can lead to a more fulfilling and rewarding life.</li>
                                                        </ul>
                                                        </div>
                                                    </div>:
                                                    this.state.activeData === 4 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext ">
                                                            <span className="pfscoreclr">C<span className="score360">360</span>S</span> is a measure of your skills and abilities that is used to match you with jobs. It is calculated based on your education, work experience, and skills assessments. The higher your score, the more likely you are to be matched with a job that is a good fit for you.
                                                            <div className="mt-3">The benefits of having a high  <span className="pfscoreclr">C<span className="score360">360</span>S</span>:
                                                            <li className="C360list my-3">You are more likely to be matched with a job that is a good fit for your skills and interests.</li>
                                                            <li className="C360list mb-3">You are more likely to be considered for a job interview.</li>
                                                            <li className="C360list mb-0">You are more likely to be offered a job.</li>   </div>   
                                                    </div> :
                                                    this.state.activeData === 5 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                            Career Connect aims to align individuals' skills and interests with suitable career paths, enabling them to make informed decisions and achieve their professional goals. It recognizes the importance of continuous learning, adaptability, and staying current with industry trends to thrive in a rapidly changing job market. Ultimately, Career Connect seeks to empower individuals to build meaningful and fulfilling careers by providing them with the tools, knowledge, and connections necessary to navigate their professional journeys successfully.
                                                    </div>:
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                            Connect with your shortlisted candidates virtually 
                                                            and capture real time feedback. 
                                                            You can choose to connect 
                                                            with candidate 1 on 1 or as a panel
                                                    </div>
                                                }
                                    </div>
                            </div>

                        <div className="textprocessflowwrap-indi px-2"> 
                            <div className="processflowAccessbtMobile  ">
                                {this.state.activeData === 3 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow">
                                        <div className="pop-imagcent">
                                            <button id="viewall-butn" className="meet-our-team mar-pfright"><a className="no-text-dec" href="https://mots.skillablers.com/#" target="_blank">View Course</a></button>
                                            <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={IndividualUrllogin+'/indi-login'} target="_blank">Upgrade</a></button>
                                        </div>
                                    </div>
                                }
                                {this.state.activeData === 5 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow">
                                            <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
                                    </div>
                                }
                                {this.state.activeData === 4 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow">
                                            <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
                                    </div>
                                }
                                {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow">
                                            <button className="createprofile meet-our-team texnonetransfor py-2"
                                                onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? IndividualUrllogin+'/indi-login'  :
                                                    this.state.activeData === 2 ? IndividualUrllogin+'/indi-login' :
                                                    this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                    {this.state.activeData === 1 ? "Create Profile" : this.state.activeData === 2 ? " Create Profile " :
                                                    this.state.activeData === 3 ? "View Jobs" : this.state.activeData === 5 ? "Contact Us" :
                                                    "View Courses"}
                                                </a>
                                            </button>
                                    </div>
                                }
                            </div>                                                  
                        </div>

                    </div>
                    {/* //mobile view process flow  */}
            </div>
    )
    }}
    export default processFlow