import React, { useState } from 'react';
import {  BrowserRouter, useHistory } from "react-router-dom";
import { Routing } from "../../Assests/js/routing";
import history from "../../histoty";
import { NavLink } from "react-router-dom";
import HeaderSignin from "./Header-signin";
import {motsSkillablers} from '../../Assests/js/config';
import $ from 'jquery';
import EDClogofullcurved02C from '../../Assests/Images/EDC_logo_full_curved-02C.png';
import { HashRouter } from 'react-router-dom';
import Signinicon from '../../Assests/Images/signinlogowhite.svg';
const NOOP = () =>{}
function SecondFunction({toggle}) {
    const [ShowLgin , setShowLgin] = useState(false)
    const his = useHistory
    const RoutingTo = (location) => {
        window.open(window.location.origin + location, '_self');
    }
    const closePopup = () =>{
       setShowLgin(false)
    }
    const opensignpoup=()=>{
        setShowLgin(true)
        AutoClose()
    }
    const AutoClose=()=>{
        document.getElementById("header").classList.remove('transparent-backgror')
        toggle(1)
        window.footer.relo(); 
        // window.header.PageRerender()
        document.getElementById("activesubmenu").style.display="none"
        document.getElementById("activesmenu2").style.display="none"
        document.getElementById("upcomingmenu").style.display="none"
        document.getElementById("pastmenu").style.display="none"
        document.getElementById("activesmenu3").style.display="none"
    }
    const  AutoScroll = ()=>{
        $('html,body').animate({scrollTop: 0});
        let scrolling = $("#dfooter").offset().top
        $('html,body').animate({scrollTop: scrolling},'slow');
        toggle(1)
    }
    const OpenYouTube=()=>{
        window.open("https://www.youtube.com/channel/UCZx7OXHcq8YOl_-95SFZAaA",'_blank')
    }
    const Openfacebook =()=>{
        window.open("https://www.facebook.com/skillablers/",'_blank')
    }
    const Opentwitter=()=>{
        window.open("https://twitter.com/SkillablersTech",'_blank')
    }
    const OpenLinkedIN=()=>{
        window.open("https://www.linkedin.com/company/skillablers",'_blank')
    }
    const OpenInstagram=()=>{
        window.open("https://www.instagram.com/skillablers/",'_blank')
        if (document.getElementById("cont") !== undefined) {
            document.getElementById("cont").classList.add("mhide");
        }
    }
    const clickopensuvbmenu=()=>
    {
        var x = document.getElementById("activesubmenu");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
    }

    const opensigninfromimg=()=>
    {
        setShowLgin(true)
        AutoClose()
    }

    const clickopensuvbmenuEVents=()=>
    {
        var x = document.getElementById("activesmenu2");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
    }

    const clickopenADVice=()=>
    {
        var x = document.getElementById("activesmenu3");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
    }

    const clickupcomingEvent=()=>
    {
        var x = document.getElementById("upcomingmenu");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
    }
    const clickPastEvent=()=>
    {
        var x = document.getElementById("pastmenu");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
    }

    const openultra=()=>
  {
    window.open("https://events.skillablers.com/ultratechstimulus", '_blank');
    AutoClose()
  }
  const openbioquiz2023=()=>
{
  window.open("https://events.skillablers.com/bioquiz2023", '_blank');
  AutoClose()
}

const openYcc2020=()=>
{
  window.open("https://events.skillablers.com/events/ycc", '_blank');
  AutoClose()
}

const YCCkerala2024=()=>
{
  //window.open('#' +Routing.ycc, '_self')
  window.open(Routing.ycc, '_blank')
  AutoClose()
}

const talenttosucess=()=>
{
    window.open("https://t2sgroup.co.in", '_blank')
    AutoClose()
}
    return (
        <HashRouter>
         <ul className="nav__menu-lists nav__menu--1-lists navarea-dropdown">
            {/* {(window.location.hash.includes(Routing.Home) || window.location.hash.includes(Routing.Individual) || 
            (window.location.hash.includes(Routing.Industry) && window.location.hash.includes(Routing.rediness))) && */}
            <li className="menu-sign menu-dts-data signchnages" onClick={opensignpoup}>
                {/* <a className="boxsix" id="showbtn"  onClick={opensignpoup} >Sign In</a> */}
                <a  ><img src={Signinicon}   className="siginlogoclss"/>Sign In</a>
            </li>
            {/* } */}    
            <li className="menu-dts-data"   >
                <NavLink onClick={AutoClose} to={Routing.indihomelanding}  activeClassName="active" className={"menuanchor"}>Individuals</NavLink>        
            </li>
            {/* <li className="menu-dts-data" >
                <a onClick={AutoClose} className={"menuanchor"} href={motsSkillablers}>Skills</a>        
            </li>        */}
            <li className="menu-dts-data" >
                <NavLink  onClick={AutoClose}  to={Routing.insthomelanding} NavLinkactiveClassName="active" className={"menuanchor"}  >Institutions</NavLink>     
            </li>
            <li className="menu-dts-data" >
                <NavLink  onClick={AutoClose}  to={Routing.induhomelanding} NavLinkactiveClassName="active" className={"menuanchor"}  >Industries</NavLink>     
            </li>
            {/* <li className="menu-dts-data" >
                <NavLink  onClick={AutoClose}  to={Routing.CareerBlog}  NavLinkactiveClassName="active" className={"menuanchor"}  >Career Advice</NavLink>    
            </li>  */}
            <li className="menu-dts-data">
                <a className={window.location.hash.includes(Routing.CareerBlog) || window.location.hash.includes(Routing.CareerCounsell) ? "Ourpartnerhighlight" :" " }><div className='disflexourpart'><li className="menuanchor oruparttextclr" onClick={clickopenADVice} >Career Advice</li> <i class="fa fa-sort-desc clrwhitedrop" onClick={clickopenADVice} aria-hidden="true"></i></div></a>
            </li>
            <div id="activesmenu3" >
                    <li className={window.location.hash.includes(Routing.CareerBlog) ?"activeclrgreenmenu ":"submenuchanges"} >
                        <NavLink  onClick={AutoClose}  to={Routing.CareerBlog}   className={"menuanchor"}  >CAREER GAP ANALYSIS</NavLink>    
                    </li>
                    <li className={window.location.hash.includes(Routing.CareerCounsell) ?"activeclrgreenmenu ":"submenuchanges"}  >
                        <NavLink onClick={AutoClose} to={Routing.CareerCounsell}  className={"menuanchor"}>CAREER COUNSELING</NavLink>    
                    </li>

            </div>
            <li className="menu-dts-data" >
                <NavLink  onClick={AutoClose}  to={Routing.Newsletterpage}  NavLinkactiveClassName="active" className={"menuanchor"}  >Newsletter</NavLink>    
            </li> 
            <li className="menu-dts-data" >
                <NavLink  onClick={AutoClose}  to={Routing.Inquizition}  NavLinkactiveClassName="active" className={"menuanchor"}  >Inquizition</NavLink>    
            </li> 
            <li className="menu-dts-data" >
                <NavLink  onClick={AutoClose}  to={Routing.sklaspire}  NavLinkactiveClassName="active" className={"menuanchor"}  >SKL-ASPIRE</NavLink>    
            </li> 
            {/* //Events block */}
            <li className="menu-dts-data">
                <a><div className='disflexourpart'><li className="menuanchor oruparttextclr" onClick={clickopensuvbmenuEVents} >Events</li> <i class="fa fa-sort-desc clrwhitedrop" onClick={clickopensuvbmenuEVents} aria-hidden="true"></i></div></a>
            </li>
            <div id="activesmenu2" >
                    <li className="submenuchanges" >
                        <div onClick={clickupcomingEvent}><span  className={"menuanchor"}  >Upcoming</span> <span><i class="fa fa-sort-desc clrwhitedrop padzeromoveleftarrow"  aria-hidden="true"></i></span></div>
                       <div id="upcomingmenu">  
                        <div className='marrightfntsizeEvents'  onClick={YCCkerala2024}>SKL YCC 2024 KERALA</div></div>   
                    </li>
                    <li className="submenuchanges">
                        <div onClick={clickPastEvent}><span className={"menuanchor"}>Past</span><span><i class="fa fa-sort-desc clrwhitedrop padzeromoveleftarrow" aria-hidden="true"></i></span></div>
                        <div id='pastmenu'>
                        <div className='marrightfntsizeEvents'  onClick={openbioquiz2023}>Bio Quiz 2023</div>  
                        <div className='marrightfntsizeEvents' onClick={openYcc2020} >Youth Career Connect 2020</div>
                        <div className='marrightfntsizeEvents' onClick={openultra}>UltraTech Stimulus Quiz</div> 
                        </div>     
                    </li>
            </div>
            {/* //Events block */}
            <li className="menu-dts-data">
                <a className={window.location.hash.includes(Routing.chaloexamhome2) || window.location.hash.includes(Routing.aatce) ? "Ourpartnerhighlight" :" " }><div className='disflexourpart'><li className="menuanchor oruparttextclr" onClick={clickopensuvbmenu} >Partners</li> <i class="fa fa-sort-desc clrwhitedrop" onClick={clickopensuvbmenu} aria-hidden="true"></i></div></a>
            </li>
            <div id="activesubmenu" >
                    <li className={window.location.hash.includes(Routing.chaloexamhome2) ?"activeclrgreenmenu ":"submenuchanges"} >
                        <NavLink  onClick={AutoClose}  to={Routing.chaloexamhome2}   className={"menuanchor"}  >ChaloExam</NavLink>    
                    </li>
                    <li className={window.location.hash.includes(Routing.aatce) ?"activeclrgreenmenu ":"submenuchanges"}  >
                        <NavLink onClick={AutoClose} to={Routing.aatce}  className={"menuanchor"}>iPlanet</NavLink>    
                    </li>
                    <li className={"submenuchanges"} >
                        <div onClick={talenttosucess}  className={"menuanchor"}>Talent 2 Success</div>    
                    </li>
            </div>
            
            {/* <li className="menu-dts-data" >
                <NavLink  onClick={AutoClose}  to={Routing.chaloexamhome2} NavLinkactiveClassName="active" className={"menuanchor"}  >ChaloExam</NavLink>    
            </li> */}
            {/* <li className="menu-dts-data" >
                <NavLink onClick={AutoClose} to={Routing.aatce}NavLinkactiveClassName="active" className={"menuanchor"} >iPlanet</NavLink>    
            </li> */}
            <div className="divfooterrol menu-section-undeline">
                <div className="d-flex-col menu-footerr-conatiner">
                    <ul className="menu-socila-media ">
                        <li onClick={Openfacebook} className="social-btns no-margin-left"> <i className="fa fa-facebook-f"></i></li>
                        <li onClick={Opentwitter} className="social-btns"><i className="fa fa-twitter"></i></li>
                        <li onClick={OpenLinkedIN} className="social-btns"><i className="fa fa-linkedin"></i></li>
                        <li onClick={OpenInstagram} className="social-btns"> <i className="fa fa-instagram"></i></li>
                        <li onClick={OpenYouTube} className="social-btns"><i className="fa fa-youtube"></i></li>
                    </ul>
                        
                </div>
            </div>
        </ul>
        {ShowLgin && 
        <HeaderSignin  close={closePopup} />}           
    </HashRouter>
    )
}

export default SecondFunction;