import React from "react";
import $ from 'jquery';
// import Institutes from '../../Assests/Videos/Institute.mp4';
// import Institutesss from '../../Assests/Images/institutesss.png';
// import shadegirl from '../../Assests/Images/shadegirl.png';
// import yc from '../../Assests/Images/yc.png';
// import shadeboy from '../../Assests/Images/shadeboy.png';
// import video4 from '../../Assests/Images/video4.jpg';
// import video5 from '../../Assests/Images/video5.jpg';
// import woman from '../../Assests/Images/woman.png';
import bengaluru from '../../Assests/Images/bengaluru.png';
import youth from '../../Assests/Images/youth.png';
import UTIndiaNextStimulus  from '../../Assests/Images/UTIndiaNextStimulus.png';
import sight from '../../Assests/Images/sight.png';
import Modal from './Modal'
import '../../Assests/css/Institute.css';

class Institute extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            isIntern: false
        }
    }

    componentDidMount(){

        
            $('html,body').animate({
                scrollTop: 0
            });
            document.getElementsByClassName('skltext')[0].classList.add('active')
            document.getElementsByClassName('row1')[0].classList.add('active')
            document.getElementsByClassName('row2')[0].classList.add('active')
            document.getElementsByClassName('row3')[0].classList.add('active')


            $(window).on('scroll', e => {
                $('.individualBlockIdent').each(function () {
                    if ($(this)[0].id === "visulprofile") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                            document.getElementById('visulprofile').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "perfectJobs") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                            document.getElementById('perfectJobs').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "videoesss") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 40 / 100) < $(window).scrollTop()) {
                            document.getElementById('videoesss').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "Events") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                            document.getElementById('Events').classList.add('active')
                        }
                    }
                })
            })
    }
   
          
    YoutubeWindow= (id)=>{
        // console.log("youtube")
       if(id === 1){
           window.open("https://www.youtube.com/watch?v=gDrFvRw5dSU&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw")
       }
       else if(id === 2){
           window.open("https://www.youtube.com/watch?v=yrg085gq6rU&list=PLtpsAZJjrAoRGErHcZKH3YbL7qtHM4pQO&index=3")
       }
       else if(id === 3){
           window.open("https://www.youtube.com/watch?v=DxM3qrH9JWs&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw&index=5")
       }
       else if(id === 4){
           window.open("https://www.youtube.com/watch?v=aT7Cl5nhzJw")
       }
       else if(id === 5){
           window.open("https://www.youtube.com/watch?v=yzvDn4gttKk")
       }
       else if(id === 6){
           window.open("https://www.youtube.com/watch?v=uybwVo3sfyA&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw&index=13")
       }
   }


 openIndiasight=()=>{
    window.open("https://events.skillablers.com/indiainsight",'_blank')
}
openYCC=()=>{
    window.open("https://events.skillablers.com/events/ycc2021",'_blank')
}
UltraTech=()=>{
    window.open("https://events.skillablers.com/ultratechstimulus",'_blank')
}
OpenBioQuiz=()=>{
    window.open('https://events.skillablers.com/nanotechquiz2022','_blank')
}
openRegisteration=()=>{
    window.open("https://inst.skillablers.com/ins-registration",'_blank')
}

AutoScroll = ()=>{
    $('html,body').animate({
        scrollTop: 0
    });
    let scrolling = $("#dfooter").offset().top
    $('html,body').animate({
        scrollTop: scrolling
    },
        'slow');
}

    render(){
        return(
            <div class="indi-conatiner" id="InstituteContainer">
        <div class="pain threeiConatinerr-mainss" style={{backgroundColor: 'white'}}>
            
            <div class="container  individualBlockIdent" id="contain">
                <div class="row d-flex-center" id="secflx1">
                    <div class="col-lg-6 col-xl-6 col-md-12 col-sm-6 col-12" id="seccol2">
                        <div class="skltext">ENABLING</div>
                        <div class="row1">INSTITUTIONS</div>
                        <div class="row2">
                        SKILLABLERS Campus Connect – A comprehensive virtual platform 
that PARTNERS with a College to facilitate a STUDENTS journey from
ADMISSIONS to EMPLOYMENT
                        </div>
                        <div class="row3 margin-bottom-0px">
                            <button id="btn" onClick={this.AutoScroll}>Contact Us</button>
                        </div>
                        

                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-12 col-sm-6 " id="scecol1">
           
                     <video  autoPlay loop muted id="finalvideo1" >
                    {/* <source src={Institutes} type="video/mp4"></source> */}
                    </video>

                        </div>
                    </div>
                </div>
            </div>
            <section id="center1" class="inst-whole-padding">
                <div class="lap-maingrandparent individualBlockIdent " id="visulprofile">
                    <div class="lap-parent jsu-center">
                        <div class="lap-text">
                            <p class="create-text">we Enable you to</p>
                            <p class="visual-text"> find students</p>
                            <p class="visual-paraone">BE THE INSTITUTION OF CHOICE for The STUDENTS</p>
                            <p class="visual-paraone ">by Branding and Showcasing your institution on the </p>
                            <p class="visual-paraone"> SKILLABLERS platform </p>
                        </div>
                    </div>
                </div>
                {/* <img src={Institutesss} /> */}
                <div class="lap-maingrandparent individualBlockIdent " id="perfectJobs">
                    <div class="lap-parent jsu-center no-padd-btm-line">
                        <div class="lap-text">
                            <p class="create-text">We enable Your </p>
                            <p class="visual-text">  Students</p>
                            <p class="create-text"> Find their right</p>
                            <p class="visual-text"> careers</p>
                            <p class="visual-paraone">The SKILLABLERS Partnership helps institutions </p>
                            <p class="visual-paraone ">Focus On STUDENTS skill Development Needs and  </p>
                            <p class="visual-paraone">create Employment opportunities with Corporates </p>
                        </div>
                    </div>
                </div>
            <div class="indus-join-us-btn inst-conatact-us-part">
                <button class="lapview-btn custombutton" onClick={this.AutoScroll} >Contact Us</button>
            </div>
        </section>
        <div class="section" id="sec">
            <div class="main1 individualBlockIdent" id="videoesss">
                <div class="m1">
                    {/* <div class="row" id="r1">
                        <p>VIDEOS</p>
                    </div> */}
 <div class="events-headsss individualBlockIdent active" id="eventhead">VIDEOS</div>
                    <div class="row" id="r2">
                        <div class="col-lg-4 col-6 pos-realtive">
                            {/* <img src={shadegirl} alt="" id="vid1"/> */}
                        
                        <i class="fa fa-play-circle play-iocnss" aria-hidden="true" onClick={()=>this.YoutubeWindow(1)}></i>
                    </div>
                        <div class="col-lg-4 col-6 pos-realtive">
                            {/* <img src={yc} alt="" id="vid2"/> */}
                            <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(2)}></i>
                            </div>
                        <div class="col-lg-4  pos-realtive" > 
                        {/* <img src={shadeboy} alt="" id="vid3"/> */}
                            <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(3)} ></i></div>
                    </div>
                    <div class="row" id="r3">
                        <div class="col-lg-4 col-6 pos-realtive">
                            {/* <img src={video4} alt="" id="vid4"/> */}
                            <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(4)} ></i></div>
                        <div class="col-lg-4 col-6 pos-realtive">
                            {/* <img src={video5} alt="" id="vid5"/> */}
                            <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(5)}></i></div>
                        <div class="col-lg-4 pos-realtive">
                            {/* <img src={woman} alt="" id="vid6"/> */}
                            <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(6)} ></i></div>
                    </div>
                    <div class="row" id="r4">
                        <button id="bttn1"><a href="https://www.youtube.com/channel/UCZx7OXHcq8YOl_-95SFZAaA"  target="_blank">View
                                All</a></button>
                    </div>

                </div>
            </div>
            <div class="main2 individualBlockIdent" id="Events">
                <div class="m2 ">
                    {/* <div class="row" id="r5">
                        <p>EVENTS</p>
                    </div> */}
                     <div class="events-headsss individualBlockIdent active" id="eventhead">EVENTS</div>
                    <div class="row" id="r6">
                        <div class="col-lg-3 col-6">
                            <img src={bengaluru} alt="" id="image1" class="cursor-pointer" onClick={this.OpenBioQuiz}/>
                                
                            </div>
                        <div class="col-lg-3 col-6">
                            <img src={youth} alt="" id="image2"  class="cursor-pointer" onClick={this.openYCC}/>

                            </div>
                        <div class="col-lg-3 col-6">
                             <img src={UTIndiaNextStimulus} alt=""
                                id="image3"  class="cursor-pointer" onClick={this.UltraTech}/>

                                </div>
                        <div class="col-lg-3 col-6"> 
                        <img src={sight} alt="" id="image4"  class="cursor-pointer" onClick={this.openIndiasight}/>

                        </div>
                        <div class="row" id="r7">
                            <button id="bttn2">
                                <a href="https://events.skillablers.com/"  target="_blank">View All</a></button>
                        </div>
                    </div>
                </div>
            </div>
                </div>
                {this.state.isIntern &&
                    <Modal
                        isFrame={false}
                        Url={""}
                        close={this.closeIntern}
                        isIntern={true}
                        Industry={this.state.Industry}
                    />
                }
            </div>
        )
    }
}
export default Institute
 