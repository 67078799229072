import React, { useEffect, useState } from 'react';
import Individual from '../Individual_component/Individual';
import Industry from '../Industry_component/Industry';
import Institute from '../Institute_component/Institute';

function ThreeIConnect (){

    const [Tab , setTab] = useState(1)

    // useEffect(()=>{
    //     document.getElementsByClassName("avbout-us-skl-text")[0].classList.add('active')
    //     document.getElementById('howwedotabs').classList.add('active')
    //     setTimeout(() => {
    //        document.getElementsByClassName("how-we-do-tabsectionns")[0].classList.add('active')
    //     }, 700);
    // },[])
    return(
        <div className='padding-top-20vhh'>
            <div class="avbout-us-skl-text threeIAboutsect">Our 3-i Connect</div>
            <div class="what-we-do-txtx-main individualBlockIdent" id="howwedotabs">
            <div class={Tab === 1 ? "how-we-do-tabsectionns w-100vw how-we-do-tabsectionns-1" : Tab === 2 ? "how-we-do-tabsectionns w-100vw how-we-do-tabsectionns-2" :
        "how-we-do-tabsectionns w-100vw how-we-do-tabsectionns-3" }>
                        <div class="tabssss">
                            <div onClick={()=>setTab(1)}
                              className={Tab === 1 ? "tabsdaatta active-color-white" : " tabsdaatta"}>Individuals</div>
                            <div onClick={()=>setTab(2)}
                             className={Tab === 2 ? "tabsdaatta active-color-white" : "tabsdaatta"}>Institutions</div>
                            <div onClick={()=>setTab(3)}
                             className={Tab === 3 ? "tabsdaatta active-color-white" : "tabsdaatta"}> Industries</div>
                        </div>
                        </div>
                        </div>
                        <div>
                            {
                                Tab === 1 ? 
                                <Individual />:
                                Tab === 2 ?
                                <Institute />
                                : <Industry />
                            }

                        </div>
                
        </div>
        
    )
}

export default ThreeIConnect