import React, { useEffect, useState, useRef } from 'react';
import Objec from '../../Assests/Images/Objective-copy.png';
import Objec1 from '../../Assests/Images/Objective.png';
import Sched from '../../Assests/Images/Schedule.png';
import Sched1 from '../../Assests/Images/Schedule-copy.png';
import Quiz from '../../Assests/Images/Quiz.png';
import Quiz1 from '../../Assests/Images/Quiz-copy.png';
import StartUp from '../../Assests/Images/Startup-copy.png';
import SatrtUp1 from '../../Assests/Images/Startup.png';
import Reg from '../../Assests/Images/register.png';
import Accociate from '../../Assests/Images/Associ1.png';
import Voulnt from '../../Assests/Images/Volunteers.png';
import $ from 'jquery';
import ActiveAccoci from '../../Assests/Images/withus.png';
import desk from '../../Assests/Images/Desk.png';
import Tab from '../../Assests/Images/tab.png';
import Mob from '../../Assests/Images/MobView.png';
import PrevYCC from '../../Assests/Images/Previous.png';
import CountUp from 'react-countup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SwiperCoverflow from './video-swiper';
import PartnerwithUs from '../../Assests/Images/Branding_partners.png';
import Modal from './Modal';
import Group1 from '../../Assests/Images/Group1.png';
import Group2 from '../../Assests/Images/Group2.png';
import '../../Assests/css/YCC.css';
import Placement from '../../Assests/Images/PlacementBig.webp';
import participate from '../../Assests/Images/participate.jpg';
import { HashRouter, Link } from 'react-router-dom';
import { Routing } from '../../Assests/js/routing';
import Ycchomeimage1 from '../../Assests/Images/Ycchomeimage1.png';
import YCchomeimg3 from '../../Assests/Images/YCchomeimg3.png';
import Ycchomeimage2 from '../../Assests/Images/Ycchomeimage2.png';
import advertSect from '../../Assests/Images/sectors/advertising.png';
import agersect from '../../Assests/Images/sectors/agriculture.png';
import altersect from '../../Assests/Images/sectors/Alternate.png';
import agreculsect from '../../Assests/Images/sectors/architecture.png';
import artde from '../../Assests/Images/sectors/art&design.png';
import atrlang from '../../Assests/Images/sectors/arts&language.png';
import audiovis from '../../Assests/Images/sectors/audiovisual.png';
import automobile from '../../Assests/Images/sectors/automobile.png';
import aviation from '../../Assests/Images/sectors/aviation.png';
import banking from '../../Assests/Images/sectors/banking.png';
import commerce from '../../Assests/Images/sectors/commerce.png';
import converging from '../../Assests/Images/sectors/converging.png';
import defence from '../../Assests/Images/sectors/defence.png';
import dental from '../../Assests/Images/sectors/dental.png';
import digitalworld from '../../Assests/Images/sectors/digital world.png';
import ecommerce from '../../Assests/Images/sectors/ecommerce.png';
import edtech from '../../Assests/Images/sectors/edtech.png';
import engg_civil from '../../Assests/Images/sectors/engg_civil.png';
import engg_comp from '../../Assests/Images/sectors/engg_comp.png';
import engg_electrical from '../../Assests/Images/sectors/engg_electrical.png';
import engg_mechanical from '../../Assests/Images/sectors/engg_mechanical.png';
import entertainment from '../../Assests/Images/sectors/entertainment.png';
import fashion from '../../Assests/Images/sectors/fashion.png';
import futureofschool from '../../Assests/Images/sectors/future of school.png';
import futureskills from '../../Assests/Images/sectors/future skills.png';
import gaming from '../../Assests/Images/sectors/gaming.png';
import Government from '../../Assests/Images/sectors/Government.png';
import healthcare from '../../Assests/Images/sectors/healthcare.png';
import healthtech from '../../Assests/Images/sectors/healthtech.png';
import Hospitality from '../../Assests/Images/sectors/Hospitality.png';
import Humanities from '../../Assests/Images/sectors/Humanities.png';
import internationaleducation from '../../Assests/Images/sectors/internationaleducation.png';
import IT from '../../Assests/Images/sectors/IT.png';
import law from '../../Assests/Images/sectors/law.png';
import management from '../../Assests/Images/sectors/management.png';
import manufacturing from '../../Assests/Images/sectors/manufacturing.png';
import masscomm from '../../Assests/Images/sectors/mass comm.png';
import medicalstudies from '../../Assests/Images/sectors/medical studies.png';
import metaverse from '../../Assests/Images/sectors/metaverse.png';
import NGO from '../../Assests/Images/sectors/NGO.png';
import nursing from '../../Assests/Images/sectors/nursing.png';
import paramedical from '../../Assests/Images/sectors/paramedical.png';
import PR from '../../Assests/Images/sectors/PR.png';
import retail from '../../Assests/Images/sectors/retail.png';
import science from '../../Assests/Images/sectors/science.png';
import sports from '../../Assests/Images/sectors/sports.png';
import travel from '../../Assests/Images/sectors/travel.png';
import veterinary from '../../Assests/Images/sectors/veterinary.png';
import vocational from '../../Assests/Images/sectors/vocational.png';
import wellness from '../../Assests/Images/sectors/wellness.png';
import track from '../../Assests/Images/track.png';
import trackactive from '../../Assests/Images/trackactive.png';
import YCCvideo from '../../Assests/Videos/Ycc2022_RKR (1).mp4';
import RippleSlide from './ripple-slide';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Slicksettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
};


const SplideSetting = {
    type: "loop",
    perPage: 3,
    perMove: 3,
    autoPlay: false,
    keyboard: "focused", //enabling keyboard for focused element
    gap: 3,
    fixedWidth: "10rem",
    fixedHeight: "10rem",
    pagination: false

}

function fn() {
}

function Home() {
    const [nav1, setNav1] = React.useState(null)
    const [activeImage, setactiveImage] = useState(0)
    const [tabactive, settabactive] = useState(0)
    const [DataBlock, setDataBlock] = useState(0)
    const [addFixedHt, setaddFixedHt] = useState(true)
    const [Previmage, setPrevimage] = useState(-1)
    const [Counterscrolled, setCounterscrolled] = useState(false)
    const [IndustryAwar, setIndustryAwar] = useState(1)
    const [SelectedSlider, setSelectedSlider] = useState(0)
    const [activeNumber, setactiveNumber] = useState(0)
    const slider1 = useRef(null);
    const [industryData, setindustryData] = useState(1)
    const [futurestar, setfuturestar] = useState(1)
    const [futurestarData, setfuturestarData] = useState(1)
    const [connectwithus, setconnectwithus] = useState(2)
    const [connectwithusData, setconnectwithusData] = useState(2)
    const customeSlider = React.createRef();
    const [isIntern, setisIntern] = useState(false)
    const [Brand, setBrand] = useState(false)
    const [showFloatbtn, setshowFloatbtn] = useState(true)
    const [sheduleDateList, setsheduleDateList] = useState([])
    const [trackList, settrackList] = useState([])
    const [slectedDate, setslectedDate] = useState('')
    const [scheduleFadeOut, setscheduleFadeOut] = useState(true)
    const [panelLiSTdata, setpanelLiSTdata] = useState([])
    const [liveVideoStreaming, setliveVideoStreaming] = useState(0)
    const [YCCvideomute, setYCCvideomute] = useState(false)
    const [pageLoad, setpageLoad] = useState(false)
    const [showPanel, setshowPanel] = useState({
        Show: false,
        TrackId: 0
    })
    const  videoPlayer =  React.useRef()


    useEffect(() => {
        
       

        $('html,body').animate({
            scrollTop: 0
        }, 10);

        if ((window.matchMedia("(min-width: 991px)").matches)) {
            setshowFloatbtn(false)
        }

        document.getElementById("header").classList.add('transparent-backgror')
        document.body.style.overflow = "hidden"
        setTimeout(() => {

            let YCCImage = document.getElementsByClassName("YCCBg-image")[0].getBoundingClientRect()
          //  console.log('window.matchMedia(("(max-width: 568px)"))', window.matchMedia(("(max-width: 568px)")))
            let topvalueImge = window.matchMedia(("(max-width: 568px)")).matches ? YCCImage.top + (YCCImage.height * 0.25) :
                window.matchMedia(("(max-width: 991px)")).matches ? YCCImage.top + (YCCImage.height * 0.20) : YCCImage.top + (YCCImage.height * 0.28)
            topvalueImge = topvalueImge + 'px'
            let nextTop = window.matchMedia(("(max-width: 568px)")).matches ? YCCImage.top + (YCCImage.height * 0.75) :
                window.matchMedia(("(max-width: 991px)")).matches ? YCCImage.top + (YCCImage.height * 0.80) : YCCImage.top + (YCCImage.height * 0.80)
            nextTop = nextTop + 'px'
           // console.log('YCCImage', topvalueImge, nextTop, YCCImage.top, (YCCImage.height * 0.80), YCCImage.height * 0.25)
            document.getElementsByClassName('main-page-iframe')[0].style.top = topvalueImge
            document.getElementsByClassName('text-data')[0].style.top = nextTop
            if (window.matchMedia(("(min-width: 991px)")).matches) {
                let leftimgtop = YCCImage.top + (YCCImage.height * 0.225)
                leftimgtop = leftimgtop + 'px'
                document.getElementsByClassName('main-frame-left-video')[0].style.top = leftimgtop

                let leftimgleft = window.innerWidth * 0.03
                leftimgleft = leftimgleft + 'px'
                document.getElementsByClassName('main-frame-left-video')[0].style.left = leftimgleft


                let rigytimgtop = YCCImage.top + (YCCImage.height * 0.225)
                rigytimgtop = rigytimgtop + 'px'
                document.getElementsByClassName('main-frame-right-video')[0].style.top = rigytimgtop

                let rightimgleft = window.innerWidth * 0.03
                rightimgleft = rightimgleft + 'px'
                document.getElementsByClassName('main-frame-right-video')[0].style.right = rightimgleft
                setTimeout(() => {
                    document.getElementsByClassName('main-frame-right-video')[0].style.zIndex = 2
                }, 600)
                setTimeout(() => {
                    document.getElementsByClassName('main-frame-left-video')[0].style.zIndex = 2
                }, 600)
                setTimeout(() => {
                    document.getElementsByClassName('text-data')[0].style.zIndex = 2
                    //  document.body.style.overflow = "auto"
                }, 600)

            }
            else {
                setTimeout(() => {
                    document.getElementsByClassName('text-data')[0].style.zIndex = 2
                    //  document.body.style.overflow = "auto"

                }, 600)
            }

            setTimeout(() => {
                //  
               // document.getElementById("myVideo").play();
                document.body.style.overflow = "auto"
                setpageLoad(true)

            }, 700)
//             var video = document.getElementById("myVideo");
// console.log('video')
//             if (video.readyState === 4) {
//                // var x = document.getElementById("myVideo").autoPlay;
             
//                // setpageLoad(true)
//                 setTimeout(() => {
//                     console.log( document.getElementById("myVideo"))
//                     document.getElementById("myVideo").play();
//                     document.body.style.overflow = "auto"
//                     setpageLoad(true)
//                 }, 100)

//             }

// var video = document.getElementById("myVideo");

// video.onloadeddata = function() {
//     console.log('video',video)
//     setTimeout(() => {
//         console.log( document.getElementById("myVideo"))

//     }, 100)
// };


        }, 800)
        window.addEventListener('load', handleResize)

        window.addEventListener('scroll', handleScroll);

        document.body.style.background = "#1C1C1D"
        document.getElementById("header").style.height = "12vh"
        // document.getElementById("img1").style.width = "auto"
        // document.getElementById("img1").style.height = "8vh"


        return (() => {
            window.removeEventListener('scroll', handleScroll);
            document.body.style.background = "#fff"
            document.getElementById("header").style.height = "auto"
            // document.getElementById("img1").style.width = "40%"
            // document.getElementById("img1").style.height = "auto"
        })


    }, [])

    // useEffect(() => {
    //     if (performance.navigation.type === 1) {
    //         //$('#myVideo').trigger('play');
    //         //$("#video_player")[0].autoPlay = true;
    //        // alert('haiii')
    //         //$("#myVideo").prop('autoPlay', true); 

    //     } 
    //   });





    const handleResize = () => {
        $('html,body').animate({
            scrollTop: 0
        }, 0);
        setTimeout(() => {
            let YCCImage = document.getElementsByClassName("YCCBg-image")[0].getBoundingClientRect()
            //console.log('window.matchMedia(("(max-width: 568px)"))', window.matchMedia(("(max-width: 568px)")))
            let topvalueImge = window.matchMedia(("(max-width: 568px)")).matches ? YCCImage.top + (YCCImage.height * 0.25) :
                window.matchMedia(("(max-width: 991px)")).matches ? YCCImage.top + (YCCImage.height * 0.20) : YCCImage.top + (YCCImage.height * 0.28)
            topvalueImge = topvalueImge + 'px'
            let nextTop = window.matchMedia(("(max-width: 568px)")).matches ? YCCImage.top + (YCCImage.height * 0.75) :
                window.matchMedia(("(max-width: 991px)")).matches ? YCCImage.top + (YCCImage.height * 0.80) : YCCImage.top + (YCCImage.height * 0.80)
            nextTop = nextTop + 'px'
          //  console.log('YCCImage', topvalueImge, nextTop, YCCImage.top, (YCCImage.height * 0.80), YCCImage.height * 0.25)
            document.getElementsByClassName('main-page-iframe')[0].style.top = topvalueImge
            document.getElementsByClassName('text-data')[0].style.top = nextTop
            if (window.matchMedia(("(min-width: 991px)")).matches) {
                let leftimgtop = YCCImage.top + (YCCImage.height * 0.225)
                leftimgtop = leftimgtop + 'px'
                document.getElementsByClassName('main-frame-left-video')[0].style.top = leftimgtop

                let leftimgleft = window.innerWidth * 0.03
                leftimgleft = leftimgleft + 'px'
                document.getElementsByClassName('main-frame-left-video')[0].style.left = leftimgleft


                let rigytimgtop = YCCImage.top + (YCCImage.height * 0.225)
                rigytimgtop = rigytimgtop + 'px'
                document.getElementsByClassName('main-frame-right-video')[0].style.top = rigytimgtop

                let rightimgleft = window.innerWidth * 0.03
                rightimgleft = rightimgleft + 'px'
                document.getElementsByClassName('main-frame-right-video')[0].style.right = rightimgleft
                setTimeout(() => {
                    document.getElementsByClassName('main-frame-right-video')[0].style.zIndex = 2
                }, 600)
                setTimeout(() => {
                    document.getElementsByClassName('main-frame-left-video')[0].style.zIndex = 2
                }, 600)
                setTimeout(() => {
                    document.getElementsByClassName('text-data')[0].style.zIndex = 2
                }, 600)

            }
            else {
                setTimeout(() => {
                    document.getElementsByClassName('text-data')[0].style.zIndex = 2
                }, 600)
            }

        }, 800)
    }


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        vertical: true,
        slidesToScroll: 1,
        arrows: false,
        afterChange: (event, slick, currentSlide, nextSlide) => {
            if (SelectedSlider === activeNumber) {
                setSelectedSlider(event)
                setactiveNumber(event)
            }
            else {
                setSelectedSlider(event)
            }
        }
    };

    const handleScroll = () => {
        if (window.innerHeight * 0.88 > window.pageYOffset) {
            document.getElementById("header").classList.add('transparent-backgror')
        }
        else {
            document.getElementById("header").classList.remove('transparent-backgror')
        }
        // if ((window.$('.patrner-universe')[0].offsetTop - window.innerHeight * 0.2) > window.pageYOffset) {
        //     setaddFixedHt(true)
        // }

        if ((window.$('.partner')[0].offsetTop - window.innerHeight * 0.2) > window.pageYOffset) {
            setaddFixedHt(true)
        }

        else {
            setaddFixedHt(false)

        }
        if (document.getElementsByClassName('youth-blockss-sectionss-dataa-1').length > 0) {
            if ((window.$('.youth-blockss-sectionss-dataa-1')[0].offsetTop - window.innerHeight * 0.9) < window.pageYOffset) {
                setCounterscrolled(true)
            }
        }
        if ((window.matchMedia("(min-width: 991px)").matches)) {
            if (window.innerHeight * 0.75 >= window.pageYOffset) {
                setshowFloatbtn(false)
            }
            else {
                setshowFloatbtn(true)
            }
        }
        if ((window.matchMedia("(min-width: 991px)").matches)) {
            if (window.$('.future-imag-ht').length > 0) {
                if (window.$('.intership-block').length > 0) {
                    let scroller = document.getElementsByClassName('intership-block')[0]
                    let height = scroller.clientHeight;
                    let scrollHeight = scroller.scrollHeight - height;
                    let scrollTop = scroller.scrollTop;

                    let percent = Math.floor(scrollTop / scrollHeight * 100);
                    if (1 === 1) {

                        let percent = (window.pageYOffset / (height + (scroller.offsetTop - window.innerHeight * 0.45))) * 100
                        let offTop = scroller.offsetTop - window.innerHeight * 0.45

                        if (height > window.innerHeight * 0.60) {
                            if (scroller.offsetTop - window.innerHeight * 0.45 <= window.pageYOffset &&
                                (height + (scroller.offsetTop - window.innerHeight * 0.45)) >= window.pageYOffset) {
                                if ((scroller.offsetTop - window.innerHeight * 0.30) > window.pageYOffset) {

                                    let toppp = ((window.innerHeight * 0.55 -
                                        document.getElementsByClassName("future-imag-ht")[0].clientHeight) / 2)
                                    document.getElementsByClassName("future-imag-ht")[0].style.top = toppp + 'px';
                                    document.getElementsByClassName("future-imag-ht")[0].style.bottom = 'auto'
                                    document.getElementsByClassName("future-imag-ht")[0].style.position = 'absolute';
                                }
                                else if (((height + (scroller.offsetTop - window.innerHeight * 0.45)) - window.innerHeight * 0.44) < window.pageYOffset) {
                                    document.getElementsByClassName("future-imag-ht")[0].style.top = 'auto';
                                    document.getElementsByClassName("future-imag-ht")[0].style.bottom = '25vh'
                                    document.getElementsByClassName("future-imag-ht")[0].style.position = 'absolute';
                                }
                                else {
                                    document.getElementsByClassName("future-imag-ht")[0].style.position = 'fixed';
                                    document.getElementsByClassName("future-imag-ht")[0].style.bottom = 'auto'
                                    document.getElementsByClassName("future-imag-ht")[0].style.top = '50%'
                                }
                            }
                            else {

                                let toppp = ((window.innerHeight * 0.55 -
                                    document.getElementsByClassName("future-imag-ht")[0].clientHeight) / 2)
                                document.getElementsByClassName("future-imag-ht")[0].style.top = toppp + 'px';
                                document.getElementsByClassName("future-imag-ht")[0].style.bottom = 'auto'
                                document.getElementsByClassName("future-imag-ht")[0].style.position = 'absolute';
                            }
                        }
                        else {
                            document.getElementsByClassName("future-imag-ht")[0].style.top = 'auto';
                            document.getElementsByClassName("future-imag-ht")[0].style.bottom = 'auto'
                            document.getElementsByClassName("future-imag-ht")[0].style.position = 'static';
                        }
                    }
                    else {
                        document.getElementsByClassName("future-imag-ht")[0].style.top = 'auto';
                        document.getElementsByClassName("future-imag-ht")[0].style.bottom = 'auto'
                        document.getElementsByClassName("future-imag-ht")[0].style.position = 'static';
                    }
                }
                else {
                    if (document.getElementsByClassName("future-imag-ht").length > 0) {
                        document.getElementsByClassName("future-imag-ht")[0].style.top = 'auto';
                        document.getElementsByClassName("future-imag-ht")[0].style.bottom = 'auto'
                        document.getElementsByClassName("future-imag-ht")[0].style.position = 'static';
                    }
                }
            }
            if (window.$('.startupConatiner').length > 0) {
                if (window.$('.intership-block').length > 0) {
                    let scroller = document.getElementsByClassName('intership-block')[0]
                    let height = scroller.clientHeight;
                    let scrollHeight = scroller.scrollHeight - height;
                    let scrollTop = scroller.scrollTop;

                    let percent = Math.floor(scrollTop / scrollHeight * 100);
                    if (1 === 1) {

                        let percent = (window.pageYOffset / (height + (scroller.offsetTop - window.innerHeight * 0.45))) * 100
                        let offTop = scroller.offsetTop - window.innerHeight * 0.45

                        if (height > window.innerHeight * 0.70) {
                            if (scroller.offsetTop - window.innerHeight * 0.45 <= window.pageYOffset &&
                                (height + (scroller.offsetTop - window.innerHeight * 0.45)) >= window.pageYOffset) {
                                if ((scroller.offsetTop - window.innerHeight * 0.345) > window.pageYOffset) {

                                    let toppp = ((window.innerHeight * 0.53 -
                                        document.getElementsByClassName("startupConatiner")[0].clientHeight) / 2)
                                    document.getElementsByClassName("startupConatiner")[0].style.top = toppp + 'px';
                                    document.getElementsByClassName("startupConatiner")[0].style.bottom = 'auto'
                                    document.getElementsByClassName("startupConatiner")[0].style.position = 'absolute';
                                }
                                else if (((height + (scroller.offsetTop - window.innerHeight * 0.45)) - window.innerHeight * 0.50) < window.pageYOffset) {
                                    document.getElementsByClassName("startupConatiner")[0].style.top = 'auto';
                                    document.getElementsByClassName("startupConatiner")[0].style.bottom = '24vh'
                                    document.getElementsByClassName("startupConatiner")[0].style.position = 'absolute';
                                }
                                else {
                                    document.getElementsByClassName("startupConatiner")[0].style.position = 'fixed';
                                    document.getElementsByClassName("startupConatiner")[0].style.bottom = 'auto'
                                    document.getElementsByClassName("startupConatiner")[0].style.top = '50%'
                                }
                            }
                            else {

                                let toppp = ((window.innerHeight * 0.55 -
                                    document.getElementsByClassName("startupConatiner")[0].clientHeight) / 2)
                                document.getElementsByClassName("startupConatiner")[0].style.top = toppp + 'px';
                                document.getElementsByClassName("startupConatiner")[0].style.bottom = 'auto'
                                document.getElementsByClassName("startupConatiner")[0].style.position = 'absolute';
                            }
                        }
                        else {
                            document.getElementsByClassName("startupConatiner")[0].style.top = 'auto';
                            document.getElementsByClassName("startupConatiner")[0].style.bottom = 'auto'
                            document.getElementsByClassName("startupConatiner")[0].style.position = 'static';
                        }
                    }
                    else {
                        document.getElementsByClassName("startupConatiner")[0].style.top = 'auto';
                        document.getElementsByClassName("startupConatiner")[0].style.bottom = 'auto'
                        document.getElementsByClassName("startupConatiner")[0].style.position = 'static';
                    }
                }
                else {
                    if (document.getElementsByClassName("startupConatiner").length > 0) {
                        document.getElementsByClassName("startupConatiner")[0].style.top = 'auto';
                        document.getElementsByClassName("startupConatiner")[0].style.bottom = 'auto'
                        document.getElementsByClassName("startupConatiner")[0].style.position = 'static';
                    }
                }
            }
        }


        ///  startupConatiner
    }

    const ChangeTab = (id) => {
        let previamge = tabactive
        setactiveImage(id)
        setPrevimage(previamge)
        window.scrollTo({
            top: window.innerHeight * 0.9,
            left: 0,
            behavior: 'smooth'
        });
        setTimeout(() => {
            setPrevimage(-1)
        }, 300);
        setTimeout(() => {
            settabactive(id)
            setDataBlock(id)
            setindustryData(1)
            setfuturestarData(1)
            setfuturestar(1)
            setIndustryAwar(1)
            if (document.getElementsByClassName("future-imag-ht").length > 0) {
                document.getElementsByClassName("future-imag-ht")[0].style.top = 'auto';
                document.getElementsByClassName("future-imag-ht")[0].style.bottom = 'auto'
                document.getElementsByClassName("future-imag-ht")[0].style.position = 'static';
            }
            if (document.getElementsByClassName("startupConatiner").length > 0) {
                document.getElementsByClassName("startupConatiner")[0].style.top = 'auto';
                document.getElementsByClassName("startupConatiner")[0].style.bottom = 'auto'
                document.getElementsByClassName("startupConatiner")[0].style.position = 'static';
            }

            setTimeout(() => {
                if (document.getElementsByClassName('YCC-calender-conatiner-1').length > 0) {
                    document.getElementsByClassName('YCC-calender-conatiner-1')[0].scrollTo({ top: 0 })
                    let scr = (document.getElementById(slectedDate).getBoundingClientRect().top -
                        document.getElementsByClassName("YCC-calender-conatiner-1")[0].getBoundingClientRect().top)
                    document.getElementsByClassName("YCC-calender-conatiner-1")[0].scrollTo({
                        top: scr,
                        behavior: 'smooth',
                    })
                }
            }, 500);


        }, 300);


    }

    const AutoScroll = () => {
        window.scrollTo({
            top: window.$("#YCCMAINMENU")[0].offsetTop - window.innerHeight * 0.1,
            left: 0,
            behavior: 'smooth'
        });
    }

    const GoNext = async () => {
        // if (SelectedSlider < 8) {
        //    // document.getElementsByClassName("")[0].
        //    window.$(".arrow-down").attr("disabled", "disabled");
        //     await customeSlider.current.slickGoTo(SelectedSlider + 1)
        //     setTimeout(() => {
        //         console.log(SelectedSlider === 5 , SelectedSlider , activeNumber , activeNumber > 7)
        //         if(SelectedSlider === 5 &&  activeNumber <= 7)
        //         setactiveNumber(activeNumber => activeNumber + 1)
        //     }, 500)

        //     setTimeout(()=>{
        //         window.$(".arrow-down").removeAttr("disabled");
        //     },800)
        // }
        if (SelectedSlider === 5 && activeNumber <= 7) {
            setactiveNumber(activeNumber => activeNumber + 1)
        }
        else {
            window.$(".arrow-down").attr("disabled", "disabled");
            await customeSlider.current.slickGoTo(SelectedSlider + 1)

            setTimeout(() => {
                window.$(".arrow-down").removeAttr("disabled");
            }, 800)
        }
    }
    const Prev = async () => {
        if (activeNumber <= 5) {

            window.$(".arrow-up").attr("disabled", "disabled");
            await customeSlider.current.slickGoTo(SelectedSlider - 1)

            setTimeout(() => {
                window.$(".arrow-up").removeAttr("disabled");
            }, 800)
        }
        else {
            setactiveNumber(activeNumber => activeNumber - 1)
        }
    }

    const noop = () => {

    }

    const SetIndustry = (id) => {
        setIndustryAwar(id)
        setTimeout(() => {
            setindustryData(id)

        }, 600)
        if ((window.matchMedia("(min-width: 991px)").matches)) {
            setTimeout(() => {
                window.scrollTo({
                    top: window.pageYOffset + 1
                })
            }, 700)
        }
    }

    const SetFuture = (id) => {
        setfuturestar(id)
        setTimeout(() => {
            setfuturestarData(id)
        }, 600)
        if ((window.matchMedia("(min-width: 991px)").matches)) {
            setTimeout(() => {
                window.scrollTo({
                    top: window.pageYOffset + 1
                })
            }, 700)
        }
    }

    const SetConnect = (id) => {
        setconnectwithus(id)
        setTimeout(() => {
            setconnectwithusData(id)
        }, 600)
    }

    const OpenIntern = () => {
        setisIntern(true)
    }

    const closeIntern = () => {
        setisIntern(false)
    }

    const openBrand = () => {
        setBrand(true)
    }

    const closeBrand = () => {
        setBrand(false)
    }



    const ShowPanelModal = (TrackId) => {
        setshowPanel({
            ...showPanel,
            Show: true,
            TrackId: TrackId
        })

       // console.log('TrackId', TrackId)
    }

    const ClosePanelModal = () => {
        setshowPanel({
            ...showPanel,
            Show: false,
            TrackId: 0
        })
    }

    const restDataset = async (id) => {
       // console.log('haii iam herrre', id)

        let index = trackList.findIndex(x => x.TrackId === id)
        let trac = [...trackList]
        // trac.filter(x=> x.Live_Rec === 1).map((x,i)=>{

        //     trac[i].Live_Rec  = 0
        // })
        // trac[index].Live_Rec  = 1
        // console.log('trac',trac , index, trac[index].Live_Rec  )
        // await  settrackList(trac)
      //  console.log('')
        await settrackList(
            trackList.map(item =>
                item.TrackId === parseInt(id)
                    ? { ...item, Live_Rec: 1 }
                    : { ...item, Live_Rec: 0 }
            ))
        await setliveVideoStreaming(parseInt(id))

    }

    const VolumeChnages = () => {
        if ($("#myVideo").prop('muted')) {
            $("#myVideo").prop('muted', false);
        }
        else {
            $("#myVideo").prop('muted', true);
        }
        setYCCvideomute((val) => !val)

    }


    const RefreshFooter = () => {
        window.footer.relo();
        window.header.pageRender()
    }


    return (
        <div className='YCCConatiner'>
            {
                !pageLoad &&
                <div className="loader-bricks-container exp-loader-align-center yccpageslider-loader" >
                <div className="loader">
                    <ul>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                        <li><div></div></li>
                    </ul>
                </div>
            </div>
            }

            <div className='Home-Fsthalf'>
                <div className='reg-flip'>
                    {/* <div>
                        <div>Registation Start On</div>
                        <div>
                            <div>June 15</div>
                        </div>
                    </div> */}
                    {/* <div> </div> */}
                    <div className='overflow-datta-settts'>
                        <ul class="content__container__list">
                            {/* <li class="content__container__list__item"> <HashRouter> */}
                            {/* <Link to={Routing.registration} onClick={RefreshFooter} >Register Now</Link> </HashRouter></li> */}
                        </ul>
                        {/* <ul class="content__container__list">
                            {/* <li class="content__container__list__item">Registration starts on</li> */}
                        {/* <li class="content__container__list__item secon-datee">Soon</li>
                            <li class="content__container__list__item registeraion">Registration starts</li>
                            <li class="content__container__list__item">Soon</li>
                        </ul> */}
                        {/* <HashRouter>
                       <div className='reg-start-from  reg-start-alternative'><span className='blink_text'>
                        <Link to={Routing.registration} onClick={RefreshFooter} >Register Now</Link> </span></div>
                       </HashRouter> */}

                    </div>
                </div>
                <div className='text-data'>
                    15-DAYS, International Youth-centric, MEGA-EVENT.
                    Interactive sessions, Admissions & Placement Drive for Youth, of Youth and by Youth.
                    Establishing the 3-I (Individuals, Institutions & Industries) Connect for powerful engagement.

                    <div className='ycc-date-sect-home'>
                        
                    </div>
                    {/* The Mega Youth event of 2022 is here!! SKILLABLERS takes the YCC 2022 event to your college with Live interactions, workshops, information, corporate placement booths and more.... Be there!! */}
                    {/* <div><span className='dots-ycc-home'>YOUTH-CENTRIC MEGA-EVENT
</span> <span  >
</span> </div>
<div className='dots-ycc-home '> INTERACTIVE INDUSTRY SESSIONS & PLACEMENT DRIVE</div> */}
                </div>
                <div className='main-frame-left-video' style={{ backgroundImage: Group1 }} >
                </div>
                <div className='main-frame-right-video' style={{ backgroundImage: Group2 }}>
                </div>
                <div className='main-page-iframe' >
                    {/* <iframe src={YCCvideo}    /> */}
                    {/* <video  
                    autoPlay={true}
                     loop ={true}
                     width={'100%'} height={'100%'} id="myVideo" 
                    //onLoad={}
                    >
                      <source src={YCCvideo} type="video/mp4"></source>
                    </video>  */}
                    {/* <video 
                    autoPlay={true}
                    loop ={true}
                     src={YCCvideo}  loop id="myVideo"></video> */}
                      <video  autoPlay loop muted id="myVideo" >
                      <source src={YCCvideo} type="video/mp4"></source>
                    </video>
                </div>

                <div className='ycc-bg-section'><img src={window.matchMedia(("(max-width: 568px)")).matches ? Mob
                    : window.matchMedia(("(max-width: 991px)")).matches ? Tab : desk} className="YCCBg-image" /></div>
                <div className="scroll-for-more">
                    <div className="icon-scroll" onClick={() => { AutoScroll() }}></div>
                    <p className='cursor-pointer color-white margin-bottom-4' onClick={() => { AutoScroll() }}>Scroll For More</p>
                </div>
                {/* <div className=''>
                    <button className='ycc-home-button z-ined-2postition-realtive fixed-registerButton '>
                        <HashRouter><Link to={Routing.registration} onClick={RefreshFooter} ><img src={Reg} /><span className='Mml-2'>Register Now</span>
                        </Link></HashRouter></button>
                    </div> */}

            </div>
            <div>
                {/* <button className='ycc-home-button z-ined-2postition-realtive fixed-registerButton '>
                   <HashRouter><Link to={Routing.registration} onClick={RefreshFooter} ><><img src={Reg} />
                    Register Now</></Link></HashRouter></button> */}
            </div>
            <div id="YCCMAINMENU" className={addFixedHt ? 'sticky-main-head height-menu ' + 'height-menu-' + activeImage :
                'height-menu ' + 'height-menu-' + activeImage}>
                <div className={((tabactive === 0 && activeImage === tabactive) || Previmage === 0) ? 'menu-items active' : 'menu-items'} onClick={() => { ChangeTab(0) }}>
                    <img src={((tabactive === 0 && activeImage === tabactive) || Previmage === 0) ? Objec : Objec1} />
                    <div className='dispaly-main-menu-text'>ABOUT</div>
                </div>
                <div className={((tabactive === 5 && activeImage === tabactive) || Previmage === 5) ? 'menu-items active' : 'menu-items '}
                    onClick={() => { ChangeTab(5) }}
                >
                    <img src={((tabactive === 5 && activeImage === tabactive) || Previmage === 5) ? trackactive : track} />
                    <div className='dispaly-main-menu-text'>ACADEMIC &
                    </div>
                    <div className='dispaly-main-menu-text'>INDUSTRY SECTORS</div>
                </div>
                <div className={((tabactive === 1 && activeImage === tabactive) || Previmage === 1) ? 'menu-items active' : 'menu-items diabled'}
                >
                    <img src={((tabactive === 1 && activeImage === tabactive) || Previmage === 1) ? Sched1 : Sched} />
                    <div className='dispaly-main-menu-text'>SCHEDULE</div>
                </div>
                {/* <div className={((tabactive === 1 && activeImage === tabactive) || Previmage === 1) ? 'menu-items active' : 'menu-items diabled'}
                >
                    <img src={((tabactive === 1 && activeImage === tabactive) || Previmage === 1) ? Sched1 : Sched} />
                    <div className='dispaly-main-menu-text'>SCHEDULE</div>
                </div>
                <div className={((tabactive === 2 && activeImage === tabactive) || Previmage === 2) ? 'menu-items active' : 'menu-items diabled'}
                >
                    <img src={((tabactive === 2 && activeImage === tabactive) || Previmage === 2) ? Quiz1 : Quiz} />
                    <div className='dispaly-main-menu-text'>YCC2022 INDUSTRY</div>
                    <div className='dispaly-main-menu-text'> AWARENESS QUIZ</div>
                </div> */}
                <div className={((tabactive === 3 && activeImage === tabactive) || Previmage === 3) ? 'menu-items active' : 'menu-items diabled'}
                //  onClick={() => { ChangeTab(3) }}
                >
                    <img src={((tabactive === 3 && activeImage === tabactive) || Previmage === 3) ? StartUp : SatrtUp1} />
                    <div className='dispaly-main-menu-text'>SPOTLIGHT
                    </div>
                </div>
                <div className={((tabactive === 4 && activeImage === tabactive) || Previmage === 4) ? 'menu-items active' : 'menu-items '}
                    onClick={() => { ChangeTab(4) }}
                >
                    <img src={((tabactive === 4 && activeImage === tabactive) || Previmage === 4) ? Accociate : ActiveAccoci} />
                    <div className='dispaly-main-menu-text'>ASSOCIATE WITH</div>
                    <div className='dispaly-main-menu-text'>
                        SKL YCC</div>
                </div>
            </div>
            {(tabactive === 0) ?
                <div className={activeImage === 0 ? 'YCCFadeOut YCCFadeIn' : 'YCCFadeOutdata YCCFadedata'}>
                    <div className='ycc-main-head-daa'>
                        <div className='dontshow-desktop pb-0for-heda'>ABOUT</div>
                        <div>
                            <div className='globe-scetionsss'>
                                <RippleSlide />

                            </div>
                        </div>


                        <div className='p-btm-2vh-para'>The Youth are at crossroads. They are bracing a VUCA environment. Volatility, Uncertainty, Complexity and Ambiguity(VUCA) seem to define a NEW NORMAL.
                            <span> SKILLABLERS YOUTH CAREER CONNECT 2024 KERALA (SKL YCC 2024 KERALA)</span>  is a focused mega event to address their viewpoint and help them understand the new normal.</div>

                        <div className='p-btm-2vh-para'><span>SKILLABLERS</span> is spear-heading
                            this important and collective movement by connecting Individuals, Institutions and Industries (3-I Connect) together.
                            Youth will remain the focal point of our dialogue. We intend to address a wide spectrum of challenges and explore placement opportunities across industry verticals.</div>

                        <div className='p-btm-2vh-para'> To be held over 15-Days and at 15+ Institutional Venues, the event will explore clarity on What-n-How the future looks from the Youth-perspective.</div>

                    </div>
                    <div className='ycc-objective-data fle-col-tab'>

                        <div className='row mx-0 col-xl-9 col-md-12 col-lg-10 col-md-12 px-0 d-flex-fully-cent-tabview d-flex-cent1'>
                            <div className='col-lg-10 px-0 row mx-0'>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                    <div className='indu-brand'>
                                        <div className='indu-brand-fst ycc-about-1'>Industry & Youth Sync Sessions
                                        </div>
                                        <div className='infu-sec-text'>40 Live Interactive Industry Sessions across streams
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                    <div className='indu-brand'>
                                        <div className='indu-brand-fst yccabout-2'>Corporate Placement booths

                                        </div>
                                        <div className='infu-sec-text'>For spot internship and job offers, you just can't miss!

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                    <div className='indu-brand'>
                                        <div className='indu-brand-fst ycc-about-3'>Workshops by Life Coaches
                                        </div>
                                        <div className='infu-sec-text'>Get direction from the best to MAX out your future!!

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                    <div className='indu-brand'>
                                        <div className='indu-brand-fst ycc-about-4'>Future Star Start-Ups

                                        </div>
                                        <div className='infu-sec-text'>Pitch your business plans to potential investors for funding and mentorship

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                    <div className='indu-brand'>
                                        <div className='indu-brand-fst ycc-about-5'>What’s Hot

                                        </div>
                                        <div className='infu-sec-text'>Spot niche areas for careers of the future

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                    <div className='indu-brand'>
                                        <div className='indu-brand-fst ycc-about-6'>Industry Awareness Quiz Competitions

                                        </div>
                                        <div className='infu-sec-text'>Get quizzed by GIRI PICKBRAIN. Are you up to it??

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                    <div className='indu-brand'>
                                        <div className='indu-brand-fst ycc-about-7'>Learn 2 Grow

                                        </div>
                                        <div className='infu-sec-text'>Acquire soft skills to rise above the rest and Get noticed!!

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                    <div className='indu-brand'>
                                        <div className='indu-brand-fst ycc-about-8'>Entertainment Breakouts

                                        </div>
                                        <div className='infu-sec-text'>Offering a platform to the young & talented

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-11 px-0 ycc-objec-data d-flex-cent1'>
                               <div className='about-fst-section'> The Mega Youth event of 2022 is here!! SKILLABLERS takes the YCC 2022 event to your college 
                                   with Live interactions, workshops, information, corporate placement booths and more.... Be there!!</div>

                               <div className='about-main-point'> Industry & Youth Sync Sessions</div>
                               <div className='about-main-thr-data'>  40 Live Interactive Industry Sessions across streams </div>
                               <div className='about-main-point'>  Corporate Placement booths </div>
                               <div className='about-main-thr-data'> For spot internship and job offers, you just can't miss! </div>
                               <div className='about-main-point'> Workshops by Life Coaches </div>
                               <div  className='about-main-thr-data'>  Get direction from the best to MAX out your future!! </div>
                               <div className='about-main-point'> Future Star Start-Ups </div>
                               <div className='about-main-thr-data'> Pitch your business plans to potential investors for funding and mentorship </div>
                               <div className='about-main-point'>Entertainment Breakouts </div>
                               <div className='about-main-thr-data'>  Offering a platform to the young & talented  </div>
                               <div className='about-main-point'> Trending / What’s Hot </div>
                               <div className='about-main-thr-data'> Spot niche areas for careers of the future </div>
                               <div className='about-main-point'>Industry Awareness Quiz Competitions</div>
                               <div className='about-main-thr-data'> Get quizzed by GIRI PICKBRAIN. Are you up to it??</div>
                               <div className='about-main-point'> Learn 2 Grow</div>
                               <div className='about-main-thr-data'> Acquire soft skills to rise above the rest and Get noticed</div>
                            </div> */}
                        </div>
                    </div>
                    <div>
                        <div className='ycc-objec-head'>OBJECTIVES</div>
                        {window.matchMedia("(min-width: 568px)").matches ?
                            <>
                                <div className='col-xl-8 col-md-11 row mr-autoo ycc-obj-data-sectionss' >
                                    <div className='col-4 col-4-mob-lpadding'>
                                        <div className='content'>
                                            15-DAYS, INTERNATIONAL-LEVEL, MEGA-EVENT – packed with interactive sessions for Youth, of Youth and by Youth
                                        </div></div>
                                    <div className='col-4 col-4-mob-lpadding'>
                                        <div className='content'>
                                            15+ COLLEGE CAMPUS VENUES across the country</div>
                                    </div>
                                    <div className='col-4 col-4-mob-lpadding'><div className='content'> Multiple PANEL DISCUSSION SESSIONS directly addressing Youth</div>
                                    </div>
                                </div>
                                <div className='col-xl-8 col-md-11 row mr-autoo ycc-obj-data-sectionss'>
                                    <div className='col-4 col-4-mob-lpadding'><div className='content'>Connecting Youth (Individuals) with INSTITUTIONS for ADMISSIONS & PLACEMENTS</div>
                                    </div>
                                    <div className='col-4 col-4-mob-lpadding'><div className='content'>
                                        Connecting Youth (Individuals) with INDUSTRIES for PLACEMENTS through HACKATHONS, INDUSTRY AWARENESS QUIZZES (IAQ), WORKSHOPS and STUDENT INNOVATORS
                                    </div> </div>
                                    <div className='col-4 col-4-mob-lpadding'><div className='content'>
                                        Addressing apprehensions of YEEP stakeholders:
                                        <div className='Add-object'>
                                            <div>Youth – Fresher & Alumni </div>
                                            <div>Students (18-35 YRS)</div>
                                            <div>Employers</div>
                                            <div>Educationists</div>
                                            <div>Parents</div>  </div></div>
                                    </div>
                                </div>
                                <div className='col-xl-8 col-md-11 row mr-autoo ycc-obj-data-sectionss d-just-center'>
                                    <div className='col-4 col-4-mob-lpadding'><div className='content'>Perspective that matter the most today! – Maximum representation of Youth from across the country and beyond</div></div>
                                    <div className='col-4 col-4-mob-lpadding'><div className='content'>CAREER CONNECT Placement Drive for Fresher & Lateral Hiring</div>
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className='col-xl-8 col-11 row mr-autoo ycc-obj-data-sectionss' >
                                    <div className='col-6 col-4-mob-lpadding'>
                                        <div className='content'>
                                            15-DAYS, INTERNATIONAL-LEVEL, MEGA-EVENT – packed with interactive sessions for Youth, of Youth and by Youth
                                        </div></div>
                                    <div className='col-6 col-4-mob-lpadding'>
                                        <div className='content'>
                                            15+ COLLEGE CAMPUS VENUES across the country</div>
                                    </div>

                                </div>
                                <div className='col-xl-8 col-11 row mr-autoo ycc-obj-data-sectionss'>
                                    <div className='col-6 col-4-mob-lpadding'><div className='content'> Multiple PANEL DISCUSSION SESSIONS directly addressing Youth</div>
                                    </div>
                                    <div className='col-6 col-4-mob-lpadding'><div className='content'>Connecting Youth (Individuals) with INSTITUTIONS for ADMISSIONS & PLACEMENTS</div>
                                    </div>

                                </div>
                                <div className='col-xl-8 col-11 row mr-autoo ycc-obj-data-sectionss d-just-center'>
                                    <div className='col-6 col-4-mob-lpadding'><div className='content'>
                                        Connecting Youth (Individuals) with INDUSTRIES for PLACEMENTS through HACKATHONS, INDUSTRY AWARENESS QUIZZES (IAQ), WORKSHOPS and STUDENT INNOVATORS
                                    </div> </div>
                                    <div className='col-6 col-4-mob-lpadding'><div className='content'>
                                        Addressing apprehensions of YEEP stakeholders:
                                        <div className='Add-object'>
                                            <div>Youth – Fresher & Alumni </div>
                                            <div>Students (18-35 YRS)</div>
                                            <div>Employers</div>
                                            <div>Educationists</div>
                                            <div>Parents</div>  </div></div>
                                    </div>
                                </div>
                                <div className='col-xl-8 col-11 row mr-autoo ycc-obj-data-sectionss d-just-center'>
                                    <div className='col-6 col-4-mob-lpadding'><div className='content'>Perspective that matter the most today! – Maximum representation of Youth from across the country and beyond</div></div>
                                    <div className='col-6 col-4-mob-lpadding'><div className='content'>CAREER CONNECT Placement Drive for Fresher & Lateral Hiring</div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="youth-blockss-sectionss-dataa-1 datatsect-122">
                        <div className='contenet'>
                            <div className="numbertextt-data clr-1">{Counterscrolled ? <div><CountUp start={0} end={5} duration={2} />K+</div> :
                                '0k+'}</div>
                            <div>Individual Campus
                            </div>
                            <div>Audience</div>
                        </div>
                        <div className='contenet'>
                            <div className="numbertextt-data clr-2">{Counterscrolled ? <div><CountUp start={0} end={100} duration={2} />K+</div> :
                                '0+'}</div>
                            <div>In-Campus

                            </div>
                            <div>Registrations</div>
                        </div>
                        <div className='contenet'>
                            <div className="numbertextt-data clr-3">{Counterscrolled ? <div><CountUp start={0} end={100} duration={2} />K+</div> :
                                '0+'}</div>
                            <div>Online</div><div>
                                Registrations</div>
                        </div>
                        <div className='contenet'>
                            <div className="numbertextt-data clr-4">{Counterscrolled ? <div><CountUp start={0} end={40} duration={2} />+</div> :
                                '0+'}</div>
                            <div>Campus

                            </div>
                            <div>Venues</div>
                        </div>
                        <div className='contenet'>
                            <div className="numbertextt-data clr-5">{Counterscrolled ? <div><CountUp start={0} end={250} duration={2} />+</div> :
                                '0+'}</div>
                            <div>Institution


                            </div>
                            <div>Partners</div>
                        </div>
                        <div className='contenet'>
                            <div className="numbertextt-data clr-4">{Counterscrolled ? <div><CountUp start={0} end={4} duration={2} />-
                                <CountUp start={0} end={6} duration={2} /></div> :
                                '0+'}</div>
                            <div>Months Active


                            </div>
                            <div>Engagement</div>
                        </div>
                    </div>
                    {/* <div className='globe-scetionsss'>
                        <div className='globe-sect-mainn-landd'>
                            <div className='w-60percent' >
                                <img src={Ycchomeimage2} className="w-100per opac-reduce-image" />
                            </div>
                            <div className='w-40percent'>
                                <div className='just-cent-data-forGlobe'>
                                    <div className='reg-state-datat'>
                                        <div>
                                            <div> RECOGNIZED</div>
                                            <div>BY</div>
                                            <div className='whitespace-nowrap' >N.E.A.T AICTE</div>
                                        </div>
                                        <div>
                                            <img src={YCchomeimg3} className="img-w-95percent" />
                                        </div>
                                    </div>
                                    <div>
                                        <img src={Ycchomeimage1} className="img-w-full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='prev-block'>
                        <div className='prev-onYCC'>
                            PREVIOUSLY ON

                        </div>
                        <div className='prev-onYCC Prevfont-we'>YCC 2020</div>
                        <img src={PrevYCC} className="prev-event-image" />
                    </div>
                    <div>
                        <SwiperCoverflow />
                    </div>
                </div>
                :
                (tabactive === 1) ?
                    <></> :
                    (tabactive === 2) ?
                        <></>
                        :

                        (tabactive === 3) ?
                            <></> :

                            (tabactive === 4) ?
                                <div className={activeImage === 4 ? 'YCCFadeOut YCCFadeIn' : 'YCCFadeOutdata YCCFadedata'}>
                                    <div className='associate-conatiner flex-d-col-tab'>
                                        <div className='dontshow-desktop'><div> ASSOCIATE WITH SKL YCC 2022  </div></div>
                                        <div className='col-lg-8 col-11 px-0 row mx-0'>
                                            <div className='col-12 px-0 row mx-0 col-12 participate-imag'>
                                                <div className='col-lg-6 px-0'>
                                                    <div className='to-participate-data'> TO PARTICIPATE
                                                    </div>
                                                    <ul className='participate-ul'>
                                                        <li className='toparticipate-lits'>Enroll your Campus Venue for the Event</li>
                                                        <li className='toparticipate-lits'>Offer Student coordinators & Volunteers</li>
                                                        <li className='toparticipate-lits'>Share Local Vendor contacts</li>
                                                        <li className='toparticipate-lits'>Management or Faculty can become Panelists</li>
                                                        <li className='toparticipate-lits'>Share Media coverage and publicity on your handles</li>
                                                        <li className='toparticipate-lits'>Statutory permissions
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='col-lg-6 px-0 participate-scet'>
                                                    <img src={participate} />
                                                </div>
                                            </div>
                                            <div className='col-12 px-0 row mx-0 col-12 mb-2 participate-imag rorate-col'>
                                                <div className='col-lg-6 px-0 participate-scet'>
                                                    <img src={Placement} />
                                                </div>
                                                <div className='col-lg-6 px-0'>
                                                    <div className='to-work'> WE OFFER

                                                    </div>
                                                    <ul className='participate-ul'>
                                                        <li className='toparticipate-lits'>End-to-end Event - Plan, Organize and Conduct
                                                        </li>
                                                        <li className='toparticipate-lits'>Extensive Media Publicity, branding and Promotions
                                                        </li>
                                                        <li className='toparticipate-lits'>Industry connections for future networking
                                                        </li>
                                                        <li className='toparticipate-lits'>Management or Faculty can become Panelists</li>
                                                        <li className='toparticipate-lits'>Credits- get noticed by accrediting agencies like AICTE, NAC, NBA, University, etc.

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={connectwithus !== 2 ? 'tabsection interest-btn-center' : 'tabsection active interest-btn-center'}>

                                                <button className='ycc-home-button z-ined-2postition-realtive mt-3 no-z-index margin-btm-button-intern ' onClick={openBrand}>We're Interested</button> </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                (tabactive === 5) ?
                                    <div className={activeImage === 5 ? 'YCCFadeOut YCCFadeIn' : 'YCCFadeOutdata YCCFadedata'}>
                                        <div className='associate-conatiner flex-d-col-tab ycc-main-head-daa padd-btm-swipe-home'>
                                            <div className='dontshow-desktop'>ACADEMIC & INDUSTRY SECTORS</div>
                                            <div className='col-12 px-0 row mx-0 just-cent-dataset'>
                                                <div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={advertSect} />
                                                        <div>
                                                            Advertising & allied services
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={agersect} />
                                                        <div>
                                                            Agriculture & allied sciences
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={agreculsect} />
                                                        <div>
                                                            Architecture & allied sciences
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={atrlang} />
                                                        <div>
                                                            Arts & language studies
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={artde} />
                                                        <div>
                                                            Art & design
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={automobile} />
                                                        <div>
                                                            Automobile design & engineering
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={aviation} />
                                                        <div>
                                                            Aviation & allied services
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={audiovis} />
                                                        <div>
                                                            Audio visual engineering
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={science} />
                                                        <div>
                                                            Sciences
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={travel} />
                                                        <div>
                                                            Travel & tourism
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={banking} />
                                                        <div>
                                                            Banking & financial services
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={commerce} />
                                                        <div>
                                                            Commerce & trade
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={defence} />
                                                        <div>
                                                            Defense & armed services
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={dental} />
                                                        <div>
                                                            Dental sciences
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={digitalworld} />
                                                        <div>
                                                            Digital world
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={engg_civil} />
                                                        <div>
                                                            Engineering #1 (civil & environment)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={engg_electrical} />
                                                        <div>
                                                            Engineering #2 (ee, te & pe)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={engg_mechanical} />
                                                        <div>
                                                            Engineering #3 (me & eme)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={paramedical} />
                                                        <div>
                                                            Paramedical & physiotherapy
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={veterinary} />
                                                        <div>
                                                            Veterinary sciences
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={engg_comp} />
                                                        <div>
                                                            Engineering #4 (cs & ec)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={entertainment} />
                                                        <div>
                                                            Entertainment & the arts
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={ecommerce} />
                                                        <div>
                                                            E-commerce & logistics
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={edtech} />
                                                        <div>
                                                            Edtech & fintech
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={fashion} />
                                                        <div>
                                                            Fashion design & technology
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={futureskills} />
                                                        <div>
                                                            Future skills
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={Government} />
                                                        <div>
                                                            Government & governance
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={gaming} />
                                                        <div>
                                                            Gaming
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={vocational} />
                                                        <div>
                                                            Vocational skilling
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={wellness} />
                                                        <div>
                                                            Wellness & mental health sciences
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={healthcare} />
                                                        <div>
                                                            Healthcare (medical science & hospital administration)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={healthtech} />
                                                        <div>
                                                            Healthtech (biomedical engineering)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={Humanities} />
                                                        <div>
                                                            Humanities
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={Hospitality} />
                                                        <div>
                                                            Hospitality & hotel management
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={IT} />
                                                        <div>
                                                            IT & ITES
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={internationaleducation} />
                                                        <div>
                                                            International education
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={law} />
                                                        <div>
                                                            Law studies & legal services
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={management} />
                                                        <div>
                                                            Management (BBA, BBM, MBA & PGD)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={futureofschool} />
                                                        <div>
                                                            Future of schools
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={metaverse} />
                                                        <div>
                                                            Metaverse & mixed reality
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={manufacturing} />
                                                        <div>
                                                            Manufacturing (engineering & production)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={masscomm} />
                                                        <div>
                                                            Mass communication & journalism (PUB, TV & Radio)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={medicalstudies} />
                                                        <div>
                                                            Medical studies & health sciences
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={NGO} />
                                                        <div>
                                                            NGO sector & social sciences
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={nursing} />
                                                        <div>
                                                            Nursing & pharmacy
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={PR} />
                                                        <div>
                                                            PR & event management
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={retail} />
                                                        <div>
                                                            Retailing & consumer sciences
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={sports} />
                                                        <div>
                                                            Sports, fitness & sports administration
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={altersect} />
                                                        <div>
                                                            Alternative professions (RJ, DJ, VJ, Blogger, Vlogger, Photography, Podcaster, influencer)
                                                        </div>
                                                    </div>
                                                </div><div className='col-xl-2 col-md-3 col-4 px-0'>
                                                    <div className='image-sector-conatiner'>
                                                        <img src={converging} />
                                                        <div>
                                                            Converging industries
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='prev-block'>
                                            <div className='prev-onYCC'>
                                                PREVIOUSLY ON

                                            </div>
                                            <div className='prev-onYCC Prevfont-we'>YCC 2020</div>
                                            <img src={PrevYCC} className="prev-event-image" />
                                        </div>
                                        <div>
                                            <SwiperCoverflow />
                                        </div>
                                    </div>
                                    : <></>}
            <div className='partner'></div>
            {isIntern &&
                <Modal
                    isFrame={false}
                    Url={""}
                    close={closeIntern}
                    isIntern={true}
                />}
            {Brand &&
                <Modal
                    isFrame={false}
                    Url={""}
                    close={closeBrand}
                    isIntern={false}
                />}
        </div>
    )

}

export default React.memo(Home);