import React, { useEffect, useState } from 'react';
import { HashRouter, Link } from "react-router-dom";
import { Routing } from '../../Assests/js/routing';
import YCCLogo from '../../Assests/Images/YccLogo.png';
import Proc  from '../../Assests/Images/Pro.png';
import mail  from '../../Assests/Images/mail.png';
import {GetEventDetails} from './reg-methods';

function Congrates(){
    const [manage, setManage] = useState({
        QuizDate:""
    })
    // const navigate = useNavigate();
    // const {state} = useLocation();
    // useEffect(() => {
    //     if(state === null || !state.status){navigate(Routing.registration)}
    // })

    // useEffect(() => {
    //     GetCountryCodeList()    
    // }, [])
    
    // const GetCountryCodeList = () => {
    //     GetEventDetails()
    //     .then(result => {
    //         setManage({
    //             ...manage,
    //             QuizDate:result.data.recordsets[0][0].EventDateString
    //         })
    //     })
    // }

    return(
        <div className="min-height-conatainer pt-zero-imp">
            <div className="Ycc-reg-text">Welcome To Skillablers</div>
            <div className={"reg-main-wrapper reg-fst-conatiner active congrates-margin-bottom"}>
            <div className="row mx-0 col-xl-8 col-lg-10 col-md-8 col-10 px-0">
                <div className="col-lg-4 col-12 reg-fist-conatiner">
                    <div className="ycc-logo-conatiner">
                        <img src={YCCLogo} />
                    </div>
                    <div className="ycc-date">{(manage.QuizDate === undefined || 
                                manage.QuizDate === "" ||  manage.QuizDate === null) ? '05 SEP 2022 - 26 JAN 2023' :
                                manage.QuizDate   }</div>
                </div>
                {(window.matchMedia("(max-width: 568px)").matches) ?
                <div className="col-lg-8 col-12 regis-sec-section padding-congrate">
                <div className="enter-dts congrates-had">CONGRATULATIONS!</div>
                <div className='padding-btm-25px'>You have successfully registered for 
                    <HashRouter><Link  to={Routing.ycc} className='login-color-text' > "Skillablers Youth Career Connect 2022 (SKL YCC 2022)" </Link>  </HashRouter> event. You are now a registered user of Skillablers platform too!</div>
                    <div  className='padding-congrates-mail mail-section'>
                       <img   src={mail}/>
                       <div >
                           We have just mailed your login credentials and password to your registered email address.
                            Please <a href='https://indi.skillablers.com/indi-login' target='_blank' className='login-color-text'>LOGIN</a> to complete your Visual Profile and explore a host of services to enhance your career journey.
                           </div>
                   </div>
                   <div>
                  
                   It’s all for free for you to attend and participate Skillablers Youth Career Connect 2022 (SKL YCC 2022) event. The schedule for the same is  <HashRouter><Link  to={Routing.ycc} className='login-color-text disp-content' >here</Link></HashRouter>.</div>
                   <div>
                   <img src= {Proc} className="take-me-img" />
                   </div>
                   </div>:
                    <div className="col-lg-8 col-12 regis-sec-section padding-congrate">
                    <div className="enter-dts congrates-had">CONGRATULATIONS!</div>
                    <div className='padding-btm-25px'>You have successfully registered for 
                        <HashRouter><Link  to={Routing.ycc} className='login-color-text' > "Skillablers Youth Career Connect 2022 (SKL YCC 2022)" </Link>  </HashRouter> event. You are now a registered user of Skillablers platform too!</div>
                        <div  className='col-xl-6 col-lg-7 col-md-6 col-sm-9 col-10 padding-congrates-mail mail-section'>
                       <img   src={mail}/>
                       <div>
                           <div className='wid-142per'>
                           We have just mailed your login credentials and password to your registered email address.
                            Please <a href='https://indi.skillablers.com/indi-login' target='_blank'  className='login-color-text' >LOGIN</a> to complete your Visual Profile and explore a host of services to enhance your career journey.
                           </div>
                       </div>
                   </div>
                        <div className='row mx-0'>
                   <div className='col-md-5  px-0 tetx-align-leftt take-methere-sect conatiner-topvalue take-methere-sect  '>
                   <HashRouter> It’s all for FREE for you to attend and participate Skillablers Youth Career Connect 2022 (SKL YCC 2022).<br></br> The schedule for the same is <Link  to={Routing.ycc} className='login-color-text disp-content' >here</Link></HashRouter>.
                           </div>
                         <div className='col-md-7 px-0'>
                           <img src= {Proc} className="take-me-img" />
                       </div>
                   </div> 
                      
                       </div>
                   }
                   {/* <div className='row mx-0'>
                   <div className='col-md-5  px-0 tetx-align-leftt take-methere-sect conatiner-topvalue take-methere-sect  '>
                           It’s all for free for you to attend and participate Skillablers YCC 2022 event scheduled from AUGUST 15  - NOVEMBER 30 of 2022. The schedule for the same is here
                         </div>
                         <div className='col-md-7 px-0'>
                           <img src= {Proc} className="take-me-img" />
                       </div>
                   </div> */}
                   {/* <div className='col-12 px-0 row mx-0'>
                       <div className='col-md-5  px-0 '>
                           It’s all for free for you to attend and participate Skillablers YCC 2022 event scheduled from AUGUST 15  - NOVEMBER 30 of 2022. The schedule for the same is here
                         </div>
                       <div className='col-md-7 px-0'>
                           <img src= {Proc} className="take-me-img" />
                       </div>
                   </div> */}
                
                {/* <div className="col-lg-8 col-12 regis-sec-section padding-congrate">
                    <div className="enter-dts congrates-had">CONGRATULATIONS!</div>
                 
                    <div className='padding-btm-25px'>You have successfully registered for 
                    <HashRouter><Link  to={Routing.ycc} >Youth Career Connect 2022 </Link>  </HashRouter> event. You are now a registered user of Skillablers platform too!</div>
                    <div  className='col-xl-6 col-lg-7 col-md-6 col-sm-9 col-10 padding-congrates-mail mail-section'>
                       <img   src={mail}/>
                       <div>
                           <div>
                           We have just mailed your login credentials and password to your registered email address.
                            Please log in to complete your Visual Profile and explore a host of services to enhance your career journey.
                           </div>
                       </div>
                   </div>
                   <div className='col-12 px-0 row mx-0'>
                       <div className='col-md-5 px-0 tetx-align-leftt take-methere-sect conatiner-topvalue take-methere-sect '>
                           <div>
                           It’s all for free for you to attend and participate Skillablers YCC 2022 event scheduled from AUGUST 15  - NOVEMBER 30 of 2022. The schedule for the same is here
                           </div>
                           <div className='pt-3' ><span  className="next-button cursor-pointer "> TAKE ME THERE</span></div>
                       </div>
                       <div className='col-md-7 px-0'>
                           <img src= {Proc} className="take-me-img" />
                       </div>
                   </div>
                </div> */}
            </div>
        </div>
    </div>
    )
}


export default Congrates;