import axios from 'axios';
import { APIUrl,sphereAPIUrl } from '../../Assests/js/config';


export function GetAllJobs() {
    return axios({
      method: 'POST',
      url:  sphereAPIUrl+'/api/GetAllJobs',
      withCredentials: true,
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      data: {}
    })
      .then(result => {
        return result.data;
      }).catch(e => {
        // console.log(e);
      });
  }

export function GetAllClientDetails() {
    return axios({
      method: 'POST',
      url:  sphereAPIUrl+'/api/GetAllClientDetails',
      withCredentials: true,
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      data: {}
    })
      .then(result => {
        return result.data;
      }).catch(e => {
        // console.log(e);
      });
  }

  





  let Filter=[] 
  export function GetFunctionalSkillsCompanyName(Input) {
      axios({
          method: 'POST',
          url: 'https://i1testapi.skillablers.com/api/GetFunctionalSkillsCompanyName',
          withCredentials: true,
          headers: new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }),
          data:{
              InputValue : Input
          }
      })
          .then(res => {
              let result = res.data;
              // console.log('res',res)
              Filter = result.recordsets[0]
          }).catch(e => {
              // console.log(e);
          })
  
          if (Filter.length > 0) {
              return  Filter.filter(i =>
                 1===1
              );
          }        
  }
  