import React from "react";
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import { IndustryURL,IndusURL,Instituteurl } from "../../Assests/js/config";

import 'react-toastify/dist/ReactToastify.css';
import visualprofile_light from '../../Assests/Images/visualprofile-light.png'
import visualprofile_dark1 from '../../Assests/Images/visualprofile-dark1.png'
import profile360_light from '../../Assests/Images/profile360-light.png'
import profile360_dark from '../../Assests/Images/profile360-dark.png'
import jobconnect_dark from '../../Assests/Images/AACTe dark.png'
import jobconnect_light from '../../Assests/Images/AACTe.png'
import interview_dark from '../../Assests/Images/interview-dark.png'
import interview_light from '../../Assests/Images/interview-light.png'
import {IndividualUrllogin} from '../../Assests/js/config';
import Instdashboard from '../../Assests/Images/Instdashboard.png'
import Lmscourse from '../../Assests/Images/Lmscourse.png'
import InstSvp from '../../Assests/Images/InstSvp.png'
import Phonixpng from '../../Assests/Images/Phonixpng.png'
import upskillinggs from '../../Assests/Images/upskillinggs.png';
import onlineinterview from '../../Assests/Images/onlineinterview.png';
import careerCoun from '../../Assests/Images/careerCoun.png';
import c360light from '../../Assests/Images/connect360-light.png'
import c360dark from '../../Assests/Images/c360dark.png';
import c360lightv1 from '../../Assests/Images/c360lightv1.png';
import history from '../../../src/histoty';
import base64 from 'base-64'
import indulite from '../../Assests/Images/indulite.svg';
import indudark from '../../Assests/Images/indudark.svg';
import Instdashdark from '../../Assests/Images/Instdashdark.svg';
import Instdashlite from '../../Assests/Images/Instdashlite.svg';
import Instsvpdark from '../../Assests/Images/Instsvpdark.svg';
import Instsvplite from '../../Assests/Images/Instsvplite.svg';
import Instlmsdark from '../../Assests/Images/Instlmsdark.svg';
import instlmslite from '../../Assests/Images/instlmslite.svg';
import appleimg from '../../Assests/Images/AACTelarge.png'
import Requestdomologo from "../../Assests/Images/mobiledemologo.svg"
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import OnlineDemo from '../Skl_aspire_component/skl-aspire-onlinedemo'
import Processflowarrow from '../../Assests/Images/RightandLeftarrowinProcessFlow.svg'

import inquibyccseriesimgLight from '../../Assests/Images/inquibyccseriesimgLight.png'
import inquibyccseriesimgDark from '../../Assests/Images/inquibyccseriesimgDark.png'
import inquibSectorimglight from '../../Assests/Images/inquibSectorimglight.png'
import inquibSectorimgDark from '../../Assests/Images/inquibSectorimgDark.png'

import inquibOfferCoursesimaglight from '../../Assests/Images/inquibOfferCoursesimaglight.png'
import inquibOfferCoursesimagDark from '../../Assests/Images/inquibOfferCoursesimagDark.png'

import inquibAssementimaglight from '../../Assests/Images/inquibAssementimaglight.png'
import inquibAssementDark from '../../Assests/Images/inquibAssementDark.png'

import inquibEngageLight from '../../Assests/Images/inquibEngageLight.png'
import inquibEngageDark from '../../Assests/Images/inquibEngageDark.png'

import Sklaspirelogo from '../../Assests/Images/Sklaspirelogo.png'
import InquibimagProcess from '../../Assests/Images/InquibimagProcess.png'

import inquibsectorimags from '../../Assests/Images/inquibsectorimags.svg'
import quibsectomobiletabimag from '../../Assests/Images/quib-secto-mobile-tabimag.svg'


import EnquirenowQuib from '../../Assests/Images/EnquirenowQuib.svg'
import PartnernowQuib from '../../Assests/Images/PartnernowQuib.svg'
import freeimgicone from '../../Assests/Images/freeimgicone.svg';

import Dummycicleaspire from '../../Assests/Images/Dummycicleaspire.png';
import {RoutingUrl} from '../../Assests/js/config'

import orange from '../../Assests/Images/orangesklaspire.png';
import green from '../../Assests/Images/greensklaspire.png';
import violet from '../../Assests/Images/violetaspire.png';

import FeaturesHighlightDesk from '../../Assests/Images/FeaturesHighlightDesk.png';
import FeaturesHighlightMob from '../../Assests/Images/FeaturesHighlightMob.png';

import sklaspassemntLight from '../../Assests/Images/sklaspassemntLight.png';
import sklaspassemntDark from '../../Assests/Images/sklaspassemntDark.png';
import solutionsklaspLight from '../../Assests/Images/solutionsklaspLight.png';
import solutionsklaspDark from '../../Assests/Images/solutionsklaspDark.png';

import INTEGRATIONLight from '../../Assests/Images/INTEGRATIONLight.png';
import INTEGRATIONDark from '../../Assests/Images/INTEGRATIONDark.png';
import FEATURESLIGHT from '../../Assests/Images/FEATURESLIGHT.png';
import FEATURESDark from '../../Assests/Images/FEATURESDark.png';


import Sklaspstage1 from '../../Assests/Images/Sklaspstage1.png';
import Sklasptage2 from '../../Assests/Images/Sklasptage2.png';
import Sklaspstage3 from '../../Assests/Images/Sklaspstage3.png';













let scroll

let QuizUrl="http://localhost:3000/skillablers-aspire"
class SklAspire extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeData:1,
            skills: [],
            jobs :[],
            clientdetail: [],
            categ : [],
            catcomplogo:[],
            // searchSkills : '',
            searchSkills : [],
            textactive:1,
            Tab:1,
            PFCfiltredselected:'',
            CategHalf :[],
            selectedpack:'',
            checkedtrue:false,
            isReqstdemo:false,
            type:""
        }
    }

    componentDidMount(){
        //mobile view tabs process flow function
    // hide all contents accept from the first div
    window.$('html,body').animate({
        scrollTop: 0
    });
    $('.tabContent div:not(:first)').toggle();
    // hide the previous button
    $('.PreviousprocessFlow').hide();
    $('.tabs1 li').click(function () {
    if ($(this).is(':last-child')) {
            $('.nextprocessFlow').hide();
    } 
    else {
            $('.nextprocessFlow').show();
    }
    if ($(this).is(':first-child')) {
            $('.PreviousprocessFlow').hide();
    } 
    else {
            $('.PreviousprocessFlow').show();
    }
        var position = $(this).position();
            // console.log("position",position)
        var corresponding = $(this).data("id");
    // scroll to clicked tab with a little gap left to show previous tabs
        scroll = $('.tabs1').scrollLeft();
        // console.log("scroll",scroll)
        $('.tabs1').animate({
            'scrollLeft': scroll + position.left
           
        }, 200);
    // show content of corresponding tab
        $('div.' + corresponding).toggle();
    // remove active class from currently not active tabs
        $('.tabs1 li').removeClass('active');
    // add active class to clicked tab
        $(this).addClass('active');
    });

    $('.nextprocessFlow').click(function(e){
    e.preventDefault();
    $('li.active').next('li').trigger('click');
    if(scroll== 185|| scroll== 184||scroll== 159 || scroll == 160)
        {
          
            $('.nextprocessFlow').hide();
        }
    });

    $('.PreviousprocessFlow').click(function(e){
    e.preventDefault();
    $('li.active').prev('li').trigger('click'); 
    });
//mobile view tabs process flow function
    }
    visuaprofiling = (id) => {

        this.setState({
            activeData: id,
            textactive: 0
        }, () => {
            setTimeout(() => {
                this.setState(
                    {
                        textactive: id
                    }
                )
            }, 900)


        })
        window.$('html,body').animate({
            scrollTop: 0
        });
    }
    openinsti=()=>
    {
       window.open(Instituteurl+'/ins-login','_blank')
    }

    // popup for Request for demo

OpenRequestDemo = (type) => {

    this.setState({

        isReqstdemo : true,
        type:type
     

    })

}



 closeRequestDemo = () => {

   

   this.setState({

    isReqstdemo : false,
    type:""

})

}

 toastMessage=()=>{

    toast.success('Thank you for your request. Our team will contact you soon.', {    

        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        // autoClose: 5555000,
        theme: "colored"

    })

 }

 setlasttabactive=(id)=>
 {
    this.setState(
        {
            activeData:id
        }
    )
    window.$('html,body').animate({
        scrollTop: 0
    });
 }

 redirectinstituteReg=()=>
 {
    window.open(Instituteurl+'/ins-registration', '_blank')
 }
 redirectindustryReg=()=>
 {
    window.open(IndusURL+'/indu-registration', '_blank')
 }
 redirectGuestLogin=()=>
 {
     window.open(IndividualUrllogin+'/indi-guest-login', '_blank')
 }


 aspireenquiryform=()=>
 {
    window.open('#' +Routing.sklenquiry, '_self')
 }

//quiz assement
 redirecttoQuiz = (QuizId,IsRetake)=>{
    let canid='CAN19A00267'
    // let canid='CAN23A19065'
    let obj = {'Quiz_Id':QuizId,"RedirectURL":window.location.href,"CANUID":canid,"Is_Retake":IsRetake}
    obj = base64.encode(JSON.stringify(obj))
    let URL =  QuizUrl +'?'+ obj;
    let a = document.createElement('a');
    a.target = '_self';
    a.href = URL;
    a.click();
}

    render(){
        return(
           
            <div>
                 <div className="indi-conatiner bg-roadmap d-flex mb-3" >
            
                 <div className="indihomeland-subwrap" id="popupdetail"></div>
                 </div>
                <div className="mb-style-changewrap paddtopdeskNewflows Desktopnewdesign" id="Processflow">
                <div className="col-lg-12 col-md-12 col-3 px-0 row mx-0 d-flex justify-content-center  directmbcloum ">
                    <div className="  col-md-2 col-lg-2 px-0  pubjd">
                        <a target='_blank'>
                            <div className={this.state.activeData === 1 ? "skl-productss active  Brradius-indu" : "skl-productss   Brradius-indu"}
                                onClick={()=>this.visuaprofiling(1)}
                            >
                                <div className="indu-image-blocks">
                                    <img src= {this.state.activeData === 1 ? sklaspassemntDark : sklaspassemntLight} className="Brradius-indu"/>
                                </div>
                                <div>
                                    <div className="skl-prod-name bold-efeect" id='VProfile'>ADMISSION ASSESSMENT TEST</div>
                                </div>
                           </div>
                        </a>
                    </div>
                    <div className="   col-md-2 col-lg-2   px-0">
                        <a target='_blank'>
                            <div className={this.state.activeData === 2 ? "skl-productss active  Brradius-indu" : "skl-productss Brradius-indu"}
                                onClick={()=>this.visuaprofiling(2)} >
                                    <div className="indu-image-blocks">
                                        <img src= {this.state.activeData === 2 ? solutionsklaspDark : solutionsklaspLight} className="Brradius-indu"
                                            // onMouseOver={e => e.currentTarget.src = P360_1}
                                            // onMouseOut={e => e.currentTarget.src = P360}
                                       />
                                    </div>
                                    <div>
                                        <div className="skl-prod-name bold-efeect">THE SOLUTION</div>
                                                   
       
                                               </div>
                                           </div>
                                       </a>
                                   </div>
                                   
                                   <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
       
                                       <div className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                           onClick={()=>this.visuaprofiling(3)}>
                                           <div className="indu-image-blocks">
                                               <img src={this.state.activeData === 3 ? FEATURESDark  : FEATURESLIGHT} className="Brradius-indu"
                                               />
                                           </div>
                                           <div>
                                               <div className="skl-prod-name bold-efeect">FEATURES HIGHTLIGHT</div>
       
                                           </div>
                                       </div>
                                   </div>
                                   <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
                                       <a target='_blank'>
                                           <div className={this.state.activeData === 4 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(4)}>
                                               <div className="indu-image-blocks">
                                                   <img src={this.state.activeData === 4 ? INTEGRATIONDark : INTEGRATIONLight} className="Brradius-indu"
                                                   // onMouseOver={e => e.currentTarget.src = online1}
                                                   // onMouseOut={e => e.currentTarget.src = online}
                                                   />
                                               </div>
                                               <div>
                                               <div className="skl-prod-name bold-efeect">INTEGRATION</div>
       
                                               </div>
                                           </div>
                                       </a></div>
 
                                       <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                                       <a target='_blank'>
                                           <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(5)}>
                                               <div className="indu-image-blocks">
                                                  <img src={this.state.activeData === 5 ? inquibOfferCoursesimagDark  : inquibOfferCoursesimaglight} className="Brradius-indu"
                                               />
                                               </div>
                                               <div>
                                                   <div className="skl-prod-name bold-efeect"><span className="txt-trans">VALUE PROPOSITION</span></div>
       
                                               </div>
                                           </div>
                                       </a></div>
                                      
                                  
                               </div>
 
                               <div className={this.state.activeData === 1 ? "col-sm-12 col-9 px-0 indu-data-containerss active fst-border" :
                                   this.state.activeData === 5 ? "col-sm-12 col-9  px-0 indu-data-containerss active lst-border" : "col-sm-12 col-9  px-0 indu-data-containerss active all-border "}>
                                   <div className="col-12 px-0 row mx-0   tabsec-center  mi-heigh-overall">
                                    
                                    {this.state.activeData === 1 ?
                                    <div>
                                      <div className="row alignaccordinginquib">
                                        <div className="col-lg-4">
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={orange} className="inquibyccfreeimag sklaspnewwidthimg"></img></div>
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Pre-assess ALL candidate's academic ability and potential for success in their chosen field of study.</div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={green} className="inquibprocess sklaspnewwidthimg"></img></div>
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Designed to effortlessly conduct and manage your institution's own admission tests across departments.</div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={violet} className="inquibprocess sklaspnewwidthimg"></img></div>
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Acquire admission assessment results to effortlessly identify and organize all candidates - instantly.</div>
                                        </div>
                                      </div>
            
                                     <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                     <button className="createprofile meet-our-team texnonetransfor"  onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                        </div>
                                        
                                    </div>
                                    :
                                    this.state.activeData === 2 ?
                                        <div>
                                        <div>
                                             <div className="">
                                                <div className="rqst-heading  text-trns-demo fntsizepopuptext">To effectively conduct Admission Assessment Tests, educational institutions need to increasingly turning to digital solutions</div>
                                                <div className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2"><span className="sklaspbold">SKL-ASPIRE </span> – powered by the patented <span className="sklaspbold">SKILLABLERS</span> Platform – streamlines and ensures 100% efficiency, fairness, and effectiveness in selecting candidates foradmissions.</div>
                                             </div>
                                             <div>
                                             <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Online Registration and Admission Assessment Test Availability</span></div>
                                             <ul className="listparent-quib alignheadingandbelowtextsklaspire">
                                                <li className="rqst-heading  text-trns-demo fntsizepopuptext listyleaspire">IAQs assess and validate prospective recruits' knowledge and competence in their respective industries, ensuring that they are well-informed to perform their roles effectively.</li>
                                                <li className="rqst-heading  text-trns-demo fntsizepopuptext listyleaspire mt-2">This approach ensures that the admission process is always available and accessible to all applicants, eliminating issues related to the physical distribution of forms.</li>
                                            </ul>
                                            </div>
                                            <div>
                                             <div className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Online Admission Assessment Test Technology</span></div>
                                             <ul className="listparent-quib alignheadingandbelowtextsklaspire">
                                                <li className="rqst-heading  text-trns-demo fntsizepopuptext listyleaspire"><span className="sklaspbold">SKL-ASPIRE </span>  provides the capability to conduct objective testing online. This includes a variety of question types and even assessments for traits like leadership,teamwork, motivation, and career preferences.</li>
                                                <li className="rqst-heading  text-trns-demo fntsizepopuptext listyleaspire mt-2">The robust technology also allows for thinking skill assessments to evaluate students' problem-solving abilities.</li>
                                            </ul>
                                            </div>
                                            <div>
                                             <div  className="subheadaspiresecondtab"><span className="bldtex-list-quib sklasptexthead">Use of AI in Admissions</span></div>
                                             <ul className="listparent-quib alignheadingandbelowtextsklaspire">
                                                <li className="rqst-heading  text-trns-demo fntsizepopuptext listyleaspire"><span className="sklaspbold">SKL-ASPIRE </span>  will transform the admissions process by automating admission assessment test workflows, scoring capabilities and compatibility, managing stakeholders, and shortlisting ALL candidates by their scores in descending order.</li>
                                                <li className="rqst-heading  text-trns-demo fntsizepopuptext listyleaspire mt-2"><span className="sklaspbold">SKILLABLERS</span> Platform can also identify skill gaps and provide real-time analytics, thus enhancing the efficiency of the admission process and improving outcomes.</li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                     <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                        </div>
                                       </div>
                                    :
                                      this.state.activeData === 3 ?
                                   <div>
                                     <div className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-3 "><span className="sklaspbold">SKL-ASPIRE </span>  contributes to the development of a robust, efficient, and fair digital solution for entrance tests in education institutions</div>
                
                                           <div className="row mx-0">
                                            <div className="">
                                                <img src={FeaturesHighlightDesk} className="widsector-tab-imag-quib"></img>
                                            </div>
                                           </div> 
                                           <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                           </div>
                                        </div>
                                  
                                    
                                    :
                                    this.state.activeData === 4 ?
                                    <div>
                                        <div className="row alignaccordinginquib">
                                        <div className="col-lg-4">
                                            <div className="stagesclasese"><div>STAGE 1</div></div>
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={Sklaspstage1} className="inquibyccfreeimag"></img></div>
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Application acceptance and processing</div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div  className="stagesclasese"><div>STAGE 2</div></div>
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={Sklasptage2} className="inquibprocess"></img></div>
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">SKL – ADMISSION ASSESSMENT TEST</div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div  className="stagesclasese"><div>STAGE 3</div></div>
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={Sklaspstage3} className="inquibprocess"></img></div>
                                            <div className="aspcirclecent  rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Student fee processing and collection</div>
                                        </div>
                                      </div>
                                        <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                     <button className="createprofile meet-our-team texnonetransfor"  onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                        </div>
                                    </div>
                                    :
                                    this.state.activeData === 5 ?
                                    <div>
                                    <div>
                                         <div className="">
                                          <div className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2"><span className="sklaspbold">SKL-ASPIRE </span>  will enable educational institutions to adopt Admission Assessment Test’s (AAT) digital and online solutions with the following measurable benefits</div>
                                         </div>
                                         <div>
                                         <ul className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Efficiency</li></ul>
                                         <div className="asptextleftandright">
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext "><span className="sklaspbold">SKL-ASPIRE’s</span> digital solution streamlines the entire testing process, from test creation to result dissemination</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Accessibility</li></ul>
                                         <div className="asptextleftandright">
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext "><span className="sklaspbold">SKL-ASPIRE’s</span> can be taken from any location, instantly making them more accessible to a wider range of applicants</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Scalability</li></ul>
                                         <div className="asptextleftandright">
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext "><span className="sklaspbold">SKL-ASPIRE </span>  can handle large number of applicants simultaneously, which is ideal for institutions with extensive applicant pools</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">High Cost Effective</li></ul>
                                         <div className="asptextleftandright">
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext "><span className="sklaspbold">SKL-ASPIRE </span>  reduces the need for physical infrastructure and manpower, thus lowering costs drastically</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Real-Time Analytics</li></ul>
                                         <div className="asptextleftandright">
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext "><span className="sklaspbold">SKL-ASPIRE </span>  enables instant analytics and feedback – thereby aiding in quicker decision-making</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Flexibility</li></ul>
                                         <div className="asptextleftandright">
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext "><span className="sklaspbold">SKL-ASPIRE </span>  offers flexibility in scheduling and conducting tests, which is beneficial for both institutions and applicants</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Security</li></ul>
                                         <div className="asptextleftandright">
                                            <div className="rqst-heading  text-trns-demo fntsizepopuptext "><span className="sklaspbold">SKL-ASPIRE’s</span> advanced security features minimize the risks of cheating and test content leakage</div>
                                        </div>
                                        </div>
                                        
                                        <div className="">
                                          <div className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">In summary, <span className="sklaspbold">SKL-ASPIRE </span>  offers a modern, efficient, and flexible approach to conducting entrance exams – essential in today's rapidly evolving educational landscape.</div>
                                        </div>
                                    </div>
                                    <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                 <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                    </div>
                                   </div>
                                    :
                                    this.state.activeData === 6 ?
                                    <>
                                     <div>
                                      <div className="row  mx-0">
                                        <div className="col-lg-12">
                                            <div className="disflexiinquibmob mb-1"><img src={Sklaspirelogo} className="inquibyccfreeimag"></img></div>
                                        </div>
                                      </div>
                                      <div className="row mx=0">
                                        <div className=" Inquib-corporateText aspirewidtab6 textcolrtab6cuttingedge">
                                        Cutting-edge technology solution to seamlessly manage 
                                        institution admission assessment test process – instantly
                                        </div>
                                        <div className="col-12 Inquib-corporateText martop-aspire-sch-eng Robototextligth">Launch a Live Demo<button className="createprofile meet-our-team texnonetransfor margskladspbut" onClick={() => { this.OpenRequestDemo("SKLASPIREPolicy") }}>Start Demo</button></div>
                                        <div className="col-12 Inquib-corporateText Robototextligth">Engage Ahead<button className="createprofile meet-our-team texnonetransfor margskladspbut" onClick={this.aspireenquiryform}>Partner Now</button></div>
                                       {/* <button className="createprofile meet-our-team texnonetransfor margskladspbut" onClick={()=>this.redirecttoQuiz(173,1)}>sklasp</button> */}

                                      </div>
                                     

                                    </div>
                                    </>
                                    :
                                    <></>
                                    }
                                   </div>
                               </div>
                            

                              
                               </div>
            
                <div className="MobileviewnewDesign">
                        <div className="indiproceswrap">
                        <div class="contentWrapperProcessflow">
                            <div class="tabsWrapperProcessflow">
                                <ul class="tabs1">
                                    <li data-id="contentOne" class="active"><span className={this.state.activeData=== 1?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(1)}>ADMISSION ASSESSMENT TEST</span><div className={this.state.activeData=== 1?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentTwo"><span className={this.state.activeData=== 2?"pfscoreclr fntwettext ":"pfscoreclr"} onClick={()=>this.visuaprofiling(2)}>THE SOLUTION </span><div className={this.state.activeData=== 2?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentThree"><span className={this.state.activeData=== 3? "pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(3)}>FEATURES HIGHTLIGHT</span><div className={this.state.activeData=== 3?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentFour"><span className={this.state.activeData=== 4?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(4)}>INTEGRATION</span><div className={this.state.activeData=== 4?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentSix"><span className={this.state.activeData=== 5?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(5)}>VALUE PROPOSITION</span><div className={this.state.activeData=== 5?"bdbottomclrnew ":""}></div></li>
                                </ul>
                            </div> 
                        <span class="nextprocessFlow">
                            <img src={Processflowarrow}></img>
                        </span>
                        <span class="PreviousprocessFlow">
                        <img src={Processflowarrow}></img>
                        </span>

                        </div>
                        </div>
                        {this.state.activeData === 1 ?
                                   <div>
                                   <div className="row alignaccordinginquib wi80margautomob">
                                     <div className="col-lg-4 px-0">
                                         <div className="makecenterfsttabimag-quib aspcirclecent"><img src={orange} className="inquibyccfreeimag sklaspnewwidthimg"></img></div>
                                         <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Pre-assess ALL candidate's academic ability and potential for success in their chosen field of study.</div>
                                     </div>
                                     <div className="col-lg-4 px-0">
                                         <div className="makecenterfsttabimag-quib aspcirclecent"><img src={green} className="inquibprocess sklaspnewwidthimg"></img></div>
                                         <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Designed to effortlessly conduct and manage your institution's own admission tests across departments.</div>
                                     </div>
                                     <div className="col-lg-4  px-0">
                                         <div className="makecenterfsttabimag-quib aspcirclecent"><img src={violet} className="inquibprocess sklaspnewwidthimg"></img></div>
                                         <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Acquire admission assessment results to effortlessly identify and organize all candidates - instantly.</div>
                                     </div>
                                   </div>
         
                                  <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                  <button className="createprofile meet-our-team texnonetransfor"onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                     </div>
                                     
                                 </div>
                                
                                :
                                    this.state.activeData === 2 ?
                                    <div>
                                       {/* <div className="row mx-0">
                                        <div className="">
                                            <img src={quibsectomobiletabimag} className="widsector-tab-imag-quib"></img>
                                        </div>
                                       </div>  */}
                                       <div className="wid90mobTab2">
                                             <div className="">
                                                <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext disblockmobtab2">To effectively conduct Admission Assessment Tests, educational institutions need to increasingly turning to digital solutions</div>
                                                <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span> – powered by the patented <span className="sklaspbold">SKILLABLERS</span> Platform – streamlines and ensures 100% efficiency, fairness, and effectiveness in selecting candidates foradmissions.</div>
                                             </div>
                                             <div>
                                             <div className="subheadaspiresecondtab asptab2lineheight"><span className="bldtex-list-quib sklasptexthead">Online Registration and Admission Assessment Test Availability</span></div>
                                             <ul className="listparent-quib  alignheadingandbelowtextsklaspire">
                                                <li className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext listyleaspire disblockmobtab2">IAQs assess and validate prospective recruits' knowledge and competence in their respective industries, ensuring that they are well-informed to perform their roles effectively.</li>
                                                <li className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext listyleaspire mt-2 disblockmobtab2">This approach ensures that the admission process is always available and accessible to all applicants, eliminating issues related to the physical distribution of forms.</li>
                                            </ul>
                                            </div>
                                            <div>
                                             <div className="subheadaspiresecondtab asptab2lineheight"><span className="bldtex-list-quib sklasptexthead">Online Admission Assessment Test Technology</span></div>
                                             <ul className="listparent-quib alignheadingandbelowtextsklaspire">
                                                <li className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext listyleaspire disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span> provides the capability to conduct objective testing online. This includes a variety of question types and even assessments for traits like leadership,teamwork, motivation, and career preferences.</li>
                                                <li className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext listyleaspire mt-2 disblockmobtab2">The robust technology also allows for thinking skill assessments to evaluate students' problem-solving abilities.</li>
                                            </ul>
                                            </div>
                                            <div>
                                             <div  className="subheadaspiresecondtab asptab2lineheight"><span className="bldtex-list-quib sklasptexthead">Use of AI in Admissions</span></div>
                                             <ul className="listparent-quib alignheadingandbelowtextsklaspire">
                                                <li className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext listyleaspire disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span>  will transform the admissions process by automating admission assessment test workflows, scoring capabilities and compatibility, managing stakeholders, and shortlisting ALL candidates by their scores in descending order.</li>
                                                <li className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext listyleaspire mt-2 disblockmobtab2"><span className="sklaspbold">SKILLABLERS</span> Platform can also identify skill gaps and provide real-time analytics, thus enhancing the efficiency of the admission process and improving outcomes.</li>
                                            </ul>
                                            </div>
                                        </div>
                                       <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                            <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                       </div>
                                    </div>
                                
                                    :
                                    this.state.activeData === 3 ?
                                    <div>
                                         <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 ml-3 disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span>  contributes to the development of a robust, efficient, and fair digital solution for entrance tests in education institutions</div>
                                    <div className="row mx-0">
                                     <div className="">
                                         <img src={FeaturesHighlightMob} className="widsector-tab-imag-quib"></img>
                                     </div>
                                    </div> 
                                    <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                         <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                    </div>
                                 </div>
                                   :
                                    this.state.activeData === 4 ?
                                    <div>
                                        <div className="wid90mobTab2">
                                        <div>
                                        <div className="row alignaccordinginquib">
                                        <div className="col-lg-4">
                                            <div className="stagesclasese"><div>STAGE 1</div></div>
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={Sklaspstage1} className="inquibyccfreeimag "></img></div>
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Application acceptance and processing</div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div  className="stagesclasese"><div>STAGE 2</div></div>
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={Sklasptage2} className="inquibprocess "></img></div>
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">SKL – ADMISSION ASSESSMENT TEST</div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div  className="stagesclasese"><div>STAGE 3</div></div>
                                            <div className="makecenterfsttabimag-quib aspcirclecent"><img src={Sklaspstage3} className="inquibprocess"></img></div>
                                            <div className="aspcirclecent  rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2">Student fee processing and collection</div>
                                        </div>
                                      </div>
                                        
                                    </div> 
                                        </div>
                                        <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                     <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                        </div>
                                    </div>
                                    :
                                     this.state.activeData === 5 ?
                                     <div>
                                        <div className="wid80mobTab5"> 
                                         <div className="">
                                          <div className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 aspcirclecent disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span>  will enable educational institutions to adopt Admission Assessment Test’s (AAT) digital and online solutions with the following measurable benefits</div>
                                         </div>
                                         <div>
                                         <ul className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Efficiency</li></ul>
                                         <div className="asptextleftandright">
                                            <div className=" aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE’s</span> digital solution streamlines the entire testing process, from test creation to result dissemination</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Accessibility</li></ul>
                                         <div className="asptextleftandright">
                                            <div className=" aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE’s</span> can be taken from any location, instantly making them more accessible to a wider range of applicants</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Scalability</li></ul>
                                         <div className="asptextleftandright">
                                            <div className=" aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span>  can handle large number of applicants simultaneously, which is ideal for institutions with extensive applicant pools</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">High Cost Effective</li></ul>
                                         <div className="asptextleftandright">
                                            <div className=" aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span>  reduces the need for physical infrastructure and manpower, thus lowering costs drastically</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Real-Time Analytics</li></ul>
                                         <div className="asptextleftandright">
                                            <div className=" aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span>  enables instant analytics and feedback – thereby aiding in quicker decision-making</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Flexibility</li></ul>
                                         <div className="asptextleftandright">
                                            <div className="aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE </span>  offers flexibility in scheduling and conducting tests, which is beneficial for both institutions and applicants</div>
                                        </div>
                                        </div>
                                        <div>
                                         <ul  className="subheadaspiresecondtab"><li className="bldtex-list-quib listyleaspire tab5headclraspire">Security</li></ul>
                                         <div className="asptextleftandright">
                                            <div className=" aspcirclecent rqst-heading  text-trns-demo fntsizepopuptext disblockmobtab2"><span className="sklaspbold">SKL-ASPIRE’s</span> advanced security features minimize the risks of cheating and test content leakage</div>
                                        </div>
                                        </div>
                                        
                                        <div className="">
                                          <div className="rqst-heading  text-trns-demo fntsizepopuptext mt-2 mb-2 aspcirclecent disblockmobtab2">In summary, <span className="sklaspbold">SKL-ASPIRE </span>  offers a modern, efficient, and flexible approach to conducting entrance exams – essential in today's rapidly evolving educational landscape.</div>
                                        </div>

                                        <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                 <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.visuaprofiling(6)}>Partner Now</button>
                                        </div>
                                    </div>
                                     </div>
                                    :
                                    this.state.activeData === 6 ?
                                    <>
                                     <div className="wid90mobTab2">
                                      <div className="row  mx-0">
                                        <div className="col-lg-12">
                                            <div className="disflexiinquibmob mb-1"><img src={Sklaspirelogo} className="inquibyccfreeimag"></img></div>
                                        </div>
                                      </div>
                                      <div className="row mx=0">
                                        <div className=" Inquib-corporateText aspirewidtab6 textcolrtab6cuttingedge">
                                        Cutting-edge technology solution to seamlessly manage 
                                        institution admission assessment test process – instantly
                                        </div>
                                        <div className="col-12 Inquib-corporateText martop-aspire-sch-eng Robototextligth">Launch a Live Demo</div>
                                        <div className="col-12 Inquib-corporateText"><button className="createprofile meet-our-team texnonetransfor" onClick={() => { this.OpenRequestDemo("SKLASPIREPolicy") }}>Start Demo</button></div>
                                        <div className="col-12 Inquib-corporateText Robototextligth">Engage Ahead</div>
                                        <div className="col-12 Inquib-corporateText"><button className="createprofile meet-our-team texnonetransfor " onClick={this.aspireenquiryform}>Partner Now</button></div>
                                      </div>
                                     

                                    </div>
                                    </>
                                    :
                                    <></>
                                    }

        
                </div>


                <ToastContainer className="toastsubmit"/>
 {this.state.isReqstdemo &&
 <OnlineDemo
 isFrame={false}
 Url={""}
close={this.closeRequestDemo}
Actiontype={this.state.type}
 isReqstdemo={true}
 toastMsg={this.toastMessage}
 />}
          
            </div>

        )
        }}
        export default SklAspire