import { APIUrl } from '../../Assests/js/config';
import axios from 'axios';

export function AutoGenerateCode(ReferenceCode = '', length = 6) {
  var text = "";
  if (ReferenceCode !== "") {
    text = ReferenceCode;
  }
  else {
    // var possible = "ABCDEFGHJKLMNPQRSTWXYZ23456789"; 
    var possible = "1234567890"; //only numbers
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function GetToken() {
  if (localStorage.getItem('IsLoggedIn') === '1' ? true : false) {
    return axios({
      method: 'POST',
      url: APIUrl + '/api/GetToken',
      withCredentials: true,
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      data: {
        token: localStorage.getItem('refreshToken')
      }
    })
      .then(res => {
        let result = res.data;
        localStorage.setItem('accessToken', result.accessToken);
      }).catch(e => {
        console.log(e);
      });
  }
}

export function ToSendOTP(MobileNo, OTPCode) {
  return axios({
    method: 'POST',
    url: APIUrl + '/api/ToSendOTP',
    withCredentials: true,
    headers: new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    data: {
      MobileNo,
      OTPCode
    }
  })
    .then(result => {
      return result.data;
    }).catch(e => {
      console.log(e);
    });
}

export function ToVerifyOTP(OTPCode) {
  // console.log('ToVerifyOTP.otp-->',OTPCode)
  return axios({
      method: 'POST',
      url: APIUrl + '/api/ToVerifyOTP',
      withCredentials: true,
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      data: {
        OTPCode
      }
  })
  .then(result => {
    //console.log('ToVerifyOTP.result-->',result)
    return result.data;
  }).catch(e => {
    console.log(e);
  });
}

export function sendMail(EmailId, MailFrom, setSubject, setBody){
  return axios({
    method: 'POST',
    url: APIUrl + '/api/SendMail',
    withCredentials: true,
    headers: new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    data: {
      EmailId,
      MailFrom,
      setSubject,
      setBody
    }
  })
  .then(result => {
    return result.data;
  }).catch(e => {
    console.log(e);
  });
}

export function GetNLAQQuizData(SectorId, Year, Month){
  return axios({
    method: 'POST',
    url: APIUrl + '/api/GetNLAQQuizData',
    withCredentials: true,
    headers: new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    data: {
      SectorId,
      Year,
      Month,
    }
  })
  .then(result => {
    return result.data;
  }).catch(e => {
    console.log(e);
  });
}

