import React, { useEffect, forwardRef, useState } from "react";
import $ from 'jquery';
import SKLInstitutionbanner from '../../Assests/Images/Sklaspireenquirybanner.jpg';
// import SKLIndustrybanner from '../../asset/image/ycc2024/SKL-Industry-Banner.jpg';
import Select, { components } from 'react-select';

import AsyncSelect from 'react-select/async';
import {GetcityListBycountryAndCity, GetAllInstitutionCategoryByEvent, GetAllSectorList, SaveEnquiryDetailsAspireEngage,GetPincodeInfo} from './skl-aspir-method';

import { EventToconsider } from '../../Assests/js/config'
import {OnlyNumbers , validateEmail, ValidateRequired} from '../registeration/validation';
import history from '../../Assests/js/config';
// import base64 from 'react-native-base64';
import { ToastContainer, toast } from 'react-toastify';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';
import AsyncCreatableSelect from 'react-select/async-creatable';
// import customStyles,{customStylecollege} from './CustomStyle'
import {GetConsent} from '../Industry_component/request-demo-method';

import moment from 'moment';

const Input = (props) => <components.Input {...props} isHidden={false} />;

function Enquiry(){
    const [manage, setManage] = useState({
        College : [],
        CollegeInput : '',
        Institution: [],
        errors: {},
        Numberofstudent:[],
        sector:'',
        sectorArray:[],
        city:[],
        selecity:'',
        webAddress:'',
        ContactPerson:'',
        Designation:'',
        Mobile:'',
        Mail:'',
        Address:'',
        Company : [],
        Industry:'',
        PartnerArray:[],
        Current_CountrySelected:[],
        Current_PincodeSelected: [],
        currentpincodefocus:false,
        NumberofStudentList:[{value:1,label:'1-100'},{value:2,label:'101-250'},{value:3,label:'251-500'},{value:2,label:'501-1000'},{value:2,label:'1000+'}],
        InstituteName:'',
        DataConsent:[],
        ConsentDetails:{},
        concentErr:""
    })
    const [errInst, seterrINst] = useState('')
    const [errcity, seterrcity] = useState('')
    const [errComp, seterrComp] = useState('')
    const [sectorList, setsectorList] = useState()
    const [Entity, setEntity] = useState(3)
    const [Enquiryfor, setEnquiryfor] = useState('')
    const [PincodeERRor,setPincodeERRor]= useState("");
    const [slelectedAddress , setslelectedAddress] = useState("");
    const [AddressError , setAddressError] = useState("");
    const [errnumberofstudent, seterrnumberofstudent] = useState('')

    useEffect(() => {
       
        $('html,body').animate({
            scrollTop: 0
        });

        GetSectorList()
        GetConsent().then((result) => {
            // console.log("reeeee",result.recordsets[0].filter(x=>x.Label===Actiontype))
            setManage({
                ...manage,
                // ConsentDetails : result.recordsets[0].length > 0 ? result.recordsets[0] : []
                ConsentDetails:result.recordsets[0].filter(x=>x.Label==='SKLASPIREPolicy').length> 0 ? result.recordsets[0].filter(x=>x.Label==='SKLASPIREPolicy'):[]
            })
            // setIndu(window.location.hash.includes('#/industries')?true:false)
        })
    },[])

    const getCollegeOptions = (input) => {
        if (input.length > 1) {
            return GetAllInstitutionCategoryByEvent(EventToconsider, input,'College')                
            .then((res) => {                   
                let result = res;                
                return result;                
            })                
            .catch(function (error) {});    
        }     
    }

    const OnChangeSelect = (selectedOptions) =>{
        if(selectedOptions !== null){
            let err = manage.errors
            err['Institution'] = ''
            setManage({
                ...manage,
                College : selectedOptions,
                Institution:selectedOptions,
                errors: err
            })
            seterrINst('')
        }
        else{
            setManage({
                ...manage,
                College : [],
                Institution:'',
                //errors: err
            })
        }
    }

    const CollegeInputChange = (newValue, { action }) => {
        const inputValue = newValue;
        if (action === "input-change") {
            if (inputValue.trim() !== "") {
                setManage({
                    ...manage,
                    College :  { value: 0, label: inputValue },
                    Institution: inputValue 
                })
                
                return inputValue
            }
            else {
                setManage({
                    ...manage,
                    College :  [],
                    Institution: ''
                })
              
                return ''
            }
        }
    }

    const SetCollege = (e) =>{
        Institute()
    }

    const Institute =()=>{
        let errors = manage.errors;
        let flag = true;
        if(manage['College'].label !== undefined && manage['College'].label!==""){
            if(manage['College'].label.length>1 ){
                var req = manage['College'].label
                var regex = /[A-Z a-z 0-9]/g;
                var found = req.match(regex);
                if (found) {
                    errors= "";
                    flag = true;
                }else{
                    errors= "*Please Enter Valid Institution";
                    flag = false;
                }
            }else{
                errors = "*Please Enter Valid Institution";
                flag = false;
            }
            seterrINst(errors)
        }else{
            errors = "*Please Enter Valid Institution";
            flag = false;
            seterrINst(errors)
        }
       
        return flag
    }

    const handleChangeinput = e =>{
        setManage({
            ...manage,
            [e.target.name]:e.target.value 
        })
    }

    const ValidateIsEmpty = (name, label) => {
        let flag = true;
        let errors = manage.errors
        ValidateRequired(manage[name], label, function (err) {
            errors[name] = err.RequiredField;
            flag = err.formIsValid;
        });
        setManage(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const GetSectorList =()=>{
        GetAllSectorList(0)
        .then(result => {
            setsectorList(result.recordsets[0])
        })
    }

    const handleSelection = (selection) => {
        // let selectedSectors=manage.sectorArray
        // if(!selectedSectors.includes(selection)){
        //     selectedSectors.push(selection)
        // }
        setManage({
            ...manage,
            // sector: selection,
            Numberofstudent:selection
            // sectorArray:selectedSectors
        })
    }

    const deletefromarray = (selection) =>{
        let selectedSectors=manage.sectorArray
        selectedSectors.pop(selection)
        setManage({
            ...manage,
            sectorArray:selectedSectors
        })
    }

    const getcityOptions = (input) =>{
        if (input.length > 1) {
            return GetcityListBycountryAndCity('IND',206,input,'GETCITYDATA')                
            .then((res) => {                
                let result = res.recordset;                      
                return result;                
            })                
            .catch(function (error) {});    
        } 
    }

    const OnChangeSelectcity = (selectedOptions) =>{
        if(selectedOptions !== null){
            let err = manage.errors
            err['city'] = ''
            setManage({
                ...manage,
                city : selectedOptions,
                selecity:selectedOptions.label,
                errors: err
            })
            seterrcity('')
        }else{
            setManage({
                ...manage,
                city : [],
                selecity:'',
                //errors: err
            })
        }

    }

    const Setcity = (e) =>{
        city()
    }

    const city =()=>{
        let errors = manage.errors;
        let flag = true;
        if(manage['city'].label !== undefined && manage['city'].label!==""){
            if(manage['city'].label.length>1 ){
                var req = manage['city'].label
                var regex = /[A-Z a-z 0-9]/g;
                var found = req.match(regex);
                if (found) {
                    errors= "";
                    flag = true;
                }else{
                    errors= "*Please Enter Valid city";
                    flag = false;
                }
            }else{
                errors = "*Please Enter Valid city";
                flag = false;
            }
            seterrcity(errors)
        }else{
            errors = "*Please Enter Valid city";
            flag = false;
            seterrcity(errors)
        }
       
        return flag
    }

    const CityInputChange = (inputValue, { action }) => {
        if (action === "input-change") {
            if (inputValue.trim() !== "") {
                setManage({
                    ...manage,
                    city :  { value: 0, label: inputValue },
                    selecity: inputValue 
                })
                
                return inputValue
            }
            else {
                setManage({
                    ...manage,
                    city :  [],
                    selecity: ''
                })
              
                return ''
            }
        }
    }

    const Saveinstituteenquiry = (number) =>{
        let val =  Validatereg()
        if(val==true){
            let sectors  = []
            // manage.sectorArray.map((x,i)=>{
            //     sectors.push(x.value)
            // })
            // let Instituition = manage.Institution.label
            let Instituition =manage.InstituteName
            // console.log(Instituition)
            let Industry = manage.Industry
            let Numberofstudent = manage.Numberofstudent.label
            let sector = manage.sector
            let city = manage.city
            let webAddress = manage.webAddress
            let ContactPerson = manage.ContactPerson
            let Designation = manage.Designation
            let Mobile = manage.Mobile
            let Mail = manage.Mail
            let Address = manage.Address
            
            // let EnquiryFor = manage.PartnerArray.toString()
            
            let pincodelabel=manage.Current_PincodeSelected.Pincode
            let pincodevalue=manage.Current_PincodeSelected.value
            SaveEnquiryDetailsAspireEngage('',ContactPerson,'',Mail,Mobile,Instituition,'','SKL Aspire','',Address,webAddress,pincodelabel,pincodevalue,city,Numberofstudent,Designation,manage.DataConsent).then(result=>{
                    // if(result.data.recordsets[0][0].MSG === 'saved'){
                        toast.dismiss()
                        toast.success("Thanks For Showing intrest, we will get back to you shorly.", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined
                        })
                        discard()
                    // }
                })
            // }
        }
    }

    const Validatereg = () =>{
        let a = ValidateIsEmpty('InstituteName','Institute Name')
        let b = manage.Numberofstudent.label===undefined? false : true
        // let c = ValidateIsEmpty('sector','Preffered Sectors')
        // let d = ValidateIsEmpty('city','city')
        let e = ValidateIsEmpty('webAddress','Website Address')
        let f = ValidateIsEmpty('ContactPerson','Contact Person')
        let g = ValidateIsEmpty('Mobile','Mobile Number')
        let h = ValidateIsEmpty('Mail','Mail Id')
        let i = ValidateIsEmpty('Address','Official Address')
        let j = ValidateIsEmpty('Designation', 'Designation')
        let k=manage.Current_PincodeSelected.Pincode===undefined? false : true
        let l=manage.Current_PincodeSelected.value===undefined? false : true
        let o =    valConcent()
        if(l==false)
        {
           
            setPincodeERRor("*Please Enter Pincode") 
        }
        if(b===false){
            seterrnumberofstudent("*Please Enter Number of Students")
        }
        if(a===true && b===true &&  e===true && f===true && g===true && h===true && i===true && j===true && k===true && l===true && o===true){
            return true
        }else{
            return false
        }
    }

    const discard =()=>{
        setManage({
            ...manage,
            CollegeInput : [],
            CollegeInput : '',
            Institution: '',
            Industry:'',
            errors: {},
            Numberofstudent:0,
            sector:'',
            sectorArray:[],
            city:[],
            selecity:'',
            webAddress:'',
            ContactPerson:'',
            Designation:'',
            Mobile:'',
            Mail:'',
            Address:'',
            Current_PincodeSelected:[],
            Current_PincodeSelected:[],
            currentpincodefocus:false,
            InstituteName:'',
            DataConsent:[],
            concentErr:"",
            errnumberofstudent:''
        })
    } 

    const validatEmail = (label) =>{
        let flag = true;
        let errors = manage.errors
        validateEmail(manage["mail"], label, function (err) {
            errors["mail"] = err.mail;
            flag = err.formIsValid;
        });
        setManage(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const TermsCheck=(value)=>{
        let PartnerArray=manage.PartnerArray
        let enteredvalue=PartnerArray.includes(value)
        // console.log(enteredvalue)
        if(!enteredvalue){
            PartnerArray.push(value)
        }
        else{
            PartnerArray.pop(value)  
        }
        
        // console.log(PartnerArray)
        setManage({
            ...manage,
            PartnerArray:PartnerArray
        })
    }


    const CurrentPincodehandleInputChange = (newValue, { action }) => {
        let inputValue = newValue;

        if (manage.Current_CountrySelected.value === 1) {    //india
            //do nothing          
            if (action === "input-change") {
                setManage({
                    ...manage,
                    Current_PincodeSelected:inputValue
                })
            }
        }
        else {
            if (action === "input-change") {
                setManage({
                    ...manage,
                    Current_PincodeSelected:inputValue
                })
            }
            if (inputValue !== "") {
                setManage({
                    ...manage,
                    Current_PincodeSelected: { value: 0, label: inputValue }
                })
            }
        }
        return inputValue;
    };
    const CurrentPincodeOnChange = (selectedOption) => {
        let CurrentPincodeOptions = selectedOption
        // console.log("selectedOption",selectedOption)
        setManage({
            ...manage,
            Current_PincodeSelected: CurrentPincodeOptions,
            city:CurrentPincodeOptions.CityName
        })
        setPincodeERRor("")
       
    }

    const getOptions = (input) => {
        if (input.length > 1) {
            return GetPincodeInfo(input)
                .then((res) => {
                    let result = res;
                    return result;
                })
                .catch(function (error) {
                });
        }
    }
    const onaddresshchnage=(event)=>
    {
        setslelectedAddress(event.target.value)
       
        
    }
    const validateAddRess=()=>
    {

        if(slelectedAddress!='')
        {
            setAddressError()
            return true;
        }
        else
        {
            setAddressError("*Please Enter Adderess")
            return false;
        }
    }
    // const getCompanyOptions = (input) => {
    //     if (input.length > 1) {
    //         return GetAllInstitutionCategoryByEvent(EventToconsider, input,'College')                
    //         .then((res) => {                   
    //             let result = res;                
    //             return result;                
    //         })                
    //         .catch(function (error) {});    
    //     }     
    // }

    // const OnChangeSelectCompany = (selectedOptions) =>{
    //     if(selectedOptions !== null){
    //         let err = manage.errors
    //         err['Industry'] = ''
    //         setManage({
    //             ...manage,
    //             Company : selectedOptions,
    //             Industry:selectedOptions,
    //             errors: err
    //         })
    //         seterrComp('')
    //     }else{
    //         setManage({
    //             ...manage,
    //             Company : [],
    //             Industry:'',
    //             //errors: err
    //         })
    //     }
    // }

    // const CompanyInputChange = (newValue, { action }) => {
    //     const inputValue = newValue;
    //     if (action === "input-change") {
    //         if (inputValue.trim() !== "") {
    //             setManage({
    //                 ...manage,
    //                 Company :  { value: 0, label: inputValue },
    //                 Industry: inputValue 
    //             })
                
    //             return inputValue
    //         }
    //         else {
    //             setManage({
    //                 ...manage,
    //                 Company :  [],
    //                 Industry: ''
    //             })
              
    //             return ''
    //         }
    //     }
    // }

    // const SetCompany = (e) =>{
    //     Company()
    // }
    // const Company =()=>{
    //     let errors = manage.errors;
    //     let flag = true;
    //     if(manage['Industry'].label !== undefined && manage['Industry'].label!==""){
    //         if(manage['Industry'].label.length>1 ){
    //             var req = manage['Industry'].label
    //             var regex = /[A-Z a-z 0-9]/g;
    //             var found = req.match(regex);
    //             if (found) {
    //                 errors= "";
    //                 flag = true;
    //             }else{
    //                 errors= "*Please Enter Valid Company";
    //                 flag = false;
    //             }
    //         }else{
    //             errors = "*Please Enter Valid Company";
    //             flag = false;
    //         }
    //         seterrComp(errors)
    //     }else{
    //         errors = "*Please Enter Valid Company";
    //         flag = false;
    //         seterrComp(errors)
    //     }
       
    //     return flag
    // }

    const dataconsent = (Value) => {
        let DataConsnt = manage.DataConsent;
        if(DataConsnt.filter(x=>x.Consent_Id===Value).length>0){
            DataConsnt = DataConsnt.filter(x=>x.Consent_Id !== Value)
        }else{
            DataConsnt.push({Consent_Id:Value,CheckedDate:moment(new Date()).format('YYYY-MM-DD h:m:s')});
        }
        setManage({
            ...manage,
            DataConsent: DataConsnt
        })
    }
    const valConcent =()=>{
        let concet=false
     
        if(manage.DataConsent.length >0){
        
            concet=true     
        
                
            setManage({
                ...manage ,  concentErr:""
            })
        }
        else{
       
            setManage({
                ...manage , concentErr: "*Please give consent to proceed."
            })

        }
        return concet
    }
    
    return(
        <div className='paddin-top bgclrfroem'>
            <div className="Widt-adjust">
            
            <div className="ycc-enquiry-banner">
            <img src={SKLInstitutionbanner} className="widimg10perasp"/>
            </div>
            <div className="ycc-enquiry-content">
               
                    <>
                        {/* <div className="ycc-enquiry-wrapper-input">
                            <div className="ycc-enquiry-wrapper-row row">
                                <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                                    Institution Name <span className="ycc-enquiry-wrapper-span">*</span>
                                </div>
                                <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12 svgsizeinmovbile clg-select">
                                    
                                    <AsyncCreatableSelect
                                    cacheOptions
                                    defaultOptions
                                    
                                    loadOptions={getCollegeOptions}
                                    isClearable={true}
                                    formatCreateLabel={undefined}
                                    name={'Institution'}
                                    
                                    inputValue = {(manage.Institution === null ) ? '' :manage.Institution.label }
                                    controlShouldRenderValue={false}                               
                                    components={{                                    
                                        Input                               
                                    }}
                                    onChange={OnChangeSelect}
                                    onInputChange ={CollegeInputChange}
                                    onBlur={()=>{SetCollege()}}
                                />
                               
                                    
                                </div>
                            </div>
                        </div>
                        {manage.Institution.label === undefined &&
                        <div className="row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                        <div className="err-div">
                                    <span className="error-class padd-adjust">{manage.errors.Institution}</span>
                                </div>
                                </div>
                        </div>
                        
                                    } */}
                    <div className="ycc-enquiry-wrapper-input">
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            Institute Name <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <input 
                                type="text" 
                                className="input-ycc-reg" 
                                value={manage.InstituteName}
                                onBlur={()=>{ValidateIsEmpty('InstituteName','Institute Name')}}
                                name='InstituteName'
                                onChange={handleChangeinput}  
                            />
                        </div>
                    </div>
                    {(manage.InstituteName === "")&&  
                        <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{manage.errors.InstituteName}</span>
                                    </div>
                                    </div>
                            </div>
                                    }
                </div>
                        
                    </>
        
                
                <div className="ycc-enquiry-wrapper-input">
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            Contact Person <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <input 
                                type="text" 
                                className="input-ycc-reg" 
                                value={manage.ContactPerson}
                                onBlur={()=>{ValidateIsEmpty('ContactPerson','Contact Person')}}
                                name='ContactPerson'
                                onChange={handleChangeinput}  
                            />
                        </div>
                    </div>
                    {(manage.ContactPerson === "")&&  
                        <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{manage.errors.ContactPerson}</span>
                                    </div>
                                    </div>
                            </div>
                                    }
                </div>
                <div className="ycc-enquiry-wrapper-input">
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            Designation <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <input 
                                type="text" 
                                className="input-ycc-reg" 
                                value={manage.Designation} 
                                name='Designation'
                                onBlur={()=>{ValidateIsEmpty('Designation','Designation')}}
                                onChange={handleChangeinput}  
                            />
                            {/* <div className="err-div  ">
                                        <span className="error-class padd-adjust">{manage.errors.Designation}</span>
                                    </div> */}
                        </div>
                    </div>
                    {(manage.Designation === "")&&  
                        <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{manage.errors.Designation}</span>
                                    </div>
                                    </div>
                            </div>
                                    }
                </div>
                <div className="ycc-enquiry-wrapper-input">
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            Mobile Number <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <input 
                                type="tel" 
                                className="input-ycc-reg" 
                                value={manage.Mobile} 
                                name='Mobile'
                                onBlur={()=>{ValidateIsEmpty('Mobile','Mobile Number')}}
                                onChange={handleChangeinput} 
                                maxlength="12"   
                                onKeyPress={OnlyNumbers}
                            />
                            {/* <div className="err-div  ">
                                        <span className="error-class padd-adjust">{manage.errors.Mobile}</span>
                                    </div> */}
                        </div>
                    </div>
                    {(manage.Mobile === "")&&  
                        <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{manage.errors.Mobile}</span>
                                    </div>
                                    </div>
                            </div>
                                    }
                </div>
                <div className="ycc-enquiry-wrapper-input">
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            Mail Id <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <input 
                                type="email" 
                                className="input-ycc-reg" 
                                value={manage.Mail} 
                                name='Mail'
                                onBlur={()=>{ValidateIsEmpty('Mail','Mail Id')}}
                                onChange={handleChangeinput}  
                            />
                            {/* <div className="err-div  ">
                                        <span className="error-class padd-adjust">{manage.errors.Mail}</span>
                                    </div> */}
                        </div>
                    </div>
                    {(manage.Mail === "")&&  
                        <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{manage.errors.Mail}</span>
                                    </div>
                                    </div>
                            </div>
                                    }
                </div>
                {/* <div className="ycc-enquiry-wrapper-input">
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            Official Address <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <input 
                                type="text" 
                                className="input-ycc-reg" 
                                value={manage.Address} 
                                name='Address'
                                onBlur={()=>{ValidateIsEmpty('Address','Official Address')}}
                                onChange={handleChangeinput}   
                            />
                            <span className="err-msg">{manage.errors.Address}</span>
                        </div>
                    </div>
                </div> */}
                <div className="ycc-enquiry-wrapper">
                            <div className="ycc-enquiry-wrapper-row row">
                                <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                                    Address <span className="ycc-enquiry-wrapper-span">*</span>
                                </div>
                                <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12 svgsizeinmovbile clg-select">
                                    <div className="">
                                                <textarea 
                                                    type="textarea" 
                                                    // className="input-ycc-reg" 
                                value={manage.Address} 
                                name='Address'
                                                    className="input-ycc-reg" 
                                                    placeholder=""
                                                    onChange={handleChangeinput} 
                                                    // value={manage.Address}
                                                    // onKeyPress= {OnlyAlphabets}
                                                    onBlur={()=>{ValidateIsEmpty('Address','Official Address')}}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            {/* {manage.Address === '' &&
                                    <div className="err-div  ">
                                        <span className="error-class padd-adjust">{manage.errors.Address}</span>
                                    </div>} */}
                                </div>
                            </div>
                            {(manage.Address === "")&&  
                        <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{manage.errors.Address}</span>
                                    </div>
                                    </div>
                            </div>
                                    }
                        </div>
                        <div className="ycc-enquiry-wrapper-input">
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            Website Address <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <input 
                                type="text" 
                                className="input-ycc-reg" 
                                value={manage.webAddress}
                                onBlur={()=>{ValidateIsEmpty('webAddress','Website Address')}}
                                name='webAddress'
                                onChange={handleChangeinput} 
                            />
                            {/* <div className="err-div  ">
                                        <span className="error-class padd-adjust">{manage.errors.webAddress}</span>
                                    </div> */}
                        </div>
                    </div>
                    {(manage.webAddress === "")&&  
                        <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{manage.errors.webAddress}</span>
                                    </div>
                                    </div>
                            </div>
                                    }
                </div>

                        {/* //pincode  */}
                        <div className="ycc-enquiry-wrapper">
                            <div className="ycc-enquiry-wrapper-row row countryselectalign">
                                <div className=" col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                                    Pincode <span className="ycc-enquiry-wrapper-span">*</span>
                                </div>
                                <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12  svgsizeinmovbile clg-select">
                                    <div className="">
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions 
                                        noOptionsMessage={() =>(manage.Current_PincodeSelected !== "") ?  'Nothing found' : 'Type to select'}
                                        loadingMessage={() => 'searching...'}
                                        loadOptions={getOptions}
                                        isClearable={true}
                                        // styles={customStylecollegeRegYcc}
                                        // placeholder={manage.Current_CountrySelected !== null ? manage.Current_CountrySelected.value !== undefined ? " " : " " : []}
                                        // placeholder={}
                                        value={manage.Current_PincodeSelected}
                                        inputValue={manage.Current_PincodeSelected !== null ? manage.Current_PincodeSelected.label : []}
                                        onInputChange={CurrentPincodehandleInputChange}
                                        onChange={CurrentPincodeOnChange}
                                        
                                        isDisabled={false}
                                        maxLength="100"
                                        controlShouldRenderValue={false}
                                        components={{
                                            Input
                                        }}
                                            // onFocus={() => {
                                            //     this.setState({
                                            //         currentpincodefocus: true
                                            //     })
                                            // }}
                                        />      
                                            </div>
                                </div>
                            </div>
                            {manage.Current_PincodeSelected.CountryName !=="" &&
                            <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{PincodeERRor}</span>
                                    </div>
                                    </div>
                            </div>}
                            
                            
                                    {/* {manage.Current_PincodeSelected.label===undefined &&
                                     <div className="err-div  ">
                                        <span className="error-class padd-adjust">{PincodeERRor}</span>
                                    </div> 
                                     }  */}
                        </div>
                        {/* pincode  */}
                        <div className="ycc-enquiry-wrapper-input">
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            City <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <input 
                                type="text" 
                                className="input-ycc-reg" 
                                value={manage.city}
                                disabled={true}
                                // onBlur={()=>{ValidateIsEmpty('webAddress','Website Address')}}
                                // name='webAddress'
                                // onChange={handleChangeinput} 
                            />
                            {/* <div className="err-div  ">
                                        <span className="error-class padd-adjust">{manage.errors.webAddress}</span>
                                    </div> */}
                        </div>
                    </div>
                   
                </div>

                <div className="ycc-enquiry-wrapper-input margintopnumberfirels" >
                    <div className="ycc-enquiry-wrapper-row row">
                        <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12 pr-0 YCC-font-weight">
                            Number of Students <span className="ycc-enquiry-wrapper-span">*</span>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12 number-ofstudent-sv">
                        <Select
                               className="svgsizeinmovbile"
                                options={manage.NumberofStudentList }
                                onChange={handleSelection}
                                value={manage.Numberofstudent}
                                onBlur={()=>{ValidateIsEmpty('sector','Preffered Sectors')}}
                            />
                            {/* <div className="err-div  ">
                                        <span className="error-class padd-adjust">{manage.errors.Mail}</span>
                                    </div> */}
                        </div>
                    </div>
                    {(manage.Numberofstudent.label === undefined)&&  
                        <div className="row">
                            <div className="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
                            <div className="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                            <div className="err-div">
                                        <span className="error-class padd-adjust">{errnumberofstudent}</span>
                                    </div>
                                    </div>
                            </div>
                                    }
                </div>
                <div className="ycc-enquiry-wrapper-input sklaspiredataconsent">
                {manage.ConsentDetails !== undefined &&
                            manage.ConsentDetails.length > 0 &&
                            manage.ConsentDetails.map((val,i) =>
                                    <>
                                      {val.ConsentName === 'SKL ASPIRE Policy' &&
                                            <div>
                                                <div className='data-cnsnt-main'>
                                                    <div className='check-box-cnsnt'>
                                                        <input 
                                                            type="checkbox" 
                                                            id="consent1" 
                                                            name="consent1" 
                                                            value="consent" 
                                                            onChange={()=>dataconsent(val.Value)} 
                                                            checked={manage.DataConsent.filter(x=>x.Consent_Id ===  val.Value).length  >0}
                                                        />
                                                    </div>
                                                    <div className='consnt-txt consnt1-txt'>
                                                        <div dangerouslySetInnerHTML={{__html: val.ConsentContent}}></div>
                                                    </div>
                                                </div>
                                                {(manage.DataConsent.includes(val.Value)) == ""?
                                             
                                                        <span className='error-class padd-adjust'>{manage.concentErr}
                                                          
                                                        </span>   :
                                                        <div></div>
                                                     }                                
                                                    
                                            </div>
                                        }
                                    </>
                                )                        
                            } 
                    </div> 
                <div className="ycc-enquiry-wrapper">
                        {/* <p> We confirm our participation as host institution for SKL YCC 2024 KERALA. 
                            We assure you of our best efforts to support the event in all possible ways as agreed upon.
                        </p> */}
                        
                    <div className="ycc-enquiry-wrapper-btn">
                        <button className='createprofile meet-our-team texnonetransfor' onClick={()=>{Saveinstituteenquiry(2)}}>
                            Submit
                        </button> 
                    </div>
                </div>
            </div>
            <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover={false}
                />
                </div>
        </div>
    )
}
export default Enquiry