import React from "react";
import ReactDOM from 'react-dom'
import $ from 'jquery';
import layer48copy4 from '../../Assests/Images/Layer 48 copy 4.png'
// import skillablersbird1 from '../../Assests/Images/skillablers_bird_1.png'
import youthconnect from '../../Assests/Images/YccLogo.png';
import UTIndiaNextStimulus from '../../Assests/Images/UTIndiaNextStimulus.png';
import sight from '../../Assests/Images/sight.png';
import bengaluru from '../../Assests/Images/bengaluru.png';
import startupindia from '../../Assests/Images/startupindia.png';
import awards from '../../Assests/Images/awards.png';
import msme from '../../Assests/Images/msme.png';
import neatlogotop from '../../Assests/Images/neat-logo-top.png';
import { Routing } from "../../Assests/js/routing";
import { HashRouter, Link } from 'react-router-dom';
import {IndividualUrllogin,motsSkillablers} from '../../Assests/js/config';
import Slider from "react-slick";
import bioquiz_version_2  from '../../Assests/Images/bioquiz-version-2.0.png';
import CareerModal from "./CareerModal";
import CountUp from 'react-countup';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {RoutingUrl,viewalleventurl} from '../../Assests/js/config'
import history from "../../histoty";
import Chaloexamlogo1 from '../../Assests/Images/Chaloexamlogo1.svg';
import Talenttosucess from '../../Assests/Images/Talent to success.svg';

import {Context} from '../../App';
import Debugging_logo1 from '../../Assests/Images/Debugging-logo1.svg'
import skillablersbird1 from '../../Assests/Images/Skl-minibirdcoloured.png'
import Roadmap3iconnect from '../../Assests/Images/Roadmap3iconnect.svg'
import Indiviroadcircle from '../../Assests/Images/Indiviroadcircle.svg'
import Instiroadcircle from '../../Assests/Images/Roadmapcircleinst.svg'
import Induroadcircle from '../../Assests/Images/Roadmapcircleindu.svg'
// import mobviewroadmapabt from '../../Assests/Images/mobviewroadmapabt.svg'
import {IndusURL,Instituteurl} from '../../Assests/js/config';
import Iplanetlogo from '../../Assests/Images/Groupappleiomg.svg'
import engaging from '../../Assests/Images/SVGICON1.svg'
import pointering from '../../Assests/Images/pointering.svg'
import bridging from '../../Assests/Images/bridging.svg'
import connecting from '../../Assests/Images/connecting.svg'
import jobsbanner2resume from '../../Assests/Images/jobsbanner2resume.png';
import jobsbanner3question from '../../Assests/Images/jobsbanner3question.png';
import ReactPlayer from 'react-player';
import Connect360bannerimg from '../../Assests/Images/withoutxtc360.png';
import Profile360banner from '../../Assests/Images/withouttxtp360.png';
import Ycc_homepage_banner from '../../Assests/Images/Yccwithouttxt.png';
import Event from '../../Assests/Images/IAQwithouttxt.png'
// import Process from '../Indivi_jobs_component/process_flow'
import AEcte from '../../Assests/Images/Aectewithouttxt.png';
import Newprocessimg from '../../Assests/Images/visualprofilewithouttxt.png';
import { NavLink , BrowserRouter, Redirect } from "react-router-dom";
import base64 from 'base-64';
import eventbanner from '../../Assests/Images/New3iconnect2.png'
import sklecosysbanner from '../../Assests/Images/sklecosysbanner.png'
import eventbannermobile from '../../Assests/Images/mobileview3iconnectbanner.png'
import ifstbannermobile from '../../Assests/Images/3ifstbannermobile.svg'
import ifstbannergetnoticemobi from '../../Assests/Images/3ifstbannergetnoticemobi.svg'
import sevenbanneryccimgmobile from '../../Assests/Images/sevenbanneryccimgmobile.svg'
import seven_PerspectiveimgbannerMob from '../../Assests/Images/seven_PerspectiveimgbannerMob.svg'
import eighthbannerIAQMob from '../../Assests/Images/eighthbannerIAQMob.png'
import sixbannerConnect360Score_mob from '../../Assests/Images/sixbannerConnect360Score_mob.svg'
import fifthbannetProfile360ScoreMob from '../../Assests/Images/fifthbannetProfile360ScoreMob.svg'
import AACteMobileviewBanner from '../../Assests/Images/AACteMobileviewBanner.png'
import VisualprofilevbanneremptyMob from '../../Assests/Images/VisualprofilevbanneremptyMob.png'
import SvpviualpropfileimgMob from '../../Assests/Images/SvpviualpropfileimgMob.svg'
import ThreeiconnecthexagonMob from '../../Assests/Images/3iconnecthexagonMob.png'
import SklbannerEcosyMob from '../../Assests/Images/SklbannerEcosyMob.png'
import mobviewroadmapabt from '../../Assests/Images/TrianglethreeiMob.svg'
import YCCIAQSeriesDeskBan from '../../Assests/Images/YCCIAQSeries-Desk-Ban.png'
import YCCIAQSECmapMob from '../../Assests/Images/YCCIAQ-SECmapMob.png'
import YCCiaqquesMarkLOG from '../../Assests/Images/YCCiaq-quesMarkLOG.png'
import PatentsucessbannerDesk from '../../Assests/Images/PatentsucessbannerDesk.png'
import Excitingnewsbanner from '../../Assests/Images/Excitingnewsbanner.png'
import patentcertificate from '../../Assests/Images/patentcertificate.png'
import Patentbelowbannertext from '../../Assests/Images/Patentbelowbannertext.png'
import Patentimgpopup from '../../Assests/Images/Patentimgpopup.png'
import patentwithhumantextimgDesk from '../../Assests/Images/patentwithhumantextimgDesk.png'
import NewpatentlogomobHuman from '../../Assests/Images/NewpatentlogomobHuman.svg'
import Yccnewbanner20 from '../../Assests/Images/Yccnewbanner2.0.png'
import Ycc2imaginmobbanner from '../../Assests/Images/Ycc2.0imaginmobbanner.svg'
import Perspectivethatmatterimag from '../../Assests/Images/Perspectivethatmatterimag.svg'
import Inquizitionbanner from '../../Assests/Images/Inquizition banner.png'
import InquiztionMobbanner from '../../Assests/Images/InquiztionMobbanner.png'


import Aspirebannerdesk from '../../Assests/Images/SKL Aspire banner.jpg'
import Aspirebannermob from '../../Assests/Images/Mobile_SKL Aspire.jpg'
import Aspireimgandtextmob from '../../Assests/Images/Aspireimgandtextmob.svg'

import EDCbannerDesk from '../../Assests/Images/EDCbanner.jpg'

import Mobile_EdcBanner from '../../Assests/Images/Mobile_Edc.jpg'
import Edcbannertextmob from '../../Assests/Images/Edcbannertextmob.png'


import InstitutionsNew2024banner from '../../Assests/Images/InstitutionsNew2024banner.jpg'


import IndustryNew2024banner from '../../Assests/Images/IndustryNew2024banner.jpg'

import Mobile_Institutions2024banner from '../../Assests/Images/Mobile_Institutions2024banner.jpg'

import Mobile_Industriess2024banner from '../../Assests/Images/Mobile_Industriess2024banner.jpg'























var job_search='job-search'
var Industry = 'industry'

const fade = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    adaptiveHeight: true,
    arrows:true,
    cssEase: 'ease-out',
    speed:1000
}

class Aboutus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "1",
            // activeData: 1,
            tabChange: 1,
            ShowPopup : false,
            activestatushome:0,
            threeIconnectactive:0,
            setanivalu:0,
            walkactiveid:0,
            patentpopup:false
        }
    }
   
    componentDidMount() {
        // this.setState({
        //     patentpopup:true
        // })
        if(history.location.hash.includes('#ourpartner')){
            // console.log('$("#ourpartner").offset().top',$("#ourpartner").offset().top)
            window.$('html,body').animate({
                scrollTop: $("#ourpartner").offset().top - 100
            },'slow');
            setTimeout(() => {
                this.Autoscrollpartnersection()
            }, 500)
            
        }else{
            window.$('html,body').animate({
                scrollTop: 0
            });
        }
        // const user = this.context
        this.animationflower()
        // console.log('Context',Context.Consumer)
        // document.getElementsByClassName("containement").classList.add('animate')
        document.getElementsByClassName('SKLhome-data-containerss')[0].classList.remove('active')
        $('html,body').animate({
            scrollTop: 0
        });
        // document.getElementsByClassName('avbout-us-skl-text')[0].classList.add('active')
        // document.getElementsByClassName('quotes')[0].classList.add('active')
        document.getElementById('aboutUds').classList.add('active')
        // document.getElementsByClassName('about-us-who')[0].classList.add('active')
        setTimeout(() => {
            if(document.getElementsByClassName('skl-prod-main-cont').length > 0)
            document.getElementsByClassName('skl-prod-main-cont')[0].classList.add('active')
            if(document.getElementsByClassName('SKLhome-data-containerss').length > 0)
            document.getElementsByClassName('SKLhome-data-containerss')[0].classList.add('active')
            ///Reading hisrory state
            //console.log('history.location.state',history.location , this.props.autoscrollTo)
            //console.log('ation.state',this.props.location.aboutprops)
            // if(this.props.autoscrollTo === true){
            //     let scrolling = $("#ourpartner").offset().top
            //     $('html,body').animate({
            //         scrollTop: scrolling
            //     },
            //         'slow');
            // }

        }, 2500)

        $(window).on('scroll', e => {
            $('.individualBlockIdent').each(function () {
                if ($(this)[0].id === "about-us-who") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                        document.getElementById('about-us-who').classList.add('active')
                    }
                }
                //         else if ($(this)[0].id === "skl-prod-main-cont"){
                //             if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                //                 document.getElementsByClassName('skl-prod-main-cont')[0].classList.add('active')
                // document.getElementsByClassName('SKLhome-data-containerss')[0].classList.add('active')
                //             }
                //         }
                // else  if($(this)[0].id === "howweDo"){
                //     if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                //         document.getElementById('howweDo').classList.add('active')
                //     } 
                // }
                // else if($(this)[0].id === "howwedotabs"){
                //     if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                //         document.getElementById('howwedotabs').classList.add('active')
                //         setTimeout(() => {
                //            document.getElementsByClassName("how-we-do-tabsectionns")[0].classList.add('active')
                //         }, 700);
                //     }  
                // }
                else if ($(this)[0].id === "ecosystem") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                        document.getElementById('ecosystem').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "eventhead") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                        document.getElementById('eventhead').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "bigevent") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                        document.getElementById('bigevent').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "other") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                        document.getElementById('other').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "awards") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                        document.getElementById('awards').classList.add('active')
                        document.getElementById("image1").style.transition = "all 0.3s ease 0s"
                        document.getElementById("image2").style.transition = "all 0.3s ease 0s"
                        document.getElementById("image3").style.transition = "all 0.3s ease 0s"
                        document.getElementById("image4").style.transition = "all 0.3s ease 0s"
                        document.getElementsByClassName("EventsssImga")[0].style.transition = "all 0.3s ease 0s"
                    }
                }
            })
        })

        window.$('.btn').on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                //Do nothing
            } else {
                var icon = $(this).data('icon');
                $('.wheel').data('state', icon).attr('data-state', icon);
                var state = $('.wheel').data('state');
                document.getElementsByClassName('font-text-daat')[0].style.opacity = 0
                setTimeout(() => {
                    if (parseInt(icon) === 1) {
                        document.getElementsByClassName('font-text-daat')[0].innerHTML = "Comprehensive visual profiling  through single view profile"
                    }
                    if (parseInt(icon) === 2) {
                        document.getElementsByClassName('font-text-daat')[0].innerHTML = "<div>1. Focus areas</div><div>2. Course advise</div><div>3. Admission guidance​</div>"
                    }
                    if (parseInt(icon) === 3) {
                        document.getElementsByClassName('font-text-daat')[0].innerHTML = "Industry standard psycho-metric evaluation​"
                    }
                    if (parseInt(icon) === 4) {
                        document.getElementsByClassName('font-text-daat')[0].innerHTML = "<div>1. Curated courses to cater to industry demands</div>​2. Training provided by industry professionals"
                    }

                    if (parseInt(icon) === 5) {
                        document.getElementsByClassName('font-text-daat')[0].innerHTML = "<div>1. Beyond academics programs </div> <div>2. Pre-placement foundation</div>"
                    }
                    if (parseInt(icon) === 6) {

                        document.getElementsByClassName('font-text-daat')[0].innerHTML = "<div>1. Corporate connect & placements</div><div>2. Entrepreneurship incubators </div>​"
                    }
                }, 400)
                setTimeout(() => {
                    document.getElementsByClassName('font-text-daat')[0].style.opacity = 1
                }, 500)
                // Hide other dropdowns
                $('.btn.active').removeClass('active');
                // Open this dropdown
                $(this).addClass('active');
            }

        });
        let clear
        clear=setInterval(() => {
            this.animationflower()
          }, 4000)

         
        let walk=setInterval(() => {
          this.Walkingfunction()
        }, 10000)
        // this.Walkingfunction()
    }

    RoutingTo = () => {
        window.open(window.location.origin + Routing.ycc, '_blank');
    }

    tabchange = (val) => {
        this.setState({
            tab: val
        })
    }

    tootalchange = (id) => {
        this.DatatChanges(id)
        this.tabchange(id)

    }
    closPopuppatent=()=>
    {
        this.setState({
            patentpopup:false 
        })
    }
    DatatChanges = (id) => {
        document.getElementById("TabImagess").style.transition = "all 0.2s ease"
      
        document.getElementsByClassName('UlTabdata')[0].classList.remove("active")
        if (id === 1) {
            // document.getElementById("TabImagess").src = { MobileIndividual }
            document.getElementsByClassName('how-we-do-tabsectionns')[0].className = "how-we-do-tabsectionns " + "how-we-do-tabsectionns-1"
            setTimeout(() => {
                document.getElementsByClassName('UlTabdata')[0].innerHTML = `<li>INCREASED AWARENESS through Professional Career Counselling and Psycho-Metric Evaluation
                    </li>
                    <li>CAREER OPPORTUNITIES directly mapped to Skills, Aptitude and Aspirations
                    </li>
                    <li>ENHANCED EMPLOYMENT PROSPECTS through a curated Industry Readiness Programs
                    </li> 
                    <li>GET NOTICED with a comprehensive Profile, that provides both visibility and transparency of individual’s skills and accomplishments
                    </li>
                  `
            }, 700)
        }
        else if (id === 2) {
            document.getElementsByClassName('how-we-do-tabsectionns')[0].className = "how-we-do-tabsectionns " + "how-we-do-tabsectionns-2"
            // document.getElementById("TabImagess").src ={MobileIndustry} 
            setTimeout(() => {
                document.getElementsByClassName('UlTabdata')[0].innerHTML = `<li>IMPROVED REPUTATION amongst students given the focus on Skill Development and Career Opportunities</li>
                    <li> Successful student Placements RAISES INSTITUTION PROFILE with Companies</li>
                    <li> ENHANCED STUDENT PROFILE as they undertake their academic journey through the college</li>`
            }, 700)
        }
        else {
            document.getElementsByClassName('how-we-do-tabsectionns')[0].className = "how-we-do-tabsectionns  " + "how-we-do-tabsectionns-3"
            // document.getElementById("TabImagess").src = {MobileInstitute}    
            setTimeout(() => {
                document.getElementsByClassName('UlTabdata')[0].innerHTML = `<li>Access to an INDUSTRY READY and PROFESSIONALLY TRAINED talent pool</li>
                <li>SKILLABLERS ACADEMY CURRICULUM CURATED BASED ON COMPANY JOB DESCRIPTIONS</li>
                <li> A COMPREHENSIVE VIDEO POWERED STUDENT PROFILE, helps companies to achieve an efficient and scalable recruitment process </li>`
            }, 700)
        }
        setTimeout(() => {
            document.getElementsByClassName('UlTabdata')[0].classList.add("active")
        }, 800)
    }

    openIndiasight = () => {
        window.open( viewalleventurl+"/indiainsight", '_blank')
    }
    openYCC = () => {
        window.open(viewalleventurl+"/events/ycc2021", '_blank')
    }
    UltraTech = () => {
        window.open(viewalleventurl+"/ultratechstimulus", '_blank')
    }
    OpenbengaluruQuiz = () => {
        window.open(viewalleventurl+'/nanotechquiz2022', '_blank')
    }
    bioquizversion2 = () => {
        window.open(viewalleventurl+'/bioquiz2022', '_blank')
    }
    RoutingToOurTeam = () => {
        window.open(window.location.origin + Routing.OurTeam, '_self');
    }
    OpenAicte = () => {
        window.open("https://neat.aicte-india.org/course-details/NEAT2020615_PROD_2", '_blank')
    }

    openModal = () =>{
        this.setState({
            ShowPopup : true
        })
    }

    closeModal = () =>{
        this.setState({
            ShowPopup : false
        })
    }


    creatbutton=()=>
    {
        window.open( IndividualUrllogin +'/indi-login', '_blank')
    }

    indiloginpage=()=>
    {
        window.open( IndividualUrllogin +'/indi-login', '_blank')
    }

    FindJob=()=>
    {
        window.open(RoutingUrl+job_search, '_self')
    }

    upgradeskill=()=>
    {
        window.open(motsSkillablers, '_blank') 
    }

    companyindus=()=>
    {
        window.open(RoutingUrl+Industry, '_self')
    }
    ReadContext=(value)=>{
         
          let [val , fun , reset] = [...value]

          if(val){

            // console.log('from appjs',value)
            reset(false)
            //window.header.pageRender()
            fun(false)
           
            setTimeout(() => {
                let scrol = $("#ourpartner").offset().top
                $('html,body').animate({
                    scrollTop: scrol
                },
                    'slow');
              }, (1000));

          }
    }

    Homedatesetactive=(id)=>
    {
      this.setState(
        {
            activestatushome: id
        }
      )
   }

    threeIconnectfunc=(value)=>
    {
        this.setState({
           threeIconnectactive: value
        })
    }

    Autobridging = (bool)=>{
        // console.log('bool headersdfsdfszdf',bool)
        // console.log("locatiozDxaszdxaszn",window.location.hash.includes(Routing.Home),bool)
        if(bool){
          // console.log('header 2')
        //   this.props.ChangeAutoScroll(true)
        //   this.props.setRedirectedHome(true)
          setTimeout(()=>{
           // return <Redirect to={ Routing.Home } />
          //  this.setState({
          //   RedirectedHome : true
          //  },()=>{
            //setTimeout(()=>{
             
              this.setState({
                Redirect : false
              })
          //   },100)
          //  })
           
          },100)
        }
          
          
              
              setTimeout(()=>{ 
                let scrolling = $("#IConnect").offset().top - 100
          $('html,body').animate({
              scrollTop: scrolling
          },
              'slow');
              },500)
       
      }

    animationflower=()=>
    {
        if(this.state.setanivalu===0)
        {

            
            this.setState({
                setanivalu:1,
                activestatushome:0
            })

        }

        else if(this.state.setanivalu===1)
        {

            this.setState({
                setanivalu:2,
                activestatushome:2
            })

            
        }

        else if(this.state.setanivalu===2)
        {

            this.setState({
                setanivalu:0,
                activestatushome:1
            })

            
        }
 
    }

  
  Walkingfunction=()=>
  {
    if(this.state.walkactiveid===0)
    {
        this.setState({walkactiveid: 1})
       
    }
    else if(this.state.walkactiveid===1)
    {
        this.setState({walkactiveid: 2})
    }

    else if(this.state.walkactiveid===2)
    {
         this.setState({walkactiveid: 0})
     
    }
    }

    indihome=()=>
    {
        // console.log("Routing.indihomelanding",Routing.indihomelanding)
        // window.open(, '_self') 
    }

    Autoscrollto3i=()=>{
        let scrolling = $("#Three-iconnect").offset().top
        $('html,body').animate({
            scrollTop: scrolling
        },
            'slow');
    }

    ReadContext=(value)=>{}

    // sendskill =(id,url)=>{
    //     console.log(id,url)
    //      let Obj = {id:id}
    //      let URL = RoutingUrl+url+ '?' + btoa(JSON.stringify(Obj))
    //      console.log(URL)
    //      let a = document.createElement('a');
    //      a.href = URL;
    //      a.target = "_self"
    //     a.click();
    // }
    AutoScrollPartner = (bool)=>{
        // console.log("locatiozDxaszdxaszn",window.location.hash.includes(Routing.Home),bool)
        if(bool){
          // console.log('header 2')
        //   this.props.ChangeAutoScroll(true)
        //   this.props.setRedirectedHome(true)
          setTimeout(()=>{
           // return <Redirect to={ Routing.Home } />
          //  this.setState({
          //   RedirectedHome : true
          //  },()=>{
            //setTimeout(()=>{
             
              this.setState({
                Redirect : false
              })
          //   },100)
          //  })
           
          },100)
        }
          
              
              setTimeout(()=>{ 
                let scrolling = $("#PRocess_flow").offset().top
          $('html,body').animate({
              scrollTop: scrolling
          },
              'slow');
              },100)
       
      }
     
    redirec=(id,url)=>{
        if(id===0){
            let a = document.createElement('a');
            a.href = url;
            a.target = "_self"
            a.click();
        }
        else if(id===8){
            let a = document.createElement('a');
            a.href = url;
            a.target = "_blank"
            a.click();
        }
        else{
            let URL = url+ '?' + id
            let a = document.createElement('a');
            a.href = URL;
            a.target = "_self"
            a.click();
        }
    }

    Autoscrollpartnersection=()=>{
        let scrolling = $("#ourpartner").offset().top-100
        $('html,body').animate({
            scrollTop: scrolling
        },
            'slow');
    }


    RoutingToQUIBTION = () => {
        // window.open('#' +Routing.Inquizition, '_self')
        window.open(IndividualUrllogin+'/indi-guest-login', '_blank')
    }

YCCkerala2024=()=>
{
  //window.open('#' +Routing.ycc, '_blank')
  window.open(Routing.ycc, '_blank');
}
sklaspire=()=>
{
  window.open('#' +Routing.sklaspire, '_self')
}


OpenEdc=()=>
{
    window.open('https://www.edccts.com/creative-tech-solution', '_blank') 
}

redirectTelenttosucess=()=>
{
   
    window.open('https://t2sgroup.co.in', '_blank')  
}
    


    render() {
        return (
            <Context.Consumer>
            {value=>
            <div onLoad={()=>this.ReadContext(value)}>
                <div class="Homebanner">
                    <Slider {...fade}>
                    {/* {edcbanner} */}
                    <div>
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" onClick={this.OpenEdc}>
                            <div className=" appleimgadivbanner">
                                <img src={EDCbannerDesk} alt="19" className="newprocess" width="100%" />
                                <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize" onClick={this.OpenEdc}>Know More</button></div>
                                </div>
                            </div>
                            {/* <div className="bannertextbelow">Apple Authorized Training Center for Education (AATCe)</div> */}
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={Mobile_EdcBanner} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    
                                    <div className="row mx-0  AACteabsolute Edctextbannermob">
                                        <div className="col-12">
                                         <div className="centerflexmobbanner widthedctextmob"><img src={Edcbannertextmob} alt="19" className="banneryccmobvie" /></div>
                                         </div>
                                    </div>
                                    <div className="col-12 knowmorerealabsolute knowmoreedcbutton">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            // onClick={()=>this.redirec(0,'#/aatce')}
                                           onClick={this.OpenEdc}
                                            >Know More</button></div>
                                        </div>
                                  </div>
                          </div>
                        </div>
                        {/* {edcbanner} */}
                        {/* patent sucess banner */}
                        <div>
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" 
                        // onClick={()=>this.redirec(0,'#/aatce')}
                        >
                            <div className=" appleimgadivbanner">
                                <img src={patentwithhumantextimgDesk} alt="19" className="newprocess" width="100%" />
                                <div className="row">
                                <div className=" positionbannerLeftText positionfornewupdatechanges">
                                    <div className="skl-eco-system fnthumansixetext">HUMAN INSIGHTS TO HUMAN RESOURCES</div>
                                    <p className="font-apple-weight mb-0 banner_fontsize weightboldbannersubhead fntsixebannertext-cele">Celebrating our patent</p>
                                    <p className="banner_fontsize mb-0">The <span className="fntold">SKILLABLERS</span> Platform being granted the patent is a recognizing of our commitment to innovation and excellence in connecting the <span className="fntold">3-I's (Individuals, Institutions & Industries)</span> and enabling youth - like never before - to be gainfully employable and to choose their relevant career paths seamlessly.</p>
                                    <p className="banner_fontsize mb-0">This patent represents a significant milestone for us, as it validates our vision, and affirms our position as a pioneer in realigning aspirations of youth for measurable social impact.</p>
                                    {/* <button id="" className="meet-our-team " onClick={()=>this.redirec(1,'#'+Routing.aatce)}>Know more</button> */}
                                </div>
                                <div className="col-6 positionbanner">
                                
                                </div>
                                </div>
                                {/* <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize"  onClick={()=>this.redirec(1,'#'+Routing.aatce)}>Know More</button></div>
                                </div> */}
                            </div>
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={VisualprofilevbanneremptyMob} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0  mobbannerabsolute">
                                        <div className="col-12">
                                            <div className="centerflexmobbanner "><img src={NewpatentlogomobHuman} alt="19" className="widfornewpatentlogo-human" /></div>
                                        </div>
                                        <div className="col-12 pad-topvisualprofilmob pdnewpatent-humantop">
                                                <div className="skl-eco-system  text-pop-up-top connectetxtMob">HUMAN INSIGHTS TO HUMAN RESOURCES</div>
                                            <p className="font-apple-weight mb-0 banner_fontsize weightboldbannersubhead fnts-cele-clr">Celebrating our patent</p>
                                            <p className="banner_fontsize mb-0">The <span className="fntold">SKILLABLERS</span>  Platform being granted the patent is a recognizing of our commitment to innovation and excellence in connecting the <span className="fntold">3-I's (Individuals, Institutions & Industries)</span> and enabling youth - like never before - to be gainfully employable and to choose their relevant career paths seamlessly.</p>
                                            <p className="banner_fontsize mb-0">This patent represents a significant milestone for us, as it validates our vision, and affirms our position as a pioneer in realigning aspirations of youth for measurable social impact.</p>
                                        </div>
                                
                                       
                                    </div>
                                    {/* <div className="col-12  knowmorerealabsolute">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)}
                                            >Know More</button></div>
                                    </div> */}
                                  </div>
                            </div>
                        </div>
                        {/* patent sucess banner */}

                        {/* IAQ-banner */}
                        {/* <div>
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" onClick={this.indiloginpage}>
                            <div className=" appleimgadivbanner">
                                <img src={YCCIAQSeriesDeskBan} alt="19" className="newprocess" width="100%" />
                                <div className="row">
                                <div className="positionbannercentertextIAQ">
                                    <p className="banner_fontsize mb-0 pb-0 bannefontsize-IAQ">A Weekly Quiz to test your</p>
                                    <p className="banner_fontsize mb-0 pb-0  bannefontsize-IAQ">Industry Awareness Knowledge across <span className="ban1-textindi">40+ SECTORS</span></p>
                            
                                </div>
                                </div>
                                <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize"  onClick={this.creatbutton}>Know More</button></div>
                                </div>

                            </div>
                            
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={eventbannermobile} alt="19" className="bannermobbackgroundFst " width="100%" />
                                    <div className="row mx-0  mobbannerabsolute">
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={YCCIAQSECmapMob} alt="19" className="bannerthreeimobvie img-widmobYCCSERfstimg" /></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="centerflexmobbanner">
                                                <div class="banner_fontsize mobileviewliststyle pdtop-wid-textmob-IAQ-ycc">                            
                                                      <div>A Weekly Quiz to test your</div>    
                                                      <div>Industry Awareness Knowledge across <span className="ban1-textindi">40+ SECTORS</span></div>         
                                                </div> 
                                            </div> 
                                           
                                        </div>
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={ YCCiaqquesMarkLOG} alt="19" className="bannergetnoticed  img-widmobYCCSERLastimg"  /></div>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="col-12 knowmorerealabsolute">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={this.creatbutton}
                                            >Know More</button></div>
                                        </div>
                                  </div>
                            </div>
                        </div> */}
                         {/* IAQ-banner */}
                        {/* 1st banner  */}
                        <div class="" >
                        
                      
                            <div class= "appleimgadivbanner FstbannerDEsktop"
                           
                           
                            >
                                  {/* <div className="skl-eco-system sklnametext1stbanner">Vision & Mission</div> */}
                                 <img src={eventbanner} alt="19" className="newprocess" width="100%" />
                                 
                                <div class="custom-container">
                                
                                    <div class="eventbannerclss ">
                                    
                                        <div class="row mx-0 padding-for-text text-vertical-center center-grid aligncentevent">
                                            <div class="col-lg-4 carosoule-space" data-aos="fade-left" data-aos-duration="1500">
                                                {/* <div class="text-center my-1 kannur-logo index-home-log">
                                                    <img src={skl_events_logo } alt="logo" class="img-fluid" />
                                                </div>
                                                <div class="get-noties-details">                            
                                                    Skillablers’ focus is to enable-n-empower the Youth/Millennials by providing them ample tools and guides to get career-ready and attract opportunities aplenty through our real-time platform. We facilitate the formulation of specific career goals – based on your qualifications, interests, values, personality and skills. Connect & Engage with us to prepare yourself for the workplace of the Millennials.              
                                                </div>                             */}
                                                {/* <div><img src={threeiconnectimgwithtext} className="imagwid3iandgetnotice"/></div> */}
                                            </div>
                                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-flex">
                                                <div class="banner_fontsize widthfstbannerone fnt-bottom-padd-0">                            
                                                Skillablers, is a patented (Patent No: 446382) technology platform connecting <span className="ban1-textindi"> Individuals </span> with Industry-curated courses from <span className="ban1-textinst"> Institutions </span>  and access to direct internship-n-employment opportunities from prospective employers across various <span className="ban1-textindu"> Industries</span> .             
                                                </div> 
                                                <div class="banner_fontsize widthfstbannerone">                            
                                                Skillablers focus is to enable-n-empower the Youth/Millennials by providing them ample tools and guides to get career-ready and attract opportunities aplenty through our real-time platform. We facilitate the formulation of specific career goals - based on your qualifications, interests, values, personality and skills. Connect & Engage with us to prepare yourself for the workplace of the Millennials.             
                                                </div> 
                                            </div>
                                            <div class="col-12 col-sm-8 col-md-8 col-lg-3 col-flex">
                                                <div id="home-page-banner-carosoule" class="owl-carousel owl-theme home-banner-cal left-right-arrow" >
                                                    <div class="itemgetnotice">
                                                        {/* <div class="side-video-holder over-ride-heding-clr text-center ">
                                                            <video autoPlay controls loop id="finalvideo1">
                                                                <source src={Evetshomefirst} type="video/mp4"></source>
                                                            </video>
                                                        </div>  */}
                                                        {/* <div><img src={Getnoticedimagtext} className="imagwid3iandgetnotice"/></div> */}
                                                    </div>                                
                                                </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                   
                                </div>
                                {/* <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsizebanner" 
                                onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))}
                                >Know More</button></div>
                                </div> */}
                                 <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize"  onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))}>Know More</button></div>
                                </div>

                            </div>

                            <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={eventbannermobile} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0  mobbannerabsolute">
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={ifstbannermobile} alt="19" className="bannerthreeimobvie" /></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="centerflexmobbanner">
                                                <div class="banner_fontsize mobileviewliststyle">                            
                                                    <li>Skillablers, is a patented (Patent No: 446382) technology platform connecting <span className="ban1-textindi"> Individuals </span> with Industry-curated courses from <span className="ban1-textinst"> Institutions </span>  and access to direct internship-n-employment opportunities from prospective employers across various <span className="ban1-textindu"> Industries</span> . </li>            
                                                </div> 
                                            </div> 
                                            <div className="centerflexmobbanner">
                                                <div class="banner_fontsize mobileviewliststyle">                            
                                                <li> Skillablers focus is to enable-n-empower the Youth/Millennials by providing them ample tools and guides to get career-ready and attract opportunities aplenty through our real-time platform. We facilitate the formulation of specific career goals - based on your qualifications, interests, values, personality and skills. Connect & Engage with us to prepare yourself for the workplace of the Millennials.   </li>            
                                                    </div> 
                                            </div> 
                                            <div className="centerflexmobbanner">
                                                <div class="banner_fontsize mobileviewliststyle">                            
                                                <li> We facilitate the formulation of specific career goals - based on your qualifications, interests, values, personality and skills.</li>               
                                                    </div> 
                                            </div> 
                                            <div className="centerflexmobbanner">
                                                <div class="banner_fontsize mobileviewliststyle">                            
                                                <li> Connect & Engage with us to prepare yourself for the workplace of the Millennials  </li>             
                                                    </div> 
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={ ifstbannergetnoticemobi} alt="19" className="bannergetnoticed"  /></div>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="col-12 knowmorerealabsolute">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))}
                                            >Know More</button></div>
                                        </div>
                                  </div>
                            </div>

                        </div>
                        {/* 2nd banner  */} 
                        <div>
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" onClick={()=>this.redirec(0,'#/aatce')}>
                            <div className=" appleimgadivbanner">
                                <img src={AEcte} alt="19" className="newprocess" width="100%" />
                                <div className="row">
                                <div className=" positionbannerLeftText">
                                    <p className="font-apple-weight mb-0 banner_fontsize weightboldbannersubhead">Help nurturing the best in every student. </p>
                                    <p className="banner_fontsize mb-0">Apple Authorized Training Center for Education (AATCE)  is a specialized educational institution offering that is certified by Apple Inc. to provide training and certification programs focused on Apple technologies. These training centers offer courses and workshops designed to enhance the skills and knowledge of educators, IT professionals, and individuals.</p>
                                    <p className="banner_fontsize mb-0">Skillablers partnership with iPlanet, enables us to jointly offer educational institutions the opportunity to set up a dedicated Centre of Excellence Lab in campuses.</p>
                                    {/* <button id="" className="meet-our-team " onClick={()=>this.redirec(1,'#'+Routing.aatce)}>Know more</button> */}
                                </div>
                                <div className="col-6 positionbanner">
                                    {/* <p className="font-apple-weight ">Want to stand out from the crowd? </p> */}
                                {/* <div className="bannertextbelow">Apple Authorized Training Center for Education (AATCe)</div> */}
                                </div>
                                </div>
                                <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize"  onClick={()=>this.redirec(1,'#'+Routing.aatce)}>Know More</button></div>
                                </div>
                            </div>
                            {/* <div className="bannertextbelow">Apple Authorized Training Center for Education (AATCe)</div> */}
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={AACteMobileviewBanner} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0  AACteabsolute">
                                         
                                        <div className="col-12">
                                            <p className="font-apple-weight mb-0 banner_fontsize weightboldbannersubhead">Help nurturing the best in every student. </p>
                                            <p className="banner_fontsize mb-0">Apple Authorized Training Center for Education (AATCE)  is a specialized educational institution offering that is certified by Apple Inc. to provide training and certification programs focused on Apple technologies. These training centers offer courses and workshops designed to enhance the skills and knowledge of educators, IT professionals, and individuals.</p>
                                            <p className="banner_fontsize mb-0">Skillablers partnership with iPlanet, enables us to jointly offer educational institutions the opportunity to set up a dedicated Centre of Excellence Lab in campuses.</p>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="col-12 knowmorerealabsolute ">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={()=>this.redirec(0,'#/aatce')}
                                            >Know More</button></div>
                                        </div>
                                  </div>
                          </div>
                        </div>
                        
                        {/* 3rd banner  */}
                        <div className="">
                            <div class="ecosysparentwraper FstbannerDEsktop">
                            <img src={sklecosysbanner} className="ecobannerimg"/>
                                <div class="about-page-sec-sections individualBlockIdent  posabsolclsssecosystem" id="aboutUds">
                               
                                    <div class="row mx-0 col-12 px-0 align-cnt">
                                        {/* <div class="col-md-4 px-0">
                                            <div class="who-we-are-head pb-0">
                                                <div class="who-we-text">
                                                    <div className="skl-eco-system">The</div>
                                                    <div className="skl-eco-system" >Skillablers</div>
                                                    <div className="skl-eco-system">Ecosystem</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8 what-wedoChartss">
                                            <div class="fst-round">
                                                <div class="round-chart-datat">
                                                    <img src={secround} />
                                                </div>
                                            </div>
                                            <div class="sec-round">
                                                <div class="round-chart-datat"> <img src={thrdround} />
                                                </div>
                                            </div>
                                            <div class="thr-round">
                                                <div class="round-chart-datat">   <img src={fstround} /> </div>
                                            </div>
                                            <img src={skillablersbird1} class="img-skillablers-birds-sections" />
                                        </div> */}
                                        <div class="who-we-text newtextupdationskl mob-viewecosystem">
                                            <div className="skl-eco-system ">The Skillablers Ecosystem</div>
                                            <div className="Hea-below-ecosys">One platform to connect with individuals, Industries and Institutions where the objective is to have right individual at right place</div>
                                            {/* <div className="skl-eco-system" >Skillablers</div>
                                            <div className="skl-eco-system">Ecosystem</div> */}
                                        </div>
                                        <div class="col-lg-5 col-md-7  what-wedoChartss  ">
                                            {/* overallrotate-newvectorskl    (Rotateclass) */}
                                            {/* <div class="fst-round" onClick={()=>this.redirec(0,'#/indi-home-landing')}>
                                                <div class="round-chart-datat fstindiimag">
                                                    <img src={individualvector} 
                                                        // onMouseEnter={()=>this.Homedatesetactive(0)} 
                                                        className= {this.state.setanivalu===1?"indianimate indianimateActive":"indianimate"}
                                                        // onClick={()=>this.Homedatesetactive(0)}
                                                    />
                                                </div>
                                                <div className={this.state.setanivalu===1?"textinsidepent-indi indianimateText":"textinsidepent-indi"}>
                                                    <span 
                                                        // onClick={()=>this.Homedatesetactive(0)}
                                                        // onMouseEnter={()=>this.Homedatesetactive(0)} 
                                                    >   
                                                        Individuals
                                                    </span>
                                                </div>
                                                <div class="rou-indsidearrow1">
                                                    <img src={individualinsidevector1} />
                                                </div>
                                                <div class="Arrow-insideicon-home1">
                                                    <img src={Industry_Icon_vector} />
                                                </div>
                                            </div>
                                            <div class="sec-round" onClick={()=>this.redirec(0,'#/indu-home-landing')}>
                                                <div class="round-chart-datat"  >
                                                    <img src={industryvector} 
                                                        // onMouseEnter={()=>this.Homedatesetactive(1)} 
                                                        className= {this.state.setanivalu===0?"induanimate induanimateActive":"induanimate"}
                                                        // onClick={()=>this.Homedatesetactive(1)}
                                                    />
                                                </div>
                                                <div className={this.state.setanivalu===0?"textinsidepent-indus instanimateText":"textinsidepent-indus"}>
                                                    <span  
                                                        //  onClick={()=>this.Homedatesetactive(1)}
                                                        // onMouseEnter={()=>this.Homedatesetactive(1)} 
                                                    >
                                                        Industries
                                                    </span>
                                                </div>
                                                <div class="rou-indsidearrow2">
                                                    <img src={instituteinsidevector2} />
                                                </div>
                                                <div class="Arrow-insideicon-home2" >
                                                    <img src={Individual_Icon_vector} />
                                                </div>
                                            </div>
                                            <div class="thr-round thrdrou" onClick={()=>this.redirec(0,'#/inst-home-landing')}>
                                                <div class="round-chart-datat"  >  
                                                    <img src={Institutevector} 
                                                        // onMouseEnter={()=>this.Homedatesetactive(2)}  
                                                        className={this.state.setanivalu===2?"instanimate instanimateActive":"instanimate"}
                                                        // onClick={()=>this.Homedatesetactive(2)}
                                                    /> 
                                                </div>
                                                <div className={this.state.setanivalu===2 ? "instanimateText textinsidepent-insti": "textinsidepent-insti" } >
                                                    <span 
                                                        // onClick={()=>this.Homedatesetactive(2)}
                                                        // onMouseEnter={()=>this.Homedatesetactive(2)} 
                                                    >
                                                        Institutions
                                                    </span>
                                                </div>
                                                <div class="rou-indsidearrow3">
                                                    <img src={industryinsidevector3} />
                                                </div>
                                                <div class="Arrow-insideicon-home3">
                                                   <img src={Institutions_Icon_vector}/>
                                                </div>
                                            </div>
                                            <img src={skillablersbird1} class="img-skillablers-birds-sections" /> */}
                                            {/* <img src={SKLECOS} className="sklecosystem"/> */}
                                            
                                        </div>
                                        <div class="col-lg-7 col-md-5 px-0 margin_topvalue">
                                            <div class=" pb-0 ">
                                                {/* dis-blk-sepatesec heightclass*/}
                                                <div class="who-we-text newtextupdationskl  mobdisnoneclsEcosystem">
                                                    <div className="skl-eco-system  text-pop-up-top">The Skillablers Ecosystem</div>
                                                    <div></div>
                                                    <div className="Hea-below-ecosys">One platform to connect with individuals, Industries and Institutions where the objective is to have right individual at right place</div>
                                                    {/* <div className="skl-eco-system" >Skillablers</div>
                                                    <div className="skl-eco-system">Ecosystem</div> */}
                                                </div>
                                                <div className="wholetextholding-homeabt">
                                                    
                                                    <div className="animate_individual">
                                                    <div className="row ">
                                                    <div className="textandknowbut  col-11">
                                                    <div className="Inditext-homeabt ">Individuals  </div>
                                                    {/* <div className="col-6 d-flex align-items-center"><div class="arrow-1" ></div></div> */}
                                                    {/* <div className="knomore-ecosystem  col-2 point" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>Know more </div> */}
                                                     <div className=""><button id="" className="meet-our-team knowmore-btnsize " onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>Know More</button></div>
                                                    </div>
                                                    </div>
                                                    <div className="Inditext_abthom-belowconten ">Empowering individuals to succeed with clear career perspective by offering latest openings by industries</div></div>
                                                    <div className="animate_institute">
                                                    <div className="row">
                                                    <div className="textandknowbut col-11">
                                                    <div className="Inditext-homeabt  Indutexthomeabt ">Institutions</div>
                                                    {/* <div className="knomore-ecosystem  col-2 point" onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}>Know more </div> */}
                                                    <div className=""><button id="" className="meet-our-team knowmore-btnsize " onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}>Know More</button></div>
                                                    </div>
                                                    </div>
                                                    <div className="Inditext_abthom-belowconten ">Enables institutions to be catalyst to individual success, acting as Placement partner with many institutions</div></div>
                                                    <div className="animate_industry ">
                                                    <div className="row">
                                                    <div className="textandknowbut col-11">
                                                    <div className="Inditext-homeabt  Institexthomeabt ">Industries</div>
                                                    {/* <div className="knomore-ecosystem  col-2 point" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>Know more </div> */}
                                                    <div className=""><button id="" className="meet-our-team  knowmore-btnsize "  onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>Know More</button></div></div>
                                                    </div>
                                                    <div className="Inditext_abthom-belowconten ">Industries connect with us to create career opportunity for the youth as well as the experience professionals</div></div>
                                                    
                                                    
                                                    
                                                    <div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="Fstbannermob">
                                  <div >
                                    <img src={SklbannerEcosyMob} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0  mobbannerabsolute">
                                        <div className="col-12 ">
                                            <div className="skl-eco-system ">The Skillablers Ecosystem</div>
                                            <div className="Hea-below-ecosys">One platform to connect with individuals, Industries and Institutions where the objective is to have right individual at right place</div>
                                        </div>
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={ThreeiconnecthexagonMob} alt="19" className="hexathreeiimgMob" /></div>
                                        </div>
                                        
                                        <div className="textandknowbut  col-12">
                                             <div className="Inditext-homeabt ">Individuals  </div>
                                                        {/* <div className="col-6 d-flex align-items-center"><div class="arrow-1" ></div></div> */}
                                                        {/* <div className="knomore-ecosystem  col-2 point" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>Know more </div> */}
                                            <div className=""><button id="" className="meet-our-team knowmore-btnsize Reducebtnsize" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>Know More</button></div>
                                             </div>
                                            <div className="Inditext_abthom-belowconten ">Empowering individuals to succeed with clear career perspective by offering latest openings by industries</div>
                                        <div className="textandknowbut col-12">
                                                <div className="Inditext-homeabt  Indutexthomeabt ">Institutions</div>
                                                    {/* <div className="knomore-ecosystem  col-2 point" onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}>Know more </div> */}
                                                    <div className=""><button id="" className="meet-our-team knowmore-btnsize Reducebtnsize" onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}>Know More</button></div>
                                                    </div>
                                                <div className="Inditext_abthom-belowconten ">Enables institutions to be catalyst to individual success, acting as Placement partner with many institutions</div>
                                        <div className="textandknowbut col-12">
                                                <div className="Inditext-homeabt  Institexthomeabt ">Industries</div>
                                                    {/* <div className="knomore-ecosystem  col-2 point" onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}>Know more </div> */}
                                                    <div className=""><button id="" className="meet-our-team  knowmore-btnsize Reducebtnsize"  onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>Know More</button></div></div>
                                                <div className="Inditext_abthom-belowconten ">Industries connect with us to create career opportunity for the youth as well as the experience professionals</div>
                                    </div>
                                    <div></div>
                                  </div>
                            </div>
                        </div>
                        {/* 4th banner  */}
                        <div className="">
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" >
                        <div className=" appleimgadivbanner">
                                <img src={Newprocessimg} alt="19" className="newprocess" width="100%" />
                                <div className="row">
                                <div className="col-6">
                                {/* <img src={AATCE_banner} alt="19" width="100%" /> */}
                                </div>
                                <div className="col-6 positionbanner">
                                <div className="skl-eco-system  text-pop-up-top individualtextclr">Individuals</div>
                                    <p className="font-apple-weight mb-0 banner_fontsize weightboldbannersubhead">Want to stand out from the crowd? </p>
                                    <p className="banner_fontsize mb-0">The SKILLABLERS Platform offers a unique approach to candidate evaluation through visual profiling. Gone are the days of relying solely on resumes and interviews to gauge a candidate's suitability. With this unique profiling method, organizations can now assess candidates based on their practical skills, competencies, and abilities - all presented through an engaging and unique dashboard format.</p>
                                    <p className="banner_fontsize mb-0">Visual profiling gives you the power to express yourself.</p>
                                    {/* <button id="" className="meet-our-team " onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)}>Know more</button> */}
                                </div>
                                </div>
                                <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize"  onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)}>Know More</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={VisualprofilevbanneremptyMob} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0  mobbannerabsolute">
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={SvpviualpropfileimgMob} alt="19" className="" /></div>
                                        </div>
                                        <div className="col-12 pad-topvisualprofilmob">
                                                <div className="skl-eco-system  text-pop-up-top connectetxtMob individualtextclr">Individuals</div>
                                            <p className="font-apple-weight mb-0 banner_fontsize weightboldbannersubhead">Want to stand out from the crowd? </p>
                                            <p className="banner_fontsize mb-0">The SKILLABLERS Platform offers a unique approach to candidate evaluation through visual profiling. Gone are the days of relying solely on resumes and interviews to gauge a candidate's suitability. With this unique profiling method, organizations can now assess candidates based on their practical skills, competencies, and abilities - all presented through an engaging and unique dashboard format.</p>
                                            <p className="banner_fontsize mb-0">Visual profiling gives you the power to express yourself.</p>
                                        </div>
                                
                                       
                                    </div>
                                    <div className="col-12  knowmorerealabsolute">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)}
                                            >Know More</button></div>
                                    </div>
                                  </div>
                            </div>
                        </div>
                        {/* 6th banner  */} 
                        <div className="">
                        <div className="">
                        <div className="point bannerimg-heightdiv  FstbannerDEsktop">
                            <div className=" appleimgadivbanner">
                            <img src={InstitutionsNew2024banner} className="newprocess" alt="19" width="100%" />
                                    <div className="row">
                                    <div className="col-6">
                                     
                                    </div>
                                    <div className="col-6 positionbanner institutionbanner">
                                   
                                    {/* <div className="skl-eco-system  text-pop-up-top"><span className="pfscoreclr">Profile</span> <span className="score360">360</span> <span className="pfscoreclr">Score</span> (P<span className="clrredd">360</span>S)</div> */}
                                    <div className="skl-eco-system  text-pop-up-top institutetextclr">Institutions</div>
                                     
                                        <p className=" mb-0 banner_fontsize instfontweightclrfst ">
                                        Unleashing Campus Hiring Efficiency:
                                        {/* <span className="pfscoreclr">P<span className="score360">360</span>S</span>
                                        <span> is a powerful rating mechanism that is  assigned to an individual's profile based on certain criteria.</span> */}
                                        </p>
                                        {/* <div className="instfontweightclrEfficency">Efficiency:</div> */}
                                        <p className=" mb-0 banner_fontsize instfontweightclr">Zero-Cost, Top Talent</p>
                                        
                                      
                                        <p className="banner_fontsize mb-0 fntweigttextinst padbotminst">Hire Top 10% from Campuses Guaranteed.</p>
                                        <p className="banner_fontsize mb-0 fntweigttextinst">Eliminate Recruitment Costs.</p>
                                      
                                    </div>
                                    </div>
                                    <div className="col-12 positionbannerYccc">
                                    <div className=""><button id="" className="meet-our-team knowmore-btnsize"  onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}>Know More</button></div>
                                    </div>
                                </div>
                              
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={Mobile_Institutions2024banner} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0  mobbannerabsolute inst2024textblock">
                                       
                                        <div className="col-12">
                                        <div className="skl-eco-system  text-pop-up-top institutetextclr textaligninst">Institutions</div>
                                        <p className=" mb-0 banner_fontsize instnewbannerfsttext">Unleashing Campus Hiring Efficiency: </p>
                                        <p className=" mb-0 banner_fontsize instnewbannersectext">Zero-Cost, Top Talent</p>
                                        <p className="banner_fontsize mb-0 instlasttextnewbanner padbotminst">Hire Top 10% from Campuses Guaranteed.</p>
                                        <p className="banner_fontsize mb-0 instlasttextnewbanner">Eliminate Recruitment Costs.</p>
                                        </div>
                                       
                                    </div>
                                    <div className="col-12   knowmorerealabsolute ">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}
                                            >Know More</button></div>
                                        </div>
    
                                  </div>
                            </div>
                         </div>
                           
                        </div>
                        {/* 6th banner  */}
                        <div className="">
                        <div className="point bannerimg-heightdiv  FstbannerDEsktop">
                            <div className=" appleimgadivbanner">
                            <img src={IndustryNew2024banner} className="newprocess" alt="19" width="100%" />
                                    <div className="row">
                                    <div className="col-6">
                                     
                                    </div>
                                    <div className="col-6 positionbanner institutionbanner">
                                   
                                    {/* <div className="skl-eco-system  text-pop-up-top"><span className="pfscoreclr">Profile</span> <span className="score360">360</span> <span className="pfscoreclr">Score</span> (P<span className="clrredd">360</span>S)</div> */}
                                    <div className="skl-eco-system  text-pop-up-top industrytextclr">INDUSTRIES</div>
                                     
                                        <p className=" mb-0 banner_fontsize industryfontweightclrfst ">
                                        <span className="ban1-textindu">Patented platform</span> transforming campus hiring by connecting <span className="ban1-textindu">Individuals</span>, <span className="ban1-textindu">Institutions</span>, and <span className="ban1-textindu">Industries</span> together.
                                        </p>
                                       
                                       
                                        
                                      
                                        <p className="banner_fontsize mb-0 fntweigttextinst">Assured placement opportunities for all eligible students.</p>
                                      
                                    </div>
                                    </div>
                                    <div className="col-12 positionbannerYccc">
                                    <div className=""><button id="" className="meet-our-team knowmore-btnsize"  onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>Know More</button></div>
                                    </div>
                                </div>
                              
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={Mobile_Industriess2024banner} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0  mobbannerabsolute inst2024textblock">
                                       
                                        <div className="col-12">
                                        <div className="skl-eco-system  text-pop-up-top industrytextclr textaligninst">INDUSTRIES</div>
                                        <p className=" mb-0 banner_fontsize instnewbannerfsttext indusnewtextpadbot"><span className="ban1-textindu">Patented platform</span> transforming campus hiring by connecting <span className="ban1-textindu">Individuals</span>, <span className="ban1-textindu">Institutions</span>, and <span className="ban1-textindu">Industries</span> together</p>
    
                                        <p className="banner_fontsize mb-0 instlasttextnewbanner">Assured placement opportunities for all eligible students.</p>
                                        </div>
                                       
                                    </div>
                                    <div className="col-12   knowmorerealabsolute ">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}
                                            >Know More</button></div>
                                        </div>
    
                                  </div>
                            </div>
                        </div>

                        {/* 7th banner  */}
                        <div className="">
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" 
                        // onClick={()=>this.redirec(0,'#/YCC-2022')}
                        onClick={this.YCCkerala2024}
                        >
                        <div className=" appleimgadivbanner">
                            <img src={Yccnewbanner20} className="newprocess" alt="19" width="100%" />
                                <div className="row">
                                <div className="col-6">
                                {/* <img src={AATCE_banner} alt="19" width="100%" /> */}
                                </div>
                                <div className="col-12 positionbanner  pos-newycc2">
                                             <p className="banner_fontsize mb-0">YOUTH CAREER CONNECT (popularly known as YCC) is a seminar-session series addressing youth from various fields by providing them opportunities to directly interact with professionals from INDUSTRIES and INSTITUTIONS to express their thoughts on current and future trends.</p>
                                            <p className="banner_fontsize mb-0">With Youth as the focal point of our dialogue, our intent is to bring a wide spectrum of the challenges and recommended solutions across various industry verticals apart from guiding and providing opportunities for their CAREERS, ADMISSIONS, INTERNSHIPS, PLACEMENTS and STARTUPS.</p>  
                                </div>
                                </div>
                                <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize" 
                                // onClick={()=>this.redirec(0,'#'+Routing.ycc)}
                                onClick={this.YCCkerala2024}
                                >Know More</button></div>
                                </div>
                            </div>
                            {/* <div className="bannertextbelow">Youth Career Connect (YCC)</div> */}
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={eventbannermobile} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0  mobbannerabsolute ">
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={Ycc2imaginmobbanner} alt="19" className="banneryccmobvie" /></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={Perspectivethatmatterimag} alt="19" className="bannerprespective"  /></div>
                                        </div>
                                        <div className="col-12 pad-topvisualprofilmob newpaddmobilycc2o">
                                             <p className="banner_fontsize mb-0">YOUTH CAREER CONNECT (popularly known as YCC) is a seminar-session series addressing youth from various fields by providing them opportunities to directly interact with professionals from INDUSTRIES and INSTITUTIONS to express their thoughts on current and future trends.</p>
                                              <p className="banner_fontsize mb-0">With Youth as the focal point of our dialogue, our intent is to bring a wide spectrum of the challenges and recommended solutions across various industry verticals apart from guiding and providing opportunities for their CAREERS, ADMISSIONS, INTERNSHIPS, PLACEMENTS and STARTUPS.</p>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="col-12  knowmorerealabsolute">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            //  onClick={()=>this.redirec(0,'#'+Routing.ycc)}
                                             onClick={this.YCCkerala2024}
                                            >Know More</button></div>
                                        </div>
                                  </div>
                            </div>
                        </div>
                       {/* IAQ-banner */}
                          <div>
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" onClick={this.RoutingToQUIBTION}>
                            <div className=" appleimgadivbanner">
                                <img src={Inquizitionbanner} alt="19" className="newprocess" width="100%" />
                                <div className="row">
                                <div className="positionbannercentertextIAQ">
                                    <p className="banner_fontsize mb-0 pb-0 bannefontsize-IAQ">A Weekly Quiz to test your</p>
                                    <p className="banner_fontsize mb-0 pb-0  bannefontsize-IAQ">Industry Awareness Knowledge across <span className="ban1-textindi">40+ SECTORS</span></p>
                            
                                    {/* <button id="" className="meet-our-team " onClick={()=>this.redirec(1,'#'+Routing.aatce)}>Know more</button> */}
                                </div>
                                </div>
                                <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize"  onClick={this.RoutingToQUIBTION}>Take Quiz</button></div>
                                </div>

                            </div>
                            {/* <div className="bannertextbelow">Apple Authorized Training Center for Education (AATCe)</div> */}
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={eventbannermobile} alt="19" className="bannermobbackgroundFst " width="100%" />
                                    <div className="row mx-0  mobbannerabsolute">
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={InquiztionMobbanner} alt="19" className="bannerthreeimobvie img-widmobYCCSERfstimg" /></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="centerflexmobbanner">
                                                <div class="banner_fontsize mobileviewliststyle pdtop-wid-textmob-IAQ-ycc">                            
                                                      <div>A Weekly Quiz to test your</div>    
                                                      <div>Industry Awareness Knowledge across <span className="ban1-textindi">40+ SECTORS</span></div>         
                                                </div> 
                                            </div> 
                                           
                                        </div>
                                        <div className="col-12">
                                            <div className="centerflexmobbanner"><img src={ YCCiaqquesMarkLOG} alt="19" className="bannergetnoticed  img-widmobYCCSERLastimg"  /></div>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="col-12 knowmorerealabsolute">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={this.RoutingToQUIBTION}
                                            >Take Quiz</button></div>
                                        </div>
                                  </div>
                            </div>
                        </div>
                         {/* IAQ-banner */}

                            {/* sklaspire banner  */}
                            <div>
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" onClick={this.sklaspire}>
                            <div className=" appleimgadivbanner">
                                <img src={Aspirebannerdesk} alt="19" className="newprocess" width="100%" />
                                <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize" onClick={this.sklaspire}>Know More</button></div>
                                </div>
                            </div>
                            {/* <div className="bannertextbelow">Apple Authorized Training Center for Education (AATCe)</div> */}
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={Aspirebannermob} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    
                                    <div className="row mx-0  AACteabsolute sklaspirebannermob">
                                        <div className="col-12">
                                         <div className="centerflexmobbanner"><img src={Aspireimgandtextmob} alt="19" className="banneryccmobvie" /></div>
                                         </div>
                                      </div>
                                    <div className="col-12 knowmorerealabsolute ">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            // onClick={()=>this.redirec(0,'#/aatce')}
                                           onClick={this.sklaspire}
                                            >Know More</button></div>
                                        </div>
                                  </div>
                          </div>
                        </div>
                        {/* sklaspire banner  */}
                         
                        {/* 8th banner  */}
                        <div className="">
                        <div className="point bannerimg-heightdiv FstbannerDEsktop" onClick={()=>this.redirec(8,viewalleventurl+'iaq-home')}>
                            {/* <img src={Event} alt="19" width="100%" /> */}
                            <div className=" appleimgadivbanner">
                            <img src={Event} className="newprocess" alt="19" width="100%" />
                                <div className="row">
                                <div className="col-6">
                                {/* <img src={AATCE_banner} alt="19" width="100%" /> */}
                                </div>
                                <div className="col-6 positionbanner">
                                    {/* <p className=" mb-0"><span className="pfscoreclr">Connect</span> <span className="score360">360</span> <span className="pfscoreclr">Score</span> <span className="pfscoreclr">(C<span className="score360">360</span>S)</span> is a measure of your skills and abilities </p>
                                    <p className="banner_fontsize mb-0">that is used to match you with jobs. It is calculated based on your </p>
                                    <p className="font-apple-weight banner_fontsize mb-0">education, work experience, and skills assessments.</p> */}
                                {/* <div className="bannertextbelow">Apple Authorized Training Center for Education (AATCe)</div> */}
                                </div>
                                </div>
                                <div className="col-12 positionbannerYccc">
                                <div className=""><button id="" className="meet-our-team knowmore-btnsize" onClick={()=>this.redirec(8,viewalleventurl+'iaq-home')}>Know More</button></div>
                                </div>
                            </div>
                            {/* <div className="bannertextbelow">Industry Awareness Quiz (IAQ)</div> */}
                        </div>
                        <div className="Fstbannermob">
                                  <div className="relimgbtn">
                                    <img src={eighthbannerIAQMob} alt="19" className="bannermobbackgroundFst" width="100%" />
                                    <div className="row mx-0">
                                     
                                       
                                    </div>
                                    <div className="col-12 knowmorerealabsolute">
                                                    <div className="knowmorbtnwrap"><button id="" className="meet-our-team knowmore-btnsize" 
                                            onClick={()=>this.redirec(8,viewalleventurl+'iaq-home')}
                                            >Know More</button></div>
                                        </div>
                                  </div>
                            </div>
                            </div>
                    </Slider>
                </div>
                {/* <div class="about-page-sec-sections individualBlockIdent" id="aboutUds">
                    <div class="row mx-0 col-12 px-0 align-cnt">
                      
                        <div class="who-we-text newtextupdationskl mob-viewecosystem">
                                    <div className="skl-eco-system ">The Skillablers Ecosystem</div>
                                    <div className="Hea-below-ecosys">One platform to connect with individuals, Industries and Institutions where the objective is to have right individual at right place</div>
                                     
                        </div>
                         <div class="col-lg-5 col-md-7  what-wedoChartss  overallrotate-newvectorskl">

                            <div class="fst-round">
                                <div class="round-chart-datat fstindiimag">
                                    <img src={individualvector} 
                                    
                                    className= {this.state.setanivalu===1?"indianimate indianimateActive":"indianimate"}
                                     
                                    />
                                </div>
                                <div className={this.state.setanivalu===1?"textinsidepent-indi indianimateText":"textinsidepent-indi"}><span 
                             
                                >Individuals</span></div>
                                <div class="rou-indsidearrow1">
                                    <img src={individualinsidevector1} />
                                </div>
                                <div class="Arrow-insideicon-home1">
                                   
                                     <img src={Industry_Icon_vector} />
                                </div>
                            </div>
                            <div class="sec-round">
                                <div class="round-chart-datat"  >
                                    <img src={industryvector} 
                                    
                                    className= {this.state.setanivalu===0?"induanimate induanimateActive":"induanimate"}
                                  
                                    />
                                </div>
                                 <div className={this.state.setanivalu===0?"textinsidepent-indus instanimateText":"textinsidepent-indus"}><span  
                                 
                                 >Industries</span></div>
                                <div class="rou-indsidearrow2">
                                    <img src={instituteinsidevector2} />
                                </div>
                                <div class="Arrow-insideicon-home2" >
                                <img src={Individual_Icon_vector} />
                                </div>
                            </div>
                            <div class="thr-round thrdrou">
                                <div class="round-chart-datat"  >  
                                <img src={Institutevector} 
                                
                                className={this.state.setanivalu===2?"instanimate instanimateActive":"instanimate"}
                                
                                /> 
                                </div>
                                <div className={this.state.setanivalu===2 ? "instanimateText textinsidepent-insti": "textinsidepent-insti" } ><span 
                                 
                               
                                >Institutions</span></div>
                                <div class="rou-indsidearrow3">
                                    <img src={industryinsidevector3} />
                                </div>
                                <div class="Arrow-insideicon-home3">
                                    <img src={Institutions_Icon_vector}/>
                                </div>
                            </div>
                            <img src={skillablersbird1} class="img-skillablers-birds-sections" />
                        </div>

                        <div class="col-lg-7 col-md-5 px-0">
                            <div class="who-we-are-head pb-0 dis-blk-sepatesec">
                                <div class="who-we-text newtextupdationskl  mobdisnoneclsEcosystem">
                                    <div className="skl-eco-system  text-pop-up-top">The Skillablers Ecosystem</div>
                                    <div></div>
                                    <div className="Hea-below-ecosys">One platform to connect with individuals, Industries and Institutions where the objective is to have right individual at right place</div>
                                </div>
                                <div className="wholetextholding-homeabt">
                                    {this.state.activestatushome === 0 ?
                                    <div className="animate_individual">
                                    <div className="Inditext-homeabt  ">Individuals</div>
                                    <div className="Inditext_abthom-belowconten ">Empowering individuals to succeed with clear career perspective by offering latest openings by industries</div></div>
                                    :
                                    this.state.activestatushome === 1 ?
                                    <div className="animate_industry ">
                                    <div className="Inditext-homeabt  Institexthomeabt">Industries</div>
                                    <div className="Inditext_abthom-belowconten ">Industries connect with us to create career opportunity for the youth as well as the experience professionals</div></div>
                                    :
                                    this.state.activestatushome === 2 ?
                                    <div className="animate_institute">
                                    <div className="Inditext-homeabt  Indutexthomeabt">Institutions</div>
                                    <div className="Inditext_abthom-belowconten ">Enables institutions to be catalyst to individual success, acting as Placement partner with many institutions</div></div>
                                    :
                                    <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div> */}
                {/* //constant redirection */}
                <div className="custom-containercls-tabswrap  desktopbannerbelowsec">
                        <div class="bottom-holder event-details-heading">
                            <div class="row mx-0 option-holder-width">                           
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 px-0 mb-2 mb-md-0 hole-div"  >
                                    {/* <a href={'#/indi-home-landing'}> */}
                                        <div class="skl-type row">
                                            <div class="col-3 pl-0  hovereffect">
                                                <img src={engaging} alt=' . $key->home_banner_spots_title . ' onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)} id="bannerspots" class="img-fluid" /></div>
                                            <div className="col-9  pl-0">
                                                <p id="bannerspots" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>ENGAGING </p>
                                                <span id="bannerspots" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>YOUTH</span>
                                            </div>
                                        </div>
                                    {/* </a> */}
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 px-0 mb-2 mb-md-0 "  >
                                    {/* <a  href={'#/indu-home-landing'}> */}
                                        <div class="skl-type row mx-0">
                                            <div class="col-3 pl-0 hovereffect">
                                                <img src={connecting} alt=' . $key->home_banner_spots_title . ' id="bannerspots" class="img-fluid" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}/></div>
                                            <div className="col-8 pl-0 ">
                                                <p id="bannerspots" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>CONNECTING </p>
                                                <span id="bannerspots" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>INDUSTRIES</span>
                                            </div>
                                        </div>
                                    {/* </a> */}
                                </div>
                                <div class="col-6 col-sm-6 col-md-3 col-lg-3 px-0 mb-2 mb-md-0"  >
                                {/* <NavLink 
                        onClick={()=>this.AutoScrollPartner( (window.location.hash.includes(Routing.Home) === true ?true:false) )}
                        
                        
                         to={Routing.Job}
                           > */}
                        
                                    {/* <a href="#/job-search?1"> */}
                                        <div class="skl-type mx-0 txt-align-left row" >
                                        <div class="col-7  pr-0" >
                                                <p id="bannerspots" className="paraauto" onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)}>PIONEERING </p>
                                                <span id="bannerspots" onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)}>VISUAL PROFILING</span>
                                            </div>
                                            <div class="col-3 pr-0 hovereffect" >
                                                <img src={pointering} alt=' . $key->home_banner_spots_title . ' class="img-fluid" onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)} id="bannerspots"/></div>
                                            
                                        </div>
                                    {/* </a> */}
                                    {/* </NavLink>  */}
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 px-0 mb-2 mb-md-0 tabviewmarginbtm lasttab"  >
                                        <div class="skl-type txt-align-left mx-0 row jc-left">
                                        <div class="col-9  pr-0">
                                                <p id="bannerspots" className="paraauto" onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))}>BRIDGING </p>
                                                <span id="bannerspots" onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))}>URBAN - RURAL DIVIDE</span>
                                            </div>
                                            <div class="col-3 pr-0 hovereffect">
                                                <img src={bridging} alt=' . $key->home_banner_spots_title . ' onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))} class="img-fluid" id="bannerspots"/></div>
                                           
                                        </div>
                                </div>                         
                            </div>
                        </div>
                </div>
                <div className="Mobilebannerbelowsec">
                <div className="row wd-90-mob ">
                    <div className="col-6 px-0 disflexiePoinering">
                        <div className="engaeflexmob">
                        <img src={engaging} alt=' . $key->home_banner_spots_title . ' onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)} id="bannerspots" class="fluidengageimag" />
                        <div><p id="bannerspots" className="engagemar" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>ENGAGING </p>
                            <span id="bannerspots" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>YOUTH</span>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 px-0 disflexiePoinering">
                        <div className="engaeflexmob">
                        <img src={connecting} alt=' . $key->home_banner_spots_title . ' id="bannerspots" class="fluidengageimag" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}/>
                        <div> <p id="bannerspots"  className="engagemar" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>CONNECTING </p>
                             <span id="bannerspots" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>INDUSTRIES</span>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="row wd-90-mob">
                    <div className="col-6 px-0 disflexiePoinering">
                        <div className="engaeflexmob">
                            <img src={pointering} alt=' . $key->home_banner_spots_title . 'onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)} id="bannerspots" class="fluidengageimag" />
                            <div><p id="bannerspots" className="engagemar" onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)}>PIONEERING </p>
                                <span id="bannerspots" className="fntsmallmob" onClick={()=>this.redirec(1,'#'+Routing.indihomelanding)}>VISUAL PROFILING</span>
                            </div>
                            </div>
                    </div>
                    <div className="col-6 px-0 disflexiePoinering">
                        <div className="engaeflexmob ">
                            <img src={bridging} alt=' . $key->home_banner_spots_title . ' onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))} id="bannerspots" class="fluidengageimag" />
                            <div><p id="bannerspots" className="engagemar"  onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))}>BRIDGING </p>
                                <span id="bannerspots" className="fntsmallmob" onClick={()=>this.Autobridging((window.location.hash.includes(Routing.Home) === true ?true:false))}>URBAN-RURAL DIVIDE</span>
                            </div>
                            </div>
                    </div>
                </div>
                </div>
            {/* //constant redirection */}
                {/* <div className="mouse" onClick={this.Autoscrollto3i}></div> */}
                <div className="about-html-main-conatiner abt-main-container-new  martop-skl3icon" id="Three-iconnect">
                    {/* <div class="about-us-fst-block">
                <div class="avbout-us-skl-text">THE SKILLABLERS ECOSTYSTEM</div>
                <blockquote class="quotes">We are a Patent Pending Technology Ecosystem, connecting Individuals, Institutions and Industries. The platform allows you to GET NOTICED by addressing skill gaps with Industry Readiness Programs and connecting to the right Career Opportunities.</blockquote>
            </div> */}
                    <div className="skl-prod-main-cont individualBlockIdent" id="skl-prod-main-cont">
                        {/* <div className="col-12 px-0 row mx-0">
                            <div className="col-2  px-0">
                                <a target='_blank'>
                                    <div className={this.state.activeData === 1 ? "skl-productss active" : "skl-productss"}
                                        onClick={() => { this.setState({ activeData: 1 }) }}>
                                        <div className="skl-image-blocks">
                                            <img src={svp} />
                                        </div>
                                        <div>
                                            <div className="skl-prod-name">Visual Profiling</div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-2  px-0">
                                <a target='_blank'>
                                    <div className={this.state.activeData === 2 ? "skl-productss active" : "skl-productss"}
                                        onClick={() => { this.setState({ activeData: 2 }) }}>
                                        <div className="skl-image-blocks"
                                        >
                                            <img src={P360} />
                                        </div>
                                        <div>
                                            <div className="skl-prod-name">Profile 360 score</div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-2  px-0">

                                <div className={this.state.activeData === 3 ? "skl-productss active" : "skl-productss"}
                                    onClick={() => { this.setState({ activeData: 3 }) }}>
                                    <div className="skl-image-blocks">
                                        <img src={job} />
                                    </div>
                                    <div>
                                        <div className="skl-prod-name">Job connect</div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-2  px-0">
                                <a target='_blank'>
                                    <div className={this.state.activeData === 4 ? "skl-productss active" : "skl-productss"}
                                        onClick={() => { this.setState({ activeData: 4 }) }}>
                                        <div className="skl-image-blocks">
                                            <img src={online} />
                                        </div>
                                        <div>
                                            <div className="skl-prod-name">online interviews</div>

                                        </div>
                                    </div>
                                </a></div>
                            <div className="col-2  px-0">
                                <a target='_blank'>
                                    <div className={this.state.activeData === 5 ? "skl-productss active" : "skl-productss"}
                                        onClick={() => { this.setState({ activeData: 5 }) }}>
                                        <div className="skl-image-blocks">
                                            <img src={cousell} />
                                        </div>
                                        <div>
                                            <div className="skl-prod-name">career counselling</div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-2  px-0">
                                <a target='_blank'>
                                    <div className={this.state.activeData === 6 ? "skl-productss active" : "skl-productss"}
                                        onClick={() => { this.setState({ activeData: 6 }) }}>
                                        <div className="skl-image-blocks">
                                            <img src={upskill} />
                                        </div>
                                        <div>
                                            <div className="skl-prod-name">Learning & upskilling</div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div> */}
                        <div className={this.state.activeData === 1 ? "col-12 px-0 SKLhome-data-containerss active fst-border" :
                            this.state.activeData === 6 ? "col-12 px-0 SKLhome-data-containerss active lst-border" : "col-12 px-0 SKLhome-data-containerss active all-border new-containr"}>
                            {/* <div className="col-12 px-0 row mx-0">
                                <div className="col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0"   >
                                    <div className="visul-porfile-head">
                                        {this.state.activeData === 1 ? <>
                                            <div className="visual-profile-head1">Create your </div>
                                            <div className="visual-haed-two">Visual profile</div>
                                        </> :
                                            this.state.activeData === 2 ?
                                                <>
                                                    <div className="visual-profile-head1">Check out your</div>
                                                    <div className="visual-haed-two">Profile 360 Score</div>
                                                </>
                                                : this.state.activeData === 3 ?
                                                    <>
                                                        <div className="visual-profile-head1">FIND YOUR</div>
                                                        <div className="visual-haed-two">perfect job</div>
                                                    </>
                                                    :
                                                    this.state.activeData === 4 ?
                                                        <>
                                                            <div className="visual-profile-head1">Attend your</div>
                                                            <div className="visual-haed-two">online interviews</div>
                                                        </> :
                                                        this.state.activeData === 5 ?
                                                            <>
                                                                <div className="visual-profile-head1">Get your</div>
                                                                <div className="visual-haed-two">Career counselling</div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="visual-profile-head1">upgrade your
                                                                </div>
                                                                <div className="visual-haed-two">skills</div>
                                                            </>
                                        }
                                    </div>
                                    <div className="visual-data-contentss">
                                        {this.state.activeData === 1 ?
                                            <>
                                                Want to Stand out from the Crowd?  Visual profiling
                                                gives you the power to express yourself. In this digital
                                                age, video profiles are undoubtedly the future.
                                            </>
                                            :
                                            this.state.activeData === 2 ?
                                                <>
                                                    P360 Score is a powerful rating mechanism that takes into account
                                                    various attributes of your Skills, Experience and Accomplishments across
                                                    various categories. On a scale of 360, your score is a weightage calculated
                                                    based on your Academics, Skills, Experience and other accomplishments. </>
                                                :
                                                this.state.activeData === 3 ?
                                                    <>
                                                        Got the Right Skills?  Embark on a career journey
                                                        that allows you to Learn, Grow and accelerate!
                                                    </> :
                                                    this.state.activeData === 4 ?
                                                        <>
                                                            Connect with the candidates with a one click
                                                            scheduling and interviewing platform. Avoid multiple email / phone
                                                            follow-ups with candidates. With real-time video feedback capture,
                                                            this truly makes your overall recruitment process comprehensive,
                                                            complete and efficient.
                                                        </> :
                                                        this.state.activeData === 5 ?
                                                            <>
                                                                Every successful journey stands on the basis of sound advice. Our expert counsellors provide one on one personalised sessions and can advise on college admissions, the right courses that matches your aptitude and aspirations, all leveraging a powerful psychometric evaluation platform.</>
                                                            :
                                                            <>
                                                                Learning is a continuous process!  keep yourself relevant
                                                                through our industry readiness programs that helps bridge
                                                                your skills and employability gaps.</>

                                        }
                                    </div>
                                    {this.state.activeData === 3 &&
                                     <div  className="visual-view-more-btn-btnnns">
                                      <HashRouter><Link to={Routing.Job} >
                                      <button className="visual-view-more-btn lapview-btn"  >View jobs</button></Link></HashRouter>
                                      </div>
                                    }
                                    {this.state.activeData === 5 &&
                                     <div  className="visual-view-more-btn-btnnns">
                                     <button className="visual-view-more-btn lapview-btn" onClick={()=>{this.openModal()}} >Contact us</button>
                                      </div>
                                    }
                                    {(this.state.activeData !== 4 && this.state.activeData !== 3  && this.state.activeData !== 5) &&
                                        <div className="visual-view-more-btn-btnnns">
                                            {console.log('iamm herer')}
                                            <button className="visual-view-more-btn lapview-btn"
                                            onClick={()=>{this.state.activeData === 5 && this.openModal()}}>
                                                <a href={this.state.activeData === 1 ? "https://indi.skillablers.com/indi-login" :
                                                    this.state.activeData === 2 ? "https://indi.skillablers.com/indi-login" :
                                                        this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                    {this.state.activeData === 1 ? "Create Profile" : this.state.activeData === 2 ? " view your p360 score " :
                                                        this.state.activeData === 3 ? "View jobs" : this.state.activeData === 5 ? "Contact us" :
                                                            "View Courses"}</a></button>
                                            {this.state.activeData === 2 &&
                                                <div className="create-your-profie">by creating your profile</div>}
                                        </div>
                                    }
                                    
                                </div>
                                <div className="col-sm-6 col-12 dflex-cent padding-zer">
                                    <img src={this.state.activeData === 1 ? DtsSvp : this.state.activeData === 2 ?
                                        profil360 : this.state.activeData === 3 ? JOB1 :
                                            this.state.activeData === 4 ? onlineinterview :
                                                this.state.activeData === 5 ? careerCoun : upskillinggs} className="width-100per-image" />
                                </div>
                            </div> */}
                        </div>

                            {/* added by shilpa */}
                            {/* <div className="row main-ind-compny-container">
                            <div className=" col-lg-8 ind-company-container pad-indicomp  newblock">
                                <div className="row txt-ind-company  mar-top">INDIVIDUAL</div>
                                <div className="row  class-image-div">
                                    
                                <div className="col-lg-4 class-image-div mag-lftimg ">
                                    <a href= {IndividualUrllogin +'/indi-login'} target='_blank'><img className="indimg img-hov padimg   " src={createyurprofile}/></a>
                                    <button onClick={this.creatbutton} className="getstart widcompanyimg  newbut-width">Create Profile</button>
                                    </div>

                                    <div className="col-lg-4 ">
                                       <a href={RoutingUrl+job_search}  ><img className="indimg padimg  img-hov " src={FindJob} /></a> 
                                       <button onClick={this.FindJob} className="widcompanyimg getstart  newbut-width">Find Job</button>
                                    </div>
                                    <div className="col-lg-4 class-image-div mag-lftimg ">
                                    <a href={motsSkillablers} target='_blank'><img className="indimg img-hov padimg   " src={UpgradeSkill}/></a>
                                    <button onClick={this.upgradeskill} className="getstart widcompanyimg  newbut-width">Upgrade Skills</button>
                                    </div>

                                   

                                </div>
                            </div>
                            <div className=" col-lg-3 ind-company-container mob-magin  newblock">
                                <div className="row txt-ind-company mar-top">COMPANY</div>
                                <div className="row ">
                                    <div className="col-lg-12 class-image-div  pad-com-zero ">
                                       <a href={RoutingUrl+Industry} > <img className="indimg img-hov padimgcompany  " src={LookTalent} /></a>
                                       <button onClick={this.companyindus} className="getstart widcompanyimg  newbut-width">Get Started</button> 
                                    </div>
                                    
                                </div>

                                 </div>
                            
                        </div> */}

                       
                         <div id="IConnect"></div>
                        <div className="" >
                        {/* <div class="events-headsss individualBlockIdent  sklb3icontext" >SKILLABLERS 3I CONNECT</div>  */}
                                
                                {/* <div className="row  skl3iwrapconte  mx-0">
                                <div className="col-lg-4  lineboxwrap3i">
                                    <div className="linboxwrap-abtus" >
                                        <div className={this.state.threeIconnectactive===0?"indi-box-abt indi-box-abtActive ":"indi-box-abt"} >
                                            <div className="indi-icon_andimag" onMouseEnter={()=> this.threeIconnectfunc(0)}>
                                                <div><img src={this.state.threeIconnectactive===0? indi_icon_dark :indi_iconlight} className="imgwidabt-in"/></div><div className="box-insi-texte">Individuals</div>
                                            </div>
                                        </div>
                                        <div className=""><hr className="hrline-abtindi lineclrindi"/></div>
                                        <div className=""><div class="vl-line-indiabt lineclrindibrder"></div></div>
                                    </div>

                                    <div className="linboxwrap-abtus" >
                                        <div className={this.state.threeIconnectactive===1?"insti-box-abt insti-box-abtActive ":"insti-box-abt"}>
                                            <div className="indi-icon_andimag"  onMouseEnter={()=> this.threeIconnectfunc(1)}>
                                                <div><img src={this.state.threeIconnectactive===1? inst_icon_dark :inst_iconlight} className="imgwidabt-in"/></div><div className="box-insi-texte">Institutions</div>
                                            </div>
                                            
                                        </div>
                                        <div className=""><hr className="hrline-abtindi lineclrinst"/></div>
                                        <div className=""><div class="vl-line-indiabt lineclrinstbrder"></div></div>
                                    </div>

                                    <div className="linboxwrap-abtus" >
                                        <div className={this.state.threeIconnectactive===2?"indus-box-abt indus-box-abtActive ":"indus-box-abt"} >
                                            <div className="indi-icon_andimag"  onMouseEnter={()=> this.threeIconnectfunc(2)}>
                                                <div><img src={this.state.threeIconnectactive===2? indu_icon_dark :indu_iconlight} className="imgwidabt-in"/></div><div className="box-insi-texte">Industries</div>
                                            </div>
                                        </div>
                                        <div className=""><hr className="hrline-abtindi lineclrindu"/></div>
                                        <div className=""><div class="vl-line-indiabt lineclrindubrder"></div></div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                      <div className="vp-backgrdwrap">
                                        {this.state.threeIconnectactive===0?
                                        <>
                                          <div className="abt-vp-textfntsz">Visual Profiling</div>
                                          <div className="vp-imag-creat-prof"><img src={Create_your_profile1_indi}/></div>
                                          <div className="Know_more-Vp"><button id="viewall-butn" className="knowmoreinsidrbox-bg" ><a  className="no-text-dec">KNOW MORE</a></button></div>
                                        </>
                                        :this.state.threeIconnectactive===1?
                                        <>
                                          <div className="abt-vp-textfntsz">Visual Profiling</div>
                                          <div className="vp-imag-creat-prof"><img src={Create_your_profile1_indi}/></div>
                                          <div className="Know_more-Vp"><button id="viewall-butn" className="Know_more-Vpins" ><a  className="no-text-dec">KNOW MORE</a></button></div>
                                        </> 
                                        :
                                        <>
                                          <div className="abt-vp-textfntsz">Visual Profiling</div>
                                          <div className="vp-imag-creat-prof"><img src={Create_your_profile1_indi}/></div>
                                          <div className="Know_more-Vp"><button id="viewall-butn" className="Know_more-Vpindu" ><a  className="no-text-dec">KNOW MORE</a></button></div>
                                        </>
                                        }
                                      </div>
                                </div>
                                {this.state.threeIconnectactive===0?
                                        <div className="col-lg-3 upgrdtabs  col-12">
                                        <div>
                                            <div className="flextstartbox-abtus i3fstbox">
                                                <div className="us-abt-endboxprocee">
                                                    <div>Upgrade Your Skill</div>
                                                </div>
                                            </div>
                                            <div  className="flextendbox-abtus i3sndbox">
                                                <div className="us-abt-endboxprocee">
                                                    <div>Become Industry ready</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div  className="flextstartbox-abtus i3thrdbox">
                                                <div className="us-abt-endboxprocee">
                                                    <div>Connect Industry</div>
                                                </div>
                                            </div>
                                            <div  className="flextendbox-abtus i3fourthbox"> 
                                                <div className="us-abt-endboxprocee">
                                                    <div>Jumpstart Your Career</div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    :this.state.threeIconnectactive===1?

                                         <div className="col-lg-3 upgrdtabs  col-12">
                                        <div>
                                            <div className="flextstartbox-abtus i3fstboxinst">
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_inst">
                                                    <div>Students</div>
                                                </div>
                                            </div>
                                            <div  className="flextendbox-abtus i3sndboxinst">
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_inst">
                                                    <div>Placement Link</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div  className="flextstartbox-abtus i3thrdboxinst">
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_inst">
                                                    <div>Campus Drive</div>
                                                </div>
                                            </div>
                                            <div  className="flextendbox-abtus i3fourthboxinst"> 
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_inst">
                                                    <div>Industrial Visit</div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    :

                                    <div className="col-lg-3 upgrdtabs  col-12  indudisblkmob">
                                        <div className="disfleabt-indus">
                                            <div className="flextstartbox-abtus i3fstboxindu">
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_indu">
                                                    <div>Publish JD</div>
                                                </div>
                                            </div>
                                            <div  className="flextendbox-abtus i3sndboxindu">
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_indu">
                                                    <div>Candidate Profiling</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="disfleabt-indus">
                                            <div  className="flextstartbox-abtus i3thrdboxindu">
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_indu">
                                                    <div>Screening</div>
                                                </div>
                                            </div>
                                            <div  className="flextendbox-abtus i3fourthboxindu"> 
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_indu">
                                                    <div>Shortlisted Candidates</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                            <div  className="flextstartbox-abtus i3fifthboxindu disnone-change">
                                                <div className="us-abt-endboxprocee lstcolumprocesstabs-bgrdclr_indu">
                                                    <div>Interview Process</div>
                                                </div>
                                            </div>
                                      
                                        </div>
                                    }
                                    
                                </div> */}
                        <div class="roadmapcls">
                        {window.matchMedia("(min-width: 1030px)").matches ?
                            <div className="roadmarel">
                            <div><img className="Roadmapeeimagwid Desktriangleimg" src={Roadmap3iconnect}/></div>
                            <div className="threeiconnectposition">
                                <div>SKILLABLERS</div> 
                                <div>3I CONNECT</div></div>
                             <div className="circleabsolutindi">
                                <div className="imagrelindi"><img src={Indiviroadcircle} className="shadowimgindi"/>
                                <div className="Arrowindi">
                                  {/* <div class="arrow-1" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}></div> */}
                                    <button id="" className="meet-our-team Roadmapknowmorebtn"  onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}>Know More</button>
                                  </div>
                                </div>
                             </div>
                             <div className="circleabsolutinst"><img src={Instiroadcircle}  className="shadowimginst"/>
                             <div className="Arrowindi">
                                <div><button id="" className="meet-our-team Roadmapknowmorebtn"  onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}>Know More</button></div>
                                </div>
                             </div>
                             <div className="circleabsolutindu"><img src={Induroadcircle} className="shadowimgindu"/>
                             <div className="Arrowindi">
                                    {/* <div class="arrow-1" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}></div> */}
                                    <div><button id="" className="meet-our-team Roadmapknowmorebtn"  onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>Know More</button></div>
                                </div>
                             </div>
                            </div>
                         :    
                            <div className="rodmapmobileview">
                                <div><img className="Roadmapeeimagwid " src={mobviewroadmapabt}/></div>
                                <div className="threeiconnectposition">
                                <div>SKILLABLERS</div> 
                                <div>3I CONNECT</div></div>
                                <div className="Mob_Updatecircleabsolutindi">
                                    <img src={Indiviroadcircle} className="shadowimgindi" onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}/>
                                    <div className="Arrowindi mob-indiarrow">
                                        <div className="arrow-arrow">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="Mob_Updatecircleabsolutinst">
                                    <img src={Instiroadcircle}  className="shadowimginst" onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}/>
                                    <div className="Arrowinst">
                                        <div className="arrow-arrow" onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}>
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="Mob_Updatecircleabsolutindu">
                                    <img src={Induroadcircle} className="shadowimgindu"  onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}/>
                                    <div className="Arrowindu">
                                        <div className="arrow-arrow"  onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}>
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="Mob_circleabsolutindi">
                                    <div className="imagrelindi"><img src={Indiviroadcircle} className="shadowimgindi"/>
                                    <div className="Arrowindi">
                                      <div class="arrow-1"onClick={()=>this.redirec(0,'#'+Routing.indihomelanding)}></div>
                                         
                                    </div>
                                    </div>
                                </div>
                                <div className="Mob_circleabsolutinst"><img src={Instiroadcircle}  className="shadowimginst"/>
                                    <div className="Arrowindi  indurrrarrow">
                                       <div class="arrow-1" onClick={()=>this.redirec(0,'#'+Routing.insthomelanding)}></div>
                                            
                                        </div>
                                </div>
                                <div className="Mob_circleabsolutindu"><img src={Induroadcircle} className="shadowimgindu"/>
                                    <div className="Arrowindi">
                                        <div class="arrow-1" onClick={()=>this.redirec(0,'#'+Routing.induhomelanding)}></div>
                                    </div>
                                </div> */}
                            </div>
                        }
                        </div>

                            
                        </div>

                        {/* ends here */}
                        
                    </div>

                   
                </div>
                <div class="about-us-who individualBlockIdent" id="about-us-who">
                        <div class="row mx-0 col-12 about-us-cent-ali  abtimgryttextpad">
                           
                            <div class="col-lg-6 whoimghover parallel" >
                                
                                {/* <img src={layer48copy4} class="img-width-100 " /> */}
                                <div id="container">
	<div id="panel">
		<div id="panel-container">
<img src={layer48copy4} class="img-width-100 " />
		</div>
	</div>
</div>
                        
                            </div>
                            <div class="col-lg-6 px-0">
                                <div class="who-we-are-head padding-btm-textcent whowetext ">
                                    <div class="who-we-text">
                                        <div>who</div>
                                        <div>we</div>
                                    </div>
                                    <div class="who-are-text">are</div>
                                </div>
                                <div class="who-desc-text padding-btm-textcent text-transform">
                                    <div> We enable <span className="ban1-textindi">Individuals</span> to discover their Passion, develop their Strengths and pursue a Career Journey that matches their abilities and aspirations by fostering a strong partnership with <span className="ban1-textindi">Institutions</span> & <span className="ban1-textindi">Industries</span>.  </div>
                                    <div class=" margin-bottom-0px pad-btm-who-we ">
                                        <HashRouter><Link to={Routing.OurTeam} onClick={()=>{ window.header.pageRender()}} >
                                            <button id="" className="meet-our-team">Meet our Team </button></Link></HashRouter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* //Theatre effect screen close 3i */}
                                {/* <div className="newdeg-in-st-du">
                                    <div className="row jsutfyyyy">
                                        <div className="col-lg-12">
                                            <div className="row jsutfyyyy">
                                            <div className="col-lg-3 indiclrsec">
                                            <article class="card">
                                                    <div class="temporary_text">
                                                        <img className="indimg img-hov padimgcompany  " src={indiimg_newlogo} />
                                                    </div>
                                                <div class="card_content">
                                                    <span class="card_title">Individual</span>
                                                    
                                                        <p class="card_description">Empowering individuals to succeed with clear career perspective by offering latest openings by industries</p>
                                                    <div className="Know_more-Vp"><button id="viewall-butn" className=" newupdatedbtnclr" ><a  className="no-text-dec  newupdatedbtnclrTEXT">KNOW MORE</a></button></div>
                                                </div>
                                                </article>
                                                </div>
                                            <div className="col-lg-3   instclrsec">
                                            <article class="card">
                                                    <div class="temporary_text">
                                                        <img className="indimg img-hov padimgcompany  " src={Instimg_group853} />
                                                    </div>
                                                <div class="card_content cardcon2">
                                                    <span class="card_title">Institution</span>
                                                        <p class="card_description">Enables institutions to be catalyst to individual success, acting as Placement partner with many institutions</p>
                                                    <div className="Know_more-Vp"><button id="viewall-butn" className="newupdatedbtnclr" ><a  className="no-text-dec newupdatedbtnclrTEXT">KNOW MORE</a></button></div>
                                                </div>
                                                </article>
                                                </div>
                                            <div className="col-lg-3  induclrsec">
                                            <article class="card">
                                                    <div class="temporary_text">
                                                        <img className="indimg img-hov padimgcompany  " src={Induimg_rec_newabt} />
                                                    </div>
                                                <div class="card_content cardcon3">
                                                    <span class="card_title">Industries</span>
                                                        <p class="card_description">Industries connect with us to create career opportunity for the youth as well as the experience professionals</p>
                                                        <div className="Know_more-Vp"><button id="viewall-butn" className="newupdatedbtnclr" ><a  className="no-text-dec newupdatedbtnclrTEXT">KNOW MORE</a></button></div>
                                                    
                                                </div>
                                                </article>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                    {/* //Theatre effect screen close 3i */}


                <div class="how-wedodata" id="ourpartner">
                    <div>
                        {/* <div class="who-we-are-head mob-howewedoit individualBlockIdent" id="howweDo">
                    <div class="who-we-text">
                        <div>how</div>
                        <div>we</div>
                    </div>
                    <div class="who-are-text">DO it</div>
                </div>
                <div class="what-we-do-txtx-main individualBlockIdent" id="howwedotabs">
                    <div><img src= {this.state.tab === 1?  MobileIndividual :this.state.tab === 3? MobileIndustry: this.state.tab === 2?MobileInstitute: MobileIndividual}  id="TabImagess" />
                    <HashRouter>
                        <Link to={this.state.tab === 2?Routing.Institute : this.state.tab === 3 ? Routing.Industry : Routing.Individual}>
                        <button class="lapview-btn custombutton" id="show-mob">VIEW MORE</button></Link></HashRouter>
                    </div>
                    <div class="how-we-do-tabsectionns">
                        <div class="tabssss">
                            <div onClick={()=>this.tootalchange(1)}
                             >Individuals</div>
                            <div onClick={()=>this.tootalchange(2)}>Institutions</div>
                            <div onClick={()=>this.tootalchange(3)}> Industries</div>
                        </div>
                        <ul class="UlTabdata">
                            <li>INCREASED AWARENESS through Professional Career Counselling and Psycho-Metric Evaluation

                            </li>
                            <li>CAREER OPPORTUNITIES directly mapped to Skills, Aptitude and Aspirations

                            </li>
                            <li> ENHANCED EMPLOYMENT PROSPECTS through a curated Industry Readiness Programs
</li>
                            <li> GET NOTICED with a comprehensive Profile, that provides both visibility and transparency of individual’s skills and accomplishments
</li>

                        </ul>
                        <HashRouter>
                        <Link to={this.state.tab === 2?Routing.Institute : this.state.tab === 3 ? Routing.Industry : Routing.Individual}>
                        <button class="lapview-btn custombutton" id="show-mob-1">VIEW MORE</button></Link></HashRouter>
                    </div>
                   
                </div> */}
                        <div class="individualBlockIdent" id="ecosystem">
                            {/* <div class="head">ECOSYSTEM </div>
                    <div class="subhead">Our Platform Is Focussed Around YOUth</div>
                    <div class="pos-relative">
                        <div class="col-lg" id="wheel-container">
                            <div class="wheel" data-state="1">
                                <ul>
                                    <li>
                                        <div><a class="btn active" data-icon="1">
                                                <div></div>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div><a class="btn" data-icon="2">
                                                <div></div>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div><a class="btn" data-icon="3">
                                                <div></div>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div><a class="btn" data-icon="4">
                                                <div></div>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div><a class="btn" data-icon="5">
                                                <div></div>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div><a class="btn" data-icon="6">
                                                <div></div>
                                            </a></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="Hightlighter"></div>
                        <div class="indivo-spinner">
                            <div class="font-you">YOU</div>
                            <div class="font-text-daat">Comprehensive visual profiling  through single view profile

                            </div>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </div>
                <div >
                <div className="Partner_Parent" id="#ourpart2">
                    <div class="events-headsss individualBlockIdent" >OUR PARTNERS</div> 
                    <div className="row partcentnew">

                    <div className="col-lg-4 marspac-partner marginbotmforpartnerlist ">
                        <div class="card_partner">
                        <div className="parner-logoparent iplanertpartner">
                        <img src={Iplanetlogo} alt="logo"  className="ourpartnerlogos-Abt-home ipimg"/>
                        </div>
                        <div className="Partner-content-">
                            <span>The program`s goal is to offer educators and students the highest-quality training experience.


                    </span> 
                        </div>
                        <div className="Know_morebut-ourpartner">
                            <HashRouter><Link to={Routing.aatce}>
                                        <button id="" className="meet-our-team">Know More </button></Link></HashRouter>
                            </div>   
                        </div>  
                        </div>

                        <div className="col-lg-4 marginbotmforpartnerlist">
                        <div class="card_partner">
                        <div className="parner-logoparent">
                        <img src={Talenttosucess} alt="logo"  className="talentimgwidth"/>
                        </div>
                        <div class="Partner-content-">
                            <span>Shaping Tomorrow, Today!</span> 
                        </div>
                        <div className="Know_morebut-ourpartner">
                               <button id="" className="meet-our-team" onClick={this.redirectTelenttosucess}>Know More </button>
                            </div>     
                        </div>  
                        </div>

                        <div className="col-lg-4 marginbotmforpartnerlist">
                        <div class="card_partner">
                        <div className="parner-logoparent">
                        <img src={Chaloexamlogo1} alt="logo"  className="ourpartnerlogos-Abt-home"/>
                        </div>
                        <div class="Partner-content-">
                            <span>To be the One Point for Structured E- Learning and E- Reference study materials (for UG & beyond)</span> 
                        </div>
                        <div className="Know_morebut-ourpartner">
                                               <HashRouter><Link to={Routing.chaloexamhome2}>
                                        <button id="" className="meet-our-team">Know More </button></Link></HashRouter>
                            </div>     
                        </div>  
                        </div>
                        
                      
                    </div>
                </div>
                </div>
                <div class="events-sectionsss eve-secnewbghome">
                    <div class="events-headsss individualBlockIdent" id="eventhead">EVENTS</div>
                    <div class="row mx-0">
                        <div class="col-lg-7 px-0 youth-data-sectionnss individualBlockIdent" id="bigevent">
                            <HashRouter><Link to={Routing.ycc} >
                                <img src={youthconnect} class="EventsssImga" /></Link></HashRouter>
                            <div class="largest-data-section"></div>
                            {window.matchMedia("(min-width: 568px)").matches ?
                            <>
                            <div class="youth-blockss-sectionss-dataa">
                            <div>
                            <div className="numbertextt-data clr-1">{1 === 1 ? <><CountUp start={0} end={5} duration={2} />K+</> :
                                '0k+'}</div>
                            <div>Individual Campus
</div>
                            <div>Audience</div>
                        </div>
                        <div>
                            <div className="numbertextt-data clr-2">{1 === 1 ? <><CountUp start={0} end={100} duration={2} />K+</> :
                                '0+'}</div>
                            <div>In-Campus

                            </div>
                            <div>Registrations</div>
                        </div>
                        <div>
                            <div className="numbertextt-data clr-3">{1 === 1 ? <><CountUp start={0} end={100} duration={2} />K+</> :
                                '0+'}</div>
                            <div>Online</div><div>
Registrations</div>
                        </div>
                        <div>
                            <div className="numbertextt-data clr-4">{1 === 1 ? <><CountUp start={0} end={40} duration={2} />+</> :
                                '0+'}</div>
                            <div>Campus

                            </div>
                            <div>Venues</div>
                        </div>
                        <div>
                            <div className="numbertextt-data clr-5">{1 === 1 ? <><CountUp start={0} end={250} duration={2} />+</> :
                                '0+'}</div>
                            <div>Institution


                            </div>
                            <div>Partners</div>
                        </div>
                        <div>
                            <div className="numbertextt-data clr-4">{1 === 1 ? <><CountUp start={0} end={4} duration={2} />-
                            <CountUp start={0} end={6} duration={2} /></> :
                                '0+'}</div>
                            <div>Months Active


                            </div>
                            <div>Engagement</div>
                        </div>
                                {/* <div>
                                    <div class="numbertextt-data clr-1">50K+</div>
                                    <div>Youth</div>
                                    <div>Participants</div>
                                </div>
                                <div>
                                    <div class="numbertextt-data clr-2">200+</div>
                                    <div>Institution
                                    </div>
                                    <div>Partners</div>
                                </div>
                                <div>
                                    <div class="numbertextt-data clr-3">225+</div>
                                    <div>Partners</div>
                                </div>
                                <div>
                                    <div class="numbertextt-data clr-4">40+</div>
                                    <div>Industry Verticals
                                    </div>
                                    <div>Covered</div>
                                </div> */}
                            </div>
                            </>:
                            <div className="youth-blockss-sectionss-dataa-1 datatsect-122 daat-sect-222">
                            <div className='contenet'>
                                <div className="numbertextt-data clr-1">{1 === 1 ? <div><CountUp start={0} end={5} duration={2} />K+</div> :
                                    '0k+'}</div>
                                <div>Individual Campus
                                </div>
                                <div>Audience</div>
                            </div>
                            <div className='contenet'>
                                <div className="numbertextt-data clr-2">{1 === 1 ? <div><CountUp start={0} end={100} duration={2} />K+</div> :
                                    '0+'}</div>
                                <div>In-Campus
    
                                </div>
                                <div>Registrations</div>
                            </div>
                            <div className='contenet'>
                                <div className="numbertextt-data clr-3">{1 === 1 ? <div><CountUp start={0} end={100} duration={2} />K+</div> :
                                    '0+'}</div>
                                <div>Online</div><div>
                                    Registrations</div>
                            </div>
                            <div className='contenet'>
                                <div className="numbertextt-data clr-4">{1 === 1 ? <div><CountUp start={0} end={40} duration={2} />+</div> :
                                    '0+'}</div>
                                <div>Campus
    
                                </div>
                                <div>Venues</div>
                            </div>
                            <div className='contenet'>
                                <div className="numbertextt-data clr-5">{1 === 1 ? <div><CountUp start={0} end={250} duration={2} />+</div> :
                                    '0+'}</div>
                                <div>Institution
    
    
                                </div>
                                <div>Partners</div>
                            </div>
                            <div className='contenet'>
                                <div className="numbertextt-data clr-4">{1 === 1 ? <div><CountUp start={0} end={4} duration={2} />-
                                    <CountUp start={0} end={6} duration={2} /></div> :
                                    '0+'}</div>
                                <div>Months Active
    
    
                                </div>
                                <div>Engagement</div>
                            </div>
                        </div>
                            }
                        </div>
                        <div class="col-lg-5 px-0 individualBlockIdent" id="other">
                            <div class="others-events-data">Other Events</div>
                            <div class="other-evet-conatiner">
                                <div class="row mx-0" id="r6">
                                    <div class=" col-6 px-2 py-2"><img src={bengaluru} alt="" id="image1" onClick={this.OpenbengaluruQuiz} />
                                    </div>
                                    <div class=" col-6 px-2 py-2"><img src={bioquiz_version_2} alt="" id="image2" onClick={this.bioquizversion2} /></div>

                                    <div class=" col-6 px-2 py-2"> <img src={UTIndiaNextStimulus} alt=""
                                        id="image3" onClick={this.UltraTech} /></div>
                                    <div class="col-6 px-2 py-2"> <img src={sight} alt="" id="image4" onClick={this.openIndiasight} /></div>
                                    <div class="row" id="r7">
                                        <button id="viewall-butn" className="meet-our-team"><a href= {viewalleventurl} target="_blank" className="no-text-dec">View All</a></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>


     {/* //man walking  */}    
        {/* <div class="container22">
                <div class="infinite1">
                <div>
                <div className="text-inst-img">Institution</div>
                <div className="text-indu-img">Industries</div>
                </div>
                    <div class="shadow">
                    </div>
                </div>
                <div id={this.state.walkactiveid==0?"walk-container":this.state.walkactiveid==1?"walk-container2":"walk-container3"}>
                    <div className="walkingimg" id={this.state.walkactiveid==0?"walk":this.state.walkactiveid==1?"walk2":"walk3"}></div>
                </div>
        </div> */}
   {/* //man walking  */}
      
                <div className="RECOGNITIONSparent">
                <div className="awarcolor">
                    <div class="individualBlockIdent " id="awards">
                        <div class="events-headsss padding-top-6vh ">AWARDS & RECOGNITIONS</div>
                        <div class="awards-sectionss">
                            <div class="awards-image-conatiners mgbot-homeaeward">

                                <img src={startupindia} class="awrs-sec-img  " />

                                <img src={awards} class="awardss-platfprm" />
                            </div>
                            <div class="awards-image-conatiners mgbot-homeaeward">

                                <img src={msme} class="awrs-sec-img" />

                                <img src={awards} class="awardss-platfprm" />
                            </div>
                            <div class="awards-image-conatiners ">

                                <img src={neatlogotop} class="awrs-sec-img cur-pointer" />

                                <img src={awards} class="awardss-platfprm cursor-pointer" onClick={this.OpenAicte} />
                            </div>

                        </div>
                    </div>
                    </div>
                    {/* <Process/> */}
                    </div>


                  
              {
                this.state.ShowPopup &&
                <CareerModal 
                close = {this.closeModal}
                />
              }
              {
                this.state.patentpopup &&
                <>
                <div className='modal-overlay PatentoverlayPopup'></div>
                <div className=' box-bg active  box-bg width-patentpopup'>
                <div className='modal-close z-index-patentpopupclose' 
                onClick={this.closPopuppatent}
                ><FontAwesomeIcon icon={faTimes} /> </div>
                 <div className="row Patentpopupparent">
                    <div className="col-lg-6 col-12 padd-top-patenmobissue">
                       <div>
                        <img class="POPupImgpate" src={Patentimgpopup}/>
                       </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="patentpopupHead"><h2>THE <span className="patentskltext">SKILLABLERS PLATFORM</span> IS NOW <span className="patentskltext">PATENTED!</span></h2></div>
                        <div className="patentpopupCont">We are thrilled to announce that the <span className="Patentskltext">SKILLABLERS PLATFORM</span> has been granted the patent, recognizing our commitment to innovation and excellence in connecting the <span className="PatentskltextI">3-I's (Individuals, Institutions & Industries) </span> and enabling youth - like never before - to be employable and to choose their relevant career paths seamlessly.</div>
                        <div className="patentpopupCont"> Our dedicated teams continue to work tirelessly to bring this groundbreaking invention to life, and we couldn't be prouder of each and everyone's hard work and dedication.</div>
                        <div className="patentpopupCont">This patent represents a significant milestone for us, as it validates our vision, and affirms our position as a pioneer in realigning aspirations of youth for measurable social impact.</div>
                        <div className="patentpopupCont"> We will continue to push the boundaries of innovation and drive positive change with youth. </div>
                        <div className="patentpopupCont">Explore more on how this patent will shape the future of youth employment and entrepreneurship!</div>
                    </div>
                </div> 
                </div>
                </>
              }
            </div>}
            </Context.Consumer>



        )
    }
}
export default Aboutus