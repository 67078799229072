import React, { useEffect, forwardRef, useState } from "react";
import { Routing } from "../../Assests/js/routing";
import { HashRouter, Link } from "react-router-dom"
import YCCLogo from '../../Assests/Images/YccLogo.png';
import { ValidateRequired, ValidateRequiredradio, ValidateDDLRequired, validateEmail, OnlyNumbers , OnlyAlphaNumeric , OnlyAlphabets} from '../General/validation';
import Select, { components } from 'react-select';
import customStyles,{customStylecollege} from './CustomStyle'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AutoGenerateCode, ToSendOTP, ToVerifyOTP} from '../General/general-methods';
import {CheckUserMob, CheckUsermail, GetAllCountryCodeList, SaveYCC2022Reg, GetAllInstitutionCategoryByEvent} from './reg-methods';
import Proc  from '../../Assests/Images/Pro.png';
import mail  from '../../Assests/Images/mail.png';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Congrates from "./reg-congra";
import { Event } from '../../Assests/js/config';

const Input = (props) => <components.Input {...props} isHidden={false} />;
const Registeration = forwardRef((props, ref) => {
    const [CountryCodeList, setCountryCodeList] = useState();
    const EventId = Event;
    const [password, setPassword] = useState("");
    const [selectedusertype , setselectedusertype] = useState("");
    const [Disable, setDisable] = useState(false);
    const [LoadCongrates , setLoadCongrates] = useState(false)
    const [QuizDate, setQuizDate] = useState("");
    const [errInst , seterrINst] = useState('')
    const [errCrse , seterrCrse] = useState('')
    const [errSpec , seterrSpec] = useState('')
    const [manage, setManage] = useState({
        FirstName: '',
        LastName: '',
        CountryCode: '',
        MobNumber: '',
        OTP: '',
        Email: '',
        errors: {},
        IsMobileVerified: false,
        isMobileNumberExists: 0,
        isEmailIdExists: 0,
        MobileReferenceCode: '',
        IsTermsChecked: false,
        IsTermsLoaded: false,
        showOtp : false,
        Isverifyclicked: false,
        Course:'',
        CourseSpecialization:'',
        Institution: '',
        JobSpecialization: '',
        Company:'',
        CompanySpecialization:'',
        SpecifyDetails:'',
        College : [],
        SelCourse : [],
        SelSpecialization : [],
        CollegeInput : ''
    });

    useEffect(() => {
        eventDetails()    
    },[])
    
    const eventDetails = () => {
        GetAllCountryCodeList().then(result => {
            let List = result.recordsets[0];
            setCountryCode(List)
            generatePassword()
        })

        //GetEventDetails().then(result => {
        //    // let List = result.data.recordsets[6];
        //    // setCountryCode(List)
        //    setQuizDate(result.data.recordsets[0][0].EventDateString)
        //    //generatePassword()
        //});
    }

    const generatePassword = () => {
        const randomPassword = Math.random().toString(36).slice(2);
        setPassword(randomPassword);
    }

    const ShowUserTypeContents=(selectedcontent)=>{
        setselectedusertype(selectedcontent);
        let error = manage.errors
        error["UserType"] = ''
        setManage({
            ...manage,
            errors:error
        })
    }

    const handleChange = (name) => event => {
        if(name === 'MobNumber'){
            let error = manage.errors
            error["MobileNumber"] = ''
            setManage({
                ...manage,
                [name]: event.target.value,
                errors:error
            })
        }
        setManage({
            ...manage,
            [name]: event.target.value
        })
    }

    const handleSelection = (selection) => {
        setManage({
            ...manage,
            CountryCode:(window.matchMedia("(max-width: 568px)").matches) ?{value : selection.value , 
                label :selection.value }   : selection,
            MobNumber: '',
            IsMobileVerified: false
        })
    }
    
    const setCountryCode = (List) =>{
        setCountryCodeList(List)
    }

    const SendOTP = () => {
        if (manage.isMobileNumberExists === 0) {
            let MobileReferenceCode = AutoGenerateCode(manage.MobileReferenceCode);
            if (MobileReferenceCode !== "") {
                let MobNo = manage.MobNumber;
                ToSendOTP(MobNo, MobileReferenceCode)
                .then(result => {
                    toast.dismiss();
                    if (result.Status === "Success") {
                        toast.success("Sent OTP Successfully.", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setManage({
                            ...manage,
                            MobileReferenceCode: MobileReferenceCode
                        })
                    } else {
                        toast.error("Sending OTP Failed.", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
            }
        }
    }

    const Verify = () => {
        let flag = true;
        let IsMobileVerified = false;
        let Isverifyclicked = true;
        ToVerifyOTP(manage.OTP).then(result => {
            toast.dismiss();
            if(result.Status !== undefined && result.Status === "Success") {
                toast.success("Mobile number verification completed successfully.", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                IsMobileVerified = true
                setManage({
                    ...manage,
                    IsMobileVerified: IsMobileVerified,
                    Isverifyclicked: Isverifyclicked
                });
            } 
            else {
                toast.error("Incorrect OTP.", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                IsMobileVerified = false
                setManage({
                    ...manage,
                    IsMobileVerified: IsMobileVerified,
                    Isverifyclicked: Isverifyclicked
                });
            }
        })
        return flag;
    }

    const  Save = async () => {
        let val = await ValidateAll();
        if (val===true) {
            let FName = manage.FirstName
            let LName = manage.LastName
            let EmailId = manage.Email
            let Password = password
            let MobileNo = manage.MobNumber
            let Eventid = EventId
            let CatogoryId = 0
            let CountryCode = manage.CountryCode.value
            let CategoryIds = []
            let CategoryValue = []
            let RegCode = 'ycc2022'
            if (selectedusertype === 'stud') {
                CatogoryId = 1
                CategoryIds = [5,6,13]
                CategoryValue = [manage.Course, manage.CourseSpecialization, manage.Institution]
            } else if (selectedusertype === 'work') {
                CatogoryId = 4
                CategoryIds = [11,12]
                CategoryValue = [manage.Company, manage.CompanySpecialization]
            } else if (selectedusertype === 'job') {
                CatogoryId = 2
                CategoryIds = [8]
                CategoryValue = [manage.JobSpecialization]
            } else if (selectedusertype === 'oth') {
                CatogoryId = 14
                CategoryIds = [15]
                CategoryValue = [manage.SpecifyDetails]
            }
            await SaveYCC2022Reg(FName, LName, EmailId, Password, MobileNo, Eventid, CountryCode, CatogoryId, CategoryIds.join(','), 
            CategoryValue.join(','),  RegCode).then(result => {
                if (result.data.recordsets[1][0].MSG === 'SUCCESS') {
                    setLoadCongrates(true)
                }else{
                    toast.error("Registraion Failed, Please try Again Later.", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })  
            setDisable(true);
        }
    }

    const VerifiedOtp = async()=>{
        let errors = manage.errors;
        let flag = false;
        if(await validateOtp()){
            if(!manage.Isverifyclicked){
                errors["OTP"] = "*Please Verify OTP";
                flag = false;
            }else{
                errors["OTP"] = "";
                flag = true;
            }
        }else{
            flag = false;
        }
        return flag
    }

    const validatestud = async()=>{
        let Cours =  await Course()
        let CourseSpecialization =  await Specialization()
        let Institution =  await Institute()
        if(Cours && CourseSpecialization && Institution){
            return true
        }else{
            return false
        }
    }

    const validatejob = async()=>{
        let JobSpecialization = await validatelenandchar("JobSpecialization","Specialization")
        if(JobSpecialization){
            return true
        }else{
            return false
        }
    }

    const validatework = async()=>{
        let Company =  await validatelenandchar("Company","Company")
        let CompanySpecialization =  await validatelenandchar("CompanySpecialization","Specialization")
        if(Company && CompanySpecialization){
            return true
        }else{
            return false
        }
    }

    const validateoth = async()=>{
        let SpecifyDetails =  await validatelenandchar("SpecifyDetails","Detail")
        if(SpecifyDetails){
            return true
        }else{
            return false
        }
    }

    const  ValidateAll = async () => {
        let FirstName =  await validatelenandchar("FirstName","First Name")
        let LastName =  await validatelenandchar("LastName","Last Name")
        let MobileNumber = await ValMobileNoIsExist()
        let email = await ValEmailIsExist()
        let usertypecontent = await validateUTcontent()
        let termschecked = validateTermsChecked()
        let OtPverified = await VerifiedOtp()
        let STDCode =  ValidateCountryCode()
        let usertype = false
        if(selectedusertype ==='stud'){
            usertype =  await validatestud()
        }else if(selectedusertype ==='job'){
            usertype = await validatejob()
        }else if(selectedusertype ==='work'){
            usertype = await validatework()
        }else if(selectedusertype ==='oth'){
            usertype = await validateoth()
        }
        let ccode = manage.CountryCode["value"] === '91' ? manage.IsMobileVerified:true
        if(FirstName && LastName && MobileNumber && email  && usertypecontent && manage.isEmailIdExists === 0 
            && manage.isMobileNumberExists === 0 && termschecked && ccode && usertype && STDCode){
            return true
        }else{
            return false
        }
    }

    // const validateFname = () => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["FirstName"], 'First Name', function (err) {
    //         errors["FirstName"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    // const validateLname = () => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["LastName"], 'Last Name', function (err) {
    //         errors["LastName"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    const ValidateCountryCode = () => {
        let errors = manage.errors;
        let flag = true;
        let CountryCodeSelected = manage.CountryCode.label === undefined ? '' : manage.CountryCode.label;
        ValidateDDLRequired(CountryCodeSelected, 'Country Code', function (err) {
            errors["MobileNumber"] = err.DDLRequiredField;
            flag = err.formIsValid;
        });
        setManage({
            ...manage,
            errors: errors
        });
        return flag;
    }

    const validate_MobileNumber = async() => {
        let errors = manage.errors;
        let flag = true;
        let req = manage.MobNumber;
        let IsMobileNumberVerified = manage.IsMobileNumberVerified;
        let OTP = manage.OTP;
        if (ValidateCountryCode()) {
            if (!req) {
                flag = false;
                errors["MobileNumber"] = "*Please Enter Your Mobile Number.";
                IsMobileNumberVerified = true
            }else if (typeof req !== "undefined") {
                flag = true;
                errors["MobileNumber"] = "";
                if (manage.VerifiedMobileNumber !== (req)) {
                    IsMobileNumberVerified = false;
                    OTP = '';
                }
            }else if (typeof req == "undefined") {
                flag = false;
            }
        }else{
            flag = false;
        }
        
        setManage({
            ...manage,
            errors: errors,
            IsMobileNumberVerified:IsMobileNumberVerified,
            OTP:OTP
        });
        return flag;
    }

    const ValMobileNoIsExist = async() => {
        let errors = manage.errors;
        let IsMobileNumberVerified = manage.IsMobileNumberVerified;
        let isMobileNumberExists = 0
        let flag = false;
        let showOtp = false;
        let req = manage.MobNumber;
        let STDCode = manage.CountryCode["value"] === undefined ? 0 : manage.CountryCode["value"];
        var res = await validate_MobileNumber();
        if (res === true) {
            await CheckUserMob(STDCode,EventId,manage.MobNumber).then(result => {
                let Isexists = result.recordsets[0][0].Status === "Failed" ? 0 : 1;
                if (Isexists === 1) {
                    flag = false;
                    errors["MobileNumber"] = 'Hi, Good to See You Again! It seems you are Already registered For Event.';
                    IsMobileNumberVerified = true;
                    isMobileNumberExists = 1;
                    showOtp = false
                }else{
                    if (STDCode === "91") {
                        if (!req.match(/^([6789]{1})([0-9]{9})$/)) {
                            flag = false;
                            errors["MobileNumber"] = "*Please Enter Valid Mobile Number.";
                            errors["formIsValid"] = true;
                            showOtp = false
                        }else{
                            flag = true;
                            errors["MobileNumber"] = '';
                            IsMobileNumberVerified = manage.onceOtpVerfied === manage.MobileNumber ? true :
                                manage.Ifmobilvalidation === true ? false : true;
                            showOtp = true
                        }
                    }else{
                        flag = true;
                    }
                }
                setManage({
                    ...manage,
                    errors: errors,
                    isMobileNumberExists: isMobileNumberExists,
                    IsMobileNumberVerified:IsMobileNumberVerified,
                    showOtp:showOtp
                })
            })
        }else {
            flag = false;
            setManage({
                ...manage,
                isMobileNumberExists: isMobileNumberExists,
                IsMobileNumberVerified: true,
                showOtp: showOtp
            });
        }
        return flag
    }

    const validateOtp = async()=>{
        let errors = manage.errors;
        let flag = true;
        ValidateRequired(manage["OTP"], 'OTP', function (err) {
            errors["OTP"] = err.RequiredField;
            flag = err.formIsValid;
        });
        setManage({
            ...manage,
            errors: errors
        });
        return flag
    }

    const validatEmailID = async() => {
        let errors = manage.errors;
        let flag = true;
        validateEmail(manage["Email"], 'Email Id', function (err) {
            errors["EmailId"] = err.EmailId;
            flag = err.formIsValid;
        });
        setManage({
            ...manage,
            errors: errors
        });
        return flag
    }

    const ValEmailIsExist = async () => {
        let errors = manage.errors;
        let flag = false;
        let res = await validatEmailID();
        let Isexists = 0; 
        if (res === true) {
            await CheckUsermail(EventId,manage.Email).then(result => {
                Isexists = result.recordsets[0][0].Status === "Failed" ? 0 : 1;
                if (Isexists === 1) {
                    flag = false;
                    errors["EmailId"] = 'Hi, Good to See You Again! It seems you are Already registered For Event.';
                    setManage({
                        ...manage,
                        isEmailIdExists:Isexists,
                        errors: errors
                    });
                } else {
                    flag = true;
                    errors["EmailId"] = ''
                    setManage({
                        ...manage,
                        isEmailIdExists:Isexists,
                        errors: errors
                    });
                    }     
            })
        }else{
            flag = false;
        }
        return flag
    }
    
    const validateUTcontent = async() => {
        let errors = manage.errors;
        let flag = true;
        ValidateRequiredradio(selectedusertype, 'User Type', function (err) {
            errors["UserType"] = err.RequiredField;
            flag = err.formIsValid;
        });
        setManage({
            ...manage,
            errors: errors
        });
        return flag
    }

    const validatelenandchar = (value, label)=>{
        let errors = manage.errors;
        let flag = true;
        if(manage[value]!==""){
            if(manage[value].length>1 || value==="LastName"){
                var req = manage[value]
                var regex = /[A-Z a-z 0-9]/g;
                var found = req.match(regex);
                if (found) {
                    errors[value] = "";
                    flag = true;
                }else{
                    errors[value] = "*Please Enter Valid "+label;
                    flag = false;
                }
            }else{
                errors[value] = "*Please Enter Valid "+label;
                flag = false;
            }
            setManage({
                ...manage,
                errors: errors
            });
        }else{
            errors[value] = "*Please Enter "+label;
            flag = false;
            setManage({
                ...manage,
                errors: errors
            });
        }
       
        return flag
    }

    // const validateCourse = async() => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["Course"], 'Course', function (err) {
    //         errors["Course"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    // const validateCourseSpecialization = async() => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["CourseSpecialization"], 'Spcialization', function (err) {
    //         errors["CourseSpecialization"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    // const validateInstitution = async() => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["Institution"], 'Institution', function (err) {
    //         errors["Institution"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    // const validateJobSpecialization = async() => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["JobSpecialization"], 'Specialization', function (err) {
    //         errors["JobSpecialization"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    // const validateCompany = async() => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["Company"], 'Company', function (err) {
    //         errors["Company"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    // const validateCompanySpecialization = async() => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["CompanySpecialization"], 'Specialization', function (err) {
    //         errors["CompanySpecialization"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    // const validateSpecifyDetails = async() => {
    //     let errors = manage.errors;
    //     let flag = true;
    //     ValidateRequired(manage["SpecifyDetails"], 'Specify Details', function (err) {
    //         errors["SpecifyDetails"] = err.RequiredField;
    //         flag = err.formIsValid;
    //     });
    //     setManage({
    //         ...manage,
    //         errors: errors
    //     });
    //     return flag
    // }

    const validateTermsChecked = () =>{
        let errors = manage.errors;
        let flag = true;
        if(manage.IsTermsChecked === false){
            errors["Terms"] = "*Please Accept Terms and Conditions";
            flag = false;
        }else{
            errors["Terms"] = "";
            flag = true;
        }
        setManage({
            ...manage,
            errors: errors
        });
        return flag
    }

    const TermsCheck = () =>{
        let errors = manage.errors;
        if(manage.IsTermsChecked === true){
            errors["Terms"] = "*Please Accept Terms and Conditions";
            setManage({
                ...manage,
                IsTermsChecked:false,
                errors: errors
            });
        }else{
            errors["Terms"] = "";
            setManage({
                ...manage,
                IsTermsChecked:true,
                errors: errors
            });
        }
    }
    
    const LoadTerms = () => {
        setManage({
            ...manage,
            IsTermsLoaded:true
        })
    }

    const AcceptTerms = () =>{
        let errors = manage.errors;
        if(manage.IsTermsChecked === true){
            errors["Terms"] = "*Please Accept Terms and Conditions";
            setManage({
                ...manage,
                IsTermsChecked:false,
                IsTermsLoaded:false,
                errors: errors
            })
        }else{
            errors["Terms"] = "";
            setManage({
                ...manage,
                IsTermsChecked:true,
                IsTermsLoaded:false,
                errors: errors
            }) 
        }
    }

    const GoBack = () =>{
        setManage({
            ...manage,
            IsTermsLoaded : false
        })
    }

    const getCollegeOptions = (input) => {
        if (input.length > 1) {
            return GetAllInstitutionCategoryByEvent(EventId, input,'College')                
            .then((res) => {                   
                let result = res;                
                return result;                
            })                
            .catch(function (error) {});    
        }     
    }

    const getCourseOptions = (input) => {
        if (input.length > 1) {
            return GetAllInstitutionCategoryByEvent(EventId, input, 'Course')                
            .then((res) => {                   
                let result = res;                
                return result;                
            })                
            .catch(function (error) {});    
        }     
    }

    const getSpecializationOptions = (input) => {
        if (input.length > 1) {
            return GetAllInstitutionCategoryByEvent(EventId, input, 'Specialization')                
            .then((res) => {                   
                let result = res;                
                return result;                
            })                
            .catch(function (error) {});    
        }     
    }

    const OnChangeSelect = async(selectedOptions) =>{
        if(selectedOptions !== null){
            let err = manage.errors
            err['Institution'] = ''
            await setManage({
                ...manage,
                College : selectedOptions,
                Institution:selectedOptions.label,
                errors: err
            })
            await seterrINst('')
        }
    }

    const OnChangeSelectCourse = async(selectedOptions) =>{
        if(selectedOptions !== null){
            let err = manage.errors
            err['Course'] = ''
            await setManage({
                ...manage,
                SelCourse : selectedOptions,
                Course:selectedOptions.label,
                errors: err
            })
            await seterrCrse('')
        }
    }

    const OnChangeSelectSpecialization = async(selectedOptions) =>{
        if(selectedOptions !== null){
            let err = manage.errors
            err['Specialization'] = ''
            await setManage({
                ...manage,
                SelSpecialization : selectedOptions,
                CourseSpecialization:selectedOptions.label,
                errors: err
            })
            await seterrSpec('')
        }
    }

    const validateInstitute=()=>{
        let err = manage.errors
        err['Institution'] = ''
        setManage({
            ...manage,
            errors: err
        })
    }  

    const CollegeInputChange = (inputValue, { action }) => {
        if (action === "input-change") {
            if (inputValue.trim() !== "") {
                setManage({
                    ...manage,
                    College :  { value: 0, label: inputValue },
                    Institution: inputValue 
                })
                
                return inputValue
            }
            else {
                setManage({
                    ...manage,
                    College :  [],
                    Institution: ''
                })
              
                return ''
            }
        }
    }

    const CourseInputChange = (inputValue, { action }) => {
        if (action === "input-change") {
            if (inputValue.trim() !== "") {
                setManage({
                    ...manage,
                    SelCourse :  { value: 0, label: inputValue },
                    Course: inputValue 
                })
                
                return inputValue
            }
            else {
                setManage({
                    ...manage,
                    SelCourse :  [],
                    Course: ''
                })
              
                return ''
            }
        }
    }

    const SpecializationInputChange = (inputValue, { action }) => {
        if (action === "input-change") {
            if (inputValue.trim() !== "") {
                setManage({
                    ...manage,
                    SelSpecialization :  { value: 0, label: inputValue },
                    CourseSpecialization: inputValue 
                })
                
                return inputValue
            }
            else {
                setManage({
                    ...manage,
                    SelSpecialization :  [],
                    CourseSpecialization: ''
                })
              
                return ''
            }
        }
    }

    const SetCollege = (e) =>{
        Institute()
    }

    const SetCourse = (e) =>{
        Course()
    }

    const SetSpecialization = (e) =>{
        Specialization()
    }

    const Institute =()=>{
        let errors = manage.errors;
        let flag = true;
        if(manage['College'].label !== undefined && manage['College'].label!==""){
            if(manage['College'].label.length>1 ){
                var req = manage['College'].label
                var regex = /[A-Z a-z 0-9]/g;
                var found = req.match(regex);
                if (found) {
                    errors= "";
                    flag = true;
                }else{
                    errors= "*Please Enter Valid Institution";
                    flag = false;
                }
            }else{
                errors = "*Please Enter Valid Institution";
                flag = false;
            }
            seterrINst(errors)
        }else{
            errors = "*Please Enter Valid Institution";
            flag = false;
            seterrINst(errors)
        }
       
        return flag
    }

    const Course =()=>{
        let errors = manage.errors;
        let flag = true;
        if(manage['SelCourse'].label !== undefined && manage['SelCourse'].label!==""){
            if(manage['SelCourse'].label.length>1 ){
                var req = manage['SelCourse'].label
                var regex = /[A-Z a-z 0-9]/g;
                var found = req.match(regex);
                if (found) {
                    errors= "";
                    flag = true;
                }else{
                    errors= "*Please Enter Valid Course";
                    flag = false;
                }
            }else{
                errors = "*Please Enter Valid Course";
                flag = false;
            }
            seterrCrse(errors)
        }else{
            errors = "*Please Enter Valid Course";
            flag = false;
            seterrCrse(errors)
        }
       
        return flag
    }

    const Specialization =()=>{
        let errors = manage.errors;
        let flag = true;
        if(manage['SelSpecialization'].label !== undefined && manage['SelSpecialization'].label!==""){
            if(manage['SelSpecialization'].label.length>1 ){
                var req = manage['SelSpecialization'].label
                var regex = /[A-Z a-z 0-9]/g;
                var found = req.match(regex);
                if (found) {
                    errors= "";
                    flag = true;
                }else{
                    errors= "*Please Enter Valid Specialization";
                    flag = false;
                }
            }else{
                errors = "*Please Enter Valid Specialization";
                flag = false;
            }
            seterrSpec(errors)
        }else{
            errors = "*Please Enter Valid Specialization";
            flag = false;
            seterrSpec(errors)
        }
       
        return flag
    }

    let TabsList = <></>;
    if(manage.IsTermsLoaded){
        TabsList = (
            <>
                <div className="enter-dts">SKL YCC 2022 TERMS AND CONDITIONS</div>
                <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                    <ul>
                        <li>The organisers retain the right to make any changes to the event, its format, date, rules and in any matter related to this event, at any time before or during the event. The organisers reserve the right to accept or reject any application to registration and / or entry to the event proceedings.</li>
                        <li>By entering the event and / or participating in the Quiz, the attendees permit themselves to be photographed / videographed / recorded by electronic and other mediums for telecast / publication and other display usage of the organisers.</li>
                        <li>By registering for the event, you also indicate your acceptance that the organisers can send you communication from time to time regarding the event or any other initiatives by the organisers.</li>
                        <li>Prizes once awarded will be final and there would be no exchange/substitution of the prizes to the winners. All tax liabilities wherever applicable, will be the responsibility of the recipients of the prizes.</li>
                        <li>Neither the organisers nor the sponsors, or employees of the organisers and sponsors may be held liable for any warranty, costs, damage, injury, or any other claims incurred as a result of usage of the prize by the winner.</li>
                        <li>If the specified prize becomes unavailable due to unforeseen circumstances, the organisers may substitute the prize at their discretion.</li>
                        <li>If any contestant has any questions or suggestions about the event, they are welcome to contact <b>connect@skillablers.com</b>.</li>
                        <li>The organiser reserves the right to alter/ amend/ modify any rules of the contest at anytime without any notice whatsoever. The organiser reserves the right to withdraw or terminate this event at any time without prior notice.</li>
                        <li>Once you register for the event you are indicating your acceptance that the decision of the organisers and quizmaster/s will be final and binding on all issues related to this quiz and no correspondence in this regard would be entertained.</li>
                    </ul>
                </div>
                <div className="text-aligen-center go-backk-sectionn">
                <span className="next-button prevBtn show-cursor trans-animate" onClick={GoBack}>Back</span>
                {manage.IsTermsChecked? <></> :
                    <span className="next-button show-cursor  trans-animate " onClick={AcceptTerms}>{manage.IsTermsChecked?'Refuse':'Accept'}</span>}
                </div>
            </>)
    }else{
        TabsList = (
            <>
                <div className="enter-dts">ENTER DETAILS</div>
                <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                    <div className="col-md-6 col-12 fst-col-6">
                        <input 
                            type="text" 
                            className="input-reg" 
                            placeholder="First name" 
                            onChange={handleChange('FirstName')}
                            value={manage.FirstName}
                            onKeyPress= {OnlyAlphabets}
                            //onBlur={validateFname}
                            onBlur={()=>{validatelenandchar("FirstName","First Name")}}
                            autoComplete="off"
                        />
                        {(manage.errors.FirstName !== undefined  && manage.errors.FirstName !== '')&&
                        <div className="err-div">
                            <span className="error-class">{manage.errors.FirstName}</span>
                        </div>}
                    </div>
                    <div className="col-md-6 col-12 sec-col-6">
                        <input 
                            type="text" 
                            className="input-reg" 
                            placeholder="Last name"
                            onChange={handleChange('LastName')}
                            value={manage.LastName}
                            onKeyPress= {OnlyAlphabets}
                            //onBlur={validateLname}
                            onBlur={()=>{validatelenandchar("LastName","Last Name")}}
                            autoComplete="off"
                        />
                        {(manage.errors.LastName !== undefined && manage.errors.LastName !== '') &&
                        <div className="err-div">
                            <span className="error-class padd-adjust">{manage.errors.LastName}</span>
                        </div>}
                    </div>
                </div>
                <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                    <div className="col-md-6 col-12 fst-col-6">
                        <div className="row mx-0 col-12 px-0">
                            <div className="col-4 country-code-padding">
                                <Select
                                    maxMenuHeight={150}
                                    placeholder="Country"
                                    styles={customStyles}
                                    options={CountryCodeList}
                                    onChange={handleSelection}
                                    value={manage.CountryCode}
                                />
                            </div>
                            <div className="col-8 mobile-padding">
                                <input 
                                    type="text"
                                    className="input-reg" 
                                    placeholder="Mobile number"
                                    onChange={handleChange('MobNumber')} 
                                    onKeyPress={OnlyNumbers} 
                                    value={manage.MobNumber}
                                    onBlur={()=>ValMobileNoIsExist()}
                                    maxLength={10}
                                    disabled={manage.CountryCode.value === undefined}
                                    autoComplete="off"
                                />
                            </div>
                            {(manage.errors.MobileNumber !== undefined && manage.errors.MobileNumber !== '') &&
                            <div className="err-div">
                                <span className="error-class">{manage.errors.MobileNumber}</span>
                            </div>}
                        </div>
                    </div>
                    {(manage.CountryCode.value === "91" && manage.showOtp === true && manage.IsMobileVerified === false)&&
                        <div className="col-md-6 col-12 sec-col-md-6 col-12 padding-col ">
                            <div className="row mx-0 col-12 d-flex-fully-cent">
                                <div className="col-4 country-code-padding">
                                    <button 
                                        className="reg-button" 
                                        onClick={SendOTP}
                                    >
                                        {manage.MobileReferenceCode === "" ? "Send OTP" : "Re-Send OTP"}
                                    </button>
                                </div>
                                <div className="col-4 px-0">
                                    <input 
                                        type="text" 
                                        className="input-reg" 
                                        placeholder="Enter OTP" 
                                        onChange={handleChange('OTP')}
                                        onKeyPress={OnlyAlphaNumeric}
                                        onBlur={validateOtp}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="col-4 mobile-padding">
                                    <button className="reg-button" onClick={Verify}>Verify</button>
                                </div>
                            </div>
                            {(manage.errors.OTP !== undefined && manage.errors.OTP !== '') && 
                            <div className="err-div">
                                <span className="error-class">{manage.errors.OTP}</span>
                            </div>}
                        </div>
                    } 
                </div>
                <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                    <div className="col-md-6 col-12 fst-col-6">
                        <input 
                            type="text" 
                            className="input-reg" 
                            placeholder="Email id"
                            onChange={handleChange('Email')}
                            value={manage.Email}
                            onBlur={ValEmailIsExist}
                            autoComplete="off"
                        />
                        {(manage.errors.EmailId !== undefined  && manage.errors.EmailId !=='')  && 
                        <span className="error-class padd-email">{manage.errors.EmailId}</span>}
                    </div>
                </div>
                <div className="col-12 row mx-0 padding-bottom-one-vh ht-second-radio">
                    <div className="row mx-0  col-xl-12 col-12 col-12 px-0 height-gender ">
                        <div className="col-lg-3 col-md-3 px-0 transform-trans-45">
                            <input 
                                className="mr-2" 
                                type="radio" id={"stud"} 
                                name="radio-group-User-type" 
                                checked={ selectedusertype === "stud"}
                                onChange={(e) => ShowUserTypeContents("stud")}
                            />
                            <label className=" line-height-19px Custom-radio label-font " htmlFor={"stud"}>Student</label>
                        </div>
                        <div className="col-lg-3 col-md-3 px-0 transform-trans-45">
                            <input 
                                className="mr-2" 
                                type="radio" 
                                id={"job"} 
                                name="radio-group-User-type" 
                                checked={ selectedusertype === "job"}
                                onChange={(e) => ShowUserTypeContents("job")}
                            />
                            <label className=" line-height-19px Custom-radio  label-font " htmlFor={"job"}>Job Seeker</label>
                        </div>
                        <div className="col-lg-4 col-md-6 px-0 transform-trans-45">
                            <input 
                                className="mr-2" 
                                type="radio" id={"work"}  
                                name="radio-group-User-type" 
                                checked={ selectedusertype === "work"}
                                onChange={(e) => ShowUserTypeContents("work")}
                            />
                            <label className=" line-height-19px  Custom-radio  label-font " htmlFor={"work"}>Working Professional</label>
                        </div>
                        <div className="col-lg-2 col-md-3 px-0 transform-trans-45">
                            <input 
                                className="mr-2" 
                                type="radio" id={"oth"} 
                                name="radio-group-User-type" 
                                checked={selectedusertype === "oth"}
                                onChange={(e) => ShowUserTypeContents("oth")}
                            />
                            <label className="line-height-19px Custom-radio label-font" htmlFor={"oth"}>Others</label>
                        </div>
                        {(manage.errors.UserType !== undefined &&  manage.errors.UserType !== "")&&
                        <div className="err-div">
                            <span className="error-class">{manage.errors.UserType}</span>
                        </div>}
                    </div>
                </div>
                {selectedusertype==='stud' && 
                    <div className="col-12 px-0 row mx-0 padding-bottom-one-vh ht-second-radio">
                        <div className="row mx-0  col-xl-12 col-12 col-12 px-0 height-gender ">
                            <div className="col-lg-4 col-md-4 px-0 transform-trans-45">
                                {/* <input 
                                    type="text" 
                                    className="input-reg" 
                                    onChange={handleChange('Course')}
                                    value={manage.Course}
                                    //onBlur={validateCourse}
                                    onBlur={()=>{validatelenandchar("Course","Course")}}
                                    placeholder="Course"
                                    autoComplete="off"
                                /> */}
                                <AsyncCreatableSelect
                                    cacheOptions
                                    defaultOptions
                                    maxMenuHeight={150}
                                    styles={customStylecollege}
                                    loadOptions={getCourseOptions}
                                    isClearable={true}
                                    formatCreateLabel={undefined}
                                    name={'Course'}
                                    placeholder={'Course'}
                                    inputValue = {(manage.SelCourse === null || manage.SelCourse.label === undefined) ? '' :manage.SelCourse.label }
                                    controlShouldRenderValue={false}                               
                                    components={{                                    
                                        Input                               
                                    }}
                                    onChange={OnChangeSelectCourse}
                                    onInputChange ={CourseInputChange}
                                    onBlur={()=>{SetCourse()}}
                                />
                               
                            </div>
                            <div className="col-lg-4 col-md-4 px-0 transform-trans-45">
                                {/* <input 
                                    type="text" 
                                    className="input-reg" 
                                    onChange={handleChange('CourseSpecialization')}
                                    value={manage.CourseSpecialization}
                                    //onBlur={validateCourseSpecialization}
                                    onBlur={()=>{validatelenandchar("CourseSpecialization","Spcialization")}}
                                    placeholder="Specialization"
                                    autoComplete="off"
                                /> */}
                                <AsyncCreatableSelect
                                    cacheOptions
                                    defaultOptions
                                    maxMenuHeight={150}
                                    styles={customStylecollege}
                                    loadOptions={getSpecializationOptions}
                                    isClearable={true}
                                    formatCreateLabel={undefined}
                                    name={'Specialization'}
                                    placeholder={'Specialization'}
                                    inputValue = {(manage.SelSpecialization === null || manage.SelSpecialization.label === undefined) ? '' :manage.SelSpecialization.label }
                                    controlShouldRenderValue={false}                               
                                    components={{                                    
                                        Input                               
                                    }}
                                    onChange={OnChangeSelectSpecialization}
                                    onInputChange ={SpecializationInputChange}
                                    onBlur={()=>{SetSpecialization()}}
                                />
                               
                            </div>
                            <div className="col-lg-4 col-md-4 px-0 transform-trans-45">
                                {/* <input 
                                    type="text" 
                                    className="input-reg" 
                                    onChange={handleChange('Institution')}
                                    value={manage.Institution}
                                    //onBlur={validateInstitution}
                                    onBlur = {()=>{validatelenandchar("Institution","Institution")}}
                                    placeholder="Name of Institution"
                                    autoComplete="off"
                                /> */}
                                <AsyncCreatableSelect
                                    cacheOptions
                                    defaultOptions
                                    maxMenuHeight={150}
                                    styles={customStylecollege}
                                    loadOptions={getCollegeOptions}
                                    isClearable={true}
                                    formatCreateLabel={undefined}
                                    name={'Instituition'}
                                    placeholder={'Name of Institution'}
                                    inputValue = {(manage.College === null || manage.College.label === undefined) ? '' :manage.College.label }
                                    controlShouldRenderValue={false}                               
                                    components={{                                    
                                        Input                               
                                    }}
                                    onChange={OnChangeSelect}
                                    onInputChange ={CollegeInputChange}
                                    onBlur={()=>{SetCollege()}}
                                />
                                {/* <AsyncSelect
                                    cacheOptions                               
                                    defaultOptions                                
                                    loadOptions={getCollegeOptions}                                
                                    isClearable={true}
                                    name={'Instituition'}
                                    placeholder={'Name of Institution'}
                                    value={manage.College}
                                    inputValue = {(manage.College === null || manage.College.label === undefined) ? '' :manage.College.label }
                                    styles={customStylecollege}
                                    controlShouldRenderValue={false}                               
                                    components={{                                    
                                        Input                               
                                    }}
                                    onChange={OnChangeSelect}
                                    onInputChange ={CollegeInputChange}
                                    onBlur={()=>{SetCollege()}}
                                /> */}
                            </div>
                        </div>
                        <div className="row mx-0  col-xl-12 col-12 col-12 px-0 height-gender ">
                            <div className="col-lg-4 col-md-4 px-0 transform-trans-45">
                            {(errCrse !== '') &&
                                    <div className="err-div">
                                        <span className="error-class">{errCrse}</span>
                                    </div>
                                }
                                </div>
                                <div className="col-lg-4 col-md-4 px-0 transform-trans-45">
                                {(errSpec) &&
                                    <div className="err-div">
                                        <span className="error-class">{errSpec}</span>
                                    </div>
                                }
                                </div>
                                <div className="col-lg-4 col-md-4 px-0 transform-trans-45">
                                {errInst !== '' &&
                                    <div className="err-div">
                                        <span className="error-class">{errInst}</span>
                                    </div>
                                }
                                </div>
                                </div>
                    </div>
                }
                {selectedusertype==='job' && 
                    <div className="col-12 px-0 row mx-0 padding-bottom-one-vh ht-second-radio">
                        <div className="row mx-0  col-xl-12 col-12 col-12 px-0 height-gender ">
                            <div className="col-lg-6 col-md-6 px-0 transform-trans-45">
                                <input 
                                    type="text" 
                                    className="input-reg"
                                    onChange={handleChange('JobSpecialization')}
                                    value={manage.JobSpecialization}
                                    //onBlur={validateJobSpecialization} 
                                    onBlur = {()=>{validatelenandchar("JobSpecialization","Specialization")}}
                                    placeholder="Specialization"
                                    autoComplete="off"
                                />
                                {(manage.errors.JobSpecialization !== undefined  && manage.errors.JobSpecialization !='')&&
                                    <div className="err-div">
                                        <span className="error-class">{manage.errors.JobSpecialization}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {selectedusertype==='work' &&
                    <div className="col-12 px-0 row mx-0 padding-bottom-one-vh ht-second-radio">
                        <div className="row mx-0  col-xl-12 col-12 col-12 px-0 height-gender ">
                            <div className="col-lg-6 col-md-6 px-0 transform-trans-45">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    onChange={handleChange('Company')}
                                    value={manage.Company}
                                    //onBlur={validateCompany}
                                    onBlur = {()=>{validatelenandchar("Company","Company")}}
                                    placeholder="Name of Company"
                                    autoComplete="off"
                                />
                                
                            </div>
                            <div className="col-lg-6 col-md-6 px-0 transform-trans-45">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    onChange={handleChange('CompanySpecialization')}
                                    value={manage.CompanySpecialization}
                                    //onBlur={validateCompanySpecialization}
                                    onBlur= {()=>{validatelenandchar("CompanySpecialization","Specialization")}}
                                    placeholder="Specialization"
                                    autoComplete="off"
                                />
                                
                            </div>
                        </div>
                        <div className="row mx-0  col-xl-12 col-12 col-12 px-0 height-gender ">
                            <div className="col-lg-6 col-md-6 px-0 transform-trans-45">
                            {manage.errors.Company !== undefined &&
                                    <div className="err-div">
                                        <span className="error-class">{manage.errors.Company}</span>
                                    </div>
                                }
                                </div>
                                <div className="col-lg-6 col-md-6 px-0 transform-trans-45">
                                {manage.errors.CompanySpecialization !== undefined &&
                                    <div className="err-div">
                                        <span className="error-class">{manage.errors.CompanySpecialization}</span>
                                    </div>
                                }
                                </div>
                                </div>
                    </div>
                }
                {selectedusertype==='oth' &&
                    <div className="col-12 px-0 row mx-0 padding-bottom-one-vh ht-second-radio">
                        <div className="row mx-0  col-xl-12 col-12 col-12 px-0 height-gender ">
                            <div className="col-lg-6 col-md-6 px-0 transform-trans-45">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    onChange={handleChange('SpecifyDetails')}
                                    value={manage.SpecifyDetails}
                                    //onBlur={validateSpecifyDetails}
                                    onBlur= {()=>{validatelenandchar("SpecifyDetails","Detail")}}
                                    placeholder="Specify Details"
                                    autoComplete="off"
                                />
                                {manage.errors.SpecifyDetails !== undefined &&
                                    <div className="err-div">
                                        <span className="error-class">{manage.errors.SpecifyDetails}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="padding-btm-row txt-center mt-3">
                    <input
                        type="checkbox"
                        className="squre-checkbox"
                        checked={manage.IsTermsChecked}
                        onChange={TermsCheck}
                    />
                    <label className="label-font squre-checkbox-1 cust-data " htmlFor={"SelectAll"}>
                        I agree to the <span className="acolor cursor-pointer color-blue" onClick={LoadTerms}>Terms &amp; Conditions</span>
                    </label>
                    {manage.errors.Terms !== undefined &&
                    <div className="padding-btm-row txt-center terms-height">
                        <span className="error-class">{manage.errors.Terms}</span>
                    </div>}
                </div>
                
                <div className="text-aligen-center trans-animate">
                    <span  className="next-button show-cursor " onClick={Save}>SUBMIT</span>
                </div>
            </>
        )
    }

    return (
        <div className="min-height-conatainer ycc-centeraligened-reg">
            {LoadCongrates ?
            <Congrates> </Congrates>
             :<>
            <div className="Ycc-reg-text padding-top-zro">SKL YCC 2022 registration</div>
            <>
                <div className="reg-main-wrapper reg-fst-conatiner active">
                    <div className="row mx-0 col-lg-9 col-md-11 col-10 px-0">
                        <div className="col-lg-4 col-12 reg-fist-conatiner">
                            <div className="ycc-logo-conatiner">
                                <img src={YCCLogo} />
                            </div>
                            <div className="ycc-date">{(QuizDate === undefined || 
                                QuizDate === "" ||  QuizDate === null) ? '' :
                                QuizDate   }</div>
                        </div>
                        <div className="col-lg-8 col-12 regis-sec-section">
                            {TabsList}  
                        </div>
                    </div>
                </div>
            </> </>}  
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
})

export default Registeration;