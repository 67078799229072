import React from "react";
import $ from 'jquery';
// import Leap from '../../Assests/Images/leapimg.png';
// import Primer from '../../Assests/Images/primerimg.png';
import '../../Assests/css/leap-primer.css';
import Modal from "./Modal";
    
// import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEnvelope, faLeaf, faMapLocation, faMapMarked, faMapMarkedAlt, faMapMarker, faMapMarkerAlt, faPlay, faUserCircle } from '@fortawesome/free-solid-svg-icons';
class PrimerLeap extends React.Component
{

constructor(props){
    super(props);
    this.state ={
        isIntern: false,
        Industry: 0
    }
}
    // let scrolltopReached = 0 , executed = 0
    componentDidMount(){
            window.$('html,body').animate({
                scrollTop: 0
            }); 
        document.body.style.background = "#1C1C1D"
    $(window).on('scroll',e=>{
        $('.leap-sub').each(function(){
            //console.log('document.querySelectorAll("#sk-primer-content")[0].scrollHeight + (window.innerHeight * 0.3)',)
            if(($(this).offset().top - (window.innerHeight * 30))  < $(window).scrollTop()){
                document.getElementsByClassName("leap-sub")[0].style.position = 'static'
            }
            if(($(this).offset().top - (window.innerHeight * -0.2))  < $(window).scrollTop()){
                document.getElementById("leap-p1").style.opacity = '0.5'
                document.getElementById("leap-p2").style.opacity = '1'
                // document.getElementById("leap-p2").style.transition = '1.5s'
                document.getElementById("leap-dot-one").style.display = 'none'
                document.getElementById("primer-dot-one").style.display = 'inline-block'
                

               
            }
            if(($(this).offset().top - (window.innerHeight * 0.02))  > $(window).scrollTop()){
                document.getElementById("leap-p1").style.opacity = '1'
                document.getElementById("leap-p2").style.opacity = '0.5'
                // document.getElementById("leap-p2").style.transition = '1.5s'
                // document.getElementById("primer-dot").style.display = 'block'
                document.getElementById("leap-dot-one").style.display = ' inline-block'
                document.getElementById("primer-dot-one").style.display = 'none'
                
                
            }
            if(($(this).offset().top - (window.innerHeight * 0.3))  < $(window).scrollTop())
            {
                document.getElementsByClassName("leap-sub")[0].style.position = 'fixed'
                document.getElementsByClassName("leap-sub")[0].style.top = '30vh'
                document.getElementsByClassName("leap-sub")[0].style.left = '21.85%'
                // console.log('scrolltopReached,',scrolltopReached)
            }
            if(($("#sk-primer-content").offset().top - (window.innerHeight * 0.3))  < $(window).scrollTop())
            {
            document.getElementsByClassName("leap-sub")[0].style.position = 'static'
           
            }
            if(($("#sk-primer-content").offset().top - (window.innerHeight * 0.3))  > $(window).scrollTop())
            {
                document.getElementsByClassName("primer-sub")[0].style.display = 'none'
                document.getElementById("primer-p1").style.opacity = '0.5'
            }
            if(($("#sk-primer-content").offset().top - (window.innerHeight * 0.3))  < $(window).scrollTop())
            {
                 document.getElementsByClassName("primer-sub")[0].style.display = 'block' 
                 document.getElementById("primer-dot-two").style.display = "block"
                 document.getElementById("primer-p2").style.whiteSpace = "nowrap"
                 
            }

        })
    })
    
   
}

componentWillUnmount(){
    document.body.style.background = "#fff"
}

 OpenIntern = (id) => {
    //setisIntern(true)
    this.setState({
        isIntern : true,
        Industry : id
    })
}

 closeIntern = () => {
   // setisIntern(false)
   this.setState({
    isIntern : false,
    Industry : 0
})
}
  AutoScroll = ()=>{
    if(  window.matchMedia("(min-width: 568px)").matches){
    let scrolling = $("#sk-leap-content").offset().top - window.innerHeight * 0.25
    $('html,body').animate({
        scrollTop: scrolling
    },
        'slow');
}
else{
    let scrolling = window.innerHeight * 0.25
    $('html,body').animate({
        scrollTop: scrolling
    },
        'slow');
}

}
AutoScroll1 = ()=>{
   if(  window.matchMedia("(min-width: 568px)").matches){
    let scrolling = $(".primer-wrapper").offset().top - window.innerHeight * 0.25
    $('html,body').animate({
        scrollTop: scrolling
    },
        'slow');
}
else{
    $('html,body').animate({
        scrollTop:0
    }); 
    let scrolling = document.getElementById("leap-logo").getBoundingClientRect().top +  window.innerHeight * 0.3
    $('html,body').animate({
        scrollTop: scrolling
    },
        'slow'); 
}
}
    
    render(){

       
        return(
            <div>
            <div class="main-leap-primer">
            <div class="heading-wrapper">
               <div class="heading">
                   <h1 id="sk-ind">INDUSTRY READINESS</h1>
                   <p id="stop">We have got few programs that would mould an industry ready generation</p>
                    
               </div>
           </div> 
       
            <div class="leap-primer-wrapper">
           <div class="leap-wrapper">
               <div class="leap" >
                   <div class="leap-heading">
                       <div class="leap-sub">
                      <h3 id="leap-p1" onClick={this.AutoScroll}><span id="leap-firstletter">L</span>EAP <span id="leap-dot-one" class="animate__animated animate__bounce" > <i class="fa fa-circle" id="dot-size" aria-hidden="true"></i></span></h3>
                      <h3 id="leap-p2" onClick={this.AutoScroll1}><span id="primer-firstletter">9</span>th SEM<span id="primer-dot-one" class="animate__animated animate__bounce"> <i class="fa fa-circle" id="dot-size" aria-hidden="true"></i></span></h3>
                   </div>
                   </div>
                   <div id="sk-leap-content">
                       {/* <img src={Leap} id="leap-image"/>  */}
                       <ul>
                           <li>6 months of hands-on industry readiness engagement</li>
                           <li>Real life project development experience working with industry professionals</li>
                           <li>Internship opportunities</li>
                           <li>Interview preparation workshop</li>
                           <li>Module wise performance assessments</li>
                           <li>Upto 3 interview opportunities with reputed corporates</li>
                           <button  id="sk-contacts-btn" onClick={()=>{this.OpenIntern(1)}} >Contact Us</button>
                       </ul>
                   </div>
               </div>
           </div> 
        
            <div class="primer-wrapper">
               <div class="primer" >
                   <div class="primer-heading">
                       <div class="primer-sub">
                      <h3 id="primer-p1"  onClick={this.AutoScroll}><span id="leap-firstletter">L</span>EAP <span id="leap-dot-two"><i class="fa fa-circle" id="dot-size" aria-hidden="true"></i></span> </h3>
                      <h3 id="primer-p2" onClick={this.AutoScroll1}><span id="primer-firstletter">9</span>th SEM<span id="primer-dot-two"><i class="fa fa-circle" id="dot-size" aria-hidden="true"></i></span> </h3>
                   </div>
                   </div>
                     <div id="sk-primer-content"> 
                       {/* <img src={Primer}alt="" id="primer-image"/> */}
                       <ul>
                       <li>Orientation & why industry readiness</li>
            <li>Importance of profiling and hands on visual profile  creation</li>
            <li>Online industry specific training video modules with assignments</li>
            <li>Communication and interview preparation modules</li>
            <li>Access to skillablers platform course contents</li>
            <li>Upto 3 placement oppurtunities</li>
                           <button   id="sk-contacts-btn" onClick={()=>{this.OpenIntern(2)}} >Contact Us</button>
                       </ul>
                       
                   </div>
               </div>
           </div> 
       </div>
       </div>



       <div class="tabview-main">
<div class="tab-heading-wrapper">
    <div class="tab-heading-content">
        <h3 id="tab-ind">INDUSTRY READINESS</h3>
        <p id="tab-content">We have got few programs that would mould an industry ready generation.</p>
    </div>
</div>
<div class="tabview-leap-primer-wrapper">
    <div class="tabview-leap-primer-heading">
        <div class="tabview-leap"><h3 id="p1" onClick={this.AutoScroll}> <span id="tab-leap-firstletter">L</span>EAP</h3></div>
        <div class="tabview-primer"><h3 id="p2"  onClick={this.AutoScroll1}> <span id="tab-primer-firstletter">9</span>th SEM</h3></div>
    </div>
    <div class="tabview-leap-content" id="sk-leap-content">
       {/* <div> <img src={Leap}  id="leap-logo"/> </div> */}
                <ul>
                    <li>6 months of hands-on industry readiness engagement</li>
                    <li>Real life project development experience working with industry professionals</li>
                    <li>Internship opportunities</li>
                    <li>Interview preparation workshop</li>
                    <li>Module wise performance assessments</li>
                    <li>Upto 3 interview oppurtunities</li>
                    <button   id="tabview-contact-btn" onClick={()=>{this.OpenIntern(1)}}>Contact Us</button>
                </ul>
    </div>
       <div class="tabview-primer-content primer-wrapper" id="sk-primer-content">
        {/* <img src={Primer}  alt="" id="primer-logo"/> */}
        <ul>
            <li>Orientation & why industry readiness</li>
            <li>Importance of profiling and hands on visual profile  creation</li>
            <li>Online industry specific training video modules with assignments</li>
            <li>Communication and interview preparation modules</li>
            <li>Access to skillablers platform course contents</li>
            <li>Upto 3 placement oppurtunities</li>
            <button id="tabview-contact-btn" onClick={()=>{this.OpenIntern(2)}}>Contact Us</button>
        </ul>
        
    </div>
</div>
</div>  
{this.state.isIntern &&
                <Modal
                    isFrame={false}
                    Url={""}
                    close={this.closeIntern}
                    isIntern={true}
                    Industry = {this.state.Industry}
                />}
       </div>
        )}
}
export default PrimerLeap