import React from "react";
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import { IndustryURL,IndusURL,Instituteurl } from "../../Assests/js/config";

import 'react-toastify/dist/ReactToastify.css';
import visualprofile_light from '../../Assests/Images/visualprofile-light.png'
import visualprofile_dark1 from '../../Assests/Images/visualprofile-dark1.png'
import profile360_light from '../../Assests/Images/profile360-light.png'
import profile360_dark from '../../Assests/Images/profile360-dark.png'
import jobconnect_dark from '../../Assests/Images/AACTe dark.png'
import jobconnect_light from '../../Assests/Images/AACTe.png'
import interview_dark from '../../Assests/Images/interview-dark.png'
import interview_light from '../../Assests/Images/interview-light.png'
import {IndividualUrllogin} from '../../Assests/js/config';
import Instdashboard from '../../Assests/Images/Instdashboard.png'
import Lmscourse from '../../Assests/Images/Lmscourse.png'
import InstSvp from '../../Assests/Images/InstSvp.png'
import Phonixpng from '../../Assests/Images/Phonixpng.png'
import upskillinggs from '../../Assests/Images/upskillinggs.png';
import onlineinterview from '../../Assests/Images/onlineinterview.png';
import careerCoun from '../../Assests/Images/careerCoun.png';
import c360light from '../../Assests/Images/connect360-light.png'
import c360dark from '../../Assests/Images/c360dark.png';
import c360lightv1 from '../../Assests/Images/c360lightv1.png';
import history from '../../../src/histoty';
import base64 from 'base-64'
import indulite from '../../Assests/Images/indulite.svg';
import indudark from '../../Assests/Images/indudark.svg';
import Instdashdark from '../../Assests/Images/Instdashdark.svg';
import Instdashlite from '../../Assests/Images/Instdashlite.svg';
import Instsvpdark from '../../Assests/Images/Instsvpdark.svg';
import Instsvplite from '../../Assests/Images/Instsvplite.svg';
import Instlmsdark from '../../Assests/Images/Instlmsdark.svg';
import instlmslite from '../../Assests/Images/instlmslite.svg';
import appleimg from '../../Assests/Images/AACTelarge.png'
import Requestdomologo from "../../Assests/Images/mobiledemologo.svg"
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import RequestDemo from '../Industry_component/requestdemo-popup'
import Processflowarrow from '../../Assests/Images/RightandLeftarrowinProcessFlow.svg'

import Phonixfactor2024Dark from '../../Assests/Images/Phonixfactor2024Dark.svg'
import Phoenixfactor2024Light from '../../Assests/Images/Phoenixfactor2024Light.svg'

import Phoenixfactor2024IAQ from '../../Assests/Images/Phoenix2024factor.jpg'





let scroll
class Instprocessflow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeData:3,
            skills: [],
            jobs :[],
            clientdetail: [],
            categ : [],
            catcomplogo:[],
            // searchSkills : '',
            searchSkills : [],
            textactive:1,
            Tab:1,
            PFCfiltredselected:'',
            CategHalf :[],
            selectedpack:'',
            checkedtrue:false,
            isReqstdemo:false,
            type:""
        }
    }

    componentDidMount(){
        //mobile view tabs process flow function
    // hide all contents accept from the first div
    $('.tabContent div:not(:first)').toggle();
    // hide the previous button
    $('.PreviousprocessFlow').hide();
    $('.tabs1 li').click(function () {
    if ($(this).is(':last-child')) {
            $('.nextprocessFlow').hide();
    } 
    else {
            $('.nextprocessFlow').show();
    }
    if ($(this).is(':first-child')) {
            $('.PreviousprocessFlow').hide();
    } 
    else {
            $('.PreviousprocessFlow').show();
    }
        var position = $(this).position();
            // console.log("position",position)
        var corresponding = $(this).data("id");
    // scroll to clicked tab with a little gap left to show previous tabs
        scroll = $('.tabs1').scrollLeft();
        // console.log("scroll",scroll)
        $('.tabs1').animate({
            'scrollLeft': scroll + position.left
           
        }, 200);
    // show content of corresponding tab
        $('div.' + corresponding).toggle();
    // remove active class from currently not active tabs
        $('.tabs1 li').removeClass('active');
    // add active class to clicked tab
        $(this).addClass('active');
    });

    $('.nextprocessFlow').click(function(e){
    e.preventDefault();
    $('li.active').next('li').trigger('click');
    if(scroll== 185|| scroll== 184||scroll== 159 || scroll == 160)
        {
          
            $('.nextprocessFlow').hide();
        }
    });

    $('.PreviousprocessFlow').click(function(e){
    e.preventDefault();
    $('li.active').prev('li').trigger('click'); 
    });
//mobile view tabs process flow function
    }
    visuaprofiling = (id) => {

        this.setState({
            activeData: id,
            textactive: 0
        }, () => {
            setTimeout(() => {
                this.setState(
                    {
                        textactive: id
                    }
                )
            }, 900)


        })
    }
    openinsti=()=>
    {
       window.open(Instituteurl+'/ins-login','_blank')
    }

    // popup for Request for demo

OpenRequestDemo = (type) => {

    this.setState({

        isReqstdemo : true,
        type:type
     

    })

}



 closeRequestDemo = () => {

   // setisIntern(false)

   this.setState({

    isReqstdemo : false,
    type:""

    // pop : 0

})

}

 toastMessage=()=>{

    toast.success('Thank you for your request. Our team will contact you soon.', {    

        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        // autoClose: 5555000,
        theme: "colored"

    })

 }

    render(){
        return(
            // <div className="skl-prod-main-cont individualBlockIdent pt-2" id="skl-prod-main-cont">
            //                 <div className="col-12 px-0 row mx-0 d-flex justify-content-between">
            //                     <div className="col-3  px-0">
            //                         <a target='_blank'>
            //                             <div className={this.state.activeData === 1 ? "skl-productss active" : "skl-productss"}
            //                                 onClick={() => this.visuaprofiling(1)}

            //                             >
            //                                 <div className="skl-image-blocks">
            //                                     <img src={this.state.activeData === 1 ? Instsvpdark : Instsvplite}
            //                                     // onMouseOver={e => e.currentTarget.src = svp1}
            //                                     // onMouseOut={e => e.currentTarget.src = svp}
            //                                     />
            //                                 </div>
            //                                 <div>
            //                                     <div className="skl-prod-name bold-efeect">Institute profiling</div>

            //                                 </div>
            //                             </div>
            //                         </a>
            //                     </div>
            //                     <div className="col-3  px-0">
            //                         <a target='_blank'>
            //                             <div className={this.state.activeData === 2 ? "skl-productss active" : "skl-productss"}
            //                                 onClick={() => this.visuaprofiling(2)} >
            //                                 <div className="skl-image-blocks"
            //                                 >
            //                                     <img src={this.state.activeData === 2 ? Instlmsdark : instlmslite}
            //                                     // onMouseOver={e => e.currentTarget.src = P360_1}
            //                                     // onMouseOut={e => e.currentTarget.src = P360}
            //                                     />
            //                                 </div>
            //                                 <div>
            //                                     <div className="skl-prod-name bold-efeect">offer courses</div>

            //                                 </div>
            //                             </div>
            //                         </a>
            //                     </div>
            //                     <div className="col-3  px-0">

            //                         <div className={this.state.activeData === 3 ? "skl-productss active" : "skl-productss"}
            //                             onClick={() => this.visuaprofiling(3)}>
            //                             <div className="skl-image-blocks">
            //                                 <img src={this.state.activeData === 3 ? indudark  : indulite}
            //                                 // onMouseOver={e => e.currentTarget.src = job1}
            //                                 // onMouseOut={e => e.currentTarget.src = job}
            //                                 />
            //                             </div>
            //                             <div>
            //                                 <div className="skl-prod-name bold-efeect">campus program</div>

            //                             </div>
            //                         </div>
            //                     </div>
            //                     <div className="col-3  px-0">
            //                         <a target='_blank'>
            //                             <div className={this.state.activeData === 4 ? "skl-productss active" : "skl-productss"}
            //                                 onClick={() => this.visuaprofiling(4)}>
            //                                 <div className="skl-image-blocks">
            //                                     <img src={this.state.activeData === 4 ? Instdashdark : Instdashlite}
            //                                     // onMouseOver={e => e.currentTarget.src = online1}
            //                                     // onMouseOut={e => e.currentTarget.src = online}
            //                                     />
            //                                 </div>
            //                                 <div>
            //                                     <div className="skl-prod-name bold-efeect">placement Opportunities</div>

            //                                 </div>
            //                             </div>
            //                         </a></div>
                               
            //                 </div>
            //                 <div className={this.state.activeData === 1 ? "col-12 px-0 SKLhome-data-containerss active fst-border" :
            //                     this.state.activeData === 4 ? "col-12 px-0 SKLhome-data-containerss active lst-border" : "col-12 px-0 SKLhome-data-containerss active all-border "}>
            //                     <div className="col-12 px-0 row mx-0 websiteminheight">
            //                         <div className={this.state.textactive == 0 ? "col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0  align-paddd-induss  hgt-process-jobs pt-2 pb-4" : "col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0  align-paddd-induss  hgt-process-jobs pt-2 pb-4"}   >
            //                             <div className="visul-porfile-head ">
            //                                 {this.state.activeData === 1 ? < div className="">
            //                                     <div className="visual-profile-head1 ">Create </div>
            //                                     <div className="visual-haed-two ">Institution profile</div>
            //                                 </div> :
            //                                     this.state.activeData === 2 ?
            //                                         <div className="">
            //                                             <div className="visual-profile-head1">Create Your</div>
            //                                             <div className="visual-haed-two ">Learning Experiences</div>
            //                                         </div>
            //                                         : this.state.activeData === 3 ?
            //                                             <div className="">
            //                                                 <div className="visual-profile-head1 ">Get YOUR Students</div>
            //                                                 <div className="visual-haed-two  ">Industry Ready</div>
            //                                             </div>
            //                                             :
            //                                             this.state.activeData === 4 ?
            //                                                 <>
            //                                                     <div className="visual-profile-head1 animat ">Bridging the gap between</div>
            //                                                     <div className="visual-haed-two  ">Education and Employment</div>
            //                                                 </>
            //                                                 : <></>
                                                 
            //                                 }
            //                             </div>
            //                             <div className="visual-data-contentss ">
            //                                 {this.state.activeData === 1 ?
            //                                     <div className="">
            //                                         <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
            //                                         <p className="mb-3">
            //                                             Institute profiling refers to providing information and insights about an educational institute, school, college, or an university.
            //                                         </p>
            //                                         <p className="mb-3">
            //                                             Profiling an Institute helps create visibility and awareness among students, parents, and the community. It allows the institute to showcase its unique features, programs, and achievements, establishing its brand identity and reputation.
            //                                         </p>
            //                                         <p className="mb-3">
            //                                             With the best use of UX-n-UI, one can express oneself effectively-n-efficiently. This is the best way to get noticed and with least clutter reach out to the world with one's own credibility, capabilities, skills and aspirations.
            //                                         </p>
            //                                         <p className="mb-3">
            //                                             Institute profiling plays a crucial role in student recruitment. It helps potential students and parents make informed decisions by providing comprehensive information about the institute's academic offerings, admission procedures, scholarships, and placement records.
            //                                         </p>
            //                                         <p className="mb-0">
            //                                             Institute profiling supports the growth and development of educational institutes by enhancing their visibility, reputation, and engagement with students, parents, alumni, and partners.                            
            //                                         </p>
            //                                         </div>
            //                                     </div>
            //                                     :
            //                                     this.state.activeData === 2 ?
            //                                         <div className=" ">
            //                                             <p className="mb-3">
            //                                             Aims at providing educational programs or learning opportunities to individuals or groups and make courses available for enrollment or participation by students or learners.
            //                                             </p>
            //                                             <p className="mb-3">
            //                                             This includes Curriculum Development, Course Creation, Instruction and Facilitation, Assessment and Evaluation, Enrollment and Registration, & Support Services.
            //                                             </p>
            //                                             <p className="mb-0">
            //                                             Courses can cover a wide range of subjects, disciplines, and levels of education, from academic subjects in schools and universities to professional training programs and skill development courses. The aim is to provide structured learning experiences and opportunities for individuals to acquire knowledge, develop skills, and achieve specific learning goals.
            //                                             </p>
            //                                         </div>
            //                                         :
            //                                         this.state.activeData === 3 ?
            //                                             <div className=" ">
            //                                                 <p className="mb-3">
            //                                                 <span className="font-weight-bold">Learn new skills.</span> Learning a new skill can help you improve your job prospects and make yourself more marketable. It can also help you expand your knowledge and interests.
            //                                                 </p>
            //                                                 <p className="mb-3">
            //                                                 <span className="font-weight-bold">Get involved in extracurricular activities.</span> Extracurricular activities are a great way to meet new people, learn new skills, and develop your interests. There are many different types of extracurricular activities available, so you can find something that you are passionate about.
            //                                                 </p>
            //                                                 <p className="mb-3">
            //                                                 <span className="font-weight-bold">Soft skills:</span> Soft skills, such as communication, teamwork, and problem-solving, are also important for industry readiness. These skills can be developed through education, experience, and self-reflection.
            //                                                 </p>
            //                                                 <p className="mb-0">
            //                                                 <span className="font-weight-bold">Get a job.</span> Getting a job can help you develop your work ethic and learn new skills. It can also help you save money for college or other expenses.                                </p>
                                                        
            //                                             </div> :
            //                                             this.state.activeData === 4 ?
            //                                                 <div className=" ">
            //                                                     <p className="mb-3">
            //                                                     Placement opportunities refer to the opportunities provided to individuals, typically students or job seekers, to secure employment or internships in their chosen field or industry. These opportunities are typically facilitated by educational institutions, career services, recruitment agencies, or companies themselves.                                                                </p>
            //                                                     <p className="mb-3">
            //                                                     Placement opportunities aim to bridge the gap between theoretical learning and practical application by offering individuals a chance to gain hands-on experience in a professional setting. They provide a platform for individuals to apply their knowledge, develop essential skills, and gain industry-specific insights.                                                                </p>
            //                                                     <p className="mb-0">
            //                                                     Placement opportunities can take various forms, including internships, apprenticeships, co-op programs, or even full-time job placements. They may be offered by a specific company or organization, or they could be facilitated through partnerships between educational institutions and industry partners.                                                                </p>
            //                                                     {/* <p className="mb-3">
            //                                                     These opportunities often come with structured learning objectives, mentorship or supervision, and exposure to real-world work environments. They allow individuals to gain valuable industry experience, expand their professional network, and enhance their employability by building relevant skills and showcasing their abilities to potential employers.
            //                                                     </p>
            //                                                     <p className="mb-0">
            //                                                     Placement opportunities play a crucial role in shaping an individual's career trajectory, providing them with practical experience, and increasing their chances of securing future employment in their desired field. They are an important stepping stone for individuals to transition from education to the workforce and can significantly contribute to their professional growth and success.
            //                                                     </p> */}
            //                                                 </div> :
            //                                                 this.state.activeData === 5 ?
            //                                                     <div className=" ">
            //                                                         Every successful journey stands on the basis of sound advice. Our expert counsellors provide one on one personalised sessions and can advise on college admissions, the right courses that matches your aptitude and aspirations, all leveraging a powerful psychometric evaluation platform.</div>
            //                                                     :
            //                                                     <div className=" ">
            //                                                         Learning is a continuous process!  keep yourself relevant
            //                                                         through our industry readiness programs that helps bridge
            //                                                         your skills and employability gaps.</div>

            //                                 }
            //                             </div>
            //                             <div className="processflowAccessbt">
            //                             {this.state.activeData === 3 &&
            //                                 <div className="visual-view-more-btn-btnnns">
            //                                         <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openinsti() }} >Get Started</button>
            //                                 </div>
            //                             }
            //                             {this.state.activeData === 4 &&
            //                                 <div className="visual-view-more-btn-btnnns">
            //                                         <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openinsti() }} >Get Started</button>
            //                                 </div>
            //                             }
                                        
            //                             {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
            //                                 <div className="visual-view-more-btn-btnnns">

            //                                     <button className="createprofile meet-our-team texnonetransfor"
            //                                         onClick={() => { this.state.activeData === 5 && this.openModal() }}>
            //                                         <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? Instituteurl+'/indi-login'  :
            //                                             this.state.activeData === 2 ? Instituteurl+'/indi-login' :
            //                                                 this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
            //                                             {this.state.activeData === 1 ? "Get Started" : this.state.activeData === 2 ? " Get Started " :
            //                                                 this.state.activeData === 3 ? "Get Started" : this.state.activeData === 5 ? "Get Started" :
            //                                                     "Get Started"}</a></button>
                                               
            //                                 </div>
            //                             }
            //                             </div>

            //                         </div>
            //                         <div className={this.state.textactive == 0 ? "col-sm-6 col-12 dflex-cent padding-zer imgalignment  " : "col-sm-6 col-12 dflex-cent padding-zer imgalignment  align-items-center "}>
            //                             <img src={this.state.activeData === 1 ? InstSvp: this.state.activeData === 2 ?
            //                               Lmscourse : this.state.activeData === 3 ? Phonixpng :
            //                                     this.state.activeData === 4 ? Instdashboard :
            //                                         this.state.activeData === 5 ? careerCoun : upskillinggs} className="width-100per-image" />
            //                         </div>

            //                         <div className="processflowAccessbtMobile">
            //                             {/* {this.state.activeData === 3 &&
            //                                 <div className="visual-view-more-btn-btnnns">
            //                                         <button className="createprofile meet-our-team texnonetransfor"  onClick={() => { this.openindi() }} >View Jobs</button>
            //                                 </div>
            //                             } */}
            //                             {/* {this.state.activeData === 5 &&
            //                                 <div className="visual-view-more-btn-btnnns">
            //                                     <button className="visual-view-more-btn lapview-btn texnonetransfor" >Contact Us</button>
            //                                 </div>
            //                             } */}
            //                             {/* {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
            //                                 <div className="visual-view-more-btn-btnnns">

            //                                     <button className="createprofile meet-our-team texnonetransfor"
            //                                         onClick={() => { this.state.activeData === 5 && this.openModal() }}>
            //                                         <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? IndividualUrllogin+'/indi-login'  :
            //                                             this.state.activeData === 2 ? IndividualUrllogin+'/indi-login' :
            //                                                 this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
            //                                             {this.state.activeData === 1 ? "Create Profile" : this.state.activeData === 2 ? " View P360 Score " :
            //                                                 this.state.activeData === 3 ? "View Jobs" : this.state.activeData === 5 ? "Contact Us" :
            //                                                     "View Courses"}</a></button>
            //                                     {this.state.activeData === 2 &&
            //                                         <div className="create-your-profie">by creating your profile</div>}
            //                                 </div>
            //                             } */}
            //                             </div>
                        
            //                     </div>
            //                 </div>

            //             </div>
            <div>
                <div className="mb-style-changewrap paddtopdeskNewflows Desktopnewdesign" id="Processflow">
                <div className="col-lg-12 col-md-12 col-3 px-0 row mx-0 d-flex justify-content-center  directmbcloum ">
                    <div className="   col-md-2 col-lg-2 col-sm-2  px-0 pubjd">
                        
                        <div className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                            onClick={()=>this.visuaprofiling(3)}>
                            <div className="indu-image-blocks">
                                <img src={this.state.activeData === 3 ? Phonixfactor2024Dark  : Phoenixfactor2024Light} className="Brradius-indu"
                                />
                            </div>
                            <div>
                                <div className="skl-prod-name bold-efeect">campus program</div>

                            </div>
                        </div>
                    </div>
                    <div className="  col-md-2 col-lg-2 px-0  pubjd">
                        <a target='_blank'>
                            <div className={this.state.activeData === 1 ? "skl-productss active  Brradius-indu" : "skl-productss   Brradius-indu"}
                                onClick={()=>this.visuaprofiling(1)}
                            >
                                <div className="indu-image-blocks">
                                    <img src= {this.state.activeData === 1 ?Instsvpdark : Instsvplite} className="Brradius-indu"/>
                                </div>
                                <div>
                                    <div className="skl-prod-name bold-efeect" id='VProfile'>Institute profiling</div>
                                </div>
                           </div>
                        </a>
                    </div>
                    <div className="   col-md-2 col-lg-2   px-0">
                        <a target='_blank'>
                            <div className={this.state.activeData === 2 ? "skl-productss active  Brradius-indu" : "skl-productss Brradius-indu"}
                                onClick={()=>this.visuaprofiling(2)} >
                                    <div className="indu-image-blocks">
                                        <img src= {this.state.activeData === 2 ? Instlmsdark : instlmslite} className="Brradius-indu"
                                            // onMouseOver={e => e.currentTarget.src = P360_1}
                                            // onMouseOut={e => e.currentTarget.src = P360}
                                       />
                                    </div>
                                    <div>
                                        <div className="skl-prod-name bold-efeect">offer courses</div>
                                                   
       
                                               </div>
                                           </div>
                                       </a>
                                   </div>
                                   {/* <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
       
                                       <div className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                           onClick={()=>this.visuaprofiling(3)}>
                                           <div className="indu-image-blocks">
                                               <img src={this.state.activeData === 3 ? jobconnect_dark  : jobconnect_light} className="Brradius-indu"
                                               />
                                           </div>
                                           <div>
                                               <div className="skl-prod-name bold-efeect">Carrier connect</div>
       
                                           </div>
                                       </div>
                                   </div> */}
                                  
                                   <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
                                       <a target='_blank'>
                                           <div className={this.state.activeData === 4 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(4)}>
                                               <div className="indu-image-blocks">
                                                   <img src={this.state.activeData === 4 ? Instdashdark : Instdashlite} className="Brradius-indu"
                                                   // onMouseOver={e => e.currentTarget.src = online1}
                                                   // onMouseOut={e => e.currentTarget.src = online}
                                                   />
                                               </div>
                                               <div>
                                               <div className="skl-prod-name bold-efeect">placement Opportunities</div>
       
                                               </div>
                                           </div>
                                       </a></div>
 
                                       <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                                       <a target='_blank'>
                                           <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(5)}>
                                               <div className="indu-image-blocks">
                                                   <img src={this.state.activeData === 5 ? jobconnect_dark  : jobconnect_light} className="Brradius-indu"
                                                   />
                                               </div>
                                               <div>
                                                   <div className="skl-prod-name bold-efeect"><span className="txt-trans">AATCe</span></div>
       
                                               </div>
                                           </div>
                                       </a></div>
                                       {/* <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                                       <a target='_blank'>
                                           <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(5)}>
                                               <div className="indu-image-blocks">
                                                   <img src={this.state.activeData === 5 ? interview_dark : interview_light} className="Brradius-indu"
                                                   />
                                               </div>
                                               <div>
                                                   <div className="skl-prod-name bold-efeect">online interviews</div>
       
                                               </div>
                                           </div>
                                       </a></div> */}
                                  
                               </div>
 
                               <div className={this.state.activeData === 3 ? "col-sm-12 col-9 px-0 indu-data-containerss active fst-border" :
                                   this.state.activeData === 5 ? "col-sm-12 col-9  px-0 indu-data-containerss active lst-border" : "col-sm-12 col-9  px-0 indu-data-containerss active all-border "}>
                                   <div className="col-12 px-0 row mx-0   tabsec-center  mi-heigh-overall">
                                       <div className={ this.state.textactive==0?"col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0  align-paddd-induss py-3":"col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0 align-paddd-induss   py-3" }   >
                                           <div className="visul-porfile-head  flow-align">
                                               {this.state.activeData === 1 ? 
                                               <div className="">
                                                   {/* <div className="visual-profile-head1 ">PUBLISH</div>
                                                   <div className="visual-haed-two ">JOB DESCRIPTION</div> */}
                                                   <div className="visual-profile-head1">Create</div>
                                                     <div className="visual-haed-two ">Institution profile</div>
                                               </div> :
                                                   this.state.activeData === 2 ?
                                                       <div className="">
                                                           <div className="visual-profile-head1">Create Your</div>
                                                           <div className="visual-haed-two ">Learning Experiences</div>
                                                       </div>
                                                       : this.state.activeData === 3 ?
                                                           <div className="">
                                                               <div className="visual-profile-head1 ">Get YOUR Students</div>
                                                               <div className="visual-haed-two  ">Industry Ready</div>
                                                           </div>
                                                        //    <div className="">
                                                        //         <div className="visual-profile-head1 ">FIND YOUR</div>
                                                        //         <div className="visual-haed-two  ">perfect job</div>
                                                        //     </div>
                                                           
                                                           :
                                                           this.state.activeData === 4 ?
                                                               <>
                                                               <div className="visual-profile-head1 ">Get YOUR Students</div>
                                                                   {/* <div className="visual-profile-head1 animat ">CANDIDATE</div> */}
                                                                   <div className="visual-profile-head1 animat ">Bridging the gap between</div>
                                                                   <div className="visual-haed-two  ">Education and Employment</div>
                                                               </> 
                                                             //   :<></>
                                                              :
                                                             this.state.activeData === 5 ?
                                                                   <>
                                                                       {/* <div className="visual-profile-head1">Apple Authorized Training Center</div> */}
                                                                       <div className="visual-haed-two">Apple Authorized Training Center <span className="txt-trans">(AATCe)</span> </div>
                                                                   </>
                                                                //    <>
                                                                //    <div className="visual-profile-head1">Attend your</div>
                                                                //    <div className="visual-haed-two">online interviews</div>
                                                                //     </>
                                                             
                                                                 :  <>
                                                                       {/* <div className="visual-profile-head1">upgrade your
                                                                       </div>
                                                                       <div className="visual-haed-two">skills</div> */}
                                                                       {/* <div className="visual-profile-head1">Get your</div>
                                                                      <div className="visual-haed-two">Career counselling</div> */}
                                                                   </>
                                                                 
                                                                 // <></>
 
                                               }
                                           </div>
                                           <div className="visual-data-contentss ">
                                               {this.state.activeData === 1 ?
                                                   <div className="">
                                                      {/* Want to stand out from the crowd? Visual profiling gives you the power to express yourself. In this digital age, video profiles are undoubtedly the future. */}
                                                      {/* Visual Profiling is a revolutionary digital-n-dynamic technology tool for positioning, interpreting, profiling and managing Individual profiles across various professions, qualifications and industries. */}
                                                      <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                        <p className="mb-3">Institute profiling refers to providing information and insights about an educational institute, school, college, or an university.</p>
                        <p className="mb-3">Profiling an Institute helps create visibility and awareness among students, parents, and the community. It allows the institute to showcase its unique features, programs, and achievements, establishing its brand identity and reputation.</p>
                        <p className="mb-3">Institute profiling plays a crucial role in student recruitment. It helps potential students and parents make informed decisions by providing comprehensive information about the institute's academic offerings, admission procedures, scholarships, and placement records.</p>
                        <p className="mb-3">Institute profiling supports the growth and development of educational institutes by enhancing their visibility, reputation, and engagement with students, parents, alumni, and partners.</p>
                    </div>
                                                   </div>
                                                   :
                                                   this.state.activeData === 2 ?
                                                       <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                        <p className="mb-3">
                                                         Aims at providing educational programs or learning opportunities to individuals or groups and make courses available for enrollment or participation by students or learners.
                                                         </p>
                                                         <p className="mb-3">
                                                         This includes Curriculum Development, Course Creation, Instruction and Facilitation, Assessment and Evaluation, Enrollment and Registration, & Support Services.
                                                         </p>
                                                        <p className="mb-0">
                                                        Courses can cover a wide range of subjects, disciplines, and levels of education, from academic subjects in schools and universities to professional training programs and skill development courses. The aim is to provide structured learning experiences and opportunities for individuals to acquire knowledge, develop skills, and achieve specific learning goals.
                                                        </p>
                                                        
                                                          {/* <span className="pfscoreclr">P<span className="score360">360</span>S</span> is a powerful rating mechanism that takes into account various attributes of your Skills, Experience and Accomplishments across various categories. On a scale of <span className="score360">360</span>, your score is a weightage calculated based on your Academics, Skills, Experience and other Accomplishments.  */}
                                                          {/* A Profile 360 Score (P360S) */}
                                                          {/* <span className="pfscoreclr">P<span className="score360">360</span>S</span> is a powerful rating mechanism that is assigned to an individual's profile based on certain criteria. It is designed to provide a quick summary of the person's qualifications, skills, experience, or suitability for a job application. */}
                                                        </div>
                                                       :
                                                       this.state.activeData === 3 ?
                                                           <div className=" ">
                                                                 <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                                    {/* <ul className="ul-skillupgrading">
                                                                        <li className="mb-3"><span className="font-weight-bold">Increased job opportunities:</span> In today's competitive job market, having the latest skills and knowledge is essential for getting a good job. By upgrading your skills, you can make yourself more marketable to employers and increase your chances of getting hired.</li>
                                                                        <li className="mb-3"><span className="font-weight-bold">Higher salaries:</span> Employees with in-demand skills are often paid more than those with outdated skills. By upgrading your skills, you can increase your earning potential.</li>
                                                                        <li className="mb-3"><span className="font-weight-bold">More job satisfaction:</span> When you have the skills and knowledge to do your job well, you're more likely to be satisfied with your work. This can lead to increased productivity and a better work-life balance.</li>
                                                                        <li className="mb-3"><span className="font-weight-bold">Improved career prospects:</span> By upgrading your skills, you can open up new career opportunities for yourself. You may be able to move into a new role, start your own business, or take on more responsibility in your current role.</li> 
                                                                        <li className="mb-0"><span className="font-weight-bold">Personal development:</span> Learning new skills can help you to grow as a person. You'll gain new knowledge, develop new skills, and meet new people. This can lead to a more fulfilling and rewarding life.</li>
                                                                    </ul> */}
                                                                    <p className="mb-3">
                                                             {/* <span className="font-weight-bold">Learn new skills.</span> Learning a new skill can help you improve your job prospects and make yourself more marketable. It can also help you expand your knowledge and interests. */}
                                                             <span className="font-weight-bold">SKILLABLERS</span>(SKL) engages with Higher Education Institutions (HEI's) through a weekly Industry Awareness Newsletter (IANL) - followed by the Industry Awareness Quiz (IAQ).
                                                             </p>
                                                             <p className="mb-3">
                                                             IANL provides curated articles, case studies, and expert analyses on latest industry developments and trends - across 40+ industry verticals.
                                                             {/* <span className="font-weight-bold">Get involved in extracurricular activities.</span> Extracurricular activities are a great way to meet new people, learn new skills, and develop your interests. There are many different types of extracurricular activities available, so you can find something that you are passionate about. */}
                                                             </p>
                                                             <p className="mb-3">
                                                             IAQ complements IANL by testing students on the covered content, reinforcing, regular orientation, understanding and retention of domain-specific understanding.
                                                             {/* <span className="font-weight-bold">Soft skills:</span> Soft skills, such as communication, teamwork, and problem-solving, are also important for industry readiness. These skills can be developed through education, experience, and self-reflection. */}
                                                             </p>
                                                             <p className="mb-3">
                                                             Participation in IANL and IAQ is integrated into academic programs, equating to 1 Semester Credit for students.
                                                             {/* <span className="font-weight-bold">Get a job.</span> Getting a job can help you develop your work ethic and learn new skills. It can also help you save money for college or other expenses. */}
                                                             </p>
                                                             <p className="mb-0">
                                                             This initiative bridges the gap between academic learning and industry needs, enhancing students' readiness for the modern workforce.
                                                             {/* <span className="font-weight-bold">Get a job.</span> Getting a job can help you develop your work ethic and learn new skills. It can also help you save money for college or other expenses. */}
                                                             </p>
                                                                </div>
                                                           </div> 
                                                        //    <div className=" ">
                                                        //         Career Connect aims to align individuals' skills and interests with suitable career paths, enabling them to make informed decisions and achieve their professional goals. It recognizes the importance of continuous learning, adaptability, and staying current with industry trends to thrive in a rapidly changing job market. Ultimately, Career Connect seeks to empower individuals to build meaningful and fulfilling careers by providing them with the tools, knowledge, and connections necessary to navigate their professional journeys successfully.  
                                                        //     </div> 
                                                           :
                                                           this.state.activeData === 4 ?
                                                               <div className=" rqst-heading  text-trns-demo fntsizepopuptext ">
                                                                   <p className="mb-3">
                                                                   Educational Institutions plays a prominent role in facilitating placement opportunities to student, to secure employment or internships in their chosen field or industry.                                                                 </p>
                                                                 <p className="mb-3">
                                                                 Placement opportunities aim to bridge the gap between theoretical learning and practical application by offering individuals a chance to gain hands-on experience in a professional setting. They provide a platform for individuals to apply their knowledge, develop essential skills, and gain industry-specific insights.                                                                </p>
                                                                 <p className="mb-0">
                                                                 Placement opportunities can take various forms, including internships, apprenticeships, co-op programs, or even full-time job placements. They may be offered by a specific company or organization, or they could be facilitated through partnerships between educational institutions and industry partners.                                                                </p> 
                                                               </div> :
                                                               this.state.activeData === 5 ?
                                                                   <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                                    <p className="mb-3">
                                                                    Apple Authorized Training Center for Education <span className="txt-trans">(AATCe)</span> is a specialized educational institution offering that is certified by Apple Inc. to provide training and certification programs focused on Apple technologies. These training centers offer courses and workshops designed to enhance the skills and knowledge of educators, IT professionals, and individuals.                                                                </p>
                                                                    <p className="mb-3">
                                                                    Skillablers partnership with iPlanet, enables us to jointly offer educational institutions the opportunity to set up a dedicated Centre of Excellence Lab in campuses.                                                                </p>
                                                                    {/* <p className="mb-3">
                                                                    Apple Authorized Training Center for Education (AATCE) is a specialized educational institution offering that is certified by Apple Inc. to provide training and certification programs focused on Apple technologies. These training centers offer courses and workshops designed to enhance the skills and knowledge of educators, IT professionals, and individuals.                                                                </p> */}
                                                                    {/* Career Connect aims to align individuals' skills and interests with suitable career paths, enabling them to make informed decisions and achieve their professional goals. It recognizes the importance of continuous learning, adaptability, and staying current with industry trends to thrive in a rapidly changing job market. Ultimately, Career Connect seeks to empower individuals to build meaningful and fulfilling careers by providing them with the tools, knowledge, and connections necessary to navigate their professional journeys successfully. */}
                                                                       {/* Connect with the candidates with a one click scheduling and interviewing platform. Avoid multiple email / phone follow-ups with candidates. With real-time video feedback capture, this truly makes your overall recruitment process Comprehensive, Complete and Efficient. */}
                                                                    </div>
                                                                   :
                                                                   <div className="">
                                                                       Connect with your shortlisted candidates virtually 
                                                                       and capture real time feedback. 
                                                                       You can choose to connect 
                                                                       with candidate 1 on 1 or as a panel</div>
       
                                               }
                                           </div>
 
                                          
                                        <div className="processflowAccessbt">
                                         {this.state.activeData === 3 &&
                                             <div className="visual-view-more-btn-btnnns" onClick={() => { this.OpenRequestDemo("PhoenixFactorPolicy") }} >
                                                     <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                            </div>
                                         }
                                         {this.state.activeData === 4 &&
                                             <div className="visual-view-more-btn-btnnns " onClick={() => { this.openinsti() }}>
                                                     <button className="createprofile meet-our-team texnonetransfor"   >Get Started</button>
                                             </div>
                                         }
                                          {this.state.activeData === 5 &&
                                             <div className="visual-view-more-btn-btnnns " onClick={() => { this.OpenRequestDemo("AACTEPolicy") }}>
                                                     <button className="createprofile meet-our-team texnonetransfor"   >Enquire Now</button>
                                             </div>
                                         }
                                      
                                         {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
                                             <div className="visual-view-more-btn-btnnns">

                                                 <button className="createprofile meet-our-team texnonetransfor"
                                                     onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                     <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? Instituteurl+'/ins-login'  :
                                                        this.state.activeData === 2 ? Instituteurl+'/ins-login' :
                                                             this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                         {this.state.activeData === 1 ? "Get Started" : this.state.activeData === 2 ? " Get Started " :
                                                             this.state.activeData === 3 ? "Get Started" : this.state.activeData === 5 ? "Get Started" :
                                                                 "Get Started"}</a></button>
                                               
                                             </div>
                                         }
                                         </div>
                                           
                                       </div>

                                       <div className={this.state.textactive == 0 ? "col-sm-6 col-12 dflex-cent padding-zer imgalignment  " : "col-sm-6 col-12 dflex-cent padding-zer imgalignment  align-items-center "}>
                                         <img src={this.state.activeData === 1 ? InstSvp: this.state.activeData === 2 ?
                                           Lmscourse : this.state.activeData === 3 ? Phoenixfactor2024IAQ :
                                                 this.state.activeData === 4 ? Instdashboard :
                                                     this.state.activeData === 5 ? appleimg : upskillinggs} className="width-100per-image" />
                                     </div>

                                     <div className="processflowAccessbtMobile">
                                         {this.state.activeData === 3 &&
                                             <div className="visual-view-more-btn-btnnns" onClick={() => { this.OpenRequestDemo("PhoenixFactorPolicy") }} >
                                                     <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                            </div>
                                         }
                                         {this.state.activeData === 4 &&
                                             <div className="visual-view-more-btn-btnnns " onClick={() => { this.openinsti() }}>
                                                     <button className="createprofile meet-our-team texnonetransfor"   >Get Started</button>
                                             </div>
                                         }
                                          {this.state.activeData === 5 &&
                                             <div className="visual-view-more-btn-btnnns " onClick={() => { this.OpenRequestDemo("AACTEPolicy") }}>
                                                     <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                             </div>
                                         }
                                      
                                         {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
                                             <div className="visual-view-more-btn-btnnns">

                                                 <button className="createprofile meet-our-team texnonetransfor"
                                                     onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                     <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? Instituteurl+'/ins-login'  :
                                                        this.state.activeData === 2 ? Instituteurl+'/ins-login' :
                                                             this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                         {this.state.activeData === 1 ? "Get Started" : this.state.activeData === 2 ? " Get Started " :
                                                             this.state.activeData === 3 ? "Get Started" : this.state.activeData === 5 ? "Get Started" :
                                                                 "Get Started"}</a></button>
                                               
                                             </div>
                                         }
                                         </div>
                                       
                                   </div>
                               </div>
                               {/* {console.log()} */}

                              
                               </div>
            
                <div className="MobileviewnewDesign">
                        <div className="indiproceswrap">
                        <div class="contentWrapperProcessflow">
                            <div class="tabsWrapperProcessflow">
                                <ul class="tabs1">
                                    <li data-id="contentThree"><span className={this.state.activeData=== 3? "pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(3)}>CAMPUS</span><div className={this.state.activeData=== 3?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentOne" class="active"><span className={this.state.activeData=== 1?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(1)}>SVP</span><div className={this.state.activeData=== 1?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentTwo"><span className={this.state.activeData=== 2?"pfscoreclr fntwettext ":"pfscoreclr"} onClick={()=>this.visuaprofiling(2)}>COURSES</span><div className={this.state.activeData=== 2?"bdbottomclrnew ":""}></div></li>
                                    {/* <li data-id="contentThree"><span className={this.state.activeData=== 3? "pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(3)}>CAMPUS</span><div className={this.state.activeData=== 3?"bdbottomclrnew ":""}></div></li> */}
                                    <li data-id="contentFour"><span className={this.state.activeData=== 4?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(4)}>PLACEMENT</span><div className={this.state.activeData=== 4?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentSix"><span className={this.state.activeData=== 5?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(5)}>AACTE</span><div className={this.state.activeData=== 5?"bdbottomclrnew ":""}></div></li>
                                </ul>
                            </div> 
                        <span class="nextprocessFlow">
                            <img src={Processflowarrow}></img>
                        </span>
                        <span class="PreviousprocessFlow">
                        <img src={Processflowarrow}></img>
                        </span>

                        </div>
                        </div>
                        <div className="ProcessflowdescriptionWrap-Indi px-2">
                            <div className="visul-porfile-head  flow-align">
                                                    {this.state.activeData === 1 ? 
                                                    <div className="">
                                                            <div className="visual-profile-head1">Create</div>
                                                            <div className="visual-haed-two ">Institution profile</div>
                                                     </div> :
                                                    this.state.activeData === 2 ?
                                                    <div className="">
                                                            <div className="visual-profile-head1">Create your</div>
                                                            <div className="visual-haed-two ">Learning Experiences</div>
                                                    </div> : 
                                                    this.state.activeData === 3 ?
                                                    <div className="">
                                                            <div className="visual-profile-head1 ">Get YOUR Students</div>
                                                            <div className="visual-haed-two  ">Industry Ready</div>
                                                    </div>:
                                                    this.state.activeData === 4 ?
                                                    <>
                                                            <div className="visual-profile-head1 ">Get YOUR Students</div>
                                                            <div className="visual-haed-two  ">Bridging the gap</div>
                                                            <div className="visual-haed-two  ">between Education</div>
                                                            <div className="visual-haed-two  ">and Employment</div>
                                                    </> :
                                                    this.state.activeData === 5 ?
                                                    <>
                        
                                                            <div className="visual-haed-two">Apple Authorized Training Center <span className="txt-trans">(AATCe)</span></div>
                                                    </> :  
                                                    <>

                                                    </>
                                                    }
                                                </div>
                            </div>
                            <div className="px-2">
                                <div className={this.state.textactive == 0 ? "col-sm-6 col-12 dflex-cent padding-zer imgalignment  " : "col-sm-6 col-12 dflex-cent padding-zer imgalignment   "}>
                                        <img src={this.state.activeData === 1 ? InstSvp: this.state.activeData === 2 ?
                                           Lmscourse : this.state.activeData === 3 ? Phoenixfactor2024IAQ :
                                                 this.state.activeData === 4 ? Instdashboard :
                                                     this.state.activeData === 5 ? appleimg : upskillinggs} className="width-100per-image imagshaw-pf" />
                                </div>
                            </div>
                            <div className="textprocessflowwrap-indi px-2">
                                    <div className="visual-data-contentss ">
                                                    {this.state.activeData === 1 ?
                                                    <div className="">
                                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                            <p className="mb-3">Institute profiling refers to providing information and insights about an educational institute, school, college, or an university.</p>
                                                            <p className="mb-3">Profiling an Institute helps create visibility and awareness among students, parents, and the community. It allows the institute to showcase its unique features, programs, and achievements, establishing its brand identity and reputation.</p>
                                                            <p className="mb-3">Institute profiling plays a crucial role in student recruitment. It helps potential students and parents make informed decisions by providing comprehensive information about the institute's academic offerings, admission procedures, scholarships, and placement records.</p>
                                                            <p className="mb-3">Institute profiling supports the growth and development of educational institutes by enhancing their visibility, reputation, and engagement with students, parents, alumni, and partners.</p>
                                                        </div>
                                                    </div> :
                                                    this.state.activeData === 2 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext ">
                                                        <p className="mb-3">
                                                         Aims at providing educational programs or learning opportunities to individuals or groups and make courses available for enrollment or participation by students or learners.
                                                         </p>
                                                         <p className="mb-3">
                                                         This includes Curriculum Development, Course Creation, Instruction and Facilitation, Assessment and Evaluation, Enrollment and Registration, & Support Services.
                                                         </p>
                                                        <p className="mb-0">
                                                        Courses can cover a wide range of subjects, disciplines, and levels of education, from academic subjects in schools and universities to professional training programs and skill development courses. The aim is to provide structured learning experiences and opportunities for individuals to acquire knowledge, develop skills, and achieve specific learning goals.
                                                        </p>
                                                    </div>:
                                                    this.state.activeData === 3 ?
                                                    <div className=" ">
                                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                            {/* <p className="mb-3">
                                                             <span className="font-weight-bold">Learn new skills.</span> Learning a new skill can help you improve your job prospects and make yourself more marketable. It can also help you expand your knowledge and interests.
                                                             </p>
                                                             <p className="mb-3">
                                                             <span className="font-weight-bold">Get involved in extracurricular activities.</span> Extracurricular activities are a great way to meet new people, learn new skills, and develop your interests. There are many different types of extracurricular activities available, so you can find something that you are passionate about.
                                                             </p>
                                                             <p className="mb-3">
                                                             <span className="font-weight-bold">Soft skills:</span> Soft skills, such as communication, teamwork, and problem-solving, are also important for industry readiness. These skills can be developed through education, experience, and self-reflection.
                                                             </p>
                                                             <p className="mb-0">
                                                             <span className="font-weight-bold">Get a job.</span> Getting a job can help you develop your work ethic and learn new skills. It can also help you save money for college or other expenses.                                </p> */}
                                                            <p className="mb-3">
                                                            <span className="font-weight-bold">SKILLABLERS</span>(SKL) engages with Higher Education Institutions (HEI's) through a weekly Industry Awareness Newsletter (IANL) - followed by the Industry Awareness Quiz (IAQ).
                                                             </p>
                                                             <p className="mb-3">
                                                             IANL provides curated articles, case studies, and expert analyses on latest industry developments and trends - across 40+ industry verticals.
                                                             </p>
                                                             <p className="mb-3">
                                                             IAQ complements IANL by testing students on the covered content, reinforcing, regular orientation, understanding and retention of domain-specific understanding.
                                                             </p>
                                                             <p className="mb-3">
                                                             Participation in IANL and IAQ is integrated into academic programs, equating to 1 Semester Credit for students.
                                                            
                                                             </p>
                                                             <p className="mb-0">
                                                             This initiative bridges the gap between academic learning and industry needs, enhancing students' readiness for the modern workforce.
                                                           
                                                             </p> 
                                                        </div>
                                                    </div>:
                                                    this.state.activeData === 4 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext ">
                                                         <p className="mb-3">
                                                                   Educational Institutions plays a prominent role in facilitating placement opportunities to student, to secure employment or internships in their chosen field or industry.                                                                 </p>
                                                                 <p className="mb-3">
                                                                 Placement opportunities aim to bridge the gap between theoretical learning and practical application by offering individuals a chance to gain hands-on experience in a professional setting. They provide a platform for individuals to apply their knowledge, develop essential skills, and gain industry-specific insights.                                                                </p>
                                                                 <p className="mb-0">
                                                                 Placement opportunities can take various forms, including internships, apprenticeships, co-op programs, or even full-time job placements. They may be offered by a specific company or organization, or they could be facilitated through partnerships between educational institutions and industry partners.                                                                </p>        
                                                    </div> :
                                                    this.state.activeData === 5 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                                                  <p className="mb-3">
                                                                    Apple Authorized Training Center for Education <span className="txt-trans">(AATCe)</span> is a specialized educational institution offering that is certified by Apple Inc. to provide training and certification programs focused on Apple technologies. These training centers offer courses and workshops designed to enhance the skills and knowledge of educators, IT professionals, and individuals.                                                                </p>
                                                                    <p className="mb-3">
                                                                    Skillablers partnership with iPlanet, enables us to jointly offer educational institutions the opportunity to set up a dedicated Centre of Excellence Lab in campuses.                                                                </p>
                                                    </div>:
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                             Connect with your shortlisted candidates virtually 
                                                                       and capture real time feedback. 
                                                                       You can choose to connect 
                                                                       with candidate 1 on 1 or as a panel
                                                    </div>
                                                }
                                    </div>
                            </div>

                        <div className="textprocessflowwrap-indi px-2"> 
                            <div className="processflowAccessbtMobile  ">
                                {this.state.activeData === 3 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow">
                                        <div className="pop-imagcent" onClick={() => { this.OpenRequestDemo("PhoenixFactorPolicy") }}>
                                            <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                        </div>
                                    </div>
                                }
                                {this.state.activeData === 5 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow" onClick={() => { this.OpenRequestDemo("AACTEPolicy") }}>
                                            <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                    </div>
                                
        
                                }
                                {this.state.activeData === 4 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow" onClick={() => { this.openinsti() }}>
                                            <button className="createprofile meet-our-team texnonetransfor"  >Get Started</button>
                                    </div>
    
                                }
                                {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow">
                                            <button className="createprofile meet-our-team texnonetransfor py-2"
                                                onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? Instituteurl+'/ins-login'  :
                                                        this.state.activeData === 2 ? Instituteurl+'/ins-login' :
                                                             this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                         {this.state.activeData === 1 ? "Get Started" : this.state.activeData === 2 ? " Get Started " :
                                                             this.state.activeData === 3 ? "Get Started" : this.state.activeData === 5 ? "Get Started" :
                                                                 "Get Started"}</a>
                                            </button>
                                    </div>
                                }
                            </div>                                                  
                        </div>
        
                </div>



                <div className="rqst-demp-main">
<button className="btn rqst-demo  mobilenone" onClick={() => { this.OpenRequestDemo("DemoPolicy") }} >
Request For Demo
</button>
</div>
<div className="mobiledemologo  rqst-demologo "> 
<img src={Requestdomologo} className="" onClick={() => { this.OpenRequestDemo("DemoPolicy") }} />
</div>
 

<ToastContainer className="toastsubmit"/>
 {this.state.isReqstdemo &&
 <RequestDemo
 isFrame={false}
 Url={""}
close={this.closeRequestDemo}
Actiontype={this.state.type}
 isReqstdemo={true}
 toastMsg={this.toastMessage}
 />}
            </div>

        )
        }}
        export default Instprocessflow