
import React from 'react';
import '../../Assests/css/rippleslider.scss';

var curpage = 1;
var sliding = false;
var click = true;
var left;
var right;
var pagePrefix = "slide";
var pageShift = 500;
var transitionPrefix = "circle";
var svg = true;

function RippleSlide() {

    React.useEffect(() => {
        curpage = 1;
        sliding = false;
        click = true;
        left = document.getElementById("left");
        right = document.getElementById("right");
        pagePrefix = "slide";
        pageShift = 500;
        transitionPrefix = "circle";
        svg = true;

        left.onmousedown = () => {
            leftSlide();
            // rightSlide();
        };

        right.onmousedown = () => {
            rightSlide();
            // leftSlide();
        };

        document.onkeydown = e => {
            if (e.keyCode == 37) {
                leftSlide();
            } else if (e.keyCode == 39) {
                rightSlide();
            }
        };
    }, [])

    const leftSlide = () => {
        if (click) {
            if (curpage === 1) curpage = 5;
            sliding = true;
            curpage--;
            svg = true;
            click = false;
            for (let k = 1; k <= 3; k++) {
                var a1 = document.getElementById(pagePrefix + k);
                a1.className += " tran";
            }
            setTimeout(() => {
                move();
            }, 200);
            setTimeout(() => {
                for (let k = 1; k <= 3; k++) {
                    var a1 = document.getElementById(pagePrefix + k);
                    a1.classList.remove("tran");
                }
            }, 1400);
        }
    }

    const rightSlide = () => {
        if (click) {
            if (curpage === 4) curpage = 0;
            // console.log("woek");
            sliding = true;
            curpage++;
            svg = false;
            click = false;
            for (let k = 1; k <= 4; k++) {
                var a1 = document.getElementById(pagePrefix + k);
                a1.className += " tran";
            }
            setTimeout(() => {
                move();
            }, 200);
            setTimeout(() => {
                for (let k = 1; k <= 4; k++) {
                    var a1 = document.getElementById(pagePrefix + k);
                    a1.classList.remove("tran");
                }
            }, 1400);
        }
    }

    const move = () => {
        // console.log('sliding', sliding, svg)
        if (sliding) {
            sliding = false;

            if (svg) {
                for (let j = 1; j <= 19; j++) {
                    var c = document.getElementById(transitionPrefix + j);
                    c.classList.remove("steap");
                    c.setAttribute("class", transitionPrefix + j + " streak");
                    // console.log("streak");
                }
            } else {

                for (let j = 20; j <= 38; j++) {
                    var c = document.getElementById(transitionPrefix + j);
                    // console.log('c', c)
                    c.classList.remove("steap");
                    c.setAttribute("class", transitionPrefix + j + " streak");
                    // console.log("streak");
                }
            }
            setTimeout(() => {
                for (let i = 1; i <= 4; i++) {
                    if (i === curpage) {
                        var a = document.getElementById(pagePrefix + i);
                        a.className += " up1";
                    } else {
                        var b = document.getElementById(pagePrefix + i);
                        b.classList.remove("up1");
                    }
                }
                sliding = true;
            }, 600);
            setTimeout(() => {
                click = true;
            }, 1700);

            setTimeout(() => {
                if (svg) {
                    for (let j = 1; j <= 19; j++) {
                        var c = document.getElementById(transitionPrefix + j);
                        c.classList.remove("streak");
                        c.setAttribute("class", transitionPrefix + j + " steap");
                    }
                } else {
                    for (let j = 20; j <= 38; j++) {
                        var c = document.getElementById(transitionPrefix + j);
                        c.classList.remove("streak");
                        c.setAttribute("class", transitionPrefix + j + " steap");
                    }
                    sliding = true;
                }
            }, 850);
            setTimeout(() => {
                click = true;
            }, 1700);
        }
    }

    return (
        <body>
            <div class='parent'>
                <div class='slider'>
                    <button type="button" id='right' class='right' name="button">

                        <svg version="1.1" id="Capa_1" width='40px' height='40px ' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 477.175 477.175" xmlSpace="preserve">
                            <g>
                                <path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
          c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
          "/>
                            </g>

                        </svg>

                    </button>
                    <button type="button" id='left' class='left' name="button">
                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 477.175 477.175" xmlSpace="preserve">
                            <g>
                                <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
          c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"/>
                            </g>
                        </svg>
                    </button>
                    <svg id='svg2' class='up2' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        {window.matchMedia("(min-width: 991px)").matches ? <>
                        <circle id='circle1' class='circle1 steap' cx="3vw" cy="50%" r="3vw" stroke='red' />
                        <circle id='circle2' class='circle2 steap' cx="3vw" cy="50%" r="7vw" stroke='blue' />
                        <circle id='circle3' class='circle3 steap' cx="3vw" cy="50%" r="11vw" stroke="yellow" />
                        <circle id='circle4' class='circle4 steap' cx="3vw" cy="50%" r="15vw" stroke='green' />
                        <circle id='circle5' class='circle5 steap' cx="3vw" cy="50%" r="18vw" stroke='orange' />
                        <circle id='circle6' class='circle6 steap' cx="3vw" cy="50%" r="22vw" stroke='pink' />
                        <circle id='circle7' class='circle7 steap' cx="3vw" cy="50%" r="26.2vw" stroke='brown' />
                        <circle id='circle8' class='circle8 steap' cx="3vw" cy="50%" r="30.4vw" stroke='rose' />
                        <circle id='circle9' class='circle9 steap' cx="3vw" cy="50%" r="34.5vw" stroke='black' />
                        <circle id='circle10' class='circle10 steap' cx="3vw" cy="50%" r="38.5vw" stroke='black' />
                        <circle id='circle11' class='circle11 steap' cx="3vw" cy="50%" r="42.5vw" stroke='red' />
                        <circle id='circle12' class='circle12 steap' cx="3vw" cy="50%" r="46.2vw" stroke='blue' />
                        <circle id='circle13' class='circle13 steap' cx="3vw" cy="50%" r="49vw" stroke="yellow" />
                        <circle id='circle14' class='circle14 steap' cx="3vw" cy="50%" r="51.3vw" stroke='green' />
                        <circle id='circle15' class='circle15 steap' cx="3vw" cy="50%" r="53vw" stroke='orange' />
                        <circle id='circle16' class='circle16 steap' cx="3vw" cy="50%" r="54.5vw" stroke='pink' />
                        <circle id='circle17' class='circle17 steap' cx="3vw" cy="50%" r="55.5vw" />
                        <circle id='circle18' class='circle18 steap' cx="3vw" cy="50%" r="56vw" />
                        <circle id='circle19' class='circle19 steap' cx="3vw" cy="50%" r="57vw" />
                        </>:
                        <>
                        <circle id='circle1' class='circle1 steap'   cx="6vw" cy="50%" r="6vw" stroke='red' />
                        <circle id='circle2' class='circle2 steap'   cx="6vw" cy="50%" r="12vw" stroke='blue' />
                        <circle id='circle3' class='circle3 steap'   cx="6vw" cy="50%" r="18vw" stroke="yellow" />
                        <circle id='circle4' class='circle4 steap'   cx="6vw" cy="50%" r="24vw" stroke='green' />
                        <circle id='circle5' class='circle5 steap'   cx="6vw" cy="50%" r="30vw" stroke='orange' />
                        <circle id='circle6' class='circle6 steap'   cx="6vw" cy="50%" r="36vw" stroke='pink' />
                        <circle id='circle7' class='circle7 steap'   cx="6vw" cy="50%" r="42vw" stroke='brown' />
                        <circle id='circle8' class='circle8 steap'   cx="6vw" cy="50%" r="48vw" stroke='rose' />
                        <circle id='circle9' class='circle9 steap'   cx="6vw" cy="50%" r="54vw" stroke='black' />
                        <circle id='circle10' class='circle10 steap' cx="6vw" cy="50%" r="60vw" stroke='black' />
                        <circle id='circle11' class='circle11 steap' cx="6vw" cy="50%" r="66vw" stroke='red' />
                        <circle id='circle12' class='circle12 steap' cx="6vw" cy="50%" r="72vw" stroke='blue' />
                        <circle id='circle13' class='circle13 steap' cx="6vw" cy="50%" r="78vw" stroke="yellow" />
                        <circle id='circle14' class='circle14 steap' cx="6vw" cy="50%" r="84vw" stroke='green' />
                        <circle id='circle15' class='circle15 steap' cx="6vw" cy="50%" r="90vw" stroke='orange' />
                        <circle id='circle16' class='circle16 steap' cx="6vw" cy="50%" r="96vw" stroke='pink' />
                        <circle id='circle17' class='circle17 steap' cx="6vw" cy="50%" r="98vw" />
                        <circle id='circle18' class='circle18 steap' cx="6vw" cy="50%" r="104vw" />
                        <circle id='circle19' class='circle19 steap' cx="6vw" cy="50%" r="108vw" />
                        </>}
                    </svg>
                    <svg id='svg1' class='up2' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    {window.matchMedia("(min-width: 991px)").matches ? <>
                        <circle id='circle20' class='circle20 steap' cx="52.5vw" cy="50%" r="3vw" stroke='red' />
                        <circle id='circle21' class='circle21 steap' cx="52.5vw" cy="50%" r="7vw" stroke='blue' />
                        <circle id='circle22' class='circle22 steap' cx="52.5vw" cy="50%" r="11vw" stroke="yellow" />
                        <circle id='circle23' class='circle23 steap' cx="52.5vw" cy="50%" r="15vw" stroke='green' />
                        <circle id='circle24' class='circle24 steap' cx="52.5vw" cy="50%" r="18vw" stroke='orange' />
                        <circle id='circle25' class='circle25 steap' cx="52.5vw" cy="50%" r="22vw" stroke='pink' />
                        <circle id='circle26' class='circle26 steap' cx="52.5vw" cy="50%" r="26.2vw" stroke='brown' />
                        <circle id='circle27' class='circle27 steap' cx="52.5vw" cy="50%" r="30.4vw" stroke='rose' />
                        <circle id='circle28' class='circle28 steap' cx="52.5vw" cy="50%" r="34.5vw" stroke='black' />
                        <circle id='circle29' class='circle29 steap' cx="52.5vw" cy="50%" r="38.5vw" stroke='black' />
                        <circle id='circle30' class='circle30 steap' cx="52.5vw" cy="50%" r="42.5vw" stroke='red' />
                        <circle id='circle31' class='circle31 steap' cx="52.5vw" cy="50%" r="46.2vw" stroke='blue' />
                        <circle id='circle32' class='circle32 steap' cx="52.5vw" cy="50%" r="49vw" stroke="yellow" />
                        <circle id='circle33' class='circle33 steap' cx="52.5vw" cy="50%" r="51.3vw" stroke='green' />
                        <circle id='circle34' class='circle34 steap' cx="52.5vw" cy="50%" r="53vw" stroke='orange' />
                        <circle id='circle35' class='circle35 steap' cx="52.5vw" cy="50%" r="54.5vw" stroke='pink' />
                        <circle id='circle36' class='circle36 steap' cx="52.5vw" cy="50%" r="55.5vw" />
                        <circle id='circle37' class='circle37 steap' cx="52.5vw" cy="50%" r="56vw" />
                        <circle id='circle38' class='circle38 steap' cx="52.5vw" cy="50%" r="57vw" />
                        </>:
                        <>
                        <circle id='circle20' class='circle20 steap' cx="80vw" cy="50%" r="6vw" stroke='red' />
                        <circle id='circle21' class='circle21 steap' cx="80vw" cy="50%" r="12vw" stroke='blue' />
                        <circle id='circle22' class='circle22 steap' cx="80vw" cy="50%" r="18vw" stroke="yellow" />
                        <circle id='circle23' class='circle23 steap' cx="80vw" cy="50%" r="24vw" stroke='green' />
                        <circle id='circle24' class='circle24 steap' cx="80vw" cy="50%" r="30vw" stroke='orange' />
                        <circle id='circle25' class='circle25 steap' cx="80vw" cy="50%" r="36vw" stroke='pink' />
                        <circle id='circle26' class='circle26 steap' cx="80vw" cy="50%" r="42vw" stroke='brown' />
                        <circle id='circle27' class='circle27 steap' cx="80vw" cy="50%" r="48vw" stroke='rose' />
                        <circle id='circle28' class='circle28 steap' cx="80vw" cy="50%" r="54vw" stroke='black' />
                        <circle id='circle29' class='circle29 steap' cx="80vw" cy="50%" r="60vw" stroke='black' />
                        <circle id='circle30' class='circle30 steap' cx="80vw" cy="50%" r="66vw" stroke='red' />
                        <circle id='circle31' class='circle31 steap' cx="80vw" cy="50%" r="72vw" stroke='blue' />
                        <circle id='circle32' class='circle32 steap' cx="80vw" cy="50%" r="78vw" stroke="yellow" />
                        <circle id='circle33' class='circle33 steap' cx="80vw" cy="50%" r="84vw" stroke='green' />
                        <circle id='circle34' class='circle34 steap' cx="80vw" cy="50%" r="90vw" stroke='orange' />
                        <circle id='circle35' class='circle35 steap' cx="80vw" cy="50%" r="96vw" stroke='pink' />
                        <circle id='circle36' class='circle36 steap' cx="80vw" cy="50%" r="98vw" />
                        <circle id='circle37' class='circle37 steap' cx="80vw" cy="50%" r="104vw" />
                        <circle id='circle38' class='circle38 steap' cx="80vw" cy="50%" r="108vw" />
                        </>
                        }
                    </svg>
                    <div id='slide1' class='slide1 up1'></div>
                    <div id='slide2' class='slide2'></div>
                    <div id='slide3' class='slide3'></div>
                    <div id='slide4' class='slide4'></div>
                </div>
            </div>

        </body>
    );
}

export default RippleSlide;
