import React from "react";

import Individual_Road from '../../Assests/Images/Industry-Road.svg'
import locationicon_indust from '../../Assests/Images/locationicon_indust.svg'
import $ from 'jquery';
import { flatMap } from "lodash";

import {IndividualUrllogin} from '../../Assests/js/config';
import indulociconclrnewActive from '../../Assests/Images/indulociconclrnewActive.svg'
import Industry_Road_mobile from '../../Assests/Images/Industry_Road_mobile.svg'
import visualprofile_light from '../../Assests/Images/visualprof1newpopup.svg'
import Profile360sSVG from '../../Assests/Images/Profile360sSVG.svg'
import JD2 from '../../Assests/Images/JD 2.png';
import Interviewsheduled2 from '../../Assests/Images/Interview-sheduled 2.png';
import ShorlistedCandidate2 from '../../Assests/Images/Shorlisted-Candidate 2.png';
import VisualProfileacreen2 from '../../Assests/Images/Visual-Profile_acreen 2.png';
import IndustrySVPindu from '../../Assests/Images/IndustrySVP-indu.svg';

import { IndustryURL,IndusURL } from "../../Assests/js/config";
import candidateprofiling from '../../Assests/Images/candidateprofiling.png';
import interviewprocesslite from '../../Assests/Images/interviewprocess-lite.png';
import interviewprocessdark from '../../Assests/Images/interviewprocess-dark.png';
import shortlistlite from '../../Assests/Images/shortlist-lite.png';
import shortlistdark from '../../Assests/Images/shortlist-dark.png';
import Screeninglite from '../../Assests/Images/Screeninglite.png';
import Screeningdark from '../../Assests/Images/Screeningdark.png';
import candidateprofilinglite from '../../Assests/Images/candidateprofiling-lite.png';
import candidateprofilingdark from '../../Assests/Images/candidateprofiling-dark.png';
import publicjdlite from '../../Assests/Images/publicjd-lite.png';
import publicjddark from '../../Assests/Images/publicjd-dark.png';
// import IndustryNew from "../Industry_component/Industry_new";
import Industry from "../Industry_component/Industry";



class InduLanding extends React.Component
{
    constructor(props){
        super(props)
        this.state = {
            zoompoint:0,
            popupopen:false
        }
    }


    componentDidMount ()  { 
        window.$('html,body').animate({
            scrollTop: 0
        });
       
}
visuaprofiling=(id)=>
    {

        this.setState({
            activeData:id,
            textactive:0
        } ,()=> {
            setTimeout(()=>{
                this.setState(
                    {
                        textactive: id
                    }
                )
            },900)
            

        })
    }
    opencompany=()=>
    {
       window.open(IndusURL+'/indu-login','_blank')
    }

getdesiredpostion=(id)=>
{
   
    this.setState(
        {
            zoompoint:id,
            popupopen:(window.matchMedia("(max-width: 992px)").matches)?true:false,
        }
    )
    this.autoscroll()
  

}

closPopup=()=>
{
    this.setState(
        {
            zoompoint:0,
            popupopen:false
        }
    )
  
  
}
closeSideslide=()=>
{
    this.setState(
        {
            zoompoint:0,
        }
    )
}

autoscroll=()=>
{
    let scrolling = $("#popupdetail").offset().top
    // console.log("scrolling",scrolling)
    $('html,body').animate({
        scrollTop: scrolling
    },
        'slow');
}


render(){
        return(
        <div>
        <div className="indi-conatiner bg-roadmap d-flex mb-4" >
        
        <div className="indihomeland-subwrap" id="popupdetail">
        {/* <div class="events-headsss mt-3 pb-0">INDUSTRIES JOURNEY</div> */}
                {/* <div className="pos-iconabslocation">
                <div className="indirouteimgwrap">
                {window.matchMedia("(min-width: 992px)").matches ?
                    <img className="roadmapImg" src={Individual_Road} />
                :
                    <img src={Industry_Road_mobile } />
                }

                </div>
                       <div className="locateicon" onClick={()=>this.getdesiredpostion(1)}>
                            <div className="loc-icon-content">
                                <div className="line-text-cicle rowreverloc-icon"><div className="brdlinewithloc-visualProf-indu"></div><span className="list-ic-indu"></span><div className="bulletpointtext-locatioRight">Industry Profiling</div></div>
                                <div  onClick={()=>this.getdesiredpostion(1)} className={this.state.zoompoint===1 ? "zoominpoint":"zoomoutpoint"}><img src={this.state.zoompoint===1? indulociconclrnewActive :locationicon_indust} className="hvr-wobble-vertical"/></div>
                            </div>
                        </div>
                        <div className="locateicon2" onClick={()=>this.getdesiredpostion(2)}>
                            <div className="loc-icon-content ">
                                <div onClick={()=>this.getdesiredpostion(2)}  className={this.state.zoompoint===2 ? "zoominpoint d-flex justify-content-end":"zoomoutpoint d-flex justify-content-end"}><img src={this.state.zoompoint===2? indulociconclrnewActive :locationicon_indust} className="hvr-wobble-vertical"/></div>
                                <div className="line-text-cicle"><div className="brdlinewithloc-indu"></div><span className="list-ic-indu"></span><div className="bulletpointtext-locatio">Publish JD</div></div>
                            </div>
                        </div>
                        <div className={this.state.zoompoint ?"locateicon3-indu":"locateicon3-indu locateicon3-indu-aftr"} onClick={()=>this.getdesiredpostion(3)}>
                            <div className="loc-icon-content mob-flex-direction">
                                <div className="line-text-cicle rowreverloc-icon"><div className="brdlinewithloc-indu"></div><span className="list-ic-indu"></span><div className="bulletpointtext-locatioRight">Candidates Screening</div></div>
                                <div  onClick={()=>this.getdesiredpostion(3)} className={this.state.zoompoint===3 ? "zoominpoint":"zoomoutpoint"}><img src={this.state.zoompoint===3? indulociconclrnewActive :locationicon_indust} className="hvr-wobble-vertical"/></div>
                            </div>
                        </div>
                        <div className={this.state.zoompoint ?"locateicon4-indu":"locateicon4-indu locateicon4-indu-aftr"} onClick={()=>this.getdesiredpostion(4)}>
                            <div className="loc-icon-content">
                                <div onClick={()=>this.getdesiredpostion(4)}  className={this.state.zoompoint===4 ? "zoominpoint d-flex justify-content-end":"zoomoutpoint d-flex justify-content-end"}><img src={this.state.zoompoint===4? indulociconclrnewActive :locationicon_indust} className="hvr-wobble-vertical"/></div>
                                <div className="line-text-cicle"><div className="brdlinewithloc-indu"></div><span className="list-ic-indu"></span><div className="bulletpointtext-locatio">Shortlisted Candidates</div></div>
                            </div>
                        </div>
                        <div className={this.state.zoompoint ?"locateicon5-indu":"locateicon5-indu locateicon5-indu-aftr"} onClick={()=>this.getdesiredpostion(5)}>
                            <div className="loc-icon-content ">
                                <div className="line-text-cicle rowreverloc-icon"><div className="brdlinewithloc-indu"></div><span className="list-ic-indu"></span><div className="bulletpointtext-locatioRight">Interview Process</div></div>
                                <div  onClick={()=>this.getdesiredpostion(5)} className={this.state.zoompoint===5 ? "zoominpoint":"zoomoutpoint"}><img src={this.state.zoompoint===5? indulociconclrnewActive :locationicon_indust} className="hvr-wobble-vertical"/></div>
                            </div>
                        </div>
                        
                </div> */}
        </div>
        {/* <div id="zoompoints" className={this.state.zoompoint ?"side-map-details active":"notActive"}>
            {this.state.zoompoint===1&&
            <div className="px-3 pb-3">
                <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >Industry Profiling</div>
                <div className="pop-imagcent my-2"><img src={IndustrySVPindu}></img></div>
                <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                <p className="mb-3">
                         Industry profile serves as an introduction to the business, giving an overview of its history, mission, values, and core offerings. It helps create awareness and visibility among potential customers, partners, investors, and stakeholders.
                         </p><p className="mb-3">
                        A compelling Industry profile can also assist in attracting top talent. It showcases the company's culture, values, and growth potential, making it more appealing to potential employees who are seeking alignment with the company's mission and values.
                        </p><p className="mb-3">
                        A well-crafted Industry profile can enhance a business's visibility, credibility, and attractiveness to customers, partners, investors, and talent, ultimately contributing to its growth and success.                         </p>
                     
                </div>
            </div>
            }
             {this.state.zoompoint===2&&
                    <div className="px-3 pb-3">
                        <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >PUBLISH JOB DESCRIPTION</div>
                        <div className="pop-imagcent my-2"><img src={JD2}></img></div>
                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                        <p className="mb-3">Setting the foundation for strong talent acquisition begins with a well written and comprehensive Job Description. It is a key to answer the important question - “What does the person in this role actually do”?</p>
                        </div>
                    </div>
                    }

                    {this.state.zoompoint===3&&
                    <div className="px-3 pb-3">
                    <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >CANDIDATE SCREENING</div>
                    <div className="pop-imagcent my-2"><img src={VisualProfileacreen2}></img></div>
                    <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                    <p className="mb-3">Assess your shortlisted candidates through our screening platform, which includes multi-mode evaluation processes and virtual candidate interviews.</p>
                    </div>
                    </div>
                    }

                    {this.state.zoompoint===4&&
                    <div className="px-3 pb-3">
                        <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' >CANDIDATE SHORTLISTING</div>
                        <div className="pop-imagcent my-2"><img src={ShorlistedCandidate2}></img></div>
                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                        <p className="mb-3">
                            Narrow down the list of candidates who meet the JD requirements and have cleared initial assessments for final selection and approval process
                        </p>
                         </div>
                    </div>
                    }

                    {this.state.zoompoint===5&&
                    <div className="px-3 pb-3">
                    <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' >CANDIDATE INTERVIEW PROCESS</div>
                    <div className="pop-imagcent my-2"><img src={Interviewsheduled2}></img></div>
                    <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                    <p className="mb-3">Connect with your shortlisted candidates virtually and capture real time feedback. You can choose to connect with candidate 1 on 1 or as a panel</p>
                     </div>
                </div>
                    }
        </div> */}
        {/* //Detailed view popup  */}
        {/* {this.state.popupopen &&
        <div >
            <div className="detailsviewpopmainwrapper" >
            <div className='modal-overlay'></div> 
            <div class={this.state.popupopen ? "detailedview-popup " :"detailedviewpopupclose"}>
            <div className='modal-close close-popdemo' onClick={this.closPopup} >
                    <i class="fa fa-times clr-close-rqst-demo" aria-hidden="true"></i></div>
                      
                    {this.state.zoompoint===1&&
                     <div className="px-3 pb-3">
                     <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >Industry Profiling</div>
                     <div className="pop-imagcent my-2"><img src={IndustrySVPindu}></img></div>
                     <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                         <p className="mb-3">
                         Industry profile serves as an introduction to the business, giving an overview of its history, mission, values, and core offerings. It helps create awareness and visibility among potential customers, partners, investors, and stakeholders.
                         </p><p className="mb-3">
                        A compelling Industry profile can also assist in attracting top talent. It showcases the company's culture, values, and growth potential, making it more appealing to potential employees who are seeking alignment with the company's mission and values.
                        </p><p className="mb-3">
                        A well-crafted Industry profile can enhance a business's visibility, credibility, and attractiveness to customers, partners, investors, and talent, ultimately contributing to its growth and success.                         </p>
                     </div>
                 </div>
                    }

                    {this.state.zoompoint===2&&
                    <div className="px-3 pb-3">
                    <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >PUBLISH JOB DESCRIPTION</div>
                    <div className="pop-imagcent my-2"><img src={JD2}></img></div>
                    <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                    <p className="mb-3">Setting the foundation for strong talent acquisition begins with a well written and comprehensive Job Description. It is a key to answer the important question - “What does the person in this role actually do”?</p>
                    </div>
                    </div>
                    }

                    {this.state.zoompoint===3&&
                    <div className="px-3 pb-3">
                        <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' >CANDIDATE SHORTLISTING</div>
                        <div className="pop-imagcent my-2"><img src={ShorlistedCandidate2}></img></div>
                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                        <p className="mb-3">
                            Narrow down the list of candidates who meet the JD requirements and have cleared initial assessments for final selection and approval process
                        </p>
                        </div>
                    </div>
                    }

                    {this.state.zoompoint===4&&
                    <div className="px-3 pb-3">
                    <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' >CANDIDATE SHORTLISTING</div>
                    <div className="pop-imagcent my-2"><img src={ShorlistedCandidate2}></img></div>
                    <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                    <p className="mb-3">
                        Narrow down the list of candidates who meet the JD requirements and have cleared initial assessments for final selection and approval process
                    </p>
                     </div>
                    </div>
                    }

                    {this.state.zoompoint===5&&
                   <div className="px-3 pb-3">
                   <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' >CANDIDATE INTERVIEW PROCESS</div>
                   <div className="pop-imagcent my-2"><img src={Interviewsheduled2}></img></div>
                   <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                   <p className="mb-3">Connect with your shortlisted candidates virtually and capture real time feedback. You can choose to connect with candidate 1 on 1 or as a panel</p>
                    </div>
               </div>
                    }
               
                    </div>
            </div>
        </div>} */}
        {/* //Detailed view popup  */}
        
     

        </div>
        <Industry/>

</div>

       
         )
    }
}
export default InduLanding