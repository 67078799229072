import axios from "axios";
import {APIUrl} from '../../Assests/js/config';
export function SaveEnquiryDetailsAspireEngage(CompanyName,firstname,lastname,EmailId,mobile,college,course,Type,Description,Address,website,Pincode,PincodeId,city,StudentCount,Designation,DataConsent) {
   
    return axios({
        method: 'POST',
        url: APIUrl + "/api/SaveEnquiryDetailsAspireEngage",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        data: {
            CompanyName
            ,firstname
            ,lastname
            ,EmailId
            ,mobile
            ,college
            ,course
            ,Type
            ,Description
            ,Address
            ,website
            ,Pincode
            ,PincodeId
            ,city
            ,StudentCount
            ,Designation
            ,DataConsent
        }
    })
    .then(result => {
        return result;
    }).catch(e => {
        console.log(e);
    });
}



export function GetAllSectorList(SectorId) {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/GetAllSectorList",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }),
        data: {
            SectorId
        }
    })
    .then(result => {
        return result.data;
    }).catch(e => {
        console.log(e);
    });
}
export function GetAllInstitutionCategoryByEvent(EventId, InputValue, category) {
    return axios({
        method: 'POST',
        url:APIUrl + "/api/GetAllInstitutionCategoryByEvent",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        data: {
            EventId:EventId,
            InputValue:InputValue,
            category:category
        }
    })
    .then(result => {
        let res = result.data;
        let CollegeList = res.recordset;
        return CollegeList;
    }).catch(e => {
        console.log(e);
    });
}

export function GetcityListBycountryAndCity(CountryId,StateId,City,Operation) {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/GetStateCityDataByIdForYCC",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }),
        data: {
            CountryId
            ,StateId
            ,City
            ,Operation
        }
    })
    .then(result => {
        return result.data;
    }).catch(e => {
        console.log(e);
    });
}
export function GetPincodeInfo(Pincode) {
    return axios({
        method: 'POST',
        url: APIUrl + '/api/GetPincodeInfo',
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }),
        data: {
            Pincode
        }
    })
        .then(result => {
            let res = result.data;
            let PincodeList = res.recordsets[0];
            return PincodeList;
        }).catch(e => {
            console.log(e);
        });
}