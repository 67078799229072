import axios from "axios";
import { APIUrl } from '../../Assests/js/config';

// export function GetAllCollegeList(InputValue) {

//     return axios({
//         method: 'POST',
//         url: "https://i1testapi.skillablers.com/api/GetAllCollege",
//         withCredentials: true,
//         headers: new Headers({
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
//         }),
//         data: {
//             InputValue
//         }
//     })

//     .then(result => {
//         //console.log('res-->',result.data)
//         return result.data;
//     }).catch(e => {
//         console.log('err-->',e);
//     });
// }


export function GetAllCollege(InputValue, University = '') 
{ return axios({ method: 'POST', url:"https://i1testapi.skillablers.com/api/GetAllCollege",
 withCredentials: true, headers: 
 new Headers({ 'Accept': 'application/json', 'Content-Type': 'application/json',
  'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }), data: { InputValue } }).
  then(result => { let res = result.data; let CollegeList = res.recordsets[0]; if (University !== '') 
  { return CollegeList.filter(i => i.MST_University_Id === parseInt(University) || i.MST_University_Id === 0) } 
  else { return CollegeList; } }).catch(e => { console.log(e); }); }

  export function saveContact(firstname, lastname, EmailId, mobile, college, course, Type) {
    return axios({
        method: 'POST',
        url: APIUrl + '/api/saveContactDetails',
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }),
        data: {firstname, lastname, EmailId, mobile, college, course, Type}
    })
    .then(result => {
        return result.data;
    }).catch(e => {
        // console.log(e);
    });
}