import React from 'react';
import { NavLink , BrowserRouter } from "react-router-dom";
import { HashRouter , Link } from 'react-router-dom';
import { Routing } from "../../Assests/js/routing";import $ from 'jquery';
// import HomeVideo21 from '../../Assests/Videos/Home_Video21.mp4';3
// import laptopopening3 from '../../Assests/Videos/laptopopening3.mp4';
// import laptopopening5 from '../../Assests/Videos/laptop_opening_5.mp4';
// import Layer10 from '../../Assests/Images/Layer 10.png';
// import Layer11 from '../../Assests/Images/Layer 11.png';
// import Requirement from '../../Assests/Images/requirements.png'
// import shadegirl from '../../Assests/Images/shadegirl.png';
// import yc from '../../Assests/Images/yc.png';
// import shadeboy from '../../Assests/Images/shadeboy.png';
// import video4 from '../../Assests/Images/video4.jpg';
// import video5 from '../../Assests/Images/video5.jpg';
// import woman from '../../Assests/Images/woman.png';

import bengaluru from '../../Assests/Images/bengaluru.png';
import youth from '../../Assests/Images/youth.png';
import UTIndiaNextStimulus  from '../../Assests/Images/UTIndiaNextStimulus.png';
import sight from '../../Assests/Images/sight.png';




 

class Individual extends React.Component
{

    componentDidMount(){

        
        // window.onload = function () {
             window.$('html,body').animate({
                scrollTop: 0
            });
            document.getElementsByClassName('skltext')[0].classList.add('active')
            document.getElementsByClassName('row1')[0].classList.add('active')
            document.getElementsByClassName('row2')[0].classList.add('active')
            // document.getElementsByClassName('row3')[0].classList.add('active')
            document.getElementsByClassName('images')[0].classList.add('active')
            setTimeout(()=>{
                document.getElementById("job1").style.transition = "all 0.8s ease"
                document.getElementById("job2").style.transition = "all 0.8s ease"
            },2500)
        // }
    

        $(window).on('scroll', e => {
            $('.individualBlockIdent').each(function () {
                if ($(this)[0].id === "visulprofile") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                        document.getElementById('visulprofile').classList.add('active')
                        setTimeout(()=>{
                          document.getElementsByClassName('lapview-btn')[0].style.transition = 'all 0.5s ease '
                        },1000)
                    }
                }
                else if ($(this)[0].id === "perfectJobs") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                        document.getElementById('perfectJobs').classList.add('active')
                        setTimeout(()=>{
                          document.getElementsByClassName('lapview-btn')[1].style.transition = 'all 0.5s ease '
                        },1000)
                    }
                }
                else if ($(this)[0].id === "upgradeSkill") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                        document.getElementById('upgradeSkill').classList.add('active')
                        setTimeout(()=>{
                          document.getElementsByClassName('lapview-btn')[2].style.transition = 'all 0.5s ease '
                        },1000)
                    }
                }
                else if ($(this)[0].id === "videoesss") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 40 / 100) < $(window).scrollTop()) {
                        document.getElementById('videoesss').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "Events") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                        document.getElementById('Events').classList.add('active')
                    }
                }
            })
        })


     
    }

            


   
   
          
    YoutubeWindow= (id)=>{
        console.log("youtube")
       if(id === 1){
           window.open("https://www.youtube.com/watch?v=gDrFvRw5dSU&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw")
       }
       else if(id === 2){
           window.open("https://www.youtube.com/watch?v=yrg085gq6rU&list=PLtpsAZJjrAoRGErHcZKH3YbL7qtHM4pQO&index=3")
       }
       else if(id === 3){
           window.open("https://www.youtube.com/watch?v=DxM3qrH9JWs&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw&index=5")
       }
       else if(id === 4){
           window.open("https://www.youtube.com/watch?v=aT7Cl5nhzJw")
       }
       else if(id === 5){
           window.open("https://www.youtube.com/watch?v=yzvDn4gttKk")
       }
       else if(id === 6){
           window.open("https://www.youtube.com/watch?v=uybwVo3sfyA&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw&index=13")
       }
   }


 openIndiasight=()=>{
    window.open("https://events.skillablers.com/indiainsight",'_blank')
}
openYCC=()=>{
    window.open("https://events.skillablers.com/events/ycc2021",'_blank')
}
UltraTech=()=>{
    window.open("https://events.skillablers.com/ultratechstimulus",'_blank')
}
OpenBioQuiz=()=>{
    window.open('https://events.skillablers.com/nanotechquiz2022','_blank')
}
openRegisteration=()=>{
    window.open("https://inst.skillablers.com/ins-registration",'_blank')
}

openSignup=()=>{
    window.open("https://indi.skillablers.com/indi-registration", '_blank')
}
 
openCourse=()=>{
    window.open("https://mots.skillablers.com/#/" , '_blank')
}








    render(){
        return(
            
            <div class="indi-conatiner">
 
            <div class="pain threeiConatinerr-mainss" style={{backgroundColor: 'white'}}>
            <div class="container  individualBlockIdent" id="contain">
                <div class="row" id="secflx1">
                    <div class="col-lg-6 col-xl-6 col-md-12 col-sm-6 col-12" id="seccol2">
                        <div class="skltext">ENABLING</div>
                        <div class="row1">INDIVIDUALS</div>
                        <div class="row2">
                            A Patented Technology Ecosystem, that allows you to GET NOTICED through Visual
                            profiling, addressing skill gaps with Industry Readiness programs and
                            finding the right Career Opportunities
                        </div>
                       
                        <div class="images">
                            <div class="col">
                                <div class="fstimageconatiner">
                                <HashRouter>
                                <NavLink  to={Routing.Job}>
                                {/* <img src={Layer10} alt="" id="job1"/> */}
                                </NavLink>
                                </HashRouter>
                            </div>
                            <div class="secimageConatiner">
                                {/* <img src={Layer11} alt="" id="job2" onClick={this.openCourse}/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-12 col-sm-6 " id="scecol1">
                        
                        <video  autoPlay loop muted id="finalvideo1" >
                      {/* <source src={HomeVideo21} type="video/mp4"></source> */}
                    </video>
                    </div>
                </div>
            </div>
                 
            </div>
            {/* </div> */}
            <section id="center1">

<div class="lap-maingrandparent individualBlockIdent " id="visulprofile">
    <div class="lap-parent">
        <div class="lap">
          
              <video  autoPlay  muted id="finalvideo1" >
                      {/* <source src={laptopopening3} type="video/mp4"></source> */}
                    </video>
        </div>

        <div class="lap-text">
            <p class="create-text">CREATE YOUR</p>
            <p class="visual-text">VISUAL PROFILE</p>
            <p class="visual-paraone">Want to Stand out from the Crowd? Visual profiling </p>
            <p class="visual-paraone ">gives you the power to express yourself. In this digital </p>
            <p class="visual-paraone"> age, video profiles are undoubtedly the future. </p>
            <button class="lapview-btn custombutton" onClick={this.openSignup}>GET STARTED</button>
        </div>

    </div>
</div>


<div class="findyourjob-grandparent individualBlockIdent" id="perfectJobs">
    <div class="findyourjob-parent">
        <div class="findyourjob-text">
            <p class="create-text">FIND YOUR</p>
            <p class="visual-text">PERFECT JOB</p>
            <p class="visual-paraone">Got the Right Skills? Embark on a career journey </p>
            <p class="visual-paraone"> that allows you to Learn, Grow and accelerate!</p>
            <button class="lapview-btn custombutton" onClick={this.OpenJobs}>GET STARTED</button>
        </div>
        <div class="findyourjob-img">
            {/* <img src={Requirement} class="requ-imgeasectionnss" alt=""/> */}
        </div>
    </div>
</div>


<div class="lap-maingrandparent individualBlockIdent" id="upgradeSkill">
    <div class="lap-parent width-72perce ">
        <div class="lap-two">
            
             <video  autoPlay loop muted id="finalvideo1" >
                      {/* <source src={laptopopening5} type="video/mp4"></source> */}
                    </video>
        </div>
        <div class="lap-text">
            <p class="create-text">UPGRADE YOUR</p>
            <p class="visual-text">SKILLS</p>
            <p class="visual-paraone">Learning is a continuous process! keep yourself relevant </p>
            <p class="visual-paraone"> through our industry readiness programs that helps bridge </p>
            <p class="visual-paraone"> your skills and employability gaps.</p>

            <button class="lapview-btn custombutton" onClick={this.openCourse}>VIEW MORE</button>
        </div>
    </div>
</div>



</section>
            <div class="section" id="sec">
                <div class="main1 individualBlockIdent" id="videoesss">
                    <div class="m1">
                        
                         <div class="events-headsss individualBlockIdent active" id="eventhead">VIDEOS</div>
    
                        <div class="row" id="r2">
                            <div class="col-lg-4 col-6 pos-realtive">
                                {/* <img src={shadegirl} alt="" id="vid1"/> */}
                            
                            <i class="fa fa-play-circle play-iocnss" aria-hidden="true" onClick={()=>this.YoutubeWindow(1)}></i>
                        </div>
                            <div class="col-lg-4 col-6 pos-realtive">
                                {/* <img src={yc} alt="" id="vid2"/> */}
                                <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(2)}></i>
                                </div>
                            <div class="col-lg-4  pos-realtive" > 
                            {/* <img src={shadeboy} alt="" id="vid3"/> */}
                                <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(3)} ></i></div>
                        </div>
                        <div class="row" id="r3">
                            <div class="col-lg-4 col-6 pos-realtive">
                                {/* <img src={video4} alt="" id="vid4"/> */}
                                <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(4)} ></i></div>
                            <div class="col-lg-4 col-6 pos-realtive">
                                {/* <img src={video5} alt="" id="vid5"/> */}
                                <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(5)}></i></div>
                            <div class="col-lg-4 pos-realtive">
                                {/* <img src={woman} alt="" id="vid6"/> */}
                                <i class="fa fa-play-circle play-iocnss" aria-hidden="true"  onClick={()=>this.YoutubeWindow(6)} ></i></div>
                        </div>
                        <div class="row" id="r4">
                            <button id="bttn1"><a href="https://www.youtube.com/channel/UCZx7OXHcq8YOl_-95SFZAaA"  target="_blank">View
                                    All</a></button>
                        </div>
    
                    </div>
                </div>
                <div class="main2 individualBlockIdent" id="Events">
                    <div class="m2 ">
                      
                         <div class="events-headsss individualBlockIdent active" id="eventhead">EVENTS</div>
                        <div class="row" id="r6">
                            <div class="col-lg-3 col-6">
                                <img src={bengaluru} alt="" id="image1" class="cursor-pointer" onClick={this.OpenBioQuiz}/>
                                    
                                </div>
                            <div class="col-lg-3 col-6">
                                <img src={youth} alt="" id="image2"  class="cursor-pointer" onClick={this.openYCC}/>
    
                                </div>
                            <div class="col-lg-3 col-6">
                                 <img src={UTIndiaNextStimulus} alt=""
                                    id="image3"  class="cursor-pointer" onClick={this.UltraTech}/>
    
                                    </div>
                            <div class="col-lg-3 col-6"> 
                            <img src={sight} alt="" id="image4"  class="cursor-pointer" onClick={this.openIndiasight}/>
    
                            </div>
                            <div class="row" id="r7">
                                <button id="bttn2">
                                    <a href="https://events.skillablers.com/"  target="_blank">View All</a></button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>


 </div>




         
                
       
         )
    }
}
export default Individual
 