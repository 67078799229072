import React from "react";
import { Routing } from '../../Assests/js/routing';

import Inst_Road from '../../Assests/Images/Institute-Road.svg';
import locationicon_indiv from '../../Assests/Images/locationicon-inst.svg';
import $ from 'jquery';
import { flatMap } from "lodash";

import ProcessFlow from "../Indivi_jobs_component/processflow";
import {IndividualUrllogin} from '../../Assests/js/config';
import indilociconclrnewActive from '../../Assests/Images/instlociconclrnew.svg';
import Inst_Road_mobile from '../../Assests/Images/Inst-roadmaop-mobile.svg';
import visualprofile_light from '../../Assests/Images/visualprof1newpopup.svg';
import Profile360sSVG from '../../Assests/Images/Profile360sSVG.svg';
import c360light from '../../Assests/Images/connect360-light.png';

import Instprocessflow from "../Indivi_jobs_component/Instituteprocessflow"

import Instdashboard from '../../Assests/Images/Instdashboard.png'
import Lmscourse from '../../Assests/Images/Lmscourse.png'
import InstSvp from '../../Assests/Images/InstSvp.png'
import Phonixpng from '../../Assests/Images/Phonixpng.png'
import upskillinggs from '../../Assests/Images/upskillinggs.png';





class InstLanding extends React.Component
{
    constructor(props){
        super(props)
        this.state = {
            zoompoint:0,
            popupopen:false
        }
    }


    componentDidMount ()  { 
       
        window.$('html,body').animate({
            scrollTop: 0
        });
}


getdesiredpostion=(id)=>
{
   
    this.setState(
        {
            zoompoint:id,
            popupopen:(window.matchMedia("(max-width: 992px)").matches)?true:false,
        }
    )
    this.autoscroll()
  

}

closPopup=()=>
{
    this.setState(
        {
            zoompoint:0,
            popupopen:false
        }
    )
  
  
}
closeSideslide=()=>
{
    this.setState(
        {
            zoompoint:0,
        }
    )
}

autoscroll=()=>
{
    let scrolling = $("#popupdetail").offset().top
    // console.log("scrolling",scrolling)
    $('html,body').animate({
        scrollTop: scrolling
    },
        'slow');
}


render(){
        return(
        <div>
            <div className="indi-conatiner bg-roadmap d-flex mb-3" >
            
            <div className="indihomeland-subwrap" id="popupdetail">
            {/* <div class="events-headsss mt-3 pb-0">INSTITUTIONS JOURNEY</div> */}
            {/* <div className="pos-iconabslocation">
            <div className="indirouteimgwrap">
            {window.matchMedia("(min-width: 992px)").matches ?
                <img className="roadmapImg" src={Inst_Road} />
            :
                <img src={Inst_Road_mobile } />
            }
            </div>
                    <div className= {this.state.zoompoint?"locateicon2 locateicon2-inst": " locateicon2 locateicon2-inst-aftr"} onClick={()=>this.getdesiredpostion(2)}>
                        <div className="loc-icon-content ">
                            <div onClick={()=>this.getdesiredpostion(2)}  className={this.state.zoompoint===2 ? "zoominpoint d-flex justify-content-end":"zoomoutpoint d-flex justify-content-end"}><img src={this.state.zoompoint===2? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                            <div className="line-text-cicle"><div className={this.state.zoompoint===2 ?"brdlinewithloc-inst" :"brdlinewithloc-inst brdlinewithloc-inst-wid-10"}></div><span className="list-ic-inst"></span><div className="bulletpointtext-locatio">Institute Profiling</div></div>
                        </div>
                    </div>
                    <div className="locateicon3-inst" onClick={()=>this.getdesiredpostion(3)}>
                        <div className="loc-icon-content mob-flex-direction">
                            <div className="line-text-cicle rowreverloc-icon"><div className="brdlinewithloc-inst"></div><span className="list-ic-inst"></span><div className="bulletpointtext-locatioRight">Offer Courses</div></div>
                            <div  onClick={()=>this.getdesiredpostion(3)} className={this.state.zoompoint===3 ? "zoominpoint":"zoomoutpoint"}><img src={this.state.zoompoint===3? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                        </div>
                    </div>
                    <div className={this.state.zoompoint?"locateicon4": " locateicon4 locateicon4-aftr"} onClick={()=>this.getdesiredpostion(4)}>
                        <div className="loc-icon-content">
                            <div onClick={()=>this.getdesiredpostion(4)}  className={this.state.zoompoint===4 ? "zoominpoint d-flex justify-content-end":"zoomoutpoint d-flex justify-content-end"}><img src={this.state.zoompoint===4? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                            <div className="line-text-cicle"><div className="brdlinewithloc-inst"></div><span className="list-ic-inst"></span><div className="bulletpointtext-locatio">Industry Ready</div></div>
                        </div>
                    </div>
                    <div className= {this.state.zoompoint?"locateicon5": " locateicon5 locateicon5-aftr"} onClick={()=>this.getdesiredpostion(5)}>
                        <div className="loc-icon-content ">
                            <div className="line-text-cicle rowreverloc-icon"><div className="brdlinewithloc-inst"></div><span className="list-ic-inst"></span><div className="bulletpointtext-locatioRight">Placement Assistance</div></div>
                            <div  onClick={()=>this.getdesiredpostion(5)} className={this.state.zoompoint===5 ? "zoominpoint":"zoomoutpoint"}><img src={this.state.zoompoint===5? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                        </div>
                    </div>
                    
            </div> */}
            </div>
            {/* <div id="zoompoints" className={this.state.zoompoint ?"side-map-details active":"notActive"}>
                {this.state.zoompoint===1&&
                <div className="px-3 pb-3">
                    <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >What is Visual Profiling ?</div>
                    <div className="pop-imagcent"><img src={visualprofile_light}></img></div>
                    <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                        <p className="mb-3">For one's resume to really shine, we need to think carefully about how it's designed as well as what's written. Visual Profiling tool helps you do it.</p>
                        <p className="mb-3">Visual Profiling is a revolutionary digital-n-dynamic technology tool for positioning, interpreting, profiling and managing Individual profiles across various professions, qualifications and industries.</p>
                        <p className="mb-3">With the best use of UX-n-UI, one can express oneself effectively-n-efficiently. This is the best way to get noticed and with least clutter reach out to the world with one's own credibility, capabilities, skills and aspirations.</p>
                        <p className="mb-2">The powerful interpretation of one's own profile, breaks the monotony of the written word and instead communicates visually to express better, clearly and with specificity with our evolving thoughts.</p>
                    </div>
                    <div className="pop-imagcent">
                        <button id="viewall-butn" className="meet-our-team">
                            <a className="no-text-dec" href={IndividualUrllogin+'/indi-login'}>Create Profile</a>
                        </button>
                    </div>
                </div>
                }
                {this.state.zoompoint===2&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >
                            Create Your Institution Profile 
                            </div>
                            <div className="pop-imagcent"><img src={InstSvp}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                                <p className="mb-3">
                                    Institute profiling refers to providing information and insights about an educational institute, school, college, or an university.
                                </p>
                                <p className="mb-3">
                                    Profiling an Institute helps create visibility and awareness among students, parents, and the community. It allows the institute to showcase its unique features, programs, and achievements, establishing its brand identity and reputation.
                                </p>
                                <p className="mb-3">
                                    By highlighting the institute's strengths, such as its faculty, infrastructure, academic programs, extracurricular activities, and student support services, profiling helps differentiate the institute from its competitors. This can attract potential students and parents who align with the institute's offerings and values.
                                </p>
                                <p className="mb-3">
                                    Institute profiling plays a crucial role in student recruitment. It helps potential students and parents make informed decisions by providing comprehensive information about the institute's academic offerings, admission procedures, scholarships, and placement records.
                                </p>
                                <p className="mb-3">
                                    Institute profiling supports the growth and development of educational institutes by enhancing their visibility, reputation, and engagement with students, parents, alumni, and partners.                            
                                </p>
                            </div>
                            <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team">
                                <a className="no-text-dec" href={IndividualUrllogin+'/indi-login'} >Sign in</a>
                                </button></div>
                        </div>
                        }

                        {this.state.zoompoint===3&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >Create Your Learning Experiences</div>
                            <div className="pop-imagcent"><img src={Lmscourse}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <p className="mb-3">
                                Aims at providing educational programs or learning opportunities to individuals or groups and make courses available for enrollment or participation by students or learners.
                                </p>
                                <p className="mb-3">
                                This includes Curriculum Development, Course Creation, Instruction and Facilitation, Assessment and Evaluation, Enrollment and Registration, & Support Services.
                                </p>
                                <p className="mb-3">
                                Courses can cover a wide range of subjects, disciplines, and levels of education, from academic subjects in schools and universities to professional training programs and skill development courses. The aim is to provide structured learning experiences and opportunities for individuals to acquire knowledge, develop skills, and achieve specific learning goals.
                                </p>
                            </div>
                           
                        </div>
                        }

                        {this.state.zoompoint===4&&
                        <div className="px-3 pb-3">
                        <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' >
                            Get Your Students Industry ready
                        </div>
                        <div className="pop-imagcent"><img src={Phonixpng}></img></div>
                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                            <p className="mb-3">
                            <span className="font-weight-bold">Learn new skills.</span> Learning a new skill can help you improve your job prospects and make yourself more marketable. It can also help you expand your knowledge and interests.
                            </p>
                            <p className="mb-3">
                            <span className="font-weight-bold">Get involved in extracurricular activities.</span> Extracurricular activities are a great way to meet new people, learn new skills, and develop your interests. There are many different types of extracurricular activities available, so you can find something that you are passionate about.
                            </p>
                            <p className="mb-3">
                            <span className="font-weight-bold">Soft skills:</span> Soft skills, such as communication, teamwork, and problem-solving, are also important for industry readiness. These skills can be developed through education, experience, and self-reflection.
                            </p>
                            <p className="mb-3">
                            <span className="font-weight-bold">Get a job.</span> Getting a job can help you develop your work ethic and learn new skills. It can also help you save money for college or other expenses.                                </p>
                        </div>
                    </div>
                        }
                        {this.state.zoompoint===5&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >
                            Bridging gap between Education and Employment
                            </div>
                            <div className="pop-imagcent"><img src={Instdashboard}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                                <p className="mb-3">
                                    Placement assistance from an institute refers to the support and guidance provided to students or learners in their efforts to secure employment or internships in their respective fields of study or interest. It is a service offered by educational institutions to help bridge the gap between education and employment.
                                </p>
                                <p className="mb-3">
                                    This typically includes Job Search Support, Career Counselling, Industry Connections, Interview Preparation, & Conducting Job Fairs, Career Expos or networking events which help the students in interacting with potential employers and get a better understanding of the job opportunities.
                                </p>
                                <p className="mb-3">
                                    The goal of placement assistance is to enhance the employability of students and help them secure suitable employment or internships that align with their career goals and educational background. It can greatly benefit students by providing them with the necessary tools, resources, and support to navigate the job market and transition into their chosen careers.
                                </p>
                            </div>
                            
                        </div>
                        }

                        
            </div> */}
            {/* //Detailed view popup  */}
            {/* {this.state.popupopen &&
            <div >
                <div className="detailsviewpopmainwrapper" >
                <div className='modal-overlay'></div> 
                <div class={this.state.zoompoint===5? "detailedview-popupRightMove " :"detailedview-popup"}>
                <div className='modal-close close-popdemo' onClick={this.closPopup} >
                        <i class="fa fa-times clr-close-rqst-demo" aria-hidden="true"></i></div>
                        
                        {this.state.zoompoint===1&&

                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >What is Visual Profiling ?</div>
                            <div className="pop-imagcent"><img src={visualprofile_light}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <p className="mb-3">For one's resume to really shine, we need to think carefully about how it's designed as well as what's written. Visual Profiling tool helps you do it.</p>
                                <p className="mb-3">Visual Profiling is a revolutionary digital-n-dynamic technology tool for positioning, interpreting, profiling and managing Individual profiles across various professions, qualifications and industries.</p>
                                <p className="mb-3">With the best use of UX-n-UI, one can express oneself effectively-n-efficiently. This is the best way to get noticed and with least clutter reach out to the world with one's own credibility, capabilities, skills and aspirations.</p>
                                <p className="mb-3">The powerful interpretation of one's own profile, breaks the monotony of the written word and instead communicates visually to express better, clearly and with specificity with our evolving thoughts.</p>
                            </div>
                            <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={IndividualUrllogin+'/indi-login'}>Create Profile</a></button></div>
                        </div>
                        }

                        {this.state.zoompoint===2&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >Create Your Institution Profile </div>
                            <div className="pop-imagcent"><img src={InstSvp}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                                <p className="mb-3">
                                    Institute profiling refers to providing information and insights about an educational institute, school, college, or an university.
                                </p>
                                <p className="mb-3">
                                    Profiling an Institute helps create visibility and awareness among students, parents, and the community. It allows the institute to showcase its unique features, programs, and achievements, establishing its brand identity and reputation.
                                </p>
                                <p className="mb-3">
                                    By highlighting the institute's strengths, such as its faculty, infrastructure, academic programs, extracurricular activities, and student support services, profiling helps differentiate the institute from its competitors. This can attract potential students and parents who align with the institute's offerings and values.
                                </p>
                                <p className="mb-3">
                                    Institute profiling plays a crucial role in student recruitment. It helps potential students and parents make informed decisions by providing comprehensive information about the institute's academic offerings, admission procedures, scholarships, and placement records.
                                </p>
                                <p className="mb-3">
                                    Institute profiling supports the growth and development of educational institutes by enhancing their visibility, reputation, and engagement with students, parents, alumni, and partners.                            
                                </p>
                            </div>
                            <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team">
                                <a className="no-text-dec" href={IndividualUrllogin+'/indi-login'} >Sign in</a>
                                </button></div>
                        </div>
                        }

                        {this.state.zoompoint===3&&
                         <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >Create Your Learning Experiences</div>
                            <div className="pop-imagcent"><img src={Lmscourse}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <p className="mb-3">
                                Aims at providing educational programs or learning opportunities to individuals or groups and make courses available for enrollment or participation by students or learners.
                                </p>
                                <p className="mb-3">
                                This includes Curriculum Development, Course Creation, Instruction and Facilitation, Assessment and Evaluation, Enrollment and Registration, & Support Services.
                                </p>
                                <p className="mb-3">
                                Courses can cover a wide range of subjects, disciplines, and levels of education, from academic subjects in schools and universities to professional training programs and skill development courses. The aim is to provide structured learning experiences and opportunities for individuals to acquire knowledge, develop skills, and achieve specific learning goals.
                                </p>
                            </div>                            </div>
                        </div>
                        }

                        {this.state.zoompoint===4&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' >
                                Get Your Students Industry ready
                            </div>
                            <div className="pop-imagcent"><img src={Phonixpng}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <p className="mb-3">
                                <span className="font-weight-bold">Learn new skills.</span> Learning a new skill can help you improve your job prospects and make yourself more marketable. It can also help you expand your knowledge and interests.
                                </p>
                                <p className="mb-3">
                                <span className="font-weight-bold">Get involved in extracurricular activities.</span> Extracurricular activities are a great way to meet new people, learn new skills, and develop your interests. There are many different types of extracurricular activities available, so you can find something that you are passionate about.
                                </p>
                                <p className="mb-3">
                                <span className="font-weight-bold">Soft skills:</span> Soft skills, such as communication, teamwork, and problem-solving, are also important for industry readiness. These skills can be developed through education, experience, and self-reflection.
                                </p>
                                <p className="mb-3">
                                <span className="font-weight-bold">Get a job.</span> Getting a job can help you develop your work ethic and learn new skills. It can also help you save money for college or other expenses.                                </p>
                            </div>
                        </div>
                        }

                        {this.state.zoompoint===5&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >
                            Bridging gap between Education and Employment
                            </div>
                            <div className="pop-imagcent"><img src={Instdashboard}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                                <p className="mb-3">
                                    Placement assistance from an institute refers to the support and guidance provided to students or learners in their efforts to secure employment or internships in their respective fields of study or interest. It is a service offered by educational institutions to help bridge the gap between education and employment.
                                </p>
                                <p className="mb-3">
                                    This typically includes Job Search Support, Career Counselling, Industry Connections, Interview Preparation, & Conducting Job Fairs, Career Expos or networking events which help the students in interacting with potential employers and get a better understanding of the job opportunities.
                                </p>
                                <p className="mb-3">
                                    The goal of placement assistance is to enhance the employability of students and help them secure suitable employment or internships that align with their career goals and educational background. It can greatly benefit students by providing them with the necessary tools, resources, and support to navigate the job market and transition into their chosen careers.
                                </p>
                            </div>
                        </div>
                        }
                
                        </div>
                </div>
            </div>} */}
            {/* //Detailed view popup  */}
            </div>
            <div className="mb-3 ">
                <Instprocessflow/>
            </div>
        </div>
         )
    }
}
export default InstLanding