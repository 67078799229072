import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {OnlyNumbers , validateEmail, ValidateRequired,validateMobile} from '../General/validation';
import { ToastContainer, toast } from 'react-toastify';
import {SaveEnquiryDetails,GetConsent,SaveConsentHistory} from '../Industry_component/request-demo-method';
import ReCAPTCHA from "react-google-recaptcha";
import { Routing } from "../../Assests/js/routing";
import {Demoquizurl} from '../../Assests/js/config'

import moment from 'moment';
const NOOP = () => { }
const recaptchaRef = React.createRef();

function OnlineDemo({ isFrame, Url, close = NOOP, isIntern ,toastMsg=NOOP,Actiontype }) {
    // console.log("Actiontype",Actiontype)
    const [OpenDelay, setOpenDelay] = useState(false)
    const [humanCaptcha, sethumanCaptcha] = useState(false)
    const [Indu,setIndu] = useState(false)
    const [Intern , setIntern] = useState({
        compName:'',
        fstName : '',
        LstName : '',
        email : '',
        mobile : '',
        college:'', 
        course:'', 
        Type:'DEMO REQUEST',
        Desc:'',
        errors: [],
        DataConsent:[],
        ConsentDetails:{},
        concentErr:""
    })
   
    const [url , seturl] = useState([
        'https://www.youtube.com/embed/vg3y_UOKo7M',
        'https://www.youtube.com/embed/LiyDiRnPS84',
        'https://www.youtube.com/embed/m85MeVY50m8',
        'https://www.youtube.com/embed/yywIJC_NL7Q',
        'https://www.youtube.com/embed/ePIHSBkoRcg',
        'https://www.youtube.com/embed/7BXzQhSs1OY',
        'https://www.youtube.com/embed/az9GHhyf6eA'
    ])

    useEffect(() => {
        setTimeout(() => {
            setOpenDelay(true)
        }, 200);
        window. $('.modal-overlay').click(function(e) {
            if (!window.$(e.target).closest('.modal-box').length ||  !window.$(e.target).closest('.svg-play-icons').length){ closPopup() }
        });

       
    })
    useEffect(() => {
        // console.log('window',window.location.hash.includes('#/industries')?true:false)
        // console.log("Actiontype",Actiontype)
        GetConsent().then((result) => {
            // console.log("reeeee",result.recordsets[0].filter(x=>x.Label===Actiontype))
            setIntern({
                ...Intern,
                // ConsentDetails : result.recordsets[0].length > 0 ? result.recordsets[0] : []
                ConsentDetails:result.recordsets[0].filter(x=>x.Label===Actiontype).length> 0 ? result.recordsets[0].filter(x=>x.Label===Actiontype):[]
            })
            // setIndu(window.location.hash.includes('#/industries')?true:false)
        })
      }, []);

    const closPopup = () => {
        setOpenDelay(false)
        setIntern({
            compName:'',
            fstName : '',
            LstName : '',
            email : '',
            mobile : '',
            errors: [],
            college:'', 
            course:'', 
            Desc:'',
            // Type:'',
            IsPhoneTrue:false,
            DataConsent:[],
            ConsentDetails:{},
            concentErr:""
        })
        setTimeout(() => {
            close(true)
        }, 200);
    }
    // toastMsg
    const submitToast = () => {
        setOpenDelay(false)
 
        setTimeout(() => {
            toastMsg(true)
            window.open(Demoquizurl,'_blank')
        }, 300);

    }
    const handleChange = e =>{
        setIntern({
            ...Intern,
            [e.target.name]:e.target.value 
        })
    }
    const ValidateIsEmpty = (name, label) => {
        let flag = false;
        let errors = Intern.errors
        ValidateRequired(Intern[name], label, function (err) {
            errors[name] = err.RequiredField;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }
    const validatEmail = (label) =>{
        let flag = false;
        let errors = Intern.errors
        validateEmail(Intern["email"], label, function (err) {
            errors["email"] = err.EmailId;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }
    const valPhoneNumber = (name, text) => {
        let errors = Intern.errors;
        let flag = false;
        validateMobile(Intern.mobile, 'mobile number', function (err) {
            errors["mobile"] = err.MobileNumber;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
        // if (flag === true) {
        //     setIntern({
        //         IsPhoneTrue: true
        //     })
        // }
    }
    const valConcent =()=>{
        let concet=false
     
        if(Intern.DataConsent.length >0){
        
            concet=true     
            // setIntern(Prev =>({
            //     ...Prev,
            //     concentErr:"*Please give kkkkkkkkk to proceed."
            // }))
                
            setIntern({
                ...Intern ,  concentErr:""
            })
        }
        else{
        //    console.log("hiiiiii ds");
            // setIntern(Prev =>({
            //     ...Prev,
            //     concentErr:"*Please give consent to proceed."
            // }))
            setIntern({
                ...Intern , concentErr: "*Please give consent to proceed."
            })

        }
        return concet
    }
    // const dataconsent = async(Value) => {
    //     console.log("value",Value)
    //     let a = await ValidateIsEmpty('compName', 'company')
    //     let b = await ValidateIsEmpty('fstName', ' name')
    //     let c = await validatEmail('email address')
    //     let DataConsnt = Intern.DataConsent;
        
    //     if(DataConsnt.includes(Value)){
    //         let index = DataConsnt.indexOf(Value);
    //         if (index > -1) {DataConsnt.splice(index, 1)}
           
    //     }
    //     else 
    //    if( a=== true && b === true && c === true )
    //     {
    //         SaveConsentHistory(Intern,Value)
    //         DataConsnt.push(Value);
           
           
    //     }
    //     setIntern({
    //         ...Intern,
    //         DataConsent: DataConsnt
    //     })
         
    // }

    const dataconsent = (Value) => {
        let DataConsnt = Intern.DataConsent;
        if(DataConsnt.filter(x=>x.Consent_Id===Value).length>0){
            DataConsnt = DataConsnt.filter(x=>x.Consent_Id !== Value)
        }else{
            DataConsnt.push({Consent_Id:Value,CheckedDate:moment(new Date()).format('YYYY-MM-DD h:m:s')});
        }
        setIntern({
            ...Intern,
            DataConsent: DataConsnt
        })
    }

    const updateverifyCaptcha=()=>
    {
        let concet=false
        let ReCaptcha = humanCaptcha;
         if(ReCaptcha === false){
            let errors = Intern.errors
            errors["ReCaptcha"] = "*please verify recaptcha"
            setIntern({

                ...Intern,
                errors: errors
            })
        }
        else{
            concet=true   
            let errors = Intern.errors
            errors["ReCaptcha"] = undefined
            setIntern({
                ...Intern,
                errors: errors
            })
        }
        return concet
    }


    const SaveDemoReq=async()=>{
        //    submitToast()  
        
        let flag = false
        let a =  ValidateIsEmpty('compName', 'Institute')
        let b =    ValidateIsEmpty('fstName', ' name')
        let c =   validatEmail('email address')
        let d =    valPhoneNumber(Intern.mobile, 'mobile number')
        let f =    updateverifyCaptcha()
        let e =    valConcent()
       
         
        if( a=== true && b === true && c === true && d === true && e===true && f === true ){
        let type=Actiontype==='DemoPolicy'?'DEMO REQUEST': Actiontype==='PhoenixFactorPolicy' ? 'PHOENIX FACTOR': Actiontype==='SKLASPIREPolicy' ? 'SKL ASPIRE DEMO' : 'AACTE'
        let compname=(Actiontype==='DemoPolicy'?Indu?Intern.compName:"":"")
        let collegename=(Actiontype==='PhoenixFactorPolicy'|| Actiontype==='AACTEPolicy' || Actiontype==='SKLASPIREPolicy' ? Intern.compName : Actiontype==='DemoPolicy'?Indu?"":Intern.compName:"")

            await SaveEnquiryDetails(compname,Intern.fstName, Intern.LstName, Intern.email, Intern.mobile,collegename,Intern.course,type,Intern.Desc,Intern.DataConsent).then((result) => {
                 closPopup() 
                 submitToast()  
                })  
        }
        
    
       
        return flag
    }

    //ReCaptcha submits  done by dhanasekar  
    const verifyCaptcha = (res) => {
        if (res) {
            let errors = Intern.errors
            errors["ReCaptcha"] = undefined
            setIntern({
                ...Intern,
                errors: errors
            })
            sethumanCaptcha(true)


        }
    }

    const expireCaptcha = () => {
       sethumanCaptcha(false)
    }


    


    return (
        <>
            <div className='modal-overlay'></div>
            <div className={OpenDelay ? isFrame === false? ' box-bg active mi-wdth box-bg mi-wdth'  : ' box-bg active trans-background box-bg trans-background' :
            isFrame === false?  'box-bg mi-wdth': ' box-bg trans-background'}>
                <div className='modal-close close-popdemo' onClick={closPopup}>
                    {/* <FontAwesomeIcon icon={faTimes} />  */}
                    <i class="fa fa-times clr-close-rqst-demo" aria-hidden="true"></i>
                    </div>
                <>
                   <div className='intern-modal rqst-heading  text-trns-demo' >
                   {Actiontype==='DemoPolicy' ?
                    "I/We would like to request for a platform demo":Actiontype==='PhoenixFactorPolicy' ? "Enquiry Form for Phoenix Factor":Actiontype==='AACTEPolicy' ? "Enquiry Form for AATCE" : Actiontype==='SKLASPIREPolicy' ? "Online Demo Form for SKL-ASPIRE":""}
                    </div>
                        {/* <div className='iner-datasection'>Provide us with your details and we
                            will get back to you as soon as possible.</div> */}
                        <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                               <input type="text" className="input-reg input-rqstdemo" placeholder=
                               {Actiontype==='DemoPolicy'?Indu?"Industry *":"Institution *":Actiontype==='PhoenixFactorPolicy'?"Institution *":Actiontype==='AACTEPolicy'?"Institution *": Actiontype==='SKLASPIREPolicy' ? "Institution Name *":""}
                                  value={Intern.compName} 
                                  name='compName' 
                                  onChange={handleChange}
                                  onBlur={()=>{ValidateIsEmpty('compName', 'Institute')}} 
                                  />
                                   <span className='error err-demo'>{Intern.errors["compName"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <input type="text" className="input-reg input-rqstdemo" placeholder="Contact Person Name  *" 
                           value={Intern.fstName} name='fstName' 
                           onChange={handleChange}
                           onBlur={()=>{ValidateIsEmpty('fstName', ' name')}} 
                            />
                            <span className='error err-demo'>{Intern.errors["fstName"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <input type="text" className="input-reg input-rqstdemo" placeholder="Email Id *" 
                            value={Intern.email} name='email' 
                            onChange={handleChange}
                            onBlur={()=>{validatEmail('email address')}}
                            />
                             <span className='error err-demo'>{Intern.errors["email"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">

                                <input type="text" className="input-reg input-rqstdemo" placeholder="Mobile number *"
                                value={Intern.mobile} name='mobile'  
                                onChange={handleChange}
                                maxLength={10}
                                onKeyPress={OnlyNumbers}  
                                onBlur={()=>{valPhoneNumber(Intern.mobile, 'mobile number')}} 
                                 />
                                  <span className='error err-demo'>{Intern.errors["mobile"]}</span>
                            </div>
                        </div>
                        {/* <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <textarea className="input-reg input-rqstdemo"
                                    placeholder="Question/Remark"
                                    name='Desc'
                                    rows="3"
                                    type="text"
                                    value={Intern.Desc}
                                    onChange={handleChange}
                                    // onBlur={()=>{ValidateIsEmpty('Desc', 'Description')}} 
                                />
                                <span className='error err-demo'>{Intern.errors["Desc"]}</span>
                                
                            </div>
                        </div> */}
                            {Intern.ConsentDetails !== undefined &&
                            Intern.ConsentDetails.length > 0 &&
                               Intern.ConsentDetails.map((val,i) =>
                                    <>
                                         {/* {console.log("Intern.ConsentDetails",Intern.ConsentDetails)} */}
                                        {val.ConsentName === 'Demo Policy' &&
                                            <div>
                                                {/* <label className='dataclln-labl'>{val.ConsentName}*</label> */}
                                                <div className='data-cnsnt-main'>
                                                    <div className='check-box-cnsnt'>
                                                        <input 
                                                            type="checkbox" 
                                                            id="consent1" 
                                                            name="consent1" 
                                                            value="consent" 
                                                            onClick={()=>dataconsent(val.Value)} 
                                                            checked={Intern.DataConsent.filter(x=>x.Consent_Id ===  val.Value).length  >0}
                                                        />
                                                    </div>
                                                    <div className='consnt-txt consnt1-txt'>
                                                        <div dangerouslySetInnerHTML={{__html: val.ConsentContent}}></div>
                                                    </div>
                                                </div>
                                                {(Intern.DataConsent.includes(val.Value)) == ""?
                                             
                                                        <span className='error err-demo'>{Intern.concentErr}
                                                          
                                                        </span>   :
                                                        <div></div>
                                                     }                                
                                                    
                                            </div>
                                        }
                                        {val.ConsentName === 'PhoenixFactorPolicy' &&
                                            <div>
                                                {/* <label className='dataclln-labl'>{val.ConsentName}*</label> */}
                                                <div className='data-cnsnt-main'>
                                                    <div className='check-box-cnsnt'>
                                                        <input 
                                                            type="checkbox" 
                                                            id="consent1" 
                                                            name="consent1" 
                                                            value="consent" 
                                                            // onClick={()=>dataconsent(val.Value)} 
                                                            // checked={Intern.DataConsent.includes(val.Value)}
                                                            onChange={()=>dataconsent(val.Value)} 
                                                            checked={Intern.DataConsent.filter(x=>x.Consent_Id ===  val.Value).length  >0}
                                                        />
                                                    </div>
                                                    <div className='consnt-txt consnt1-txt'>
                                                        <div dangerouslySetInnerHTML={{__html: val.ConsentContent}}></div>
                                                    </div>
                                                </div>
                                                {(Intern.DataConsent.includes(val.Value)) == ""?
                                             
                                                        <span className='error err-demo'>{Intern.concentErr}
                                                          
                                                        </span>   :
                                                        <div></div>
                                                     }                                
                                                    
                                            </div>
                                        }
                                        {val.ConsentName === 'AACTe Policy' &&
                                            <div>
                                                {/* <label className='dataclln-labl'>{val.ConsentName}*</label> */}
                                                <div className='data-cnsnt-main'>
                                                    <div className='check-box-cnsnt'>
                                                        <input 
                                                            type="checkbox" 
                                                            id="consent1" 
                                                            name="consent1" 
                                                            value="consent" 
                                                            // onClick={()=>dataconsent(val.Value)} 
                                                            // checked={Intern.DataConsent.includes(val.Value)}
                                                            onChange={()=>dataconsent(val.Value)} 
                                                            checked={Intern.DataConsent.filter(x=>x.Consent_Id ===  val.Value).length  >0}
                                                        />
                                                    </div>
                                                    <div className='consnt-txt consnt1-txt'>
                                                        <div dangerouslySetInnerHTML={{__html: val.ConsentContent}}></div>
                                                    </div>
                                                </div>
                                                {(Intern.DataConsent.includes(val.Value)) == ""?
                                             
                                                        <span className='error err-demo'>{Intern.concentErr}
                                                          
                                                        </span>   :
                                                        <div></div>
                                                     }                                
                                                    
                                            </div>
                                        }
                                        {val.ConsentName === 'SKL ASPIRE Policy' &&
                                            <div>
                                                {/* <label className='dataclln-labl'>{val.ConsentName}*</label> */}
                                                <div className='data-cnsnt-main'>
                                                    <div className='check-box-cnsnt'>
                                                        <input 
                                                            type="checkbox" 
                                                            id="consent1" 
                                                            name="consent1" 
                                                            value="consent" 
                                                            // onClick={()=>dataconsent(val.Value)} 
                                                            // checked={Intern.DataConsent.includes(val.Value)}
                                                            onChange={()=>dataconsent(val.Value)} 
                                                            checked={Intern.DataConsent.filter(x=>x.Consent_Id ===  val.Value).length  >0}
                                                        />
                                                    </div>
                                                    <div className='consnt-txt consnt1-txt'>
                                                        <div dangerouslySetInnerHTML={{__html: val.ConsentContent}}></div>
                                                    </div>
                                                </div>
                                                {(Intern.DataConsent.includes(val.Value)) == ""?
                                             
                                                        <span className='error err-demo'>{Intern.concentErr}
                                                          
                                                        </span>   :
                                                        <div></div>
                                                     }                                
                                                    
                                            </div>
                                        }
                                    </>
                                )                        
                            }  
                            <div className="mt-3 col-12 px-0  d-fully-cent">
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey="6Lf6tG4UAAAAAKvPg0Gd7joyPB5w8ReJ7xtwUstj"
                                                    onChange={verifyCaptcha}
                                                    onExpired={expireCaptcha}
                                                    className="captureText"
                                                />
                                               
                                        </div>   
                                        <span className="error-class errmsss">{Intern.errors["ReCaptcha"] }</span>
                                        {humanCaptcha===true?<span className="error-class">{Intern.errors["Checkbox"] }</span>:<></>}  
                                                
                        <div className='text-aligen-center d-fully-cent '>
                            <button className='submit-demorqst-btn z-ined-2postition-realtive  login-button-padding' 
                            onClick={SaveDemoReq}
                            >Start Demo</button>
                        </div>
                    </>
               
                    </div>
        </>
    )

}

export default React.memo(OnlineDemo); 