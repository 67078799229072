import logo from './logo.svg';
// import './App.css';
import { Routing } from './Assests/js/routing';
import Header from './component/Header_component/Header'
import { createContext } from 'react';
import Footer from './component/Footer_component/Footer';
import Confirmationofpayment from './component/Confirmation_of_payment component/confirmation -of -pay';
import Cookies from './component/Cookie_component/Cookie';
import Disclaimer from './component/Disclaimer_component/Disclaimer';
import PrivacyPolicy from './component/PrivacyPolicy_component/PrivacyPolicy';
import SafeHarbor from './component/Safe_component/SafeHarbor';
import SiteMap from './component/SiteMap_component/SiteMap';
import TermsOfUse from './component/Terms_of_use_component/TermsOfUse';
import TradeMark from './component/TradeMark_component/TradeMark';
import OurTeam from './component/OurTeam_component/OurTeam';
import Institute from './component/Institute_component/Institute';
import Industry from './component/Industry_component/Industry';
import Individual from './component/Individual_component/Individual';
import Aboutus from './component/Aboutus_component/Aboutus';
import IndividualJobs from './component/Indivi_jobs_component/IndividualJobs';
import ChaloExam from './component/chaloexam-component/chalo-exam';
import { Router, Switch, Route   } from "react-router-dom";
import Home from './component/YCC-Home/Home';
import history from './histoty';
import PrimerLeap from './component/primerleap_component/Primerleap';
import { HashRouter } from 'react-router-dom';
import ThreeIConnect from './component/3iConnect/3iConnect';
import Registeration from './component/registeration/registeration';
import Congrates from './component/registeration/reg-congra';
import Recruiter from './component/Recruiter_component/Recruiter'
// import ChaloExamHome from './component/chaloexam-component/ChaloExamHome'
import IndiLanding from './component/Aboutus_component/Indilanding';
// import Processflow from './component/Aboutus_component/processflow';
import Instprocessflow from './component/Indivi_jobs_component/Instituteprocessflow'

import Aacte from './component/Aatce_component/aatce';
import InduLanding from './component/Aboutus_component/indulanding';
import InstLanding from './component/Aboutus_component/Instlanding';

import Processflow from './component/Indivi_jobs_component/processflow';
import Aecte from './component/Aatce_component/aatce'
import Chalo from './component/chaloexam-component/chalo'
import InQUIZition from './component/InQUIZition_component/InQUIZition'
import CareerGapBlog from './component/CarrerGap_component/carrer-gap-blog'
import SklAspire from './component/Skl_aspire_component/skl-aspire'
import Enquiry from './component/Skl_aspire_component/Ins-engageheadform'

import CareerCouncel from './component/CarrerGap_component/career-counseling'

import Newsletter from './component/Newsletter-comp/Newsletter'






import { useEffect, useState } from 'react';


export const Context = createContext('');

function App() {
  const [autoscrollTo , setautoscrollTo] = useState(false)
  const [RedirectedHome , setRedirectedHome] = useState(false)

  useEffect(()=>{
  // window.addEventListener('contextmenu', event => event.preventDefault());
    window.addEventListener('popstate', function(event) {
      window.header.pageRender();
  
  }, false);
  },[])

  useEffect(()=>{
    return()=>{
      // console.log('clean up',RedirectedHome)
    }
  },[RedirectedHome])

  const ChangeAutoScroll = (bool)=>{
    // console.log('bool from app',bool)
    setautoscrollTo(bool)
    if(bool === false){
    setRedirectedHome(bool)
    }
  }

  const setRedirectedHomeTrue =()=>{setRedirectedHome(true)}
  

  return (
    <div className="App">
      <Header ChangeAutoScroll={ChangeAutoScroll} RedirectedHome={RedirectedHome}  setRedirectedHome ={setRedirectedHomeTrue} /> 
       <Context.Provider value={[autoscrollTo , ChangeAutoScroll , setRedirectedHome]}>
      <HashRouter >
       <Switch>
            <Route exact  path={"/"}
            component={Aboutus} ChangeAutoScroll={ChangeAutoScroll}
             />
            <Route exact path={'/cont'} component={Congrates} />
            <Route exact path={Routing.Home} 
            component={Aboutus} ChangeAutoScroll={ChangeAutoScroll}
            />
            <Route exact path={Routing.Individual} component={Individual} />
            <Route exact path={Routing.Industry} component={Industry} />
            <Route exact path={Routing.Institute} component={Institute} />
            {/* <Route exact path={Routing.chaloexamhome} component = {ChaloExamHome} /> */}
            <Route exact path={Routing.chaloexamhome2} component = {Chalo} />
            <Route exact path={Routing.indihomelanding} component = {IndiLanding} />
            {/* <Route exact path={Routing.Processflow} component = {Processflow} /> */}
            <Route exact path={Routing.induhomelanding} component = {InduLanding} />
            <Route exact path={Routing.insthomelanding} component = {InstLanding} />
            <Route exact path={Routing.aatce} component={Aacte} />
            <Route exact path={Routing.chaloexam} component = {ChaloExam} />
            <Route  exact path={Routing.Inquizition} component={InQUIZition} />
            <Route  exact path={Routing.sklaspire} component={SklAspire} />
            <Route  exact path={Routing.Newsletterpage} component={Newsletter} />
            <Route  exact path={Routing.sklenquiry} component={Enquiry} />
            <Route exact path={Routing.Recruiter} component = {Recruiter} />
            <Route exact path={Routing.ycc} component={Home} ChangeAutoScroll={ChangeAutoScroll}/>
            <Route path={Routing.registration} component={Registeration} />
            <Route exact path={Routing.rediness} component={PrimerLeap} />
            <Route exact path={Routing.OurTeam} component={OurTeam} />
            <Route exact path={Routing.termsofuse} component={TermsOfUse} />
            <Route exact path={Routing.sitemap} component={SiteMap} />
            <Route exact path={Routing.safe} component={SafeHarbor} />
            <Route exact path={Routing.aatce} component={Aecte} />
            <Route exact path={Routing.privacypolicy} component={PrivacyPolicy} />
            <Route  exact path={Routing.disclaimer} component={Disclaimer} />
            <Route exact path={Routing.cookies} component={Cookies} />
            <Route exact path={Routing.Job} component ={IndividualJobs} />
            <Route exact path = {Routing.threeIconnect} component = {ThreeIConnect} />
            <Route exact path={Routing.confirmationofpayment} component={Confirmationofpayment} />
            <Route  exact path={Routing.CareerBlog} component={CareerGapBlog} />
            <Route  exact path={Routing.CareerCounsell} component={CareerCouncel} />
            <Route  exact path={Routing.TradeMark} component={TradeMark} />
          
            
            
            
            
            
            
            
          
            
            </Switch>
          </HashRouter>
          </Context.Provider>
       

      <Footer/>
    </div>  
  );
}

export default App;
