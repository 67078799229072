import { APIUrl } from '../../Assests/js/config';
import axios from 'axios';


export function SaveEnquiryDetails(CompanyName,firstname, lastname, EmailId, mobile, college, course,Type,Description,DataConsent) {
    return axios({
        method: 'POST',
        url: APIUrl + '/api/SaveEnquiryDetails',
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }),
        data: { CompanyName,
                firstname, 
                lastname, 
                EmailId, 
                mobile, 
                college, 
                course, 
                Type,
                Description,
                DataConsent
            }
    })
    .then(result => {
     
        return result.data;
    }).catch(e => {
   
    });
}

export function GetConsent(InputValue='') {
    
    return axios({
        method: 'POST',
        url: APIUrl + '/api/GetConsent',
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        data: {
            InputValue
        }
    })
    .then(res => {
        // console.log('re', res)
        let result = res.data;
        return result;
    }).catch(e => {
         
    });
}

// SavePrivacypolicyConsentHistory

export function SaveConsentHistory(Intern,concentId) {
    return axios({
        method: 'POST',
        url: APIUrl + '/api/SavePrivacypolicyConsentHistory',
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        // { name: 'IndustryName', value: data.IndustryName },
        // { name: 'ContactPerson', value: data.ContactPerson },
        // { name: 'EmailId', value: data.EmailId },
        // { name: 'MobileNumber', value: data.MobileNumber },
        // { name: 'STDCode', value: data.STDCode },
        // { name: 'ConcentId', value: data.ConcentId },
        // { name: 'EntityTypeId', value: 11 },
        // { name: 'Password', value: ''}
        // compName:'',
        // fstName : '',
        // LstName : '',
        // email : '',
        // mobile : '',
        // college:'', 
        // course:'', 
        // Type:'DEMO REQUEST',
        // Desc:'',
        // errors: [],
        // DataConsent:[],
        // ConsentDetails:{}
        data: {
            IndustryName : Intern.compName,
            ContactPerson : Intern.fstName,
            EmailId : Intern.email,
            MobileNumber : Intern.mobile,
            STDCode : '91',
            ConcentId : concentId,
            EntityTypeId : 11,
            Password :''
        }
    })
        .then(res => {
            let result = res.data;
            return result;
        }).catch(e => {
             
        });
}