import { APIUrl } from '../../Assests/js/config';
import axios from 'axios';

export function GETJobDisplayName() {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/GETJobDisplayName",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }),
       
    })
    .then(result => {
        return result.data;
    }).catch(e => {
      
    });
}