import React from "react";
import $ from 'jquery';
import aictelogo from '../../Assests/Images/college_logos/aicte-logo.jpg'
import appainstituteofengineeringandtechnologygulbargalogo from '../../Assests/Images/college_logos/appa-institute-of-engineering-and-technology-gulbarga-logo.jpg'
import cukfulllogo from '../../Assests/Images/college_logos/cuk-full-logo.jpg'
import Laye1 from '../../Assests/Images/college_logos/Laye 1.jpg'
import neatlogotop from '../../Assests/Images/college_logos/neat-logo-top.jpg'
import pacelogo from '../../Assests/Images/college_logos/pace-logo.jpg'
import SLNcopy from '../../Assests/Images/college_logos/S L N copy.jpg'
import SharnbasvaUniversitylogo from '../../Assests/Images/college_logos/Sharnbasva_University_logo.jpg'
import veerappa from '../../Assests/Images/college_logos/veerappa.jpg';
import '../../Assests/teamCss/ourteammain.css';
import suresh1 from '../../Assests/Images/suresh.png';
import subin from '../../Assests/Images/subin.png';
import Adars from '../../Assests/Images/Adars.png';
import jyothi from '../../Assests/Images/Jyothi-20220512130733.png';
import ravi from '../../Assests/Images/Ravi.png';

import Sindhu from '../../Assests/Images/Sindhu.png';

import suresh from '../../Assests/Images/Suresh-20220512131009.png';
import jithin from '../../Assests/Images/Jithin.png';
import OurTeamModal from "./ourTeam-popup";

import VIVEKANANDA from '../../Assests/Images/VIVEKANANDA.png';
import ALOkKUMAR from '../../Assests/Images/ALOkKUMAR .png';
import RAVEENDRAN from '../../Assests/Images/raveendran.JPG';
import ANIL from '../../Assests/Images/ANIL.png';
 

import EDCC from '../../Assests/Images/EDC_logo_full_curved-02C copy.png';
import { Routing } from "../../Assests/js/routing";

import malabarchemar from '../../Assests/Images/Malabar-Assocition.jpg';
import StartupKarnataka from '../../Assests/Images/Start-up-Karnataka-Assocition.jpg';
import Startup_IndiaASSo from '../../Assests/Images/Startup-India-Assocition.jpg';

import threeinoneNeatlogoAssocition from '../../Assests/Images/threeinoneNeatlogoAssocition.jpg';

import YCchomeimg3 from '../../Assests/Images/YCchomeimg3.png';









import Slider from "react-slick";
const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [

        {

            breakpoint: 2000,

            settings: {

                slidesToShow: 3,

                slidesToScroll: 3,

                infinite: true,

                dots: false

            }

        },

        {

            breakpoint: 1441,

            settings: {

                slidesToShow: 3,

                slidesToScroll: 3,

                infinite: true,

                dots: false

            }

        },

        {

            breakpoint: 1025,

            settings: {

                slidesToShow: 3,

                slidesToScroll: 3,

                infinite: true,

                dots: false

            }

        },

        {

            breakpoint: 601,

            settings: {

                slidesToShow: 3,

                slidesToScroll: 3

            }

        },

        {

            breakpoint: 480,

            settings: {

                slidesToShow: 1,

                slidesToScroll: 1

            }



        }



    ]
};
class OurTeam extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            OpenPopup: false,
            popupId: 0
        }
    }








    componentDidMount() {
         


        

        window.$('html,body').animate({
            scrollTop: 0
        });

        document.getElementsByClassName('boxfive')[0].classList.add('active')
        document.getElementsByClassName('boxfour')[0].classList.add('active')


        
        document.getElementsByClassName('boxfive')[0].classList.add('active')
        document.getElementsByClassName('meet-teams')[0].classList.add('active')

        // $(function(){  
        //     $('.hideme').fadeIn('slow');

        //   });

        //   $(document).ready(function() {

        // ourteam annimation
         
        // document.getElementById("our").classList.add("active")
        $(window).scroll(function () {
            // {console.log('jhdd',document.getElementsByClassName('boxfive')[1].offsetTop ,
            // $(window).scrollTop() )}
            if ((document.getElementsByClassName('boxfive')[1].offsetTop - window.innerHeight * 1) < $(window).scrollTop()) {
                document.getElementsByClassName('boxfive')[1].classList.add('active')
                document.getElementsByClassName('boxfour')[1].classList.add('active')
                document.getElementsByClassName('ourteam-txet')[0].classList.add('active')
            }
        })
        // ourteam annimation

        /* Every time the window is scrolled ... */
        //   $(window).scroll( function(){

        //       /* Check the location of each desired element */
        //      window. $('.hideme').each( function(i){

        //           var bottom_of_object = $(this).position().top + $(this).outerHeight();
        //           var bottom_of_window = $(window).scrollTop() + $(window).height();

        //          

        //           /* If the object is completely visible in the window, fade it it */
        //           if( bottom_of_window > bottom_of_object ){

        //               $(this).animate({'opacity':'1'},2500);

        //           }

        //       }); 

        //   });

        window.onscroll = () => {
            $('.hideme').each(function (i) {

                var bottom_of_object = $(this).position().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

            

                /* If the object is completely visible in the window, fade it it */
                if (bottom_of_window > bottom_of_object) {

                    $(this).animate({ 'opacity': '1' }, 2500);

                    //document.getElementsByClassName(" meet-team-headsss")[0].style.position = 'static'

                }
                else {
                    // document.getElementsByClassName(" meet-team-headsss")[0].style.position = 'fixed'
                }

            });

        }

        //   });
        window.onload = ()=> {
            $('.individualBlockIdent').each (function () {
                if ($(this)[0].id === "about-us-who") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop())
                     {
                        document.getElementById('about-us-who').classList.add('active')
                    }
                }
            }
        )}






    }

    OpenPopup = (id) => {
        this.setState({
            OpenPopup: true,
            popupId: id
        })
    }

    closPopup = () => {
        this.setState({
            OpenPopup: false,
            popupId: 0
        })
    }

    OpenAicte = () => {
        window.open("https://neat.aicte-india.org/course-details/NEAT2020615_PROD_2", '_blank')
    }

    scrollintoEdc = () => {
        // document.querySelector('#EdccData').scrollIntoView({
        //     behavior: 'smooth'
        // });
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    scrollintoSkillablser = () => {
        // document.querySelector('#ourteam1').scrollIntoView({
        //     behavior: 'smooth'
        // });
         
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // let scr =document.getElementById("SkLDatat").scrollTop+'px'
     
        // window.scrollTo({
        //     top: 400 ,
        //     behavior: 'smooth'
        // });
        let scrolling = $("#dfooter").offset().top
        $('html,body').animate({
            scrollTop: (window.matchMedia("(max-width: 568px)").matches) ? window.innerHeight * 1.45 : window.innerHeight * 1.85
        },
            'slow');
    }
    render() {
        return (

            <div>
            <div class="about-us-who individualBlockIdent" id="about-us-who">
            <div id="dr1" className="paddd-abtusteam">
                        <p className="padbot-mobalign" >MEET OUR TEAM</p>

                    </div>
                        <div class="row mx-0 col-12 about-us-cent-ali ">
                           
                            {/* <div class="col-lg-6 whoimghover " > */}
                                {/* <img src={layer48copy4} class="img-width-100" /> */}
                        
                            {/* </div> */}
                            <div class="col-lg-12 col-12 px-0">
                                
                                <div class="who-desc-text padding-btm-textcent   new-update-ourteam">
                                    <div>Our underlying principles define who we are as a Team. 
                                        The values that are most essential to us remain constant as we adapt, 
                                        evolve and grow as an organization. We always put our people first as we pursue excellence, 
                                        embrace change and serve our clients will full integrity. </div>
                                     
                                </div>
                            </div>
                        </div>
                    </div>
            
            <section className="Team complete">

                <div className="ourteam  ">
                    <div class="avbout-us-skl-text meet-teams meet-team-headsss">
                        {/* <div>Meet Our Team</div> */}

                    </div>
                    <div className="col1  tab-left-head-team" id="EdccData">
                        <div className="boxfive" id="fstSub">
                           
                            <div className="col-12 px-0 row mx-0 tam-head-center-align">
                                <div className="row mx-0 col-lg-9  col-12 px-0 disp-conte-mob">
                                    <div class="our-team-mainpgesssS">
                                        <div class="our-team-sub-headss">Leadership Team</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="centered-content" id="SkLDatat">

                        <div className="row mx-0 col-lg-9  col-12 px-0 boxfour our-team-center">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={ravi} alt="" id="vide1" className="cu-pointer border-radi50per " onClick={() => { this.OpenPopup(1) }} />
                                <p id="fname">RAVI KUMAR RADHAKRISHNAN </p>
                                <p id="desig"> CO-FOUNDER & CEO </p>
                            </div>
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-6 out-team-section">
    <img src={sasikumar} alt="" id="vide2" className="cu-pointer border-radi50per"  onClick={()=>{this.OpenPopup(2)}} />
                                        <p id="fname"> SASI KUMAR RADHAKRISHNAN</p>
                                        <p id="desig">DIRECTOR, BUSINESS DEVELOPMENT  </p>
    </div> */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={Sindhu} alt="" id="vide3" className="cu-pointer border-radi50per" onClick={() => { this.OpenPopup(3) }} />
                                <p id="fname">SINDHU RAMANATHAN

                                </p>
                                <p id="desig">CO-FOUNDER & CIO   </p>
                            </div>
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-6 out-team-section">
    <img src={AJ} alt="" id="vide4" className="cu-pointer border-radi50per"  onClick={()=>{this.OpenPopup(4)}} />
                                        <p id="fname">ANTHONY JOHNSON
                                        </p>
                                        <p id="desig">DIRECTOR & ADVISOR  </p>
    </div>
    <div className="col-xl-3 col-lg-4 col-md-6 col-6 out-team-section">
    <img src={sunil} alt="" id="vide5" className="cu-pointer border-radi50per"  onClick={()=>{this.OpenPopup(5)}} />
                                        <p id="fname">SUNIL SUBRAMANIAN 
                                        </p>
                                        <p id="desig"> SVP, BUSINESS DEVELOPMENT </p>
    </div>
    <div className="col-xl-3 col-lg-4 col-md-6 col-6 out-team-section">
    <img src={baskar} alt="" id="vide6" className="cu-pointer border-radi50per"  onClick={()=>{this.OpenPopup(6)}} />
                                        <p id="fname">DR. B. BHASKAR</p>
                                        <p id="desig">DIRECTOR, BIO-IT SERVICES & SOLUTIONS</p>
    </div> */}
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={suresh} alt="" id="vide6" className="cu-pointer border-radi50per" onClick={() => { this.OpenPopup(7) }} />
                                <p id="fname">SURESH NARAYAN  </p>
                                <p id="desig"> DIRECTOR & CTO </p>
                            </div> */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={jyothi} alt="" id="vide6" className="cu-pointer border-radi50per" onClick={() => { this.OpenPopup(8) }} />
                                <p id="fname">JYOTHI SATYANATHAN    </p>
                                <p id="desig">DIRECTOR - BUSINESS STRATEGY </p>
                            </div>

                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={VIVEKANANDA} alt="" id="vide6" className="cu-pointer border-radi50per" onClick={() => { this.OpenPopup(10) }} />
                                <p id="fname">VIVEKANANDA REVAIAH </p>
                                <p id="desig">DIRECTOR - FINANCE</p>
                            </div> */}
                             {/* <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={ALOkKUMAR } alt="" id="vide6" className="cu-pointer border-radi50per" onClick={() => { this.OpenPopup(11) }} />
                                <p id="fname">ALOK KUMAR SINGH </p>
                                <p id="desig">DIRECTOR - CORPORATE STRATEGY </p>
                            </div> */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={RAVEENDRAN } alt="" id="vide6" className="cu-pointer border-radi50per" onClick={() => { this.OpenPopup(11) }} />
                                <p id="fname">RAVEENDRAN KASTHURI </p>
                                <p id="desig">DIRECTOR - STRATEGIC INITIATIVES </p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={ANIL} alt="" id="vide6" className="cu-pointer border-radi50per" onClick={() => { this.OpenPopup(12) }} />
                                <p id="fname">ANIL KUMAR B </p>
                                <p id="desig">DIRECTOR - LEGAL </p>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="ourteam ">
                    <div className="col1">
                        <div className="boxfive tab-left-head-team" id="SecSub">
                            {/* <div className="skillablers-teams-dtatasect" id="ourteam1">

                                 <div id="new"> <p className="text-tran-upper ourteam-txet line-ht-skillablers" id="our">THE  
                                 <img src={skllogo} className="logo-between-text skill-logo skillablers-logos-sectionns-team"  /> TEAM</p></div>
                            </div> */}
                            <div className="col-12 px-0 row mx-0 tam-head-center-align">
                                <div className="row mx-0 col-lg-9  col-12 px-0 disp-conte-mob">
                                    <div class="our-team-mainpgesssS">
                                        <div class="our-team-sub-headss">The core team</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="centered-content skl-team-container-sect">
                        <div className="row mx-0 col-lg-9  col-12 px-0 boxfour our-team-center padding-bottm-skill-data ">
                            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={suresh1} alt="" id="vide1" />
                                <p id="fname">Suresh Narayan</p>
                                <p id="desig">CTO</p>
                            </div> */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={Adars} alt="" id="vide4" className="hover-none" />
                                <p id="fname">Adars AG
                                </p>
                                <p id="desig">MANAGER - PLATFORM ARCHITECTURE & DEVELOPMENT</p>
                            </div>

                         <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={subin} alt="" id="vide5"  className="hover-none"/>
                                <p id="fname">Subin George
                                </p>
                                <p id="desig">Manager - Infrastructure & Program Management</p>
                            </div>
                            
                            <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={jithin} alt="" id="vide2" className="hover-none" />
                                <p id="fname">JITHIN KK</p>
                                <p id="desig">LEAD - PLATFORM DEVELOPMENT</p>
                            </div>
                        

                            

                            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={Adars} alt="" id="vide4" className="hover-none" />
                                <p id="fname">Adars AG
                                </p>
                                <p id="desig">MANAGER - PLATFORM ARCHITECTURE & DEVELOPMENT</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-6 out-team-section">
                                <img src={subin} alt="" id="vide5"  className="hover-none"/>
                                <p id="fname">Subin George
                                </p>
                                <p id="desig">Manager - Infrastructure & Program Management</p>
                            </div> */}
                            

                        </div>
                    </div>

                </div>





















                <div className="center1">
                    <div id="dr1">
                        <p className="hideme" >ASSOCIATION</p>

                    </div>



                    <div className="center2">
                        <div className="gallery gallery-responsive portfolio_slider hideme Associteslider">
                            <Slider {...settings}>
                           
                                <div>
                                    <div className="inner">
                                        <img src={Startup_IndiaASSo} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={StartupKarnataka} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={threeinoneNeatlogoAssocition} alt="" id="ivid1" className="cursor-pointer" onClick={this.OpenAicte} />

                                    </div>
                                </div>
                                {/* <div>
                                    <div className="inner">
                                        <img src={appainstituteofengineeringandtechnologygulbargalogo} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={cukfulllogo} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={Laye1} alt="" id="ivid1" />

                                    </div>
                                </div> */}
                                <div>
                                    <div className="inner">
                                        <img src={ malabarchemar} alt="" id="ivid1" />

                                    </div>
                                </div>
                                {/* <div>
                                    <div className="inner">
                                        <img src={aictelogo} alt="" id="ivid1" />

                                    </div>
                                </div> */}

                                {/* <div>
                                    <div className="inner">
                                        <img src={threeinoneNeatlogoAssocition} alt="" id="ivid1" />

                                    </div>
                                </div> */}

                                {/* <div>
                                    <div className="inner">
                                        <img src={SLNcopy} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={SharnbasvaUniversitylogo} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={veerappa} alt="" id="ivid1" />
                                    </div>
                                </div> */}

                            </Slider>
                        </div>
                    </div>


                </div>




                <div className="center3 ">

                    <div id="r11">
                        <p className="">ASSOCIATION</p>
                    </div>




                    <div className="center4">

                        <div className="gallery gallery-responsive portfolio_slider hideme  Associteslider">
                            <Slider {...settings}>

                                <div>
                                    <div className="inner">
                                        <img src={Startup_IndiaASSo} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={StartupKarnataka} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={YCchomeimg3} alt="" id="ivid1" onClick={this.OpenAicte}/>

                                    </div>
                                </div>
                                {/* <div>
                                    <div className="inner">
                                        <img src={appainstituteofengineeringandtechnologygulbargalogo} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={cukfulllogo} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={Laye1} alt="" id="ivid1" />

                                    </div>
                                </div> */}
                                <div>
                                    <div className="inner">
                                        <img src={ malabarchemar} alt="" id="ivid1" />

                                    </div>
                                </div>
                                {/* <div>
                                    <div className="inner">
                                        <img src={aictelogo} alt="" id="ivid1" />

                                    </div>
                                </div> */}
                                {/* <div>
                                    <div className="inner">
                                        <img src={threeinoneNeatlogoAssocition} alt="" id="ivid1" />

                                    </div>
                                </div> */}
                                {/* <div>
                                    <div className="inner">
                                        <img src={SLNcopy} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={SharnbasvaUniversitylogo} alt="" id="ivid1" />

                                    </div>
                                </div>
                                <div>
                                    <div className="inner">
                                        <img src={veerappa} alt="" id="ivid1" />
                                    </div>
                                </div> */}

                            </Slider>
                        </div>
                    </div>


                </div>
                {this.state.OpenPopup &&
                    <OurTeamModal close={this.closPopup} id={this.state.popupId} />
                }
            </section>

            </div>




        )
    }
}
export default OurTeam
