import React from "react";
import $ from 'jquery';
import {RoutingUrl,edcurl} from '../../Assests/js/config'
class TradeMark extends React.Component
{
    componentDidMount(){
        window.$('html,body').animate({
            scrollTop: 0
        }); 
    }
    render(){
        return(
            <div className="tradedivcls">
    
            <div className="section section-common">
                <div className="section-wrapper">
                    <div className="regular-pages">
                        <h1 className="h1-regular-format">Trademarks and Copyrights</h1>
                        {/* TRADEMARKS AND COPYRIGHTS */}
                        <p className="p-regular-format">Skillablers and/or EDC Creative Technology Solutions Private Limited owns; under consideration and/or have applied for trademarks of all Skillablers specific brand, website addresses, The Skillablers Platform, "I am Skillabler' and all
                            other components that is used across the website.
                        </p>
                        <p className="p-regular-format">
                            The above defined trademark related brands, components, etc used above are hereinafter collectively mentioned as Skillablers and/or EDC Creative Technology Solutions Private Limited trademarks.
                        </p>
                        <p className="p-regular-format">The absence of a product or service name or logo does not constitute a waiver of Skillablers and/or EDC Creative Technology Solutions Private Limited trademark or other intellectual property rights concerning that name or logo.
                        </p>
                        <p className="p-regular-format">By using an Skillablers and/or EDC Creative Technology Solutions Private Limited related trademark, in whole or in part, you are acknowledging that Skillablers and/or EDC Creative Technology Solutions Private Limited is the sole owner
                            of the trademark. You acknowledge that you will not interfere with the rights of Skillablers and/or EDC Creative Technology Solutions Private Limited in the trademark in any manner whatsoever. The goodwill derived from using any of
                            the Skillablers and/or EDC Creative Technology Solutions Private Limited trademark exclusively inures to the benefit of and belongs to Skillablers and/or EDC Creative Technology Solutions Private Limited. Except for the limited right
                            to use as expressly permitted under these Guidelines, no other rights of any kind are granted hereunder, by implication or otherwise.
                        </p>
                        <h3>Guidelines on Use</h3>
                        <p className="p-regular-format">The trademarks, service marks and trade names of Skillablers and/or EDC Creative Technology Solutions Private Limited (either owned; under consideration or applied for) are valuable proprietary assets. Please follow the guidelines listed
                            below to help us protect our trademark rights and strengthen our brand identity.</p>
                        <p className="p-regular-format">You shall not use any Skillablers and/or EDC Creative Technology Solutions Private Limited trademark in any manner other than that stated below.</p>
        
                        <ul className="ul-regular-format" id="rbirdicon">
                            <li> Please use the appropriate ™, SM, or ® symbol in respect of each trademark on first use in any publication in India. For publications that will be distributed outside India, do not include trademark symbols but use the appropriate
                                trademark attribution notice, for example: EDC, EDC Creative Technology Solutions, EDC CTS, EDC India and/or Skillablers are trademarks of EDC Creative Technology Solutions Private Limited, registered in India and other countries.
                            </li>
        
                            <li> Always spell and capitalize the trademarks of Skillablers and/or EDC Creative Technology Solutions Private Limited exactly as they are shown across the website <a href={RoutingUrl} target="_blank" style={{color: 'red',textDecoration: 'none'}}>www.skillablers.com</a>                        and/or <a href={edcurl} target="_blank" style={{color: 'red',textDecoration: 'none'}} >www.edccts.com.</a></li>
        
                            <li> Do not shorten or abbreviate the trademarks.</li>
        
                            <li>Do not make up names that contain Skillablers and/or EDC Creative Technology Solutions Private Limited trademarks.</li>
                            <li>Do not use a variation, phonetic equivalent, foreign language equivalent, takeoff, or abbreviation of any Skillablers and/or EDC Creative Technology Solutions Private Limited trademarks for any purpose.</li>
                            <li>Do not use any Skillablers and/or EDC Creative Technology Solutions Private Limited trademark in connection with web sites, educational initiatives, products, solutions, packaging, manuals, promotional/advertising materials, or for
                                any other purpose except pursuant to an express written trademark license from Skillablers and/or EDC Creative Technology Solutions Private Limited.</li>
                            <li>Do not use any Skillablers and/or EDC Creative Technology Solutions Private Limited trademark in a manner that would imply the affiliation of Skillablers and/or EDC Creative Technology Solutions Private Limited with or endorsement,
                                sponsorship, or support of a third party product or service.</li>
                            <li>Do not manufacture, sell or give-away merchandise items, such as T-shirts and mugs, bearing any Skillablers and/or EDC Creative Technology Solutions Private Limited trademark including symbols, logos, or icons, except pursuant to an
                                express written trademark license from Skillablers and/or EDC Creative Technology Solutions Private Limited.</li>
                            <li>Do not imitate the distinctive Skillablers and/or EDC Creative Technology Solutions Private Limited web site design, logos, or typefaces.</li>
                            <li>Do not use or imitate an Skillablers and/or EDC Creative Technology Solutions Private Limited slogan or tagline. For example: "Crafting Careers…skillfully!"; "Solutions Ahead"; etc,.</li>
                            <li>Do not use an identical or virtually identical Skillablers and/or EDC Creative Technology Solutions Private Limited trademark as a second level domain name.</li>
                        </ul>
        
                    </div>
                </div>
            </div>







        </div>
        )
    }
}
export default TradeMark