import React from "react";
// import skllogo from '../../Assests/Images/skllogo.png';
import skllogo from "../../Assests/Images/Skillablers_logo1.png"
import EDClogofullcurved02C from '../../Assests/Images/EDC_logo_full_curved-02C.png';
import $ from 'jquery';
import '../../Assests/css/main.css';
import { NavLink , BrowserRouter, Redirect } from "react-router-dom";
import { Routing } from "../../Assests/js/routing";
import history from "../../histoty";
import {motsSkillablers} from '../../Assests/js/config';
import { Link } from "react-router-dom";
import Menu from "./Menu";
import SecondFunction from "./Menu2";
import { HashRouter } from 'react-router-dom';
import Signinpopup from "./Signinpopup";
import HeaderSignin from "./Header-signin";
import EDCC from '../../Assests/Images/EDC_logo_full_curved-02C copy.png';
import histoty from "../../histoty";
import Signinicon from '../../Assests/Images/Signinicon.svg';
// import Iplogoheader from '../../Assests/Images/Iplogoheader.png';
import Iplogoheader from '../../Assests/Images/Patend.png';
import freeimgicone from '../../Assests/Images/freeimgicone.svg';

import newimage from '../../Assests/Images/newgreenimg.svg';






class Header extends React.Component{
  constructor(props) {
    super(props) 
    window.header = this;     
    this.state = {
      reload : true,
      Signinheader: false,
      Industry: 0    ,
      rerender: false,
      ShowLgin : false,
      // RedirectedHome : false,
      dropdownid:0,
    }
  }
  componentDidMount(){   
    //    window.$(".hamburgerg").click(function(){
    //     });
  }

  PageRerender=()=>{
      this.setState({
          rerender : ! this.state.rerender
      })
  }

  Toggle(id){
    document.getElementById("activesubmenu").style.display="none"
    document.getElementById("activesmenu2").style.display="none"
    document.getElementById("upcomingmenu").style.display="none"
    document.getElementById("pastmenu").style.display="none"
    if(document.getElementsByClassName('boxsix').length > 0){
      document.getElementsByClassName('boxsix')[0].classList.add('active')
    }
    if(document.getElementsByClassName("AniNavMenu")[id].classList.contains("Menu-open")){ 
      if(id === 0){
          if( document.getElementById("navArea").classList.contains("display-on-menu") &&
          window.matchMedia("(max-width: 1100px)").matches ){
            document.getElementById("navArea").classList.remove("display-on-menu")
          }
        }
        document.getElementsByClassName("AniNavMenu")[id].classList.remove("Menu-open")
      //   if( id === 1){
      //   document.getElementsByClassName("menu-btn")[1].classList.remove("close")
      //   }
      //   else
      //   {
      //   document.getElementsByClassName("menu-btn")[0].classList.remove("close") 
      //   }
      //   if(document.getElementsByClassName("menu-btn")[0].classList.contains("z-index-zer0")){
      //       document.getElementsByClassName("menu-btn")[0].classList.remove("z-index-zer0")
      //   }
      //   if(document.getElementsByClassName("menu-btn")[1].classList.contains("z-index-zer0")){
      //    document.getElementsByClassName("menu-btn")[1].classList.remove("z-index-zer0")
      //   }
    }else{
      if(id === 0){
        if( document.getElementById("navArea").classList.contains("navarea-dontshow") &&
        window.matchMedia("(max-width: 1100px)").matches ){
          document.getElementById("navArea").classList.add("display-on-menu")
        }
      }
      document.getElementsByClassName("AniNavMenu")[id].classList.add("Menu-open")
      if( id === 1){
        //document.getElementsByClassName("menu-btn")[1].classList.add("close")
        //document.getElementsByClassName("menu-btn")[0].classList.add("z-index-zer0")
      }else{
        //   document.getElementsByClassName("menu-btn")[0].classList.add("close")
        //   document.getElementsByClassName("menu-btn")[1].classList.add("z-index-zer0")
      }
    }
    if( document.getElementsByClassName("hamburgerg")[0].classList.contains('is-active'))
    document.getElementsByClassName("hamburgerg")[0].classList.remove('is-active')
    else
    document.getElementsByClassName("hamburgerg")[0].classList.add('is-active')
  }
   
  RoutingTo = () => {
    window.open(window.location.origin + Routing.Home, '_self');
  }

  SingIn = () =>{
    if( window.location.hash.includes(Routing.Home)){
        window.open("https://indi.skillablers.com/indi-login", '_blank');
    }
    else if(window.location.hash.includes(Routing.Individual)){
        window.open("https://indi.skillablers.com/indi-login", '_blank');
    }
    else if(window.location.hash.includes(Routing.Industry)){
        window.open("https://indu.skillablers.com", '_blank');
    }
    else if(window.location.hash.includes(Routing.Institute)){
        window.open("https://inst.skillablers.com", '_blank');
    }
  }
  OpenSignin = (ind) => {
    this.setState({
        Signinheader:true,
        Industry:ind
    })
  }
  AutoScroll = ()=>{
    // $('html,body').animate({
    //     scrollTop: 0
    // });
    let scrolling = $("#dfooter").offset().top
    $('html,body').animate({
      scrollTop: scrolling
    },'slow');   
  }
  openPopup = () =>{
    this.setState({
        ShowLgin : true
    })
  }
  closePopup = () =>{
    this.setState({
        ShowLgin : false
    })
  }
  pageRender = () =>{
    window.footer.relo();
    this.setState({
        reload : !this.state.reload,
        //RedirectedHome : false
    })
    // let path  = window.location.hash.includes(Routing.rediness)
    if(window.location.hash.includes(Routing.rediness)){
      document.body.style.background = "#1C1C1D"
    }else if(window.location.hash.includes(Routing.Job)){
      document.body.style.background = "#E3ECF5"
      
    }else{
      document.body.style.background = "rgb(255, 255, 255)"
    }
  }

  pageRender1 = (val) =>{
    if(val === 1)
    window.location.hash = '#' + Routing.Individual
    //this.props.history.push(Routing.Individual)
    if(val === 2)
    window.location.hash = '#' + Routing.Industry
    // this.props.history.push(Routing.Industry)
    if(val === 3)
    window.location.hash = '#' + Routing.Institute
    // this.props.history.push(Routing.Institute)
    if(val === 4)
    window.location.hash = '#' + Routing.rediness
    if(val === 5)
    window.location.hash = '#' + Routing.ycc
    window.footer.relo();
    this.setState({
      reload : !this.state.reload
    })
    // let path  = window.location.hash.includes(Routing.rediness)
    if(window.location.hash.includes(Routing.rediness)){
      document.body.style.background = "#1C1C1D"
    }
    // else if(window.location.hash.includes(Routing.Job)){
    //   document.body.style.background = "#E3ECF5"
    // }
    else{
      document.body.style.background = "rgb(255, 255, 255)"
    }
  } 
  closeSignin = () => {
    this.setState({Signinheader:false})
  }
  AutoScrollPartner = (bool)=>{
    // console.log("locatiozDxaszdxaszn",window.location.hash.includes(Routing.Home),bool)
    if(bool){
      // console.log('header 2')
      this.props.ChangeAutoScroll(true)
      this.props.setRedirectedHome(true)
      setTimeout(()=>{
        // return <Redirect to={ Routing.Home } />
        //  this.setState({
        //   RedirectedHome : true
        //  },()=>{
        //setTimeout(()=>{
        this.setState({
          Redirect : false
        })
        //   },100)
        //  })
      },100)
    }
    //  console.log('window.location.pathname Autoscroll',window.location)
    if(window.location.hash.includes(Routing.Home)=== true || window.location.hash ==="#/" ||
      window.location.hash ==="#/home" || window.location.hash === "" || window.location.hash === "#/home/#ourpartner"){
      // console.log('header 1', $("#ourpartner").offset().top)
      let scrolling = $("#ourpartner").offset().top-100
      $('html,body').animate({
        scrollTop: scrolling
      },'slow');
    }
  }

  chalopage =()=>{
    window.location.hash = '#' + Routing.chaloexamhome;
  }
  pagereload=()=>{
    if(window.location.hash.includes('#ourpartner')){
      history.go(0);
    }else{

    } 
  }
  Autoscrollhometop=()=>
  {
    $('html,body').animate({
      scrollTop: 0
    },'slow');
  }

  opendrop=(id)=>
  {
    this.setState({
      dropdownid:id
    })
  }
  setdropid=(ins)=>
  {
    this.setState({
      dropdownid:ins
    })
  }

  openultra=()=>
  {
    window.open("https://events.skillablers.com/ultratechstimulus", '_blank');
  }
  openbioquiz2023=()=>
{
  window.open("https://events.skillablers.com/bioquiz2023", '_blank');
}

openYcc2020=()=>
{
  window.open("https://events.skillablers.com/events/ycc", '_blank');
}
YCCkerala2024=()=>
{
  //window.open('#' +Routing.ycc, '_blank')
  window.open(Routing.ycc, '_blank');
}

Carrergap=()=>
{
  window.open('#'+Routing.CareerBlog, '_self');

}
CarrerCouncell=()=>
{
  window.open('#'+Routing.CareerCounsell, '_self');
}

  render(){
      // console.log('hgsdghfgdfg',
      // this.props.RedirectedHome , (window.location.hash.includes(Routing.chaloexamhome)))
      // console.log('hii iam in head')
    if( ! window.location.hash.includes(Routing.ycc) &&  document.getElementById("header") !== null){
      document.getElementById("header").classList.remove('transparent-backgror')
    }
    if(this.props.RedirectedHome && (window.location.hash.includes(Routing.chaloexamhome)) ){
      return <HashRouter>
        <Redirect to={Routing.Home} />
      </HashRouter>
    }else{
      return(
        <div className="container-fluid" id="header">
          <div className="row" id="image">
            <div className="col-lg-4 col-md-5 ops-rel twologo-head">
              <HashRouter>
                <Link to={Routing.Home}>
                    <img src={skllogo} id="img1" className="cursor-pointer" onClick={this.Autoscrollhometop}/>
                </Link>
                <img src={Iplogoheader} className="iplogoclss"/>
              </HashRouter>
              {/* <div className="an-edc-group-comp">
                <div>an</div>
                <a href=" https://www.edccts.com/home" target='_blank' className="cu-pointer">
                  <img src={EDCC} className="ecd-group-logo"/>
                </a>
                <div>group company </div>
              </div> */}
            </div>
            {/* <div
              //className="col-lg-7 col-md-4  sigin-list"
              id="list"> */}
              {/* <ul>
                <li id="list4"><button id="btn" onClick={()=>this.OpenSignin(1)}>Sign In</button></li>       
              </ul> */}   
              <div className="col-lg-8 col-md-9 margintopalignheader" id="menu-left">
                <HashRouter>  
                  <ul className="home-menu-datass">
                    <li className={window.location.hash.includes(Routing.indihomelanding) ? "active" : ""}>
                      <NavLink to={Routing.indihomelanding} onClick={()=>{this.pageRender()}} activeClassName="active-menu">
                        <span className="navhover">Individuals</span>
                      </NavLink>
                    </li>
                    <li className={window.location.hash.includes(Routing.insthomelanding) ? "active" : ""}>
                      <NavLink to={Routing.insthomelanding} onClick={()=>{this.pageRender()}} activeClassName="active-menu" >
                        <span className="navhover">Institutions</span>
                      </NavLink>
                    </li>
                    <li className={window.location.hash.includes(Routing.induhomelanding) ? "active" : ""}>
                      <NavLink to={Routing.induhomelanding} onClick={()=>{this.pageRender()}} activeClassName="active-menu">
                        <span className="navhover">Industries</span>
                      </NavLink>
                    </li>
                    {/* <li className={window.location.hash.includes(Routing.CareerBlog) ? "active" : ""}>
                      <NavLink to={Routing.CareerBlog} onClick={()=>{this.pageRender()}} activeClassName="active-menu">
                        <span className="navhover">Career Advice</span>
                      </NavLink>
                    </li> */}
                       <li className={((window.location.hash.includes(Routing.CareerBlog)) ||window.location.hash.includes(Routing.CareerCounsell)  ) ? "active OurTeamss" : "OurTeamss"} >
                        <span className="navhover" onMouseOver={()=>this.setdropid(0)} >Career Advice</span><i class="fa fa-caret-down padding-left-2px" aria-hidden="true" onMouseOver={()=>this.setdropid(0)}></i>
                        <ul class="drop-menu menu-2 menu-3  brdrclr-events newcarrermenu">
                          {/* <li class="drop-item" id="drop-item" onClick={()=>{this.pageRender1(5)}} >SKL YCC 2022</li> */}
                          <li class="drop-item padding-dropunset" id="drop-item">
                          {/* <span className=""><span className="posrel-eventsechead ">Career Advice</span></span> */}
                          <div className="bg-clr-textdrop hovermenutext" onClick={this.Carrergap}><span className="posrel-eventsechead">CAREER GAP ANALYSIS</span></div>
                          <div className="bg-clr-textdrop hovermenutext" onClick={this.CarrerCouncell}><span className="posrel-eventsechead">CAREER COUNSELING</span></div>
                          </li>
                        </ul>
                      </li>
                      <li className={window.location.hash.includes(Routing.Newsletterpage) ? "active" : ""}>
                      <NavLink to={Routing.Newsletterpage} onClick={()=>{this.pageRender()}} activeClassName="active-menu">
                        <div className="incubiposrel">
                        <span className="navhover">Newsletter</span>
                        <span className="freeimagwrap"><img src={newimage} className="widfreeiconimga"></img></span>
                        </div>
                      </NavLink>
                      </li>
                    <li className={window.location.hash.includes(Routing.Inquizition) ? "active" : ""}>
                      <NavLink to={Routing.Inquizition} onClick={()=>{this.pageRender()}} activeClassName="active-menu">
                        <div className="incubiposrel">
                        <span className="navhover">InQUIZition</span>
                        <span className="freeimagwrap"><img src={freeimgicone} className="widfreeiconimga"></img></span>
                        </div>
                      </NavLink>
                    </li>
                    <li className={window.location.hash.includes(Routing.sklaspire) ? "active" : ""}>
                      <NavLink to={Routing.sklaspire} onClick={()=>{this.pageRender()}} activeClassName="active-menu">
                        <div className="incubiposrel">
                        <span className="navhover">SKL-ASPIRE</span>
                        <span className="freeimagwrap"><img src={newimage} className="widfreeiconimga"></img></span>
                        </div>
                      </NavLink>
                    </li>
                    {/* <li className={window.location.hash.includes(Routing.Job) ? "active" : ""}>
                      <NavLink to={Routing.Job} onClick={()=>{this.pageRender()}} activeClassName="active-menu" ><span className="navhover">Jobs</span></NavLink>
                    </li> */}
                    {/* <li className={(window.location.hash.includes(Routing.threeIconnect)) ? "active " : ""} >
                        <NavLink to={Routing.threeIconnect} onClick={()=>{this.pageRender()}} activeClassName="active-menu" >
                          Skills
                        </NavLink> 
                        <a href={motsSkillablers}><span className="navhover">Skills</span></a>
                    </li> */}
                    <div>
                      <li className={((window.location.hash.includes(Routing.rediness)) ||window.location.hash.includes(Routing.ycc)  ) ? "active OurTeamss" : "OurTeamss"} >
                        <span className="navhover" onMouseOver={()=>this.setdropid(0)} >Events</span><i class="fa fa-caret-down padding-left-2px" aria-hidden="true" onMouseOver={()=>this.setdropid(0)}></i>
                        <ul class="drop-menu menu-2 menu-3  brdrclr-events">
                          {/* <li class="drop-item" id="drop-item" onClick={()=>{this.pageRender1(5)}} >SKL YCC 2022</li> */}
                          <li class="drop-item padding-dropunset" id="drop-item">
                          <span className=""><span className="posrel-eventsechead">UPCOMING EVENTS</span></span>
                          {/* <div className="bg-clr-textdrop" onClick={this.openbioquiz2023}><span className="posrel-eventsechead">Bio Quiz 2023</span></div> */}
                          <div className="bg-clr-textdrop" onClick={this.YCCkerala2024}><span className="posrel-eventsechead">SKL YCC 2024 KERALA</span></div>
                          </li>
                          <li class="drop-item padding-dropunset" id="drop-item">
                            <span className="posrel-eventsechead">PAST EVENTS</span>
                            <div>
                            <div className="bg-clr-textdrop" onClick={this.openbioquiz2023}><span className="posrel-eventsechead">Bio Quiz 2023</span></div>
                            </div>
                            <div>
                            <div className= {this.state.dropdownid==1?"bg-clr-textdrop highlightsubmneu":"bg-clr-textdrop"} onClick={()=>{this.opendrop(1)}}><span className="posrel-eventsechead">SKL YCC Series</span><i class={this.state.dropdownid==1?"fa fa-caret-down padding-left-2px notrotatedsubmenu":'fa fa-caret-down padding-left-2px rotatesubmenu'} aria-hidden="true"></i></div>
                            {this.state.dropdownid==1&&
                            <div className="submenunewevwntsdropdown">
                               <div className="submenulistdrop" onClick={this.openYcc2020}>Youth Career Connect 2020</div>
                            </div>
                            }
                            </div>
                          <div className={this.state.dropdownid==2?"bg-clr-textdrop highlightsubmneu":"bg-clr-textdrop"} onClick={()=>{this.opendrop(2)}}><span className="posrel-eventsechead">Industry Awareness Quizzes</span><i class={this.state.dropdownid==2?"fa fa-caret-down padding-left-2px notrotatedsubmenu":'fa fa-caret-down padding-left-2px rotatesubmenu'}  aria-hidden="true"></i></div>
                          {this.state.dropdownid==2&&
                          <div className="submenunewevwntsdropdown">
                               <div className="submenulistdrop" onClick={this.openultra}>UltraTech Stimulus Quiz</div>
                            </div>
                          }
                  
                          </li>
                        </ul>
                      </li>
                    </div>
                    {/* <li className={window.location.hash.includes(Routing.Industry) ? "active" : ""}> <NavLink  to={Routing.Industry} activeClassName="active-menu" ><span className="navhover">Industries</span></NavLink></li> */}
                    <li className={window.location.hash.includes(Routing.chaloexamhome2)?"active OurTeamss":(window.location.hash.includes(Routing.aatce))?"active OurTeamss" : "OurTeamss"}> 
                      {/* <NavLink 
                        onClick={()=>this.AutoScrollPartner((window.location.hash.includes(Routing.chaloexamhome) === true 
                          ||window.location.hash.includes(Routing.indihomelanding) === true 
                          ||window.location.hash.includes(Routing.induhomelanding) === true 
                          ||window.location.hash.includes(Routing.insthomelanding) === true 
                          ||window.location.hash.includes(Routing.OurTeam) === true  
                          ||window.location.hash.includes(Routing.ycc) === true)?true:false)}
                        to={(window.location.hash.includes(Routing.Home) 
                          ||window.location.hash ==="#/" 
                          ||window.location.hash ==="#/home" 
                          ||window.location.hash === "" ) === true ? {} : 
                          window.location.hash.includes(Routing.chaloexamhome) === true ? Routing.chaloexamhome : Routing.Home}
                          activeClassName={window.location.hash.includes(Routing.chaloexamhome) ? "active-menu " : " "} >
                          <span className="navhover">Partners</span>
                      </NavLink> */}
                      <NavLink to={Routing.Home+'/#ourpartner'} onClick={()=>this.AutoScrollPartner(true)}>
                        <span className="navhover">Partners</span>
                      </NavLink>                          
                    </li>
                    <li id="list4">
                      {/* <button id="" className="meet-our-team" onClick={this.openPopup}>Sign In</button> */}
                      <img src={Signinicon} onClick={this.openPopup} className="siginlogoclss"/>
                      </li>
                    <div className="home-dots-section highlightline"></div> 
                  </ul>
                </HashRouter>
              </div>
              <div className="logo-image2">
                <div className="AniNav">
                  <ul className="AniNavList">
                    <li className="AniNavMenu" id="menucontainer" > 
                      <Menu/>
                    </li>
                  </ul>
                </div>
                {/* </div> */}
                <div className="logo-image2">
                  <div className="AniNav">
                    <div className="sk-logo">
                      <div className="hamburgerg" id="hamburgerg-1" onClick={()=>{this.Toggle(1)}}   >
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <ul className="AniNavList">
                      <li className="AniNavMenu menucontainer2" id="menucontainer"> 
                        <SecondFunction  toggle= {this.Toggle} change={this.OpenSignin} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {this.state.Signinheader && <Signinpopup close={this.closeSignin} />}
              {this.state.ShowLgin && <HeaderSignin  close={this.closePopup} />}
            </div>
          </div>
        )}
      }
    }
export default Header