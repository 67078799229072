
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {OnlyNumbers , validateEmail, validateMobile, ValidateRequired,ValidateDDLRequired} from '../General/validation';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import Customstyles from '../General/custom-style'
import {GetAllCollege, saveContact} from './primerleap-methods'
import {sendMail} from '../General/general-methods'
import { ToastContainer, toast } from 'react-toastify';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';
const Input = (props) => <components.Input {...props} isHidden={false} />;

const NOOP = () => { }

function Modal({ isFrame, Url, close = NOOP, isIntern, Industry}) {
    let industry_r = ""
    if(Industry === 1){
        industry_r = "LEAP";
    }else if(Industry === 2){
        industry_r = "PRIMER";
    }

    const [OpenDelay, setOpenDelay] = useState(false)

    const [Intern , setIntern] = useState({
        fstName : '',
        LstName : '',
        email : '',
        mobile : '',
        College : [],
        Course : '',
        errors: [],
        CollegeInput : ''
    })

    useEffect(() => {
        setTimeout(() => {
            setOpenDelay(true)
        }, 200);
       window. $('.modal-overlay').click(function(e) {
            if (!window.$(e.target).closest('.modal-box').length ||  !window.$(e.target).closest('.svg-play-icons').length){
                closPopup()
            }
        });
    },[])

    const getCollegeOptions = (input) => {       
        if (input.length > 1) {            
            return GetAllCollege(input, "")                
            .then((res) => {                   
                let result = res;                    
                return result;                
            })                
            .catch(function (error) {});        
        }   
    }

    const [Branding , setBranding] = useState({
         Fullname :'',
         EmailId :'',
         Country :'',
         mobile:'',
         company :'',
         Designation :'',
         errors:[]
    })

    const [url , seturl] = useState([
        'https://www.youtube.com/embed/vg3y_UOKo7M',
        'https://www.youtube.com/embed/LiyDiRnPS84',
        'https://www.youtube.com/embed/m85MeVY50m8',
        'https://www.youtube.com/embed/yywIJC_NL7Q',
        'https://www.youtube.com/embed/ePIHSBkoRcg',
        'https://www.youtube.com/embed/7BXzQhSs1OY',
        'https://www.youtube.com/embed/az9GHhyf6eA'
    ])

    const closPopup = () => {
        setOpenDelay(false)
        setIntern({
            fstName : '',
            LstName : '',
            email : '',
            mobile : '',
            College : {},
            Course : '',
            errors: [],
            CollegeInput : '',
            errors: []
        })
        setBranding({
            Fullname :'',
            EmailId :'',
            Country :'',
            mobile:'',
            company :'',
            Designation :'',
            errors : []
        })
        setTimeout(() => {
            close(true)
        }, 200);
    }

    const handleChange = e =>{
        setIntern({
            ...Intern,
            [e.target.name]:e.target.value 
        })
    }

    const handleChangeBranding = e =>{
        setBranding({
            ...Branding,
            [e.target.name]:e.target.value 
        })
    }

    const ValidateIsEmpty = (name, label) => {
        let flag = true;
        let errors = Intern.errors
        ValidateRequired(Intern[name], label, function (err) {
            errors[name] = err.RequiredField;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const validatEmail = (label) =>{
        let flag = true;
        let errors = Intern.errors
        validateEmail(Intern["email"], label, function (err) {
            errors["email"] = err.EmailId;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const Validatephone = (label) =>{
        let flag = true;
        let errors = Intern.errors
        validateMobile(Intern["mobile"], label, function(err){
            errors["mobile"] = err.MobileNumber;
            flag = err.formIsValid;
        })
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const ValidateCollege = (label) => {
        let flag = true;
        let errors = Intern.errors
        if (Intern.College !== null) {
            ValidateDDLRequired(Intern.College, 'College', function (err) {
                errors["College"] = err.DDLRequiredField;
                flag = err.formIsValid;
            });
        }
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const ValidateBrandIsEmpty = (name, label) => {
        let flag = true;
        let errors = Branding.errors
        ValidateRequired(Branding[name], label, function (err) {
            errors[name] = err.RequiredField;
            flag = err.formIsValid;
        });
        setBranding(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const validatBrandEmail = (label) =>{
        let flag = true;
        let errors = Branding.errors
        validateEmail(Branding["EmailId"], label, function (err) {
            errors["EmailId"] = err.EmailId;
            flag = err.formIsValid;
        });
        setBranding(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }
    
    const ValidateIntern=async()=>{
        let a = await ValidateIsEmpty('fstName', 'First Name')
        let b = await ValidateIsEmpty('LstName', 'Last Name')
        let c = await validatEmail('Email Id')
        let d = await Validatephone('Mobile Number')
        let e = await ValidateCollege('College')
        let f = await ValidateIsEmpty('Course', 'Course')
        //console.log(e);
        if( a=== true && b === true && c === true && d === true && e===true && f===true){
            return true;
        }
        else{
            return false
        }
    }

    const SaveIntern = async()=>{
        let val = await ValidateIntern()
        if(val){
            let firstname = Intern.fstName;
            let lastname = Intern.LstName;
            let EmailId = Intern.email;
            let mobile = Intern.mobile;
            let college = Intern.College.label;
            let course = Intern.Course;
            let Type = industry_r;
            saveContact(firstname, lastname, EmailId, mobile, college, course, Type).then(result=>{
                if(result.recordsets[0][0].MSG === 'saved'){
                    let MailFrom = 'connect@skillablers.com';
                    let setSubject = 'SKILLABLERS - INDUSTRY READINESS';
                    let bodyh = '<!DOCTYPE html><html><body>';
                    let bodyh1 = 'Hi '+firstname +',<br/>';
                    let bodyc = 'Thanks For Showing intrest, we will get back to you shortly<br/><br/>';
                    let bodyff = 'Regards,<br/> Skillablers Team <br/> <a href="https://www.skillablers.com/">www.skillablers.com</a>';
                    let bodyf ='</body></html>';
                    let setBody = bodyh+ bodyh1 + bodyc + bodyff + bodyf
                    sendMail(EmailId, MailFrom, setSubject, setBody).then(result1 =>{
                        toast.success("Thanks For Showing intrest, we will get back to you shorly.", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        closeAfterDelay()
                    })
                }else{
                    toast.error("Registraion Failed, Please try Again Later.", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }) 
        }
        const closeAfterDelay=async()=>{
            setTimeout(() =>{closPopup()},5000)
        }
    }

    const ValidateBranding  = async() =>{
        let a = await ValidateBrandIsEmpty("Fullname", "Full Name")
        let b = await validatBrandEmail('Email Id')
        let c = await ValidateBrandIsEmpty('Country','Country')
        let d = await ValidateBrandIsEmpty('mobile','mobile')
        let e = await ValidateBrandIsEmpty('company','company')
        let f =  await ValidateBrandIsEmpty('Designation','Designation')
        if(a === true && b=== true && c=== true && d === true && e === true && f === true)
        {
            return true
        }
        else{
            return false
        }
    }

    const saveBranding= async()=>{
        let val = await ValidateBranding()
    }
    
    const OnChangeSelect = selectedOptions =>{
        setIntern({
            ...Intern,
            College : selectedOptions
        })
    }

    const CollegeInputChange = (inputValue, { action }) => {
        if (action === "input-change") {
            if (inputValue.trim() !== "") {
                setIntern({
                    ...Intern,
                    College :  { value: 0, label: inputValue }
                })
                return inputValue
            }
            else {
                setIntern({
                    ...Intern,
                    College :  []
                })
                return ''
            }
        }
    }
    return (
        <>
            <div className='modal-overlay'></div>
            <div 
                className={OpenDelay ? isFrame === false? 'modal-box active mi-wdth' : 'modal-box active trans-background' :
                isFrame === false?  'modal-box mi-wdth': 'modal-box trans-background'}
            >
                <div className='modal-close' onClick={closPopup}><FontAwesomeIcon icon={faTimes} /> </div>
                
                    
                    <>
                        <div className='intern-modal'>CONTACT US</div>
                        <div className='iner-datasection'>Provide your details and we will connect with you shortly.</div>
                        <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                            <input 
                                type="text" 
                                className="input-reg" 
                                placeholder="First Name"
                                value={Intern.fstName} 
                                name='fstName' 
                                onChange={handleChange}
                                onBlur={()=>{ValidateIsEmpty('fstName', 'First Name')}} 
                            />
                            <span className='error'>{Intern.errors["fstName"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <input 
                                type="text" 
                                className="input-reg" 
                                placeholder="Last name" 
                                value={Intern.LstName} 
                                name='LstName' 
                                onChange={handleChange}
                                onBlur={()=>{ValidateIsEmpty('LstName', 'Last Name')}}  
                            />
                            <span className='error'>{Intern.errors["LstName"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <input 
                                type="text" 
                                className="input-reg" 
                                placeholder="Email Id" 
                                value={Intern.email} 
                                name='email' 
                                onChange={handleChange}
                                onBlur={()=>{validatEmail('Email Id')}}  
                            />
                            <span className='error'>{Intern.errors["email"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    placeholder="Mobile number"
                                    value={Intern.mobile} 
                                    name='mobile'  
                                    onChange={handleChange}
                                    onKeyPress={OnlyNumbers}
                                    onBlur={() =>{Validatephone('Mobile Number')}}   
                                />
                                <span className='error'>{Intern.errors["mobile"]}</span>
                            </div>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <AsyncSelect
                                    cacheOptions                               
                                    defaultOptions                                
                                    loadOptions={getCollegeOptions}                                
                                    isClearable={true}
                                    name={'College'}
                                    placeholder={'College'}
                                    value={Intern.College}
                                    inputValue = {(Intern.College === null || Intern.College.label === undefined) ? '' :Intern.College.label }
                                    styles={Customstyles}
                                    controlShouldRenderValue={false}                               
                                    components={{                                    
                                        Input                               
                                    }}
                                    onChange={OnChangeSelect}
                                    onInputChange ={CollegeInputChange}
                                    onBlur={()=>{ValidateCollege('College')}}
                                />
                                <span className='error'>{Intern.errors["College"]}</span>
                            </div>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">
                                <input 
                                    type="text" 
                                    className="input-reg" 
                                    placeholder="Course"
                                    value={Intern.Course} 
                                    name='Course' 
                                    onChange={handleChange}
                                    onBlur={()=>{ValidateIsEmpty('Course', 'Course')}} 
                                />
                                <span className='error'>{Intern.errors["Course"]}</span>
                            </div>
                        </div>
                        <div className='text-aligen-center d-fully-cent '>
                            <button 
                                className='ycc-home-button z-ined-2postition-realtive  login-button-padding' 
                                onClick={SaveIntern}
                            >
                                Submit
                            </button>
                        </div>
                    </> 
                   
                
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
        </>
    )
}

export default React.memo(Modal); 