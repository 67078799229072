import React from "react";
// import Carousel from 'react-bootstrap/Carousel';

import $ from 'jquery';
import Banner from '../../Assests/Images/Banner-img.png';
import { GetAllJobs, GetFunctionalSkillsCompanyName } from "../Indivi_jobs_component/indivi_methods";
import DefaulCom from '../../Assests/Images/defaultProfil.jpg';
import Slider from "react-slick";
import NoCateg from '../../Assests/Images/Layer 22.1.png'
import { IndusURL, SphereUrl, UploaderUrl } from '../../Assests/js/config';
import {IndividualUrl} from '../../Assests/js/config';
import {IndividualUrllogin} from '../../Assests/js/config';

import { Routing } from "../../Assests/js/routing";
import { HashRouter, Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { isNull } from 'lodash';
import EDClogofullcurved02C from '../../Assests/Images/EDC_logo_full_curved-02C.png';
import PuffLoader from "react-spinners/PuffLoader";

import RefreshsectorIcon from '../../Assests/Images/RefreshsectorIcon.svg'



// import svp from '../../Assests/Images/visual.png';
import svp1 from '../../Assests/Images/svp1.png';
import svp from '../../Assests/Images/VisualProfiling.png';
// import svp from '../../Assests/Images/Group 90.png';
import upskill from '../../Assests/Images/upskill.png';
// import P360 from '../../Assests/Images/p360.png';
import P360_1 from '../../Assests/Images/P360_1.png';
import P360 from '../../Assests/Images/Profile-360w.png';
import online1 from '../../Assests/Images/online1.png';
import online from '../../Assests/Images/Online-interview.png';
// import job from '../../Assests/Images/job.png';
// import job from '../../Assests/Images/Job-Connect.png';
import job1 from '../../Assests/Images/job2.png';
import job from '../../Assests/Images/Job-Connect.png'
import cousell from '../../Assests/Images/cousell.png';
import DtsSvp from '../../Assests/Images/SVP.png';
//import JOB1 from '../../Assests/Images/job1.png';
import profil360 from '../../Assests/Images/profile_360.png';
import upskillinggs from '../../Assests/Images/upskillinggs.png';
import onlineinterview from '../../Assests/Images/onlineinterview.png';
import careerCoun from '../../Assests/Images/careerCoun.png';
import { components } from 'react-select';
import jobsbanner2resume from '../../Assests/Images/jobsbanner2resume.png';
import jobsbanner3question from '../../Assests/Images/jobsbanner3question.png';
import bengaluru from '../../Assests/Images/bengaluru.png';
import UTIndiaNextStimulus from '../../Assests/Images/UTIndiaNextStimulus.png';


import Individual from '../Individual_component/Individual';
import Industry from '../Industry_component/Industry';
import Institute from '../Institute_component/Institute';

import onlineinterview_white from '../../Assests/Images/onlineinterview-white.png'
import profile360score_white from '../../Assests/Images/profile360score-white.png'
import visualprofiling_white from '../../Assests/Images/visualprofiling-white.png'
import jobconnect_white from '../../Assests/Images/job-connect-white.png'




import profile360_light from '../../Assests/Images/profile360-light.png'
import profile360_dark from '../../Assests/Images/profile360-dark.png'
import interview_dark from '../../Assests/Images/interview-dark.png'
import interview_light from '../../Assests/Images/interview-light.png'
import jobconnect_dark from '../../Assests/Images/jobconnect-dark.png'
import jobconnect_light from '../../Assests/Images/jobconnect-light.png'
import visualprofile_dark1 from '../../Assests/Images/visualprofile-dark1.png'
import visualprofile_light from '../../Assests/Images/visualprofile-light.png'

import Apple from '../Aatce_component/aatce'
import history from '../../histoty';
import {GETJobDisplayName} from './carrer-gap-method'


const Input = (props) => <components.Input {...props} isHidden={false} />;

var similarvarible=0

const fade = {
    dots: false,

    infinite: true,
    fade: true,
    cssEase: 'linear 0.5s',
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
}

const CustomStyleForJobSearch = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'black',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: '#20A8D8',
            color: 'white',
        },
        background:'white'
    }),
    noOptionsMessage: () => ({
        // color: 'var(--asycSelectDropDownText)',
        color: '#7f7f7f',
        background: 'white',
        textAlign: "center",
        fontSize: "12px",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: '#00000',
        background: 'white',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "0px 10px 10px 10px" : "0px 10px 10px 10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px 7px' : '2.1px 0px 1.1px 7px',
        border: 'none',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '6vh' : "39px",
        // paddingRight: (window.matchMedia("(max-width: 568px)").matches) ? "35px" : "7px",
        paddingRight: "35px",
        zIndex: 2,
        alignItems: "baseline",
        paddingTop: "1vw"

    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        textTransform: "capitalize",
        width: '100%',
        position: 'absolute',
        alignItems: "center",
        display: "contents",

        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    loadingMessage: () => ({
        // color: 'black',
        color: '#7f7f7f',
        background: 'white',
        textAlign: "center",
        fontSize: "12px",
    }),
    menu: () => ({
        color: 'black',
        background: 'white',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        textTransform: "capitalize",
        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    placeholder: () => ({
        // color: 'var(--inputHeading)',
        color: '#7f7f7f',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.38vh' : "12px",
        paddingLeft: (window.matchMedia("(min-width: 1824px)").matches) ? '6px' : "1px",
        //textTransform: "Normal",
        position: 'absolute',
        top: (window.matchMedia("(min-width: 1824px)").matches) ? '' : " 1vw",


    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        // position: "relative",
        overflow: "hidden",
        // zIndex: "3"
    }),
}

const PFCfiltedOptions = (inputValue, callback) => {

    if (inputValue.length > 1) {
        callback(GetFunctionalSkillsCompanyName(inputValue));
    }

}











class CareerBlog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeData: 1,
            skills: [],
            jobs: [],
            company: []
            , categ: [],
            catcomplogo: [],
            // searchSkills : '',
            searchSkills: [],
            textactive: 1,
            Tab: 1,
            PFCfiltredselected: '',
            CategHalf: [],
            joblistcareergap:[],
            joblistwithmoredetail:[],
            hashfun:true,
            similar:[],
            courseTypesearch:'',
            existlist:[],
            loader:true,
            setindex:0

        }
    }

    componentDidMount() {

        let resp = ~~parseInt(history.location.hash.substring(history.location.hash.length-1, history.location.hash.length));
        let con = parseInt(resp === 0? 0:resp);
        // console.log('con',con)
        if(con==0){
            window.$('html,body').animate({
                scrollTop: 0
            });
        }else{
            window.$('html,body').animate({
                scrollTop: $("#pflow").offset().top
            });
        }

        GetAllJobs()
            .then(result => {

                
                let skl = result.recordsets[11]
                let job = result.recordsets[2].filter(x=> x.ActiveJob === 1 && x.IsArchived === false && x.IsPublished === true ).sort((a,b)=> a.DaysModified > b.DaysModified ? -1 : 1)
                let company = result.recordsets[0].filter(y=> job.findIndex(x=> x.EntityUID === y.EntityUID ) !== -1)
                //console.log('company-->',company)
                let categ = result.recordsets[12].filter(y=> job.findIndex(x=> x.MSTFunctionalAreaId === y.MSTFunctionalAreaId ) !== -1)
                let catecomplogo = result.recordsets[13].filter(y=> categ.findIndex(x=> x.MSTFunctionalAreaId === y.MSTFunctionalAreaId ) !== -1
                                    && company.findIndex(com => com.Logo === y.company) !== -1 )
                // console.log('catecomplogo',catecomplogo ,company,
                // result.recordsets[13].filter(y=> categ.findIndex(x=> x.MSTFunctionalAreaId === y.MSTFunctionalAreaId ) !== -1
               
                // )
                // )
                let arr = []
                for (let i = 0; i < (result.recordsets[12].length) / 2; i++) {
                    arr.push(i)
                }

                this.setState({
                    skills: skl,
                    jobs: job,
                    company: company,
                    categ: categ,
                    CategHalf: arr,

                    catcomplogo: catecomplogo
                })
                // document.getElementById("companyNAme").innerHTML = document.getElementById("companyNAme").innerHTML.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase());
            })

            GETJobDisplayName().then(results=>
            {
                //  console.log("ressss",results.recordsets[0])
                 let jblist=[]
                 let joblistmoredetail=[]
                 jblist=results.recordsets[0]
                 joblistmoredetail=results.recordsets[1]
                //  let a =jblist.filter(z=>z.JobTitleId===x.JobTitleId)
                let arrvalue=[]
                {jblist.map((x, i) =>
                {
                  jblist.filter(z=>z.JobTitleId===x.JobTitleId).map((y,j)=> 
                   x.JObDisplayName!=y.JObDisplayName &&
                   arrvalue.push(y.JobDisplayNameId)
                )}
              )}
                this.setState({
                    joblistcareergap:jblist,
                    existlist:jblist,
                    joblistwithmoredetail:joblistmoredetail,
                    similar:arrvalue,
                    loader:false

                })
               
            })




    }




  
    onInputChange = name => (inputValue, { action }) => {

        if (action === "input-change") {
            if (name === "searchSkills") {
                this.setState({
                    searchSkills: inputValue
                })
            }


        }




    }

    ClearData = () => {
        this.setState({
            searchSkills: []
        })
    }


    OnChangeSelect = name => (selectedOption) => {

        if (name === 'searchSkills') {
            if (isNull(selectedOption)) {
                this.setState({
                    searchSkills: []
                })


            }
            else {
                this.setState({
                    searchSkills: selectedOption
                })
            }
        }







    }




    searchforJobs = () => {

        if (Object.keys(this.state.searchSkills).length > 0) {
            let Obj = {
                Skills: {
                    value: this.state.searchSkills.value,
                    label: this.state.searchSkills.label, Type_MST: "skills", Source: 'Master'
                }
            }


            let URL = IndividualUrl + '?' + btoa(JSON.stringify(Obj))
            let a = document.createElement('a');
            a.href = URL;
            a.target = "_blank"
            a.click();

        }
    }

    componentWillUnmount() {

    }

    OpenSkillset = (SkillsName) => {
        let Obj = {
            Skills: {
                value: this.state.skills.filter(x => x.MSTSkillName === SkillsName)[0].MSTSkillsId
                , label: SkillsName, Type_MST: "skills", Source: 'Master'
            }
        }
        // let URL = "https://indir.skillablers.com/indi-JobSearch" +'?' + btoa(JSON.stringify(Obj))  
        let URL = IndividualUrl + '?' + btoa(JSON.stringify(Obj))
        let a = document.createElement('a');
        a.href = URL;
        a.target = "_blank"
        a.click();
    }

    Openfunctionarea = (fun) => {
        let Obj = {
            functional: {
                value: this.state.categ.filter(x => x.MSTFunctionalAreaName === fun)[0].MSTFunctionalAreaId,
                label: fun
            }
        }
        let URL = IndividualUrl + '?' + btoa(JSON.stringify(Obj))
        let a = document.createElement('a');
        a.href = URL;
        a.target = "_blank"
        a.click();
    }



    visuaprofiling = (id) => {

        this.setState({
            activeData: id,
            textactive: 0
        }, () => {
            setTimeout(() => {
                this.setState(
                    {
                        textactive: id
                    }
                )
            }, 900)


        })
    }


    OpenJobs = () =>{
        let Obj = {LoadData: true}
        let URL = IndividualUrl + '?' + btoa(JSON.stringify(Obj))
        let a = document.createElement('a');
        a.href = URL;
        a.target = "_blank"
        a.click();   
    }

    openindi=()=>
    {
        window.open(IndividualUrllogin+'/indi-login' ,'_blank')
    }

    opindividualcareergap = (jobid) =>{
        let Obj = {ServiceTypeID:1021,
            CareerBlogjobid:jobid
        }
        let URL = IndividualUrllogin+'/indi-registration' + '?' + btoa(JSON.stringify(Obj))
        let a = document.createElement('a');
        a.href = URL;
        a.target = "_blank"
        a.click();   
    }

    openautosctollhighlight=(subjob)=>
    {
       let cls
      
       let filterarray=this.state.joblistcareergap.filter(x=>x.JobDisplayNameId===subjob)

       let RemovefilterElemet=this.state.joblistcareergap.filter(x=>x.JobDisplayNameId!==subjob)
      
       RemovefilterElemet.unshift(filterarray[0]);

        this.setState({
            joblistcareergap:RemovefilterElemet
        })
        setTimeout(() => {
            $('.indu-clientscroll').animate({
                scrollTop: 0
            },
                'slow');
            let scrolling = $("#scrltopindi").offset().top-100
                $('html,body').animate({
                    scrollTop: scrolling
                },
                'slow');
            cls=document.getElementsByClassName("trendingjobsbackground")[0]
            cls.classList.add("activitu")
            
        },1000)
        setTimeout(() => {
            cls.classList.remove("activitu")
        },3000)

       }
 

    handleChangeforsearch =(event)=> {
        var existinglist  = this.state.existlist;
        var newlist = event.target.value === '' || event.target.value === undefined?existinglist:this.state.joblistcareergap.filter(x=>x.JObDisplayName.toLowerCase().indexOf(event.target.value.toLowerCase())>-1)
        this.setState({
            courseTypesearch:event.target.value,
            joblistcareergap:newlist
        })

      }

      clearfilter=()=>
      {
        GETJobDisplayName().then(results=>
            {
                let jblist=[]
                let joblistmoredetail=[]
                 jblist=results.recordsets[0]
                 joblistmoredetail=results.recordsets[1]
                let arrvalue=[]
                {jblist.map((x, i) =>
                {
                  jblist.filter(z=>z.JobTitleId===x.JobTitleId).map((y,j)=> 
                   x.JObDisplayName!=y.JObDisplayName &&
                   arrvalue.push(y.JobDisplayNameId)
                )}
              )}
                this.setState({
                    joblistcareergap:jblist,
                    existlist:jblist,
                    joblistwithmoredetail:joblistmoredetail,
                    similar:arrvalue,
                    courseTypesearch:'',
                    loader:false

                })
               
            })
      }

   
    








    render() {
        let Settein1, setting3, setting2
        if (window.matchMedia("(min-width: 568px)").matches) {
            Settein1 = {
                dots: false,
                infinite: true,
                speed: 700,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }
                ]
            }

            setting3 = {
                dots: false,
                infinite: false,
                speed: 700,
                slidesToShow: 6,
                slidesToScroll: 6,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 6,
                            infinite: false,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 860,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 5
                        }
                    },
                    {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
            setting2 = {
                dots: false,
                infinite: false,
                speed: 700,
                slidesToShow: 5,
                slidesToScroll: 1,
                // autoplay: true,
                autoplaySpeed: 2000,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: false,
                            dots: false,
                            autoplay: true,
                            autoplaySpeed: 2800,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            autoplay: true,
                            infinite:  false,
                            autoplaySpeed: 2800,
                        }
                    },
                    {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            autoplay: true,
                            infinite: false,
                            autoplaySpeed: 2800,
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            }
        }
        else {
            Settein1 = {
                dots: false,
                infinite: true,
                speed: 700,
                slidesToShow: 2,
                slidesToScroll: 2

            }

            setting3 = {
                dots: false,
                infinite: true,
                speed: 700,
                slidesToShow: 1,
                slidesToScroll: 1
            }
            setting2 = {
                dots: false,
                infinite: true,
                speed: 700,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2800,
            }
        }
        let companysetting
        companysetting = {
            dots: false,
            infinite: false,
            speed: 700,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        infinite: false,

                    }
                },
                {
                    breakpoint: 860,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
        return (
            <div>
                <div className="job-main-container backgroundclrnoneingap">
                    <div class="pain job-container-new padding-job-conatiberss">
                        <div class="job-bannerwrpa bgclbanner-Carrergap">

                            <Slider {...fade}>
                                <div  ><img src={Banner} alt="19" width="100%" /></div>
                                <div ><img src={jobsbanner2resume} alt="19" width="100%" /></div>
                                <div><img src={jobsbanner3question} alt="19" width="100%" /></div>
                            </Slider>

                        </div>
                    </div>
                    <div className="blogheadtextaligncent"><div className="apple_heading capheading">What is Career Gap Analysis?</div></div>
                    <div className="carresblogsubwrap">
                        <div className="E-Reference-platformText">
                        Career gap analysis refers to the process of evaluating the disparity between an individual's current skills, knowledge, and qualifications and the requirements of a desired career or job role. This analysis helps individuals identify the gaps in their professional development and take steps to bridge those gaps.
                        </div>
                        <div className="blogheadtextaligncent"><div className="apple_heading capheading">Trending Jobs</div></div>
                        <div className='row martop-searchnerw' id="scrltopindi">
                        <div className='col-lg-6 col-10 col-10 '>
                        <label className="pure-material-textfield-outlined">
                                <input
                                    placeholder=" "
                                    maxLength="200"
                                    onChange={this.handleChangeforsearch}
                                    value={this.state.courseTypesearch}
                                /> 
                                <span>Search JOB*</span> 
                            </label>
                        </div>
                            <div className='Congrats-leaderbordredirect-iaq col-lg-2 col-md-2 col-sm-2 col-2 px-0'>
                                    <div className='pd-left-iaq-coin arrowiconpointIAQ mx-0'><span tooltip="Clear Filter" flow="down" >
                                        <img src={RefreshsectorIcon} className="cursorpoint" onClick={this.clearfilter}/>
                                    </span></div>
                            </div>
                        </div>
                        {this.state.joblistcareergap.length>0?
                        <div className="indu-clientscroll padingremove ">
                        {this.state.joblistcareergap.map((x, i) =>
                        <div key={i}>
                         <div className="row trendingjobsbackground mx-0">
                            {/* {console.log("x",x)} */}
                            <div className="col-lg-9">
                               <div className="jobtitlename our-team-deig " id="newtitlejob">{x.JObDisplayName}</div>
                               <div className="desctextblog E-Reference-platformText">
                               <div>{x.JObDisplayDescription}</div>
                               </div>
                               
                               <div className="desctextblog">
                               <div><span className="boltext-gap">Skills:
                               </span>
                               {this.state.joblistwithmoredetail.filter(z=>z.JobDisplayNameId===x.JobDisplayNameId).map((d)=> 
                                <span>
                                {d.Skill},
                                </span>
                                )}
                               </div>
                               </div>
                               <div className="desctextblog similarjobsalignspace">
                                
                                <div className="row mx-0">
                                {this.state.similar.includes(x.JobDisplayNameId) &&
                                   <div className="boltext-gap">Similar Jobs</div> 
                                }
                            
                                {this.state.joblistcareergap.filter(z=>z.JobTitleId===x.JobTitleId).map((y,j)=> 
                                    <>
                                    {x.JObDisplayName!=y.JObDisplayName &&
                                    <>
                                    <div className="col-lg-4"><li className="liststylejob-gap cursorpoint" onClick={()=>this.openautosctollhighlight(y.JobDisplayNameId)} >{y.JObDisplayName}</li></div>
                                    </>
                                    }
                                    </>
                                )}
                                   

                                </div>
                               </div>
                               
                            </div>
                            <div className="col-lg-3 disfleendslaryexp">
                                <div className="desctextblog martopslaryexp ">
                                <div><span className="boltext-gap"><i class="fa fa-inr" aria-hidden="true"></i></span>{x.JobDisplaySalaryRange}</div>
                                <div><span className="boltext-gap"><i class="fa fa-briefcase" aria-hidden="true"></i></span>{x.JObDisplayExperience}</div>
                                
                                </div>
                            </div>
                            {/* <div className="analyzeend"><button id="" className="meet-our-team">Analyze</button></div> */}
                            <div className="analyzetext"><span 
                            // onClick={()=>this.opindividualcareergap(x.JobTitleId)}
                             onClick={()=>this.opindividualcareergap(x.JobDisplayNameId)}>Analyze Your Readiness</span></div>
                        </div>
                        </div>
                        )}
                        </div>
                        :
                        <>
                        {this.state.loader===false ?
                        <div className="aligntetcentsearch E-Reference-platformText">
                            No Jobs Found
                        </div>
                        :
                        <div className="load-alignment">
                          <PuffLoader  color="#0572aa" />                                                       
                          </div>
                        }
                        </>
                        }
                    </div>
                </div>
               


            </div>
        )
    }
}
export default CareerBlog


