import axios from "axios";
import {APIUrl} from '../../Assests/js/config';
export function saveBrandingDetails(FullName, EmailId, Country, mobile, company, Designation, Type) {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/saveBrandingDetails",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }),
        data: {
            FullName
            , EmailId
            , Country
            , mobile
            , company
            , Designation
            , Type
        }
    })
    .then(result => {
        return result;
    }).catch(e => {
        
    });
}

export function GetAllCountryCodeList(Countrycode) {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/get-Countrycode-list",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }),
        data: {
            Countrycode
        }
    })
    .then(result => {
        return result;
    }).catch(e => {
      
    });
}