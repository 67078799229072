import { APIUrl } from '../../Assests/js/config';
import axios from 'axios';

export function CheckUserMob(STDCode, EventId, MobileNumber) {
    return axios({
        method: 'POST',
        url: APIUrl + '/api/CheckMobNumberExists',
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        data: {
            STDCode,
            EventId,
            MobileNumber
        }
    })
        .then(result => {
            return result.data;
        }).catch(e => {
            console.log(e);
        });
}

export function CheckUsermail(EventId, EmailId) {
    return axios({
        method: 'POST',
        url: APIUrl + '/api/CheckEmailIdExists',
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        data: {
            EventId,
            EmailId
        }
    })
        .then(result => {
            return result.data;
        }).catch(e => {
            console.log(e);
        });
}

export function GetAllTrackList(TrackId) {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/get-track-list",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        data: {
            TrackId
        }
    })
    .then(result => {
        return result;
    }).catch(e => {
        console.log(e);
    });
}

export function GetAllCountryCodeList(Countrycode='') {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/get-Countrycode-list",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }),
        data: {
            Countrycode
        }
    })
    .then(result => {
        return result.data;
    }).catch(e => {
        console.log(e);
    });
}

export function GetEventDetails() {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/GetEventDetails",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })
    })
    .then(result => {
        return result.data;
    }).catch(e => {
        console.log(e);
    });
}


export function SaveYCC2022Reg(FName, LName, EmailId, Password, MobileNo, Eventid, CountryCode, CatogoryId,  CategoryIds, CategoryValue,  RegCode) {
    return axios({
        method: 'POST',
        url: APIUrl + "/api/SaveYCC2022Reg",
        withCredentials: true,
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        data: {
            FName
            , LName
            , EmailId
            , Password
            , MobileNo
            , Eventid
            , CountryCode
            , CatogoryId
            , CategoryIds
            , CategoryValue
            , RegCode
        }
    })
    .then(result => {
        return result;
    }).catch(e => {
        console.log(e);
    });
}

export function GetAllCollege(EventId,InputValue) {
    return axios({ 
        method: 'POST', 
        url:APIUrl + "/api/GetInstitution",
        withCredentials: true, 
        headers: new Headers({ 
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
        }), 
        data: { 
            InstituteName:InputValue,
            EventId:EventId 
        } 
    })
    .then(result => { 
        let res = result.data; 
        let CollegeList = res.recordset; 
        return CollegeList; 
    }).catch(e => { 
        console.log(e); 
    }); 
}

export function GetMasterList(InputValue, category) {
    return axios({ 
        method: 'POST', 
        url:APIUrl + "/api/GetMasterList",
        withCredentials: true, 
        headers: new Headers({ 
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
        }), 
        data: {
            InputValue:InputValue,
            category:category
        } 
    })
    .then(result => { 
        let res = result.data; 
        let CollegeList = res.recordset; 
        return CollegeList; 
    }).catch(e => { 
        console.log(e); 
    }); 
}

export function GetAllInstitutionCategoryByEvent(EventId, InputValue, category) {
    return axios({ 
        method: 'POST', 
        url:APIUrl + "/api/GetAllInstitutionCategoryByEvent",
        withCredentials: true, 
        headers: new Headers({ 
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
        }), 
        data: {
            EventId:EventId,
            InputValue:InputValue,
            category:category
        } 
    })
    .then(result => { 
        let res = result.data; 
        let CollegeList = res.recordset; 
        return CollegeList; 
    }).catch(e => { 
        console.log(e); 
    }); 
}