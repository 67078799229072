import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {OnlyNumbers , validateEmail, ValidateRequired} from '../General/validation';
import { ToastContainer, toast } from 'react-toastify';
// import AJ  from '../../Assests/Images/AJ-20220512130721.png';
// import baskar  from '../../Assests/Images/Bhaskar-20220512130947.png';
import jyothi  from '../../Assests/Images/Jyothi-20220512130733.png';
import ravi  from '../../Assests/Images/Ravi.png';
// import sasikumar  from '../../Assests/Images/sasikumar.png';
import Sindhu  from '../../Assests/Images/Sindhu.png';
// import sunil  from '../../Assests/Images/Sunil-20220512130813.png';
import suresh  from '../../Assests/Images/Suresh-20220512131009.png';
import VIVEKANANDA from '../../Assests/Images/VIVEKANANDA.png';
import ALOkKUMAR from '../../Assests/Images/ALOkKUMAR .png';
import RAVEENDRAN from '../../Assests/Images/raveendran.JPG';
import ANIL from '../../Assests/Images/ANIL.png';
import OurTeam from './OurTeam';
const NOOP = () => { }

function OurTeamModal({ isFrame, Url, close = NOOP, isIntern , id }) {
    const [OpenDelay, setOpenDelay] = useState(false)
    const[idconditonn,setidconditonn]= useState(id)
   const [ ourTeamData , setourTeamData] = useState([
    {id: 1 , name : 'RAVI KUMAR RADHAKRISHNAN' , desig : 'CO-FOUNDER & CEO' ,
      linkedIn : 'https://in.linkedin.com/in/ravikumarradhakrishnan?trk=public_profile_browsemap' , img: ravi ,
    dec: '  Ravi’s passion for innovating technology solutions (creatively) to solve real-world challenges is legendary and reflected in the origins and evolution of EDC CTS, Skillablers, and Exotyq. His commitment, devotion & compassion to create high performance, and talented teams go beyond the realms of entrepreneurship, and he is a strong believer in the power of humane relationships. Ravi’s vision to create pioneering and exceptional purpose-driven organizations, is founded in the core of “creative technology solutions” - the force of which is experienced in innovative solutions that are cherished by numerous stakeholders and businesses worldwide. Besides architecting the next big idea, he loves his spiritual sojourns, meditating, photography, travelling, wildlife, and mentoring youth to believe in themselves.'} ,
//     {id: 2 , name : ' SASI KUMAR RADHAKRISHNAN' , desig : 'DIRECTOR, BUSINESS DEVELOPMENT  ' ,
//     linkedIn : 'https://www.linkedin.com/in/sasi-kumar-474762?trk=public_profile_browsemap' , img : sasikumar,
//   dec: 'Sasi is a senior industry business leader with over 30 years of experience in marketing & sales in leading multinationals. He is a pioneer in museums, experiential spaces and technologies, and heads the company’s business development activities, strategies and revenues. Sasi is responsible for positioning, project innovation, client management and servicing key government & corporate accounts. He holds a Master’s in Agrisciences degree and has been the recipient of a NASSCOM award for his innovative ideas on “ICT Interventions in Experiential Marketing“. While he is not busy mapping the next big business revenue opportunity in the phygital world, Sasi loves experimenting with recipes, quizzing, reading and connecting with nature in his garden and in the forest.'},
  {id: 3 , name : 'SINDHU RAMANATHAN' , desig : ' CO-FOUNDER & CIO' ,
  linkedIn : 'https://in.linkedin.com/in/sindhu-ramanathan-5789356?trk=public_profile_browsemap' , img : Sindhu,
dec: 'Sindhu has been the driving force behind our creative technology solutions for the last 25+ years. She leads and mentors the technology & creative teams to deliver great solutions, always encouraging them to advance their skills and wade through challenges. Sindhu takes the driver’s seat to navigate, develop & implement the group’s technology vision and business goals. Abreast with the latest technologies & trends, Sindhu navigates teams and solutions for UI/UX, web, mobile, enterprise applications, rich internet, metaverse, AR / VR / MR and tech platforms. When she is not intensely coordinating with customers and teams (either from office or home), she is footloose travelling, pampering her pets, trying out different cuisines and losing herself in gardening.'},
// , {id: 4 , name : 'ANTHONY JOHNSON' , desig : 'DIRECTOR & ADVISOR' ,
// linkedIn : 'https://www.linkedin.com/company/edc-creative-technology-solutions' , img: AJ,
// dec: 'Anthony is a seasoned entrepreneur and a strong believer of working with businesses that are built on innovation, trust and global standards of performance. He brings a strong focus on quality and sets international benchmarks in everything we do as an organization. Anthony has been instrumental in forging strategic alliances and business expansion for EDCCTS in Middle East, Europe and other international markets. He established Falcon Technical & Trading Co. (FTTC), Qatar in 1989, and is today recognized as the industry-leader in the supply, installation and commissioning of industrial/commercial kitchen equipment. FTTC is the exclusive dealership for brands like Hobart, Foster, Southbend, Traulsen, Ambach, MBM, Houno, Euroinox and several others in the region.'},
// {id: 5 , name : 'SUNIL SUBRAMANIAN' , desig : 'SVP, BUSINESS DEVELOPMENT' ,img: sunil,
// linkedIn : 'https://in.linkedin.com/in/sunil-subramanian-a3a9111?trk=public_profile_browsemap' ,
// dec: ' Sunil leads the creative tech solutions sales & business development for the Indian & global markets. He comes from a strong creative consulting and tech solutions sales background and has over 26 years of experience solving complex business challenges with a creative imagineering tech approach for various businesses and clients in India, UK, USA, Europe, Middle East, and African markets. He holds a Master’s in Communications degree and is a consummate storyteller and creative navigator who has spearheaded some of the country’s finest corporate experience centres by shaping ideas, design, tech, content and business experience. Apart from his love for everything wild, Sunil likes to travel and is convinced there is a dark side of the moon, with his profound love for music.'},
// {id: 6 , name : 'DR. B. BHASKAR' , desig : 'DIRECTOR, BIO-IT SERVICES & SOLUTIONS' ,img:baskar,
// linkedIn : 'https://www.linkedin.com/in/brahatheeswaran-bhaskar-3715084?trk=public_profile_browsemap' ,
// dec: '"Excellence is an art won by training and habituation. We do not act rightly because we have virtue or excellence, but we rather have those because we have acted rightly. We are what we repeatedly do. Excellence, then, is NOT an act but a habit." ~ ARISTOTLE . Coming from an extensive scientific research background in biological sciences, Dr. B. Bhaskar considers himself passionately, intellectually curious about science & technology. Being an experimentalist at heart & having been trained to have an analytical mind, finding time & space for "problem-solving" is his cup-of-tea. After spending a highly rewarding 24-year research career in academic & industry circles in India, USA & Canada, Dr. B. Bhaskar has settled himself in the rapidly evolving IT-sector. He can provide a global perspective to any challenge we ideate for solutions. A zealot for principles, precision, accuracy, detail orientation & perfection but a pragmatist at heart.'},
{id: 7 , name : 'SURESH NARAYAN' , desig : ' DIRECTOR & CTO' , img: suresh,
linkedIn : 'https://in.linkedin.com/in/suresh-narayan-70182323?trk=public_profile_browsemap' ,
dec: ' Suresh leads Skillablers Technologies and is a senior industry leader with close to 30 years of international experience shaping revenue, strategy, and technology in diverse industries. He has an exceptional track record in building high-impact, innovation focused teams of technical and functional experts. Currently he is creating and curating careers & skills for youth with a vision for industry ready employability. An alumnus of Delhi University and a certified Hermann Practitioner (HBDI - Hermann Brain Development Instrument), Suresh journeys to discover temples and their lost tales of culture, architecture and everything in between, that is while he not digging into the latest tech gadget and tearing it apart.'},
{id: 8 , name : 'JYOTHI SATYANATHAN' , desig : ' DIRECTOR - BUSINESS STRATEGY' , img: jyothi,
linkedIn : 'https://www.linkedin.com/in/jyothisatyanathan?trk=public_profile_browsemap' ,
dec: 'Jyothi is a senior industry leader with close to 33 years of experience in IT across the Asia Pacific region. He began his career as a technocrat and found his passion is sales and marketing very early in his career. From leading Wipro’s PC business to building new business revenues for IBM, he has led large teams with revenue responsibility of over 1500 crores. Jyothi has an exceptional track record for growing revenue and profit across business lines and is accomplished in building sales teams for high performance. An Alumnus of NTTF Electronics Centre, Jyothi comes with a philosophy that nothing is impossible given the right motivation and resources. He relishes indulging with his gastronomic interests and discovering artisans, creators and sustainable products with a story and soul.'},
{id: 10 , name : 'VIVEKANANDA REVAIAH' , desig : 'DIRECTOR - FINANCE' , img: VIVEKANANDA,
dec: 'An FCA graduate and a chartered accountant by profession, he comes armed with two decades of experience in financial growth of companies.'},

// {id: 11 , name : 'ALOK KUMAR SINGH' , desig : 'DIRECTOR - CORPORATE STRATEGY ' , img: ALOkKUMAR,

// dec: 'An IIT and IIM alumnus with 25+years of experience in management responsibilities in companies including Infosys, and Azure Capital.'},
{id: 11 , name : 'RAVEENDRAN KASTHURI' , desig : 'DIRECTOR - STRATEGIC INITIATIVES' , img: RAVEENDRAN,
//linkedIn : 'https://in.linkedin.com/in/raveendran-kasthuri' ,
dec: 'An accomplished executive with a proven track record in strategic planning, financial management, and operational execution. Recognized for delivering exceptional results through cutting-edge strategies that drive organizational growth and maximize profitability. Possesses strong interpersonal skills, a collaborative mindset, and an unwavering commitment to excellence. Proficient in cultivating high-performing, cross-functional teams.',
keyrolehead:'Key Credentials:',keyrole:'<p>Board Member, NIT Calicut.<br>Former Group CEO, ULCCS, and CEO, ULTS.<br>Distinguished roles at IBM: Managing Director (Global Integrated Account), Vice President Integrated Operations, Director Resource and Capacity Management, Director and Account Executive for a global account. Certified IBM Senior Project Manager and PMP from PMI, showcasing excellence in project management.<br>Holder of an Executive Leadership Certification from Cornell University.<br>Academic background includes an ME from IISc Bangalore and a B.Tech from REC (NIT) Calicut.<br>Recipient of prestigious awards, including the IBM Gerstner Award for Best Account and the Leadership Excellence Award. A seasoned leader with a refined blend of strategic vision and extensive industry experience.</p>'},
{id: 12 , name : 'ANIL KUMAR B' , desig : 'DIRECTOR - LEGAL' , img: ANIL,
dec: 'Legal Consultant in Indirect Taxesand Legal Metrology with 25+years of government and corporatelaw experience.'}


])

    useEffect(() => {
        setTimeout(() => {
            setOpenDelay(true)
        }, 200);
        window. $('.modal-overlay').click(function(e) {
            if (!window.$(e.target).closest('.modal-box').length ||  !window.$(e.target).closest('.svg-play-icons').length){ closPopup() }
        });
    })

    const closPopup = () => {
        setOpenDelay(false)
       
        setTimeout(() => {
            close(true)
        }, 200);
    }
   

    return (
        <>
            <div className='modal-overlay'></div>
            <div className={OpenDelay ?  'modal-box  active our-team-popup-width' :  'modal-box our-team-popup-width'}>
                <div className='signinmodelclose' onClick={closPopup}><i class="fa fa-times colr-close" aria-hidden="true"></i> </div>
                <div className='row mx-0 col-12 px-0 our-team-popup-head'>
                    <div className='col-4 px-0'>
                        <img src={ourTeamData.filter(x=>x.id === id)[0].img} className="our-team-popup-image " />
                    </div>
                    <div className='col-8 px-0 our-meme-name'>
                        <div className='our-team-name-hd'>{ourTeamData.filter(x=>x.id === id)[0].name} </div>
                        <div className='our-team-deig'> {ourTeamData.filter(x=>x.id === id)[0].desig} </div>
                        {idconditonn =='10' || idconditonn == '12' || idconditonn == '11'?
                            <div> </div>
                        :
                            <div>
                                {ourTeamData.filter(x=>x.id  === id )[0].linkedIn !==  ""&&
                                    <div className='our-team-connect-content'>Connect via 
                                        <a href={ourTeamData.filter(x=>x.id === id)[0].linkedIn}><i class="fa fa-linkedin"></i></a>
                                    </div>
                                }
                            </div>             
                        }
                    </div>     
                </div>
                <div className='our-team-dec'>
                    {ourTeamData.filter(x=>x.id === id)[0].dec}
                </div>
                {idconditonn == '11' &&
                    <div className='our-team-dec'>
                        <div className='keyhead'>{ourTeamData.filter(x=>x.id === id)[0].keyrolehead}</div>
                        <div className='keydesc'><div dangerouslySetInnerHTML ={{__html:ourTeamData.filter(x=>x.id === id)[0].keyrole}}></div></div>
                    </div>
                }
            </div>
        </>
    )

}

export default React.memo(OurTeamModal); 