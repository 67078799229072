import React from "react";
// import Carousel from 'react-bootstrap/Carousel';

import $ from 'jquery';
import Banner from '../../Assests/Images/Banner-img.png';
import { GetAllJobs, GetFunctionalSkillsCompanyName } from "./indivi_methods";
import DefaulCom from '../../Assests/Images/defaultProfil.jpg';
import Slider from "react-slick";
import NoCateg from '../../Assests/Images/Layer 22.1.png'
import { IndusURL, SphereUrl, UploaderUrl } from '../../Assests/js/config';
import {IndividualUrl} from '../../Assests/js/config';
import {IndividualUrllogin} from '../../Assests/js/config';

import { Routing } from "../../Assests/js/routing";
import { HashRouter, Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { isNull } from 'lodash';
import EDClogofullcurved02C from '../../Assests/Images/EDC_logo_full_curved-02C.png';
import PuffLoader from "react-spinners/PuffLoader";
import ProcessFlow from './processflow'



// import svp from '../../Assests/Images/visual.png';
import svp1 from '../../Assests/Images/svp1.png';
import svp from '../../Assests/Images/VisualProfiling.png';
// import svp from '../../Assests/Images/Group 90.png';
import upskill from '../../Assests/Images/upskill.png';
// import P360 from '../../Assests/Images/p360.png';
import P360_1 from '../../Assests/Images/P360_1.png';
import P360 from '../../Assests/Images/Profile-360w.png';
import online1 from '../../Assests/Images/online1.png';
import online from '../../Assests/Images/Online-interview.png';
// import job from '../../Assests/Images/job.png';
// import job from '../../Assests/Images/Job-Connect.png';
import job1 from '../../Assests/Images/job2.png';
import job from '../../Assests/Images/Job-Connect.png'
import cousell from '../../Assests/Images/cousell.png';
import DtsSvp from '../../Assests/Images/SVP.png';
//import JOB1 from '../../Assests/Images/job1.png';
import profil360 from '../../Assests/Images/profile_360.png';
import upskillinggs from '../../Assests/Images/upskillinggs.png';
import onlineinterview from '../../Assests/Images/onlineinterview.png';
import careerCoun from '../../Assests/Images/careerCoun.png';
import { components } from 'react-select';
import jobsbanner2resume from '../../Assests/Images/jobsbanner2resume.png';
import jobsbanner3question from '../../Assests/Images/jobsbanner3question.png';
import bengaluru from '../../Assests/Images/bengaluru.png';
import UTIndiaNextStimulus from '../../Assests/Images/UTIndiaNextStimulus.png';


import Individual from '../Individual_component/Individual';
import Industry from '../Industry_component/Industry';
import Institute from '../Institute_component/Institute';

import onlineinterview_white from '../../Assests/Images/onlineinterview-white.png'
import profile360score_white from '../../Assests/Images/profile360score-white.png'
import visualprofiling_white from '../../Assests/Images/visualprofiling-white.png'
import jobconnect_white from '../../Assests/Images/job-connect-white.png'




import profile360_light from '../../Assests/Images/profile360-light.png'
import profile360_dark from '../../Assests/Images/profile360-dark.png'
import interview_dark from '../../Assests/Images/interview-dark.png'
import interview_light from '../../Assests/Images/interview-light.png'
import jobconnect_dark from '../../Assests/Images/jobconnect-dark.png'
import jobconnect_light from '../../Assests/Images/jobconnect-light.png'
import visualprofile_dark1 from '../../Assests/Images/visualprofile-dark1.png'
import visualprofile_light from '../../Assests/Images/visualprofile-light.png'

import Apple from '../Aatce_component/aatce'
import history from '../../../src/histoty';

const Input = (props) => <components.Input {...props} isHidden={false} />;

const fade = {
    dots: false,

    infinite: true,
    fade: true,
    cssEase: 'linear 0.5s',
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
}

const CustomStyleForJobSearch = {
    option: (provided, state) => (Object.assign({ provided }), {
        textAlign: "left",
        color: 'black',
        padding: 10,
        fontSize: "12px",
        '&:hover': {
            background: '#20A8D8',
            color: 'white',
        },
        background:'white'
    }),
    noOptionsMessage: () => ({
        // color: 'var(--asycSelectDropDownText)',
        color: '#7f7f7f',
        background: 'white',
        textAlign: "center",
        fontSize: "12px",
    }),
    dropdownIndicator: () => ({
        color: 'var(--asycDropDownIndicator)',
        padding: '0px',
        margin: '5px 0px',
        borderRadius: "15px"
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    control: () => ({
        color: '#00000',
        background: 'white',
        display: 'flex',
        borderRadius: (window.matchMedia("(min-width: 1824px)").matches) ? "0px 10px 10px 10px" : "0px 10px 10px 10px",
        padding: (window.matchMedia("(min-width: 1824px)").matches) ? '7px 7px' : '2.1px 0px 1.1px 7px',
        border: 'none',
        height: (window.matchMedia("(min-width: 1824px)").matches) ? '6vh' : "39px",
        // paddingRight: (window.matchMedia("(max-width: 568px)").matches) ? "35px" : "7px",
        paddingRight: "35px",
        zIndex: 2,
        alignItems: "baseline",
        paddingTop: "1vw"

    }),
    input: () => ({
        // color: 'var(--inputTextClr)',
        // padding:'5px 0px',
        // fontSize: "13px"
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        textTransform: "capitalize",
        width: '100%',
        position: 'absolute',
        alignItems: "center",
        display: "contents",

        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    loadingMessage: () => ({
        // color: 'black',
        color: '#7f7f7f',
        background: 'white',
        textAlign: "center",
        fontSize: "12px",
    }),
    menu: () => ({
        color: 'black',
        background: 'white',
        //  marginTop: '2px',
        position: "absolute",
        marginLeft: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        marginRight: (window.matchMedia("(min-width: 1824px)").matches) ? "12px" :
            (window.matchMedia("(max-width: 568px)").matches) ? "4px" : "8px",
        zIndex: "9999999",
        marginBottom: "0px !important",
        boxShadow: "0px 0px 4px 1px rgba(0 , 0  , 0 , 0.2)",
        width: (window.matchMedia("(min-width: 1824px)").matches) ? "calc(100% - 24px)" :
            (window.matchMedia("(max-width: 568px)").matches) ? "calc(100% - 8px)" : "calc(100% - 16px)",
    }),
    singleValue: () => ({
        color: 'var(--inputTextClr)',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.6vh' : "13px",
        position: 'absolute',
        textTransform: "capitalize",
        // paddingTop: (window.matchMedia("(min-width: 1824px)").matches) ? '1.2vh' : '10px'
    }),
    placeholder: () => ({
        // color: 'var(--inputHeading)',
        color: '#7f7f7f',
        fontSize: (window.matchMedia("(min-width: 1824px)").matches) ? '1.38vh' : "12px",
        paddingLeft: (window.matchMedia("(min-width: 1824px)").matches) ? '6px' : "1px",
        //textTransform: "Normal",
        position: 'absolute',
        top: (window.matchMedia("(min-width: 1824px)").matches) ? '' : " 1vw",


    }),
    valueContainer: () => ({
        paddingLeft: "0",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "wrap",
        padding: "0",
        // position: "relative",
        overflow: "hidden",
        // zIndex: "3"
    }),
}

const PFCfiltedOptions = (inputValue, callback) => {

    if (inputValue.length > 1) {
        callback(GetFunctionalSkillsCompanyName(inputValue));
    }

}











class IndividualJobs extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeData: 1,
            skills: [],
            jobs: [],
            company: []
            , categ: [],
            catcomplogo: [],
            // searchSkills : '',
            searchSkills: [],
            textactive: 1,
            Tab: 1,
            PFCfiltredselected: '',
            CategHalf: []
        }
    }

    componentDidMount() {

        let resp = ~~parseInt(history.location.hash.substring(history.location.hash.length-1, history.location.hash.length));
        let con = parseInt(resp === 0? 0:resp);
        console.log('con',con)
        if(con==0){
            window.$('html,body').animate({
                scrollTop: 0
            });
        }else{
            window.$('html,body').animate({
                scrollTop: $("#pflow").offset().top
            });
        }

        GetAllJobs()
            .then(result => {

                
                let skl = result.recordsets[11]
                let job = result.recordsets[2].filter(x=> x.ActiveJob === 1 && x.IsArchived === false && x.IsPublished === true ).sort((a,b)=> a.DaysModified > b.DaysModified ? -1 : 1)
                let company = result.recordsets[0].filter(y=> job.findIndex(x=> x.EntityUID === y.EntityUID ) !== -1)
                //console.log('company-->',company)
                let categ = result.recordsets[12].filter(y=> job.findIndex(x=> x.MSTFunctionalAreaId === y.MSTFunctionalAreaId ) !== -1)
                let catecomplogo = result.recordsets[13].filter(y=> categ.findIndex(x=> x.MSTFunctionalAreaId === y.MSTFunctionalAreaId ) !== -1
                                    && company.findIndex(com => com.Logo === y.company) !== -1 )
                // console.log('catecomplogo',catecomplogo ,company,
                // result.recordsets[13].filter(y=> categ.findIndex(x=> x.MSTFunctionalAreaId === y.MSTFunctionalAreaId ) !== -1
               
                // )
                // )
                let arr = []
                for (let i = 0; i < (result.recordsets[12].length) / 2; i++) {
                    arr.push(i)
                }

                this.setState({
                    skills: skl,
                    jobs: job,
                    company: company,
                    categ: categ,
                    CategHalf: arr,

                    catcomplogo: catecomplogo
                })
                // document.getElementById("companyNAme").innerHTML = document.getElementById("companyNAme").innerHTML.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase());
            })





    }




    // handlachange = e =>{
    //     // var x = document.getElementById("jobsch").autofocus;

    //     this.setState({
    //         searchSkills : e.target.value
    //     })

    // }
    onInputChange = name => (inputValue, { action }) => {

        if (action === "input-change") {
            if (name === "searchSkills") {
                this.setState({
                    searchSkills: inputValue
                })
            }


        }




    }

    ClearData = () => {
        this.setState({
            searchSkills: []
        })
    }


    OnChangeSelect = name => (selectedOption) => {

        if (name === 'searchSkills') {
            if (isNull(selectedOption)) {
                this.setState({
                    searchSkills: []
                })


            }
            else {
                this.setState({
                    searchSkills: selectedOption
                })
            }
        }







    }




    searchforJobs = () => {

        if (Object.keys(this.state.searchSkills).length > 0) {
            let Obj = {
                Skills: {
                    value: this.state.searchSkills.value,
                    label: this.state.searchSkills.label, Type_MST: "skills", Source: 'Master'
                }
            }


            let URL = IndividualUrl + '?' + btoa(JSON.stringify(Obj))
            let a = document.createElement('a');
            a.href = URL;
            a.target = "_blank"
            a.click();

        }
    }

    componentWillUnmount() {

    }

    OpenSkillset = (SkillsName) => {
        let Obj = {
            Skills: {
                value: this.state.skills.filter(x => x.MSTSkillName === SkillsName)[0].MSTSkillsId
                , label: SkillsName, Type_MST: "skills", Source: 'Master'
            }
        }
        // let URL = "https://indir.skillablers.com/indi-JobSearch" +'?' + btoa(JSON.stringify(Obj))  
        let URL = IndividualUrl + '?' + btoa(JSON.stringify(Obj))
        let a = document.createElement('a');
        a.href = URL;
        a.target = "_blank"
        a.click();
    }

    Openfunctionarea = (fun) => {
        // console.log("_blank",fun)
        // console.log("_blank",this.state.categ)
        let Obj = {
            functional: {
                value: this.state.categ.filter(x => x.MSTFunctionalAreaName === fun)[0].MSTFunctionalAreaId,
                label: fun
            }
        }
        let URL = IndividualUrl + '?' + btoa(JSON.stringify(Obj))
        let a = document.createElement('a');
        a.href = URL;
        a.target = "_blank"
        a.click();
    }



    visuaprofiling = (id) => {

        this.setState({
            activeData: id,
            textactive: 0
        }, () => {
            setTimeout(() => {
                this.setState(
                    {
                        textactive: id
                    }
                )
            }, 900)


        })
    }


    OpenJobs = () =>{
        let Obj = {LoadData: true}
        let URL = IndividualUrl + '?' + btoa(JSON.stringify(Obj))
        let a = document.createElement('a');
        a.href = URL;
        a.target = "_blank"
        a.click();   
    }

    openindi=()=>
    {
        window.open(IndividualUrllogin+'/indi-login' ,'_blank')
    }








    render() {
        let Settein1, setting3, setting2
        if (window.matchMedia("(min-width: 568px)").matches) {
            Settein1 = {
                dots: false,
                infinite: true,
                speed: 700,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }
                ]
            }

            setting3 = {
                dots: false,
                infinite: false,
                speed: 700,
                slidesToShow: 6,
                slidesToScroll: 6,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 6,
                            infinite: false,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 860,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 5
                        }
                    },
                    {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
            setting2 = {
                dots: false,
                infinite: false,
                speed: 700,
                slidesToShow: 5,
                slidesToScroll: 1,
                // autoplay: true,
                autoplaySpeed: 2000,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: false,
                            dots: false,
                            autoplay: true,
                            autoplaySpeed: 2800,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            autoplay: true,
                            infinite:  false,
                            autoplaySpeed: 2800,
                        }
                    },
                    {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            autoplay: true,
                            infinite: false,
                            autoplaySpeed: 2800,
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            }
        }
        else {
            Settein1 = {
                dots: false,
                infinite: true,
                speed: 700,
                slidesToShow: 2,
                slidesToScroll: 2

            }

            setting3 = {
                dots: false,
                infinite: true,
                speed: 700,
                slidesToShow: 1,
                slidesToScroll: 1
            }
            setting2 = {
                dots: false,
                infinite: true,
                speed: 700,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2800,
            }
        }
        let companysetting
        companysetting = {
            dots: false,
            infinite: false,
            speed: 700,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        infinite: false,

                    }
                },
                {
                    breakpoint: 860,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
        return (
            <div>
                <div className="job-main-container">
                    <div class="pain job-container-new padding-job-conatiberss">
                        <div class="job-bannerwrpa bgclrbanner">

                            <Slider {...fade}>
                                <div  ><img src={Banner} alt="19" width="100%" /></div>
                                <div ><img src={jobsbanner2resume} alt="19" width="100%" /></div>
                                <div><img src={jobsbanner3question} alt="19" width="100%" /></div>
                            </Slider>

                        </div>
                    </div>
                    <div>

                        <div class="trendsearch-box  ">
                            {/* placeholder="Search for job based on skill, category or companies" */}
                            <div className="  sear-and-slider-flex">

                                <div className="col-lg-5 main-search-job-cont searchtest padd-sear-zero " style={{ position: 'relative' }}>
                                    <div className="col-md-12 col-12 col-sm-12 px-0 async-override">
                                        {/* <input type="text" placeholder=" " id="jobsch"  autoFocus
                value={this.state.searchSkills} name="searchSkills" onChange={this.handlachange} /> */}
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            // noOptionsMessage={() => (this.state.searchSkills !== "") ? 'Nothing found' : 'Type to select'}
                                            noOptionsMessage={({inputValue}) => !inputValue ? "Type to select" : "No results found"}
                                            loadingMessage={() => 'Type to select'}
                                            loadOptions={PFCfiltedOptions}
                                            styles={CustomStyleForJobSearch}
                                            // name="searchSkills" 
                                            placeholder="What are you looking for Company or Skill ?"
                                            value={this.state.searchSkills}
                                            onChange={this.OnChangeSelect('searchSkills')}
                                            // onChange={this.handlachange}
                                            // className="text-uppercase"
                                            maxLength="100"
                                            inputValue={this.state.searchSkills !== null ? this.state.searchSkills.label : ''}
                                            onInputChange={this.onInputChange('searchSkills')}

                                            controlShouldRenderValue={false}
                                            components={{
                                                Input
                                            }}
                                        />

                                        <i class="fa fa-search icon-search" aria-hidden="true" onClick={this.searchforJobs} ></i>
                                    </div>
                                </div>

 
                            </div>


                        </div>



                    </div>
                    <div class="events-headsss individualBlockIdent active  pb-0 processflow" id="eventhead">JOBS BY</div>
                    <div class="jobsdaatcontainer ">
                  
                        <div>
                            <div class="main2 individualBlockIdent">
                                <div class="m2 w-100">
                                    
                                    <div class="contenetess">
                                        <div class="tabs">


                                            <label
                                                onClick={() => this.setState(
                                                    {
                                                        Tab: 1
                                                    }
                                                )}
                                                className={this.state.Tab === 1 ? "tab activestatus " : " tab"} > Trending </label>

                                            <label
                                                onClick={() => this.setState(
                                                    {
                                                        Tab: 2
                                                    }
                                                )}
                                                className={this.state.Tab === 2 ? "tab  activestatus" : "tab"} >Categories</label>

                                            <label
                                                onClick={() => this.setState(
                                                    {
                                                        Tab: 3
                                                    })}
                                                className={this.state.Tab === 3 ? "tab activestatus" : "tab"} >Industries</label>


                                        </div>

                                    </div>
                      
                                    <div>
                                     
                                        {
                                            this.state.Tab === 1 ?

                                                <div>
                                                    <div class="trend-center">
                                                    
                                                    {this.state.jobs.length > 0 ?
                                                    <div>
                                                        <div class="row slider nwidth-fit firstblock  jobslide-cenetr   " id="trendid">
                                                            {/* <Slider {...setting2}> */}

                                                          
                                                            {this.state.jobs.slice(0, 5).map((x, i) => <div key={i} class=" col-lg-2 col-md-4 col-sm-12 col-12 paddin-botm-11 px-0">
                                                                
                                                                <div class="clogo ">
                                                                    <div className="ht-50per">
                                                                   
                                                                    {/* <img src={ (x.EntityTypeID === 10 && x.Hideclient === false)? (x.ClientLogo===''? DefaulCom :UploaderUrl + x.ClientLogo) : this.state.company.filter(y => y.EntityUID === x.EntityUID)[0].Logo === "" || this.state.company.filter(y => y.EntityUID === x.EntityUID)[0].Logo === undefined ? DefaulCom : UploaderUrl + this.state.company.filter(y => y.EntityUID === x.EntityUID)[0].Logo  } alt="clogo" /> */}
                                                                    <img src={ (x.EntityTypeID === 10 && x.Hideclient === false)? (x.ClientLogo===''? DefaulCom :UploaderUrl + x.ClientLogo) : this.state.company.filter(y => y.EntityUID === x.EntityUID).map(obj => {return obj.Logo}).toString() === "" ? DefaulCom : UploaderUrl + this.state.company.filter(y => y.EntityUID === x.EntityUID).map(obj => {return obj.Logo}).toString()   } alt="clogo" />

                                                                        
                                                                       
                                                                    </div>  <div className="jpob-datatsection">
                                                                        <a href={SphereUrl+"/job-details/?id=" + window.btoa(x.JobId.toString())}
                                                                            target="_blank" className="cursor-pointer defau-color">
                                                                            <p class="cpmapny-conatinersections comp-name">{x.JobTitle.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase())}</p>
                                                                        </a>

                                                                       { x.EntityTypeID === 10 ?
                                                                            <p class="trend-job-comp">{x.EntityName}</p>
                                                                        :
                                                                        <a href={IndusURL + this.state.company.filter(y => y.EntityUID === x.EntityUID).map(obj => {return obj.SVPLink}).toString()} target="_blank" >
                                                                            <p class="trend-job-comp">{x.EntityName}</p></a>}
                                                                            </div>
                                                                </div>
                                                            </div>)}
                                                        </div>

 
                                                        {/* </Slider> */}
                                                    
                                                        <div className="disnone-2ndslide firstblock  ">
                                                            <div className="pad-topjbs row  jobslide-cenetr">

                                                                {this.state.jobs.slice(6, 11).map((x, i) => <div key={i} class=" col-lg-2 paddin-botm-11 px-0">
                                                                        <div class="clogo ">
                                                                        <div className="ht-50per">

                                                                            {/* <img src={(x.EntityTypeID === 10 && x.Hideclient === false)? (x.ClientLogo===''? DefaulCom :UploaderUrl + x.ClientLogo) :this.state.company.filter(y => y.EntityUID === x.EntityUID)[0].Logo === "" || this.state.company.filter(y => y.EntityUID === x.EntityUID)[0].Logo === undefined ? DefaulCom : UploaderUrl + this.state.company.filter(y => y.EntityUID === x.EntityUID)[0].Logo} alt="clogo" /> */}
                                                                            <img src={ (x.EntityTypeID === 10 && x.Hideclient === false)? (x.ClientLogo===''? DefaulCom :UploaderUrl + x.ClientLogo) : this.state.company.filter(y => y.EntityUID === x.EntityUID).map(obj => {return obj.Logo}).toString() === "" ? DefaulCom : UploaderUrl + this.state.company.filter(y => y.EntityUID === x.EntityUID).map(obj => {return obj.Logo}).toString()   } alt="clogo" />

                                                                        </div>  <div className="jpob-datatsection">
                                                                            <a href={SphereUrl+"/job-details/?id=" + window.btoa(x.JobId.toString())}
                                                                                target="_blank" className="cursor-pointer defau-color">
                                                                                <p class="cpmapny-conatinersections comp-name" id="companyNAme">{x.JobTitle.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase())}</p>
                                                                            </a>
                                                                            {/* {console.log(x.JobTitle.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase()))} */}
                                                                            <a href={IndusURL + this.state.company.filter(y => y.EntityUID === x.EntityUID).map(obj => {return obj.SVPLink}).toString()} target="_blank" >
                                                                                <p class="trend-job-comp">{x.EntityName}</p></a></div>
                                                                    </div>
                                                                </div>)}

                                                            </div>

                                                        </div>
                                                        </div>

:<div className="load-alignment">
 <PuffLoader  color="#0572aa" />                                                      
</div>}
                                                       
                                                    
    

                                                        <li className="viewtrendjobs"><button id="" className="meet-our-team" onClick={this.OpenJobs}>View All</button></li>
                                                    </div>
                                                </div>
                                                :
                                                this.state.Tab === 2 ?

                                                    <div>
                                                        <div class="trend-center tredy-widthcategory  individualjobs-scroll">
                                                        {this.state.categ.length > 0?
                                                            <div class="row slider2 nwidth-fit cateegg secblock cntcompany" id="">
                                                                {/* <Slider {...setting2}> */}
                                                             
                                                                
                                                                    { this.state.categ.map((x, i) =>
                                                                            <div className="categ-mains-cont  col-lg-3 col-md-4 col-sm-12 col-12 " key={i}>
                                                                                <div className="categ-conatiners">
                                                                                    {this.state.catcomplogo.filter(y => y.MSTFunctionalAreaId === x.MSTFunctionalAreaId && y.company !== '').map((y, i) => {
                                                                                        i = this.state.catcomplogo.filter(y => y.MSTFunctionalAreaId === x.MSTFunctionalAreaId && y.company !== '').length === 3 ?
                                                                                         i: this.state.catcomplogo.filter(y => y.MSTFunctionalAreaId === x.MSTFunctionalAreaId && y.company !== '').length === 1?
                                                                                         1 : i
                                                                                        return <>
                                                                                            {i < 3 &&
                                                                                                <img class={i === 0 ? "instformimg1" : i === 1 ? "instformimg2" : "instformimg3"} src={UploaderUrl + y.company.replace(/\/\//g, "/")} alt="Image" />}</>
                                                                                    })}
                                                                                 

                                                                                  
                                                                                    <p classname="pad-instastyle" onClick={() => { this.Openfunctionarea(x.MSTFunctionalAreaName) }}>{x.MSTFunctionalAreaName}</p>
                                                                                    {/* MSTFunctionalAreaId  */}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                   
                                                                {/* </Slider> */}
                                                            </div>
                                                             :<div className="load-alignment">
                                                             <PuffLoader  color="#0572aa" />                                                       
                                                             </div>}

                                                        </div>
                                                    </div>
                                                    :

                                                    <div>
                                                        <div class="trend-comp companesBlockIdent trend-center ">
                                                            <div class="thirdblock  individualjobs-scroll" id="">
                                                           
                                                                {/* <Slider {...setting2}> */}
                                                            {this.state.company.length > 0? 
                                                                <div className="row cntcompany">
                                                                    {this.state.company.filter(x => x.Logo !== "" || x.Logo !== undefined).map((x, i) => 
                                                                    
                                                                    <div className="col-lg-2 col-md-2 col-sm-12 col-12 com-apple" key={i}>
                                                                        <a href={x.EntityUID.includes("RECR") ? "" : IndusURL + x.SVPLink} target="_blank" >
                                                                            <div class="companieslogo ">
                                                                                <div className="ht-companyimg">

                                                                                    <img className="company-img" src={x.Logo === "" || x.Logo === undefined ? DefaulCom : x.Logo.includes(UploaderUrl) ? x.Logo : UploaderUrl + x.Logo} alt="apple" />
                                                                                </div>

                                                                            </div>
                                                                        </a> </div>
                                                                        )}</div>
                                                                        
                                                                        :<div className="load-alignment">
                                                              <PuffLoader  color="#0572aa" />                                                      
                                                             </div>}

                                                                        {/* </Slider> */}
                                                                        
                                                            </div>
                                                        </div>
                                                    </div>

                                        }


                                        <></>

                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>

                    {/* <div class="sklprodcontainer1" id="pflow">
                        <div class="events-headsss individualBlockIdent active  pb-0 processflow" id="eventhead">PROCESS FLOW</div>
                        <ProcessFlow/>
                    </div> */}

                   


                </div>
               


            </div>
        )
    }
}
export default IndividualJobs


