import React from "react";
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import { IndustryURL,IndusURL,Instituteurl } from "../../Assests/js/config";

import 'react-toastify/dist/ReactToastify.css';
import visualprofile_light from '../../Assests/Images/visualprofile-light.png'
import visualprofile_dark1 from '../../Assests/Images/visualprofile-dark1.png'
import profile360_light from '../../Assests/Images/profile360-light.png'
import profile360_dark from '../../Assests/Images/profile360-dark.png'
import jobconnect_dark from '../../Assests/Images/AACTe dark.png'
import jobconnect_light from '../../Assests/Images/AACTe.png'
import interview_dark from '../../Assests/Images/interview-dark.png'
import interview_light from '../../Assests/Images/interview-light.png'
import {IndividualUrllogin} from '../../Assests/js/config';
import Instdashboard from '../../Assests/Images/Instdashboard.png'
import Lmscourse from '../../Assests/Images/Lmscourse.png'
import InstSvp from '../../Assests/Images/InstSvp.png'
import Phonixpng from '../../Assests/Images/Phonixpng.png'
import upskillinggs from '../../Assests/Images/upskillinggs.png';
import onlineinterview from '../../Assests/Images/onlineinterview.png';
import careerCoun from '../../Assests/Images/careerCoun.png';
import c360light from '../../Assests/Images/connect360-light.png'
import c360dark from '../../Assests/Images/c360dark.png';
import c360lightv1 from '../../Assests/Images/c360lightv1.png';
import history from '../../../src/histoty';
import base64 from 'base-64'
import indulite from '../../Assests/Images/indulite.svg';
import indudark from '../../Assests/Images/indudark.svg';
import Instdashdark from '../../Assests/Images/Instdashdark.svg';
import Instdashlite from '../../Assests/Images/Instdashlite.svg';
import Instsvpdark from '../../Assests/Images/Instsvpdark.svg';
import Instsvplite from '../../Assests/Images/Instsvplite.svg';
import Instlmsdark from '../../Assests/Images/Instlmsdark.svg';
import instlmslite from '../../Assests/Images/instlmslite.svg';
import appleimg from '../../Assests/Images/AACTelarge.png'
import Requestdomologo from "../../Assests/Images/mobiledemologo.svg"
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import RequestDemo from '../Industry_component/requestdemo-popup'
import Processflowarrow from '../../Assests/Images/RightandLeftarrowinProcessFlow.svg'

import inquibyccseriesimgLight from '../../Assests/Images/inquibyccseriesimgLight.png'
import inquibyccseriesimgDark from '../../Assests/Images/inquibyccseriesimgDark.png'
import inquibSectorimglight from '../../Assests/Images/inquibSectorimglight.png'
import inquibSectorimgDark from '../../Assests/Images/inquibSectorimgDark.png'

import inquibOfferCoursesimaglight from '../../Assests/Images/inquibOfferCoursesimaglight.png'
import inquibOfferCoursesimagDark from '../../Assests/Images/inquibOfferCoursesimagDark.png'

import inquibAssementimaglight from '../../Assests/Images/inquibAssementimaglight.png'
import inquibAssementDark from '../../Assests/Images/inquibAssementDark.png'

import inquibEngageLight from '../../Assests/Images/inquibEngageLight.png'
import inquibEngageDark from '../../Assests/Images/inquibEngageDark.png'

import inquibYCCwithFreeimag from '../../Assests/Images/inquibYCCwithFreeimag.png'
import InquibimagProcess from '../../Assests/Images/InquibimagProcess.png'

import inquibsectorimags from '../../Assests/Images/inquibsectorimags.svg'
import quibsectomobiletabimag from '../../Assests/Images/quib-secto-mobile-tabimag.svg'


import EnquirenowQuib from '../../Assests/Images/EnquirenowQuib.svg'
import PartnernowQuib from '../../Assests/Images/PartnernowQuib.svg'
import freeimgicone from '../../Assests/Images/freeimgicone.svg';








let scroll
class InQUIZition extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeData:1,
            skills: [],
            jobs :[],
            clientdetail: [],
            categ : [],
            catcomplogo:[],
            // searchSkills : '',
            searchSkills : [],
            textactive:1,
            Tab:1,
            PFCfiltredselected:'',
            CategHalf :[],
            selectedpack:'',
            checkedtrue:false,
            isReqstdemo:false,
            type:""
        }
    }

    componentDidMount(){
        //mobile view tabs process flow function
    // hide all contents accept from the first div
    window.$('html,body').animate({
        scrollTop: 0
    });
    $('.tabContent div:not(:first)').toggle();
    // hide the previous button
    $('.PreviousprocessFlow').hide();
    $('.tabs1 li').click(function () {
    if ($(this).is(':last-child')) {
            $('.nextprocessFlow').hide();
    } 
    else {
            $('.nextprocessFlow').show();
    }
    if ($(this).is(':first-child')) {
            $('.PreviousprocessFlow').hide();
    } 
    else {
            $('.PreviousprocessFlow').show();
    }
        var position = $(this).position();
            // console.log("position",position)
        var corresponding = $(this).data("id");
    // scroll to clicked tab with a little gap left to show previous tabs
        scroll = $('.tabs1').scrollLeft();
        // console.log("scroll",scroll)
        $('.tabs1').animate({
            'scrollLeft': scroll + position.left
           
        }, 200);
    // show content of corresponding tab
        $('div.' + corresponding).toggle();
    // remove active class from currently not active tabs
        $('.tabs1 li').removeClass('active');
    // add active class to clicked tab
        $(this).addClass('active');
    });

    $('.nextprocessFlow').click(function(e){
    e.preventDefault();
    $('li.active').next('li').trigger('click');
    if(scroll== 185|| scroll== 184||scroll== 159 || scroll == 160)
        {
          
            $('.nextprocessFlow').hide();
        }
    });

    $('.PreviousprocessFlow').click(function(e){
    e.preventDefault();
    $('li.active').prev('li').trigger('click'); 
    });
//mobile view tabs process flow function
    }
    visuaprofiling = (id) => {

        this.setState({
            activeData: id,
            textactive: 0
        }, () => {
            setTimeout(() => {
                this.setState(
                    {
                        textactive: id
                    }
                )
            }, 900)


        })
    }
    openinsti=()=>
    {
       window.open(Instituteurl+'/ins-login','_blank')
    }

    // popup for Request for demo

OpenRequestDemo = (type) => {

    this.setState({

        isReqstdemo : true,
        type:type
     

    })

}



 closeRequestDemo = () => {

   // setisIntern(false)

   this.setState({

    isReqstdemo : false,
    type:""

    // pop : 0

})

}

 toastMessage=()=>{

    toast.success('Thank you for your request. Our team will contact you soon.', {    

        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        // autoClose: 5555000,
        theme: "colored"

    })

 }

 setlasttabactive=(id)=>
 {
    this.setState(
        {
            activeData:id
        }
    )
    window.$('html,body').animate({
        scrollTop: 0
    });
 }

 redirectinstituteReg=()=>
 {
    window.open(Instituteurl+'/ins-registration', '_blank')
 }
 redirectindustryReg=()=>
 {
    window.open(IndusURL+'/indu-registration', '_blank')
 }
 redirectGuestLogin=()=>
 {
     window.open(IndividualUrllogin+'/indi-guest-login', '_blank')
 }

    render(){
        return(
           
            <div>
                 <div className="indi-conatiner bg-roadmap d-flex mb-3" >
            
                 <div className="indihomeland-subwrap" id="popupdetail"></div>
                 </div>
                <div className="mb-style-changewrap paddtopdeskNewflows Desktopnewdesign" id="Processflow">
                <div className="col-lg-12 col-md-12 col-3 px-0 row mx-0 d-flex justify-content-center  directmbcloum ">
                    <div className="  col-md-2 col-lg-2 px-0  pubjd">
                        <a target='_blank'>
                            <div className={this.state.activeData === 1 ? "skl-productss active  Brradius-indu" : "skl-productss   Brradius-indu"}
                                onClick={()=>this.visuaprofiling(1)}
                            >
                                <div className="indu-image-blocks">
                                    <img src= {this.state.activeData === 1 ?inquibyccseriesimgDark : inquibyccseriesimgLight} className="Brradius-indu"/>
                                </div>
                                <div>
                                    <div className="skl-prod-name bold-efeect" id='VProfile'>INDUSTRY AWARENESS QUIZ</div>
                                </div>
                           </div>
                        </a>
                    </div>
                    <div className="   col-md-2 col-lg-2   px-0">
                        <a target='_blank'>
                            <div className={this.state.activeData === 2 ? "skl-productss active  Brradius-indu" : "skl-productss Brradius-indu"}
                                onClick={()=>this.visuaprofiling(2)} >
                                    <div className="indu-image-blocks">
                                        <img src= {this.state.activeData === 2 ? inquibSectorimgDark : inquibSectorimglight} className="Brradius-indu"
                                            // onMouseOver={e => e.currentTarget.src = P360_1}
                                            // onMouseOut={e => e.currentTarget.src = P360}
                                       />
                                    </div>
                                    <div>
                                        <div className="skl-prod-name bold-efeect">INDUSTRY SECTORS</div>
                                                   
       
                                               </div>
                                           </div>
                                       </a>
                                   </div>
                                   {/* <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
       
                                       <div className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                           onClick={()=>this.visuaprofiling(3)}>
                                           <div className="indu-image-blocks">
                                               <img src={this.state.activeData === 3 ? jobconnect_dark  : jobconnect_light} className="Brradius-indu"
                                               />
                                           </div>
                                           <div>
                                               <div className="skl-prod-name bold-efeect">Carrier connect</div>
       
                                           </div>
                                       </div>
                                   </div> */}
                                   <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
       
                                       <div className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                           onClick={()=>this.visuaprofiling(3)}>
                                           <div className="indu-image-blocks">
                                               <img src={this.state.activeData === 3 ? inquibOfferCoursesimagDark  : inquibOfferCoursesimaglight} className="Brradius-indu"
                                               />
                                           </div>
                                           <div>
                                               <div className="skl-prod-name bold-efeect">VALUE PROPOSITION</div>
       
                                           </div>
                                       </div>
                                   </div>
                                   <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
                                       <a target='_blank'>
                                           <div className={this.state.activeData === 4 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(4)}>
                                               <div className="indu-image-blocks">
                                                   <img src={this.state.activeData === 4 ? inquibAssementDark : inquibAssementimaglight} className="Brradius-indu"
                                                   // onMouseOver={e => e.currentTarget.src = online1}
                                                   // onMouseOut={e => e.currentTarget.src = online}
                                                   />
                                               </div>
                                               <div>
                                               <div className="skl-prod-name bold-efeect">CORPORATE ASSESSMENTS</div>
       
                                               </div>
                                           </div>
                                       </a></div>
 
                                       <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                                       <a target='_blank'>
                                           <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(5)}>
                                               <div className="indu-image-blocks">
                                                   <img src={this.state.activeData === 5 ? inquibEngageDark  : inquibEngageLight} className="Brradius-indu"
                                                   />
                                               </div>
                                               <div>
                                                   <div className="skl-prod-name bold-efeect"><span className="txt-trans">ENGAGE AHEAD</span></div>
       
                                               </div>
                                           </div>
                                       </a></div>
                                       {/* <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                                       <a target='_blank'>
                                           <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                                onClick={()=>this.visuaprofiling(5)}>
                                               <div className="indu-image-blocks">
                                                   <img src={this.state.activeData === 5 ? interview_dark : interview_light} className="Brradius-indu"
                                                   />
                                               </div>
                                               <div>
                                                   <div className="skl-prod-name bold-efeect">online interviews</div>
       
                                               </div>
                                           </div>
                                       </a></div> */}
                                  
                               </div>
 
                               <div className={this.state.activeData === 1 ? "col-sm-12 col-9 px-0 indu-data-containerss active fst-border" :
                                   this.state.activeData === 5 ? "col-sm-12 col-9  px-0 indu-data-containerss active lst-border" : "col-sm-12 col-9  px-0 indu-data-containerss active all-border "}>
                                   <div className="col-12 px-0 row mx-0   tabsec-center  mi-heigh-overall">
                                       {/* <div className={ this.state.textactive==0?"col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0  align-paddd-induss py-3":"col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0 align-paddd-induss   py-3" }   >
                                           <div className="visul-porfile-head  flow-align">
                                               {this.state.activeData === 1 ? 
                                               <div className="">
                                                   
                                                   <div className="visual-profile-head1">Create</div>
                                                     <div className="visual-haed-two ">Institution profile</div>
                                               </div> :
                                                   this.state.activeData === 2 ?
                                                       <div className="">
                                                           <div className="visual-profile-head1">Create Your</div>
                                                           <div className="visual-haed-two ">Learning Experiences</div>
                                                       </div>
                                                       : this.state.activeData === 3 ?
                                                           <div className="">
                                                               <div className="visual-profile-head1 ">Get YOUR Students</div>
                                                               <div className="visual-haed-two  ">Industry Ready</div>
                                                           </div>
                                                     
                                                           
                                                           :
                                                           this.state.activeData === 4 ?
                                                               <>
                                                               <div className="visual-profile-head1 ">Get YOUR Students</div>
                                                                  
                                                                   <div className="visual-profile-head1 animat ">Bridging the gap between</div>
                                                                   <div className="visual-haed-two  ">Education and Employment</div>
                                                               </> 
                                                            
                                                              :
                                                             this.state.activeData === 5 ?
                                                                   <>
                                                                     
                                                                       <div className="visual-haed-two">Apple Authorized Training Center <span className="txt-trans">(AATCe)</span> </div>
                                                                   </>
                                                              
                                                             
                                                                 :  <>
                                                                      
                                                                   </>
                                                             
 
                                               }
                                           </div>
                                           <div className="visual-data-contentss ">
                                               {this.state.activeData === 1 ?
                                                   <div className="">
                                                      <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                        <p className="mb-3">Institute profiling refers to providing information and insights about an educational institute, school, college, or an university.</p>
                        <p className="mb-3">Profiling an Institute helps create visibility and awareness among students, parents, and the community. It allows the institute to showcase its unique features, programs, and achievements, establishing its brand identity and reputation.</p>
                        <p className="mb-3">Institute profiling plays a crucial role in student recruitment. It helps potential students and parents make informed decisions by providing comprehensive information about the institute's academic offerings, admission procedures, scholarships, and placement records.</p>
                        <p className="mb-3">Institute profiling supports the growth and development of educational institutes by enhancing their visibility, reputation, and engagement with students, parents, alumni, and partners.</p>
                    </div>
                                                   </div>
                                                   :
                                                   this.state.activeData === 2 ?
                                                       <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                        <p className="mb-3">
                                                         Aims at providing educational programs or learning opportunities to individuals or groups and make courses available for enrollment or participation by students or learners.
                                                         </p>
                                                         <p className="mb-3">
                                                         This includes Curriculum Development, Course Creation, Instruction and Facilitation, Assessment and Evaluation, Enrollment and Registration, & Support Services.
                                                         </p>
                                                        <p className="mb-0">
                                                        Courses can cover a wide range of subjects, disciplines, and levels of education, from academic subjects in schools and universities to professional training programs and skill development courses. The aim is to provide structured learning experiences and opportunities for individuals to acquire knowledge, develop skills, and achieve specific learning goals.
                                                        </p>
                                                        
                                                         
                                                        </div>
                                                       :
                                                       this.state.activeData === 3 ?
                                                           <div className=" ">
                                                                 <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                                    
                                                                    <p className="mb-3">
                                                             <span className="font-weight-bold">Learn new skills.</span> Learning a new skill can help you improve your job prospects and make yourself more marketable. It can also help you expand your knowledge and interests.
                                                             </p>
                                                             <p className="mb-3">
                                                             <span className="font-weight-bold">Get involved in extracurricular activities.</span> Extracurricular activities are a great way to meet new people, learn new skills, and develop your interests. There are many different types of extracurricular activities available, so you can find something that you are passionate about.
                                                             </p>
                                                             <p className="mb-3">
                                                             <span className="font-weight-bold">Soft skills:</span> Soft skills, such as communication, teamwork, and problem-solving, are also important for industry readiness. These skills can be developed through education, experience, and self-reflection.
                                                             </p>
                                                             <p className="mb-0">
                                                             <span className="font-weight-bold">Get a job.</span> Getting a job can help you develop your work ethic and learn new skills. It can also help you save money for college or other expenses.                                </p>
                                                                </div>
                                                           </div> 
                                                      
                                                           :
                                                           this.state.activeData === 4 ?
                                                               <div className=" rqst-heading  text-trns-demo fntsizepopuptext ">
                                                                   <p className="mb-3">
                                                                   Educational Institutions plays a prominent role in facilitating placement opportunities to student, to secure employment or internships in their chosen field or industry.                                                                 </p>
                                                                 <p className="mb-3">
                                                                 Placement opportunities aim to bridge the gap between theoretical learning and practical application by offering individuals a chance to gain hands-on experience in a professional setting. They provide a platform for individuals to apply their knowledge, develop essential skills, and gain industry-specific insights.                                                                </p>
                                                                 <p className="mb-0">
                                                                 Placement opportunities can take various forms, including internships, apprenticeships, co-op programs, or even full-time job placements. They may be offered by a specific company or organization, or they could be facilitated through partnerships between educational institutions and industry partners.                                                                </p> 
                                                               </div> :
                                                               this.state.activeData === 5 ?
                                                                   <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                                    <p className="mb-3">
                                                                    Apple Authorized Training Center for Education <span className="txt-trans">(AATCe)</span> is a specialized educational institution offering that is certified by Apple Inc. to provide training and certification programs focused on Apple technologies. These training centers offer courses and workshops designed to enhance the skills and knowledge of educators, IT professionals, and individuals.                                                                </p>
                                                                    <p className="mb-3">
                                                                    Skillablers partnership with iPlanet, enables us to jointly offer educational institutions the opportunity to set up a dedicated Centre of Excellence Lab in campuses.                                                                </p>
                                     
                                                                    </div>
                                                                   :
                                                                   <div className="">
                                                                       Connect with your shortlisted candidates virtually 
                                                                       and capture real time feedback. 
                                                                       You can choose to connect 
                                                                       with candidate 1 on 1 or as a panel</div>
       
                                               }
                                           </div>
 
                                          
                                        <div className="processflowAccessbt">
                                         {this.state.activeData === 3 &&
                                             <div className="visual-view-more-btn-btnnns" onClick={() => { this.OpenRequestDemo("PhoenixFactorPolicy") }} >
                                                     <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                            </div>
                                         }
                                         {this.state.activeData === 4 &&
                                             <div className="visual-view-more-btn-btnnns " onClick={() => { this.openinsti() }}>
                                                     <button className="createprofile meet-our-team texnonetransfor"   >Get Started</button>
                                             </div>
                                         }
                                          {this.state.activeData === 5 &&
                                             <div className="visual-view-more-btn-btnnns " onClick={() => { this.OpenRequestDemo("AACTEPolicy") }}>
                                                     <button className="createprofile meet-our-team texnonetransfor"   >Enquire Now</button>
                                             </div>
                                         }
                                      
                                         {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
                                             <div className="visual-view-more-btn-btnnns">

                                                 <button className="createprofile meet-our-team texnonetransfor"
                                                     onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                     <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? Instituteurl+'/ins-login'  :
                                                        this.state.activeData === 2 ? Instituteurl+'/ins-login' :
                                                             this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                         {this.state.activeData === 1 ? "Get Started" : this.state.activeData === 2 ? " Get Started " :
                                                             this.state.activeData === 3 ? "Get Started" : this.state.activeData === 5 ? "Get Started" :
                                                                 "Get Started"}</a></button>
                                               
                                             </div>
                                         }
                                         </div>
                                           
                                       </div>

                                       <div className={this.state.textactive == 0 ? "col-sm-6 col-12 dflex-cent padding-zer imgalignment  " : "col-sm-6 col-12 dflex-cent padding-zer imgalignment  align-items-center "}>
                                         <img src={this.state.activeData === 1 ? InstSvp: this.state.activeData === 2 ?
                                           Lmscourse : this.state.activeData === 3 ? Phonixpng :
                                                 this.state.activeData === 4 ? Instdashboard :
                                                     this.state.activeData === 5 ? appleimg : upskillinggs} className="width-100per-image" />
                                     </div>

                                     <div className="processflowAccessbtMobile">
                                         {this.state.activeData === 3 &&
                                             <div className="visual-view-more-btn-btnnns" onClick={() => { this.OpenRequestDemo("PhoenixFactorPolicy") }} >
                                                     <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                            </div>
                                         }
                                         {this.state.activeData === 4 &&
                                             <div className="visual-view-more-btn-btnnns " onClick={() => { this.openinsti() }}>
                                                     <button className="createprofile meet-our-team texnonetransfor"   >Get Started</button>
                                             </div>
                                         }
                                          {this.state.activeData === 5 &&
                                             <div className="visual-view-more-btn-btnnns " onClick={() => { this.OpenRequestDemo("AACTEPolicy") }}>
                                                     <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                             </div>
                                         }
                                      
                                         {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
                                             <div className="visual-view-more-btn-btnnns">

                                                 <button className="createprofile meet-our-team texnonetransfor"
                                                     onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                     <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? Instituteurl+'/ins-login'  :
                                                        this.state.activeData === 2 ? Instituteurl+'/ins-login' :
                                                             this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                         {this.state.activeData === 1 ? "Get Started" : this.state.activeData === 2 ? " Get Started " :
                                                             this.state.activeData === 3 ? "Get Started" : this.state.activeData === 5 ? "Get Started" :
                                                                 "Get Started"}</a></button>
                                               
                                             </div>
                                         }
                                         </div> */}
                                    {this.state.activeData === 1 ?
                                    <div>
                                      <div className="row alignaccordinginquib">
                                        <div className="col-lg-6">
                                            <div className="makecenterfsttabimag-quib"><img src={inquibYCCwithFreeimag} className="inquibyccfreeimag"></img></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="makecenterfsttabimag-quib"><img src={InquibimagProcess} className="inquibprocess"></img></div>
                                        </div>
                                      </div>
                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext fnt-quibfst-tab tex-fntnew-size tex-fntnew-sizefulhd' >
                                            <p className="mb-3">InQUIZition is the effective way of testing one's industry knowledge through fun assessments, powered by SKL YCC IAQ Series. Industry Awareness Quizzes (IAQ's) are designed to evaluate an individual's knowledge of a specific industry either before they embark on their career within it or to assist them in staying current with the industry's latest trends and advancements.</p>
                                            <p className="mb-3">Additionally, Industry Awareness Quizzes (IAQ's) serves the purpose of evaluating an individual's comprehension of the industry as a whole or concentrating on a particular sector within it.</p>
                                        </div>
                                        
                                        <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                     <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectGuestLogin}>Start Quiz</button>
                                        </div>
                                        
                                    </div>
                                    :
                                    this.state.activeData === 2 ?
                                        <div>
                                           <div className="row mx-0">
                                            <div className="">
                                                <img src={inquibsectorimags} className="widsector-tab-imag-quib"></img>
                                            </div>
                                           </div> 
                                           <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.setlasttabactive(5)}>Engage Ahead</button>
                                           </div>
                                        </div>
                                    :
                                      this.state.activeData === 3 ?
                                      <div>
                                      <div className="row mx-0">
                                           <div className="Inquib-corporateText">
                                              VALUE PROPOSITION
                                           </div>
                                      </div>
                                      <div>
                                           <ul className="listparent-quib">
                                              <li className="size-quib-pointlist">Industry Awareness Quizzes (IAQs) are valuable for several reasons, and their importance cannot be overstated</li>
                                           </ul>
                                           <div className='disflex-quib-value'>
                                           <ul className="listparent-quib">
                                              <li className="size-quib-pointlist">Knowledge Assessment</li>
                                              <li className="size-quib-pointlist">Preparation for Careers</li>
                                              <li className="size-quib-pointlist">Continuous & Focused Learning</li>
                                              <li className="size-quib-pointlist">Professional Development</li>
                                           </ul>
                                           <ul className="listparent-quib">
                                              <li className="size-quib-pointlist">Benchmarking</li>
                                              <li className="size-quib-pointlist">Competitive Advantage</li>
                                              <li className="size-quib-pointlist">Awareness of Industry Trends</li>
                                              <li className="size-quib-pointlist">Compliance and Certification</li>
                                           </ul>
                                           </div>
                                      </div>
                                      <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                   <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.setlasttabactive(5)}>Engage Ahead</button>
                                      </div>
                                     </div>
                                  
                                    
                                    :
                                    this.state.activeData === 4 ?
                                    <div>
                                        <div className="row mx-0">
                                             <div className="Inquib-corporateText">
                                                CORPORATE ASSESSMENTS
                                             </div>
                                        </div>
                                        <div>
                                             <ul className="listparent-quib">
                                                <li className="size-quib-pointlist"><span className="bldtex-list-quib">Recruitment Competency</span>: IAQs assess and validate prospective recruits' knowledge and competence in their respective industries, ensuring that they are well-informed to perform their roles effectively.</li>
                                                <li className="size-quib-pointlist"><span className="bldtex-list-quib">Skill Gap Identification</span>: These quizzes identify skill gaps within the workforce, enabling companies to tailor training and development programs to address specific knowledge deficiencies.</li>
                                                <li className="size-quib-pointlist"><span className="bldtex-list-quib">Continuous Learning</span>: IAQs encourage a culture of continuous learning and knowledge enhancement among employees, which is essential for staying competitive in rapidly evolving industries</li>
                                                <li className="size-quib-pointlist"><span className="bldtex-list-quib">Quality Assurance</span>: By assessing employee industry knowledge, IAQs contribute to maintaining and improving the overall quality and standards of products and services delivered by the organization.</li>
                                             </ul>
                                        </div>
                                        <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                     <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectindustryReg} >Engage Now</button>
                                        </div>
                                    </div>
                                    :
                                    this.state.activeData === 5 ?
                                    <div>
                                        {window.matchMedia("(min-width:993px)").matches &&
                                        <>
                                         <div className="row mx-0">
                                           <div className="col-lg-4 Inquib-corporateText fifthtabtext">
                                           INDIVIDUALS
                                           </div>
                                           <div className="col-lg-4 Inquib-corporateText fifthtabtext">
                                           INSTITUTIONS
                                           </div>
                                           <div className="col-lg-4 Inquib-corporateText fifthtabtext">
                                           INDUSTRIES
                                           </div>
                                         </div>
                                        </>
                                        }
                                         <div className="row mx-0 alignendsection posrel-alignmenDesk">
                                            <div className="col-lg-4  martop-quibfifthtab">
                                            {window.matchMedia("(max-width:993px)").matches &&
                                                <div className="Inquib-corporateText fifthtabtext posabs-deskalign">
                                                    INDIVIDUALS
                                                </div>
                                            }
                                                <div className="fifthtabtext fntsixtextquibtab">Participate to enhance your industry awareness - also to be noticed by companies.</div>
                                                <div className="mobtabalign-quibfifth">
                                                <div>
                                                    <ul className="listparent-quib list-fifthtabquib">
                                                        <li className="size-quib-pointlist">Students</li>
                                                        <li className="size-quib-pointlist">Candidates</li>
                                                        <li className="size-quib-pointlist">Executives</li>
                                                        <li className="size-quib-pointlist">Any Individual</li>
                                                    </ul>
                                                </div>
                                                <div className="parentwrap-quib-button-img topvaluebut">
                                                    <div className="visual-view-more-btn-btnnns inquibbutnCenter butend-fifthtabquib">
                                                        <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectGuestLogin}>Start Quiz</button>
                                                    </div>
                                                    <div className="Enquirenow-quib-fifth">
                                                        <img src={freeimgicone} className="imag-quib-small-fifth"></img>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  martop-quibfifthtab">
                                            {window.matchMedia("(max-width:993px)").matches &&
                                                <div className="Inquib-corporateText fifthtabtext posabs-deskalign">
                                                    INSTITUTIONS
                                                </div>
                                            }
                                                <div className="fifthtabtext fntsixtextquibtab">Organize industry awareness quiz in your campus and even across your region.</div>
                                                <div className="mobtabalign-quibfifth ">
                                                <div>
                                                    <ul className="listparent-quib list-fifthtabquib">
                                                        <li className="size-quib-pointlist">Universities</li>
                                                        <li className="size-quib-pointlist">Colleges</li>
                                                        <li className="size-quib-pointlist">Vocational Institutes</li>
                                                        <li className="size-quib-pointlist">Industry Student Chapters</li>
                                                    </ul>
                                                </div>
                                                <div className="parentwrap-quib-button-img topvaluebut">
                                                    <div className="visual-view-more-btn-btnnns inquibbutnCenter butend-fifthtabquib">
                                                        <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectinstituteReg} >Engage Now</button>
                                                    </div>
                                                    <div className="Enquirenow-quib-fifth">
                                                        <img src={EnquirenowQuib}  className="imag-quib-small-PartEnquire"></img>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 martop-quibfifthtab">
                                            {window.matchMedia("(max-width:993px)").matches &&
                                                <div className="Inquib-corporateText fifthtabtext">
                                                    INDUSTRIES
                                                </div>
                                            }
                                                <div>
                                                    <ul className="">
                                                        <li className="size-quib-pointlist">Corporates / MSME's</li>
                                                    </ul>
                                                </div>
                                                <div className="fifthtabtext fntsixtextquibtab">Conduct domain-specific assessment quiz within your organization</div>
                                                <div className="fifthtabtext-center fntsixtextquibtab">OR</div>
                                                <div className="fifthtabtext fntsixtextquibtab">Plan your next Placement Drive with a domain-specific assessment quiz</div>
                                                <div className="mobtabalign-quibfifth">
                                                <div>
                                                    <ul className="listparent-quib list-fifthtabquib">
                                                        <li className="size-quib-pointlist">Event Organizers</li>
                                                        <li className="size-quib-pointlist">Industry Associations</li>
                                                    </ul>
                                                </div>
                                                <div className="parentwrap-quib-button-img">
                                                    <div className="visual-view-more-btn-btnnns inquibbutnCenter butend-fifthtabquib">
                                                        <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectindustryReg} >Engage Now</button>
                                                    </div>
                                                    <div className="Enquirenow-quib-fifth">
                                                        <img src={PartnernowQuib}  className="imag-quib-small-PartEnquire"></img>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                         </div>
                                    </div>
                                    :
                                    <></>
                                    }
                                   </div>
                               </div>
                            

                              
                               </div>
            
                <div className="MobileviewnewDesign">
                        <div className="indiproceswrap">
                        <div class="contentWrapperProcessflow">
                            <div class="tabsWrapperProcessflow">
                                <ul class="tabs1">
                                    <li data-id="contentOne" class="active"><span className={this.state.activeData=== 1?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(1)}>INDUSTRY AWARENESS QUIZ</span><div className={this.state.activeData=== 1?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentTwo"><span className={this.state.activeData=== 2?"pfscoreclr fntwettext ":"pfscoreclr"} onClick={()=>this.visuaprofiling(2)}>INDUSTRY SECTORS</span><div className={this.state.activeData=== 2?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentThree"><span className={this.state.activeData=== 3? "pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(3)}>VALUE PROPOSITION</span><div className={this.state.activeData=== 3?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentFour"><span className={this.state.activeData=== 4?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(4)}>CORPORATE ASSESSMENTS</span><div className={this.state.activeData=== 4?"bdbottomclrnew ":""}></div></li>
                                    <li data-id="contentSix"><span className={this.state.activeData=== 5?"pfscoreclr fntwettext":"pfscoreclr"} onClick={()=>this.visuaprofiling(5)}>ENGAGE AHEAD</span><div className={this.state.activeData=== 5?"bdbottomclrnew ":""}></div></li>
                                </ul>
                            </div> 
                        <span class="nextprocessFlow">
                            <img src={Processflowarrow}></img>
                        </span>
                        <span class="PreviousprocessFlow">
                        <img src={Processflowarrow}></img>
                        </span>

                        </div>
                        </div>
                        {/* <div className="ProcessflowdescriptionWrap-Indi px-2">
                            <div className="visul-porfile-head  flow-align">
                                                    {this.state.activeData === 1 ? 
                                                    <div className="">
                                                            <div className="visual-profile-head1">Create</div>
                                                            <div className="visual-haed-two ">Institution profile</div>
                                                     </div> :
                                                    this.state.activeData === 2 ?
                                                    <div className="">
                                                            <div className="visual-profile-head1">Create your</div>
                                                            <div className="visual-haed-two ">Learning Experiences</div>
                                                    </div> : 
                                                    this.state.activeData === 3 ?
                                                    <div className="">
                                                            <div className="visual-profile-head1 ">Get YOUR Students</div>
                                                            <div className="visual-haed-two  ">Industry Ready</div>
                                                    </div>:
                                                    this.state.activeData === 4 ?
                                                    <>
                                                            <div className="visual-profile-head1 ">Get YOUR Students</div>
                                                            <div className="visual-haed-two  ">Bridging the gap</div>
                                                            <div className="visual-haed-two  ">between Education</div>
                                                            <div className="visual-haed-two  ">and Employment</div>
                                                    </> :
                                                    this.state.activeData === 5 ?
                                                    <>
                        
                                                            <div className="visual-haed-two">Apple Authorized Training Center <span className="txt-trans">(AATCe)</span></div>
                                                    </> :  
                                                    <>

                                                    </>
                                                    }
                                                </div>
                            </div> */}
                            {/* <div className="px-2">
                                <div className={this.state.textactive == 0 ? "col-sm-6 col-12 dflex-cent padding-zer imgalignment  " : "col-sm-6 col-12 dflex-cent padding-zer imgalignment   "}>
                                        <img src={this.state.activeData === 1 ? InstSvp: this.state.activeData === 2 ?
                                           Lmscourse : this.state.activeData === 3 ? Phonixpng :
                                                 this.state.activeData === 4 ? Instdashboard :
                                                     this.state.activeData === 5 ? appleimg : upskillinggs} className="width-100per-image imagshaw-pf" />
                                </div>
                            </div>
                            <div className="textprocessflowwrap-indi px-2">
                                    <div className="visual-data-contentss ">
                                                    {this.state.activeData === 1 ?
                                                    <div className="">
                                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                            <p className="mb-3">Institute profiling refers to providing information and insights about an educational institute, school, college, or an university.</p>
                                                            <p className="mb-3">Profiling an Institute helps create visibility and awareness among students, parents, and the community. It allows the institute to showcase its unique features, programs, and achievements, establishing its brand identity and reputation.</p>
                                                            <p className="mb-3">Institute profiling plays a crucial role in student recruitment. It helps potential students and parents make informed decisions by providing comprehensive information about the institute's academic offerings, admission procedures, scholarships, and placement records.</p>
                                                            <p className="mb-3">Institute profiling supports the growth and development of educational institutes by enhancing their visibility, reputation, and engagement with students, parents, alumni, and partners.</p>
                                                        </div>
                                                    </div> :
                                                    this.state.activeData === 2 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext ">
                                                        <p className="mb-3">
                                                         Aims at providing educational programs or learning opportunities to individuals or groups and make courses available for enrollment or participation by students or learners.
                                                         </p>
                                                         <p className="mb-3">
                                                         This includes Curriculum Development, Course Creation, Instruction and Facilitation, Assessment and Evaluation, Enrollment and Registration, & Support Services.
                                                         </p>
                                                        <p className="mb-0">
                                                        Courses can cover a wide range of subjects, disciplines, and levels of education, from academic subjects in schools and universities to professional training programs and skill development courses. The aim is to provide structured learning experiences and opportunities for individuals to acquire knowledge, develop skills, and achieve specific learning goals.
                                                        </p>
                                                    </div>:
                                                    this.state.activeData === 3 ?
                                                    <div className=" ">
                                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                                        <p className="mb-3">
                                                             <span className="font-weight-bold">Learn new skills.</span> Learning a new skill can help you improve your job prospects and make yourself more marketable. It can also help you expand your knowledge and interests.
                                                             </p>
                                                             <p className="mb-3">
                                                             <span className="font-weight-bold">Get involved in extracurricular activities.</span> Extracurricular activities are a great way to meet new people, learn new skills, and develop your interests. There are many different types of extracurricular activities available, so you can find something that you are passionate about.
                                                             </p>
                                                             <p className="mb-3">
                                                             <span className="font-weight-bold">Soft skills:</span> Soft skills, such as communication, teamwork, and problem-solving, are also important for industry readiness. These skills can be developed through education, experience, and self-reflection.
                                                             </p>
                                                             <p className="mb-0">
                                                             <span className="font-weight-bold">Get a job.</span> Getting a job can help you develop your work ethic and learn new skills. It can also help you save money for college or other expenses.                                </p>
                                                        </div>
                                                    </div>:
                                                    this.state.activeData === 4 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext ">
                                                         <p className="mb-3">
                                                                   Educational Institutions plays a prominent role in facilitating placement opportunities to student, to secure employment or internships in their chosen field or industry.                                                                 </p>
                                                                 <p className="mb-3">
                                                                 Placement opportunities aim to bridge the gap between theoretical learning and practical application by offering individuals a chance to gain hands-on experience in a professional setting. They provide a platform for individuals to apply their knowledge, develop essential skills, and gain industry-specific insights.                                                                </p>
                                                                 <p className="mb-0">
                                                                 Placement opportunities can take various forms, including internships, apprenticeships, co-op programs, or even full-time job placements. They may be offered by a specific company or organization, or they could be facilitated through partnerships between educational institutions and industry partners.                                                                </p>        
                                                    </div> :
                                                    this.state.activeData === 5 ?
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                                                  <p className="mb-3">
                                                                    Apple Authorized Training Center for Education <span className="txt-trans">(AATCe)</span> is a specialized educational institution offering that is certified by Apple Inc. to provide training and certification programs focused on Apple technologies. These training centers offer courses and workshops designed to enhance the skills and knowledge of educators, IT professionals, and individuals.                                                                </p>
                                                                    <p className="mb-3">
                                                                    Skillablers partnership with iPlanet, enables us to jointly offer educational institutions the opportunity to set up a dedicated Centre of Excellence Lab in campuses.                                                                </p>
                                                    </div>:
                                                    <div className="rqst-heading  text-trns-demo fntsizepopuptext">
                                                             Connect with your shortlisted candidates virtually 
                                                                       and capture real time feedback. 
                                                                       You can choose to connect 
                                                                       with candidate 1 on 1 or as a panel
                                                    </div>
                                                }
                                    </div>
                            </div>

                        <div className="textprocessflowwrap-indi px-2"> 
                            <div className="processflowAccessbtMobile  ">
                                {this.state.activeData === 3 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow">
                                        <div className="pop-imagcent" onClick={() => { this.OpenRequestDemo("PhoenixFactorPolicy") }}>
                                            <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                        </div>
                                    </div>
                                }
                                {this.state.activeData === 5 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow" onClick={() => { this.OpenRequestDemo("AACTEPolicy") }}>
                                            <button className="createprofile meet-our-team texnonetransfor">Enquire Now</button>
                                    </div>
                                
        
                                }
                                {this.state.activeData === 4 &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow" onClick={() => { this.openinsti() }}>
                                            <button className="createprofile meet-our-team texnonetransfor"  >Get Started</button>
                                    </div>
    
                                }
                                {(this.state.activeData !== 4 && this.state.activeData !== 3 && this.state.activeData !== 5) &&
                                    <div className="visual-view-more-btn-btnnns buttncentenewdegindi-processflow">
                                            <button className="createprofile meet-our-team texnonetransfor py-2"
                                                onClick={() => { this.state.activeData === 5 && this.openModal() }}>
                                                <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? Instituteurl+'/ins-login'  :
                                                        this.state.activeData === 2 ? Instituteurl+'/ins-login' :
                                                             this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                         {this.state.activeData === 1 ? "Get Started" : this.state.activeData === 2 ? " Get Started " :
                                                             this.state.activeData === 3 ? "Get Started" : this.state.activeData === 5 ? "Get Started" :
                                                                 "Get Started"}</a>
                                            </button>
                                    </div>
                                }
                            </div>                                                  
                        </div> */}
                        {this.state.activeData === 1 ?
                                    <div>
                                      <div className="row alignaccordinginquib mx-0">
                                        <div className="col-lg-6">
                                            <div className="disflexiinquibmob"><img src={inquibYCCwithFreeimag} className="inquibyccfreeimag"></img></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="disflexiinquibmob"><img src={InquibimagProcess} className="inquibprocess"></img></div>
                                        </div>
                                      </div>
                                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext wid-mobinquib tex-fntnew-size tex-fntnew-sizefulhd' >
                                            <p className="mb-3">InQUIZition is the effective way of testing one's industry knowledge through fun assessments, powered by SKL YCC IAQ Series. Industry Awareness Quizzes (IAQ's) are designed to evaluate an individual's knowledge of a specific industry either before they embark on their career within it or to assist them in staying current with the industry's latest trends and advancements.</p>
                                            <p className="mb-3">Additionally, Industry Awareness Quizzes (IAQ's) serves the purpose of evaluating an individual's comprehension of the industry as a whole or concentrating on a particular sector within it.</p>
                                        </div>
                                        
                                        <div className="visual-view-more-btn-btnnns  inquibbutnCenter">
                                                     <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectGuestLogin}>Start Quiz</button>
                                        </div>
                                        
                                    </div>
                                :
                                    this.state.activeData === 2 ?
                                    <div>
                                       <div className="row mx-0">
                                        <div className="">
                                            <img src={quibsectomobiletabimag} className="widsector-tab-imag-quib"></img>
                                        </div>
                                       </div> 
                                       <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                            <button className="createprofile meet-our-team texnonetransfor" onClick={()=>this.setlasttabactive(5)}>Engage Ahead</button>
                                       </div>
                                    </div>
                                
                                    :
                                    this.state.activeData === 3 ?
                                    <div>
                                    <div className="row mx-0">
                                         <div className="Inquib-corporateText">
                                            VALUE PROPOSITION
                                         </div>
                                    </div>
                                    <div className="wid-valuemobile-quib">
                                         <ul className="listparent-quib">
                                            <li className="size-quib-pointlist">Industry Awareness Quizzes (IAQs) are valuable for several reasons, and their importance cannot be overstated</li>
                                         </ul>
                                         <div className='disflex-quib-value'>
                                         <ul className="listparent-quib">
                                            <li className="size-quib-pointlist">Knowledge Assessment</li>
                                            <li className="size-quib-pointlist">Preparation for Careers</li>
                                            <li className="size-quib-pointlist">Continuous & Focused Learning</li>
                                            <li className="size-quib-pointlist">Professional Development</li>
                                         </ul>
                                         <ul className="listparent-quib">
                                            <li className="size-quib-pointlist">Benchmarking</li>
                                            <li className="size-quib-pointlist">Competitive Advantage</li>
                                            <li className="size-quib-pointlist">Awareness of Industry Trends</li>
                                            <li className="size-quib-pointlist">Compliance and Certification</li>
                                         </ul>
                                         </div>
                                    </div>
                                    <div className="visual-view-more-btn-btnnns inquibbutnCenter" onClick={()=>this.setlasttabactive(5)}>
                                                 <button className="createprofile meet-our-team texnonetransfor">Engage Ahead</button>
                                    </div>
                                   </div>
                                   :
                                    this.state.activeData === 4 ?
                                    <div>
                                        <div className="row mx-0">
                                             <div className="Inquib-corporateText">
                                                CORPORATE ASSESSMENTS
                                             </div>
                                        </div>
                                        <div>
                                             <ul className="listparent-quib">
                                                <li className="size-quib-pointlist"><span className="bldtex-list-quib">Recruitment Competency</span>: IAQs assess and validate prospective recruits' knowledge and competence in their respective industries, ensuring that they are well-informed to perform their roles effectively.</li>
                                                <li className="size-quib-pointlist"><span className="bldtex-list-quib">Skill Gap Identification</span>: These quizzes identify skill gaps within the workforce, enabling companies to tailor training and development programs to address specific knowledge deficiencies.</li>
                                                <li className="size-quib-pointlist"><span className="bldtex-list-quib">Continuous Learning</span>: IAQs encourage a culture of continuous learning and knowledge enhancement among employees, which is essential for staying competitive in rapidly evolving industries</li>
                                                <li className="size-quib-pointlist"><span className="bldtex-list-quib">Quality Assurance</span>: By assessing employee industry knowledge, IAQs contribute to maintaining and improving the overall quality and standards of products and services delivered by the organization.</li>
                                             </ul>
                                        </div>
                                        <div className="visual-view-more-btn-btnnns inquibbutnCenter">
                                                     <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectindustryReg}>Engage Now</button>
                                        </div>
                                    </div>
                                    :
                                     this.state.activeData === 5 ?
                                     <div>
                                          <div className="row mx-0">
                                             <div className="col-lg-4  martop-quibfifthtab">
                                                 <div className="Inquib-corporateText fifthtabtext">
                                                     INDIVIDUALS
                                                 </div>
                                                 <div className="fifthtabtext fntsixtextquibtab">Participate to enhance your industry awareness - also to be noticed by companies.</div>
                                                 <div className="mobtabalign-quibfifth">
                                                 <div>
                                                     <ul className="listparent-quib list-fifthtabquib">
                                                         <li className="size-quib-pointlist">Students</li>
                                                         <li className="size-quib-pointlist">Candidates</li>
                                                         <li className="size-quib-pointlist">Executives</li>
                                                         <li className="size-quib-pointlist">Any Individual</li>
                                                     </ul>
                                                 </div>
                                                 <div className="parentwrap-quib-button-img">
                                                     <div className="visual-view-more-btn-btnnns inquibbutnCenter butend-fifthtabquib">
                                                         <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectGuestLogin} >Start Quiz</button>
                                                     </div>
                                                     <div className="Enquirenow-quib-fifth">
                                                         <img src={freeimgicone} className="imag-quib-small-fifth"></img>
                                                     </div>
                                                 </div>
                                                 </div>
                                             </div>
                                             <div className="col-lg-4  martop-quibfifthtab">
                                                 <div className="Inquib-corporateText fifthtabtext posabs-deskalign">
                                                     INSTITUTIONS
                                                 </div>
                                                 <div className="fifthtabtext fntsixtextquibtab">Organize industry awareness quiz in your campus and even across your region.</div>
                                                 <div className="mobtabalign-quibfifth ">
                                                 <div>
                                                     <ul className="listparent-quib list-fifthtabquib">
                                                         <li className="size-quib-pointlist">Universities</li>
                                                         <li className="size-quib-pointlist">Colleges</li>
                                                         <li className="size-quib-pointlist">Vocational Institutes</li>
                                                         <li className="size-quib-pointlist">Industry Student Chapters</li>
                                                     </ul>
                                                 </div>
                                                 <div className="parentwrap-quib-button-img">
                                                     <div className="visual-view-more-btn-btnnns inquibbutnCenter butend-fifthtabquib">
                                                         <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectinstituteReg}>Engage Now</button>
                                                     </div>
                                                     <div className="Enquirenow-quib-fifth">
                                                         <img src={EnquirenowQuib}  className="imag-quib-small-PartEnquire"></img>
                                                     </div>
                                                 </div>
                                                 </div>
                                             </div>
                                             <div className="col-lg-4 martop-quibfifthtab">
                                                 <div className="Inquib-corporateText fifthtabtext">
                                                     INDUSTRIES
                                                 </div>
                                                 <div className="marleft-coorporetaMSME">
                                                     <ul className="">
                                                         <li className="size-quib-pointlist">Corporates / MSME's</li>
                                                     </ul>
                                                 </div>
                                                 <div className="fifthtabtext fntsixtextquibtab">Conduct domain-specific assessment quiz within your organization</div>
                                                 <div className="fifthtabtext-center fntsixtextquibtab">OR</div>
                                                 <div className="fifthtabtext fntsixtextquibtab">Plan your next Placement Drive with a domain-specific assessment quiz</div>
                                                 <div className="mobtabalign-quibfifth">
                                                 <div>
                                                     <ul className="listparent-quib list-fifthtabquib">
                                                         <li className="size-quib-pointlist">Event Organizers</li>
                                                         <li className="size-quib-pointlist">Industry Associations</li>
                                                     </ul>
                                                 </div>
                                                 <div className="parentwrap-quib-button-img">
                                                     <div className="visual-view-more-btn-btnnns inquibbutnCenter butend-fifthtabquib">
                                                         <button className="createprofile meet-our-team texnonetransfor" onClick={this.redirectindustryReg} >Engage Now</button>
                                                     </div>
                                                     <div className="Enquirenow-quib-fifth">
                                                         <img src={PartnernowQuib}  className="imag-quib-small-PartEnquire"></img>
                                                     </div>
                                                 </div>
                                                 </div>
                                             </div>
 
                                          </div>
                                     </div>
                                    :
                                    <></>
                                    }

        
                </div>



                {/* <div className="rqst-demp-main">
<button className="btn rqst-demo  mobilenone" onClick={() => { this.OpenRequestDemo("DemoPolicy") }} >
Request For Demo
</button>
</div>
<div className="mobiledemologo  rqst-demologo "> 
<img src={Requestdomologo} className="" onClick={() => { this.OpenRequestDemo("DemoPolicy") }} />
</div>
 

<ToastContainer className="toastsubmit"/>
 {this.state.isReqstdemo &&
 <RequestDemo
 isFrame={false}
 Url={""}
close={this.closeRequestDemo}
Actiontype={this.state.type}
 isReqstdemo={true}
 toastMsg={this.toastMessage}
 />} */}
            </div>

        )
        }}
        export default InQUIZition