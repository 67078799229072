import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {OnlyNumbers , validateEmail, ValidateRequired} from '../General/validation';
import {saveBrandingDetails, GetAllCountryCodeList} from './Home-methods';
import {sendMail} from '../General/general-methods';
import { ToastContainer, toast } from 'react-toastify';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';
import Select, { components } from 'react-select';
import customStyles from '../General/CustomStyle';

const NOOP = () => { }

function Modal({ isFrame, Url, close = NOOP, isIntern }) {
    const [OpenDelay, setOpenDelay] = useState(false)
    const [CountryCodeList, setCountryCodeList] = useState()
    const [Intern , setIntern] = useState({
        fstName : '',
        LstName : '',
        email : '',
        mobile : '',
        errors: []
    })
    const [Branding , setBranding] = useState({
         Fullname :'',
         EmailId :'',
         Country :'',
         mobile:'',
         company :'',
         Designation :'',
         errors:[]
    })
    const [url , seturl] = useState([
        'https://www.youtube.com/embed/vg3y_UOKo7M',
        'https://www.youtube.com/embed/LiyDiRnPS84',
        'https://www.youtube.com/embed/m85MeVY50m8',
        'https://www.youtube.com/embed/yywIJC_NL7Q',
        'https://www.youtube.com/embed/ePIHSBkoRcg',
        'https://www.youtube.com/embed/7BXzQhSs1OY',
        'https://www.youtube.com/embed/az9GHhyf6eA'
    ])

    useEffect(() => {
        setTimeout(() => {
            setOpenDelay(true)
        }, 200);
        window. $('.modal-overlay').click(function(e) {
            if (!window.$(e.target).closest('.modal-box').length ||  !window.$(e.target).closest('.svg-play-icons').length){ closPopup() }
        });
        GetCountryCodeList()
    }, [])

    const GetCountryCodeList = () => {
        GetAllCountryCodeList()
        .then(result => {
            let List = result.data.recordsets[0];
            setCountryCode(List)
        })
    }

    const setCountryCode = (List) =>{
        setCountryCodeList(List)
    }

    const closPopup = () => {
        setOpenDelay(false)
        setIntern({
            fstName : '',
            LstName : '',
            email : '',
            mobile : '',
            errors: []
        })
        setBranding({
            Fullname :'',
            EmailId :'',
            Country :'',
            mobile:'',
            company :'',
            Designation :'',
            errors : []
        })
        setTimeout(() => {
            close(true)
        }, 200);
    }

    const handleChange = e =>{
        setIntern({
            ...Intern,
            [e.target.name]:e.target.value 
        })
    }

    const handleChangeBranding = e =>{
        setBranding({
            ...Branding,
            [e.target.name]:e.target.value 
        })
    }

    const handleSelection = (selection) => {
        setBranding({
            ...Branding,
            Country: selection
        })
    }

    const ValidateIsEmpty = (name, label) => {
        let flag = true;
        let errors = Intern.errors
        ValidateRequired(Intern[name], label, function (err) {
            errors[name] = err.RequiredField;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const validatEmail = (label) =>{
        let flag = true;
        let errors = Intern.errors
        validateEmail(Intern["email"], label, function (err) {
            errors["email"] = err.EmailId;
            flag = err.formIsValid;
        });
        setIntern(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const ValidateBrandIsEmpty = (name, label) => {
        let flag = true;
        let errors = Branding.errors
        ValidateRequired(Branding[name], label, function (err) {
            errors[name] = err.RequiredField;
            flag = err.formIsValid;
        });
        setBranding(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const validatBrandEmail = (label) =>{
        let flag = true;
        let errors = Branding.errors
        validateEmail(Branding["EmailId"], label, function (err) {
            errors["EmailId"] = err.EmailId;
            flag = err.formIsValid;
        });
        setBranding(Prev =>({
            ...Prev,
            errors: errors
        }))
        return flag
    }

    const ValidateIntern=async()=>{
        let a = await ValidateIsEmpty('fstName', 'First Name')
        let b = await ValidateIsEmpty('LstName', 'Last Name')
        let c = await validatEmail('Email Id')
        let d = await ValidateIsEmpty('mobile', 'Mobile Number')
        if( a=== true && b === true && c === true && d === true){
            return true;
        }
        else{
            return false
        }
    }

    const SaveIntern = async()=>{
        let val = await ValidateIntern()
    }

    const ValidateBranding  = async() =>{
        let a = await ValidateBrandIsEmpty("Fullname", "Full Name")
        let b = await validatBrandEmail('Email Id')
        let c = await ValidateBrandIsEmpty('Country','Country')
        let d = await ValidateBrandIsEmpty('mobile','mobile')
        let e = await ValidateBrandIsEmpty('company','company')
        let f =  await ValidateBrandIsEmpty('Designation','Designation')
        if(a === true && b=== true && c=== true && d === true && e === true && f === true)
        {
            return true
        }
        else{
            return false
        }

    }

    const saveBranding= async()=>{
        let val = await ValidateBranding()
        if(val){
            let FullName = Branding.Fullname
            let EmailId = Branding.EmailId
            let Country = Branding.Country.value
            let mobile = Branding.mobile
            let company = Branding.company
            let Designation = Branding.Designation
            let Type = 'BRANDING PARTNER'
            saveBrandingDetails(FullName, EmailId, Country, mobile, company, Designation, Type).then(result =>{
                if(result.data.recordsets[0][0].MSG === 'saved'){
                    let MailFrom = 'connect@skillablers.com';
                    let setSubject = 'SKILLABLERS - BRANDING PARTNER';
                    let bodyh = '<!DOCTYPE html><html><body>';
                    let bodyh1 = 'Hi '+FullName +',<br/>';
                    let bodyc = 'Thanks For Showing intrest, we will get back to you shorly<br/><br/>';
                    let bodyff = 'Regards,<br/> Skillablers Team <br/> <a href="https://www.skillablers.com/">www.skillablers.com</a>';
                    let bodyf ='</body></html>';
                    let setBody = bodyh+ bodyh1 + bodyc + bodyff + bodyf
                    sendMail(EmailId, MailFrom, setSubject, setBody).then(result1 =>{
                        toast.dismiss();
                        toast.success("Thanks For Showing intrest, we will get back to you shorly.", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        closeAfterDelay()
                    })
                }else{
                    toast.error("Registraion Failed, Please try Again Later.", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        }
        const closeAfterDelay=async()=>{
            setTimeout(() =>{closPopup()},5000)
        }
    }
    return (
        <>
            <div className='modal-overlay'></div>
            <div className={OpenDelay ? isFrame === false? 'modal-box active mi-wdth' : 'modal-box active trans-background' :
            isFrame === false?  'modal-box mi-wdth': 'modal-box trans-background'}>
                <div className='modal-close' onClick={closPopup}><FontAwesomeIcon icon={faTimes} /> </div>
                {isFrame ?
                    <iframe className='modal-iframe' src={url[Url]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    :
                    isIntern ? <>
                        <div className='intern-modal'>INTERNSHIP</div>
                        <div className='iner-datasection'>Provide us with your details and we
                            will get back to you as soon as possible.</div>
                        <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                               <input type="text" className="input-reg" placeholder="First name"
                                  value={Intern.fstName} name='fstName' onChange={handleChange}
                                  onBlur={()=>{ValidateIsEmpty('fstName', 'First Name')}} />
                                   <span className='error'>{Intern.errors["fstName"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <input type="text" className="input-reg" placeholder="Last name" 
                           value={Intern.LstName} name='LstName' onChange={handleChange}
                           onBlur={()=>{ValidateIsEmpty('LstName', 'Last Name')}}  />
                            <span className='error'>{Intern.errors["LstName"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <input type="text" className="input-reg" placeholder="Email Id" 
                            value={Intern.email} name='email' onChange={handleChange}
                            onBlur={()=>{validatEmail('Email Id')}}  />
                             <span className='error'>{Intern.errors["email"]}</span>
                        </div>
                        <div className='col-12 px-0  padding-bottom-one-vh'>
                            <div className="row mx-0 col-12 px-0">

                                <input type="text" className="input-reg" placeholder="Mobile number"
                                value={Intern.mobile} name='mobile'  onChange={handleChange}
                                 onKeyPress={OnlyNumbers}  onBlur={()=>{ValidateIsEmpty('mobile', 'Mobile Number')}}  />
                                  <span className='error'>{Intern.errors["mobile"]}</span>
                            </div>
                        </div>
                        <div className='text-aligen-center d-fully-cent '>
                            <button className='ycc-home-button z-ined-2postition-realtive  login-button-padding' 
                            onClick={SaveIntern}>Submit</button>
                        </div>
                    </> :
                        <>
                            <div className='intern-modal'>Branding Partner</div>
                            <div className='iner-datasection'>Provide us with your details and we
                                will get back to you as soon as possible.</div>
                                {/* {console.log('Branding.errors',Branding.errors)} */}
                            <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                                    <input type="text" className="input-reg"
                                     placeholder="Full name" value={Branding.Fullname}  name="Fullname" 
                                     onBlur={()=>{ValidateBrandIsEmpty("Fullname", "Full Name")}} 
                                     onChange={handleChangeBranding} />
                                        <span className='error'>{Branding.errors["Fullname"]}</span>
                                {/* </div> */}
                               
                            </div>
                            
                            <div className='col-12 px-0  padding-bottom-one-vh'>
                                <input type="text" className="input-reg" placeholder="Email Id" 
                                value={Branding.EmailId} name='EmailId'  
                                onBlur={()=>{validatBrandEmail('Email Id')}}  onChange={handleChangeBranding}  
                                />
                                 <span className='error'>{Branding.errors["EmailId"]}</span>
                            </div>
                            <div className='col-12 px-0  padding-bottom-one-vh'>
                                <div className="row mx-0 col-12 px-0">
                                    <div className="col-5 country-code-padding">
                                        <Select
                                            placeholder="Country"
                                            styles={customStyles}
                                            options={CountryCodeList}
                                            onChange={handleSelection}
                                            value={Branding.Country}
                                            onBlur={()=>{ValidateBrandIsEmpty('Country','Country')}}
                                        />
                                        <span className='error'>{Branding.errors["Country"]}</span>
                                    </div>
                                    <div className="col-7 mobile-padding">
                                        <input type="text" className="input-reg" placeholder="Mobile number"
                                        value={Branding.mobile} name='mobile' 
                                        onBlur={()=>{ValidateBrandIsEmpty('mobile','Mobile Number')}}
                                        onChange={handleChangeBranding}  />
                                         <span className='error'>{Branding.errors["mobile"]}</span>
                                    </div>
                                </div>
                                
                               
                            </div>
                            <div className='col-12 px-0  padding-bottom-one-vh'>
                                <input type="text" className="input-reg" placeholder="Company"
                                value={Branding.company} name="company"
                                onBlur={()=>{ValidateBrandIsEmpty('company','company')} }
                                onChange={handleChangeBranding}  />
                                 <span className='error'>{Branding.errors["company"]}</span>
                            </div>
                            <div className='col-12 px-0  padding-bottom-one-vh'>
                                <input type="text" className="input-reg" placeholder="Designation"
                                value={Branding.Designation} name='Designation'
                                onBlur={()=>{ValidateBrandIsEmpty('Designation','Designation')}}
                                onChange={handleChangeBranding}  />
                                    <span className='error'>{Branding.errors["Designation"]}</span>
                            </div>
                            <div className='text-aligen-center d-fully-cent '>
                                <button className='ycc-home-button z-ined-2postition-realtive  login-button-padding' 
                                onClick={saveBranding} >Submit</button>
                            </div>
                        </>}
                        <ToastContainer
                            position="bottom-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable
                            pauseOnHover={false}
                        />
                    </div>
        </>
    )

}

export default React.memo(Modal); 