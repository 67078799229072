import React from "react";
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import { IndustryURL,IndusURL } from "../../Assests/js/config";
import {GetAllClientDetails} from "../Indivi_jobs_component/indivi_methods";
import DefaulCom from '../../Assests/Images/defaultProfil.jpg';
import { UploaderUrl } from '../../Assests/js/config';
import 'react-toastify/dist/ReactToastify.css';
import Chalohomelogo from '../../Assests/Images/Chalo-home-logo.svg'
import Chalo_mobile_logo from '../../Assests/Images/Chalo-mobile_logo.svg'
import Elearning_platform1 from '../../Assests/Images/Elearning platform 1.svg'
import Hiring2 from '../../Assests/Images/Hiring 2.svg'
import Mentor1 from '../../Assests/Images/Mentor 1.svg'
import Chalo_exam_roadmap from '../../Assests/Images/Chalo-exam-roadmap.svg'
import Mob_roadmapchalo from '../../Assests/Images/Mob-roadmapchalo.svg'
import { HashRouter, Link } from 'react-router-dom';



 
 
class Chalo extends React.Component
{
    constructor(props){
        super(props)
        this.state = {
            activeData:1,
            skills: [],
            jobs :[],
            clientdetail: [],
            categ : [],
            catcomplogo:[],
            // searchSkills : '',
            searchSkills : [],
            textactive:1,
            Tab:1,
            PFCfiltredselected:'',
            CategHalf :[],
            selectedpack:'',
            checkedtrue:false
        }
    }


    componentDidMount ()  { 
       
        window.$('html,body').animate({
            scrollTop: 0
        });
            // $('html,body').animate({
            //     scrollTop: 0
            // });
            // document.getElementsByClassName('skltext')[0].classList.add('active')
            // document.getElementsByClassName('row1')[0].classList.add('active')
            // document.getElementsByClassName('row2')[0].classList.add('active')
            // document.getElementsByClassName('row3')[0].classList.add('active')
            $('.individualBlockIdent').each(function () {
                if ($(this)[0].id === "visulprofile") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                        document.getElementById('visulprofile').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "perfectJobs") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                        document.getElementById('perfectJobs').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "videoesss") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 40 / 100) < $(window).scrollTop()) {
                        document.getElementById('videoesss').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "Events") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                        document.getElementById('Events').classList.add('active')
                    }
                }
            })

            $(window).on('scroll', e => {
                $('.individualBlockIdent').each(function () {
                    if ($(this)[0].id === "visulprofile") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                            document.getElementById('visulprofile').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "perfectJobs") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                            document.getElementById('perfectJobs').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "videoesss") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 40 / 100) < $(window).scrollTop()) {
                            document.getElementById('videoesss').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "Events") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                            document.getElementById('Events').classList.add('active')
                        }
                    }
                })
            })
}


render(){
        return(
        <div class="indi-conatiner">
        <div className='padding-top-10vhh bg_partnerchalo'>
        <div class="">
            <div class="chalo-exam-section1width  individualBlockIdent no-padding-top" id="">
                <div className="row">
                    <div className="col-lg-6 col-12 mob-widalign-fist-chalo">
                        <div><img src={Chalohomelogo}/></div>
                        <div className="chaloexam-heading">
                        <div><span className="india-onlyhead1">India’s ONLY E-Learning & </span></div>
                        <div><span className="E-refer-onlyhead2">E-Reference platform curated for</span></div>
                        <div><span className="Hi-edu-sechead3">Higher Education Sector</span></div>
                        <div className="explorebtn-chalo">
                        <a href="https://chaloexam.com/" target="_blank">
                           <button id="" className="meet-our-team">Explore </button>
                                            </a>
                            </div> 
                        </div>
                    </div>
                    <div className="col-lg-6 mobi_imag_wrap">
                    <div><img src={Chalo_mobile_logo}/></div>
                    </div>

                </div> 
            </div>
        </div>
        </div>

        <div className="chalo-digitaltextwrap">
            <div className="row">
                <div className="col-lg-6">
                    <div className="E-Reference-platformText">
                     <span><span className="chalotext-bold">ChaloExam</span> is the digital E-Learning / E- Reference platform in the higher education sector curated as per respective University / College / NEP guidelines. AI-Based One-To-One E-Learning Platform for the Higher Education sector exclusively for UG, PG Programs, 
                        <span className="chalotext-bold">(BCA, BBA, BCOM, BA, MBA,)</span> hosted as a website and as a mobile application</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="EMH-boxes-wrap">
           <div className="row">
                <div className="col-lg-4 chalo_flex-boxes">
                    <div className="Box-square-chalo ">
                        <div className="chalo-icon-imag"><img src={Elearning_platform1}/></div> 
                        <div className="align-tex-chalo">
                        <div className="sq-box-text_chalo"><span><span className="chalofirstlettercolor">E</span>-Learning Platform</span></div>
                        <div className="sqR_chalobox-lasttwotext">
                        <div><span>Online Sources, Case-Studies, Solved</span></div>
                        <div><span>Questions Papers, Real-Time Support</span></div>
                        </div> 
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 chalo_flex-boxes">
                   <div className="Box-square-chalo ">
                        <div className="chalo-icon-imag"><img src={Mentor1}/></div> 
                        <div className="align-tex-chalo">
                        <div className="sq-box-text_chalo"><span><span className="chalofirstlettercolor">M</span>entor Based Platform</span></div> 
                        <div className="sqR_chalobox-lasttwotext">
                        <div><span>Industry and SME's at Your Disposal,</span></div>
                        <div><span>Assessment</span></div>
                        </div>
                        </div> 
                    </div> 
                </div>
                <div className="col-lg-4 chalo_flex-boxes">
                    <div className="Box-square-chalo ">
                        <div className="chalo-icon-imag"><img src={Hiring2}/></div> 
                        <div  className="align-tex-chalo">
                        <div className="sq-box-text_chalo"><span><span className="chalofirstlettercolor">H</span>iring Support</span></div>
                        <div className="sqR_chalobox-lasttwotext">
                        <div><span>Dbugging, Profiling Ready (HR-Tech),</span></div>
                        <div><span>Internships</span></div> 
                        </div>
                        </div>
                    </div> 
                </div>
            </div>   
        </div>
        
        {/* <div className="Roadmapimag-chaloExam">
            <img src={Chalo_exam_roadmap} className="roadmapimgwid_chalo"/>
            <img src={Mob_roadmapchalo} className="roadmapimgwid_chalo-Mobile"/>
        </div> */}

    </div>
        

       
         )
    }
}
export default Chalo
 