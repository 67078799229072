import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {OnlyNumbers , validateEmail, validateMobile,ValidateRequired} from '../General/validation';
import Individual from '../../Assests/Images/individuals.png'
import Institution from '../../Assests/Images/institution.png'
import Industries from '../../Assests/Images/industry.png'
import Recruiter from '../../Assests/Images/recruiter.png'
import Individualactive from '../../Assests/Images/individuals-active.png'
import Institutionactive from '../../Assests/Images/institution-active.png'
import Industriesactive from '../../Assests/Images/industries-active.png'
import Recruiteractive from '../../Assests/Images/recruiter-active.png'
const NOOP = () => { }

function Modal({ close = NOOP}) {
    
    const [OpenDelay, setOpenDelay] = useState(false)
    const [Intern , setIntern] = useState({
      
        activelogo:'0',
        errors: [],
        errors1:{},
        key:""
       
    })
    
    const [logindata, setlogindata] = useState({
        mail: '',
        password: '',
        error: {}
    })
    useEffect(() => {
        setTimeout(() => {
            setOpenDelay(true)
        }, 200);
       window. $('.modal-overlay').click(function(e) {
            if (!window.$(e.target).closest('.modal-box').length ||  !window.$(e.target).closest('.svg-play-icons').length){
                closPopup()
            }
        });
    },[])

    const closPopup = () => {
        setOpenDelay(false)
        setIntern({
            mail: '',
            password: '',
            error: {},
            errors: [],
            errors1:{},
            key:""
        })
        setTimeout(() => {
            close(true)
        }, 200);
    }
    const handleChange = (name) => e =>{
        setlogindata({
            ...logindata,
            [name]:  e.target.value
        })
    }
    
    const SaveIntern = async()=>{
        let flag;
        let fall=Intern.errors1
        if(Intern.activelogo==='3'||Intern.activelogo==='2'||Intern.activelogo==='4'||Intern.activelogo==='5'){
            // console.log("HELLO")
            fall.key=""
            validateAll()

          
        }
        else{
            flag=false
            if(flag===false){
                fall.key='*Select the category.'

            }
           
            setIntern(
                {
                   ...Intern,
                   errors1:fall        
                })
                // console.log('fall-->',fall)
        }return flag 
        
    }
    
    
const changeimg = (val) =>{
    setIntern(
        {
           ...Intern,
           activelogo:val

        }
    )
    
}





   
const validate_UserName = () => {
    let errors = logindata.error;
    let flag = true;

    if (typeof logindata.mail !== "undefined") {
        if (logindata.mail.match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)) {
            errors = {};
        }
        else if (logindata.mail.match(/^[0-9]{10}$/)) {
            errors = {};
        } else {
            flag = false;
            errors["UserName"] = "*Please enter valid User Name.";
        }
    }
    else {
        flag = false;
        errors["UserName"] = "*Please enter valid User Name."
    }

  setlogindata({
      ...logindata,
      error: errors
  })
    return flag
}
const validate_Password = () => {
    let errors = logindata.error;
    let flag = true;
    ValidateRequired(logindata.password, 'Password', function (err) {
        errors["Password"] = err.RequiredField;
        flag = err.formIsValid;
    });
   setlogindata({
       ...logindata,
       error: errors
   })
    return flag
}

const validateAll = () =>{
    let a = validate_UserName();
    let b = validate_Password();
    if(a === true && b  ===  true){
        return true
    }
    else{
        return true
    }
}
    


    return (
        <>
            <div className='modal-overlay'></div>
            <div className={OpenDelay ? 'modal-box active mi-wdth modal-box-signin'  :  'modal-box mi-wdth'}>
                <div className='modal-close' onClick={closPopup}><FontAwesomeIcon icon={faTimes} /> </div>
                        <div className='intern-modal'>SIGN IN</div>
                        <div className='iner-datasection logos'>
                       <div className='logo-signup'>{Intern.activelogo === '2'?<img src={Individualactive} id="logo-all"/>: <img onClick={()=>changeimg('2')} src={Individual} id="logo-all" />} 
                       <p id={Intern.activelogo==="2"?"p1-indblue":'p1-ind'}>Individuals</p> </div>
                       <div className='logo-signup' > {Intern.activelogo === '3'?<img src={Institutionactive} id="logo-all"/>: <img onClick={()=>changeimg('3')} src={Institution} id="logo-all" />}
                       <p id={Intern.activelogo==="3"?"p1-indblue":'p1-ind'}>Institition</p> </div>
                       <div className='logo-signup'>  {Intern.activelogo === '4'?<img src={Industriesactive} id="logo-all"/>: <img onClick={()=>changeimg('4')} src={Industries} id="logo-all" />}
                       <p id={Intern.activelogo==="4"?"p1-indblue":'p1-ind'}>Industry</p> </div>
                       <div className='logo-signup'> {Intern.activelogo === '5'?<img src={Recruiteractive} id="logo-all"/>: <img onClick={()=>changeimg('5')} src={Recruiter} id="logo-all" />}
                       <p id={Intern.activelogo==="5"?"p1-indblue":'p1-ind'}>Recruiter</p> </div>
                           </div>
                        <div className='logoerror'>{Intern.errors1.key}</div>
                        <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                               <input  className="input-reg input-reg-signin-name"
                            
                                placeholder="Email / Mobile Number"
                                type="text"
                                name='username'
                                value={logindata.mail}
                                onChange={handleChange('mail')}
                                onBlur={validate_UserName}
                               
                            // autoComplete="off"
                            />
                                
                                   <span className='error error-signin'>{logindata.error.UserName}</span>
                        </div>
                        <div className="col-12 px-0 row mx-0 padding-bottom-one-vh">
                               <input className="input-reg input-reg-signin-pwd"
                                placeholder="Password"
                                label="PASSWORD"
                                type="password"
                                name="password"
                                value={logindata.password}
                                onChange={handleChange('password')}
                                onBlur={validate_Password}
                              />
                               <span className='error error-signin'>{logindata.error.Password}</span>
                        </div>
                        <div className='text-aligen-center d-fully-cent '>
                            <button className='ycc-home-button z-ined-2postition-realtive  login-button-padding ' 
                            onClick={SaveIntern}>Sign In</button>
                        </div>
                        <div className='account'>
                            <p id='account-text'>Don't have an account yet?</p>
                        </div>
                        <div>
                            <button id='signup-btn'>Sign Up</button>
                        </div>         
            </div>
        </>
    )
}
export default React.memo(Modal); 