import React from "react";
import $ from 'jquery';
class PrivacyPolicy extends React.Component
{
    componentDidMount(){
        window.$('html,body').animate({
            scrollTop: 0
        }); 
    }
    render(){
        return(
            <div className="section section-common">
            <div className="section-wrapper">
                <div className="regular-pages">
                    <h1 className="h1-regular-format">Privacy Policy</h1>
                    
                    <p className="p-regular-format">You are requested to read them carefully before you use the services of the site. 
                    </p>
                    <p className="p-regular-format">The term 'User' shall refer to the user who is has visited the Site and browsing the Site. The terms 'Site' refers to the aforementioned websites belonging to Skillablers. By using the Site, you agree to follow and be bound by the following terms and conditions concerning your use of Skillablers Site. Skillablers may revise the Terms of Use at any time without notice at its sole discretion.</p>
    
            
                    <ul className="ul-regular-format">
                        <li> Skillablers may terminate User's access at any time for any reason whatsoever. The provisions regarding a disclaimer of warranty, accuracy of information and indemnification shall survive such termination. Skillablers shall continuously monitor access to the Site.</li>
                        <li>All content, graphics, images, pictures etc. present on the Site is the sole and exclusive property of Skillablers. No material from this site shall be copied, modified, reproduced, republished, uploaded, transmitted, adopted, posted or distributed in any form whatsoever. All rights herein are reserved. Unauthorized use of the materials appearing on this site may violate copyright, trademark and other applicable laws, and could result in criminal and/or civil actions. Skillablers Technology Pvt. Ltd. is a registered is a trademarked brand. This trademark shall not be used in any manner whatsoever.
                        </li>
                        <li>Skillablers does not accept any responsibility towards the contents and/or information practices of third party Sites which may have links through Skillablers Site.</li>
                        <li>The Site, and all content, materials, information, software, products and services provided on the Site, are provided on an "as is" and "as available" basis. Skillablers disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</li>
                        <li>Skillablers has no responsibility for any damage to a User's computer system or loss of data that result from the download of any content, materials, information from the Site.</li>
                        <li>Skillablers may change or discontinue any aspect, portion of the Site at any time without notice, including its content or features. Skillablers reserves the right to change the terms and conditions applicable to the use of the Site. Such changes shall be effective immediately without notice, which shall be placed on the Site.</li>
                        <li>In any event Skillablers will not be liable for damages of any kind, including without limitation, direct, incidental or consequential damages (including, but not limited to, damages for loss of profits, business interruption and loss of programs or information) arising out of the use of A Site, or any information provided on the Site, or in the Products or Services, any claim attributable to errors, omissions or other inaccuracies in the Product or interpretations thereof.</li>
                        <li>The User agrees to indemnify, defend and hold Skillablers harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site.</li>
                        <li>Skillablers disclaims all warranties as to the accuracy, completeness or adequacy of information contained in the Site.</li>
                        <li>Skillablers makes no warranty (a) that the Site will meet your requirements; (b) that the Site will be available on an uninterrupted, timely, secure, or error-free basis; (c) that the results that may be obtained from the use of the Site or any services offered through the Site will be accurate or reliable.</li>
                        <li>Any information provided by the User will not be shared with any third party. Skillablers Technology Pvt. Ltd. reserves the right to use the said information.</li>
                        <li>The Site may provide links to Web sites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Site. You agree that Skillablers is not responsible for the availability of, and content provided on, third party Web sites. Skillablers is not responsible for third party content accessible through the Site, including opinions, advice, statements and advertisements and User shall bear all risks associated with the use of such content. Skillablers is not responsible for any loss or damage of any sort that a User may incur from dealing with any third party.</li>
                    </ul>
                
    
                    <h3>Contactiong the Website</h3>
                    <p className="p-regular-format">If you have any questions about this privacy statement, the practices of this site, or your dealings with our Site, you can contact Skillablers at <a href="mailto:connect@skillablers.com"  style={{color: 'red',textDecoration: 'none'}}>connect@skillablers.com</a></p>
    
                </div>
    
            </div>
        </div>
        )
    }
}
export default PrivacyPolicy
 