import React from "react";
import $ from 'jquery';
import {RoutingUrl} from '../../Assests/js/config'
class SafeHarbor extends React.Component
{
    componentDidMount(){
        window.$('html,body').animate({
            scrollTop: 0
        }); 
    }
    render(){
        return(
            <div className="section section-common">
                <div className="section-wrapper">
                    <div className="regular-pages">
                        <h1 className="h1-regular-format">Safe Harbor Provision</h1>
                        <p className="p-regular-format">Safe Harbor Provision for <a href={RoutingUrl}   style={{color: 'red',textDecoration: 'none'}}>www.skillablers.com </a>and <a href="https://www.edccts.com"  style={{color: 'red',textDecoration: 'none'}}>www.edccts.com</a></p>
                        <p className="p-regular-format">Certain statements contained herein concern our future growth prospects and are forward-looking statements regarding our future business expectations intended to qualify for the 'safe harbor' under the Private Securities Litigation Reform
                            Act of 1995, which involve a number of risks and uncertainties that could cause actual results to differ materially from those in such forward-looking statements.
                        </p>
                        <p className="p-regular-format"> The risks and uncertainties relating to these statements include, but are not limited to, risks and uncertainties regarding fluctuations in earnings, fluctuations in foreign exchange rates, our ability to manage growth, intense competition
                            in IT services including those factors which may affect our cost advantage, wage increases in India, our ability to attract and retain highly skilled professionals, time and cost overruns on fixed-price, fixed-time frame contracts,
                            client concentration, restrictions on immigration, industry segment concentration, our ability to manage our international operations, reduced demand for technology in our key focus areas, disruptions in telecommunication networks
                            or system failures, our ability to successfully complete and integrate potential acquisitions, liability for damages on our service contracts, the success of the companies in which Skillablers and/or EDC Creative Technology Solutions
                            Private Limited has made strategic investments, withdrawal or expiration of governmental fiscal incentives, political instability and regional conflicts, legal restrictions on raising capital or acquiring companies outside India, and
                            unauthorized use of our intellectual property and general economic conditions affecting our industry.
                        </p>
                        <p className="p-regular-format"> Additional risks that could affect our future operating results which may, from time to time, be modified with additional written and oral forward-looking statements, including statements contained in the company's filings and reports.
                            Any forward-looking statements contained herein are based on assumptions that we believe to be reasonable presently. The company does not undertake to update any forward-looking statements that may be made from time to time by or on
                            behalf of the company unless it is required by law.</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default SafeHarbor
 