import React from "react";
import $ from 'jquery';
class Disclaimer extends React.Component
{
    componentDidMount(){
        window.$('html,body').animate({
            scrollTop: 0
        }); 
    }
    render(){
        return(
            <div className="section section-common">
            <div className="section-wrapper">
                <div className="regular-pages">
                    <h1 className="h1-regular-format">Disclaimer</h1>
                    <p className="p-regular-format">The website may contain inaccuracies and typographical and clerical errors. Skillablers and/or EDC Creative Technology Solutions Private Limited expressly disclaims any obligation(s) to update this website or any of the materials on this
                        website.
                    </p>
                    <p className="p-regular-format"> Skillablers and/or EDC Creative Technology Solutions Private Limited does not warrant the accuracy or completeness of the materials or the reliability of any advice, opinion, statement or other information displayed or distributed through
                        the Website.</p>
                    <p className="p-regular-format">You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk.
                    </p>
                    <p className="p-regular-format">Skillablers and/or EDC Creative Technology Solutions Private Limited reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the Website.
                    </p>
                    <p className="p-regular-format">Skillablers and/or EDC Creative Technology Solutions Private Limited may make any other changes to the Website, the materials and the products, programs, services or prices (if any) described in the Website at any time without notice.
                    </p>
                    <p className="p-regular-format">This Website is for informational purposes only and should not be construed as technical advice of any manner.</p>
    
    
                </div>
            </div>
        </div>
        )
    }
}
export default Disclaimer
 