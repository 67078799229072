import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Individual from '../../Assests/Images/individuals.png'
import Institution from '../../Assests/Images/institution.png'
import Industries from '../../Assests/Images/industry.png'
import Recruiter from '../../Assests/Images/recruiter.png'
import Individualactive from '../../Assests/Images/individuals-active.png'
import Institutionactive from '../../Assests/Images/institution-active.png'
import Industriesactive from '../../Assests/Images/industries-active.png'
import Mentor_Blue from '../../Assests/Images/Mentor_Blue.png'
import Mentor_White from '../../Assests/Images/Mentor_White.png'


import {IndividualUrllogin} from '../../Assests/js/config';
import {IndusURL,Instituteurl,Mentorurl} from '../../Assests/js/config';


const NOOP = () => { }

function HeaderModal({ isFrame, Url, close = NOOP, isIntern}) {
  

    const [OpenDelay, setOpenDelay] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setOpenDelay(true)
        }, 200);
       window. $('.modal-overlay').click(function(e) {
            if (!window.$(e.target).closest('.modal-box').length ||  !window.$(e.target).closest('.svg-play-icons').length){
                closPopup()
            }
        });
    },[])

    const closPopup = () => {
        setOpenDelay(false)
            setTimeout(() => {
            close(true)
        }, 200);
    }

    const closeAfterDelay=async()=>{
        setTimeout(() =>{closPopup()},5000)
    }

    
    return (
        <>
            <div className='modal-overlay'></div>
            <div className='row mx-0'>
            <div className={OpenDelay ?'modal-box signbox active col-11 col-lg-4 col-md-5 padZero-signin' :  'modal-box col-11 col-md-6 col-lg-4  padZero-signin'}>
                <div className='signinmodelclose' onClick={closPopup}><i class="fa fa-times colr-close" aria-hidden="true"></i></div>
                <div className='intern-modal padding-data-sig fsign'>Sign In</div>
                <div className='col-12 px-0 row mx-0'>
                    <div className='col-3 col-md-3 font-login-data'>
                        <a href= {IndividualUrllogin+'/indi-login'} target='_blank' onClick={closPopup}>
                        
                        <img src={Individual} className="login-image" 

                                            onMouseOver={e => e.currentTarget.src = Individualactive}

                                            onMouseOut={e => e.currentTarget.src = Individual}

                                            />
                        <span className='fnt-signinpoup'>Individuals</span>
                        </a>
                    </div>
                    <div className='col-3 col-md-3 font-login-data'>
                    <a href={Instituteurl} target='_blank' onClick={closPopup}>
                    {/* <img src={Institution} className="login-image" /> */}
                    <img src={Institution} className="login-image" 

onMouseOver={e => e.currentTarget.src = Institutionactive}

onMouseOut={e => e.currentTarget.src = Institution}

/>
                    <span className='fnt-signinpoup'>Institutions</span>
                    </a>
                    </div>
                    <div className='col-3 col-md-3 font-login-data'>
                        <a  href= {IndusURL+'/indu-login'} target='_blank' onClick={closPopup}>
                    
                    <img src={Industries} className="login-image" 

onMouseOver={e => e.currentTarget.src = Industriesactive}

onMouseOut={e => e.currentTarget.src = Industries}

/>
                        <span className='fnt-signinpoup'>Industries</span>
                        </a>
                    </div>

                    <div className='col-3 col-md-3 font-login-data'>
                        <a  href= {Mentorurl+'/mentor-login'} target='_blank' onClick={closPopup}>
                    
                    <img src={Mentor_White} className="login-image" 

onMouseOver={e => e.currentTarget.src = Mentor_Blue}

onMouseOut={e => e.currentTarget.src = Mentor_White}

/>
                        <span className='fnt-signinpoup'>Mentor</span>
                        </a>
                    </div>
                    

                    {/* <div className='col-3 col-md-3 font-login-data'>
                    <a href={Instituteurl} target='_blank' onClick={closPopup}>
                   
                    <img src={Institution} className="login-image" 

                onMouseOver={e => e.currentTarget.src = Institutionactive}

                onMouseOut={e => e.currentTarget.src = Institution}/>
                    <span className='fnt-signinpoup'>Recruiter</span>
                    </a>
                    </div> */}

                </div>

                 

                    </div>
                    </div>
                    
        </>
    )

}

export default React.memo(HeaderModal); 