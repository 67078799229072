import Enquiry from "../../component/Skl_aspire_component/Ins-engageheadform";

export var Routing = {
    Individual:'/individual',
    Industry : '/industry',
    Recruiter:'/recruiter',
    Institute:'/institute',
    sitemap:'/sitemap',
    privacypolicy : '/privacypolicy',
    confirmationofpayment : '/confirmation-of-payment',
    disclaimer : '/disclaimer',
    safe : '/safe',
    cookies : '/cookies',
    trademarkscopyright : '/trademarks_copyright',
    OurTeam : '/our-team',
    //ycc : '/YCC-2024',
    ycc:'https://ycc.skillablers.com/',
    Home : '/home',
    termsofuse :'/terms-of-use',
    rediness : '/industry-readiness',
    Job:'/job-search',
    threeIconnect : '/3i-connect',
    registration:"/YCC-registration",
    chaloexam : '/chaloexam',
    chaloexamhome:'/Chalo-Exam',
    mainhome:'/',
    indihomelanding:'/individuals',
    induhomelanding:'/industries',
    insthomelanding:'/institutes',
    // processflow:'/process-flow',
    aatce: '/aatce',
    processflow:'/process-flow',
    chaloexamhome2:'/chalo-exam-home',
    Inquizition:'/inquizition',
    CareerBlog:'/blog/career-gap-analysis',
    CareerCounsell:'/blog/career-counseling',
    sklaspire:'/skl-aspire',
    sklenquiry:'/skl-aspire-enquiry',

    Newsletterpage:'/newsletter'
  
  }