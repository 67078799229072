// var env = 'development';
// var env =  'testing'; 
var env =  'production'; 

var Config =
{
    development: {   
        // APIUrl: 'https://websiteapitest.skillablers.com',
       APIUrl: 'http://localhost:3461',
        EventId: 43,
        RoutingUrl:"http://localhost:3000",
        UploaderUrl: 'https://uploadertest.skillablers.com/Uploads/',
        sphereAPIUrl: 'https://spheretestapi.skillablers.com',
         IndividualUrl : 'https://indi.skillablers.com/indi-JobSearch',
         industryurl:'http://localhost:3000/indu-registration',
         industryloginurl:'https://indur.skillablers.com/indu-login',
         IndustryURL1:'http://localhost:3000',
         SphereUrl : 'https://spherer.skillablers.com',
         IndividualUrllogin:"https://indir.skillablers.com",
         Instituteurl:"http://localhost:3008/",
         viewalleventurl:"http://localhost:8081/",
         motsSkillablers: "https://mots.skillablers.com",
         edcurl:'https://www.edccts.com',
         exotyqUrl:' https://exotyq.com/',
         EventToconsider:43,
         Demoquizurl:"https://sklaspiredemo.skillablers.com",
         Mentorurl:"https://mentortest.skillablers.com"

       

    },
    testing: {
        APIUrl: 'https://websiteapitest.skillablers.com',
        EventId: 43,
        RoutingUrl:"https://home.skillablers.com/#/",
        UploaderUrl: 'https://uploadertest.skillablers.com/Uploads/',
        sphereAPIUrl: 'https://spheretestapi.skillablers.com',
        IndividualUrl : 'https://indir.skillablers.com/indi-JobSearch',
        industryurl:'https://indur.skillablers.com/indu-registration',
        industryloginurl:'https://indur.skillablers.com/indu-login',
        IndustryURL1:'https://indur.skillablers.com',
        SphereUrl : 'https://spherer.skillablers.com',
        IndividualUrllogin:"https://indir.skillablers.com",
        Instituteurl:"https://i3test.skillablers.com",
        viewalleventurl:"https://cckktest.skillablers.com/",
        motsSkillablers: "https://mots.skillablers.com",
        edcurl:'https://www.edccts.com',
        exotyqUrl:' https://exotyq.com/',
        EventToconsider:43,
        Demoquizurl:"https://sklaspiredemo.skillablers.com",
        Mentorurl:"https://mentortest.skillablers.com"
    },
    production: {
        APIUrl: 'https://websiteapi.skillablers.com',
        EventId: 19,
        RoutingUrl:"https://www.skillablers.com/#/",
        sphereAPIUrl: 'https://sphereapi.skillablers.com',
        UploaderUrl: 'https://uploader.skillablers.com/Uploads/',
        IndividualUrl : 'https://indi.skillablers.com/indi-JobSearch',
        industryurl:'https://indu.skillablers.com/indu-registration',
        industryloginurl:'https://indu.skillablers.com/indu-login',
        IndustryURL1:'https://indu.skillablers.com',
        SphereUrl : 'https://sphere.skillablers.com',
        IndividualUrllogin:"https://indi.skillablers.com",
        Instituteurl:"https://inst.skillablers.com",
        viewalleventurl:"https://events.skillablers.com/",
        motsSkillablers: "https://mots.skillablers.com",
        edcurl:'https://www.edccts.com',
        exotyqUrl:'https://exotyq.com/',
        EventToconsider:43,
        Demoquizurl:"https://sklaspiredemo.skillablers.com",
        Mentorurl:"https://mentor.skillablers.com"
        


    }
}

export var APIUrl = Config[env].APIUrl;
export var Event = Config[env].EventId;
export var RoutingUrl = Config[env].RoutingUrl;
export var UploaderUrl = Config[env].UploaderUrl;
export var sphereAPIUrl = Config[env].sphereAPIUrl;
export var IndividualUrl = Config[env].IndividualUrl;
export var IndustryURL = Config[env].industryurl;
export var IndusURL = Config[env].IndustryURL1;
export var industryloginurl = Config[env].industryloginurl;
export var SphereUrl = Config[env].SphereUrl;
export var IndividualUrllogin=Config[env].IndividualUrllogin;
export var Instituteurl=Config[env].Instituteurl
export var viewalleventurl=Config[env].viewalleventurl
export var motsSkillablers=Config[env].motsSkillablers
export var edcurl=Config[env].edcurl
export var exotyqUrl=Config[env].exotyqUrl
export var EventToconsider=Config[env].EventToconsider
export var Demoquizurl=Config[env].Demoquizurl
export var Mentorurl=Config[env].Mentorurl
