import React from "react";
import $ from 'jquery';

class SiteMap extends React.Component
{
    componentDidMount(){
        window.$('html,body').animate({
            scrollTop: 0
        }); 
    }
    render(){
        return(
            <div  className="content-sitmap">
            <div className="sitemap-head">Site map</div>
            <div className="sitemap-data-set">we value the opportunity to interact with you . please feel free to get in touch with us</div>
           <div className="row mx-0 col-12 px-0 sitemap-row">
            <div className="sitemap-data-secttions dis-inlne">
                <div className="site-map-footermenu">Main menu</div>
                <a href="./about.html" target="_blank"><div>Home</div></a>
              
                <a href="./individual.html" target="_blank"><div>Individual</div></a>
                <a href="./industry.html" target="_blank"><div>Industry</div></a>
                <a href="./Institute.html" target="_blank"><div>Institution</div></a>
                <a href="./Indiv-Jobs.html" target="_blank"><div>Jobs</div></a>
                <a href="https://mots.skillablers.com/#/" target="_blank"><div>Courses</div></a>
            </div>
            <div className="disp-in-2">
                <div className="site-map-footermenu">Footer menu</div>
                <a href="./Terms of use.html" target="_blank"><div>Terms of use</div></a>
                            <a href="./privacy_statement_18-9-2018.html" target="_blank"><div>Privacy statement</div></a>
                            <a href="./confirmation-of-payment.html" target="_blank"><div>Payment terms</div></a>
                            <a href="./disclaimer.html" target="_blank"><div>Disclaimer</div></a>
                            <a href="./safe.html" target="_blank"><div>Safe harbor provision</div></a>
                            <a href="./cookies.html" target="_blank"><div>Cookies policy</div></a>
                            <a href="./trademarks_copyright.html" target="_blank"><div className="dotshow">Trademarks & copyrights</div></a>
            </div>
            <div className="disp-in-2">
                <div className="site-map-footermenu">Group of companies</div>
                <a href="https://www.edccts.com/in/home" target="_blank" className="no-text-dec"> <div>EDC Creative Technology Solutions</div></a>
                <a href="https://home.skillablers.com" target="_blank" className="no-text-dec"><div>SKILLABLERS</div></a>
                <a href="https://exotyq.com/" target="_blank" className="no-text-dec"><div>EXOTYQ</div></a>
            </div>
        </div>
            <div>
                <div></div>
            </div>
        </div>
        )
    }
}
export default SiteMap