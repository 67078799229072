import React from "react";
import { Routing } from '../../Assests/js/routing';

import Individual_Road from '../../Assests/Images/Individual-Road.svg';
import locationicon_indiv from '../../Assests/Images/locationicon-indiv.svg';
import $ from 'jquery';
import { flatMap } from "lodash";

import ProcessFlow from "../Indivi_jobs_component/processflow";
import {IndividualUrllogin} from '../../Assests/js/config';
import indilociconclrnewActive from '../../Assests/Images/indilociconclrnew.svg';
import Individual_Road_mobile from '../../Assests/Images/Indiv-roadmaop-mobile.svg';
import visualprofile_light from '../../Assests/Images/visualprof1newpopup.svg';
import Profile360sSVG from '../../Assests/Images/Profile360sSVG.svg';
import c360light from '../../Assests/Images/connect360-light.png';
import history from '../../../src/histoty';








class IndiLanding extends React.Component
{
    constructor(props){
        super(props)
        this.state = {
            zoompoint:0,
            popupopen:false
        }
    }


    componentDidMount ()  { 
        
        setTimeout(() => {
            let resp = ~~parseInt(history.location.hash.substring(history.location.hash.length-1, history.location.hash.length));
        let con = parseInt(resp === 0? 0:resp);
        // if(con==0){
        //     window.$('html,body').animate({
        //         scrollTop: 0
        //     });
        // }
        // else{
        //     setTimeout(() => {
        //     window.$('html,body').animate({
        //         scrollTop: $("#Processflow").offset().top
        //     });
        // }, 500);
        // }
                }, 100);
        
       
        window.$('html,body').animate({
            scrollTop: 0
        });
}


getdesiredpostion=(id)=>
{
   
    this.setState(
        {
            zoompoint:id,
            popupopen:(window.matchMedia("(max-width: 992px)").matches)?true:false,
        }
    )
    this.autoscroll()
  

}

closPopup=()=>
{
    this.setState(
        {
            zoompoint:0,
            popupopen:false
        }
    )
  
  
}
closeSideslide=()=>
{
    this.setState(
        {
            zoompoint:0,
        }
    )
}

autoscroll=()=>
{
    let scrolling = $("#popupdetail").offset().top
    // console.log("scrolling",scrolling)
    $('html,body').animate({
        scrollTop: scrolling
    },
        'slow');
}


render(){
        return(
        <div>
            <div className="indi-conatiner bg-roadmap d-flex mb-3" >
            
            <div className="indihomeland-subwrap" id="popupdetail">
            {/* <div class="events-headsss mt-3 pb-0">INDIVIDUALS JOURNEY</div> */}
                    {/* <div className="pos-iconabslocation">
                    <div className="indirouteimgwrap">
                    {window.matchMedia("(min-width: 992px)").matches ?
                        <img className="roadmapImg" src={Individual_Road} />
                    :
                        <img src={Individual_Road_mobile } />
                    }

                    </div>
                        <div className="locateicon" onClick={()=>this.getdesiredpostion(1)}>
                                <div className="loc-icon-content">
                                    <div className="line-text-cicle rowreverloc-icon"><div className="brdlinewithloc-visualProf"></div><span className="list-ic"></span><div className="bulletpointtext-locatioRight">Visual Profiling</div></div>
                                    <div  onClick={()=>this.getdesiredpostion(1)} className={this.state.zoompoint===1 ? "zoominpoint":"zoomoutpoint"}><img src={this.state.zoompoint===1? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                                </div>
                            </div>
                            <div className= {this.state.zoompoint?"locateicon2": " locateicon2 locateicon2-aftr"} onClick={()=>this.getdesiredpostion(2)} >
                                <div className="loc-icon-content ">
                                    <div onClick={()=>this.getdesiredpostion(2)}  className={this.state.zoompoint===2 ? "zoominpoint d-flex justify-content-end":"zoomoutpoint d-flex justify-content-end"}><img src={this.state.zoompoint===2? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                                    <div className="line-text-cicle"><div className="brdlinewithloc"></div><span className="list-ic"></span><div className="bulletpointtext-locatio">Profile 360Score</div></div>
                                </div>
                            </div>
                            <div className="locateicon3" onClick={()=>this.getdesiredpostion(3)}>
                                <div className="loc-icon-content mob-flex-direction">
                                    <div className="line-text-cicle rowreverloc-icon"><div className="brdlinewithloc"></div><span className="list-ic"></span><div className="bulletpointtext-locatioRight">Upgrade Skill</div></div>
                                    <div  onClick={()=>this.getdesiredpostion(3)} className={this.state.zoompoint===3 ? "zoominpoint":"zoomoutpoint"}><img src={this.state.zoompoint===3? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                                </div>
                            </div>
                            <div className={this.state.zoompoint?"locateicon4": " locateicon4 locateicon4-aftr"} onClick={()=>this.getdesiredpostion(4)}>
                                <div className="loc-icon-content">
                                    <div onClick={()=>this.getdesiredpostion(4)}  className={this.state.zoompoint===4 ? "zoominpoint d-flex justify-content-end":"zoomoutpoint d-flex justify-content-end"}><img src={this.state.zoompoint===4? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                                    <div className="line-text-cicle"><div className="brdlinewithloc"></div><span className="list-ic"></span><div className="bulletpointtext-locatio">Career Connect</div></div>
                                </div>
                            </div>
                            <div className= {this.state.zoompoint?"locateicon5": " locateicon5 locateicon5-aftr"} onClick={()=>this.getdesiredpostion(5)}>
                                <div className="loc-icon-content ">
                                    <div className="line-text-cicle rowreverloc-icon"><div className="brdlinewithloc"></div><span className="list-ic"></span><div className="bulletpointtext-locatioRight">Find Your Perfect Career</div></div>
                                    <div  onClick={()=>this.getdesiredpostion(5)} className={this.state.zoompoint===5 ? "zoominpoint":"zoomoutpoint"}><img src={this.state.zoompoint===5? indilociconclrnewActive :locationicon_indiv} className="hvr-wobble-vertical"/></div>
                                </div>
                            </div>
                            
                    </div> */}
            </div>
            {/* <div id="zoompoints" className={this.state.zoompoint ?"side-map-details active":"notActive"}>
                {this.state.zoompoint===1&&
                <div className="px-3 pb-3">
                    <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >What is Visual Profiling ?</div>
                    <div className="pop-imagcent"><img src={visualprofile_light}></img></div>
                    <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                        <p className="mb-3">For one's resume to really shine, we need to think carefully about how it's designed as well as what's written. Visual Profiling tool helps you do it.</p>
                        <p className="mb-3">Visual Profiling is a revolutionary digital-n-dynamic technology tool for positioning, interpreting, profiling and managing Individual profiles across various professions, qualifications and industries.</p>
                        <p className="mb-3">With the best use of UX-n-UI, one can express oneself effectively-n-efficiently. This is the best way to get noticed and with least clutter reach out to the world with one's own credibility, capabilities, skills and aspirations.</p>
                        <p className="mb-3">The powerful interpretation of one's own profile, breaks the monotony of the written word and instead communicates visually to express better, clearly and with specificity with our evolving thoughts.</p>
                    </div>
                    <div className="pop-imagcent">
                        <button id="viewall-butn" className="meet-our-team">
                            <a className="no-text-dec" href={IndividualUrllogin+'/indi-login'}>Create Profile</a>
                        </button>
                    </div>
                </div>
                }
                {this.state.zoompoint===2&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >What is <span className="pfscoreclr">profile </span><span className="score360">360 </span><span className="pfscoreclr"><span className="pfscoreclr">SCORE (P<span className="score360">360</span>S</span>)</span></div>
                            <div className="pop-imagcent"><img src={Profile360sSVG}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                            <p className="mb-3"><span className="pfscoreclr">P<span className="score360">360</span>S</span> is a powerful rating mechanism that takes into account various attributes of your Skills, Experience and Accomplishments across various categories. On a scale of 360, your score is a weightage calculated based on your Academics, Skills, Experience and other Accomplishments.</p>
                            </div>
                            <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team">
                                <a className="no-text-dec" href={IndividualUrllogin+'/indi-login'} >Sign in</a>
                                </button></div>
                        </div>
                        }

                        {this.state.zoompoint===3&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >IMPORTANCE of skill upgarding</div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <ul className="ul-skillupgrading">
                                    <li>Increased job opportunities: In today's competitive job market, having the latest skills and knowledge is essential for getting a good job. By upgrading your skills, you can make yourself more marketable to employers and increase your chances of getting hired.</li>
                                    <li>Higher salaries: Employees with in-demand skills are often paid more than those with outdated skills. By upgrading your skills, you can increase your earning potential.</li>
                                    <li>More job satisfaction: When you have the skills and knowledge to do your job well, you're more likely to be satisfied with your work. This can lead to increased productivity and a better work-life balance.</li>
                                    <li>Improved career prospects: By upgrading your skills, you can open up new career opportunities for yourself. You may be able to move into a new role, start your own business, or take on more responsibility in your current role.</li> 
                                    <li>Personal development: Learning new skills can help you to grow as a person. You'll gain new knowledge, develop new skills, and meet new people. This can lead to a more fulfilling and rewarding life.</li>
                                </ul>
                            </div>
                            <div className="pop-imagcent">
                                <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href="https://mots.skillablers.com/#">View Course</a></button>
                                <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={IndividualUrllogin+'/indi-login'}>Upgrade</a></button>
                            </div>
                        </div>
                        }

                        {this.state.zoompoint===4&&
                        <div className="px-3 pb-3">
                          
                            <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' ><span className="pfscoreclr">connect </span><span className="score360">360</span> <span className="pfscoreclr">SCORE (<span className="pfscoreclr">C<span className="score360">360</span>S</span>)</span></div>
                            <div className="pop-imagcent"><img src={c360light}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <p className="mb-3">
                                 <span className="pfscoreclr">C<span className="score360">360</span>S</span> is a measure of your skills and abilities that is used to match you with jobs. It is calculated based on your education, work experience, and skills assessments. The higher your score, the more likely you are to be matched with a job that is a good fit for you.
                                </p>
                            </div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                            The benefits of having a high <span className="pfscoreclr">C<span className="score360">360</span>S</span> :
                            <ul className="ul-skillupgrading">
                                    <li>You are more likely to be matched with a job that is a good fit for your skills and interests.</li>
                                    <li>You are more likely to be considered for a job interview.</li>
                                    <li>You are more likely to be offered a job.</li>
                            </ul>
                            </div>
                            
                            <div className="pop-imagcent">
                                <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={'#/job-search'}>View Jobs</a></button>
                                <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={IndividualUrllogin+'/indi-login'}>Apply Job</a></button>
                            </div>
                            
                        </div>
                        }

                        {this.state.zoompoint===5&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >how to find perfect career</div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                Find a career that allows you to pursue your interests.
                            </div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                            <p className="mb-3">
                                Career Connect aims to align individuals' skills and interests with suitable career paths, enabling them to make informed decisions and achieve their professional goals. It recognizes the importance of continuous learning, adaptability, and staying current with industry trends to thrive in a rapidly changing job market. Ultimately, Career Connect seeks to empower individuals to build meaningful and fulfilling careers by providing them with the tools, knowledge, and connections necessary to navigate their professional journeys successfully
                            </p>
                            </div>
                            <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={'#/job-search'}>Search Job</a></button></div>
                        </div>
                        }
            </div> */}
            {/* //Detailed view popup  */}
            {/* {this.state.popupopen &&
            <div >
               
                <div className="detailsviewpopmainwrapper" >
                <div className='modal-overlay'></div> 
                <div class={this.state.zoompoint===5? "detailedview-popupRightMove " :"detailedview-popup"}>
                <div className='modal-close close-popdemo' onClick={this.closPopup} >
                        <i class="fa fa-times clr-close-rqst-demo" aria-hidden="true"></i></div>
                        
                        {this.state.zoompoint===1&&

                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >What is Visual Profiling ?</div>
                            <div className="pop-imagcent"><img src={visualprofile_light}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <p className="mb-3">For one's resume to really shine, we need to think carefully about how it's designed as well as what's written. Visual Profiling tool helps you do it.</p>
                                <p className="mb-3">Visual Profiling is a revolutionary digital-n-dynamic technology tool for positioning, interpreting, profiling and managing Individual profiles across various professions, qualifications and industries.</p>
                                <p className="mb-3">With the best use of UX-n-UI, one can express oneself effectively-n-efficiently. This is the best way to get noticed and with least clutter reach out to the world with one's own credibility, capabilities, skills and aspirations.</p>
                                <p className="mb-3">The powerful interpretation of one's own profile, breaks the monotony of the written word and instead communicates visually to express better, clearly and with specificity with our evolving thoughts.</p>
                            </div>
                            <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={IndividualUrllogin+'/indi-login'}>Create Profile</a></button></div>
                        </div>
                        }

                        {this.state.zoompoint===2&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >What is <span className="pfscoreclr">profile</span><span className="score360">360</span><span className="pfscoreclr"><span className="pfscoreclr">SCORE(P<span className="score360">360</span>S</span>)</span></div>
                            <div className="pop-imagcent"><img src={Profile360sSVG}></img></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext pt-2' >
                            <p className="mb-3"><span className="pfscoreclr">P<span className="score360">360</span>S</span> is a powerful rating mechanism that takes into account various attributes of your Skills, Experience and Accomplishments across various categories. On a scale of 360, your score is a weightage calculated based on your Academics, Skills, Experience and other Accomplishments.</p>
                            </div>
                            <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team">
                                <a className="no-text-dec" href={IndividualUrllogin+'/indi-login'} >Sign in</a>
                                </button></div>
                        </div>
                        }

                        {this.state.zoompoint===3&&
                        <div className="px-3 pb-3">
                            <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >IMPORTANCE of skill upgarding</div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <ul className="ul-skillupgrading">
                                    <li>Increased job opportunities: In today's competitive job market, having the latest skills and knowledge is essential for getting a good job. By upgrading your skills, you can make yourself more marketable to employers and increase your chances of getting hired.</li>
                                    <li>Higher salaries: Employees with in-demand skills are often paid more than those with outdated skills. By upgrading your skills, you can increase your earning potential.</li>
                                    <li>More job satisfaction: When you have the skills and knowledge to do your job well, you're more likely to be satisfied with your work. This can lead to increased productivity and a better work-life balance.</li>
                                    <li>Improved career prospects: By upgrading your skills, you can open up new career opportunities for yourself. You may be able to move into a new role, start your own business, or take on more responsibility in your current role.</li> 
                                    <li>Personal development: Learning new skills can help you to grow as a person. You'll gain new knowledge, develop new skills, and meet new people. This can lead to a more fulfilling and rewarding life.</li>
                                </ul>
                            </div>
                            <div className="pop-imagcent">
                                <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href="https://mots.skillablers.com/#">View Course</a></button>
                                <button id="viewall-butn" className="meet-our-team"><a className="no-text-dec" href={IndividualUrllogin+'/indi-login'}>Upgrade</a></button>
                            </div>
                        </div>
                        }

                        {this.state.zoompoint===4&&
                        <div className="px-3 pb-3">
                          
                            <div className='intern-modal rqst-heading text-trns-vs pt-3 pb-1 side-content-heading' ><span className="pfscoreclr">connect</span><span className="score360">360</span><span className="pfscoreclr">SCORE(<span className="pfscoreclr">C<span className="score360">360</span>S</span>)</span></div>
                            <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                                <p className="mb-3">
                                <span className="pfscoreclr">C<span className="score360">360</span>S</span> is a measure of your skills and abilities that is used to match you with jobs. It is calculated based on your education, work experience, and skills assessments. The higher your score, the more likely you are to be matched with a job that is a good fit for you.
                                </p>
                            </div>
                            <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team"><a className="no-text-dec">Sign in</a></button></div>
                        </div>
                        }

                        {this.state.zoompoint===5&&
                        <div className="px-3 pb-3">
                        <div className='intern-modal rqst-heading  text-trns-vs pt-3 pb-1 side-content-heading' >how to find perfect career</div>
                        <div className=' rqst-heading  text-trns-demo fntsizepopuptext' >
                            Find a career that allows you to pursue your interests.
                        </div>
                        <div className="pop-imagcent"><button id="viewall-butn" className="meet-our-team"><a className="no-text-dec">Search Job</a></button></div>
                        </div>
                        }
                
                        </div>
                </div>
            </div>} */}
            {/* //Detailed view popup  */}
            </div>
            <div className="mb-5">
                <ProcessFlow/>
            </div>
        </div>
         )
    }
}
export default IndiLanding