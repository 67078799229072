import React from "react";
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import { IndustryURL,IndusURL } from "../../Assests/js/config";
import {GetAllClientDetails} from "../Indivi_jobs_component/indivi_methods";
import DefaulCom from '../../Assests/Images/defaultProfil.jpg';
import { UploaderUrl } from '../../Assests/js/config';

 




//indu image
import JD2 from '../../Assests/Images/JD 2.png';
import Interviewsheduled2 from '../../Assests/Images/Interview-sheduled 2.png';
import ShorlistedCandidate2 from '../../Assests/Images/Shorlisted-Candidate 2.png';
import VisualProfileacreen2 from '../../Assests/Images/Visual-Profile_acreen 2.png';
import candidateprofiling from '../../Assests/Images/candidateprofiling.png';




//light
import publicjdlite from '../../Assests/Images/publicjd-lite.png';
import candidateprofilinglite from '../../Assests/Images/candidateprofiling-lite.png';
import Screeninglite from '../../Assests/Images/Screeninglite.png';
import shortlistlite from '../../Assests/Images/shortlist-lite.png';
import interviewprocesslite from '../../Assests/Images/interviewprocess-lite.png';
 

//Dark
import publicjddark from '../../Assests/Images/publicjd-dark.png';
import candidateprofilingdark from '../../Assests/Images/candidateprofiling-dark.png';
import Screeningdark from '../../Assests/Images/Screeningdark.png';
import shortlistdark from '../../Assests/Images/shortlist-dark.png';
import interviewprocessdark from '../../Assests/Images/interviewprocess-dark.png';

//corret and wrong 
 
import WrongSvg from '../../Assests/Images/wrong.svg';
import CorrectSvg from '../../Assests/Images/checkcrt.svg';
import selectedcheck from '../../Assests/Images/selectedcheck.svg';
import Slider from "react-slick";
// import TwitchPlayer from "react-player/twitch";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import RequestDemo from '../Industry_component/requestdemo-popup'
import { identity } from "lodash";
import PuffLoader  from "react-spinners/PuffLoader";
 
 

const fade = {
 infinite: true,
}
const comparison = {
  infinite: true,
}

 
 
 

class Industry extends React.Component
{
    constructor(props){
        super(props)
        this.state = {
            activeData:1,
            skills: [],
            jobs :[],
            clientdetail: [],
            categ : [],
            catcomplogo:[],
            // searchSkills : '',
            searchSkills : [],
            textactive:1,
            Tab:1,
            PFCfiltredselected:'',
            CategHalf :[],
            selectedpack:'',
            checkedtrue:false
        }
    }


    componentDidMount ()  { 
       
       
       

        window.$('html,body').animate({
            scrollTop: 0
        });
            // $('html,body').animate({
            //     scrollTop: 0
            // });
            // document.getElementsByClassName('skltext')[0].classList.add('active')
            // document.getElementsByClassName('row1')[0].classList.add('active')
            // document.getElementsByClassName('row2')[0].classList.add('active')
            // document.getElementsByClassName('row3')[0].classList.add('active')
            $('.individualBlockIdent').each(function () {
                if ($(this)[0].id === "visulprofile") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                        document.getElementById('visulprofile').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "perfectJobs") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                        document.getElementById('perfectJobs').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "videoesss") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 40 / 100) < $(window).scrollTop()) {
                        document.getElementById('videoesss').classList.add('active')
                    }
                }
                else if ($(this)[0].id === "Events") {
                    if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                        document.getElementById('Events').classList.add('active')
                    }
                }
            })

            $(window).on('scroll', e => {
                $('.individualBlockIdent').each(function () {
                    if ($(this)[0].id === "visulprofile") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 30 / 100) < $(window).scrollTop()) {
                            document.getElementById('visulprofile').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "perfectJobs") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 25 / 100) < $(window).scrollTop()) {
                            document.getElementById('perfectJobs').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "videoesss") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 40 / 100) < $(window).scrollTop()) {
                            document.getElementById('videoesss').classList.add('active')
                        }
                    }
                    else if ($(this)[0].id === "Events") {
                        if ($(this).offset().top - ((window.innerHeight) - (window.innerHeight) * 20 / 100) < $(window).scrollTop()) {
                            document.getElementById('Events').classList.add('active')
                        }
                    }
                })
            })


           
          GetAllClientDetails()
            .then(result => {
              
                let client=result.recordsets[0]
                
                this.setState(
                    {
                        clientdetail:client 
                    }
                )
            


            })
     


            
     

    }
    visuaprofiling=(id)=>
    {

        this.setState({
            activeData:id,
            textactive:0
        } ,()=> {
            setTimeout(()=>{
                this.setState(
                    {
                        textactive: id
                    }
                )
            },900)
            

        })
    }

    OPenIndustry = () =>{
        let Obj = { Package : this.state.selectedpack === 1 ? 2 :
            this.state.selectedpack === 2 ? 3 : 4
        }
        let URL = IndustryURL + '?' + btoa(JSON.stringify(Obj))
        let a = document.createElement('a');
        a.href = URL;
        a.target = "_blank"
        a.click();
    }
   
          
    YoutubeWindow= (id)=>{
       
       if(id === 1){
           window.open("https://www.youtube.com/watch?v=gDrFvRw5dSU&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw",'_blank')
       }
       else if(id === 2){
           window.open("https://www.youtube.com/watch?v=yrg085gq6rU&list=PLtpsAZJjrAoRGErHcZKH3YbL7qtHM4pQO&index=3",'_blank')
       }
       else if(id === 3){
           window.open("https://www.youtube.com/watch?v=DxM3qrH9JWs&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw&index=5",'_blank')
       }
       else if(id === 4){
           window.open("https://www.youtube.com/watch?v=aT7Cl5nhzJw",'_blank')
       }
       else if(id === 5){
           window.open("https://www.youtube.com/watch?v=yzvDn4gttKk",'_blank')
       }
       else if(id === 6){
           window.open("https://www.youtube.com/watch?v=uybwVo3sfyA&list=PLtpsAZJjrAoQPYWsg-mxJtuKzJ_hUncuw&index=13",'_blank')
       }
   }


 openIndiasight=()=>{
    window.open("https://events.skillablers.com/indiainsight",'_blank')
}
openYCC=()=>{
    window.open("https://events.skillablers.com/events/ycc2021",'_blank')
}
UltraTech=()=>{
    window.open("https://events.skillablers.com/ultratechstimulus",'_blank')
}
OpenBioQuiz=()=>{
    window.open('https://events.skillablers.com/nanotechquiz2022','_blank')
}
openRegisteration=()=>{
    window.open("https://inst.skillablers.com/ins-registration",'_blank')
}
OpenRegister=()=>{
    window.open("https://indu.skillablers.com/indu-registration",'_blank')
}

AutoScroll = ()=>{
    let smooth='smooth'
    let scrolling = $("#scrol-price").offset().top
    $('html,body').animate({
        scrollTop: scrolling,
     
    },
        'slow');
       
}
AutoScrollcompare = ()=>{
    let smooth='smooth' 
    let scrolling = $("#scrol-compare").offset().top
    $('html,body').animate({
        scrollTop: scrolling,
        
    },
        'slow');
       
}

changecssvari = (id) =>{

    this.setState(
        {
            selectedpack :id
        }
    )

    if(this.state.selectedpack===id)
    {
        this.setState(
            {
                selectedpack :''
            }
        )
        
        if(id === 1){
            document.body.style.setProperty('--border1' , '2px solid transparent')
            document.body.style.setProperty('--border2' , '2px solid transparent')
            document.body.style.setProperty('--border3' , '2px solid transparent')
            }
            if(id === 2){
                document.body.style.setProperty('--border1' , '2px solid transparent')
                document.body.style.setProperty('--border2' , '2px solid transparent')
                document.body.style.setProperty('--border3' , '2px solid transparent')
                }
                if(id === 3){
                    document.body.style.setProperty('--border1' , '2px solid transparent')
                    document.body.style.setProperty('--border2' , '2px solid transparent')
                    document.body.style.setProperty('--border3' , '2px solid #transparent')
                }
       
       
        
    }
    
    
    else
    {

    
    if(id === 1){
    document.body.style.setProperty('--border1' , '2px solid grey')
    document.body.style.setProperty('--border2' , '2px solid transparent')
    document.body.style.setProperty('--border3' , '2px solid transparent')
    }
    if(id === 2){
        document.body.style.setProperty('--border1' , '2px solid transparent')
        document.body.style.setProperty('--border2' , '2px solid gold')
        document.body.style.setProperty('--border3' , '2px solid transparent')
        }
        if(id === 3){
            document.body.style.setProperty('--border1' , '2px solid transparent')
            document.body.style.setProperty('--border2' , '2px solid transparent')
            document.body.style.setProperty('--border3' , '2px solid #024470')
            }
        }
}

changeselectpackwithoutbrd =async (id) =>{
 
    await this.setState(
        {
            selectedpack :id
        }
    )
    
   
}

// popup for Request for demo

OpenRequestDemo = () => {

    this.setState({

        isReqstdemo : true,

     

    })

}



 closeRequestDemo = () => {

   // setisIntern(false)

   this.setState({

    isReqstdemo : false,

    // pop : 0

})

}

 toastMessage=()=>{

    toast.success('Thank you for your request. Our team will contact you soon.', {    

        position: toast.POSITION.TOP_CENTER,
        // autoClose: 3000,
        autoClose: 5555000,
        theme: "colored"

    })

 }

 opencompany=()=>
 {
    window.open(IndusURL+'/indu-login','_blank')
 }

 handlecheck=(event)=>
 {
    if (event.target.checked) {
         this.setState(
            {
                checkedtrue:true
            }
         )

    }

    else
    {
        this.setState(
            {
                checkedtrue:false
            }
         )
    }
 }

 changeselection=(newchange)=>
 {
    if(this.state.selectedpack===newchange)
    {
        this.setState(
            {
                selectedpack:''
            }
         )
         if(newchange === 1){
            document.body.style.setProperty('--border1' , '2px solid transparent')
            document.body.style.setProperty('--border2' , '2px solid transparent')
            document.body.style.setProperty('--border3' , '2px solid transparent')
            }
            if(newchange === 2){
                document.body.style.setProperty('--border1' , '2px solid transparent')
                document.body.style.setProperty('--border2' , '2px solid transparent')
                document.body.style.setProperty('--border3' , '2px solid transparent')
                }
                if(newchange === 3){
                    document.body.style.setProperty('--border1' , '2px solid transparent')
                    document.body.style.setProperty('--border2' , '2px solid transparent')
                    document.body.style.setProperty('--border3' , '2px solid #transparent')
                }
    }
    else 
    {
        this.setState(
            {
                selectedpack: newchange
            }
         )
         if(newchange === 1){
            document.body.style.setProperty('--border1' , '2px solid grey')
            document.body.style.setProperty('--border2' , '2px solid transparent')
            document.body.style.setProperty('--border3' , '2px solid transparent')
            }
            if(newchange === 2){
                document.body.style.setProperty('--border1' , '2px solid transparent')
                document.body.style.setProperty('--border2' , '2px solid gold')
                document.body.style.setProperty('--border3' , '2px solid transparent')
                }
                if(newchange === 3){
                    document.body.style.setProperty('--border1' , '2px solid transparent')
                    document.body.style.setProperty('--border2' , '2px solid transparent')
                    document.body.style.setProperty('--border3' , '2px solid #024470')
                }
    }

   
    
   
    
   
 }












    render(){
        return(
            <div class="indi-conatiner">
                
                 <div className={this.state.Tab==1 ?'padding-top-20vhh bgcolor-indus' : this.state.Tab==2 ? 'padding-top-10vhh bg-colorprice-comp':'padding-top-10vhh bg-colorprice-comp' }   >
        {/* <div class="pain threeiConatinerr-mainss bgcolor-indus"  > */}
            
        {/* <div class="container  individualBlockIdent no-padding-top" id="contain"> */}
                {/* <div class="row " id="secflx1"> */}
                    {/* <div class="col-lg-6 col-xl-6 col-md-12 col-sm-6 col-12" id="seccol2"> */}
                        {/* <div class="skltext">ENABLING</div>
                        <div class="row1">INDUSTRIES</div> */}
                        {/* <div class="row2">
                        The SKILLABLERS Corporate Connect is a powerful eco-system that ENABLES companies 
                        to access and acquire the right talent as well as keep their skills updated through Industry Readiness Programs
                        </div>
                        <div class="row3 margin-bottom-0px">
                            <button id="btn"  onClick={this.OpenRegister}>Join Us</button>
                        </div> */}
                    {/* <div className="flex-industry-mob-text">
                    <div class="lap  ht-indust-video-container mob-indus">
                        
                        <video  autoPlay muted>
                    <source src={Industry1} type="video/mp4"></source>
                    </video>
                    </div>
                    <div class="lap-text individualBlockIdent pad-indusfind "  id="visulprofile">
                        <div className="animateindus fadeInDown twoindus">
                        <p class="create-text indus-clrfind ">FIND THE</p>
                        <p class="visual-text margin-bottom-2view-ht "> RIGHT TALENT</p></div>

                        <div className="animatevisual threevisual fadeInLeft">
                        <p class="visual-paraone indu-para-one indus-clr">Access to an INDUSTRY READY and PROFESSIONALLY  </p>
                        <p className="visual-paraone indu-para-two indus-clr">TRAINED talent pool</p>
                        <p class="visual-paraone indu-para-one indus-clr"> A COMPREHENSIVE VIDEO POWERED INDIVIDUAL PROFILE </p>
                        <p class="visual-paraone indu-para-two indus-clr"> helps shortlisting the right candidate for the role </p>
                        <p class="visual-paraone  indu-para-one indus-clr">An End-To-End Candidate interaction workflow to achieve</p>
                        <p class="visual-paraone indu-para-two indus-clr"> an efficient and scalable recruitment process  </p>
                        </div> 
                        <div class="industry-talent-right margin-top-2point5vh">
                            <div class="industry-talent-fst-head"> Profiling</div>
                            <div class="industry-sec-haed">of candidates</div>
                        </div>
                        <div class="industry-talent-right margin-top-1point5vh">
                            <div class="industry-talent-fst-head">Screening</div>
                            <div class="industry-sec-haed">& shortlisting</div>
                        </div>
                        <div class="industry-talent-right margin-top-1point5vh">
                            <div class="industry-talent-fst-head">Interview</div>
                            <div class="industry-sec-haed">process</div>
                        </div>
                        <div className="row  pa-indusprop-can ">
                            <div className="col-lg-4 col-md-4 col-12 fleindus-prof-cand  animateiconindus fadeInUp oneiconindus">
                            <div>   <img src={Profiling1} className="wid-iconindus" /></div> 
                            <div>
                            <div class="industry-talent-fst-head "> Profiling</div>
                            <div class="industry-sec-haed">of candidates</div></div>   
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 fleindus-prof-cand animateiconindus fadeInUp oneiconindus" >
                                <div> <img src={Screening1}  className="wid-iconindus"/></div>
                                <div>
                            <div class="industry-talent-fst-head"> Screening</div>
                            <div class="industry-sec-haed">& shortlisting</div>
                            </div>   
                            </div>
                       
                            <div className="col-lg-4 col-md-4 col-12 fleindus-prof-cand animateiconindus fadeInUp oneiconindus">
                            <div> <img src={Interview1} className="wid-iconindus" /></div>
                                <div>
                            <div class="industry-talent-fst-head"> Interview</div>
                            <div class="industry-sec-haed">process</div>
                            </div> 
                            </div>
                        </div>
                    </div>

                    </div> */}
                 
                         

                    {/* </div> */}
                    {/* <div class="col-lg-6 col-xl-6 col-md-12 col-sm-6 " id="scecol1">
                       
                    <video  autoPlay loop muted id="finalvideo1" >
                    <source src={final3} type="video/mp4"></source>
                    </video>
                    </div> */}
                {/* </div> */}
            {/* </div> */}
            <div>Recruiter</div>

            <div className="row tabbutton-alignmen-comp">
                <div className="col-lg-4 col-4">
                        <div  className=""
                          onClick={() => this.setState(
                            {
                                Tab: 1
                            }
                        )}
                        >
                          <button className={this.state.Tab===1? "createprofile     wid-smae-indus industry-active nowhitespace-recr":"createprofile  fntweight-indus  wid-smae-indus  nowhitespace-recr" }> Recruitment Process</button>
                        </div>
                </div> 
                <div className="col-lg-4 col-4">
                        <div  className=""
                          onClick={() => this.setState(
                            {
                                Tab: 2
                            }
                        )}
                        >
                          <button className={this.state.Tab===2? "createprofile    wid-smae-indus industry-active":"createprofile  fntweight-indus  wid-smae-indus"}> Our Clients</button>
                        </div>
                </div> 
                <div className="col-lg-4 col-4">
                        <div  className=""
                        onClick={() => this.setState(
                            {
                                Tab: 3
                            }
                        )}>
                          <button className={this.state.Tab===3? "createprofile    wid-smae-indus industry-active":"createprofile  fntweight-indus  wid-smae-indus"}>Pricing & Packages</button>
                        </div>
                </div> 
            </div> 
          
            
        <div className="indu-prod-main-cont individualBlockIdent" id="skl-prod-main-cont">
        

        {this.state.Tab==1 &&              
        <div className="mb-style-changewrap">
       <div className="col-lg-12 col-md-12 col-3 px-0 row mx-0 d-flex justify-content-center  directmbcloum ">
                                  <div className="  col-md-2 col-lg-2 px-0  pubjd">
                                      <a target='_blank'>
                                          <div className={this.state.activeData === 1 ? "skl-productss active  Brradius-indu" : "skl-productss   Brradius-indu"}
                                              onClick={()=>this.visuaprofiling(1)}
                                             
                                              >
                                              <div className="indu-image-blocks">
                                                  <img src= {this.state.activeData === 1 ?publicjddark:publicjdlite} className="Brradius-indu"
                                                   
                                                  />
                                              </div>
                                              <div>
                                                  <div className="skl-prod-name bold-efeect">PUBLISH JD</div>
      
                                              </div>
                                          </div>
                                      </a>
                                  </div>
                                  <div className="   col-md-2 col-lg-2   px-0">
                                      <a target='_blank'>
                                          <div className={this.state.activeData === 2 ? "skl-productss active  Brradius-indu" : "skl-productss Brradius-indu"}
                                               onClick={()=>this.visuaprofiling(2)} >
                                              <div className="indu-image-blocks"
                                              >
                                                  <img src= {this.state.activeData === 2 ? candidateprofilingdark:candidateprofilinglite} className="Brradius-indu"
                                                  // onMouseOver={e => e.currentTarget.src = P360_1}
                                                  // onMouseOut={e => e.currentTarget.src = P360}
                                                  />
                                              </div>
                                              <div>
                                                  <div className="skl-prod-name bold-efeect">CANDIDATE PROFILING</div>
      
                                              </div>
                                          </div>
                                      </a>
                                  </div>
                                  <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
      
                                      <div className={this.state.activeData === 3 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                          onClick={()=>this.visuaprofiling(3)}>
                                          <div className="indu-image-blocks">
                                              <img src={this.state.activeData === 3 ? Screeningdark:Screeninglite} className="Brradius-indu"
                                              // onMouseOver={e => e.currentTarget.src = job1}
                                              // onMouseOut={e => e.currentTarget.src = job}
                                              />
                                          </div>
                                          <div>
                                              <div className="skl-prod-name bold-efeect">SCREENING</div>
      
                                          </div>
                                      </div>
                                  </div>
                                  <div className="   col-md-2 col-lg-2 col-sm-2  px-0">
                                      <a target='_blank'>
                                          <div className={this.state.activeData === 4 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                               onClick={()=>this.visuaprofiling(4)}>
                                              <div className="indu-image-blocks">
                                                  <img src={this.state.activeData === 4 ? shortlistdark:shortlistlite} className="Brradius-indu"
                                                  // onMouseOver={e => e.currentTarget.src = online1}
                                                  // onMouseOut={e => e.currentTarget.src = online}
                                                  />
                                              </div>
                                              <div>
                                                  <div className="skl-prod-name bold-efeect">SHORTLIST CANDIDATES</div>
      
                                              </div>
                                          </div>
                                      </a></div>

                                      <div className="   col-md-2 col-sm-2 col-lg-2 px-0">
                                      <a target='_blank'>
                                          <div className={this.state.activeData === 5 ? "skl-productss active Brradius-indu" : "skl-productss Brradius-indu"}
                                               onClick={()=>this.visuaprofiling(5)}>
                                              <div className="indu-image-blocks">
                                                  <img src={this.state.activeData === 5 ? interviewprocessdark:interviewprocesslite} className="Brradius-indu"
                                                  // onMouseOver={e => e.currentTarget.src = online1}
                                                  // onMouseOut={e => e.currentTarget.src = online}
                                                  />
                                              </div>
                                              <div>
                                                  <div className="skl-prod-name bold-efeect">INTERVIEW PROCESS</div>
      
                                              </div>
                                          </div>
                                      </a></div>
                                 
                              </div>

                              <div className={this.state.activeData === 1 ? "col-sm-12 col-9 px-0 indu-data-containerss active fst-border" :
                                  this.state.activeData === 5 ? "col-sm-12 col-9  px-0 indu-data-containerss active lst-border" : "col-sm-12 col-9  px-0 indu-data-containerss active all-border "}>
                                  <div className="col-12 px-0 row mx-0   tabsec-center  mi-heigh-overall">
                                      <div className={ this.state.textactive==0?"col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0  align-paddd-induss ":"col-sm-6 col-12 d-flex-full-cent-datata padding-zer px-0 align-paddd-induss   " }   >
                                          <div className="visul-porfile-head  flow-align">
                                              {this.state.activeData === 1 ? 
                                              <div className="">
                                                  {/* <div className="visual-profile-head1 ">PUBLISH</div>
                                                  <div className="visual-haed-two ">JOB DESCRIPTION</div> */}
                                                  <div className="visual-profile-head1">PUBLISH</div>
                                                    <div className="visual-haed-two ">JOB DESCRIPTION</div>
                                              </div> :
                                                  this.state.activeData === 2 ?
                                                      <div className="">
                                                          <div className="visual-profile-head1">CANDIDATE</div>
                                                          <div className="visual-haed-two ">PROFILING</div>
                                                      </div>
                                                      : this.state.activeData === 3 ?
                                                          <div className="">
                                                              <div className="visual-profile-head1 ">CANDIDATE</div>
                                                              <div className="visual-haed-two  ">SCREENING</div>
                                                          </div>
                                                          :
                                                          this.state.activeData === 4 ?
                                                              <>
                                                                  <div className="visual-profile-head1 animat ">CANDIDATE</div>
                                                                  <div className="visual-haed-two  ">SHORTLISTING</div>
                                                              </> 
                                                            //   :<></>
                                                             :
                                                            this.state.activeData === 5 ?
                                                                  <>
                                                                      <div className="visual-profile-head1">CANDIDATE</div>
                                                                      <div className="visual-haed-two">INTERVIEW PROCESS</div>
                                                                  </>
                                                            
                                                                :  <>
                                                                      {/* <div className="visual-profile-head1">upgrade your
                                                                      </div>
                                                                      <div className="visual-haed-two">skills</div> */}
                                                                      {/* <div className="visual-profile-head1">Get your</div>
                                                                     <div className="visual-haed-two">Career counselling</div> */}
                                                                  </>
                                                                
                                                                // <></>

                                              }
                                          </div>
                                          <div className="visual-data-contentss ">
                                              {this.state.activeData === 1 ?
                                                  <div className="">
                                                     Setting the foundation for strong talent acquisition begins with a well written and comprehensive Job Description. 
                                                     It is a key to answer the important question - 
                                                     “What does the person in this role actually do”? 
                                                  </div>
                                                  :
                                                  this.state.activeData === 2 ?
                                                      <div className="">
                                                        Shortlist the ideal candidates with our Profile 360 and Connect 360 scores
                                                         that showcases the candidate skills as well 
                                                         as how it matches to the Job Description. </div>
                                                      :
                                                      this.state.activeData === 3 ?
                                                          <div className=" ">
                                                              Assess your shortlisted candidates through our screening platform, 
                                                              which includes multi-mode evaluation processes 
                                                              and virtual candidate interviews.
                                                          </div> :
                                                          this.state.activeData === 4 ?
                                                              <div className=" ">
                                                                  Narrow down the list of candidates who meet the JD requirements 
                                                                  and have cleared initial assessments 
                                                                  for final selection and approval process
                                                              </div> :
                                                              this.state.activeData === 5 ?
                                                                  <div className="">
                                                                      Every successful journey stands on the basis of sound advice. 
                                                                      Our expert counsellors provide one on one personalised sessions 
                                                                      and can advise on college admissions, the right courses 
                                                                      that matches your aptitude and aspirations, 
                                                                      all leveraging a powerful psychometric evaluation platform.</div>
                                                                  :
                                                                  <div className="">
                                                                      Connect with your shortlisted candidates virtually 
                                                                      and capture real time feedback. 
                                                                      You can choose to connect 
                                                                      with candidate 1 on 1 or as a panel</div>
      
                                              }
                                          </div>
                                          {this.state.activeData === 3 &&
                                           <div  className="visual-view-more-btn-btnnns">
                                          
                                            <button className="createprofile meet-our-team  fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}}  >Login</button>
                                            
                                            </div>
                                          }
                                          {this.state.activeData === 5 &&
                                           <div  className="visual-view-more-btn-btnnns ">
                                           <button className="createprofile meet-our-team fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}} >Login</button>
                                            </div>
                                          }
                                           {this.state.activeData === 4 &&
                                           <div  className="visual-view-more-btn-btnnns ">
                                           <button className="createprofile meet-our-team fntweight-indus texnonetransfor" onClick={()=>{this.opencompany()}} >Login</button>
                                            </div>
                                          }
                                          {(this.state.activeData !== 4 && this.state.activeData !== 3  && this.state.activeData !== 5) &&
                                              <div className="visual-view-more-btn-btnnns">
                                               
                                                  <button className="createprofile meet-our-team fntweight-indus texnonetransfor"
                                                  onClick={()=>{this.state.activeData === 5 && this.opencompany()}}>
                                                      <a className="create-job-clr clr-dec" href={this.state.activeData === 1 ? IndusURL+'/indu-login' :
                                                          this.state.activeData === 2 ? IndusURL+'/indu-login' :
                                                              this.state.activeData === 6 ? "https://mots.skillablers.com/#/" : ""} target="_blank">
                                                          {this.state.activeData === 1 ? "Get Started" : this.state.activeData === 2 ? "Login " :
                                                              this.state.activeData === 3 ? "Know More" : this.state.activeData === 5 ? "Know More" :
                                                                  "View Courses"}</a></button>
                                                   
                                              </div>
                                          }
                                          
                                      </div>
                                      <div className={this.state.textactive==0?"col-sm-6 col-12 dflex-cent padding-zer flowimgcewnter    ":"col-sm-6 col-12 dflex-cent padding-zer  flowimgcewnter"}>
                                          <img src={this.state.activeData === 1 ? JD2 : this.state.activeData === 2 ?
                                              candidateprofiling : this.state.activeData === 3 ? VisualProfileacreen2 :
                                                  this.state.activeData === 4 ? ShorlistedCandidate2 :
                                                      this.state.activeData === 5 ? Interviewsheduled2 : ''} className="width-100per-image" /> 
                                                   
                                      </div>
                                  </div>
                              </div>

                              </div>
                              }
                              
                              
              </div>
              </div> 






        
        {this.state.Tab==2 &&  
        <>

         
        
          <div class="trend-comp companesBlockIdent   ">
                                                            <div class="thirdblock  indu-clientscroll" id="">
                                                            {this.state.clientdetail.length > 0 ?
                                                                
                                                                <div className="row cntcompany">
                                                                    {this.state.clientdetail.map((x, i) => 
                                                                    
                                                                    <div className="col-lg-2 col-md-3 col-sm-12 col-12 com-apple  companieslogo  indu-clientlogo mx-2 mb-3 logo-margin">
                                                                       
                                                                          
                                                                                <div className="ht-companyimg clint-indu-subimg">

                                                                                    <img className="company-img client-mbimgpad" src={  x.ClientLogo === "" ? DefaulCom : UploaderUrl + x.ClientLogo} alt="apple" />
                                                                                   
                                                                                   
                                                                                </div>
                                                                                <p class="cpmapny-conatinersections comp-name name-client">{x.ClientName}</p>

                                                                          
                                                                         </div>
                                                                         )} 
                                                                        </div>

                                                                        : <div className="load-alignment">
                                                                        <PuffLoader  color="#0572aa" />                                                    
                                                                        </div>}
                                                                    
                                                                        
                                                            </div>

                                                        </div>
        </>
        }



       
         {/* pricing comparison tabel  */}
        {this.state.Tab==3 &&  
        <>
     
     <div className="bgclrindu-main" id="scrol-price" >
        <div className="pad-bottom-main">
            <div class="events-headsss individualBlockIdent active pb-0 pad-pricingtabel" id="eventhead"> PACKAGES </div>
            <div className="clickhertxrt-indu " onClick={this.AutoScrollcompare}>CLICK HERE TO SEE THE PRICE COMPARISON</div>
           
        </div>

    <div className="des-view">
        <div className="wid-tabelpricing">
            <div className="row  border-parent">
          
                 <div className="col-lg-3 brdr-main   ">
                   
                 </div>
                 <div className="col-lg-3  brdr-main main-heads" onClick={()=>this.changecssvari(1)} >
                    <div className="plan-textclr bacground-plan-silver border-bot-1px">SILVER</div>
                    {/* <hr className="hghtline"/> */}
                </div>
                 <div className="col-lg-3  brdr-main  main-heads"  onClick={()=>this.changecssvari(2)} >
                    <div className="plan-textclr bacground-plan-Gold border-bot-1px">GOLD</div>
                    {/* <hr className="hghtline"/>  */}
                 </div>
                 <div className="col-lg-3  brdr-main  pso-rel  main-heads"  onClick={()=>this.changecssvari(3)} >
                    <div className="plan-textclr bacground-plan-Platinum border-bot-1px">PLATINUM</div> 
                    {/* <hr className="hghtline"/> */}
                    <div className="recommd-industry">Recommended</div>
                 </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                <div className="plan-daysclr bacgroundplancompare-signup signfee-pr-compare"><span id="sigfont">SIGNUP FEE</span></div>
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="plan-daysclr bacground-plan-silver"><span id="bold-textindu">Free</span><span id="spfontsize">/365 days</span></div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="plan-daysclr bacground-plan-Gold"><span id="spfontsize">INR </span> <span id="bold-textindu">1,00,000*</span> <span id="spfontsize">/365 days</span></div>
                    {/* <hr /> */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="plan-daysclr bacground-plan-Platinum"><span id="spfontsize">INR </span> <span id="bold-textindu"> 2,00,000*</span> <span id="spfontsize">/365 days</span></div>
                    {/* <hr /> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">Job Descriptions</div>
                    {/* <hr /> */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"> 5 JD’s </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"> 15 JD’s + 3 free^</div>
                    {/* <hr />    */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"> 25 JD’s + 3 free^</div>
                    {/* <hr />   */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">JD Validity – 90 Days (from Date of Posting) </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">Success Fee for all confirmed / selected Candidates </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"> + 8.33% of Annual CTC</div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"> + 4.2% of Annual CTC</div>
                    {/* <hr /> /      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"> + 4.2% of Annual CTC</div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">JD Promoted across social media platforms </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

           

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">Dedicated Account Manager </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>
            <div className="hisdingrel"></div>

            <div className="row marspace-platform ">
                <div className="col-lg-12  ">
                    <div className="industext-pricingtable bgplat bordernone-indus">PLATFORM FEATURES </div>
                   
                </div>
                
            </div>
            <div className="row   ">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">Access to Visual Profiles </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">JD-Specific Questions for Video Responses </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    {/* <div className="industext-pricingtable ">
                        Short-listing of ALL Candidates – based on Profile360Score (P360S) & Connect360Score (C360S) 
                        </div> */}
                        <div className='industext-pricingtable displblk'>Short-listing of ALL Candidates - based 
                        on<span className='pricing-fontstylr ali-scoreleft'>Profile<span className='pricing-360'>360</span>
                        Score (P<span className='pricing-360'>360</span>S) & Connect<span className='pricing-360'>360</span>
                        Score (C<span className='pricing-360'>360</span>S)</span></div>
                 
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing pa-bigtext-price"><img src={CorrectSvg} /></div>
                    
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing pa-bigtext-price"><img src={CorrectSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing pa-bigtext-price recombgclr" ><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">Technical Assessment (MCQs Based)</div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">Aptitude Assessment (MCQs Based)</div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={CorrectSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">Behavioural Competency Profiling (ATMA)</div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable">Hackathon Based Technical Evaluation (Online)</div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing"><img src={WrongSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing recombgclr"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 brdr-main ">
                    <div className="industext-pricingtable  border-bend-indu">Candidates Access to Pre-Placement Preparation Session (Online)</div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing pa-bigtext-price border-bend-indu"><img src={WrongSvg} /></div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing pa-bigtext-price border-bend-indu"><img src={WrongSvg} /></div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 brdr-main ">
                    <div className="content-text-pricing pa-bigtext-price recombgclr border-bend-indu"><img src={CorrectSvg} /></div>
                    {/* <hr/> */}
                </div>
            </div>
            
            <div className="hisdingrel"></div>

            <div className="row pd-down">
                <div className="col-lg-3   ">
                      
                </div>
                <div className="col-lg-3 hovertranssbutton ">
                    <div className="content-text-pricing silverconfirm" onClick={()=>this.changecssvari(1)}>
                        SILVER 
                      
                        <input type="checkbox"  className="checkbox-style-indu"
                    //    onChange={()=>this.changeselection(1)}
                        checked={this.state.selectedpack==1? true : false}
                        ></input>
                

                        </div>
                      
                </div>
                <div className="col-lg-3  hovertranssbutton  ">
                    <div className="content-text-pricing goldconfirm" onClick={()=>this.changecssvari(2)}>
                        GOLD 
                       
                        <input type="checkbox"  className="checkbox-style-indu"
                        //  onChange={()=>this.changeselection(2)}
                       checked={this.state.selectedpack===2  ? true: false}
                       ></input>
                        
                        </div>
                         
                </div>
                <div className="col-lg-3  hovertranssbutton ">
                    <div className="content-text-pricing platinumconfirm"  onClick={()=>this.changecssvari(3)}>
                        PLATINUM
                        
                        <input type="checkbox"  className="checkbox-style-indu"
                        //  onChange={()=>this.changeselection(3)}
                       checked={this.state.selectedpack===3  ? true: false}
                       ></input>
                        </div>
                 
                </div>
            </div>

            <div className="row sig-mar">
                <div className="col-lg-3  ">
                     
                </div>
                <div className="col-lg-9   ">
                <button className= 
                {this.state.selectedpack==='' ?"createprofile meet-our-team  wid-signup dsektopcontinuewidth notselopac":"createprofile meet-our-team  wid-signup dsektopcontinuewidth"}
                onClick={this.OPenIndustry}  disabled={this.state.selectedpack!== '' ? false : true}>
                 Continue with  {this.state.selectedpack==1? 'Silver' : this.state.selectedpack==2? 'Gold' : this.state.selectedpack== 3?'Platinum':''}
              
                    </button>  
                </div>
               
            </div>
 
            <div className="row">
            <div className="col-3">

            </div>
            <div className="col-9   fontsItext ">
            *   + GST
            </div>
           
        </div>
        <div className="row">
            
        <div className="col-3">

        </div>
            <div className="col-9  fontsItext padbelow-pricing ">
            **  Success Fee of 4.20% can be availed ONLY for a paid GOLD or PLATINUM Subscription. If neither GOLD nor PLATINUM is subscribed for, then the standard 8.33% will be applicable for all successful Hires.
            </div>
           
        </div>

        <div className="row">
            
        <div className="col-3">

        </div>
            <div className="col-9  fontsItext padbelow-pricing ">
            ^  Introductory offer 3 JD’s free. Valid for 90 day’s from the date of registration.
            </div>
           
        </div>

       

           
        </div>


    </div>

    <div className="mobile-view-price">
        <div className="prnextbut">
            <Slider {...fade} >
           
            <div>
                  <div className="row">
                    <div className="col-6">
                     
                    </div>
                    <div className="col-6    main-heads" onClick={()=>this.changeselectpackwithoutbrd(1)} >
                    <div className="plan-textclr bacground-plan-silver border-bot-1px">SILVER</div>
                    </div>
                    </div>

                  <div className="row">
                    <div className="col-6">
                    <div className="plan-daysclr bacgroundplancompare-signup signfee-pr-compare"><span id="sigfont">SIGNUP FEE</span></div>
                    </div>
                    <div className="col-6  ">
                    <div className="plan-daysclr bacground-plan-silver"><span id="bold-textindu">Free</span><span id="spfontsize">/365 days</span>
                    </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Job Descriptions</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing"> 5 JD’s </div>
                    </div>
                   </div>
                  
                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD Validity – 90 Days (from Date of Posting) </div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Success Fee for all confirmed / selected Candidates </div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> + 8.33% of Annual CTC**  </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD Promoted across social media platforms</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg} /> </div>
                    </div>
                   </div>

                   
                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Dedicated Account Manager</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg} /> </div>
                    </div>
                   </div>

                    <div className="hisdingrel"></div>

                    <div className="row marspace-platform ">
                    <div className="col-lg-12  ">
                        <div className="industext-pricingtable bgplat bordernone-indus">PLATFORM FEATURES </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Access to Visual Profiles</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD-Specific Questions for Video Responses</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        {/* <div className="industext-pricingtable">
                            Short-listing of ALL Candidates – based on Profile360Score (P360S) & Connect360Score (C360S)
                            </div> */}
                            <div className='industext-pricingtable displblk'>Short-listing of ALL Candidates – based on  
                          <span className='pricing-fontstylr ali-scoreleft'>Profile<span className='pricing-360'>360</span>
                        Score (P<span className='pricing-360'>360</span>S) & Connect<span className='pricing-360'>360</span>
                        Score (C<span className='pricing-360'>360</span>S)</span></div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Technical Assessment (MCQs Based)</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg}  /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Aptitude Assessment (MCQs Based)</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Behavioural Competency Profiling (ATMA)</div>
                    </div>
                    <div className="col-6 ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Hackathon Based Technical Evaluation (Online)</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg}  /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable border-bend-indu">Candidates Access to Pre-Placement Preparation Session (Online)</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob border-bend-indu"> <img src={WrongSvg}  /> </div>
                    </div>
                   </div>

                   <div className="hisdingrel"></div>

                    <div className="row pd-down">
                    <div className="col-6  ">
                        
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing silverconfirm posrelmbview brdr-radimbview" onClick={()=>this.changeselectpackwithoutbrd(1)}>
                        SILVER 
                         
                        <input type="checkbox"  className="checkbox-style-indu posr-highlight"
                        onChange={()=>this.changeselection(1)}
                       checked={this.state.selectedpack===1  ? true: false}
                       ></input>
                        
                    </div>
                   </div>
                   </div>

                

                   

            </div>
            
            <div>
                  <div className="row">
                    <div className="col-6">
                     
                    </div>
                    <div className="col-6    main-heads" onClick={()=>this.changecssvari(2)} >
                    <div className="plan-textclr bacground-plan-Gold border-bot-1px">GOLD</div>
                    </div>
                    </div>

                  <div className="row">
                    <div className="col-6">
                    <div className="plan-daysclr bacgroundplancompare-signup signfee-pr-compare"><span id="sigfont">SIGNUP FEE</span></div>
                    </div>
                    <div className="col-6   ">
                    <div className="plan-daysclr bacground-plan-Gold"><span id="spfontsize">INR </span> <span id="bold-textindu">1,00,000*</span> <span id="spfontsize">/365 days</span>
                    </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Job Descriptions</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing"> 15 JD’s + 3 free^ </div>
                    </div>
                   </div>
                  
                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD Validity - 90 Days (from Date of Posting) </div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Success Fee for all confirmed / selected Candidates </div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> + 4.2% of Annual CTC** </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD Promoted across social media platforms</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   
                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Dedicated Account Manager</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg} /> </div>
                    </div>
                   </div>

                    <div className="hisdingrel"></div>

                    <div className="row marspace-platform ">
                    <div className="col-lg-12  ">
                        <div className="industext-pricingtable bgplat bordernone-indus">PLATFORM FEATURES </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Access to Visual Profiles</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD-Specific Questions for Video Responses</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        {/* <div className="industext-pricingtable">
                            Short-listing of ALL Candidates – based on Profile360Score (P360S) & Connect360Score (C360S)
                        </div> */}
                        <div className='industext-pricingtable displblk'>Short-listing of ALL Candidates – based on 
                        <span className='pricing-fontstylr ali-scoreleft'>Profile<span className='pricing-360'>360</span>
                        Score (P<span className='pricing-360'>360</span>S) & Connect<span className='pricing-360'>360</span>
                        Score (C<span className='pricing-360'>360</span>S)</span></div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Technical Assessment (MCQs Based)</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}  /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Aptitude Assessment (MCQs Based)</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Behavioural Competency Profiling (ATMA)</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Hackathon Based Technical Evaluation (Online)</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={WrongSvg}  /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable border-bend-indu">Candidates Access to Pre-Placement Preparation Session (Online)</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob border-bend-indu"> <img src={WrongSvg}  /> </div>
                    </div>
                   </div>

                   <div className="hisdingrel"></div>

                    <div className="row pd-down">
                    <div className="col-6  ">
                        
                    </div>
                    <div className="col-6   ">
                    <div className="content-text-pricing goldconfirm posrelmbview brdr-radimbview" onClick={()=>this.changeselectpackwithoutbrd(2)}>
                        GOLD 
                       {/* {this.state.selectedpack==2&&
                        <img src={selectedcheck}  id='selectpacktick'/>}  */}
                        <input type="checkbox"  className="checkbox-style-indu posr-highlight"
                        onChange={()=>this.changeselection(2)}
                       checked={this.state.selectedpack===2  ? true: false}
                       ></input>
                        
                        </div>
                         
                    </div>
                   </div>

                   

            </div>
            
            <div>
                  <div className="row">
                    <div className="col-6">
                     
                    </div>
                    <div className="col-6    main-heads  pso-rel " onClick={()=>this.changeselectpackwithoutbrd(3)} >
                    <div className="plan-textclr bacground-plan-Platinum border-bot-1px">PLATINUM</div> 
                    <div className="recommd-industry">Recommended</div>
                    </div>
                    </div>

                  <div className="row">
                    <div className="col-6">
                    <div className="plan-daysclr bacgroundplancompare-signup signfee-pr-compare"><span id="sigfont">SIGNUP FEE</span></div>
                    </div>
                    <div className="col-6   ">
                    <div className="plan-daysclr bacground-plan-Platinum"><span id="spfontsize">INR </span> <span id="bold-textindu"> 2,00,000*</span> <span id="spfontsize">/365 days</span>
                    </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Job Descriptions</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing"> 25 JD’s + 3 free^ </div>
                    </div>
                   </div>
                  
                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD Validity – 90 Days (from Date of Posting) </div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Success Fee for all confirmed / selected Candidates </div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> + 4.2% of Annual CTC**  </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD Promoted across social media platforms</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   
                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Dedicated Account Manager</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                    <div className="hisdingrel"></div>

                    <div className="row marspace-platform ">
                    <div className="col-lg-12  ">
                        <div className="industext-pricingtable bgplat bordernone-indus">PLATFORM FEATURES </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Access to Visual Profiles</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">JD-Specific Questions for Video Responses</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg} /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        {/* <div className="industext-pricingtable">
                            Short-listing of ALL Candidates – based on Profile360Score (P360S) & Connect360Score (C360S)
                            </div> */}
                            <div className='industext-pricingtable displblk'>Short-listing of ALL Candidates – based on 
                        <span className='pricing-fontstylr ali-scoreleft'>Profile<span className='pricing-360'>360</span>
                        Score (P<span className='pricing-360'>360</span>S) & Connect<span className='pricing-360'>360</span>
                        Score (C<span className='pricing-360'>360</span>S)</span></div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Technical Assessment (MCQs Based)</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}  /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Aptitude Assessment (MCQs Based)</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Behavioural Competency Profiling (ATMA)</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}   /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable">Hackathon Based Technical Evaluation (Online)</div>
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing justcentsvgmob"> <img src={CorrectSvg}  /> </div>
                    </div>
                   </div>

                   <div className="row">
                    <div className="col-6">
                        <div className="industext-pricingtable border-bend-indu">Candidates Access to Pre-Placement Preparation Session (Online)</div>
                    </div>
                    <div className="col-6  ">
                        <div className="content-text-pricing justcentsvgmob border-bend-indu"> <img src={CorrectSvg}  /> </div>
                    </div>
                   </div>

                   <div className="hisdingrel"></div>

                    <div className="row pd-down">
                    <div className="col-6  ">
                        
                    </div>
                    <div className="col-6  ">
                    <div className="content-text-pricing platinumconfirm  posrelmbview brdr-radimbview" onClick={()=>this.changeselectpackwithoutbrd(3)}>
                        Platinum
                       {/* {this.state.selectedpack==3 &&
                        <img src={selectedcheck} id='selectpacktick' />}  */}
                        <input type="checkbox"  className="checkbox-style-indu posr-highlight"
                       onChange={()=>this.changeselection(3)}
                       checked={this.state.selectedpack===3  ? true: false}
                       ></input>
                        
                        </div>
                   </div>
                   </div>

                   

            </div>

            </Slider>
            <div className="row sig-mar">
                <div className="col-3  ">
                     
                </div>
                <div className="col-9  ">
                <button className= {this.state.selectedpack===''?"createprofile meet-our-team  wid-signup dsektopcontinuewidth notselopac":"createprofile meet-our-team  wid-signup dsektopcontinuewidth"}   onClick={this.OPenIndustry} disabled={this.state.selectedpack!== '' ? false : true}>
                   Continue with {  this.state.selectedpack==1? 'Silver' : this.state.selectedpack==2? 'Gold' :this.state.selectedpack==3?  ' Platinum':''}
                    </button>  
                </div>
               
                </div>

                <div className="row">
            
                <div className="col-12   fontsItext">
                *   + GST
                </div>
               
            </div>
            <div className="row">
                
                <div className="col-12   fontsItext padbelow-pricing">
                **  Success Fee of 4.20% can be availed ONLY for a paid GOLD or PLATINUM Subscription. If neither GOLD nor PLATINUM is subscribed for, then the standard 8.33% will be applicable for all successful Hires.
                </div>
               
            </div>

            <div className="row">
                
                <div className="col-12   fontsItext padbelow-pricing">
                ^  Introductory offer 3 JD’s free. Valid for 90 day’s from the date of registration.
                </div>
               
            </div>
            
            

        </div>
    </div>
  



    </div>

    {/* subscription tabel */}
   
    

    


    <div className="comparison-bgclrindu " id="scrol-compare">
        <div className="pad-bottom-main">
            <div class="events-headsss individualBlockIdent active pb-0 pad-pricingtabelcomparison" id="eventhead"> PRICING COMPARISON </div>
             {/* <div className="clickhertxrt-indu " onClick={this.AutoScroll}>CLICK HERE TO VIEW THE PACKAGES</div> */}
             
           
        </div>

    <div className="des-view">
        <div className="wid-tabelpricing">
            <div className="row  ">
                <div className="col-lg-3    ">
                   
                 </div>
                 <div className="col-lg-3  ">
                    <div className="plan-textclr bacground-plan-silver border-bot-1px">SILVER</div>
                    {/* <hr className="hghtline"/>  */}
                </div>
                 <div className="col-lg-3   ">
                    <div className="plan-textclr bacground-plan-Gold border-bot-1px">GOLD</div>
                    {/* <hr className="hghtline"/>  */}
                 </div>
                 <div className="col-lg-3   ">
                    <div className="plan-textclr bacground-plan-Platinum border-bot-1px">PLATINUM</div> 
                    {/* <hr className="hghtline"/>  */}
                 </div>
            </div>

            <div className="row">
                <div className="col-lg-3   ">
                <div className="plan-daysclr bacgroundplancompare-signup signfee-pr-compare"><span id="sigfont">SIGNUP FEE</span></div>
                </div>
                <div className="col-lg-3 ">
                    <div className="plan-daysclr bacground-plan-silver"><span id="bold-textindu">0</span></div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3  ">
                    <div className="plan-daysclr bacground-plan-Gold"><span id="spfontsize">INR </span> <span id="bold-textindu">1,00,000/-*</span></div>
                    {/* <hr /> */}
                </div>
                <div className="col-lg-3   ">
                    <div className="plan-daysclr bacground-plan-Platinum"><span id="spfontsize">INR </span> <span id="bold-textindu"> 2,00,000/-*</span></div>
                    {/* <hr /> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-head bordernone-indus fonweight-bold-ctc">SUCCESS FEE, PER CANDIDATE, FOR ACCEPTED JOB OFFERS</div>
                    {/* <hr /> */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext pa-bigtext-price bordernone-indus fonweight-bold-ctc"> + 8.33% of Annual CTC**</div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3 ">
                    <div className="comparison-pricitable-subtext  pa-bigtext-price bordernone-indus fonweight-bold-ctc"> + 4.2% of Annual CTC**</div>
                    {/* <hr />    */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext pa-bigtext-price bordernone-indus fonweight-bold-ctc"> + 4.2% of Annual CTC**</div>
                    {/* <hr />   */}
                </div>
            </div>

           

             

            <div className="row ">
                <div className="col-lg-12  ">
                    <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus">JUNIOR RESOURCES </div>
                   
                </div>
                
            </div>

            <div className="row">
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-head">Average Annual CTC </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext"> INR 6,00,000/-</div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext">INR 6,00,000/- </div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext">INR 6,00,000/- </div>
                    {/* <hr/> */}
                </div>
            </div>

           

            <div className="row">
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext bordernone-indus pad-leftnumberof-hire"> 5 </div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext bordernone-indus pad-leftnumberof-hire"> 5 </div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext bordernone-indus pad-leftnumberof-hire"> 5 </div>
                    {/* <hr/> */}
                </div>
            </div>


            <div className="row ">
                <div className="col-lg-12  ">
                    <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus"> MID-LEVEL RESOURCES </div>
                   
                </div>
                
            </div>

            <div className="row">
                <div className="col-lg-3 ">
                    <div className="comparison-pricitable-head">Average Annual CTC </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext"> INR 10,00,000/-</div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext">INR 10,00,000/- </div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 ">
                    <div className="comparison-pricitable-subtext">INR 10,00,000/- </div>
                    {/* <hr/> */}
                </div>
            </div>

           

            <div className="row">
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext bordernone-indus pad-leftnumberof-hire"> 3 </div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext bordernone-indus pad-leftnumberof-hire"> 3 </div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3 ">
                    <div className="comparison-pricitable-subtext bordernone-indus pad-leftnumberof-hire"> 3 </div>
                    {/* <hr/> */}
                </div>
            </div>




            <div className="row ">
                <div className="col-lg-12  ">
                    <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus">SENIOR RESOURCES</div>
                   
                </div>
                
            </div>

            <div className="row">
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-head">Average Annual CTC </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext"> INR 25,00,000/-</div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext">INR 25,00,000/- </div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext">INR 25,00,000/- </div>
                    {/* <hr/> */}
                </div>
            </div>

           

            <div className="row">
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-head"> Number of Hires </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext pad-leftnumberof-hire"> 2 </div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext pad-leftnumberof-hire" > 2 </div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext pad-leftnumberof-hire"> 2 </div>
                    {/* <hr/> */}
                </div>
            </div>
            
           
           
           
           
           
           
            <div className="row marpace-cost">
                <div className="col-lg-12  ">
                    <div className="industext-pricingtable costsumry bordernone-indus"> COST SUMMERY </div>
                   
                </div>
                
            </div>
            <div className="row   ">
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-head">Signup Fee  </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext">0</div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext">INR 1,00,000/-</div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext">INR 2,00,000/-</div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-head">Junior Resource Success Fee </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext">INR 2,49,000/-</div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext">INR 1,26,000/-</div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext">INR 1,26,000/-</div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-head "> Mid-Level Resource Success Fee</div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext">INR 2,49,000/-</div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext ">INR 1,26,000/-</div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext  " >INR 1,26,000/-</div>
                    {/* <hr/> */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-head">Senior Resource Success Fee</div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext">INR 4,16,000/-</div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext">INR 2,10,000/-</div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext">INR 2,10,000/-</div>
                    {/* <hr/>/ */}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 ">
                    <div className="comparison-pricitable-head fons-bold-recr border-bend-indu"> TOTAL RECRUITMENT COST </div>
                    {/* <hr />   */}
                </div>
                <div className="col-lg-3   ">
                    <div className="comparison-pricitable-subtext redclr-textinudstry fntweigtprice border-bend-indu">INR 9,16,300/- (+GST) </div>
                    {/* <hr />      */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext redclr-textinudstry fntweigtprice gold-totalrcrprice border-bend-indu">INR 5,62,000/- (+GST)</div>
                    {/* <hr />       */}
                </div>
                <div className="col-lg-3  ">
                    <div className="comparison-pricitable-subtext greenclr-textindustry fntweigtprice border-bend-indu">INR 6,62,000/- (+GST)</div>
                    {/* <hr/> */}
                </div>
            </div>
            <div className="ppadd-top-emptydvi"></div>
             
            <div className="row pd-down">
                <div className="col-lg-3   ">
                      
                </div>
                <div className="col-lg-3 hovertranssbutton ">
                    <div className="content-text-pricing silverconfirm" onClick={()=>this.changecssvari(1)}>
                        SILVER 
                      
                        <input type="checkbox"  className="checkbox-style-indu"
                       onChange={()=>this.changeselection(1)}
                         
                       checked={this.state.selectedpack===1  ? true: false}
                        ></input>
                

                        </div>
                      
                </div>
                <div className="col-lg-3  hovertranssbutton  ">
                    <div className="content-text-pricing goldconfirm" onClick={()=>this.changecssvari(2)}>
                        GOLD 
                       
                        <input type="checkbox"  className="checkbox-style-indu"
                       onChange={()=>this.changeselection(2)}
                       checked={this.state.selectedpack===2  ? true: false}
                       ></input>
                        
                        </div>
                         
                </div>
                <div className="col-lg-3  hovertranssbutton ">
                    <div className="content-text-pricing platinumconfirm"  onClick={()=>this.changecssvari(3)}>
                        PLATINUM
                        
                        <input type="checkbox"  className="checkbox-style-indu"
                       onChange={()=>this.changeselection(3)}
                       checked={this.state.selectedpack===3  ? true: false}
                       ></input>
                        </div>
                 
                </div>
            </div>

            <div className="row sig-mar">
                <div className="col-lg-3  ">
                     
                </div>
                <div className="col-lg-9   ">
                <button className= 
                {this.state.selectedpack==='' ?"createprofile meet-our-team  wid-signup dsektopcontinuewidth notselopac":"createprofile meet-our-team  wid-signup dsektopcontinuewidth"}
                onClick={this.OPenIndustry}  disabled={this.state.selectedpack!== '' ? false : true}>
                 Continue with  {this.state.selectedpack==1? 'Silver' : this.state.selectedpack==2? 'Gold' : this.state.selectedpack== 3?'Platinum':''}
              
                    </button>  
                </div>
               
            </div>

            

            

        
            
            <div className="row  gst-marpcomarison">
                <div className="col-lg-3 ">
                     
                </div>
                <div className="col-lg-9  fontsItext">
                *   + GST
                </div>
               
            </div>
            <div className="row">
                <div className="col-lg-3   ">
                     
                </div>
                <div className="col-lg-9   fontsItext ">
                **  Success Fee of 4.20% can be availed ONLY for a paid GOLD or PLATINUM Subscription. If neither GOLD nor PLATINUM is subscribed for, then the standard 8.33% will be applicable for all successful Hires.
                </div>
               
            </div>
             
        </div>


    </div>
    
    <div className="mobile-view-price">
        <div>
        <div className="prnextbut">
            <Slider {...comparison}>
            <div>
                <div className="row">
                    <div className="col-6">

                    </div>
                    <div className="col-6">
                        <div className="plan-textclr bacground-plan-silver border-bot-1px">SILVER</div> 
                    </div>
                </div> 

                <div className="row">
                    <div className="col-6">
                        <div className="plan-daysclr bacgroundplancompare-signup signfee-pr-compare"><span id="sigfont">SIGNUP FEE</span></div>
                    </div>
                    <div className="col-6">
                        <div className="plan-daysclr bacground-plan-silver"><span id="bold-textindu">0</span></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus fonweight-bold-ctc">SUCCESS FEE, PER CANDIDATE, FOR ACCEPTED JOB OFFERS</div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext pa-bigtext-price bordernone-indus fonweight-bold-ctc"> + 8.33% of Annual CTC**</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus">JUNIOR RESOURCES </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 6,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 5 </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-12  ">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus"> MID-LEVEL RESOURCES </div>
                   
                    </div>
               </div>

               <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 10,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 3 </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-12  ">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus">SENIOR RESOURCES</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 25,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 2 </div>
                    </div>
                </div>

                <div className="row marpace-cost">
                    <div className="col-12  ">
                        <div className="industext-pricingtable costsumry bordernone-indus"> COST SUMMERY </div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Signup Fee  </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">0</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Junior Resource Success Fee </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 2,49,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head "> Mid-Level Resource Success Fee</div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 2,49,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Senior Resource Success Fee</div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 4,16,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head fons-bold-recr border-bend-indu"> TOTAL RECRUITMENT COST </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext redclr-textinudstry fntweigtprice border-bend-indu">INR 9,16,300/- (+GST) </div>
                    </div>
                </div>
                <div className="pa-bott-mb-butt"></div>

                <div className="row pd-down">
                    <div className="col-6  ">
                        
                    </div>
                    <div className="col-6   ">
                        <div className="content-text-pricing silverconfirm posrelmbview brdr-radimbview" onClick={()=>this.changeselectpackwithoutbrd(1)}>
                        SILVER 
                         
                        <input type="checkbox"  className="checkbox-style-indu posr-highlight"
                       onChange={()=>this.changeselection(1)}
                       checked={this.state.selectedpack===1  ? true: false}
                       ></input>
                        
                    </div>
                   </div>
                   </div>


            
            </div>
            {/* silver end mobile */}
            <div>
                <div className="row">
                    <div className="col-6">

                    </div>
                    <div className="col-6">
                        <div className="plan-textclr bacground-plan-Gold border-bot-1px">GOLD</div>
                    </div>
                </div> 

                <div className="row">
                    <div className="col-6">
                        <div className="plan-daysclr bacgroundplancompare-signup signfee-pr-compare"><span id="sigfont">SIGNUP FEE</span></div>
                    </div>
                    <div className="col-6">
                        <div className="plan-daysclr bacground-plan-Gold"><span id="spfontsize">INR </span> <span id="bold-textindu">1,00,000/-*</span></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus fonweight-bold-ctc">SUCCESS FEE, PER CANDIDATE, FOR ACCEPTED JOB OFFERS</div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext pa-bigtext-price bordernone-indus fonweight-bold-ctc"> + 4.2% of Annual CTC**</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus">JUNIOR RESOURCES </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 6,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 5 </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-12  ">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus"> MID-LEVEL RESOURCES </div>
                   
                    </div>
               </div>

               <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 10,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 3 </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-12  ">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus">SENIOR RESOURCES</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 25,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 2 </div>
                    </div>
                </div>

                <div className="row marpace-cost">
                    <div className="col-12  ">
                        <div className="industext-pricingtable costsumry bordernone-indus"> COST SUMMERY </div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Signup Fee  </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 1,00,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Junior Resource Success Fee </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 1,26,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head "> Mid-Level Resource Success Fee</div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 1,26,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Senior Resource Success Fee</div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 2,10,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head fons-bold-recr border-bend-indu"> TOTAL RECRUITMENT COST </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext redclr-textinudstry fntweigtprice gold-totalrcrprice border-bend-indu">INR 5,62,000/- (+GST)</div>
                    </div>
                </div>

                <div className="pa-bott-mb-butt"></div>

                   <div className="row pd-down">
                    <div className="col-6  ">
                        
                    </div>
                    <div className="col-6   ">
                    <div className="content-text-pricing goldconfirm posrelmbview brdr-radimbview" onClick={()=>this.changeselectpackwithoutbrd(2)}>
                        GOLD 
                       {/* {this.state.selectedpack==2&&
                        <img src={selectedcheck}  id='selectpacktick'/>}  */}
                        <input type="checkbox"  className="checkbox-style-indu posr-highlight"
                        onChange={()=>this.changeselection(2)}
                       checked={this.state.selectedpack===2  ? true: false}
                       ></input>
                        
                        </div>
                         
                    </div>
                   </div>
            
            </div>
            {/* Gold end  mobile */}

            <div>
                <div className="row">
                    <div className="col-6">

                    </div>
                    <div className="col-6">
                        <div className="plan-textclr bacground-plan-Platinum border-bot-1px">PLATINUM</div> 
                    </div>
                </div> 

                <div className="row">
                    <div className="col-6">
                        <div className="plan-daysclr bacgroundplancompare-signup signfee-pr-compare"><span id="sigfont">SIGNUP FEE</span></div>
                    </div>
                    <div className="col-6">
                        <div className="plan-daysclr bacground-plan-Platinum"><span id="spfontsize">INR </span> <span id="bold-textindu"> 2,00,000/-*</span></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus fonweight-bold-ctc">SUCCESS FEE, PER CANDIDATE, FOR ACCEPTED JOB OFFERS</div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext pa-bigtext-price bordernone-indus fonweight-bold-ctc"> + 4.2% of Annual CTC**</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus">JUNIOR RESOURCES </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 6,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 5 </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-12  ">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus"> MID-LEVEL RESOURCES </div>
                   
                    </div>
               </div>

               <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 10,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 3 </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-12  ">
                        <div className="industext-pricingtable junres redclr-textinudstry bordernone-indus">SENIOR RESOURCES</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head">Average Annual CTC </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext"> INR 25,00,000/-</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="comparison-pricitable-head bordernone-indus"> Number of Hires </div>
                    </div>
                    <div className="col-6">
                        <div className="comparison-pricitable-subtext bordernone-indus"> 2 </div>
                    </div>
                </div>

                <div className="row marpace-cost">
                    <div className="col-12  ">
                        <div className="industext-pricingtable costsumry bordernone-indus"> COST SUMMERY </div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Signup Fee  </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 2,00,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Junior Resource Success Fee </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 1,26,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head "> Mid-Level Resource Success Fee</div>
                    </div>
                    <div className="col-6 ">
                    <div className="comparison-pricitable-subtext">INR 1,26,000/-</div>
                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head">Senior Resource Success Fee</div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext">INR 2,10,000/-</div>

                    </div>
                </div>

                <div className="row   ">
                    <div className="col-6 ">
                        <div className="comparison-pricitable-head fons-bold-recr border-bend-indu"> TOTAL RECRUITMENT COST </div>
                    </div>
                    <div className="col-6 ">
                        <div className="comparison-pricitable-subtext greenclr-textindustry fntweigtprice border-bend-indu">INR 6,62,000/- (+GST)</div>
                    </div>
                </div>

                <div className="pa-bott-mb-butt"></div>

                <div className="row pd-down">
                    <div className="col-6  ">
                        
                    </div>
                    <div className="col-6  ">
                    <div className="content-text-pricing platinumconfirm  posrelmbview brdr-radimbview" onClick={()=>this.changeselectpackwithoutbrd(3)}>
                        Platinum
                       {/* {this.state.selectedpack==3 &&
                        <img src={selectedcheck} id='selectpacktick' />}  */}
                        <input type="checkbox"  className="checkbox-style-indu posr-highlight"
                        onChange={()=>this.changeselection(3)}
                       checked={this.state.selectedpack===3  ? true: false}
                       ></input>
                        
                        </div>
                   </div>
                   </div>


            
            </div>
            {/* platinum end  mobile */}
            </Slider>
            </div>

            <div className="row sig-mar">
                <div className="col-3  ">
                     
                </div>
                <div className="col-9  ">
                <button className= {this.state.selectedpack===''?"createprofile meet-our-team  wid-signup dsektopcontinuewidth notselopac":"createprofile meet-our-team  wid-signup dsektopcontinuewidth"}   onClick={this.OPenIndustry} disabled={this.state.selectedpack!== '' ? false : true}>
                   Continue with {  this.state.selectedpack==1? 'Silver' : this.state.selectedpack==2? 'Gold' :this.state.selectedpack==3?  ' Platinum':''}
                    </button>  
                </div>
               
                </div>

            <div className="row  gst-marpcomarison">
                <div className="col-lg-3 ">
                     
                </div>
                <div className="col-lg-9  fontsItext">
                *   + GST
                </div>
               
            </div>
            <div className="row">
                <div className="col-lg-3   ">
                     
                </div>
                <div className="col-lg-9   fontsItext  ">
                **  Success Fee of 4.20% can be availed ONLY for a paid GOLD or PLATINUM Subscription. If neither GOLD nor PLATINUM is subscribed for, then the standard 8.33% will be applicable for all successful Hires.
                </div>
               
            </div>
            
        </div>
    </div>

    
    

    </div>
    



    </>
    
    }
 


   
    <div className="rqst-demp-main">

<button className="btn rqst-demo" onClick={()=>{this.OpenRequestDemo()}} >Request For Demo</button>

</div>
 
<ToastContainer className="toastsubmit"/>
 {this.state.isReqstdemo &&
 <RequestDemo
 isFrame={false}
 Url={""}
close={this.closeRequestDemo}
 isReqstdemo={true}
 toastMsg={this.toastMessage}
// Industry = {this.state.Industry}
 />}

 



        </div>
        

       
         )
    }
}
export default Industry
 