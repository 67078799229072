import React from "react";
import { HashRouter , Link  } from 'react-router-dom';
import $ from 'jquery';
import { Routing } from "../../Assests/js/routing";
import {motsSkillablers,exotyqUrl,edcurl,RoutingUrl} from '../../Assests/js/config';
import Header from "../Header_component/Header";
import newtwitter from "../../Assests/Images/newtwitter.svg"
class Footer extends React.Component
{
    constructor(props){
        super(props);
        window.footer =  this;
        this.state={
           reloadData : true,
           year:'',
        }
    }

    componentDidMount(){
            let currentyear=new Date().getFullYear() 
            this.setState({
                year:currentyear
            })

            
            var mapProp = {
                zoom: 12,
                center: new window.google.maps.LatLng(12.95961777808009,  77.71658739458387),
                
            };
            var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);
            const marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(12.95961777808009,  77.71658739458387),
                map: map,
            });
            marker.setMap(map);
            window.google.maps.event.addListener(marker, 'click', function () {
                var infowindow = new window.google.maps.InfoWindow({
                    content: "<a target='_blank' href='https://www.google.com/maps/place/EDC+Creative+Technology+Solutions+PVT.LTD/@12.9615049,77.7108679,15z/data=!4m8!1m2!3m1!2sEDC+Creative+Technology+Solutions+PVT.LTD!3m4!1s0x0:0xaf1326a4a443f93f!8m2!3d12.963014!4d77.7195194'>View Location.</a>"
                });
                infowindow.open(map, marker);
            })    }
relo = () =>{
    this.setState({
        reloadData : !this.state.reloadData,
        
    })
}
    Openfacebook=()=>{
        window.open("https://www.facebook.com/skillablers/",'_blank')
    }
    OpenInstagram=()=>{
        window.open("https://www.instagram.com/skillablers/",'_blank')
    }
    Opentwitter=()=>{
        window.open("https://twitter.com/SkillablersTech",'_blank')
    }
    OpenLinkedIN=()=>{
        window.open("https://www.linkedin.com/company/skillablers",'_blank')
    }
    OpenEDC=()=>{
        window.open("https://www.edccts.com/in/home" , '_blank')
    }
    Newtab=()=>{
        window.open('mailto:connect@skillablers.com', "_blank");
    }
    OpenYouTube=()=>{
        window.open("https://www.youtube.com/channel/UCZx7OXHcq8YOl_-95SFZAaA",'_blank')
    }
    Newtab=()=>{
        window.open('mailto:connect@skillablers.com', "_blank");
    }

    Newtab2=()=>{
        window.open('mailto:customersupport@skillablers.com', "_blank");
    }

    RoutingPath = (location) => {
        window.open(window.location.origin + location, '_self');
        location.reload()
    }

    AutoClose =() =>{
        document.getElementById("header").classList.remove('transparent-backgror')
       if(document.getElementsByClassName("AniNavMenu")[0].classList.contains("Menu-open")){
        window.header.Toggle(1)
       }
       $('html,body').animate({
        scrollTop: 0
    },100);
 this.relo();
 if(window.location.hash.includes(Routing.ycc)|| window.location.hash(Routing.rediness)){
    document.body.style.background = "#1C1C1D"
 }
 else{
    document.body.style.background = "#fff"
 }
 if(window.location.hash.includes(Routing.ycc)){
    document.getElementById("header").style.height = "12vh"
    document.getElementById("img1").style.width = "auto"
    document.getElementById("img1").style.height = "8vh"
 }
 else{
    document.getElementById("header").style.height = "auto"
    document.getElementById("img1").style.width = "40%"
    document.getElementById("img1").style.height = "auto"
 }
    }

//  colorchange=()=>
//  {
    
//     if(window.location.hash.includes(Routing.Home)===true)
//     {
//         document.getElementById("dfooter").classList.add('ishome')
        
        
//     }
//     else if(window.location.hash.includes(Routing.Home)===true)
//     {
//         document.getElementById("dfooter").classList.add('isjob')
        
//     }
//     else if(window.location.hash.includes(Routing.Industry)===true)
//     {
//         document.getElementById("dfooter").classList.add('isIndutry')
        
//     }
//     else if(window.location.hash.includes(Routing.ycc)===true)
//     {
//         document.getElementById("dfooter").classList.add('isycc')
        
//     }
//  }
   
    render(){
        
        return(
              

            // <section id="dfooter" className={((window.location.hash.includes(Routing.ycc) &&  !window.location.hash.includes(Routing.registration)) ) ? 
            // "D-ffoter-color-chnage" : window.location.hash.includes(Routing.registration) ?  "D-ffoter-color-chnage-1":  window.location.hash.includes(Routing.Home) ?  "backgroundclrhome": ""}>
        <div className={window.location.hash.includes(Routing.chaloexam) ?  "chalofooter ": " "}>
         <section id="dfooter" className={ window.location.hash.includes(Routing.Home) ?  "backgroundclrhome": window.location.hash.includes(Routing.Job) ? "backgroundclrjobspage" : window.location.hash.includes(Routing.Industry) ? "backgroundclrjobspage" 
         : window.location.hash.includes(Routing.OurTeam) ?"backgroundclrjobspage" : window.location.hash.includes(Routing.ycc) ?" backgroundclrourteam ": window.location.hash.includes(Routing.registration) ?"backgroundclrourteam " : window.location.hash.includes(Routing.chaloexamhome) ?"backgroundclrhome "  : window.location.hash.includes(Routing.indihomelanding) ?" ": window.location.hash.includes(Routing.sklenquiry) ?"backgroundclrEnquiry" :window.location.hash.includes(Routing.CareerCounsell) ?"backgroundclrcouncell"  :"backgroundclrhome"}> 
           <section class="footer-mainsec">

                <footer class="width">
                    <div class="footer-maindiv">
                        <div class="connect-text">
                            <h1>CONNECT</h1>
                        </div>

                        <div class="c1">
                            <div class="row main-iconmaps mx-0">

                                <div class="col-xl-3 col-lg-3 col-md-6 col-12 px-0">



                                    <div class="mail-loc" id="order1">
                                        <div class="mail">
                                            <div class="mc2">
                                                <p onClick={this.Newtab} class="cursor-pointer">connect@skillablers.com</p>
                                                <p onClick={this.Newtab2} class="cursor-pointer">customersupport@skillablers.com</p>
                                            </div>
                                        </div>
                                        <div class="loc">
                                           
                                            <div class="ic2">
                                                <p className="font-address-new">
                                                    SKILLABLERS Technologies Private Limited.
                                                    <br/>
                                                    IndiQube Omega,
                                                    <br/>
                                                    ITPL Main Road
                                                    <br/>
                                                    Bengaluru - 560066, Karnataka

                                                </p>


                                            </div>

                                        </div>
                                        <div class="icon-parent footer-socail" >
                                            <div class="twitter newlogotwitterstyle" onClick={this.Opentwitter}><img src={newtwitter} className="border-twitter"/></div>
                                            <div class="linked" onClick={this.OpenLinkedIN}><i class="fa fa-linkedin" aria-hidden="true"></i></div>
                                            <div class="facebook" onClick={this.Openfacebook}><i class="fa fa-facebook" aria-hidden="true"></i>
                                            </div>
                                            <div class="insta" onClick={this.OpenInstagram}><i class="fa fa-instagram" aria-hidden="true"></i></div>
                                            <div class="utube" onClick={this.OpenYouTube}><i class="fa fa-youtube-play" aria-hidden="true"></i>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-md-6 col-12 px-0" id="order2">
                                    <div class="ic2">
                                        <p className="grop-com-whitspa"> <a  href={edcurl+"/who-we-are/company-profile"} target="_blank" className="color-grouptext-dec">Group of Companies</a></p>
                                        </div>
                                    <div class="icons-teramsform">
                                        <div class="terms grcompan">
                                           
                                           <div className="foot-gp-com-site-pad-bot"><a href= {edcurl+"/creative-tech-solution"} target="_blank" class="no-text-dec"> <div className="groupcomp-edclogo">EDCCTS </div></a></div>
                                                    <div className="foot-gp-com-site-pad-bot"><a href={RoutingUrl} target="_blank" class="no-text-dec"><div className="group-comp-brds">SKILLABLERS</div></a></div>
                                                   <div className="foot-gp-com-site-pad-bot"> <a href= {exotyqUrl} target="_blank" class="no-text-dec"><div className="groupcomp-exotyqlogo">EXOTYQ</div></a></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-md-6 col-12 px-0" id="order2">
                                    <div class="ic2">
                                        <p className="sitemap-text"> <span    className="color-site">SITE MAP</span></p>
                                        </div>
                                    <div class="icons-teramsform pad-sites">
                                        <div class="terms wid-sitemap">
                                        <HashRouter>
                                         


                                                   <div className="foot-gp-com-site-pad-bot"><Link  to={Routing.indihomelanding} ><div className="point-text-footer"><span className="bulleticon-font"><i class="fa fa-circle" aria-hidden="true"></i></span><div className="navhover pad-sitemap color-sitemapcontent ">INDIVIDUALS</div></div></Link></div>
                                                   <div className="foot-gp-com-site-pad-bot"><Link  to={Routing.insthomelanding} ><div className="point-text-footer"><span className="bulleticon-font"><i class="fa fa-circle" aria-hidden="true"></i></span><div className="navhover pad-sitemap color-sitemapcontent ">INSTITUTIONS</div></div></Link></div>
                                                    <div className="foot-gp-com-site-pad-bot"><Link  to={Routing.induhomelanding} ><div className="point-text-footer"><span className="bulleticon-font"><i class="fa fa-circle" aria-hidden="true"></i></span><div className="navhover pad-sitemap color-sitemapcontent ">INDUSTRIES</div></div></Link></div>
                                                    {/* <div className="foot-gp-com-site-pad-bot"><Link  to={Routing.ycc} ><div className="point-text-footer"><span className="bulleticon-font"><i class="fa fa-circle" aria-hidden="true"></i></span><div className="navhover pad-sitemap color-sitemapcontent">EVENTS</div></div></Link></div> */}
                                                    <div className="foot-gp-com-site-pad-bot"><Link  to={Routing.Inquizition} ><div className="point-text-footer"><span className="bulleticon-font"><i class="fa fa-circle" aria-hidden="true"></i></span><div className="navhover pad-sitemap color-sitemapcontent">INQUIZITION</div></div></Link></div>
                                                    <div className="foot-gp-com-site-pad-bot"><Link  to={Routing.OurTeam} ><div className="point-text-footer"><span className="bulleticon-font"><i class="fa fa-circle" aria-hidden="true"></i></span><div className="navhover pad-sitemap color-sitemapcontent">OUR TEAM</div></div></Link></div>
                                                    <div className="foot-gp-com-site-pad-bot"><Link  to={Routing.Job} ><div className="point-text-footer"><span className="bulleticon-font"><i class="fa fa-circle" aria-hidden="true"></i></span><div className="navhover pad-sitemap color-sitemapcontent">JOBS</div></div></Link></div>
                                                   <div className="foot-gp-com-site-pad-bot"> <a href={motsSkillablers} target="" class="no-text-dec"><div className="point-text-footer"><span className="bulleticon-font"><i class="fa fa-circle" aria-hidden="true"></i></span><div className="navhover pad-sitemap color-sitemapcontent">SKILLS</div></div></a></div>
                                            </HashRouter>
                                        </div>
                                    </div>
                                </div>

         

                                <div class="col-xl-3 col-lg-3 col-md-12 col-12 map-new px-0" id="order3">
                                    <div class="right-side-section-block connect-tab-view-right">
                                        <div id="googleMap" style={{width:'100%',height:'30%'}}></div>

                                    </div>
                                </div>
                            </div>




                             
                         

                        </div>



                    </div>

                    <div class="footersitemap">
                        
                    <HashRouter>
                        {/* <a onClick={()=> this.RoutingPath(Routing.sitemap)} target="_blank"><div>Site map</div></a> */}
                        
                        <Link  to={Routing.termsofuse} ><div>Terms of use</div></Link>
                        {/* <Link  to={Routing.ycc}><div>SKL YCC 2022</div></Link> */}
                        <Link  to={Routing.privacypolicy}><div>Privacy statement</div></Link>
                        <Link  to={Routing.confirmationofpayment}><div>Payment terms</div></Link>
                        <Link  to={Routing.disclaimer}><div>Disclaimer</div></Link>
                        <Link  to={Routing.safe}><div>Safe harbor provision</div></Link>
                        <Link  to={Routing.cookies}><div>Cookies policy</div></Link>
                        <Link  to={Routing.trademarkscopyright}><div class="dotshow">Trademarks & copyrights</div></Link>
                   </HashRouter>
                    </div>
                    <div class="last">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> Skillablers Technologies PVT LTD.{this.state.year}. All
                            right reserved</p>
                    </div>









                </footer>


            </section>
            {/* <Header
            colorchange={this.colorchange}
            /> */}

            
        </section>
        </div> 
            
        )
    }
}
export default Footer
